import React from "react";

import BaseCommandBar from "../../../common/CommandBar";
import type { CommandBarItemProps } from "../../../common/CommandBar/methods";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar/methods";

import SearchByTags from "./Tags/SearchByTags";
import { useTranslation } from "react-i18next";

type ThreadCommentsCommandBarProps = {
  handleSearch?: (value: string) => void;
  onAdd: () => void;
  onManageTags: () => void;
};

export const CommandBar = ({
  handleSearch,
  onAdd,
  onManageTags,
}: ThreadCommentsCommandBarProps) => {
  const { t } = useTranslation();
  const items: CommandBarItemProps[] = [
    {
      key: "newComment",
      text: t("New comment"),
      className: "secondary-dropdown",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add" },
      onClick: onAdd,
    },
    {
      key: "tags",
      text: t("Manage tags"),
      className: "secondary-dropdown",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Tag" },
      onClick: onManageTags,
    },
    {
      key: "tagSearch",
      type: CommandBarItemType.Custom,
      onRender: () => <SearchByTags />,
      className: "secondary-dropdown",
    },
  ];

  return (
    <BaseCommandBar
      className="comments-command-bar"
      items={computeCommandBarItems(items)}
      onSearch={handleSearch}
    />
  );
};
