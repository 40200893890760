import axios from "axios";
import JSZip from "jszip";
import { sortBy } from "lodash-es";

import type { WaterfallChartInterface } from "../../../analysis-raw-data/types";
import { getApiClient } from "../../../core/apiClient/useApiStore";
import { conditionOrder } from "../../../../Components/MachineCV/MachineCVSummaryPage/SignalsSection/methods";
import type { DataPoint } from "../../../../types";

interface GetTrendMeasuredDataInAMonthZipArgs {
  signalId: string;
  year: number;
  month: number;
}

export const getTrendMeasuredDataInAMonthZip = async (
  args: GetTrendMeasuredDataInAMonthZipArgs
) => {
  const body = await getApiClient().get(
    `/data/read/v1/trends/${args.signalId}/${args.year}/${args.month}/zip`,
    {
      responseType: "arraybuffer",
    }
  );

  if (!body.data.byteLength) return [] as DataPoint[];

  const zip = await JSZip.loadAsync(body.data);
  const fileName = `${args.month}.json`;
  const jsonString = await zip.file(fileName)?.async("string");
  const json = JSON.parse(jsonString || "[]");

  return json as DataPoint[];
};

interface GetTrendMeasuredDataInAYearZipArgs {
  signalId: string;
  year: number;
}

export const getTrendMeasuredDataInAYearZip = async (
  args: GetTrendMeasuredDataInAYearZipArgs
) => {
  const body = await getApiClient().get(
    `/data/read/v1/trends/${args.signalId}/${args.year}/zip`,
    {
      responseType: "arraybuffer",
    }
  );

  if (!body.data.byteLength) return [] as DataPoint[];

  const zip = await JSZip.loadAsync(body.data);
  const fileName = `${args.year}.json`;
  const jsonString = await zip.file(fileName)?.async("string");
  const json = JSON.parse(jsonString || "[]");

  return json as DataPoint[];
};

interface GetShortTrendRawBlocksForAMonthZipArgs {
  signalId: string;
  year: number;
  month: number;
}

export const getShortTrendRawBlocksForAMonthZip = async (
  args: GetShortTrendRawBlocksForAMonthZipArgs
) => {
  const body = await getApiClient().get(
    `/data/read/v1/shorttrends/${args.signalId}/${args.year}/${args.month}/raw/zip`,
    {
      responseType: "arraybuffer",
    }
  );

  if (!body.data.byteLength) return [] as unknown[];

  const zip = await JSZip.loadAsync(body.data);
  const fileName = `${args.month}.json`;
  const jsonString = await zip.file(fileName)?.async("string");
  const json = JSON.parse(jsonString || "[]");

  return json as unknown[];
};

interface GetShortTrendMetaDataPointsInAMonthArgs {
  signalId: string;
  year: number;
  month: number;
}

export const getShortTrendMetaDataPointsInAMonth = async (
  args: GetShortTrendMetaDataPointsInAMonthArgs
) => {
  const body = await getApiClient().get(
    `/data/read/v1/shorttrends/${args.signalId}/${args.year}/${args.month}`
  );

  return body.data as unknown[];
};

interface GetShortTrendMetaDataPointsInACustomTimeRangeAsZipArgs {
  signalId: string;
  startDateTime: string;
  endDateTime: string;
}

interface ShortTrendMetaDataPoint {
  dataPoints: { id: string; timeStamp: string }[];
}

export const getShortTrendMetaDataPointsInACustomTimeRangeAsZip = async (
  args: GetShortTrendMetaDataPointsInACustomTimeRangeAsZipArgs
) => {
  const body = await getApiClient().get(
    `/data/read/v1/shorttrends/${args.signalId}?startDateTime=${args.startDateTime}&endDateTime=${args.endDateTime}`
  );

  return body.data as ShortTrendMetaDataPoint;
};

interface GetShortTrendRawDataPointsForOneDataPointAsZipArgs {
  signalId: string;
  dataPointId: string;
}

interface ShortTrendRawDataPoints {
  dataPoints: number[];
  id: string;
  sampleRateInHz: number;
  signalId: string;
  timeStamp: string;
  value: number;
}

export const getShortTrendRawDataPointsForOneDataPointAsZip = async (
  args: GetShortTrendRawDataPointsForOneDataPointAsZipArgs
): Promise<ShortTrendRawDataPoints> => {
  const body = await getApiClient().get(
    `/data/read/v1/shorttrends/${args.signalId}/${args.dataPointId}/raw/zip`,
    {
      responseType: "arraybuffer",
    }
  );

  if (!body.data.byteLength)
    return {
      dataPoints: [],
      id: "",
      sampleRateInHz: 0,
      signalId: "",
      timeStamp: "",
      value: 0,
    } as ShortTrendRawDataPoints;

  const zip = await JSZip.loadAsync(body.data);
  const fileName = `${args.dataPointId}.json`;
  const jsonString = await zip.file(fileName)?.async("string");
  const json = JSON.parse(jsonString || "[]");

  return json as ShortTrendRawDataPoints;
};

interface GetTrendDataPointsInAMonthArgs {
  signalId: string;
  year: string | number | Date | undefined;
  month: string | number | Date | undefined;
}

export const getTrendDataPointsInAMonth = async (
  args: GetTrendDataPointsInAMonthArgs
): Promise<DataPoint[]> => {
  const body = await getApiClient().get(
    `/data/read/v1/trends/${args.signalId}/${args.year}/${args.month}/zip`,
    {
      responseType: "arraybuffer",
    }
  );

  if (!body.data.byteLength) return [];

  const zip = await JSZip.loadAsync(body.data);
  const fileName = `${args.month}.json`;
  const jsonString = await zip.file(fileName)?.async("string");
  const json = JSON.parse(jsonString || "[]");

  return json as DataPoint[];
};

export const getSliderImagesList = async () => {
  // const body = await axios.get(`https://dummyjson.com/products`);
  const body = await axios.get(`http://localhost:8888`);
  return body.data as unknown[];
};

interface GetConditionOverviewArgs {
  machineId: string;
}

export interface MachineCVCondition {
  status: string;
  machine: {
    comment: string;
    customerCode: string;
    dalogId: string;
    manufacturer: string;
    name: string;
    notation: string;
    process: string;
    projectId: string;
    type: string;
    dataFrom: string;
    dataUntil: string;
    eventDataFrom: string;
    eventDataUntil: string;
    fastTrendDataFrom: string;
    fastTrendDataUntil: string;
    id: string;
    shortTermDataFrom: string;
    shortTermDataUntil: string;
    trendDataFrom: string;
    trendDataUntil: string;
    drawingIds: {
      fileId: string;
      sortIndex: number;
    }[];
    gearbox: {
      manufacturer: string;
      name: string;
      notation: string;
      power: number;
      rotationalSpeed: number;
      serialnumber: string;
    };
  };
  conditions: {
    id: string;
    machineId: string;
    value: string;
    group: string;
    name: string;
  }[];
}

export const getConditionOverview = async (args: GetConditionOverviewArgs) => {
  const { data } = await getApiClient().get<MachineCVCondition>(
    `/meta/machinecv/v1/conditions/overview/${args.machineId}`
  );

  const { conditions = [], machine, ...rest } = data;

  return {
    conditions: sortBy(
      conditions.map((item) => ({
        ...item,
        conditionKey: conditionOrder[item.value],
      })),
      ({ name }) => name?.toLowerCase()
    ),
    machine: {
      ...machine,
      drawingIds: sortBy(machine.drawingIds || [], ["sortIndex"]),
    },
    ...rest,
  };
};

interface GetImageArgs {
  id: string;
}

export const getImage = async (args: GetImageArgs) => {
  const body = await getApiClient().get(`/files/v1/files/${args.id}`, {
    responseType: "arraybuffer",
  });

  return body.data as unknown[];
};

interface GetShortTrendFFTReducedAsZipArgs {
  signalId: string;
  startDateTime: string | number | Date | undefined;
  endDateTime: string | number | Date | undefined;
  reduction: number;
}

export const getShortTrendFFTReducedAsZip = async (
  args: GetShortTrendFFTReducedAsZipArgs,
  onProgress: (progress: number) => void
) => {
  const body = await getApiClient().get(
    `/data/read/v1/shorttrends/${args.signalId}/fft/reduced/zip?startDateTime=${args.startDateTime}&endDateTime=${args.endDateTime}&reduction=${args.reduction}`,
    {
      responseType: "arraybuffer",
      onDownloadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        onProgress(progress);
      },
    }
  );

  if (!body.data.byteLength) return { data: [] };

  const zip = await JSZip.loadAsync(body.data);
  const fileName = "ffts.json";
  const jsonString = await zip.file(fileName)?.async("string");
  const json = JSON.parse(jsonString || "[]");

  return json as WaterfallChartInterface;
};

interface GetSignalMetadataByIdArgs {
  machineId: string;
  signalId: string;
}

export const getSignalMetadataById = async (
  args: GetSignalMetadataByIdArgs
) => {
  const { machineId, signalId } = args;
  const body = await getApiClient().get(
    `/meta/read/v1/machines/${machineId}/signals/${signalId}`
  );

  return body.data;
};

interface GetShortTrendFFTForASingleDataPointArgs {
  signalId: string;
  dataPointId: string;
}

export const getShortTrendFFTForASingleDataPoint = async (
  args: GetShortTrendFFTForASingleDataPointArgs
) => {
  const body = await getApiClient().get(
    `/data/read/v1/shorttrends/${args.signalId}/${args.dataPointId}/fft/zip`,
    {
      responseType: "arraybuffer",
    }
  );

  if (!body.data.byteLength) return [];

  const zip = await JSZip.loadAsync(body.data);
  const fileName = "fft.json";
  const jsonString = await zip.file(fileName)?.async("string");
  const json = JSON.parse(jsonString || "[]");

  return json;
};
