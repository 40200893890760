import type { ISearchBoxProps } from "@fluentui/react";
import { SearchBox, Stack, Text } from "@fluentui/react";
import classNames from "classnames";
import type { PropsWithChildren } from "react";
import React from "react";

import { Spin } from "../../common/Spin";
import { NoData } from "../../common/NoData";
import { useTranslation } from "react-i18next";

export type SectionCommandBarItem = {
  key: string;
  onRender: () => JSX.Element;
};

type SectionContainerProps = React.HTMLAttributes<HTMLElement> & {
  title: string;
  isLoading?: boolean;
  noData?: boolean;
  commandBarItems?: SectionCommandBarItem[];
  searchBoxProps?: ISearchBoxProps;
};

const SectionContainer = ({
  title,
  commandBarItems,
  isLoading,
  noData,
  searchBoxProps,
  children,
  ...rest
}: PropsWithChildren<SectionContainerProps>) => {
  const { t } = useTranslation();
  let content: React.ReactNode = children;
  if (isLoading === true) {
    content = <Spin style={{ height: "100%" }} />;
  } else if (noData === true) {
    content = <NoData />;
  }

  return (
    <section
      {...rest}
      className={classNames("summary-section-container", rest.className)}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        tokens={{ childrenGap: 5 }}
      >
        <Stack
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 5 }}
          style={{ height: "30px" }}
        >
          <Text
            key={`section-title-${title}`}
            variant="mediumPlus"
            as="h3"
            className="summary-section-title"
          >
            {title}
          </Text>
          {commandBarItems?.map((item) => (
            <Stack.Item key={item.key}>{item.onRender()}</Stack.Item>
          ))}
        </Stack>
        {searchBoxProps && (
          <SearchBox
            {...searchBoxProps}
            key={`search-box-${title}`}
            className={classNames(
              "summary-section-search-box",
              searchBoxProps.className
            )}
            placeholder={t(`Search`)}
          />
        )}
      </Stack>
      <div className="summary-section-content">{content}</div>
    </section>
  );
};

export default SectionContainer;
