import { useTranslation } from "react-i18next";
import { TabMenu } from "../../../common/TabMenu";

import { TriggersList } from "./ListTab/TriggersList";
import TriggeringStatistics from "./StatisticsTab/TriggeringStatistics";

enum TAB_TITLES {
  Table = "Table",
  Statistics = "Statistics",
}

const SensorTriggering = () => {
  const { t } = useTranslation();
  const tabItems = [
    {
      key: TAB_TITLES.Table,
      title: t("Table"),
      content: <TriggersList />,
    },
    {
      key: TAB_TITLES.Statistics,
      title: t("Statistics"),
      content: <TriggeringStatistics />,
    },
  ];

  return (
    <>
      <div className="sensor-triggering-container">
        <TabMenu
          tabs={tabItems}
          pivotProps={{
            styles: {
              link: { flex: 1, margin: 0, fontSize: 14, fontWeight: "600" },
              linkIsSelected: { background: "#2C529F", color: "#fff" },
            },
            defaultSelectedKey: TAB_TITLES.Table,
          }}
        />
      </div>
    </>
  );
};

export default SensorTriggering;
