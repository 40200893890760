import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import uniqueId from "lodash-es/uniqueId";
import type { CSSProperties, MutableRefObject } from "react";
import { forwardRef, useEffect, useLayoutEffect, useMemo } from "react";
import { useEnsuredForwardedRef } from "react-use";

import { createScrollbar } from "./scrollbar";
import { useTranslation } from "react-i18next";

import am5locales_en_US from "@amcharts/amcharts5/locales/en_US";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import am5locales_fr_FR from "@amcharts/amcharts5/locales/fr_FR";
import am5locales_de_DE from "@amcharts/amcharts5/locales/de_DE";
import am5locales_pt_PT from "@amcharts/amcharts5/locales/pt_PT";

const languages = {
  en: am5locales_en_US,
  es: am5locales_es_ES,
  fr: am5locales_fr_FR,
  de: am5locales_de_DE,
  pt: am5locales_pt_PT,
};

const AMCHARTS_LICENSE = process.env.REACT_APP_AMCHARTS_LICENSE;

const defaultSettings: am5xy.IXYChartSettings = {
  panX: true,
  panY: true,
  wheelY: "zoomX",
  pinchZoomX: true,
};

export type XYChartRef = {
  root: am5.Root;
  chart: am5xy.XYChart;
};

export type XYChartStyles = {
  root?: CSSProperties;
  chart?: CSSProperties;
  export?: CSSProperties;
};

export type XYChartProps = {
  styles?: XYChartStyles;
  settings?: am5xy.IXYChartSettings;
  exportSelector: string;
  isMinimized?: boolean;
  customSettings?: any;
  className?: any;
};

const XYChart = forwardRef<XYChartRef, XYChartProps>(
  (
    {
      className,
      settings,
      exportSelector,
      isMinimized = false,
      styles: _styles,
      customSettings = {},
    },
    _ref
  ) => {
    const ref = useEnsuredForwardedRef(
      _ref as MutableRefObject<XYChartRef | null>
    );

    const { i18n } = useTranslation();

    const { rootId, chartId } = useMemo(() => {
      const id = uniqueId("xy-");

      return {
        rootId: `root-${id}`,
        chartId: `chart-${id}`,
      };
    }, []);

    const styles = useMemo<XYChartStyles>(
      () => ({
        root: {
          position: "relative",
          // visibility: ready ? 'visible' : 'hidden',
          ..._styles?.root,
        },
        chart: {
          width: "100%",
          height: 450,
          ..._styles?.chart,
        },
        export: {
          ..._styles?.export,
        },
      }),
      [_styles]
    );

    useLayoutEffect(() => {
      am5.addLicense(`${AMCHARTS_LICENSE}`);

      // === Root ===

      const root = am5.Root.new(chartId);

      root.dom.addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });

      // https://www.amcharts.com/docs/v5/getting-started/root-element/#chart-ready-event
      // let timerId: ReturnType<typeof setTimeout> | null = null;

      // const isReady = () => {
      //   if (timerId) {
      //     clearTimeout(timerId);
      //   }

      //   timerId = setTimeout(() => {
      //     root.events.off('frameended', isReady);
      //     setReady(true);
      //   }, 500);
      // };

      // root.events.on('frameended', isReady);

      // === Chart ===

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          ...defaultSettings,
          ...settings,
          layout: root.verticalLayout,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          wheelX: "panX",
          focusable: true,
          cursor: am5xy.XYCursor.new(root, {}),
        })
      );

      chart.plotContainer.events.on("wheel", function (ev) {
        const disabledChart = chart.get("disabled");
        if (ev.originalEvent.ctrlKey) {
          ev.originalEvent.preventDefault();
          chart.set("wheelY", disabledChart ? "none" : "zoomY");
        } else {
          chart.set("wheelY", disabledChart ? "none" : "zoomX");
        }
      });

      // === Zoom Out Button ===

      chart.zoomOutButton.setAll({
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color(0xeb3f3d),
          cornerRadiusBL: 100,
          cornerRadiusBR: 100,
          cornerRadiusTL: 100,
          cornerRadiusTR: 100,
        }),
      });

      createScrollbar(root, chart, {
        type: "scrollbarX",
        orientation: "horizontal",
        ...customSettings.scrollbarX,
      });

      if (!customSettings?.hideYscrollbar) {
        createScrollbar(root, chart, {
          type: "scrollbarY",
          orientation: "vertical",
          ...customSettings.scrollbarY,
        });
      }

      ref.current = {
        root,
        chart,
      };

      chart.appear(500, 300);

      if (customSettings?.onReady) {
        customSettings.onReady(ref.current);
      }

      return () => {
        chart.dispose();
        root.dispose();
        ref.current = null;
      };
    }, []);

    useEffect(() => {
      if (!ref?.current) return;
      const root = ref.current.root;

      root.locale = languages[i18n.resolvedLanguage];
    }, [ref, i18n.resolvedLanguage]);

    useEffect(() => {
      if (!ref?.current) return;
      const root = ref.current.root;

      const timerId = setTimeout(() => {
        document
          .querySelectorAll(`#${root.dom.id} canvas`)
          .forEach((canvas) => {
            canvas.classList.add("chart-canvas");
          });
      }, 1000);

      return () => {
        clearTimeout(timerId);
      };
    }, []);

    useEffect(() => {
      if (!ref?.current || !exportSelector) {
        return;
      }

      const exportBtn = document.querySelector(
        `.${exportSelector} .amcharts-export-button`
      );

      if (!exportBtn) {
        return;
      }

      isMinimized
        ? exportBtn.classList.add("amcharts-export-button--no-label")
        : exportBtn.classList.remove("amcharts-export-button--no-label");
    }, [exportSelector, isMinimized]);

    return (
      <div id={rootId} style={styles.root} className={className}>
        <div id={chartId} style={styles.chart} />
      </div>
    );
  }
);

XYChart.displayName = "XYChart";

export default XYChart;
