import "./style.scss";

import type { IDropdownOption } from "@fluentui/react";
import { Dropdown } from "@fluentui/react";
import type { FormEvent } from "react";
import { useMemo } from "react";

import { default as chartSymbols } from "../../../../../../assets/svg/ChartSymbolsIcons";

import useLayoutSettingsStore from "../../useLayoutSettingsStore";

import { useTranslation } from "react-i18next";

const chartSymbolsComponents = chartSymbols.components;

function onRenderOption(option: any) {
  const key = option?.index || option?.key || 0;
  const text = option?.text || "";

  return (
    <div className="axis-picker-dropdown-item">
      <span className="axis-picker-dropdown-item_text">{text}</span>
      {chartSymbolsComponents[key]}
    </div>
  );
}

const onRenderTitle = (options: any) => {
  const selectedOption = options[0];

  return onRenderOption(selectedOption);
};

export default function AxisSelector() {
  const { t } = useTranslation();

  const { syncSettingsMode, selectedYAxisIndex, seriesNames, updateStore } =
    useLayoutSettingsStore((store: any) => ({
      syncSettingsMode: store.syncSettingsMode,
      selectedYAxisIndex: store.selectedYAxisIndex,
      seriesNames: store.seriesNames,
      updateStore: store.updateStore,
    }));

  const options = useMemo(
    () =>
      (seriesNames || []).map((name: string, index: number) => ({
        key: index,
        text: name,
      })),
    [seriesNames]
  );

  function onDropdownChange(
    event: FormEvent<HTMLDivElement>,
    option: IDropdownOption<any> | undefined,
    index: number | undefined
  ) {
    const newIndex = index || 0;
    updateStore({ selectedYAxisIndex: newIndex });
  }

  return (
    <Dropdown
      placeholder={t("Select an option")}
      options={options}
      disabled={syncSettingsMode === "syncAll"}
      defaultSelectedKey={selectedYAxisIndex}
      onChange={onDropdownChange}
      onRenderOption={onRenderOption}
      onRenderTitle={onRenderTitle}
    />
  );
}
