import { Spinner, SpinnerSize } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import type { ZodSchema } from "zod";
import { z } from "zod";

import { getColumns } from "./columns";
import BaseDialog from "../../../../../Components/common/Dialog/Dialog";
import {
  renderFormItems,
  useZodForm,
} from "../../../../../Components/common/Form";
import type { FormItemProps } from "../../../../../Components/common/Form/FormItems/helpers";
import { FormItemType } from "../../../../../Components/common/Form/FormItems/helpers";
import type { TableProps } from "../../../../../Components/common/Table";
import Table from "../../../../../Components/common/Table";
import type { UploadedFile } from "../../../../../Components/common/Uploader/types";
import type { AcceptedFile } from "../../../../../Components/common/Uploader/Uploader";
import Uploader from "../../../../../Components/common/Uploader/Uploader";

import { useTranslation } from "react-i18next";
import { t } from "i18next";

const dialogStyles = {
  root: { height: "75%" },
};

const defaultFields: FormItemProps[] = [
  {
    name: "description",
    type: FormItemType.TextField,
    groupProps: { label: t("Description") },
  },
];

const defaultSchema = z.object({
  description: z
    .string()
    .max(200, {
      message: t("Description must contain at most 200 character(s)"),
    })
    .optional(),
});

type DocumentDialogFieldsSchema = {
  schema: ZodSchema;
  fields: FormItemProps[];
};

type AddDocumentDialogProps = {
  hidden: boolean;
  onClose: () => void;
  onSubmit: (files: UploadedFile[]) => void;
  formFields?: DocumentDialogFieldsSchema;
};

const AddDocumentDialog = ({
  hidden,
  onClose,
  onSubmit,
  formFields = { fields: defaultFields, schema: defaultSchema },
}: AddDocumentDialogProps) => {
  const { t } = useTranslation();
  const [localFiles, setLocalFiles] = useState<AcceptedFile[]>([]);
  const { fields, schema } = formFields;

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog",
        version: 1,
      },
      items: localFiles,
      perPage: 5,
      hidePerPage: true,
      hasSelection: false,
      columns: getColumns({
        t,
        onRemove: (fileId) => {
          setLocalFiles((prev) => [...prev.filter(({ id }) => id !== fileId)]);
        },
      }),
    }),
    [localFiles, t]
  );

  useEffect(() => {
    if (hidden) {
      reset();
      setLocalFiles([]);
    }
  }, [hidden]);

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
  });

  const onFormSubmit = handleSubmit(async (data) => {
    const files = localFiles.map((file) => ({ ...file, ...data }));
    await onSubmit(files);
    onClose();
  });

  const isDisabled = useMemo(
    () =>
      isSubmitting ||
      !isValid ||
      localFiles.filter(({ isValid }) => isValid).length === 0,
    [isSubmitting, isValid, localFiles]
  );

  const onUpload = (accepted: AcceptedFile[]) => {
    setLocalFiles((prev: AcceptedFile[]) => [...accepted, ...prev]);
  };

  return (
    <BaseDialog
      hidden={hidden}
      styles={dialogStyles}
      dialogContentProps={{
        type: DialogType.normal,
        title: t("Add new document"),
      }}
    >
      <form onSubmit={onFormSubmit}>
        {renderFormItems(fields, {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}
        <Uploader onChange={onUpload} />
        <Table {...tableProps} />
        <DialogFooter>
          <PrimaryButton
            text={t("Upload")}
            type="submit"
            disabled={isDisabled}
            onRenderIcon={() =>
              isSubmitting ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton text={t("Cancel")} onClick={onClose} />
        </DialogFooter>
      </form>
    </BaseDialog>
  );
};

export default AddDocumentDialog;
