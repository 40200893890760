import { Sparkle28Filled } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";

export const AnswerIcon = () => {
  const { t } = useTranslation();

  return (
    <Sparkle28Filled
      primaryFill={"rgba(115, 118, 225, 1)"}
      aria-hidden="true"
      aria-label={t("Answer logo")}
    />
  );
};
