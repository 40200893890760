import "./styles.scss";

import {
  ContextualMenu,
  DefaultButton,
  DialogFooter,
  DialogType,
  DirectionalHint,
  IconButton,
  Spinner,
  SpinnerSize,
  Stack,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import { useEffect } from "react";

import BaseDialog, {
  DialogSize,
} from "../../../../Components/common/Dialog/Dialog";

import AxisSelector from "./components/AxisSelector";
import SyncSettings from "./components/SyncSettings";
import YAxisSettings from "./components/YAxisSettings";
import { dialogStyles } from "./constants";
import useLayoutSettingsStore from "./useLayoutSettingsStore";

import { useTranslation } from "react-i18next";

const HorizontalLine = () => <div className="horizontal-line" />;

const ChartLayoutModal = ({ series, yAxes }: any) => {
  const { t } = useTranslation();

  const { isChartLayoutOpen, resetSettings, updateStore } =
    useLayoutSettingsStore((store: any) => ({
      isChartLayoutOpen: store.isChartLayoutOpen,
      resetSettings: store.resetSettings,
      updateStore: store.updateStore,
    }));

  useEffect(() => {
    if (yAxes?.length > 0) {
      const defaultYAxesSettings = yAxes.reduce(
        (acc: any, _: any, index: number) => {
          acc[index] = {
            factor: 0,
            offset: 0,
          };
          return acc;
        },
        {}
      );

      updateStore({
        yAxes,
        yAxesSettings: defaultYAxesSettings,
      });
    }

    if (series?.length > 0) {
      updateStore({
        seriesNames: series.map((line: any) => line.get("name")),
      });
    }
  }, [yAxes?.length, series?.length]);

  function closeModal() {
    updateStore({ isChartLayoutOpen: false });
  }

  return (
    <BaseDialog
      hidden={!isChartLayoutOpen}
      dialogContentProps={{
        title: t("Chart Layout"),
        type: DialogType.close,
      }}
      modalProps={{
        overlay: {
          hidden: true,
        },
        dragOptions: {
          moveMenuItemText: t("Move"),
          closeMenuItemText: t("Close"),
          menu: ContextualMenu,
          keepInBounds: true,
        },
      }}
      styles={dialogStyles}
      size={DialogSize.S}
      onDismiss={closeModal}
    >
      {yAxes ? (
        <Stack className="chart-layout-modal-content">
          <Stack className="section">
            <div className="section-title">{t("Selector")}</div>
            <div className="section-description">
              {t("Pick the line you want to setup")}
            </div>
            <AxisSelector />
          </Stack>

          <HorizontalLine />

          <Stack className="section">
            <div className="section-title">{t("Sync Settings")}</div>
            <div className="section-description">
              {t("Select an option from the list to assign to Y-Axis")}
            </div>
            <SyncSettings />
          </Stack>

          <HorizontalLine />

          <Stack className="section">
            <div className="section-title">
              {t("Y-Axis settings")}{" "}
              <TooltipHost
                tooltipProps={{
                  onRenderContent: () => (
                    <div>{t("Reset current axis settings")}</div>
                  ),
                }}
                delay={TooltipDelay.zero}
                id="signal-info-tooltip"
                directionalHint={DirectionalHint.topCenter}
              >
                <IconButton
                  iconProps={{
                    iconName: "SyncOccurence",
                  }}
                  styles={{
                    root: [
                      {
                        ".ms-Button-flexContainer": {
                          transform: "rotate(125deg)",
                        },
                      },
                    ],
                  }}
                  onClick={() => resetSettings()}
                />
              </TooltipHost>
            </div>

            <YAxisSettings />
          </Stack>
        </Stack>
      ) : (
        <Spinner size={SpinnerSize.medium} />
      )}

      <DialogFooter>
        <DefaultButton
          text={t("Close")}
          style={{ color: "#fff", background: "#2C529F" }}
          onClick={closeModal}
        />
        <DefaultButton
          styles={{
            root: { border: "unset", background: "transparent" },
          }}
          text={t("Reset all changes")}
          onClick={() => resetSettings("all")}
        />
      </DialogFooter>
    </BaseDialog>
  );
};

export default ChartLayoutModal;
