import type { IDropdownOption } from "@fluentui/react";
import { SelectableOptionMenuItemType } from "@fluentui/react";

import type { Condition } from "../../../../modules/machine-cv/constants";
import { getConditionFormat } from "../../../../modules/machine-cv/constants";

export const getStatusOptions: (t) => IDropdownOption<Condition | "All">[] = (
  t
) => [
  {
    key: "selectAll",
    text: t("Select All"),
    itemType: SelectableOptionMenuItemType.SelectAll,
  },
  { key: "Ok", text: t("Ok"), data: "Ok" },
  { key: "Indication", text: t("Indication"), data: "Indication" },
  { key: "Alert", text: t("Alert"), data: "Alert" },
  { key: "Danger", text: t("Danger"), data: "Danger" },
  {
    key: "NotInstalled",
    text: getConditionFormat(t)["NotInstalled"],
    data: "NotInstalled",
  },
  {
    key: "NoStatus",
    text: getConditionFormat(t)["NoStatus"],
    data: "NoStatus",
  },
  { key: "Faulty", text: t("Faulty"), data: "Faulty" },
];

export const conditionOrder: { [key: string]: number } = {
  Faulty: 0,
  Danger: 1,
  Alert: 2,
  Indication: 3,
  Ok: 4,
  NotInstalled: 5,
  NoStatus: 6,
};

export const handleComboBoxChange = ({
  selectedOption,
  selectedOptions,
  options,
  onChange,
}: any) => {
  const selectableStatusOptions = options.filter(
    (option: any) =>
      (option.itemType === SelectableOptionMenuItemType.Normal ||
        option.itemType === undefined) &&
      !option.disabled
  );
  const selected = selectedOption?.selected;
  const currentSelectedOptionKeys = selectedOptions.filter(
    (key: any) => key !== "selectAll"
  );
  const selectAllState =
    currentSelectedOptionKeys.length === selectableStatusOptions.length;

  if (selectedOption) {
    if (selectedOption?.itemType === SelectableOptionMenuItemType.SelectAll) {
      selectAllState
        ? onChange([])
        : onChange([
            "selectAll",
            ...selectableStatusOptions.map((o: any) => o.key as string),
          ]);
    } else {
      const updatedKeys = selected
        ? [...currentSelectedOptionKeys, selectedOption!.key as string]
        : currentSelectedOptionKeys.filter(
            (k: any) => k !== selectedOption.key
          );
      if (updatedKeys.length === selectableStatusOptions.length) {
        updatedKeys.push("selectAll");
      }
      onChange(updatedKeys);
    }
  }
};
