import { notification } from "../common/Notification";
import { axiosInstance } from "../..";
import { CreateFile, File } from "./models";
import { AxiosRequestConfig } from "axios";
import { t } from "i18next";

export async function downloadFile(
  id: string
): Promise<ArrayBuffer | undefined> {
  const response = await axiosInstance
    .get<ArrayBuffer | any>(`/files/v1/files/${id}`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "image/png",
      },
    })
    .then((resp) => {
      if (resp.status >= 200 && resp.status < 300) {
        return resp.data;
      } else {
        notification.error(t("Error fetching requested file."));
      }
    });
  return response;
}

export async function deleteFile(id: string): Promise<File | any> {
  const urlRequest = "/files/v1/files/" + id;
  const response = await axiosInstance.delete<File | any>(urlRequest);
  if (response.data) return response.data;
  else return response;
}

export async function getFile(id: string): Promise<File | any> {
  const urlRequest = "/files/v1/files/" + id;
  const response = await axiosInstance.get<File | any>(urlRequest);
  if (response.data) return response.data;
  else return response;
}

export async function getFilesOfMachine(id: string): Promise<File[] | any> {
  const urlRequest = "/files/v1/files/machines/" + id;
  const response = await axiosInstance.get<File[] | any>(urlRequest);
  if (response.data) return response.data;
  else return response;
}

export async function addFile(file: CreateFile): Promise<File | any> {
  const urlRequest = "/files/v1/files/";

  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const response = await axiosInstance.post<File | any>(
    urlRequest,
    file,
    config
  );
  if (response.data) return response.data;
  else return response;
}

export async function addBulkFiles(files: CreateFile[]): Promise<File[] | any> {
  let results;

  results = await Promise.all(
    files.map(async (file) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = await addFile(file);
    })
  );

  return results;
}
