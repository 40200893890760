import "./styles.scss";

import { CSSProperties, useMemo } from "react";

import { useSearch } from "../../../../../Hooks";

import type { ConditionStatus } from "../../../../../types";
import type { CommandBarItemProps } from "../../../../common/CommandBar";
import BaseCommandBar, {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../../common/CommandBar";

import Table, { Column } from "../../../../common/Table";
import { Link } from "react-router-dom";
import { Stack, TooltipHost } from "@fluentui/react";
import MachineCvSensors from "../../../../../assets/svg/MachineCvSensors";
import TaskIcon from "../../../../../assets/svg/TaskIcon";
import MultipleComments from "../../../../../assets/svg/MultipleComments";
import FluentSchedule from "../../../../../assets/svg/FluentSchedule";
import TrendsIcon from "../../../../../assets/svg/TrendsIcon";
import RawDataIcon from "../../../../../assets/svg/RawDataIcon";
import MachineCVSummary from "../../../../../assets/svg/MachineCVSummary";
import {
  conditionColor,
  getConditionFormat,
} from "../../../../../modules/machine-cv/constants";
import { useMachineConditionsTable } from "./useMachineConditionsTable";
import { uniqueId } from "lodash-es";
import { useTranslation } from "react-i18next";

import MachineNameField from "./MachineNameField";

export type MachineCardType = {
  id: string;
  title: string;
  subtitle?: string;
  iconId?: string;
  query: string;
  condition: ConditionStatus;
  isBookmarked?: boolean;
  projectName?: string;
};

type MachinesProps = {
  isLoading: boolean;
  sizeProps?: any;
};

export const machinesGridStyle: CSSProperties = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(450px, 1fr))",
  gap: 16,
  marginTop: 10,
};

const containerTitleStyle: CSSProperties = {
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "22px",
};

const getCommandBarItems: (t) => CommandBarItemProps[] = (t) => [
  {
    key: "title",
    type: CommandBarItemType.Custom,
    onRender: () => (
      <div style={containerTitleStyle}>{t("Machines Conditions")}</div>
    ),
  },
];

const ACTION_OPTIONS = [
  { name: "Summary", link: `/machine-cv/summary`, icon: <MachineCVSummary /> },
  {
    name: "Sensors",
    link: `/machine-cv/sensors`,
    icon: <MachineCvSensors />,
  },
  {
    name: "Tasks",
    link: `/machine-cv/tasks`,
    icon: <TaskIcon />,
  },
  {
    name: "Comments",
    link: `/machine-cv/comments`,
    icon: <MultipleComments />,
  },
  {
    name: "Reports",
    link: `/machine-cv/reports`,
    icon: <FluentSchedule />,
  },
  {
    name: "Trend",
    link: `/trend-view`,
    icon: <TrendsIcon fill="#2c529f" />,
  },
  {
    name: "Raw data",
    link: `/raw-data`,
    icon: <RawDataIcon />,
  },
];

function renderConditionField({ t, item, ...rest }, defaultRender) {
  const conditionText = getConditionFormat(t)[item.condition];
  const id = uniqueId();
  return (
    <div
      key={id}
      style={{
        background: conditionColor[item?.condition],
        color: "#3B3A39",
      }}
    >
      {defaultRender({ item: { ...item, condition: conditionText }, ...rest })}
    </div>
  );
}

function renderTextField(props, defaultRender) {
  const columnKey = props.column.key;
  const id = uniqueId();
  return (
    <TooltipHost key={id} content={props.item[columnKey]}>
      {defaultRender(props)}
    </TooltipHost>
  );
}

export const Machines = ({ isLoading }: MachinesProps) => {
  const { t } = useTranslation();
  const columns: Column[] = useMemo(
    () => [
      {
        key: "title",
        fieldName: "title",
        name: t("Machine Name"),
        minWidth: 100,
        maxWidth: 200,
        isSortable: true,
        onRenderField: (props, defaultRender) => (
          <MachineNameField
            key={props.item.id}
            {...props}
            defaultRender={defaultRender}
          />
        ),
      },
      {
        key: "projectName",
        fieldName: "projectName",
        name: t("Project"),
        minWidth: 100,
        maxWidth: 200,
        isSortable: true,
        onRenderField: renderTextField,
      },
      {
        key: "condition",
        fieldName: "condition",
        name: t("Status"),
        minWidth: 100,
        maxWidth: 200,
        isSortable: true,
        onRenderField: (props, defaultRender) =>
          renderConditionField({ t, ...props }, defaultRender),
      },
      {
        key: "lastUpdate",
        // TODO Mircea: Change fieldName when lastUpdate field is ready
        fieldName: "condition",
        name: t("Last Data Update"),
        minWidth: 100,
        maxWidth: 200,
        isSortable: true,
        onRenderField: (props, defaultRender) =>
          renderConditionField({ t, ...props }, defaultRender),
      },
      {
        key: "actions",
        fieldName: "actions",
        name: t("Actions"),
        minWidth: 100,
        maxWidth: 200,
        isSortable: false,
        onRender: (item) => (
          <Stack horizontal>
            {ACTION_OPTIONS.map(({ name, link, icon }, idx) => (
              <TooltipHost
                key={idx}
                content={t(name)}
                styles={{ root: { display: "flex" } }}
              >
                <Link to={`${link}?id=${item.id}`} style={{ margin: "0 5px" }}>
                  {icon}
                </Link>
              </TooltipHost>
            ))}
          </Stack>
        ),
      },
    ],
    [t]
  );

  const { filteredMachines } = useMachineConditionsTable();
  const { data, handleSearch } = useSearch<MachineCardType>({
    data: filteredMachines,
    keys: ["title", "projectName", "condition"],
  });

  return (
    <div
      className="machines-container"
      style={{
        marginTop: 16,
        marginBottom: 80,
      }}
    >
      <BaseCommandBar
        items={computeCommandBarItems(getCommandBarItems(t))}
        onSearch={handleSearch}
        styles={{
          root: {
            alignItems: "center",
            background: "#F3F2F1",
            padding: "11px 12px",
            height: "100%",
          },
        }}
      />

      <Table
        items={data}
        columns={columns}
        hasSelection={false}
        isLoading={isLoading}
        persistOpts={{ key: "table-homepage-conditions", version: 2 }}
      />
    </div>
  );
};
