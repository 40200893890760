import { Dropdown, Icon, Stack } from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function onRenderTitle(selectedOption) {
  const { data } = selectedOption[0];

  return (
    <Stack horizontal style={{ gap: "8px" }}>
      <Icon iconName="Globe" />
      {data}
    </Stack>
  );
}

export default function LanguageMenu() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage || "en";

  const [langKey, setLangKey] = useState(currentLanguage);

  const options = [
    {
      key: "en",
      data: "EN",
      text: "English (EN)",
    },
    {
      key: "es",
      data: "ES",
      text: "Español (ES)",
    },
    {
      key: "de",
      data: "DE",
      text: "Deutsch (DE)",
    },
    {
      key: "fr",
      data: "FR",
      text: "Français (FR)",
    },
    {
      key: "pt",
      data: "PT",
      text: "Português (PT)",
    },
  ];

  return (
    <Dropdown
      styles={{
        root: {
          ".ms-Dropdown-title": {
            height: "100%",
            padding: "0px 8px",
            color: "#FFF !important",
            background: "transparent",
            border: "none",
          },
        },
      }}
      dropdownWidth={"auto"}
      options={options}
      selectedKey={langKey}
      onChange={(_, option) => {
        i18n.changeLanguage(option.key as string);
        setLangKey(option.key as string);
      }}
      onRenderTitle={onRenderTitle}
      onRenderCaretDown={() => null}
    />
  );
}
