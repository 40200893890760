import { DefaultButton, DialogType, Stack, TextField } from "@fluentui/react";
import FormDialog from "../../Generic/FormDialog";
import { DialogSize } from "../../common/Dialog";
import { PropsWithChildren, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type UploadImageBasicProps = {
  title: string;
  accept: string;
  isLoading: boolean;
  isValid: boolean;
  onFilesSelected: (files: File[]) => void;
  onSubmit: () => void;
  onClose: (listHasChanged: boolean) => void;
};

/**
 * Gets the files names in a single string.
 * @param files The files array.
 * @returns The files names in a single string.
 */
const getFilesNames = (files: File[]): string => {
  let result = "";

  for (let i = 0; i < files.length; i++) {
    result += files[i].name;
    if (i !== files.length - 1) {
      result += "\n";
    }
  }

  return result;
};

/**
 * Gets the Upload image basic dialog component.
 * @param title The dialog title.
 * @param accept The accepted image formats.
 * @param isValid Value indicating whether the form fields is valid or not.
 * @param isLoading Value indicating whether the form is in loading state.
 * @param onFilesSelected Method called when a set of files have been selected.
 * @param onSubmit Method called when the submit button is clicked.
 * @param onClose Method called to close this dialog.
 * @returns The upload image basic dialog component.
 */
const UploadImageBasicDialog = ({
  title,
  accept,
  isValid,
  isLoading,
  onFilesSelected,
  onSubmit,
  onClose,
  children,
}: PropsWithChildren<UploadImageBasicProps>) => {
  const { t } = useTranslation();
  const htmlFileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  // Handlers
  const onFilesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length === 0) {
      return;
    }

    const fileList = event.target.files;
    setSelectedFiles(Array.from(fileList));
    onFilesSelected?.(Array.from(fileList));
  };

  return (
    <FormDialog
      title={title}
      submitButtonText={!isLoading ? t("Upload") : t("Uploading...")}
      isLoading={isLoading}
      isValid={selectedFiles && isValid}
      type={DialogType.normal}
      size={DialogSize.S}
      onSubmit={onSubmit}
      onClose={() => onClose(false)}
    >
      {children}
      <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }}>
        <Stack.Item grow>
          <TextField
            label={t("Image files")}
            readOnly
            required
            value={getFilesNames(selectedFiles)}
            multiline={selectedFiles.length > 1}
            resizable={false}
            rows={2}
          />
        </Stack.Item>
        <DefaultButton
          text={t("Browse...")}
          style={{ borderStyle: "none" }}
          onClick={() => htmlFileInputRef?.current.click()}
        />
        <input
          type="file"
          accept={accept}
          style={{ display: "none" }}
          ref={htmlFileInputRef}
          onChange={onFilesChanged}
          multiple
        />
      </Stack>
    </FormDialog>
  );
};

export default UploadImageBasicDialog;
