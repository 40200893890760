import { DefaultButton, IconButton, Separator, Stack } from "@fluentui/react";
import { Resizable } from "re-resizable";
import { useEffect, useState } from "react";
import * as ReactRouterDOM from "react-router-dom";

import { hamburgerIcon } from "../../../../../modules/analysis-raw-data/constants/icons";
import { WSSidebar } from "./Sidebar";
import useWSSidebarStore from "../../../hooks/useWSSidebarStore";
import { useTranslation } from "react-i18next";

const SideBar = () => {
  const { t } = useTranslation();
  const { isExpanded, toggle } = useWSSidebarStore((store) => ({
    isExpanded: store.isExpanded,
    toggle: store.toggle,
    openedNodes: store.openedNodes,
  }));

  const [sidebarWidth, setSidebarWidth] = useState(300);
  const navigate = ReactRouterDOM.useNavigate();

  useEffect(() => {
    if (isExpanded) {
      setSidebarWidth(300);
    } else {
      setSidebarWidth(50);
    }
  }, [isExpanded]);

  return (
    <Stack
      className="no-select"
      style={{
        position: "relative",
      }}
    >
      <Resizable
        minWidth={isExpanded ? 300 : 50}
        maxWidth={560}
        size={{ width: isExpanded ? sidebarWidth : 50, height: "auto" }}
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        onResizeStop={(e, direction, ref, d) => {
          setSidebarWidth(sidebarWidth + d.width);
        }}
      >
        <div
          className="sidebar-scrollbar"
          style={{
            height: `calc(100vh - 230px)`,
            width: "100%",
            overflowY: isExpanded ? "auto" : "hidden",
            overflowX: "hidden",
            background: "#FAF9F8",
            transition: "width 0.2s linear",
            willChange: "width",
            paddingBottom: 15,
          }}
        >
          <Stack
            horizontal
            verticalAlign="center"
            style={{
              display: "grid",
              gridTemplateColumns: "36px 1fr",
              padding: "0 9px",
              background: "#FAF9F8",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <IconButton
              iconProps={hamburgerIcon}
              style={{ padding: 0, color: "#2C529F", width: "100%" }}
              onClick={toggle}
            />
          </Stack>
          <div
            style={{
              opacity: isExpanded ? 1 : 0,
              pointerEvents: isExpanded ? "auto" : "none",
              transition: "opacity 0.2s",
              marginTop: 10,
              transitionDelay: isExpanded ? "0.15s" : "0s",
            }}
          >
            {/*{children}*/}
            <div className="ws-sidebar-tree">
              <WSSidebar />
            </div>
          </div>
        </div>
        <Separator />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70px",
          }}
        >
          <DefaultButton
            text={t("Errors / Incidents")}
            onClick={() => navigate(`/sensor-configurator/errors-incidents`)}
          />
        </div>
      </Resizable>
    </Stack>
  );
};

export default SideBar;
