import { ComboBox, IComboBox, IComboBoxOption } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type ControlledComboBoxProps = {
  options: IComboBoxOption[];
  selectedKey: string;
  disabled: boolean;
  label?: string;
  required?: boolean;
  onKeySelected: (key: string) => void;
};

/**
 * Gets a controlled combo box component.
 * @param options The combo box available options.
 * @param initialKey The initial key.
 * @param disabled Value indicating whether the combo box must be disabled.
 * @param onKeySelected Function called when a new key is selected.
 * @returns The controlled combo box component
 */
const ControlledComboBox = ({
  options,
  selectedKey,
  disabled,
  label,
  required,
  onKeySelected,
}: ControlledComboBoxProps) => {
  const { t } = useTranslation();
  const [currentKey, setCurrentKey] = useState<string>(selectedKey);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // Sets the selected key when the initial key is changed.
  useEffect(() => {
    setCurrentKey(selectedKey ?? "");
  }, [selectedKey]);

  const onChange = (
    _event: React.FormEvent<IComboBox>,
    option: IComboBoxOption
  ) => {
    if (option) {
      setCurrentKey(option.key.toString());
      setErrorMessage("");
      onKeySelected?.(option.key.toString());
      return;
    }

    setErrorMessage(!currentKey ? t("Select a valid option.") : "");
  };

  return (
    <ComboBox
      label={label}
      options={options}
      allowFreeform
      autoComplete="on"
      disabled={disabled}
      placeholder={t("Select an option...")}
      useComboBoxAsMenuWidth
      selectedKey={currentKey}
      errorMessage={errorMessage}
      onChange={onChange}
      required
    />
  );
};

export default ControlledComboBox;
