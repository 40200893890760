import { CommandBarButton, Stack } from "@fluentui/react";
import { CSSProperties } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const buttonStyle: CSSProperties = {
  textAlign: "start",
  width: "100%",
  padding: "0.5em 0.2em 0.5em 0em",
};

export const UserMenu = ({
  handleDismiss,
}: {
  handleDismiss: (value) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Stack.Item>
        <CommandBarButton
          iconProps={{ iconName: "Settings" }}
          text={t("Settings")}
          onClick={() => {
            navigate("settings");
            handleDismiss(false);
          }}
          style={buttonStyle}
        />
      </Stack.Item>
      <Stack.Item>
        <CommandBarButton
          iconProps={{ iconName: "SignOut" }}
          text={t("Sign out")}
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
          style={buttonStyle}
        />
      </Stack.Item>
    </Stack>
  );
};
