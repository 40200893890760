import type { IBasePickerSuggestionsProps, ITag } from "@fluentui/react";
import { Stack, TagPicker } from "@fluentui/react";
import { useMemo } from "react";

import { useLocationSearch } from "../../../../../Hooks";

import { useTagsQuery } from "../../hooks/useTagsQuery";
import useTagsStore from "../../hooks/useTagsStore";
import { useTranslation } from "react-i18next";

export default function SearchByTags({ ...rest }) {
  const { t } = useTranslation();
  const [{ id: machineId }, ,] = useLocationSearch();
  const { tags } = useTagsQuery({ machineId: machineId as string });
  const { selectedTags, updateStore } = useTagsStore((store: any) => ({
    selectedTags: store.selectedTags,
    updateStore: store.updateStore,
  }));

  const options = useMemo(
    () => tags.map((tag) => ({ key: tag?.name, name: tag?.name })),
    [tags.length]
  );

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t("Suggested tags"),
    noResultsFoundText: t("No tags found"),
  };

  const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  const onResolveSuggestions = (
    filterText: string,
    tagList?: ITag[]
  ): ITag[] => {
    return filterText
      ? options.filter(
          (tag) =>
            tag.name.includes(filterText) && !listContainsTagList(tag, tagList)
        )
      : [];
  };

  const onEmptyResolveSuggestions = (selectedTags?: ITag[]) => {
    if (selectedTags?.length) {
      const tagsName = selectedTags?.map(({ name }) => name);

      return options.filter(({ name }) => !tagsName?.includes(name));
    }

    return options;
  };

  const onChange = (items?: ITag[] | undefined) => {
    updateStore({ selectedTags: items });
  };

  return (
    <Stack verticalAlign="center" {...rest}>
      <TagPicker
        pickerSuggestionsProps={pickerSuggestionsProps}
        inputProps={{
          placeholder: selectedTags?.length ? "" : t("Pick a tag"),
        }}
        selectedItems={selectedTags}
        onEmptyResolveSuggestions={onEmptyResolveSuggestions}
        onChange={onChange}
        onResolveSuggestions={onResolveSuggestions}
      />
    </Stack>
  );
}
