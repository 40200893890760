/* eslint-disable no-var */
import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import linspace from "linspace";

import { apiService } from "../../modules/common";

interface UseShortTrendRawDataPointsForOneDataPointAsZipArgs {
  signalId: string;
  dataPointId: string;
}

interface UseShortTrendRawDataPointsForOneDataPointAsZip {
  data: {
    date: number;
    value: number;
  }[];
}

type UseShortTrendRawDataPointsForOneDataPointAsZipOptions =
  | (Omit<
      UseQueryOptions<
        UseShortTrendRawDataPointsForOneDataPointAsZip,
        unknown,
        UseShortTrendRawDataPointsForOneDataPointAsZip,
        string[]
      >,
      "initialData" | "queryFn" | "queryKey"
    > & { initialData?: (() => undefined) | undefined })
  | undefined;

const useShortTrendRawDataPointsForOneDataPointAsZip = (
  args: UseShortTrendRawDataPointsForOneDataPointAsZipArgs,
  options?: UseShortTrendRawDataPointsForOneDataPointAsZipOptions
) => {
  return useQuery(
    [
      "short-trend-raw-data-points-for-one-data-point-as-zip",
      args.signalId,
      args.dataPointId,
    ],
    () => {
      const getData =
        async (): Promise<UseShortTrendRawDataPointsForOneDataPointAsZip> => {
          const data =
            await apiService.measuredDataRead.getShortTrendRawDataPointsForOneDataPointAsZip(
              args
            );

          const floatValues = linspace(
            0,
            (data?.dataPoints?.length * 1) / data?.sampleRateInHz,
            data?.dataPoints?.length
          );

          const formattedData =
            data?.dataPoints?.map(
              (datapoint: number, index: string | number) => ({
                date: floatValues[index],
                value: datapoint,
              })
            ) || [];

          return {
            data: formattedData,
          };
        };
      return getData();
    },
    options
  );
};

export default useShortTrendRawDataPointsForOneDataPointAsZip;
