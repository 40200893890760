/* eslint-disable jsx-a11y/click-events-have-key-events */
import type {
  IButtonProps,
  IButtonStyles,
  IRenderFunction,
  ITooltipHostStyles,
} from "@fluentui/react";
import {
  DefaultButton,
  Icon,
  IconButton,
  Spinner,
  TooltipHost,
} from "@fluentui/react";

import type { CSSProperties } from "react";
import React, { useCallback, useId } from "react";
import type { DefaultNodeProps, useTreeState } from "react-hyper-tree";

import CheckboxEmptyIcon from "../../../../assets/svg/CheckboxEmptyIcon";
import CheckboxFullIcon from "../../../../assets/svg/CheckboxFullIcon";
import ClearIcon from "../../../../assets/svg/ClearIcon";
import FastTrendSignalIcon from "../../../../assets/svg/FastTrendSignalIcon";
import NoDataIcon from "../../../../assets/svg/NoDataIcon";
import RawDataSignalIcon from "../../../../assets/svg/RawDataSignalIcon";
import SearchIcon from "../../../../assets/svg/SearchIcon";
import TrendSignalIcon from "../../../../assets/svg/TrendSignalIcon";
import SignalsAutocomplete from "../../../common/components/SignalsAutocomplete";
import DataTypeEnum from "../../../common/constants/DataTypeEnum";
import { formatNode } from "../../../common/utils/signalFormatter";
import type { ResponseSimplifiedSignal } from "../../../../types";

import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import useCustomSelectNode from "./useCustomSelectNode";

import { useTranslation } from "react-i18next";

const ClearAllIcon = () => <ClearIcon />;

// Fix for the tooltip not showing on the node label for safari.
const labelStyles: React.CSSProperties = {
  visibility: "visible",
};

const eraseButtonStyles: IButtonStyles = {
  root: {
    color: "#000000",
    padding: 0,
    margin: 0,
  },
  rootHovered: {
    background: "transparent",
  },
  rootDisabled: {
    background: "transparent",
  },
};

const buttonStyles: CSSProperties = {
  background: "transparent",
  border: "unset",
  minWidth: 30,
  padding: 0,
};

const calloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: "inline-block" },
};

interface CustomSelectNodeProps extends DefaultNodeProps {
  handlers: ReturnType<typeof useTreeState>["handlers"];
}

const CustomSelectNode: React.FC<CustomSelectNodeProps> = ({
  node,
  onToggle,
  onSelect,
  handlers,
  ...props
}) => {
  const {
    showEmptyIcon,
    showFullIcon,
    showCloseIcon,
    showOpenIcon,
    handleSelect,
    handleToggle,
    handleItemClick,
    handleItemSelect,
  } = useCustomSelectNode({ node, onToggle, onSelect, handlers, ...props });

  const { t } = useTranslation();

  const { removeSelectedSignal } = useSelectedSignalsStore((state) => ({
    removeSelectedSignal: state.removeSelectedSignal,
  }));

  const tooltipId = useId();

  const clearSelectedSignals = () => {
    node.getChildren().forEach((child) => {
      handlers.setSelected(child.id, false);
      removeSelectedSignal(child as unknown as ResponseSimplifiedSignal);

      if (child?.getChildren) {
        const subChildren = child.getChildren() || [];
        subChildren.forEach((subChild) => {
          handlers.setSelected(subChild.id, false);
          removeSelectedSignal(subChild as unknown as ResponseSimplifiedSignal);
        });
      }
    });
  };

  const renderName = () => {
    const label = formatNode(node);

    if (!node.options.leaf) {
      return <span>{label}</span>;
    }

    return (
      <TooltipHost
        id={tooltipId}
        content={label}
        calloutProps={calloutProps}
        styles={hostStyles}
      >
        <span style={labelStyles}>{label}</span>
      </TooltipHost>
    );
  };

  const getNodeOptions = useCallback(() => {
    let options: any = [];

    const children = node?.getChildren ? node.getChildren() : [];

    children.forEach((item) => {
      const subChildren = item?.getChildren ? item?.getChildren() || [] : [];
      if (subChildren.length > 0) {
        options = [...options, ...subChildren];
      } else {
        options = [...options, item];
      }
    });

    return options
      .filter(
        (child: any) =>
          child.getData().dataType !== DataTypeEnum.RawData &&
          !child.data.groupedNodeParent
      )
      .map((child: any) => ({
        ...child,
        id: child.getData().id,
        name: child.getData().name,
        label: formatNode(child),
        icon: child.getData().dataType,
      }));
  }, [node]);

  const checkClearCheckboxAvailable = useCallback(() => {
    return !!(
      node?.getChildren &&
      node.getChildren().find((child) => {
        if (child.isSelected()) {
          return true;
        }

        if (child?.getChildren && child.getChildren().length > 0) {
          return child.getChildren().find((subChild) => {
            return subChild?.isSelected && subChild?.isSelected();
          });
        }

        return false;
      })
    );
  }, [node]);

  return (
    <div
      data-node-id={node.id}
      style={{
        display:
          node.options.leaf && node.data.dataType === "ShortTerm"
            ? "none"
            : "block",
      }}
    >
      <div key={node.data.title} className="tree-node raw-data-signals-sidebar">
        {showFullIcon && (
          <DefaultButton style={buttonStyles} onClick={handleSelect}>
            <CheckboxFullIcon />
          </DefaultButton>
        )}
        {showEmptyIcon && (
          <DefaultButton style={buttonStyles} onClick={handleSelect}>
            <CheckboxEmptyIcon />
          </DefaultButton>
        )}
        <div
          className="node-content-wrapper"
          role="button"
          tabIndex={0}
          style={{ cursor: "pointer" }}
          onClick={handleItemClick}
        >
          <div className="titles" role="button" tabIndex={0}>
            {node.getData().dataType === DataTypeEnum.Trend && (
              <TrendSignalIcon style={{ marginRight: 10, minWidth: 17 }} />
            )}
            {node.getData().dataType === DataTypeEnum.FastTrend && (
              <FastTrendSignalIcon style={{ marginRight: 10, minWidth: 17 }} />
            )}
            {node.getData().dataType === DataTypeEnum.RawData && (
              <RawDataSignalIcon style={{ marginRight: 10, minWidth: 17 }} />
            )}
            <div className="node-title">{renderName()}</div>
          </div>
          {node.isLoading() && <Spinner />}
          {node.asyncNode &&
            node.asyncDataLoaded &&
            (node.getChildren().length > 0 ? (
              <div>
                <IconButton
                  title={t("Clear Selected Signals")}
                  ariaLabel={t("Clear Signals")}
                  style={{
                    marginRight: 4,
                    filter: checkClearCheckboxAvailable()
                      ? "none"
                      : "grayscale(1)",
                    opacity: checkClearCheckboxAvailable() ? 1 : 0.7,
                  }}
                  styles={eraseButtonStyles}
                  disabled={!checkClearCheckboxAvailable()}
                  onRenderIcon={ClearAllIcon as IRenderFunction<IButtonProps>}
                  onClick={(e) => {
                    e.stopPropagation();
                    clearSelectedSignals();
                  }}
                />
                <SignalsAutocomplete
                  items={getNodeOptions()}
                  selectedItems={(getNodeOptions() || []).filter(
                    (item: any) => item.options.selected
                  )}
                  onSelectSignal={(signal) => {
                    if (signal) {
                      handleItemSelect(signal.id);
                    }
                  }}
                  onRenderMenuIcon={() => <SearchIcon />}
                />
              </div>
            ) : (
              <TooltipHost
                id={tooltipId}
                content={t("No data")}
                calloutProps={calloutProps}
                styles={hostStyles}
              >
                <DefaultButton disabled style={buttonStyles}>
                  <NoDataIcon />
                </DefaultButton>
              </TooltipHost>
            ))}
        </div>

        {showCloseIcon && (
          <DefaultButton style={buttonStyles} onClick={handleToggle}>
            <Icon iconName="ChevronUp" />
          </DefaultButton>
        )}
        {showOpenIcon && (
          <DefaultButton style={buttonStyles} onClick={handleToggle}>
            <Icon iconName="ChevronDown" />
          </DefaultButton>
        )}
      </div>
    </div>
  );
};

export default CustomSelectNode;
