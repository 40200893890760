import {
  Callout,
  DirectionalHint,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  StackItem,
} from "@fluentui/react";

import { useMemo, useState } from "react";
import {
  FormItemProps,
  renderFormItems,
  useZodForm,
} from "../../../../common/Form";
import { useAppDispatch, useAppSelector } from "../../../../../Hooks";
import { selectUserSettings } from "../../../../Settings/selectors";
import { z } from "zod";
import {
  dashboardSettingsFieldsSchema,
  getDashboardSettingsformItems,
} from "../../../../Settings/Sections/Dashboard/Dashboard";
import { FieldError } from "react-hook-form";
import { settingsUpdateUsersRBAC } from "../../../../Settings/api";
import { updateUserSettingsSlice } from "../../../../Settings/reducer";
import { notification } from "../../../../common/Notification";
import { selectUserAccountMemberID } from "../../../../UserAccount/selectors";
import { getRowsPerTableDropdownField } from "../../../../Settings/Sections/List/List";
import { useTranslation } from "react-i18next";

const BUTTON_ID = "menu-button";

const getFormItems = (t) => [
  ...getDashboardSettingsformItems(t),
  {
    ...getRowsPerTableDropdownField(t),
    groupProps: {
      ...getRowsPerTableDropdownField(t).groupProps,
      labelProps: {
        style: {
          fontWeight: 600,
          display: "flex",
          alignItems: "center",
        },
      },
      stackProps: {
        styles: {
          root: {
            flexDirection: "row",
            marginBottom: 0,
            ".ms-ComboBox-container": {
              maxWidth: "96px",
            },
          },
        },
      },
    },
  },
];

const getSchema = () =>
  z.object({
    ...dashboardSettingsFieldsSchema,
    rowsPerTable: z.number(),
  });

export default function QuickSettings() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectUserSettings);

  const userId = useAppSelector(selectUserAccountMemberID);

  const schema = useMemo(() => getSchema(), []);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
  } = useZodForm({
    mode: "onChange",
    schema,
    values: {
      ...settings?.dashboardSettings,
      rowsPerTable: settings?.list?.rowsPerTable,
    },
  });

  const onSubmit = handleSubmit(async (data: any) => {
    if (!data) return;

    const { rowsPerTable, ...dashboardSettings } = data;

    const payload = {
      ...settings,
      dashboardSettings,
      list: {
        ...settings.list,
        rowsPerTable,
      },
    };

    setIsLoading(true);

    await settingsUpdateUsersRBAC(userId, payload)
      .then((response) => {
        if (response) {
          dispatch(updateUserSettingsSlice(response));

          setIsLoading(false);

          notification.success(t("Settings updated successfully!"));
        }
      })
      .catch(() => {
        notification.warning(
          t("Something went wrong, please refresh the page. And try it again.")
        );
      });
  });

  function closeMenu() {
    setIsVisible(false);
  }

  return (
    <div>
      <PrimaryButton
        text={t("Quick Settings")}
        style={{ padding: "5px 12px" }}
        iconProps={{ iconName: "Settings" }}
        id={BUTTON_ID}
        onClick={() => setIsVisible(!isVisible)}
      />

      {isVisible ? (
        <Callout
          role="dialog"
          directionalHint={DirectionalHint.bottomRightEdge}
          target={`#${BUTTON_ID}`}
          onDismiss={closeMenu}
          beakWidth={0}
          styles={{
            root: {
              padding: "4px",
              width: "280px",
            },
          }}
        >
          <form onSubmit={onSubmit}>
            <div className="form-items-container">
              {renderFormItems(getFormItems(t) as FormItemProps[], {
                control,
                errors: errors as { [schemaProp: string]: FieldError },
              }).map((element) => (
                <StackItem
                  styles={{
                    root: {
                      padding: "8px",
                      "> .ms-Stack": { margin: "0 !important" },
                    },
                  }}
                  key={element.key}
                >
                  {element}
                </StackItem>
              ))}
            </div>

            <div style={{ padding: "8px" }}>
              <PrimaryButton
                type="submit"
                text={t("Apply Settings")}
                style={{ width: "100%" }}
                disabled={!isDirty || isLoading}
                onRenderIcon={() =>
                  isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                }
              />
            </div>
          </form>
        </Callout>
      ) : null}
    </div>
  );
}
