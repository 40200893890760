import { Stack } from "@fluentui/react";
import Panel from "./components/Panel";

import { useMachineConditionsTable } from "../../../Machines/useMachineConditionsTable";
import { useTranslation } from "react-i18next";

const MachinesPanel = () => {
  const { machines } = useMachineConditionsTable();

  return (
    <Stack
      className="machines-number"
      verticalAlign="center"
      horizontalAlign="center"
      styles={{
        root: {
          flexGrow: 1,
        },
      }}
    >
      {machines?.length || "0"}
    </Stack>
  );
};

export default function Machines() {
  const { t } = useTranslation();

  return (
    <Panel
      title={t("Machines")}
      subTitle={t("Total monitored machines")}
      content={<MachinesPanel />}
      style={{ minWidth: "320px" }}
    />
  );
}
