/* eslint-disable react-hooks/exhaustive-deps */
import {
  ILabelStyles,
  IStackProps,
  IStackStyles,
  Label,
  Stack,
  Text,
} from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import { notification } from "../../../common/Notification";
import { ConfirmDialog } from "../../../Generic/ConfirmDialog";
import { HaagMappingApi } from "./api";
import {
  HaagSignalMapping,
  OpcUaAccessMode,
  OpcUaDataType,
  SignalSource,
} from "./models";
import { useTranslation } from "react-i18next";

type PropertyRowProps = Omit<IStackProps, "horizontal"> & {
  label: string;
  value: string;
};

type HaagMappingDeleteDialogProps = {
  machineId: string;
  item: HaagSignalMapping;
  onClose: (refresh: boolean) => void;
};

const PropertyRow = ({ label, value, ...rest }: PropertyRowProps) => {
  const containerStyles: IStackStyles = useMemo(() => {
    return { root: { columnGap: "0.5em" } };
  }, []);

  const labelStyles: ILabelStyles = useMemo(() => {
    return { root: { minWidth: "10em", fontSize: "14px" } };
  }, []);

  const textId: string = useMemo(() => {
    return `haag-property-${label?.toLocaleLowerCase()}`;
  }, [label]);

  return (
    <Stack {...rest} horizontal styles={containerStyles}>
      <Label htmlFor={textId} styles={labelStyles}>{`${label}:`}</Label>
      <Text id={textId} variant="medium" as="span">
        {value}
      </Text>
    </Stack>
  );
};

const HaagMappingDeleteDialog = ({
  machineId,
  item,
  onClose,
}: HaagMappingDeleteDialogProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Deletes the HAAG Mapping
  useEffect(() => {
    if (!isLoading || !machineId) {
      return;
    }

    let mount = true;
    HaagMappingApi.delete(machineId, item.id).then((response) => {
      if (!mount) {
        return;
      }

      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          response["text"]?.message
            ? t(`Error deleting the HAAG mapping item: {{errorMessage}}`, {
                errorMessage: response["text"]?.message,
              })
            : t(`Error deleting the HAAG mapping item: Unknown error`)
        );
        return;
      }

      notification.success(t("The HAAG mapping item was successfully deleted"));
      onClose?.(true);
    });
  }, [isLoading, machineId]);

  return (
    <ConfirmDialog
      title={t("Delete HAAG Mapping")}
      isLoading={isLoading}
      onConfirm={() => setIsLoading(true)}
      onDismiss={() => onClose(false)}
    >
      <Stack styles={{ root: { rowGap: "1em" } }}>
        <Text variant="medium" as="p">
          {t("The following datalogger will be permanently deleted:")}
        </Text>
        <Stack styles={{ root: { paddingLeft: "1em" } }}>
          <PropertyRow
            key={`haag-${item.id}-source`}
            label={t("Source")}
            value={SignalSource[item.source]}
          />
          <PropertyRow
            key={`haag-${item.id}-source-identifier`}
            label={t("Source Identifier")}
            value={item.sourceIdentifier}
          />
          <PropertyRow
            key={`haag-${item.id}-device-name`}
            label={t("Device Name")}
            value={item.deviceName}
          />
          <PropertyRow
            key={`haag-${item.id}-component-name`}
            label={t("Component Name")}
            value={item.componentName}
          />
          <PropertyRow
            key={`haag-${item.id}-metric-name`}
            label={t("Metric Name")}
            value={item.metricName}
          />
          <PropertyRow
            key={`haag-${item.id}-node-id`}
            label={t("Node ID")}
            value={item.nodeId}
          />
          <PropertyRow
            key={`haag-${item.id}-access-mode`}
            label={t("Access Mode")}
            value={OpcUaAccessMode[item.accessMode]}
          />
          <PropertyRow
            key={`haag-${item.id}-data-type`}
            label={t("Data Type")}
            value={OpcUaDataType[item.dataType]}
          />
        </Stack>
        <Text variant="medium" as="p">
          {t("Do you want to continue?")}
        </Text>
      </Stack>
    </ConfirmDialog>
  );
};

export default HaagMappingDeleteDialog;
