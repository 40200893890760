import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { FontIcon, mergeStyles } from "@fluentui/react";
import { isEmpty, isEqual } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { useMeasure } from "react-use";

import { usePrevious, useUniqueId } from "../../../../Hooks";
import useSpectrumStore from "./useSpectrumStore";
import useLayoutTypes from "../../hooks/useLayoutTypes";
import { format as signalFormatter } from "../../../common/utils/signalFormatter";
import type { XYChartRef } from "../../../../Components/common/XYChart";
import { XYChart } from "../../../../Components/common/XYChart";
import {
  createChartExport,
  updateExportButtonText,
} from "../../../../Components/common/XYChart/export";
import { createDefaultTooltip } from "../../../../Components/common/XYChart/tooltip";

import DalogLogo from "../../../../assets/images/dalog-logo.svg";
import { watermarkLogos } from "../../constants/chartWatermarkLogos";
import useChartLabels from "../../hooks/useChartLabels";
import useHarmonicsStoreV0 from "../../hooks/useHarmonicsStoreV0";
import useSignalMetaData from "../../hooks/useSignalMetaData";
import ContextMenu from "../ContextMenu";
import { useCatalogueStore } from "../FrequencyCatalogueDialog/hooks";
import {
  getHarmonicPositions,
  getHarmonicsKey,
  getSidebandPositions,
  getSidebandsFrequency,
  getSidebandsKey,
  removeAxisRanges,
} from "./core";
import { harmonicsInitialState } from "./harmonicsReducer";
import { sideband } from "./sideband";

import { useTranslation } from "react-i18next";

const chartColor = "#2C529F";

const X_AXIS_LABEL_KEY = "Frequency (Hz)";

const SpectrumChart = ({ data, exportId, signal, isInModal }: any) => {
  const { t } = useTranslation();
  const ref = useRef<XYChartRef | null>(null);
  const [contentRef] = useMeasure<HTMLDivElement>();
  const rootRef = useRef<am5.Root | null>(null);
  const chartRef = useRef<am5xy.XYChart | null>(null);
  const xAxisRef = useRef<am5xy.ValueAxis<am5xy.AxisRenderer> | null>(null);
  const yAxisRef = useRef<am5xy.ValueAxis<am5xy.AxisRenderer> | null>(null);
  const seriesRef = useRef<am5xy.LineSeries | null>(null);

  const { setSelectedSignalValues } = useCatalogueStore((store: any) => ({
    setSelectedSignalValues: store.setSelectedSignalValues,
  }));

  const { corporation, company, project, machine } = useSignalMetaData(signal);

  const [cursorClickXValue, setCursorClickXValue] = useState(0);

  const { machines, updateHarmonics } = useHarmonicsStoreV0();

  const machineState = machines[signal.machineId] || {
    harmonicsOne: harmonicsInitialState,
    harmonicsTwo: harmonicsInitialState,
    harmonicsThree: harmonicsInitialState,
  };

  const { harmonicsOne, harmonicsTwo, harmonicsThree } = machineState;

  const prevHarmonicsOne = usePrevious(harmonicsOne);

  const prevHarmonicsTwo = usePrevious(harmonicsTwo);

  const prevHarmonicsThree = usePrevious(harmonicsThree);

  const { isWaveformLayout } = useLayoutTypes();

  const {
    setScrollbarY,
    getScrollbarY,
    setScrollbarX,
    getScrollbarX,
    setHarmonics,
    getHarmonics,
  } = useSpectrumStore((state) => ({
    setScrollbarY: state.setScrollbarY,
    getScrollbarY: state.getScrollbarY,
    setScrollbarX: state.setScrollbarX,
    getScrollbarX: state.getScrollbarX,
    setHarmonics: state.setHarmonics,
    getHarmonics: state.getHarmonics,
  }));

  useEffect(() => {
    if (isWaveformLayout && !isEqual(harmonicsOne, prevHarmonicsOne)) {
      setHarmonics({ harmonicsOne });
    }
  }, [harmonicsOne]);

  useEffect(() => {
    if (isWaveformLayout && !isEqual(harmonicsTwo, prevHarmonicsTwo)) {
      setHarmonics({ harmonicsTwo });
    }
  }, [harmonicsTwo]);

  useEffect(() => {
    if (isWaveformLayout && !isEqual(harmonicsThree, prevHarmonicsThree)) {
      setHarmonics({ harmonicsThree });
    }
  }, [harmonicsThree]);

  const shouldShowChartLabels = useChartLabels();

  const uniqueId = useUniqueId();

  const iconClass = mergeStyles({
    lineHeight: 1,
    fontSize: 17,
    marginRight: 10,
  });

  const chartModalStyle = isInModal
    ? {
        root: {
          height: "100%",
        },
        chart: {
          height: "100%",
        },
      }
    : {};

  const setAxisStartEndFromStore = (
    chartScrollbarX: any,
    chartScrollbarY: any
  ) => {
    const { start: xStart, end: xEnd } = getScrollbarX();
    const { start: yStart, end: yEnd } = getScrollbarY();

    chartScrollbarX?.setAll({
      start: xStart,
      end: xEnd,
    });

    chartScrollbarY?.setAll({
      start: yStart,
      end: yEnd,
    });
  };

  useEffect(() => {
    if (!ref.current) return;

    const root = ref.current.root;
    const chart = ref.current.chart;

    // === Sending grid lines behind the chart series lines ===

    chart.gridContainer.toBack();

    // === Setting 0 basegrid line opacity ===

    const myTheme = am5.Theme.new(root);

    myTheme.rule("Grid", ["base"]).setAll({
      strokeOpacity: 0.1,
    });

    root.setThemes([myTheme]);

    // === X Axis ===

    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        maxDeviation: 0,
        strictMinMax: true,
        extraTooltipPrecision: 2,
        marginTop: 16,
        tooltip: am5.Tooltip.new(root, {
          labelText: undefined,
          forceHidden: true,
          animationDuration: 0,
        }),
      })
    );

    // === X Axis Label ===

    xAxis.children.push(
      am5.Label.new(root, {
        text: t(X_AXIS_LABEL_KEY),
        x: am5.p50,
        centerX: am5.p50,
        fontSize: 10,
      })
    );

    // === X Axis grid opacity ===

    const xRenderer = xAxis.get("renderer");

    xRenderer.grid.template.setAll({
      strokeOpacity: 0.04,
    });

    xRenderer.labels.template.set("fontSize", 10);

    // === Y Axis ===

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        maxDeviation: 0,
        strictMinMax: true,
        marginRight: 16,
        extraTooltipPrecision: 2,
        tooltip: am5.Tooltip.new(root, {
          labelText: undefined,
          forceHidden: true,
          animationDuration: 0,
        }),
      })
    );

    // === Y Axis Label ===

    yAxis.children.unshift(
      am5.Label.new(root, {
        ariaLabel: "yAxisLabel",
        ariaValueText: `${signal.unit}`,
        rotation: -90,
        text: `${signalFormatter(signal)} (${signal.unit})`,
        y: am5.p50,
        centerX: am5.p50,
        fill: am5.color(chartColor),
        fontSize: 10,
      })
    );

    // === Y Axis Label Color ===

    const yRenderer = yAxis.get("renderer");

    yRenderer.labels.template.setAll({
      fill: am5.color(chartColor),
      fontSize: 10,
    });

    yRenderer.grid.template.setAll({
      strokeOpacity: 0.04,
    });

    // === Series ===

    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        minBulletDistance: 10,
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "a",
        valueXField: "f",
        stroke: am5.color(chartColor),
        tooltip: am5.Tooltip.new(root, {
          labelText: undefined,
          forceHidden: true,
          animationDuration: 0,
        }),
      })
    );

    // START: set axis start and end from spectrum store
    if (isWaveformLayout) {
      const chartScrollbarX = chart.get("scrollbarX");
      const chartScrollbarY = chart.get("scrollbarY");

      setAxisStartEndFromStore(chartScrollbarX, chartScrollbarY);

      chartScrollbarX?.on("start", (start) => setScrollbarX({ start }));
      chartScrollbarX?.on("end", (end) => setScrollbarX({ end }));

      chartScrollbarY?.events.on("rangechanged", (ev) => {
        setScrollbarY({ start: ev.start, end: ev.end });
      });

      series.events.on("datavalidated", function () {
        setAxisStartEndFromStore(chartScrollbarX, chartScrollbarY);
        const { harmonicsOne, harmonicsTwo, harmonicsThree } = getHarmonics();

        if (!isEmpty(harmonicsOne)) {
          updateHarmonics(signal.machineId, "harmonicsOne", {
            type: "RESET",
            payload: harmonicsOne,
          });
        }

        if (!isEmpty(harmonicsTwo)) {
          updateHarmonics(signal.machineId, "harmonicsTwo", {
            type: "RESET",
            payload: harmonicsTwo,
          });
        }

        if (!isEmpty(harmonicsThree)) {
          updateHarmonics(signal.machineId, "harmonicsThree", {
            type: "RESET",
            payload: harmonicsThree,
          });
        }
      });

      chart.zoomOutButton.events.on("click", () => {
        setScrollbarY({ start: 0 });
        setScrollbarY({ end: 1 });
      });
    }
    // END: set axis start and end from spectrum store

    series.bullets.push(() => {
      const circle = am5.Circle.new(root, {
        radius: 4,
        fill: root.interfaceColors.get("background"),
        stroke: series.get("fill"),
        strokeWidth: 2,
      });

      return am5.Bullet.new(root, {
        sprite: circle,
      });
    });

    // === Footer Label ===

    const breadcrumbRoot = am5.Root.new(
      `breadcrumb-container-${machine.id}-${uniqueId}`
    );
    const breadcrumbContainer = document.getElementById(
      `breadcrumb-container-${machine.id}-${uniqueId}`
    );

    const breadcrumb = breadcrumbRoot.container.children.push(
      am5.Label.new(breadcrumbRoot, {
        html: `
        <span style='font-size: 12px;color: #878583;'>
          ${corporation?.name}  〉 ${company?.name}  〉 ${project?.name} 〉 ${
            machine?.name
          }  〉 <strong style='color: #333;'>${signalFormatter(signal)}</strong>
        </span>`,
        width: am5.percent(100),
        fill: am5.color("#878583"),
        paddingTop: 26,
      })
    );

    breadcrumb.events.on("boundschanged", () => {
      if (breadcrumbContainer) {
        breadcrumbContainer.style.height = breadcrumb.height() + "px";
      }
    });

    watermarkLogos.forEach((logoData) => {
      const logo = am5.Picture.new(root, {
        src: DalogLogo,
        height: logoData.height,
        opacity: logoData.opacity,
        x: logoData.x,
        y: logoData.y,
        centerX: logoData.centerX,
        centerY: logoData.centerY,
      });

      chart.plotContainer.children.push(logo);
    });

    root.dom.addEventListener("contextmenu", (ev) => {
      ev.preventDefault();

      // === Get cursor position value
      const cursor = chart.get("cursor");
      const selectStatedX = cursor?.getPrivate("positionX") as number;
      const xValue = xAxis.positionToValue(xAxis.toAxisPosition(selectStatedX));
      setCursorClickXValue(xValue);
    });

    createDefaultTooltip({
      ref: ref.current,
      text: "Hz",
    });

    const cursor = chart.get("cursor");

    cursor?.set("snapToSeries", [series]); //TODO: snapToSeries: [series, series2], cursor snap to all series
    cursor?.set("snapToSeriesBy", "y!");
    cursor?.set("xAxis", xAxis);

    createChartExport({
      root,
      chart,
      signal,
      selector: exportId,
      corporation: corporation?.name,
      company: company?.name,
      project: project?.name,
      machine: machine?.name,
    });

    rootRef.current = root;
    chartRef.current = chart;
    xAxisRef.current = xAxis;
    yAxisRef.current = yAxis;
    seriesRef.current = series;

    return () => {
      breadcrumbRoot.dispose();
      ref.current = null;
    };
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;

    const series = chartRef.current.series.getIndex(0);

    if (Object.keys(data).length > 0) {
      series?.data.setAll(data?.values);
    } else {
      series?.data.setAll([]);
    }

    // filter out 0 values
    series?.dataItems.forEach((dataItem) => {
      if (dataItem.get("valueY") === 0) {
        dataItem.hide();
      }
    });

    const modal = am5.Modal.new(rootRef.current as am5.Root, {
      content: `<div class="loader">${t("Loading...")}.</div>
      <div class="loader-label">${t("Loading chart...")}</div>
      <div class="loader-sublabel">${t("loading time depends on the amount of data")}</div>
      `,
    });

    modal.getPrivate("wrapper").classList.add("chart-modal");
    modal.getPrivate("content").classList.add("chart-modal--content");

    series?.events.on("datavalidated", function (ev) {
      ev.target.data.length < 1 ? modal.open() : modal.close();
    });
  }, [data]);

  const clearHarmonics = () => {
    if (!xAxisRef.current) return;

    const xAxis = xAxisRef.current;
    const axisRanges = xAxis.axisRanges;

    axisRanges.clear();

    updateHarmonics(signal.machineId, "harmonicsOne", {
      type: "RESET",
    });
    updateHarmonics(signal.machineId, "harmonicsTwo", {
      type: "RESET",
    });
    updateHarmonics(signal.machineId, "harmonicsThree", {
      type: "RESET",
    });
  };

  const createCustomRanges = ({
    linesN,
    linesF,
    sidebandsN,
    sidebandsF,
    key,
    color,
  }: {
    linesN: number;
    linesF: number;
    sidebandsN: number;
    sidebandsF: number;
    key: string;
    color: string;
  }) => {
    if (
      !rootRef.current ||
      !xAxisRef.current ||
      !seriesRef?.current ||
      !chartRef.current
    )
      return;

    const root = rootRef.current;
    const chart = chartRef.current;
    const xAxis = xAxisRef.current;

    removeAxisRanges({ xAxis, key });

    if (linesF === 0) return;

    const start = 0;

    // === Create Harmonic ===
    // === When the function is called, first we create the initial range that contains the drag handle

    const range = xAxis.createAxisRange(
      xAxis.makeDataItem({
        above: true,
      })
    );

    const rangeLabel = range.get("label");

    rangeLabel?.setAll({
      ariaLabel: getHarmonicsKey(key),
      text: `${(start + linesF).toFixed(2)} / ${sidebandsF}`,
      fill: am5.color(0xffffff),
      fontSize: 10,
      layer: 100,
      inside: true,
      dy: -15,
      background: am5.RoundedRectangle.new(root, {
        fill: am5.Color.fromString(color),
        cornerRadiusBL: 2,
        cornerRadiusBR: 2,
        cornerRadiusTL: 2,
        cornerRadiusTR: 2,
      }),
    });

    range.set("value", start + linesF);

    range.get("grid")?.setAll({
      strokeOpacity: 1,
      stroke: am5.Color.fromString(color),
      strokeWidth: 1,
      layer: 1,
    });

    const rangeButton = am5.Button.new(root, {
      themeTags: ["resize", "horizontal"],
      width: 8,
      height: 16,
      dy: -22,
      background: am5.RoundedRectangle.new(root, {
        fill: am5.Color.fromString(color),
        stroke: am5.Color.fromString(color),
        centerY: am5.p50,
        centerX: am5.p50,
        cornerRadiusBL: 2,
        cornerRadiusBR: 2,
        cornerRadiusTL: 2,
        cornerRadiusTR: 2,
        dx: 0,
        dy: 0,
        layer: 100,
      }),
    });

    // === Restrict from being dragged if it's not the first harmonic

    rangeButton.set("draggable", true);

    rangeButton.toFront();

    // === Restrict from being dragged vertically

    rangeButton.adapters.add("y", () => 0);

    // === Restrict from being dragged outside of plot

    rangeButton.adapters.add(
      "x",
      (x: number | am5.Percent | null | undefined) =>
        Math.max(0.01, Math.min(chart.plotContainer.width(), x as number))
    );

    rangeButton.adapters?.add("y", () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return -1 * chart.plotContainer.getPrivate("height");
    });

    // === On drag, clear all the ranges (harmonics and sidebands) and create new ones

    rangeButton.events.on("dragged", () => {
      const position = xAxis.toAxisPosition(
        rangeButton.x() / chart.plotContainer.width()
      );
      const value = xAxis.positionToValue(position);

      // === Update the range value (if is not set , the line will not move when dragging the handle)
      range.set("value", value);

      // === Update the range label with the current position value
      range.get("label")?.set("text", `${value.toFixed(2)} / ${sidebandsF}`);

      const axisRanges = xAxis.axisRanges;
      const count = axisRanges.length;

      const harmonicPositions = getHarmonicPositions({
        linesNumber: linesN,
        linesFreq: value,
      });

      const sidebandPositions = getSidebandPositions({
        linesNumber: linesN,
        linesFreq: value,
        sidebandsFreq: sidebandsF,
        sidebandsNumber: sidebandsN,
      });

      for (let idx = 0, harmonicIdx = 0, sidebandIdx = 0; idx < count; idx++) {
        const axisRange = axisRanges.getIndex(idx);
        const rangeLabel = axisRange?.get("label")?.get("ariaLabel");

        if (!rangeLabel) continue;

        switch (rangeLabel) {
          case getHarmonicsKey(key): {
            let harmonicValue = harmonicPositions[harmonicIdx];

            // Restrict from being dragged outside of plot and having a value of 0
            harmonicValue = Math.max(harmonicValue, 0.01);

            axisRanges.getIndex(idx)?.set("value", harmonicValue);

            axisRanges
              .getIndex(idx)
              ?.get("label")
              ?.set(
                "text",
                `${harmonicPositions[harmonicIdx].toFixed(2)} / ${sidebandsF}`
              );

            harmonicIdx += 1;

            break;
          }
          case getSidebandsKey(key): {
            axisRanges
              .getIndex(idx)
              ?.set("value", sidebandPositions[sidebandIdx]);

            axisRanges
              .getIndex(idx)
              ?.get("label")
              ?.set("text", sidebandPositions[sidebandIdx].toFixed(2));

            sidebandIdx += 1;

            break;
          }
        }
      }
    });

    // === On drag stop, set the new value of the harmonic to the state

    rangeButton.events.on("dragstop", () => {
      const position = xAxis.toAxisPosition(
        rangeButton.x() / chart.plotContainer.width()
      );
      let value = xAxis.positionToValue(position);

      // check if value is less than 0.1 and set it to 0.1 if it is
      value = Math.max(value, 0.01);

      // key === '1' means that this is the 'Set Harmonics 1' set ... and so on

      if (key === "1") {
        updateHarmonics(signal.machineId, "harmonicsOne", {
          type: "SET_LINES_FREQ",
          payload: value,
        });
      }

      if (key === "2") {
        updateHarmonics(signal.machineId, "harmonicsTwo", {
          type: "SET_LINES_FREQ",
          payload: value,
        });
      }
      if (key === "3") {
        updateHarmonics(signal.machineId, "harmonicsOne", {
          type: "SET_LINES_FREQ",
          payload: value,
        });
        updateHarmonics(signal.machineId, "harmonicsOne", {
          type: "SET_LINES_NUMBER",
          payload: harmonicsThree.linesNumber,
        });
        updateHarmonics(signal.machineId, "harmonicsOne", {
          type: "SET_SIDEBANDS_FREQ",
          payload: harmonicsThree.sidebandsFreq,
        });
        updateHarmonics(signal.machineId, "harmonicsOne", {
          type: "SET_SIDEBANDS_NUMBER",
          payload: harmonicsThree.sidebandsNumber,
        });

        updateHarmonics(signal.machineId, "harmonicsThree", {
          type: "RESET",
        });
      }
    });

    range.set(
      "bullet",
      am5xy.AxisBullet.new(root, {
        location: 0,
        sprite: rangeButton,
      })
    );

    // === On function call create the rest of the harmonics

    createLines({
      linesNumber: linesN,
      linesFreq: linesF,
      sidebandsNumber: sidebandsN,
      sidebandsFreq: sidebandsF,
      color,
      key,
    });
  };

  const createLines = ({
    linesNumber,
    linesFreq,
    sidebandsNumber,
    sidebandsFreq,
    color,
    key,
  }: {
    linesNumber: number;
    linesFreq: number;
    sidebandsNumber: number;
    sidebandsFreq: number;
    color: string;
    key: string;
  }) => {
    if (!rootRef.current || !xAxisRef.current || !chartRef.current) return;

    const root = rootRef.current;
    const chart = chartRef.current;
    const xAxis = xAxisRef.current;

    const harmonicPositions = getHarmonicPositions({ linesNumber, linesFreq });

    if (harmonicPositions.length === 0) return;

    for (let idx = 1; idx < harmonicPositions.length; idx += 1) {
      const range = xAxis.createAxisRange(
        xAxis.makeDataItem({
          above: true,
        })
      );

      const rangeLabel = range.get("label");

      rangeLabel?.setAll({
        ariaLabel: getHarmonicsKey(key),
        text: `${harmonicPositions[idx].toFixed(2)} / ${sidebandsFreq}`,
        fill: am5.Color.fromString("#ffffff"),
        fontSize: 10,
        layer: 100,
        inside: true,
        dy: -15,
        background: am5.RoundedRectangle.new(root, {
          fill: am5.Color.fromString(color),
          cornerRadiusBL: 2,
          cornerRadiusBR: 2,
          cornerRadiusTL: 2,
          cornerRadiusTR: 2,
        }),
      });

      range.set("value", harmonicPositions[idx]);

      // === Set the range line color and width
      range.get("grid")?.setAll({
        strokeOpacity: 1,
        stroke: am5.Color.fromString(color),
        strokeWidth: 1,
        layer: 1,
      });

      const sidebandButton = am5.RoundedRectangle.new(root, {
        fill: am5.Color.fromString(color),
        stroke: am5.Color.fromString(color),
        opacity: 0.8,
        width: 7,
        height: 7,
        centerY: am5.p50,
        centerX: am5.p50,
        cornerRadiusBL: 4,
        cornerRadiusBR: 4,
        cornerRadiusTL: 4,
        cornerRadiusTR: 4,
        dx: 0,
        dy: -20,
        x: am5.p50,
        layer: 1,
      });

      sidebandButton.toFront();

      sidebandButton.adapters.add("y", () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return -1 * chart.plotContainer.getPrivate("height");
      });

      range.set(
        "bullet",
        am5xy.AxisBullet.new(root, {
          sprite: sidebandButton,
        })
      );
    }

    const sidebandPositions = getSidebandPositions({
      linesNumber,
      linesFreq,
      sidebandsNumber,
      sidebandsFreq,
    });

    sidebandPositions.forEach((sidebandPosition) => {
      sideband({
        root,
        chart,
        xAxis,
        color,
        value: sidebandPosition,
        label: getSidebandsKey(key),
      });
    });
  };

  useEffect(() => {
    setSelectedSignalValues(signal.id, {
      chart: {
        xAxisRef,
        rootRef,
        chartRef,
      },
    });
  }, [signal.id, xAxisRef, rootRef, chartRef]);

  // === Create Harmonics 1 ===

  useEffect(() => {
    if (!ref?.current || !seriesRef?.current) return;
    if (!xAxisRef.current) return;

    createCustomRanges({
      linesN: harmonicsOne.linesNumber,
      linesF: harmonicsOne.linesFreq,
      sidebandsN: harmonicsOne.sidebandsNumber,
      sidebandsF: harmonicsOne.sidebandsFreq,
      key: "1",
      color: "#EB3F3D",
    });
  }, [
    harmonicsOne.linesNumber,
    harmonicsOne.linesFreq,
    harmonicsOne.sidebandsNumber,
    harmonicsOne.sidebandsFreq,
  ]);

  // === Create Harmonics 2 ===

  useEffect(() => {
    if (!ref?.current || !seriesRef?.current) return;
    if (!xAxisRef.current) return;

    createCustomRanges({
      linesN: harmonicsTwo.linesNumber,
      linesF: harmonicsTwo.linesFreq,
      sidebandsN: harmonicsTwo.sidebandsNumber,
      sidebandsF: harmonicsTwo.sidebandsFreq,
      key: "2",
      color: "#EB3FEF",
    });
  }, [
    harmonicsTwo.linesNumber,
    harmonicsTwo.linesFreq,
    harmonicsTwo.sidebandsNumber,
    harmonicsTwo.sidebandsFreq,
  ]);

  // === Create Harmonics 3 ===
  useEffect(() => {
    if (!ref?.current || !seriesRef?.current) return;
    if (!xAxisRef.current) return;

    createCustomRanges({
      linesN: harmonicsThree.linesNumber,
      linesF: harmonicsThree.linesFreq,
      sidebandsN: harmonicsThree.sidebandsNumber,
      sidebandsF: harmonicsThree.sidebandsFreq,
      key: "3",
      color: "#28a745",
    });
  }, [
    harmonicsThree.linesNumber,
    harmonicsThree.linesFreq,
    harmonicsThree.sidebandsNumber,
    harmonicsThree.sidebandsFreq,
  ]);

  useEffect(() => {
    if (!seriesRef.current) return;
    const xAxis = seriesRef.current.get("xAxis");
    const xAxisLabel = xAxis.children.values.find((item) =>
      item.isType("Label")
    );
    xAxisLabel.set("text" as keyof am5.ISpriteSettings, t(X_AXIS_LABEL_KEY));

    updateExportButtonText({ exportId, translate: t });
  }, [exportId, t]);

  return (
    <>
      <ContextMenu root={ref?.current?.root as am5.Root}>
        <button
          className="contextMenu--option"
          onClick={() => {
            updateHarmonics(signal.machineId, "harmonicsOne", {
              type: "SET_VISIBLE",
              payload: true,
            });

            updateHarmonics(signal.machineId, "harmonicsOne", {
              type: "SET_LINES_FREQ",
              payload: cursorClickXValue,
            });
          }}
        >
          <FontIcon iconName="Separator" className={iconClass} />
          {t("Set as frequency 1")}
        </button>
        <button
          disabled={!harmonicsOne.visible}
          style={{ opacity: !harmonicsOne.visible ? 0.2 : 1 }}
          className="contextMenu--option"
          onClick={() => {
            if (!xAxisRef?.current) return;

            const sidebandsFreq = getSidebandsFrequency({
              xAxis: xAxisRef?.current,
              cursorValue: cursorClickXValue,
              harmonicsKey: "1",
            });

            updateHarmonics(signal.machineId, "harmonicsOne", {
              type: "SET_SIDEBANDS_FREQ",
              payload: sidebandsFreq,
            });
          }}
        >
          <FontIcon iconName="GripperBarVertical" className={iconClass} />
          {t("Set as sideband 1")}
        </button>
        <button
          className="contextMenu--option"
          onClick={() => {
            updateHarmonics(signal.machineId, "harmonicsTwo", {
              type: "SET_VISIBLE",
              payload: true,
            });

            updateHarmonics(signal.machineId, "harmonicsTwo", {
              type: "SET_LINES_FREQ",
              payload: cursorClickXValue,
            });
          }}
        >
          <FontIcon iconName="Separator" className={iconClass} />
          {t("Set as frequency 2")}
        </button>

        <button
          disabled={!harmonicsTwo.visible}
          style={{ opacity: !harmonicsTwo.visible ? 0.2 : 1 }}
          className="contextMenu--option"
          onClick={() => {
            if (!xAxisRef?.current) return;

            const sidebandsFreq = getSidebandsFrequency({
              xAxis: xAxisRef?.current,
              cursorValue: cursorClickXValue,
              harmonicsKey: "2",
            });

            updateHarmonics(signal.machineId, "harmonicsTwo", {
              type: "SET_SIDEBANDS_FREQ",
              payload: sidebandsFreq,
            });
          }}
        >
          <FontIcon iconName="GripperBarVertical" className={iconClass} />
          {t("Set as sideband 2")}
        </button>

        <button className="contextMenu--option" onClick={clearHarmonics}>
          <FontIcon iconName="Clear" className={iconClass} />
          {t("Clear Harmonics")}
        </button>
      </ContextMenu>
      <div ref={contentRef} style={{ height: "calc(100% - 61px - 53px)" }}>
        <XYChart
          ref={ref}
          customSettings={{
            scrollbarY: {
              minBullet: { forceHidden: true },
              thumb: { forceInactive: true },
            },
          }}
          exportSelector={exportId}
          isMinimized={!shouldShowChartLabels}
          styles={chartModalStyle}
        />
        <div id={`breadcrumb-container-${machine.id}-${uniqueId}`} />
      </div>
    </>
  );
};

export default SpectrumChart;
