/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ToggleContainer } from "../Generic/ToggleContainer";
import { DModels } from "./models";
import { DataloggersTable } from "./DataloggersTable";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import {
  listAsyncMachines,
  selectMachinesStatus,
  selectMachinesToList,
} from "../Machines/reducer";
import { selectProjects } from "../Projects/reducer";
import { Status } from "../../schema/status";
import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../common/CommandBar";
import {
  commandBarStyles,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import { SearchBox, Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import { DataloggerAddDialog } from "./DataloggerAddEditDialogs";
import { MachineToList } from "../Machines/models";
import { ResponseProjectDetails } from "../Projects/models";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";

import { useTranslation } from "react-i18next";

type DataloggerToggleTableProps = {
  searchText: string;
  modelKey: string;
  isMetaDataContributor: boolean;
  projectsRef: ResponseProjectDetails[];
  machinesRef: MachineToList[];
  onToggleOpen?: (id: string) => void;
};

/**
 * Gets the command bar items
 * @param onAdd Function called when the Add button is clicked.
 * @returns The command bar items
 */
const commandBarItems = (
  t,
  title,
  isMetaDataContributor: boolean,
  onAdd: () => void
): CommandBarItemProps[] => {
  let titleItemProp: CommandBarItemProps = {
    key: "title",
    type: CommandBarItemType.Custom,
    onRender: () => <Text style={titleStyle}>{title}</Text>,
  };

  if (!isMetaDataContributor) {
    return [titleItemProp];
  }

  return [
    titleItemProp,
    ...[
      {
        key: "add",
        text: t("Add"),
        type: CommandBarItemType.Button,
        iconProps: { iconName: "Add" },
        onClick: onAdd,
      },
    ],
  ];
};

/**
 * Gets the datalogger table inside a toggle container.
 * @param isMeasuredDataContributor A value indicating whether the current user is measured data contributor.
 * @param modelKey The datalogger model key.
 * @param projectsRef The reference project list to complete the dataloggers project information.
 * @param machinesRef The reference machines list, to complete the dataloggers machines information.
 * @param searchText The search text key to filtered the dataloggers table. If empty, all dataloggers will be shown.
 * @param onToggleOpen Function called when the toggle container is opening.
 * @returns
 */
const DataloggersToggleTable = ({
  isMetaDataContributor,
  modelKey,
  projectsRef,
  machinesRef,
  searchText,
  onToggleOpen,
}: DataloggerToggleTableProps) => {
  const [show, setShow] = useState(true);

  // Gets the dataloggers count after the API call, and decides whether to show the component.
  const onDataloggersRead = (count: number) => {
    setShow(count > 0);
  };

  return (
    <ToggleContainer
      id={`${modelKey}-section`}
      title={DModels[modelKey]}
      sideText={""}
      open={true}
      show={show}
      onOpen={onToggleOpen}
    >
      <DataloggersTable
        key={`${modelKey}-section`}
        model={DModels[modelKey]}
        isMetaDataContributor={isMetaDataContributor}
        projectsRef={projectsRef}
        machinesRef={machinesRef}
        searchText={searchText}
        onDataloggersRead={onDataloggersRead}
      />
    </ToggleContainer>
  );
};

/**
 * The Dataloggers list component.
 * @returns The dataloggers list component.
 */
export const DataloggersList = () => {
  const { t } = useTranslation();
  const auth = useContext(authContext);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const machines = useAppSelector(selectMachinesToList);
  const projects = useAppSelector(selectProjects);
  const [addButtonClicked, setAddButtonClicked] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const dispatch = useAppDispatch();

  // Gets the machines list, if it is null.
  useEffect(() => {
    machineStatus === Status.void && dispatch(listAsyncMachines());
  }, [dispatch]);

  // Scrolls the elements to the desired table.
  const handleOnOpen = (id: string) => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  // Function called when the add click button is pressed.
  const onClickAdd = () => {
    if (addButtonClicked) {
      return;
    }

    setAddButtonClicked(true);
  };

  // Shows a spinner if the authentication permissions are being loaded. Otherwise, shows the tables.
  let result: JSX.Element | null = null;
  if (auth.loading) {
    result = (
      <Stack
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
        style={{ marginTop: "auto", marginBottom: "auto", height: "100%" }}
      >
        <Stack.Item>
          <Spinner size={SpinnerSize.large} />
        </Stack.Item>
        <Stack.Item align="center">
          <Text variant="medium">{t("Loading data...")}</Text>
        </Stack.Item>
      </Stack>
    );
  } else {
    result = (
      <div style={pageStyle}>
        <Stack horizontal horizontalAlign="space-between">
          <Stack.Item>
            <BaseCommandBar
              items={computeCommandBarItems(
                commandBarItems(
                  t,
                  t("Dataloggers"),
                  auth.metaDataContributor,
                  onClickAdd
                )
              )}
              styles={commandBarStyles}
            />
          </Stack.Item>
          <Stack.Item>
            <SearchBox
              placeholder={t("Search")}
              styles={{ root: { width: 300 } }}
              onChange={(_, newValue) => setSearchText(newValue.trim())}
            />
          </Stack.Item>
        </Stack>

        {React.Children.map(Object.keys(DModels), (key) => {
          return (
            <DataloggersToggleTable
              modelKey={key}
              isMetaDataContributor={auth.metaDataContributor}
              projectsRef={projects}
              machinesRef={machines}
              searchText={searchText}
              onToggleOpen={handleOnOpen}
            />
          );
        })}

        {addButtonClicked && (
          <DataloggerAddDialog
            onClose={() => {
              setAddButtonClicked(false);
            }}
          />
        )}
      </div>
    );
  }

  return result;
};
