import { useEffect, useState } from "react";

import CloudIcon from "../../../../assets/svg/WirelessSensors/CloudIcon";
import GatewayIcon from "../../../../assets/svg/WirelessSensors/GatewayIcon";
import SensorNodeIcon from "../../../../assets/svg/WirelessSensors/SensorNodeIcon";
import type { TreeItem } from "../../components/Tree";
import WirelessSensorsTree from "../../components/Tree";
import type { GatewayTelemetry, Topology } from "../../../../types";

import { useProjectTelemetry } from "../../hooks/useProjectTelemetry";
import { useWSUrlParams } from "../../hooks/useWSUrlParams";
import { useTranslation } from "react-i18next";
import { useProjectTriggering } from "../../hooks/useProjectTriggering";
import { notification } from "../../../common/Notification";
import ChartCommandBar from "../../components/Tree/ChartCommandBar";
import ManualTriggerSettings from "../Sensor/TriggersTab/ManualTriggerSettings";

const ProjectTelemetry = () => {
  const { t } = useTranslation();
  const { projectId } = useWSUrlParams();
  const [isManualTriggerOpen, setIsManualTriggerOpen] = useState(false);
  const { data, isLoading, isError, refetch } = useProjectTelemetry({
    projectId: projectId ?? "",
  });
  const [tree, setTree] = useState<TreeItem>({});
  const { triggerProjectAsync } = useProjectTriggering({
    projectId: projectId as string,
  });

  const handleManualTrigger = async (selectedKeys: string[]) => {
    try {
      await triggerProjectAsync(selectedKeys);
      notification.success(t("The project trigger was sent successfully"));
    } catch (error) {
      notification.error(t("Project trigger error"));
      throw error;
    }
  };

  const gatewayTelemetryToTreeItem = (
    telemetry: GatewayTelemetry
  ): TreeItem => {
    return {
      icon: <GatewayIcon />,
      name: telemetry.gatewayId,
      children: telemetry.topology.children.map(topologyTelemetryToTreeItem),
      condition: telemetry.enabled ? "Ok" : "Alert",
    };
  };

  const topologyTelemetryToTreeItem = (telemetry: Topology): TreeItem => {
    return {
      name: telemetry.value,
      children: telemetry.children.map(topologyTelemetryToTreeItem),
      icon: <SensorNodeIcon />,
      condition: "Ok",
    };
  };

  useEffect(() => {
    if (!isError && !isLoading && data) {
      const treeData: TreeItem = {
        name: "",
        icon: <CloudIcon />,
        children: data.gatewayTelemetries.map((telemetry: GatewayTelemetry) =>
          gatewayTelemetryToTreeItem(telemetry)
        ),
      };
      setTree(treeData);
    }
  }, [data, isLoading, isError]);

  return (
    <div className="chart-container">
      <ChartCommandBar
        title={t("Project Telemetry")}
        onRefresh={refetch}
        onTrigger={() => setIsManualTriggerOpen(true)}
      />

      <div className="chart-inner-container">
        <WirelessSensorsTree data={tree} isLoading={isLoading} />
      </div>

      <ManualTriggerSettings
        isOpen={isManualTriggerOpen}
        trendAndRaw={false}
        onDismiss={() => setIsManualTriggerOpen(false)}
        onSubmit={handleManualTrigger}
      />
    </div>
  );
};

export default ProjectTelemetry;
