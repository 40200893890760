import "./styles.scss";

import { DefaultButton, DialogFooter, DialogType } from "@fluentui/react";
import { format } from "date-fns";
import { useMemo } from "react";

import { FORMAT } from "../../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import { setMaxNumberDecimals } from "../../../../../../modules/common/components/InteractiveColorBar/methods";
import usePerformanceStore from "../../../hooks/usePerformanceStore";
import BaseDialog, { DialogSize } from "../../../../../common/Dialog/Dialog";
import { Spin } from "../../../../../common/Spin";

import PerformanceStatus from "../PerformanceStatus";
import ModalTable from "./Table";
import { useTranslation } from "react-i18next";

type ModalProps = {
  performance: any;
  isLoading: any;
  data: any;
  bestPerformance: any;
};

const PerformanceModal = ({
  data = [],
  bestPerformance,
  performance,
  isLoading,
}: ModalProps) => {
  const { t } = useTranslation();
  const { selectedTimeStamp, isIndicesModalOpen, updateStore } =
    usePerformanceStore((store: any) => ({
      isIndicesModalOpen: store.isIndicesModalOpen,
      selectedTimeStamp: store.selectedTimeStamp,
      updateStore: store.updateStore,
    }));

  const { performancePageKpiSignals, performancePageParamSignals } =
    performance;

  const dialogTitleTimeStamp = selectedTimeStamp
    ? format(selectedTimeStamp, "yyyy-MM-dd HH:mm:ss")
    : "";

  const selectedTimeStampData = useMemo(() => {
    return data.find(
      ({ timeStamp }: { timeStamp: number }) => timeStamp === selectedTimeStamp
    );
  }, [selectedTimeStamp, data]);
  const progress = setMaxNumberDecimals(selectedTimeStampData?.value || 0, 2);

  const selectedTimeStampPeriod = selectedTimeStamp
    ? format(selectedTimeStamp, FORMAT)
    : "";
  const bestPerformanceTimeStamp = bestPerformance?.timeStamp
    ? format(bestPerformance?.timeStamp, FORMAT)
    : "";

  const commonProps = {
    bestPerformance: { timeStamp: bestPerformanceTimeStamp },
    worstPerformance: { timeStamp: selectedTimeStampPeriod },
    perfPeriodFilter: undefined,
    isLoading: false,
    tableSettings: {
      hasSelection: false,
      selection: null,
    },
    tableStyle: {
      minWidth: "100%",
      height: "100%",
    },
  };

  const kpiTableProps = {
    ...commonProps,
    title: "KPIs",
    tableKeyName: "kpi-modal-table",
    kpiSignals: performancePageKpiSignals,
    tableHeight: "34%",
  };

  const signalListTableProps = {
    ...commonProps,
    title: t("Signal list"),
    tableKeyName: "signals-modal-table",
    kpiSignals: performancePageParamSignals,
    tableHeight: "53%",
  };

  return (
    <BaseDialog
      hidden={!isIndicesModalOpen}
      dialogContentProps={{
        title: t(`Load and Performance {{timestamp}}`, {
          timestamp: dialogTitleTimeStamp,
        }),
        type: DialogType.close,
      }}
      minWidth="750px"
      styles={{
        root: [
          {
            ".ms-Dialog-main": {
              maxWidth: "100%",
              resize: "both",
              height: "70vh",
            },
          },
          {
            ".ms-Modal-scrollableContent": {
              height: "100%",
              maxHeight: "100%",
            },
          },
          {
            ".ms-Dialog--close": {
              height: "100%",
            },
          },
          {
            ".ms-Dialog-header": {
              height: "auto",
            },
          },
          {
            ".ms-Dialog-inner": {
              height: "calc(100% - 63px)",
            },
          },
          {
            ".ms-Dialog-content": {
              height: "calc(100% - 48px)",
            },
          },
        ],
      }}
      size={DialogSize.AUTO}
      onDismiss={() => updateStore({ isIndicesModalOpen: false })}
    >
      <div className="performance-modal-wrap">
        {isLoading ? (
          <Spin style={{ height: "100%" }} />
        ) : (
          <div className="performance-modal">
            <PerformanceStatus
              withTitleProgress
              progress={progress}
              style={{ display: "flex", flexFlow: "column", gap: "8px" }}
            />

            <ModalTable {...kpiTableProps} />
            <ModalTable {...signalListTableProps} />
          </div>
        )}
      </div>

      <DialogFooter>
        <DefaultButton
          text={t("Close")}
          style={{ color: "#fff", background: "#2C529F" }}
          onClick={() => updateStore({ isIndicesModalOpen: false })}
        />
      </DialogFooter>
    </BaseDialog>
  );
};

export default PerformanceModal;
