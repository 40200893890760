import type { IPivotItemProps, IPivotProps } from "@fluentui/react";
import { Pivot, PivotItem } from "@fluentui/react";

export type TabProps = {
  key: string;
  title: string;
  content: JSX.Element;
  pivotItemProps?: IPivotItemProps;
};

type TabMenuProps = IPivotProps & {
  pivotProps?: IPivotProps;
  tabs: TabProps[];
};

export const TabMenu = ({ tabs, pivotProps }: TabMenuProps) => {
  return (
    <Pivot {...pivotProps}>
      {tabs.map((tab, idx) => (
        <PivotItem
          key={idx}
          itemKey={tab.key}
          headerText={tab.title}
          {...tab.pivotItemProps}
        >
          {tab.content}
        </PivotItem>
      ))}
    </Pivot>
  );
};
