/* eslint-disable react-hooks/exhaustive-deps */
import { DialogType, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import { DialogSize } from "../../../common/Dialog";
import { notification } from "../../../common/Notification";
import FormDialog, { FormDialogProps } from "../../../Generic/FormDialog";
import FormItemRow from "../../../Generic/FormItemRow";
import { HaagMappingApi } from "./api";
import { useTranslation } from "react-i18next";

type HaagMappingDeleteBySourceDialogProps = Omit<
  FormDialogProps,
  "title" | "isLoading" | "isValid" | "type" | "size" | "onSubmit" | "onClose"
> & {
  machineId: string;
  onClose: (refresh: boolean) => void;
};

const HaagMappingDeleteBySourceDialog = ({
  machineId,
  onClose,
  ...rest
}: HaagMappingDeleteBySourceDialogProps) => {
  const { t } = useTranslation();
  const [identifier, setIdentifier] = useState<string | undefined>(undefined);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  // Deletes the entities by source identifier
  useEffect(() => {
    if (!isDeleting || !machineId) {
      return;
    }

    let mount = true;
    HaagMappingApi.deleteAll(machineId, identifier).then((response) => {
      if (!mount) {
        return;
      }

      setIsDeleting(false);
      if (response.status !== 200) {
        notification.error(
          response["text"]?.message
            ? t(
                `Error deleting HAAG mapping entries by source identifier '{{identifier}}': {{errorMessage}}`,
                { identifier, errorMessage: response["text"]?.message }
              )
            : t(
                `Error deleting HAAG mapping entries by source identifier '{{identifier}}': Unknown error`,
                { identifier }
              )
        );
        return;
      }

      if (!response.data || response.data.length === 0) {
        notification.info(
          t(
            `No HAAG mapping entries with the source identifier '{{identifier}}' were found.`,
            { identifier }
          )
        );
        return;
      }

      notification.success(
        response.data?.length
          ? t(
              `All HAAG mapping entries ({{entriesNumber}}) by source identifier '{{identifier}}' were successfully deleted.`,
              { identifier, entriesNumber: response.data?.length }
            )
          : t(
              `All HAAG mapping entries (0) by source identifier '{{identifier}}' were successfully deleted.`,
              { identifier }
            )
      );
      onClose?.(true);
    });

    // Cleans all resources
    return () => {
      mount = false;
    };
  }, [isDeleting]);

  return (
    <FormDialog
      {...rest}
      title={t("Delete HAAG by Source Identifier")}
      submitButtonText={isDeleting ? t("Deleting...") : t("Delete All")}
      isLoading={isDeleting}
      isValid={identifier?.length > 0}
      type={DialogType.normal}
      size={DialogSize.S}
      onSubmit={() => setIsDeleting(true)}
      onClose={() => onClose?.(false)}
    >
      <FormItemRow
        label={t("Source Identifier")}
        htmlFor={"haag-source-identifier"}
      >
        <TextField
          id="haag-source-identifier"
          value={identifier}
          onChange={(_, newValue) => setIdentifier(newValue)}
        />
      </FormItemRow>
    </FormDialog>
  );
};

export default HaagMappingDeleteBySourceDialog;
