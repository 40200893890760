import "./style.scss";
import { TabMenu } from "../../../../common/TabMenu";
import { Mode, modeIcons, useModeSelect } from "../../hooks/useModeSelect";
import { IPivotItemProps } from "@fluentui/react";
import Overview from "./Overview";
import Map from "./Map";

import { MapProvider } from "../../../../common/Map";
import { useAppSelector } from "../../../../../Hooks";
import { selectUserSettings } from "../../../../Settings/selectors";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Continents } from "./Continents";

const onRenderItemLink: any = (props: IPivotItemProps) => {
  const ModeIcon = modeIcons[props?.itemKey as Mode];

  return (
    <div className="mode-tab">
      <ModeIcon />
      {props.headerText}
    </div>
  );
};

const Dashboard = () => {
  const { t } = useTranslation();
  const {
    dashboardSettings: {
      machinesCounter,
      conditions,
      lastDataUpdate,
      worldMap,
    },
  } = useAppSelector(selectUserSettings);

  const hasOverviewTab = machinesCounter || conditions || lastDataUpdate;
  const { mode, setDataMode } = useModeSelect();

  const MODE_OPTIONS = useMemo(
    () =>
      [
        hasOverviewTab && {
          key: "overview",
          pivotItemProps: {
            itemKey: "overview",
            onRenderItemLink,
          },
          title: t("Overview"),
          content: <Overview />,
        },
        worldMap && {
          key: "map",
          pivotItemProps: {
            itemKey: "map",
            onRenderItemLink,
          },
          title: t("Map"),
          content: (
            <div style={{ marginTop: "-40px" }}>
              <Continents />
              <Map />
            </div>
          ),
        },
      ].filter(Boolean),
    [hasOverviewTab, t, worldMap]
  );

  return (
    <MapProvider>
      <TabMenu
        tabs={MODE_OPTIONS}
        pivotProps={{
          className: "mode-select-tab-menu",
          defaultSelectedKey: mode,
          onLinkClick: (tab) => setDataMode(tab?.props?.itemKey as Mode),
          styles: {
            root: {
              display: "flex",
              background: "white",
              marginBottom: 16,
            },
            link: { flex: 1, margin: 0, fontSize: 14, fontWeight: 600 },
            linkIsSelected: { background: "#2C529F", color: "#fff" },
          },
        }}
      />
    </MapProvider>
  );
};

export default Dashboard;
