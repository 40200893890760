import {
  getTheme,
  Label,
  PrimaryButton,
  Stack,
  StackItem,
} from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { IconNames } from "../Icons/IconNames";
import Icon from "../Icons/Icons";
import { useContext } from "react";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";
import { useTranslation } from "react-i18next";

interface items404WithPermission {
  key: string;
  nameIcon: IconNames;
  textField: string;
  url: string;
  canI: boolean;
}

export const NotFoundRoute: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const theme = getTheme();
  const auth = useContext(authContext);
  const navigate = useNavigate();

  const iconsAll: items404WithPermission[] = [
    {
      key: "trend-view",
      textField: t("Trend View"),
      nameIcon: "TrendView",
      url: "trend-view",
      canI: auth.measuredDataReader,
    },
    {
      key: "raw-data",
      textField: t("Raw View"),
      nameIcon: "RawView",
      url: "raw-data",
      canI: auth.measuredDataReader,
    },
    {
      key: "machine-cv",
      textField: t("Machine CV"),
      nameIcon: "MachineCV",
      url: "machine-cv",
      canI: auth.machineCvReader,
    },
  ];

  return (
    <div style={{ height: "95vh" }}>
      <Stack
        verticalFill
        //verticalAlign="center"
        horizontalAlign="center"
        //style={{ height: "95vh" }}
      >
        <StackItem>
          <div style={{ textAlign: "center", paddingTop: "35vh" }}>
            <Label
              style={{
                fontSize: "42px",
                fontFamily: "Segoe UI",
                lineHeight: "52px",
                color: theme.palette.themeDarker,
              }}
            >
              404
            </Label>
            <Label
              style={{
                fontSize: "20px",
                fontFamily: "Segoe UI",
                lineHeight: "28px",
                color: theme.palette.themeDarker,
              }}
            >
              {t("Amazing! You found the unfoundable.")}
            </Label>

            <Label
              style={{
                fontSize: "14px",
                fontFamily: "Segoe UI",
                color: theme.palette.black,
                fontWeight: "400",
                width: "392px",
              }}
            >
              {t(
                "Sadly you can’t do much here, but can find other useful information on the links below or just go on homepage."
              )}
            </Label>
            <div style={{ paddingTop: "20px" }}>
              <PrimaryButton
                key={"Take me Home"}
                text={t("Take me Home")}
                onClick={() => navigate("")}
              />
            </div>
          </div>
        </StackItem>

        <StackItem>
          <Label
            key="Quick links"
            style={{
              fontSize: "12px",
              fontFamily: "Segoe UI",
              lineHeight: "16px",
              color: theme.palette.black,
              fontWeight: "600",
              paddingTop: "10vh",
            }}
          >
            {t("Quick links")}
          </Label>
          <Stack horizontal>
            {iconsAll
              .filter((ic) => ic.canI === true)
              .map((ico) => (
                <div key={ico.nameIcon}>
                  <StackItem
                    verticalFill
                    style={{
                      textAlign: "center",
                      paddingRight: "60px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(ico.url, { replace: false })}
                  >
                    <Icon
                      name={ico.nameIcon}
                      width="67"
                      height="40"
                      viewBox="0 0 20 20"
                    />
                    <Label
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: theme.palette.black,
                        fontWeight: "450",
                      }}
                    >
                      {ico.textField}
                    </Label>
                  </StackItem>
                </div>
              ))}
          </Stack>
        </StackItem>
      </Stack>
    </div>
  );
};
