import { t } from "i18next";
import Icon1 from "./torqueSvgs/Icon1.svg";
import Icon2 from "./torqueSvgs/Icon2.svg";
import Icon3 from "./torqueSvgs/Icon3.svg";
import Icon4 from "./torqueSvgs/Icon4.svg";
import Icon5 from "./torqueSvgs/Icon5.svg";

export const torqueSignalsChartColors = [
  {
    name: t("Torque Maximum"),
    color: "#66CD7D",
    icon: Icon1,
  },
  {
    name: t("Torque Average"),
    color: "#344c92",
    icon: Icon2,
  },
  {
    name: t("Torque Minimum"),
    color: "#F63F4D",
    icon: Icon4,
  },
  {
    name: t("Torque Peak to Peak"),
    color: "#e044a7",
    icon: Icon5,
  },
  {
    name: t("Torque Standard Deviation"),
    color: "#FFCC00",
    icon: Icon3,
  },
];
