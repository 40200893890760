import type { SVGProps } from "react";
import React from "react";

const BookmarkRemoveIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 2.70702L0.145998 0.854021C0.0995103 0.807533 0.0626342 0.752344 0.0374751 0.691605C0.012316 0.630865 -0.000633239 0.565765 -0.00063324 0.500021C-0.00063324 0.434277 0.012316 0.369177 0.0374751 0.308438C0.0626342 0.247698 0.0995103 0.192509 0.145998 0.146021C0.192486 0.0995332 0.247676 0.0626571 0.308415 0.037498C0.369154 0.0123389 0.434254 -0.000610352 0.499998 -0.000610352C0.565742 -0.000610351 0.630842 0.0123389 0.691582 0.037498C0.752321 0.0626571 0.80751 0.0995332 0.853998 0.146021L15.854 15.146C15.9479 15.2399 16.0006 15.3672 16.0006 15.5C16.0006 15.6328 15.9479 15.7601 15.854 15.854C15.7601 15.9479 15.6328 16.0007 15.5 16.0007C15.3672 16.0007 15.2399 15.9479 15.146 15.854L14 14.707V15.5C13.9999 15.5922 13.9744 15.6826 13.9261 15.7611C13.8779 15.8397 13.8089 15.9034 13.7267 15.9452C13.6446 15.987 13.5525 16.0053 13.4606 15.998C13.3687 15.9908 13.2806 15.9582 13.206 15.904L8 12.118L2.794 15.904C2.71943 15.9582 2.63133 15.9908 2.53943 15.998C2.44754 16.0053 2.35542 15.987 2.27325 15.9452C2.19109 15.9034 2.12209 15.8397 2.07386 15.7611C2.02564 15.6826 2.00008 15.5922 2 15.5V2.70702ZM13 13.707L3 3.70702V14.518L7.706 11.096C7.79144 11.0339 7.89436 11.0005 8 11.0005C8.10563 11.0005 8.20855 11.0339 8.294 11.096L13 14.518V13.707ZM13 2.50002V10.879L14 11.879V2.50002C14 1.83698 13.7366 1.2011 13.2678 0.732254C12.7989 0.263413 12.163 2.12193e-05 11.5 2.12193e-05H4.5C3.84 2.12193e-05 3.24 0.255021 2.794 0.673021L3.502 1.38002C3.767 1.14402 4.117 1.00002 4.5 1.00002H11.5C11.8978 1.00002 12.2794 1.15806 12.5607 1.43936C12.842 1.72067 13 2.1022 13 2.50002Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BookmarkRemoveIcon;
