/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IconButton } from "@fluentui/react";

import { format } from "../../../../../../utils";
import type { User } from "../../../../../../Hooks";
import {
  getUserById,
  useWebAnalysisPermissions,
} from "../../../../../../Hooks";

import { File } from "../../../../../common/Preview/File";
import type { Column } from "../../../../../common/Table/Table";
import { useTranslation } from "react-i18next";

type TaskDocumentsColumnsProps = {
  t: any;
  onRemove: ({ ids, name }: { ids: string[]; name: string }) => void;
  users: User[];
};

type ActionsMenuProps = {
  id: string;
  name: string;
  onRemove: any;
};

const ActionsMenu = ({ id, name, onRemove }: ActionsMenuProps) => {
  const { t } = useTranslation();
  const { useReadyPermission } = useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });

  if (!hasMachineCvAdministratorPermission) {
    return <>"-"</>;
  }

  return (
    <IconButton
      iconProps={{ iconName: "Delete" }}
      title={t("Delete")}
      ariaLabel={t("Delete")}
      styles={{
        root: {
          verticalAlign: "middle",
          color: "#0078d4",
          width: "100%",
          height: 16,
          padding: 0,
          margin: "0 auto",
        },
        rootHovered: {
          background: "transparent",
        },
        rootDisabled: {
          background: "transparent",
        },
      }}
      onClick={() => onRemove({ ids: [id], name })}
    />
  );
};

export const getColumns = ({
  t,
  onRemove,
  users,
}: TaskDocumentsColumnsProps): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    minWidth: 100,
    isSortable: true,
    onRender: (item) => {
      return <File file={item} />;
    },
  },
  {
    key: "addedBy",
    fieldName: "addedBy",
    name: t("Added By"),
    minWidth: 100,
    isSortable: true,
    onRender: (item) => getUserById(users, item.userId),
  },
  {
    key: "added",
    fieldName: "added",
    name: t("Date Added"),
    minWidth: 100,
    isSortable: true,
    onRender: (item) => format(new Date(item.added)),
  },
  {
    key: "description",
    fieldName: "description",
    name: t("Description"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
  },
  {
    key: "actions",
    fieldName: "actions",
    name: t("Actions"),
    minWidth: 68,
    onRender: ({ id, name, memberId }) => {
      const actionsMenuProps = {
        id,
        name,
        memberId,
        onRemove,
      };
      return <ActionsMenu {...actionsMenuProps} />;
    },
  },
];
