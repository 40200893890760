import { useQuery } from "@tanstack/react-query";

import { getApiClient } from "../../../modules/core/apiClient/useApiStore";

export const useMachineSensorsProfiles = ({ machineId }: any) => {
  const {
    data = [],
    isLoading,
    isError,
  } = useQuery(
    ["ws-machine-sensors-profiles"],
    () =>
      getApiClient()
        .get(`/dws/v1/triggers/statuses/per-machine/${machineId}`)
        .then(({ data }) => data.sensorTriggerStatuses),
    {
      cacheTime: 0,
    }
  );

  return {
    data,
    isLoading,
    isError,
  };
};
