import { useState } from "react";
import useBookmark from "../../../../../modules/common/components/Bookmarks/useBookmark";
import { PrimaryButton, Stack, TooltipHost } from "@fluentui/react";
import { getBookmarkedIcon } from "../../../../../modules/common/components/BookmarkButton";
import { useTranslation } from "react-i18next";

export default function MachineNameField({ item, defaultRender, ...rest }) {
  const { t } = useTranslation();
  const { toggleBookmark, bookmarks } = useBookmark();
  const [hovered, setHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const addOrRemoveBookmark = () => {
    toggleBookmark({
      bookmark: { ...item },
      onStart: () => setIsLoading(true),
      onSuccess: () => setIsLoading(false),
      onFailed: () => setIsLoading(false),
    });
  };

  const isBookmarked = !!bookmarks.find(
    (bookmark: any) => bookmark.id === item?.id
  );

  return (
    <Stack
      style={{ position: "relative" }}
      className="machine-conditions-machineName"
      horizontal
      verticalAlign="center"
    >
      <PrimaryButton
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        disabled={isLoading}
        styles={{
          root: {
            minWidth: "initial",
            color: "#2C529F !important",
            background: "transparent !important",
            border: "none !important",
            zIndex: 1,

            "is-disabled": {
              position: "absolute",
            },
          },
        }}
        onClick={(e) => {
          addOrRemoveBookmark();
          e.stopPropagation();
        }}
      >
        <TooltipHost content={isBookmarked ? t("Bookmarked") : t("Bookmark")}>
          {getBookmarkedIcon({ isBookmarked, hovered })}
        </TooltipHost>
      </PrimaryButton>

      {defaultRender({ item, ...rest })}
    </Stack>
  );
}
