import dayjs from "dayjs";
import { groupBy } from "lodash-es";

import { DAILY_EVENTS, YEARLY_EVENTS } from "./config";
import { DATE_FORMAT_DAYJS } from "../../../MachineCVSummaryPage/DetailsSection/OperationsTab/config";
import {
  prepareDataForMonthlyView,
  prepareDataForYearlyView,
} from "../../../MachineCVSummaryPage/DetailsSection/OperationsTab/methods";

export const prepareEventsStartEndDate = (
  activeTab: any,
  year: any = {},
  month: any = {}
) => {
  const yearStart = year?.start
    ? dayjs(year.start).format(DATE_FORMAT_DAYJS)
    : null;
  const yearEnd = year?.end ? dayjs(year.end).format(DATE_FORMAT_DAYJS) : null;
  const monthStart = month?.start
    ? dayjs(month.start).format(DATE_FORMAT_DAYJS)
    : null;
  const monthEnd = month?.end
    ? dayjs(month.end).format(DATE_FORMAT_DAYJS)
    : null;

  if (activeTab === YEARLY_EVENTS) {
    return {
      startDate: yearStart,
      endDate: yearEnd,
    };
  } else {
    return {
      startDate: monthStart,
      endDate: monthEnd,
    };
  }
};

export const prepareOperatingHours = ({
  t,
  data,
  activeTab,
  year,
  month,
}: any) => {
  let result = [];
  if (activeTab === YEARLY_EVENTS) {
    result = prepareDataForYearlyView({ t, data, year });
  } else {
    result = prepareDataForMonthlyView({ t, data, month });
  }

  return result.map(({ label, value }) => {
    return {
      label,
      operatingHours: value,
    };
  });
};

export const groupNumberOfAlerts = ({ data, activeTab }: any) => {
  let grouped: any;
  if (activeTab === YEARLY_EVENTS) {
    grouped = groupBy(data, ({ timeStamp }) => new Date(timeStamp).getMonth());
  } else {
    grouped = groupBy(data, ({ timeStamp }) => {
      return String(new Date(timeStamp).getDate()).padStart(1, "0");
    });
  }

  return grouped || {};
};

export const prepareEventsChartData = ({
  t,
  eventsData = [],
  operationData = [],
  activeTab,
  year,
  month,
  filteredNames,
}: any) => {
  const operatingHoursData = prepareOperatingHours({
    t,
    data: operationData,
    activeTab,
    year,
    month,
  });

  const disabledFilteringByName = filteredNames.includes("selectAll");

  const filteredEvents = (eventsData || []).filter((item: any) => {
    const { duration, name } = item || {};
    const isPositiveDuration = !(duration || "").includes("-");
    const isFiltered = disabledFilteringByName || filteredNames.includes(name);
    return isPositiveDuration && isFiltered;
  });

  const groupedNumberOfAlertsData = groupNumberOfAlerts({
    data: filteredEvents,
    activeTab,
    year,
    month,
  });

  return operatingHoursData.map((val, idx) => {
    const numberOfAlerts = (
      groupedNumberOfAlertsData[idx + (activeTab === DAILY_EVENTS ? 1 : 0)] ||
      []
    ).length;
    return {
      ...val,
      numberOfAlerts,
    };
  });
};
