import { AxiosResponse } from "axios";
import { axiosInstance } from "../..";
import {
  UserNotificationSettings,
  UserNotificationSettingsCorporation,
} from "./models";
import { t } from "i18next";

export type UserNotificationApiResponse<T = any> = {
  status: number;
  statusText: string;
  data: T;
};

/**
 * * The users notification API.
 */
export class UserNotificationsAPI {
  /**
   * Lists all selectable entities.
   * @param userId The user Id.
   * @returns The axios response with a list of selectable corporations.
   */
  static listSelectableEntities = async (
    userId: string
  ): Promise<AxiosResponse<UserNotificationSettingsCorporation[], any>> => {
    let result: AxiosResponse<UserNotificationSettingsCorporation[], any> = {
      status: 0,
      statusText: "",
      data: null,
      headers: null,
      config: null,
    };

    if (!userId) {
      result.statusText = "No user Id found.";
      return result;
    }

    const url = `rbac/v1/users/${userId}/notificationSettings/selectable`;
    try {
      result = await axiosInstance.get(url);
    } catch (error) {
      result = error.message || t("Unknown exception thrown.");
    }

    return result;
  };

  /**
   * Fetches the current user notification settings.
   * @param userId The user Id
   * @returns The axios response with the user notification settings object.
   */
  static readUserNotificationSettings = async (
    userId: string
  ): Promise<AxiosResponse<UserNotificationSettings, any>> => {
    let result: AxiosResponse<UserNotificationSettings, any> = {
      status: 0,
      statusText: "",
      data: null,
      headers: null,
      config: null,
    };

    if (!userId) {
      result.statusText = t("No user Id found.");
      return result;
    }

    const url = `rbac/v1/users/${userId}/notificationSettings`;
    try {
      result = await axiosInstance.get(url);
    } catch (error) {
      result = error.message || t("Unknown exception thrown.");
    }

    return result;
  };

  /**
   * Updates the current user notification settings.
   * @param userId The user Id
   * @param settings The new user notification settings.
   * @returns The axios response with the user notification settings object.
   */
  static updateUserNotificationSettings = async (
    userId: string,
    settings: UserNotificationSettings
  ): Promise<AxiosResponse<UserNotificationSettings, any>> => {
    let result: AxiosResponse<UserNotificationSettings, any> = {
      status: 0,
      statusText: "",
      data: null,
      headers: null,
      config: null,
    };

    if (!userId) {
      result.statusText = t("No user Id found.");
      return result;
    }

    const url = `rbac/v1/users/${userId}/notificationSettings`;
    try {
      result = await axiosInstance.put(url, settings);
    } catch (error) {
      result = error.message || t("Unknown exception thrown.");
    }

    return result;
  };
}
