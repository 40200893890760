import { notification } from "../common/Notification";
import { NoData } from "../common/NoData";
import BaseDialog, { DialogSize } from "../common/Dialog";

import {
  DialogType,
  DialogFooter,
  IDialogProps,
  PrimaryButton,
  DefaultButton,
  StackItem,
  Pivot,
  Stack,
  PivotItem,
  ScrollablePane,
  ScrollbarVisibility,
  IStackTokens,
  Spinner,
  Checkbox,
  DatePicker,
} from "@fluentui/react";

import { Icon as IconF } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { listAsyncRoles, selectRoles, selectRolesStatus } from "./reducer";
import { RoleType, ScopeLevelType, Status } from "../../schema/status";
import {
  CustomItemsType,
  listAsyncScopesOfRole,
  selectScopesOfRoleItems,
  selectScopesOfRoleStatus,
} from "./ScopesOfRole/reducer";
import { format } from "../../schema/Utils";
import { ScopeSelections } from "../ServiceAccount/ScopesSelections";
import { IRole } from "./models";
import {
  ServiceAccountAddRoleToShow,
  personaProps,
} from "../ServiceAccount/AddDialog";
import { useTranslation } from "react-i18next";
import { getDayPickerStrings } from "../common/Form";

export type ControlledItem = CustomItemsType & {
  check: boolean;
};

const stackTokens: IStackTokens = {
  childrenGap: 20,
};

type AddRolesPersonaDialogProps = IDialogProps & {
  show: boolean;
  onClose: () => void;
  rolesToAdd: ServiceAccountAddRoleToShow[];
  setRolesToAdd: React.Dispatch<
    React.SetStateAction<ServiceAccountAddRoleToShow[]>
  >;
  persona: personaProps;
};

interface pivotsProps {
  name: string;
  completed: boolean;
  availableOnComplete: string[];
  next?: string;
  prev?: string;
}

interface pivotsCustom {
  selected: string;
  items: pivotsProps[];
}

export type RoleItemDescription = {
  roleId: string;
  role?: RoleType | undefined;
  scopeLevel?: ScopeLevelType | undefined;
  scopeLevelId?: string | undefined;
  hasScope: boolean;
};

export const AddRolePersona = ({
  show,
  onClose,
  rolesToAdd,
  setRolesToAdd,
  persona,
  ...rest
}: AddRolesPersonaDialogProps) => {
  const { t } = useTranslation();
  const [pivotProps, setPivotProps] = useState<pivotsCustom>({
    selected: persona.root ? "expiration" : "scope",
    items: [],
  });
  const dispatch = useAppDispatch();
  const [rolesId, setRolesId] = useState<RoleItemDescription[] | undefined>();

  const rolesStatus = useAppSelector(selectRolesStatus);
  const roles = useAppSelector(selectRoles);
  const allItems: CustomItemsType[] = useAppSelector(selectScopesOfRoleItems);
  const scopePerRoleStatus = useAppSelector(selectScopesOfRoleStatus);
  const [withOutExpiration, setWithOutExpiration] = useState(false);
  const [controlledItems, setControlledItems] = useState<ControlledItem[]>();

  const [expiresAtUtc, setExpiresAtUtc] = useState<string>();

  useEffect(() => {
    const myRoles = roles.filter((el) => persona.persona.includes(el.type));
    setRolesId(
      myRoles.map((ele: IRole) => {
        return { roleId: ele.id, role: ele.type, hasScope: ele.hasScope };
      })
    );
    return () => {};
  }, [persona, roles]);

  useEffect(() => {
    rolesId && persona.root === false
      ? setControlledItems(
          allItems?.map((ele) => {
            if (ele.name === "name") return { ...ele, check: true };
            else return { ...ele, check: false };
          })
        )
      : rolesId &&
        setControlledItems(
          allItems?.map((ele) => {
            if (ele.scope === ScopeLevelType.Root)
              return { ...ele, check: true };
            else return { ...ele, check: false };
          })
        );

    return () => {};
  }, [allItems, persona.root, rolesId]);

  useEffect(() => {
    if (rolesStatus === Status.void) dispatch(listAsyncRoles());
    else if (rolesStatus === Status.idle && rolesId)
      dispatch(listAsyncScopesOfRole(rolesId.at(0).roleId));
  }, [dispatch, rolesStatus, rolesId]);

  useEffect(() => {
    const itemsSelBool =
      controlledItems?.filter((ele) => ele.check === true).length > 0;
    setPivotProps({
      selected: pivotProps.selected,
      items: persona.root
        ? [
            {
              name: "expiration",
              completed: itemsSelBool,
              availableOnComplete: ["review"],
              next: "review",
            },
            {
              name: "review",
              completed: itemsSelBool,
              availableOnComplete: ["expiration"],
              prev: "expiration",
            },
          ]
        : [
            {
              name: "scope",
              completed: itemsSelBool,
              availableOnComplete: ["expiration"],
              next: "expiration",
            },
            {
              name: "expiration",
              completed: itemsSelBool,
              availableOnComplete: ["scope", "review"],
              next: "review",
              prev: "members",
            },
            {
              name: "review",
              completed: itemsSelBool,
              availableOnComplete: ["scope", "expiration"],
              prev: "expiration",
            },
          ],
    });
    return () => {};
  }, [pivotProps.selected, controlledItems, persona.root]);

  const handleClose = () => {
    setWithOutExpiration(false);
    setPivotProps({
      selected: undefined,
      items: [],
    });
    setRolesId(undefined);
    onClose?.();
  };

  const handleAdd = async () => {
    const auxItems: ServiceAccountAddRoleToShow[] = [];
    const filteredItems = controlledItems?.filter((ele) => ele.check === true);

    const rootScope = allItems?.find(
      (ele) => ele.scope === ScopeLevelType.Root
    );

    rolesId.forEach((rol) =>
      filteredItems.forEach((ele) =>
        auxItems.push({
          roleId: rol.roleId,
          scopeLevelId: rol.hasScope ? ele.scopeId : rootScope?.scopeId,
          resource: rol.hasScope ? ele.name : "",
          role: rol.role,
          scopeResourceId: rol.hasScope ? ele.scopeResourceId : undefined,
          scopeLevel: rol.hasScope ? ele.scope : ScopeLevelType.Root,
          expiresAtUtc: expiresAtUtc,
        })
      )
    );

    setWithOutExpiration(false);
    setPivotProps({
      selected: "scope",
      items: [],
    });

    const filteredArr = [...auxItems, ...rolesToAdd].reduce((acc, current) => {
      const x = acc.find(
        (item) =>
          item.roleId === current.roleId &&
          item.scopeLevelId === current.scopeLevelId &&
          item.scopeResourceId === current.scopeResourceId
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        notification.info(t("One or more items are already selected."));
        return acc;
      }
    }, []);

    setRolesToAdd(filteredArr);

    handleClose();
  };

  const onLinkClick = (item) => {
    const myProps = pivotProps.items.find(
      (ite) => ite.name === pivotProps.selected
    );
    const myPropsToGo = pivotProps.items.find(
      (ite) => ite.name === item.props.itemKey
    );
    if (myProps.completed || myPropsToGo.completed) {
      setPivotProps({
        selected: item.props.itemKey,
        items: [...pivotProps.items],
      });
    } else {
      notification.warning(
        t("Please complete the {{name}} selection", { name: myProps.name })
      );
    }
  };

  const scopePivot = useCallback(() => {
    return (
      <>
        {scopePerRoleStatus === Status.idle ? (
          <Stack>
            <ScopeSelections
              controlledItems={controlledItems}
              setControlledItems={setControlledItems}
            />
          </Stack>
        ) : scopePerRoleStatus === Status.loading ? (
          <Spinner></Spinner>
        ) : (
          <Stack
            styles={{ root: { height: "100vh" } }}
            verticalAlign="space-around"
          >
            <NoData />
          </Stack>
        )}
      </>
    );
  }, [scopePerRoleStatus, controlledItems]);

  const expirationPivot = useCallback(() => {
    return (
      <Stack horizontalAlign="center">
        <Checkbox
          label={t("Without expiration date.")}
          onChange={(ev, checked) => {
            setWithOutExpiration(checked);
            if (checked === true) setExpiresAtUtc(undefined);
          }}
          styles={{ root: { padding: 2 } }}
          checked={withOutExpiration}
        />
        {!withOutExpiration ? (
          <>
            <DatePicker
              label={t("Select the expiration date")}
              style={{ width: "20vw", textAlign: "center" }}
              placeholder={t("Select a date")}
              ariaLabel={t("Select a date")}
              strings={getDayPickerStrings(t)}
              onSelectDate={(date) => setExpiresAtUtc(format(date))}
            />
            <h5>{t("Permission expires on (UTC)")}</h5>
          </>
        ) : (
          <></>
        )}
      </Stack>
    );
  }, [withOutExpiration, t]);

  const reviewPivot = useCallback(() => {
    const filteredItems = controlledItems?.filter((ele) => ele.check === true);
    return (
      <>
        {
          <>
            <Stack
              verticalFill
              horizontalAlign="stretch"
              style={{ padding: 40, paddingLeft: 80 }}
            >
              <Stack horizontal>
                <StackItem style={{ width: "20vw" }}>
                  <h3> {t("Roles:")}</h3>
                </StackItem>
                <StackItem>
                  {rolesId.map((rol) => (
                    <div key={rol.roleId}>
                      <StackItem>
                        <b>{rol.role}</b>
                      </StackItem>
                    </div>
                  ))}
                </StackItem>
              </Stack>

              <Stack horizontal>
                <StackItem style={{ width: "20vw" }}>
                  <h3>{t("Scopes:")} </h3>
                </StackItem>
                <StackItem>
                  {filteredItems?.map((ele) => (
                    <div key={ele.scopeResourceId}>
                      <StackItem>
                        <b>{ele.scope}</b>: {ele.name}.
                      </StackItem>
                    </div>
                  ))}
                </StackItem>
              </Stack>

              <Stack horizontal>
                <StackItem style={{ width: "20vw" }}>
                  <h3> {t("Expires:")} </h3>
                </StackItem>
                <StackItem>{expiresAtUtc ? "True" : "False"}</StackItem>
              </Stack>
              {expiresAtUtc ? (
                <Stack horizontal>
                  <StackItem style={{ width: "20vw" }}>
                    <h3> {t("Expires on:")} </h3>
                  </StackItem>
                  <StackItem>{expiresAtUtc}</StackItem>
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
          </>
        }
      </>
    );
  }, [controlledItems, expiresAtUtc, rolesId, t]);

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        title: t("Add Role"),
        type: DialogType.close,
        onDismiss: handleClose,
      }}
      size={DialogSize.L}
    >
      <Stack
        tokens={stackTokens}
        horizontalAlign="center"
        styles={{ root: { display: "grid" } }}
      >
        <StackItem>
          <Pivot
            aria-label={t("Pivots to add permission")}
            selectedKey={pivotProps.selected}
            onLinkClick={onLinkClick}
          >
            {!persona.root && (
              <PivotItem
                headerText={t("Scope")}
                itemKey={"scope"}
                onRenderItemLink={() => (
                  <div>
                    {rolesId &&
                    controlledItems?.filter((ele) => ele.check === true)
                      .length > 0 ? (
                      <>
                        <IconF iconName="SkypeCheck" />{" "}
                        {t("Scope [{{scopeName}}]", {
                          scopeName: controlledItems
                            ?.filter((item) => item.check === true)
                            .length.toString(),
                        })}
                      </>
                    ) : (
                      <>{t("Scope")}</>
                    )}
                  </div>
                )}
              >
                <ScrollablePane
                  scrollContainerFocus={true}
                  scrollbarVisibility={ScrollbarVisibility.auto}
                  scrollContainerAriaLabel={"scroll-projects"}
                  style={{
                    height: "60vh",
                    position: "relative",
                  }}
                >
                  {scopePivot()}
                </ScrollablePane>
              </PivotItem>
            )}
            <PivotItem
              headerText={t("Expiration")}
              itemKey={"expiration"}
              onRenderItemLink={() => (
                <div>
                  {rolesId &&
                  controlledItems?.filter((ele) => ele.check === true).length >
                    0 &&
                  (expiresAtUtc || withOutExpiration) ? (
                    <>
                      <IconF iconName="SkypeCheck" /> {t("Expiration")}
                    </>
                  ) : (
                    <> {t("Expiration")}</>
                  )}
                </div>
              )}
            >
              <ScrollablePane
                scrollContainerFocus={true}
                scrollbarVisibility={ScrollbarVisibility.auto}
                scrollContainerAriaLabel={"scroll-projects"}
                style={{
                  height: "60vh",
                  position: "relative",
                }}
              >
                <Stack
                  verticalFill
                  verticalAlign="center"
                  style={{ height: "50vh" }}
                >
                  {expirationPivot()}
                </Stack>
              </ScrollablePane>
            </PivotItem>
            <PivotItem headerText={t("Review")} itemKey={"review"}>
              {rolesId && (
                <ScrollablePane
                  scrollContainerFocus={true}
                  scrollbarVisibility={ScrollbarVisibility.auto}
                  scrollContainerAriaLabel={"scroll-projects"}
                  style={{
                    height: "60vh",
                    position: "relative",
                  }}
                >
                  {reviewPivot()}
                </ScrollablePane>
              )}
            </PivotItem>
          </Pivot>
        </StackItem>
      </Stack>
      <DialogFooter>
        <PrimaryButton
          text={t("Add Role")}
          disabled={
            !(
              rolesId &&
              controlledItems?.filter((ele) => ele.check === true).length > 0
            )
          }
          onClick={handleAdd}
        />
        <DefaultButton text={t("Close")} onClick={handleClose} />
      </DialogFooter>
    </BaseDialog>
  );
};
