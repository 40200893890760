import { Dropdown } from "@fluentui/react";

import { conditionColor } from "../../../../modules/machine-cv/constants";
import type { TreeItem } from "./index";
import { useTranslation } from "react-i18next";

type NodeTooltipProps = {
  data: TreeItem;
};

const options = [
  { key: "1", text: "Option 1" },
  { key: "2", text: "Option 2" },
  { key: "3", text: "Option 3" },
];

const NodeTooltip = ({ data }: NodeTooltipProps) => {
  const { t } = useTranslation();
  const { name, icon, condition } = data;

  return (
    <>
      {/*{name && <span className='name'>{name}</span>}*/}
      {icon && <div className="icon">{icon}</div>}
      <div className="content-container">
        <div className="content-line">
          <span>{t("Assigned to:")}</span>
          <span className="content-span">
            <Dropdown
              options={options}
              styles={{
                root: { width: "100%", padding: 0 },
                dropdown: { width: "100%" },
              }}
              onChange={(_, item) => {
                console.log(item);
              }}
            />
          </span>
          {/*<span className='content-span'>{name}</span>*/}
        </div>
        <div className="content-line">
          <span>{t("Name:")}</span>
          <span className="content-span">{name}</span>
        </div>
        <div className="content-line">
          <span>{t("Parent:")}</span>
          <span className="content-span">{name}</span>
        </div>
        {condition && (
          <div className="content-line">
            <span>{t("Status:")}</span>
            <span className="content-span">
              {condition}
              <span
                style={{ background: conditionColor[condition] }}
                className="condition"
              />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default NodeTooltip;
