import { Toggle } from "@fluentui/react";

import { MAX_GROUP_THRESHOLD } from "../../../../../../modules/analysis-trend-view/constants/controlOptions";
import usePerformanceStore from "../../../hooks/usePerformanceStore";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  const { selectedSignals, isGroupedEverything, updateStore } =
    usePerformanceStore((store: any) => ({
      selectedSignals: store.selectedSignals,
      isGroupedEverything: store.isGroupedEverything,
      updateStore: store.updateStore,
    }));

  function handleGroupSignalsChange(_: any, checked: boolean | undefined) {
    return selectedSignals.length > MAX_GROUP_THRESHOLD
      ? updateStore({ isWarningModalOpen: true })
      : updateStore({ isGroupedEverything: checked });
  }

  return (
    <div className="mb-8 torque-header">
      <div className="semibold-title">{t("Statistics")}</div>

      <Toggle
        inlineLabel
        checked={isGroupedEverything}
        label={t("Group signals")}
        styles={{ root: { marginBottom: 0, marginLeft: 20 } }}
        onChange={handleGroupSignalsChange}
      />
    </div>
  );
};

export default Header;
