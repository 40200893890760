/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type * as am5xy from "@amcharts/amcharts5/xy";
import type {
  IButtonStyles,
  IScrollablePaneStyleProps,
  IScrollablePaneStyles,
  IStackProps,
  IStackStyles,
  IStyle,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  ActionButton,
  Checkbox,
  CommandButton,
  IconButton,
  mergeStyles,
  mergeStyleSets,
  ScrollablePane,
  SearchBox,
  Stack,
  Text,
} from "@fluentui/react";
import type { PropsWithChildren, SVGProps } from "react";
import { useState } from "react";

import CharLayoutIcon from "../../../../../../assets/svg/ChartLayoutIcon";
import { hamburgerIcon } from "../../../../../../modules/analysis-raw-data/constants/icons";
import { maximizeButton } from "../../../../../../modules/analysis-raw-data/styles/ChartSection.styles";
import ChartLayoutModal from "../../../../../../modules/analysis-trend-view/components/ChartLayoutModal";
import useLayoutSettingsStore from "../../../../../../modules/analysis-trend-view/components/ChartLayoutModal/useLayoutSettingsStore";
import { format as signalFormatter } from "../../../../../../modules/common/utils/signalFormatter";
import type { ResponseSimplifiedSignal } from "../../../../../../types";

import useMopMaximizeStore from "./useMopMaximizeStore";
import { useTranslation } from "react-i18next";

type BaseProps = IStackProps & {
  signals: ResponseSimplifiedSignal[];
  selectedSignals: ResponseSimplifiedSignal[];
  icon?: React.FC<SVGProps<SVGSVGElement>>;
  onSelectedChanged?: (signals: ResponseSimplifiedSignal[]) => void;
  floatingText?: string;
};

type MopChartContainerProps = BaseProps & {
  chart?: am5xy.XYChart;
};

type SignalsCheckboxPanelProps = BaseProps & {
  show: boolean;
  max: number;
};

const containerStyles: IStackStyles = {
  root: { position: "relative" },
};

const backgroundStyle: IStyle = {
  position: "absolute",
  top: "0px",
  left: "0px",
  width: "100%",
  height: "100%",
  content: "''",
  opacity: "0.4",
  background: "rgb(255, 255, 255)",
};

const signalsPanelStyles: IStackStyles = {
  root: {
    position: "absolute",
    top: "-0.5em",
    left: "-0.5em",
    width: "100%",
    maxWidth: "25em",
    height: "calc(100% + 1em)",
    background: "white",
    padding: "1em",
    boxShadow: "2px 0px 4px 0px rgba(0,0,0,0.1)",
    zIndex: "3",
  },
};

const scrollablePaneStyles: IStyleFunctionOrObject<
  IScrollablePaneStyleProps,
  IScrollablePaneStyles
> = {
  root: {
    margin: "6em 0.25em 1em 1em",
  },
};

const floatingPanelStyles: IStackStyles = {
  root: {
    position: "absolute",
    top: "0em",
    left: "0em",
    zIndex: "4",
    background: "white",
    width: "100%",
  },
};

const hamburgerButtonStyles: IButtonStyles = {
  root: {
    alignSelf: "center",
    color: "#2C529F",
    width: "2em",
    height: "2em",
  },
};

const iconCheckboxStyle: IStyle = {
  width: "17px",
  marginLeft: "0.5em",
};

const SignalsCheckboxPanel = ({
  show,
  signals,
  selectedSignals,
  max,
  onSelectedChanged,
  icon,
  ...rest
}: SignalsCheckboxPanelProps) => {
  const [searchText, setSearchText] = useState<string>("");

  // Handlers
  const onCheckedChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    const signalId = ev?.currentTarget.getAttribute("name");
    if (!signalId) {
      return;
    }

    let currentSignals = [...selectedSignals];
    if (checked === true) {
      const signal = signals!.find((s) => s.id === signalId);
      signal && currentSignals.push(signal);
    } else {
      currentSignals = currentSignals.filter((s) => s.id !== signalId);
    }

    onSelectedChanged?.(currentSignals);
  };

  return (
    <Stack
      {...rest}
      styles={mergeStyleSets(signalsPanelStyles, {
        root: { display: show ? "flex" : "none" },
      })}
    >
      <SearchBox
        styles={{ root: { marginTop: "2em" } }}
        onChange={(_, newValue) => setSearchText(newValue ?? "")}
      />
      <ScrollablePane styles={scrollablePaneStyles}>
        <Stack tokens={{ childrenGap: 10 }}>
          {signals?.map((signal) => {
            const checked = selectedSignals
              .map((s) => s.id)
              .includes(signal.id);
            const visible =
              searchText.length === 0 ||
              signal.name
                ?.toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase());
            return (
              <Checkbox
                key={signal.id}
                name={signal.id}
                label={signalFormatter(signal)}
                checked={checked}
                disabled={!checked && selectedSignals.length === max}
                styles={{
                  root: { display: visible ? "flex" : "none" },
                  label: { textAlign: "left", width: "22em" },
                }}
                onRenderLabel={(props, defaultRenderer) => {
                  return (
                    <Stack
                      horizontal
                      verticalAlign="center"
                      tokens={{ childrenGap: 5 }}
                    >
                      {icon?.({ className: mergeStyles(iconCheckboxStyle) })}
                      <Stack.Item
                        grow
                        styles={{ root: { width: "100%", marginLeft: "1em" } }}
                      >
                        {defaultRenderer?.(props)}
                      </Stack.Item>
                    </Stack>
                  );
                }}
                onChange={onCheckedChange}
              />
            );
          })}
        </Stack>
      </ScrollablePane>
    </Stack>
  );
};

const MopChartContainer = ({
  signals,
  selectedSignals,
  icon,
  onSelectedChanged,
  floatingText,
  chart,
  children,
  ...rest
}: PropsWithChildren<MopChartContainerProps>) => {
  const { t } = useTranslation();
  const [isSignalsDialogOpened, setIsSignalsDialogOpened] =
    useState<boolean>(false);
  const { isChartLayoutOpen, updateStore } = useLayoutSettingsStore(
    (store: any) => ({
      isChartLayoutOpen: store.isChartLayoutOpen,
      updateStore: store.updateStore,
    })
  );
  const { isMaximized, maximizeAction, maximizeIcon, maximizeLabel } =
    useMopMaximizeStore();

  return (
    <Stack
      {...rest}
      verticalFill
      styles={mergeStyleSets(containerStyles, {
        root: {
          "::after": mergeStyleSets(backgroundStyle, {
            display: isSignalsDialogOpened ? "flex" : "none",
          }),
          height: isMaximized ? "80vh" : "100%",
        },
      })}
    >
      <Stack
        horizontal
        tokens={{ childrenGap: 5 }}
        styles={floatingPanelStyles}
      >
        <IconButton
          iconProps={hamburgerIcon}
          styles={hamburgerButtonStyles}
          title={t("Select signals")}
          onClick={() => setIsSignalsDialogOpened(!isSignalsDialogOpened)}
        />

        <ActionButton
          allowDisabledFocus
          styles={{
            root: { display: isSignalsDialogOpened ? "none" : "flex" },
          }}
          onClick={() => updateStore({ isChartLayoutOpen: true })}
        >
          <CharLayoutIcon />
          <span style={{ marginLeft: 8 }}>{t("Chart Layout")}</span>
        </ActionButton>

        {floatingText && (
          <Stack
            horizontal
            horizontalAlign="center"
            verticalAlign="center"
            styles={{
              root: {
                display: isSignalsDialogOpened ? "none" : "flex",
              },
            }}
          >
            <Text variant="smallPlus" as="span">
              {floatingText}
            </Text>
          </Stack>
        )}
        <Stack horizontal style={{ flexGrow: 1 }} horizontalAlign="end">
          <ActionButton
            allowDisabledFocus
            styles={maximizeButton}
            onClick={() => maximizeAction()}
          >
            {maximizeIcon}
            <span>{t(maximizeLabel)}</span>
          </ActionButton>
        </Stack>
      </Stack>
      <SignalsCheckboxPanel
        signals={signals}
        selectedSignals={selectedSignals}
        show={isSignalsDialogOpened}
        max={6}
        icon={icon}
        onSelectedChanged={onSelectedChanged}
      />
      {chart && isChartLayoutOpen && (
        <ChartLayoutModal
          series={chart.series.values}
          yAxes={chart.yAxes.values}
          onDismiss={() => updateStore({ isChartLayoutOpen: false })}
        />
      )}
      <Stack.Item grow styles={{ root: { paddingTop: "2em" } }}>
        {children}
      </Stack.Item>
    </Stack>
  );
};

export default MopChartContainer;
