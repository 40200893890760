import { useMachineConditionsTable } from "../../../../../Machines/useMachineConditionsTable";

export default function Slice({
  onClick,
  pathData,
  color,
  dataKey,
  dataValue,
}: any) {
  const { filterRegistry } = useMachineConditionsTable();

  const isActive =
    typeof filterRegistry?.[dataKey] !== "undefined"
      ? filterRegistry?.[dataKey] === dataValue
      : true;

  return (
    <g
      style={{
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <path
        style={{ opacity: isActive ? "100%" : "15%" }}
        d={pathData}
        fill={color}
      />

      {/* Uncomment if we need text inside the PieChart */}
      {/* <text
        x={labelX}
        y={labelY}
        textAnchor="middle"
        alignmentBaseline="middle"
        fill="#000"
        fontSize="12px"
      >
        {text}
      </text> */}
    </g>
  );
}
