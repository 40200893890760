import type { ISpinButtonProps } from "@fluentui/react";
import { SpinButton } from "@fluentui/react";
import { Controller } from "react-hook-form";

import type { BaseFormFieldProps } from "../types";
import { FormGroup } from "./FormGroup";
import { useTranslation } from "react-i18next";

export type FormNumberFieldProps = BaseFormFieldProps &
  ISpinButtonProps & {
    name: string;
  };

export const FormNumberField = ({
  name,
  groupProps,
  hookProps: { control },
  ...rest
}: FormNumberFieldProps) => {
  const { t } = useTranslation();

  return (
    <FormGroup {...groupProps}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          function onSpinButtonChange(_: any, newValue: string | undefined) {
            onChange(newValue);
          }
          return (
            <SpinButton
              min={0}
              max={100}
              step={1}
              value={value}
              incrementButtonAriaLabel={t("Increase value by 1")}
              decrementButtonAriaLabel={t("Decrease value by 1")}
              onChange={onSpinButtonChange}
              onBlur={onBlur}
              {...rest}
            />
          );
        }}
      />
    </FormGroup>
  );
};
