import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";
import { listServiceAccounts } from "./api";
import { ServiceAccount, UserExtendedProperties } from "./models";
import { t } from "i18next";

export const listAsyncServiceAccount = createAsyncThunk(
  "serviceAccounts/list",
  async () => {
    const response = await listServiceAccounts();
    return response;
  }
);

export interface ServiceAccountsState {
  serviceAccounts: ServiceAccount[];
  serviceAccountsDdpProperties: UserExtendedProperties[];
  status: Status;
  error: string;
}

const initialState: ServiceAccountsState = {
  serviceAccounts: [],
  serviceAccountsDdpProperties: [],
  status: Status.void,
  error: "",
};

export const serviceAccountsSlice = createSlice({
  name: "serviceAccounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncServiceAccount.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncServiceAccount.fulfilled, (state, action) => {
        state.status = Status.idle;
        const data: ServiceAccount[] = action.payload;
        state.serviceAccounts = data;
        state.serviceAccountsDdpProperties = data
          .map((ser) => ser.ddpProperties)
          .filter((ele) => (ele ? true : false));
      })
      .addCase(listAsyncServiceAccount.rejected, (state) => {
        state.status = Status.error;
        state.error = t(
          "Error loading Service Accounts. Please try again later."
        );
      });
  },
});

export const selectServiceAccounts = (state: RootState) =>
  state.serviceAccounts.serviceAccounts;

export const selectServiceAccountsDdpProperties = (state: RootState) =>
  state.serviceAccounts.serviceAccountsDdpProperties;

export const selectServiceAccountsStatus = (state: RootState) =>
  state.serviceAccounts.status;

export const selectServiceAccountsError = (state: RootState) =>
  state.serviceAccounts.error;

export const selectServiceAccountByMemberId =
  (id: string) => (state: RootState) => {
    if (
      state.serviceAccounts &&
      state.serviceAccounts.serviceAccounts &&
      state.serviceAccounts.serviceAccounts.length > 0
    ) {
      return state.serviceAccounts.serviceAccounts.find(
        (c: ServiceAccount) => c.ddpProperties.memberId === id
      );
    }

    return undefined;
  };

export default serviceAccountsSlice.reducer;
