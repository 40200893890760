import React from "react";

import { SecondPanelContext } from "../SubmissionsDetails";
import { SensorNodesSubmission } from "../models";
import { TextFieldWithDocuments } from "../TextFieldWithDocuments";
import { useTranslation } from "react-i18next";

interface SensorFieldsProps {
  context: SecondPanelContext;
}

export const SensorFields: React.FC<SensorFieldsProps> = ({ context }) => {
  const { t } = useTranslation();
  const { initialValues, updateFunction } = context;
  const sensor = initialValues.sen;

  if (!sensor) return null;

  const handleFieldChange = (newValue: string) => {
    const senNew: SensorNodesSubmission = {
      sensorSerialNumber: newValue.trim(),
      uniqueId: sensor.uniqueId,
    };
    updateFunction(senNew, sensor.uniqueId);
  };

  return (
    <TextFieldWithDocuments
      fieldName="sensorSerialNumber"
      onChange={handleFieldChange}
      value={sensor.sensorSerialNumber || ""}
      disabled={context.context !== "Sensor Node"}
      label={t("Sensor Serial Number")}
      files={sensor.files}
    />
  );
};
