import { useState } from "react";
import UploadImageBasicDialog from "./UploadImageBasicDialog";
import { ImageType } from "../models";
import { notification } from "../../common/Notification";
import { Checkbox } from "@fluentui/react";
import { MachineToList } from "../../Machines/models";
import MachineFormItems from "../../Generic/MachineFormItems";
import ImagesUploadProgressDialog from "./ImagesUploadProgressDialog";
import { useTranslation } from "react-i18next";

type UploadImageDialogProps = {
  onClose: (listHasChanged: boolean) => void;
  title?: string;
  accept?: string;
  imageType?: ImageType;
};

/**
 * Gets the upload image with machine dialog component.
 * @param title The dialog title.
 * @param accept The supported files to accept.
 * @param imageType The image type expected to upload.
 * @param onClose Method called to close this dialog.
 * @returns The upload image with machine dialog component.
 */
export const UploadImageWithMachineDialog = ({
  title,
  accept,
  imageType,
  onClose,
}: UploadImageDialogProps) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [machine, setMachine] = useState<MachineToList>(null);
  const [files, setFiles] = useState<File[]>([]);

  // Handlers
  const onSubmit = () => {
    setIsUploading(true);
  };

  const onUploadFinished = (dataChanged: boolean) => {
    setIsUploading(false);
    if (dataChanged) {
      onClose?.(true);
    } else {
      notification.error(
        t(
          "Failure uploading files. Check your internet connection, as well as the images you want to upload."
        )
      );
      setFiles([]);
    }
  };

  let result: JSX.Element;
  if (isUploading) {
    result = (
      <ImagesUploadProgressDialog
        imageType={imageType}
        files={files}
        additionalData={{ machineId: machine?.id, dalogId: machine?.dalogId }}
        onClose={onUploadFinished}
      />
    );
  } else {
    result = (
      <UploadImageBasicDialog
        title={title}
        accept={accept}
        isValid={machine !== null && files.length > 0}
        isLoading={false}
        onFilesSelected={setFiles}
        onSubmit={onSubmit}
        onClose={onClose}
      >
        <MachineFormItems
          initialMachineId={""}
          onMachineSelected={setMachine}
        />
      </UploadImageBasicDialog>
    );
  }

  return result;
};

/**
 * Gets the upload FLS image dialog component.
 * @param onClose Method called to close this dialog.
 * @returns The upload FLS  image dialog component.
 */
export const UploadImageFlsDialog = ({ onClose }: UploadImageDialogProps) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);

  // Handlers
  const onSubmit = () => {
    setIsUploading(true);
  };

  const onUploadFinished = (dataChanged: boolean) => {
    setIsUploading(false);
    if (dataChanged) {
      onClose?.(true);
    } else {
      notification.error(
        t(
          "Failure uploading files. Check your internet connection, as well as the images you want to upload."
        )
      );
      setFiles([]);
    }
  };

  let result: JSX.Element;
  if (isUploading) {
    result = (
      <ImagesUploadProgressDialog
        imageType={ImageType.FLS}
        files={files}
        onClose={onUploadFinished}
      />
    );
  } else {
    result = (
      <UploadImageBasicDialog
        title={t("Add FLS Image")}
        accept="*"
        isValid={files.length > 0}
        isLoading={false}
        onFilesSelected={setFiles}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    );
  }

  return result;
};

/**
 * Gets the upload wireless image dialog component.
 * @param onClose Method called to close this dialog.
 * @returns The upload wireless image dialog component.
 */
export const UploadImageWirelessDialog = ({
  onClose,
}: UploadImageDialogProps) => {
  const { t } = useTranslation();
  const [isEncrypted, setIsEncrypted] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);

  // Handlers
  const onSubmit = () => {
    setIsUploading(true);
  };

  const onUploadFinished = (dataChanged: boolean) => {
    setIsUploading(false);
    if (dataChanged) {
      onClose?.(true);
    } else {
      notification.error(
        t(
          "Failure uploading files. Check your internet connection, as well as the images you want to upload."
        )
      );
      setFiles([]);
    }
  };

  let result: JSX.Element;
  if (isUploading) {
    result = (
      <ImagesUploadProgressDialog
        imageType={ImageType.Wireless}
        files={files}
        additionalData={{ isFileEncrypted: isEncrypted }}
        onClose={onUploadFinished}
      />
    );
  } else {
    result = (
      <UploadImageBasicDialog
        title={t("Add Wireless Image")}
        accept="*"
        isValid={files.length > 0}
        isLoading={false}
        onFilesSelected={setFiles}
        onSubmit={onSubmit}
        onClose={onClose}
      >
        <Checkbox
          label={t("Is the file encrypted?")}
          boxSide="end"
          required
          checked={isEncrypted}
          onChange={(_, checked) => setIsEncrypted(checked)}
          styles={{
            root: { padding: "1em 0px 0.5em 0px" },
            text: {
              fontSize: "14px",
              fontWeight: "600",
              color: "rgb(50, 49, 48)",
            },
          }}
        />
      </UploadImageBasicDialog>
    );
  }

  return result;
};
