import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../../schema/status";
import { RootState } from "../../../store";
import { fetchData } from "./api";
import { ProjectDetailsWithChilds } from "../models";
import { MachineListItem } from "../../Machines/models";
import { t } from "i18next";

export const listAsyncProjDetails = createAsyncThunk(
  "projectDetail",
  async (id: string) => {
    const response = await fetchData(id);
    var projectAll: ProjectDetailsWithChilds;
    projectAll = { ...response };
    return projectAll;
  }
);

export interface toSortbyKey {
  sort: boolean;
  key: string;
}

export interface ProjectDetailsState {
  ProjectDet: ProjectDetailsWithChilds;
  status: Status;
  error: string;
}

const initialState: ProjectDetailsState = {
  ProjectDet: {
    companyId: "",
    corporation: { id: "", name: "", number: "" },
    city: "",
    company: { name: "", corporationId: "", id: "", number: "" },
    continent: "",
    country: "",
    currentConditions: { results: [] },
    id: "",
    latitude: 0,
    longitude: 0,
    machines: [],
    name: "",
  },
  status: Status.loading,
  error: "",
};

export const ProjectDetailsSlice = createSlice({
  name: "ProjectDetails",
  initialState,
  reducers: {
    sortValues: (
      state: ProjectDetailsState,
      action: PayloadAction<toSortbyKey>
    ) => {
      const key = action.payload.key as keyof MachineListItem;
      state.ProjectDet.machines = state.ProjectDet.machines
        .slice(0)
        .sort((a: MachineListItem, b: MachineListItem) =>
          (!a[key] || a[key] === "") && (!b[key] || b[key] === "")
            ? 0
            : !a[key] || a[key] === ""
              ? 1
              : !b[key] || b[key] === ""
                ? -1
                : (
                      action.payload.sort
                        ? a[key]!.toString().toLowerCase() <
                          b[key]!.toString().toLowerCase()
                        : a[key]!.toString().toLowerCase() >
                          b[key]!.toString().toLowerCase()
                    )
                  ? 1
                  : -1
        );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncProjDetails.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncProjDetails.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.ProjectDet = action.payload;
      })
      .addCase(listAsyncProjDetails.rejected, (state) => {
        state.status = Status.error;
        state.error = t("Error loading corporations. Please try again later.");
      });
  },
});

export const selectProjMachines = (state: RootState) =>
  state.projectDetails.ProjectDet.machines;

export const selectProjDetails = (state: RootState) =>
  state.projectDetails.ProjectDet;

export const selectProjectDetailsStatus = (state: RootState) =>
  state.projectDetails.status;

export const selectProjectDetailsError = (state: RootState) =>
  state.projectDetails.error;

export const { sortValues } = ProjectDetailsSlice.actions;
export default ProjectDetailsSlice.reducer;
