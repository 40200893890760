import {
  Stack,
  Text,
  Icon,
  IStackStyles,
  ITextStyles,
  IIconStyles,
  useTheme,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";

export const WelcomeMessage = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const cardStyles: IStackStyles = {
    root: {
      background: theme.palette.white,
      boxShadow: theme.effects.elevation8,
      borderRadius: "8px",
      padding: "24px",
      maxWidth: "450px",
      width: "90%",
      margin: "20px auto",
      textAlign: "center",
    },
  };

  const iconStyles: IIconStyles = {
    root: {
      fontSize: 48,
      color: theme.palette.themePrimary,
      marginBottom: "16px",
    },
  };

  const titleStyles: ITextStyles = {
    root: {
      fontSize: "24px",
      fontWeight: 600,
      color: theme.palette.neutralPrimary,
      marginBottom: "16px",
    },
  };

  const messageStyles: ITextStyles = {
    root: {
      fontSize: "14px",
      color: theme.palette.neutralSecondary,
      marginBottom: "16px",
      lineHeight: "20px",
    },
  };

  const secondaryMessageStyles: ITextStyles = {
    root: {
      fontSize: "12px",
      color: theme.palette.neutralSecondary,
      lineHeight: "16px",
    },
  };

  return (
    <Stack horizontalAlign="center" verticalAlign="center" styles={cardStyles}>
      <Icon iconName="Clock" styles={iconStyles} />
      <Text styles={titleStyles}>{t("Welcome aboard!")}</Text>
      <Text styles={messageStyles}>
        {t(
          "Your account is all set up, but you don't have any permissions assigned yet. Our team has been notified and will set up your access shortly."
        )}
      </Text>
      <Text styles={secondaryMessageStyles}>
        {t(
          "Please check back in a few minutes. If you need immediate access, please contact your DALOG representative."
        )}
      </Text>
    </Stack>
  );
};
