import "./style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Stack } from "@fluentui/react";
import { useState } from "react";
import Slider from "react-slick";

import { useLocationSearch, useFileUpload } from "../../../Hooks";
import AddDocumentDialog from "../../../modules/machine-cv/components/MachineCVDialogs/AddDocumentDialog";
import { getConditionFormat } from "../../../modules/machine-cv/constants";
import Image from "../../common/Image";
import ImagePlaceholder from "../../common/ImagePlaceholder";
import { NoData } from "../../common/NoData";
import { Spin } from "../../common/Spin";
import type { UploadedFile } from "../../common/Uploader/types";

import useConditionsOverview from "../MachineCVSummaryPage/ConditionsSection/hooks";
import Overview from "../MachineCVSummaryPage/ConditionsSection/Overview";
import { TaskAddDialog } from "../MachineCVTasksPage/components/TaskAdd";
import List from "./components/List";
import useSensors from "./hooks";
import { useTranslation } from "react-i18next";

const styles = {
  pageContainer: {
    marginBottom: 16,
  },
};
interface ISliderImage {
  fileId: string;
  sortIndex: number;
}

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Page = () => {
  const { t } = useTranslation();
  const [{ id }] = useLocationSearch();

  const [addTask, setAddTask] = useState(false);
  const [addDocument, setAddDocument] = useState(false);

  const { data, isLoading, isError } = useConditionsOverview(
    { machineId: id as string },
    { cacheTime: 0 }
  );

  const sensorsResponse: any = useSensors({ machineId: id as string });

  const { uploadFilesAsyncWithNotifications } = useFileUpload();

  const onUpload = async (files: UploadedFile[]) => {
    await uploadFilesAsyncWithNotifications({ files, machineId: id || "" });
  };

  const onAddTask = () => setAddTask(true);
  const onAddDocument = () => setAddDocument(true);

  const hasDrawingIds = data ? data.machine.drawingIds.length > 0 : false;
  const showLoading = !data && isLoading;
  const showError = !isLoading && isError;
  const showData = !isLoading && data;

  return (
    <Stack style={styles.pageContainer}>
      {showLoading && <Spin style={{ height: "calc(100vh - 120px)" }} />}
      {showError && <NoData style={{ height: "calc(100vh - 120px)" }} />}
      {showData && (
        <>
          <Overview
            machine={data.machine}
            status={getConditionFormat(t).NoStatus}
            description={t("Overview of the sensor position")}
            title={t("Sensors")}
            onAddTask={() => setAddTask(true)}
            onAddDocument={() => setAddDocument(true)}
          />
          <Stack className="slider-container">
            <Slider {...sliderSettings}>
              {hasDrawingIds ? (
                data.machine.drawingIds.map((item: ISliderImage) => (
                  <Image
                    key={item.fileId}
                    id={item.fileId}
                    className="slick-slider-image"
                    alt={data.machine.name}
                  />
                ))
              ) : (
                <ImagePlaceholder
                  text={t("There is no image to be displayed")}
                />
              )}
            </Slider>
          </Stack>
          <List
            listItems={sensorsResponse?.sensors?.data || []}
            onAddTask={onAddTask}
            onAddDocument={onAddDocument}
          />
          <TaskAddDialog
            hidden={!addTask}
            onDismiss={() => setAddTask(false)}
          />
          <AddDocumentDialog
            hidden={!addDocument}
            onSubmit={onUpload}
            onClose={() => setAddDocument(false)}
          />
        </>
      )}
    </Stack>
  );
};

export default Page;
