export enum SignalSource {
  Unknown = "Unknown",
  OpcUa = "OpcUa",
  Mqtt = "MQTT",
}

export enum OpcUaAccessMode {
  Unknown = "Unknown",
  Polling = "Polling",
  Subscription = "Subscription",
}

export enum OpcUaDataType {
  Boolean = "Boolean",
  SByte = "SByte",
  Byte = "Byte",
  Int16 = "Int 16",
  UInt16 = "UInt 16",
  Int32 = "Int 32",
  UInt32 = "UInt 32",
  Int64 = "Int 64",
  UInt64 = "UInt 64",
  Float = "Float",
  Double = "Double",
  String = "String",
  DateTime = "DateTime",
  Guid = "Guid",
  ByteString = "Byte String",
  XmlElement = "Xml Element",
  NodeId = "Node Id",
  ExpandedNodeId = "Expanded Node Id",
  StatusCode = "Status Code",
  QualifiedName = "Qualified Name",
  LocalizedText = "Localized Text",
  ExtensionObject = "Extension Object",
  DataValue = "Data Value",
  Variant = "Variant",
  DiagnosticInfo = "Diagnostic Info",
}

export type CreateHaagSignalMapping = {
  source: keyof typeof SignalSource;
  sourceIdentifier: string;
  nodeId: string;
  namespaceIndex: number;
  deviceName: string;
  componentName: string;
  metricName: string;
  accessMode: keyof typeof OpcUaAccessMode;
  periodSec: number;
  unit: string;
  dataType: keyof typeof OpcUaDataType;
  plantId?: string;
};

export type HaagSignalMapping = CreateHaagSignalMapping & {
  id: string;
  machineId: string;
  signalId: string;
};
