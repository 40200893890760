import {
  DialogFooter,
  DialogType,
  Dropdown,
  PrimaryButton,
  Text,
} from "@fluentui/react";
import { useCallback, useState } from "react";
import { notification } from "../../../../common/Notification";
import { useTranslation } from "react-i18next";
import BaseDialog, { DialogSize } from "../../../../common/Dialog";

export const measurementSettingOptions = [
  {
    key: "MeasSet0",
    text: "MeasSet0",
  },
  {
    key: "MeasSet1",
    text: "MeasSet1",
  },
  {
    key: "MeasSet4",
    text: "MeasSet4",
  },
];

interface ManualTriggerSettingsProps {
  isOpen: boolean;
  onDismiss: () => void;
  onSubmit: (selectedKeys: string[]) => Promise<void>;
  trendAndRaw?: boolean;
}

const ManualTriggerSettings = ({
  isOpen,
  onDismiss,
  onSubmit,
  trendAndRaw = false,
}: ManualTriggerSettingsProps) => {
  const { t } = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const availableOptions = measurementSettingOptions.filter(
    (option) => trendAndRaw || option.key === "MeasSet0"
  );

  const validateSelection = useCallback(
    (newSelection: string[]): boolean => {
      if (!trendAndRaw) {
        return (
          newSelection.length <= 1 &&
          (!newSelection.length || newSelection[0] === "MeasSet0")
        );
      }
      if (newSelection.length <= 1) return true;
      if (newSelection.length > 2) return false;
      const hasSet0 = newSelection.includes("MeasSet0");
      const hasSet1 = newSelection.includes("MeasSet1");
      const hasSet4 = newSelection.includes("MeasSet4");
      return hasSet0 && ((hasSet1 && !hasSet4) || (hasSet4 && !hasSet1));
    },
    [trendAndRaw]
  );

  const onDropdownChange = useCallback(
    (e: any, item: any) => {
      const { key, selected } = item || {};
      const newSelection = selected
        ? [...selectedKeys, key]
        : selectedKeys.filter((keyName) => keyName !== key);
      if (validateSelection(newSelection)) {
        setSelectedKeys(newSelection);
      } else {
        notification.error(t("Invalid measurement set combination"));
      }
    },
    [selectedKeys, validateSelection]
  );

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit(selectedKeys);
      onDismiss();
    } catch (error) {
      notification.error(t("Manual trigger error"));
    } finally {
      setLoading(false);
    }
  };

  const getSelectionDescription = () => {
    if (!trendAndRaw) {
      return "MeasSet0 only";
    }
    return t("Select either a single MeasSet or MeasSet0 + MeasSet1/4");
  };

  return (
    <BaseDialog
      hidden={!isOpen}
      size={DialogSize.S}
      dialogContentProps={{
        title: t("On-demand Trigger"),
        type: DialogType.close,
      }}
      onDismiss={onDismiss}
    >
      <Text
        variant="small"
        style={{
          display: "block",
          color: "#666",
          marginBottom: "8px",
        }}
      >
        {getSelectionDescription()}
      </Text>

      <Dropdown
        multiSelect
        disabled={loading}
        selectedKeys={selectedKeys}
        options={availableOptions}
        styles={{ root: { width: "100%", marginBottom: "20px" } }}
        onChange={onDropdownChange}
      />

      <DialogFooter>
        <PrimaryButton
          disabled={loading || selectedKeys.length === 0}
          text={loading ? t("Triggering...") : t("Trigger sensor")}
          onClick={handleSubmit}
        />
      </DialogFooter>
    </BaseDialog>
  );
};

export default ManualTriggerSettings;
