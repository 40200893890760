import Table, { Column } from "../../common/Table";
import { ImagesStatistics } from "../models";
import { withLoadingPanelHOC } from "../../Generic/HOCs";

import { useTranslation } from "react-i18next";

const getTableColumns: (t) => Column[] = (t) => [
  {
    key: "average",
    name: t("Average"),
    fieldName: "average",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "median",
    name: t("Median"),
    fieldName: "median",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "minimum",
    name: t("Minimum"),
    fieldName: "minimum",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "maximum",
    name: t("Maximum"),
    fieldName: "maximum",
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "count",
    name: t("Count"),
    fieldName: "count",
    minWidth: 100,
    isSortable: true,
  },
];

type ImagesStatisticsTableProps = {
  tableItems: ImagesStatistics[];
  isLoading: boolean;
};

/**
 * Gets the images statistics table component.
 * @param tableItems The table items.
 * @param isLoading Value determining whether the table is in loading state.
 * @returns The images statistics table component.
 */
const ImagesStatisticsTable = ({
  tableItems,
  isLoading,
}: ImagesStatisticsTableProps) => {
  const { t } = useTranslation();

  return withLoadingPanelHOC(
    t,
    !isLoading,
    <Table
      items={tableItems}
      columns={getTableColumns(t)}
      header={{ title: t("Images statistics") }}
      hasSelection={false}
      persistOpts={{
        key: "images-statistics-table",
        version: 2,
      }}
    />
  );
};

export default ImagesStatisticsTable;
