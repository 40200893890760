import BaseDialog, { DialogSize } from "../common/Dialog";
import {
  DialogType,
  DialogFooter,
  IDialogProps,
  PrimaryButton,
  Spinner,
  DefaultButton,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import { CSSProperties, useState } from "react";
import { DashBoardToList } from "./models";
import { deleteDashboard } from "./api";
import { useTranslation } from "react-i18next";

const textStyle: CSSProperties = {
  fontSize: 14,
  lineHeight: "20px",
  marginBottom: 16,
};

type DeleteDialogProps = IDialogProps & {
  data: DashBoardToList;
  show: boolean;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
};

export const DeleteConfirm = ({
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: DeleteDialogProps) => {
  const { t } = useTranslation();
  const [isValid, setValid] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (!isValid) {
      return;
    }

    setLoading(true);

    await deleteDashboard(data.id).then((response) =>
      onSuccess("status" in response)
    );

    handleClose();
  };

  const validate = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = (e.target as HTMLInputElement).value;

    const parsedValue = value.trim().toLowerCase();

    setValid(data.parentName.toLowerCase() === parsedValue);
  };

  const handleClose = () => {
    // reset state
    setLoading(false);

    setValid(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        title: t("Attention!"),
        type: DialogType.close,
        onDismiss: handleClose,
      }}
      size={DialogSize.M}
    >
      <div style={textStyle}>
        {t("You are about to delete selected Dashboard.")}
      </div>
      <TextField
        required
        label={t("Please confirm typing the name of the parent:")}
        onChange={validate}
      />
      <DialogFooter>
        <PrimaryButton
          text={t("Delete")}
          disabled={isLoading || !isValid}
          onClick={handleDelete}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton text={t("Close")} onClick={handleClose} />
      </DialogFooter>
    </BaseDialog>
  );
};
