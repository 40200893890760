import { AxiosRequestConfig, AxiosResponse } from "axios";
import { axiosInstance } from "../../../..";
import { CreateHaagSignalMapping, HaagSignalMapping } from "./models";

export class HaagMappingApi {
  static getAll = async (
    machineId: string
  ): Promise<AxiosResponse<HaagSignalMapping[], any>> => {
    const response = await axiosInstance.get<HaagSignalMapping[]>(
      `/meta/read/internal/v1/machines/${machineId}/haag/mappings`
    );

    return response;
  };

  static create = async (machineId: string, data: CreateHaagSignalMapping) => {
    const response = await axiosInstance.post<HaagSignalMapping>(
      `/meta/write/v1/machines/${machineId}/haag/mappings`,
      data
    );

    return response;
  };

  static import = async (
    machineId: string,
    sourceIdentifier: string,
    file: File
  ) => {
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = new FormData();
    body.append("file", file);

    const response = await axiosInstance.post<HaagSignalMapping[]>(
      `/meta/write/v1/machines/${machineId}/haag/mappings/batch/${sourceIdentifier}`,
      body,
      config
    );

    return response;
  };

  static update = async (
    machineId: string,
    id: string,
    data: CreateHaagSignalMapping
  ) => {
    const response = await axiosInstance.put<CreateHaagSignalMapping>(
      `/meta/write/v1/machines/${machineId}/haag/mappings/${id}`,
      data
    );

    return response;
  };

  static delete = async (machineId: string, id: string) => {
    const response = await axiosInstance.delete<HaagSignalMapping>(
      `/meta/write/v1/machines/${machineId}/haag/mappings/${id}`
    );

    return response;
  };

  static deleteAll = async (machineId: string, sourceIdentifier: string) => {
    const response = await axiosInstance.delete<HaagSignalMapping[]>(
      `/meta/write/v1/machines/${machineId}/haag/mappings/batch/${sourceIdentifier}`
    );

    return response;
  };
}
