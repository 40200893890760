export interface DropdownOption {
  key: string;
  text: string;
}

export const getPeriodDropdownOptions: (t) => DropdownOption[] = (t) => [
  { key: "All data", text: t("All data") },
  { key: "Last 2 years", text: t("Last 2 years") },
  { key: "Last year", text: t("Last year") },
  { key: "Last quarter", text: t("Last quarter") },
  { key: "Last month", text: t("Last month") },
  { key: "Last week", text: t("Last week") },
  { key: "Custom date", text: t("Custom date") },
];

export const getViewDropdownOptions: (t) => DropdownOption[] = (t) => [
  { key: "Yearly", text: t("Yearly") },
  { key: "Monthly", text: t("Monthly") },
  { key: "Daily", text: t("Daily") },
];

export type MopSignalData<T> = {
  id: string;
  name: string;
  unit: string;
  data?: T[];
};

export type XAxisDates = {
  start?: Date;
  end?: Date;
};
