import "./style.scss";

import type { IComboBox, IComboBoxOption } from "@fluentui/react";
import { ComboBox, Label, Slider, SpinButton, Stack } from "@fluentui/react";
import { useCallback, useMemo } from "react";

import {
  FACTOR_LIMITS,
  OFFSET_LIMITS,
  sliderStyles,
  spinButtonStyles,
} from "../../constants";
import useLayoutSettingsStore from "../../useLayoutSettingsStore";

import { useTranslation } from "react-i18next";

export default function YAxisSettings() {
  const {
    step,
    selectedYAxisIndex,
    yAxesSettings,
    updateYAxisSliderSetting,
    updateStore,
  } = useLayoutSettingsStore((store: any) => ({
    step: store.step,
    selectedYAxisIndex: store.selectedYAxisIndex,
    yAxesSettings: store.yAxesSettings,
    updateStore: store.updateStore,
    updateYAxisSliderSetting: store.updateYAxisSliderSetting,
  }));

  const { t } = useTranslation();

  const settingsStore = yAxesSettings?.[selectedYAxisIndex];

  const factor = settingsStore?.factor || 0;
  const offset = settingsStore?.offset || 0;

  const Y_AXIS_SETTINGS = useMemo(() => {
    return [
      {
        label: t("Factor"),
        id: "factor",
        min: FACTOR_LIMITS.min,
        max: FACTOR_LIMITS.max,
        sliderValue: factor,
        disabled: false,
        step,
      },

      {
        label: t("Offset"),
        id: "offset",
        min: OFFSET_LIMITS.min,
        max: OFFSET_LIMITS.max,
        sliderValue: offset,
        disabled: factor < -95 ? true : false,
        step,
      },
    ];
  }, [offset, factor, step, t]);

  const incremenetOptions: IComboBoxOption[] = [
    { key: "0.1", text: "0.1" },
    { key: "0.5", text: "0.5" },
    { key: "1", text: "1" },
    { key: "5", text: "5" },
    { key: "10", text: "10" },
  ];

  const renderYAxisSetting = useCallback((setting: any) => {
    const { label, id, sliderValue, min, max, step, disabled } = setting;

    return (
      <Stack key={label} className="chart-layout-modal_setting">
        <Label>{label}</Label>
        <div className="chart-layout-modal_setting--content">
          <SpinButton
            disabled={disabled}
            value={String(sliderValue)}
            min={min}
            max={max}
            step={step}
            styles={spinButtonStyles}
            onChange={(_: any, newValue: string | undefined) => {
              const numValue = Number(newValue);
              updateYAxisSliderSetting(numValue, id);
            }}
          />
          <div className="chart-layout-modal_setting--slider">
            <Slider
              originFromZero
              showValue={false}
              min={min}
              max={max}
              step={step}
              styles={sliderStyles}
              value={sliderValue}
              onChange={(value) => updateYAxisSliderSetting(value, id)}
            />

            {/* <SliderArrow
              customFunc={() => increment({ setting: id, changeValue: updateYAxisSliderSetting, max })}
              iconName='ChevronRight'
            /> */}
          </div>
        </div>
      </Stack>
    );
  }, []);

  return (
    <>
      <ComboBox
        selectedKey={String(step)}
        label={t("Increment value")}
        options={incremenetOptions}
        styles={{ root: { maxWidth: 140 } }}
        onChange={(
          event: React.FormEvent<IComboBox>,
          option?: IComboBoxOption | undefined,
          index?: number | undefined,
          value?: string | undefined
        ) => {
          const numValue = Number(value);
          updateStore({ step: numValue });
        }}
      />

      <div className="input-description">
        {t("Use for fine tuning of Factor and Offset")}
      </div>

      {Y_AXIS_SETTINGS.map(renderYAxisSetting)}
    </>
  );
}
