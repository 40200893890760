/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState, useEffect } from "react";

import {
  FormItemProps,
  FormItemType,
  renderFormItems,
  useZodForm,
} from "../common/Form";
import BaseDialog from "../common/Dialog";

import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
  Text,
  Stack,
} from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { CreateSignal } from "./models";
import { ResponseSimplifiedSignal, Sensor } from "../Machines/models";

import { getOptionFromEnum, isEmptyString } from "../../schema/Utils";
import { ComboBoxCustomV1, propsCustomComboBoxV1 } from "../Generic/ComboBoxV1";
import { addSignal, deleteSignal, editSignal } from "./api";
import { maxLengthType1, textStyle } from "../../schema/Constants";
import {
  BooleanDefault,
  DataTypeSignals,
  SignalCondition,
} from "../../schema/status";
import { WirelessSensorNode } from "../SensorNodes/models";
import { list } from "../SensorNodes/api";

import { useTranslation } from "react-i18next";

const getSchema = (t) =>
  z
    .object({
      bufferId: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      sampleRateDalog: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      sampleRateInHz: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      isInternal: z.nativeEnum(BooleanDefault),
      name: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      configName: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      dataType: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      signalDescription: z.string().optional().nullable(),
      signalGroup: z.string().optional().nullable(),
      signalType: z.string().optional().nullable(),
      unit: z.string().optional().nullable(),
      feature: z.string().optional().nullable(),
      condition: z.nativeEnum(SignalCondition),
    })
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);

        if (input.bufferId) {
          return valid.test(input.bufferId);
        } else return true;
      },
      {
        path: ["bufferId"],
        message: t("Try a number"),
      }
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);

        if (input.sampleRateDalog) {
          return valid.test(input.sampleRateDalog);
        } else return true;
      },
      {
        path: ["sampleRateDalog"],
        message: t("Try a number"),
      }
    )
    .refine(
      (input) => {
        const valid = new RegExp(/^\d*\.?\d*$/);

        if (input.sampleRateInHz) {
          return valid.test(input.sampleRateInHz);
        } else return true;
      },
      {
        path: ["sampleRateInHz"],
        message: t("Try a number"),
      }
    );
const getFields: (t) => FormItemProps[] = (t) => [
  {
    name: "bufferId",
    type: FormItemType.TextField,
    groupProps: { label: t("Buffer Id *") },
  },
  {
    name: "sampleRateDalog",
    type: FormItemType.TextField,
    groupProps: { label: t("Sample Rate Dalog *") },
  },
  {
    name: "sampleRateInHz",
    type: FormItemType.TextField,
    groupProps: { label: t("Sample Rate In Hz *") },
  },
  {
    name: "isInternal",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Is Internal *") },
    options: getOptionFromEnum(t, BooleanDefault),
  },
  {
    name: "name",
    type: FormItemType.TextField,
    groupProps: { label: t("Name *") },
  },
  {
    name: "configName",
    type: FormItemType.TextField,
    groupProps: { label: t("Config Name *") },
  },
  {
    name: "dataType",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Data Type *") },
    options: getOptionFromEnum(t, DataTypeSignals),
  },
  {
    name: "signalDescription",
    type: FormItemType.TextArea,
    groupProps: { label: t("Signal Description") },
  },
  {
    name: "signalGroup",
    type: FormItemType.TextField,
    groupProps: { label: t("Signal Group") },
  },
  {
    name: "signalType",
    type: FormItemType.TextField,
    groupProps: { label: t("Signal Type") },
  },
  {
    name: "unit",
    type: FormItemType.TextField,
    groupProps: { label: t("Unit") },
  },
  {
    name: "feature",
    type: FormItemType.TextField,
    groupProps: { label: t("Feature") },
  },
  {
    name: "condition",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Condition") },
    options: getOptionFromEnum(t, SignalCondition),
  },
];

type AddOrEditDialogProps = IDialogProps & {
  machineId: string;
  data: ResponseSimplifiedSignal | string | undefined;
  sensors: Sensor[];
  sensorNodes?: WirelessSensorNode[];
  show: boolean;
  onSuccess: (hasError: boolean, context: "add" | "edit" | "delete") => void;
  onClose: () => void;
  context: "add" | "edit" | "delete";
};

export const CUDDialog = ({
  machineId,
  data,
  sensors,
  sensorNodes,
  show,
  onSuccess,
  onClose,
  context,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(t), [t]);

  const [isLoading, setLoading] = useState(false);
  const [idSelected, setIdSelected] = useState<string>(
    data && typeof data !== "string" && data?.sensorSerialNo
      ? data?.sensorSerialNo
      : ""
  );

  const [idSelectedSenNode, setIdSelectedSenNode] = useState<string>(
    data && typeof data !== "string" && data?.machineId ? data?.machineId : ""
  );

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data &&
      typeof data !== "string" && {
        defaultValues: {
          bufferId: "",
          sampleRateDalog: "",
          sampleRateInHz: data.sampleRateInHz?.toString(),
          isInternal: BooleanDefault.False,
          name: data.name,
          configName: data.configName,
          dataType: data.dataType,
          signalDescription: data.signalDescription,
          signalGroup: data.signalGroup,
          signalType: data.signalType,
          unit: data.unit,
          feature: data.feature,
          condition: data.condition,
        },
      }),
  });

  useEffect(() => {
    data && typeof data !== "string"
      ? reset({
          bufferId: "",
          sampleRateDalog: "",
          sampleRateInHz: data?.sampleRateInHz?.toString(),
          isInternal: BooleanDefault.False,
          name: data?.name,
          configName: data?.configName,
          dataType: data?.dataType,
          signalDescription: data?.signalDescription,
          signalGroup: data?.signalGroup,
          signalType: data?.signalType,
          unit: data?.unit,
          feature: data?.feature,
          condition: data?.condition,
        })
      : reset({
          bufferId: "",
          sampleRateDalog: "",
          sampleRateInHz: "",
          isInternal: BooleanDefault.False,
          name: "",
          configName: "",
          dataType: DataTypeSignals.Unknown,
          signalDescription: "",
          signalGroup: "",
          signalType: "",
          unit: "",
          feature: "",
          condition: SignalCondition.NoStatus,
        });
  }, [data, reset]);

  const argsIdField: propsCustomComboBoxV1 = {
    keyMe: 30,
    label: t("Select Sensor *"),
    options: sensors
      ?.map((sig) => ({
        key: sig.sensorSerialNo,
        text: sig.sensorSerialNo + " (" + sig.sensorNo + ")",
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1)),
    selection: setIdSelected,
    errorMessage: idSelected ? "" : t("Select a Sensor"),
    defaultKey: idSelected,
  };

  const textFComp = ComboBoxCustomV1(argsIdField);

  const argsIdField2: propsCustomComboBoxV1 = {
    keyMe: 40,
    label: t("Select Sensor Node"),
    options: sensorNodes
      ?.map((sen) => ({
        key: sen.id,
        text: sen.sensorNodeId,
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1)),
    selection: setIdSelectedSenNode,
    errorMessage: "",
    defaultKey: idSelectedSenNode,
  };

  const textF2 = ComboBoxCustomV1(argsIdField2);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: CreateSignal = {
      machineId: machineId,
      sensorNo: sensors.find((ele) => ele.sensorSerialNo === idSelected)
        .sensorNo,
      sensorSerialNo: idSelected,
      bufferId: isEmptyString(formData.bufferId)
        ? undefined
        : Number(formData.bufferId),
      sampleRateDalog: isEmptyString(formData.sampleRateDalog)
        ? undefined
        : Number(formData.sampleRateDalog),
      sampleRateInHz: isEmptyString(formData.sampleRateInHz)
        ? undefined
        : Number(formData.sampleRateInHz),
      isInternal: formData.isInternal === "True" ? true : false,
      name: formData.name,
      configName: formData.configName,
      dataType: formData.dataType,
      signalDescription: formData.signalDescription,
      signalGroup: formData.signalGroup,
      signalType: formData.signalType,
      unit: formData.unit,
      feature: formData.feature,
      condition: formData.condition,
      wirelessSensorNodeId:
        idSelectedSenNode !== "" ? idSelectedSenNode : undefined,
    };

    switch (context) {
      case "add":
        await addSignal(machineId, toSend).then((response) =>
          onSuccess("status" in response, context)
        );
        break;
      case "edit":
        await editSignal(
          machineId,
          typeof data !== "string" && "id" in data ? data.id : "",
          typeof data !== "string" && "id" in data && { id: data.id, ...toSend }
        ).then((response) => onSuccess("status" in response, context));
        break;
      case "delete":
        await deleteSignal(machineId, typeof data === "string" && data).then(
          (response) => onSuccess("status" in response, context)
        );
        break;
    }

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      {context && (
        <BaseDialog
          {...rest}
          hidden={!show}
          dialogContentProps={{
            type: DialogType.normal,
            title:
              context.charAt(0).toUpperCase() +
              context.slice(1) +
              " " +
              t("Custom Signal"),
            closeButtonAriaLabel: t("Close"),
            onDismiss: handleClose,
          }}
        >
          {["add", "edit"].includes(context) ? (
            <form onSubmit={onSubmit}>
              {context === "edit" &&
              typeof data !== "string" &&
              "id" in data ? (
                <>
                  <Stack
                    style={{
                      fontWeight: "600",
                      lineHeight: "22px",
                      marginTop: "8px",
                      paddingBottom: "20px",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      {t(`Selected signal: ({{signalName}})`, {
                        signalName:
                          data && typeof data !== "string" && data.name,
                      })}
                    </Text>
                  </Stack>
                </>
              ) : null}
              {textFComp}
              {renderFormItems(getFields(t), {
                control,
                errors: errors as { [schemaProp: string]: FieldError },
              })}
              {sensorNodes.length > 0 && textF2}
              <DialogFooter>
                <PrimaryButton
                  type="submit"
                  text={t("Save Changes")}
                  disabled={
                    isLoading ||
                    !isValid ||
                    idSelected === undefined ||
                    idSelected.trim() === ""
                  }
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                />
                <DefaultButton
                  styles={{
                    root: { border: "unset", background: "transparent" },
                  }}
                  text={t("Cancel")}
                  onClick={handleClose}
                />
              </DialogFooter>
            </form>
          ) : ["delete"].includes(context) ? (
            <>
              <div style={textStyle}>
                {t("You are about to delete selected Signal.")}
              </div>

              <DialogFooter>
                <PrimaryButton
                  text={t("Delete")}
                  disabled={isLoading}
                  //onClick={onSubmit}
                  onClick={async () => {
                    setLoading(true);
                    await deleteSignal(
                      machineId,
                      typeof data === "string" && data
                    ).then((response) =>
                      onSuccess("status" in response, context)
                    );
                    handleClose();
                  }}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                />
                <DefaultButton text={t("Close")} onClick={handleClose} />
              </DialogFooter>
            </>
          ) : null}
        </BaseDialog>
      )}
    </>
  );
};
