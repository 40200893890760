import type { IColumn } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";

import { useLocationSearch } from "../../../../../Hooks";
import type { TableProps } from "../../../../common/Table";
import Table from "../../../../common/Table";

import type { ListProps, MachineCvSensor } from "./columns";
import { getColumns } from "./columns";
import { useTranslation } from "react-i18next";

const copyAndSort = (
  items: any[],
  columnKey: string,
  isSortedDescending?: boolean
): any[] => {
  return items.slice(0).sort((a, b) => {
    const stringA = String(a[columnKey]);
    const stringB = String(b[columnKey]);

    return isSortedDescending
      ? stringB.localeCompare(stringA, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      : stringA.localeCompare(stringB, undefined, {
          numeric: true,
          sensitivity: "base",
        });
  });
};

type TableData = {
  items: MachineCvSensor[];
  columns: IColumn[];
};

const List = ({ listItems, onAddTask, onAddDocument }: ListProps) => {
  const [{ search }] = useLocationSearch();
  const { t } = useTranslation();

  const filteredItems = listItems.filter(
    ({ id }) => id.toLowerCase() !== "none"
  );

  const columns = getColumns({
    t,
    items: filteredItems,
    onAddDocument,
    onAddTask,
    search,
  });

  const [tableData, setTableData] = useState<TableData>({
    items: [],
    columns: [],
  });

  // Initialize table items and columns data
  useEffect(() => {
    setTableData({
      items: filteredItems,
      columns,
    });
  }, [listItems, t]);

  const onColumnHeaderClick = (
    ev?: React.MouseEvent<HTMLElement>,
    column?: IColumn
  ): void => {
    //Reset table items and columns to initial data
    if (column?.isSorted && !column?.isSortedDescending) {
      setTableData({
        items: filteredItems,
        columns,
      });
      return;
    }

    if (column?.key === "actions") {
      return;
    }

    const tableDataColumn: IColumn | undefined = tableData.columns.filter(
      (currCol) => column?.key === currCol.key
    )[0];

    const sortedColumns = tableData.columns.map((newCol: IColumn) => {
      if (newCol?.key === tableDataColumn?.key) {
        tableDataColumn.isSortedDescending =
          !tableDataColumn.isSortedDescending;
        tableDataColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
      }

      return newCol;
    });

    const sortedItems = copyAndSort(
      tableData.items,
      tableDataColumn?.fieldName || "",
      tableDataColumn?.isSortedDescending
    );

    setTableData({
      items: sortedItems,
      columns: sortedColumns,
    });
  };

  return (
    <Table
      persistOpts={{
        key: "table-machineCV-sensors",
        version: 2,
      }}
      hasSelection={false}
      onColumnHeaderClick={onColumnHeaderClick}
      {...tableData}
    />
  );
};

export default List;
