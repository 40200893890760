import { PrimaryButton, Stack, Text } from "@fluentui/react";
import { useMachineConditionsTable } from "../../../../Machines/useMachineConditionsTable";
import { useTranslation } from "react-i18next";

export default function Panel({
  dataKey = "",
  title = "",
  subTitle = "",
  content = null,
  hasClearSelection = false,
  style = {},
}) {
  const { t } = useTranslation();
  const { getFilteredState, clearFilteredDataByKey } =
    useMachineConditionsTable();

  return (
    <Stack className="panel-card" style={style}>
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        wrap={true}
        styles={{
          root: { paddingBottom: "16px" },
          inner: { gap: "8px" },
        }}
      >
        <div>
          <Text
            variant="mediumPlus"
            styles={{ root: { fontWeight: 700, lineHeight: "22px" } }}
            block
          >
            {title}
          </Text>
          <Text
            variant="smallPlus"
            styles={{ root: { color: "#605E5C", lineHeight: "16px" } }}
            block
          >
            {subTitle}
          </Text>
        </div>
        {hasClearSelection ? (
          <PrimaryButton
            disabled={!getFilteredState(dataKey)}
            styles={{ root: { padding: "5px 12px" } }}
            text={t("Clear selection")}
            onClick={() => clearFilteredDataByKey(dataKey)}
          />
        ) : null}
      </Stack>

      {content}
    </Stack>
  );
}
