import {
  Breadcrumb,
  Icon,
  IconButton,
  Stack,
  TooltipHost,
  Text,
  ICommandBarItemProps,
} from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Status } from "../../../schema/status";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import {
  listAsyncProjDetails,
  selectProjDetails,
  selectProjectDetailsStatus,
} from "./reducer";
import { MachineListItem, MachineToList } from "../../Machines/models";

import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../../common/CommandBar";
import { notification } from "../../common/Notification";
import Table, { Column, useTableFilters } from "../../common/Table";

import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../../schema/Constants";
import { format } from "../../../schema/Utils";
import { DeleteConfirm } from "./DeleteConfirm";
import { MachineEditDialog } from "../../Machines/MachineAddEditDialogs";
import {
  listAsyncMachines,
  selectMachinesToList,
} from "../../Machines/reducer";
import { NotFoundRoute } from "../../Generic/NotFoundRoute";
import { ProjectDetailsWithChilds } from "../models";
import { authContext } from "../../LeftMenuAlt/LeftMenuAlt";

import { useTranslation } from "react-i18next";

type GetColumnsOpts = {
  t;
  hasActions: boolean;
  onEdit: (machine: MachineListItem) => void;
  onDelete: (machine: MachineListItem) => void;
};

const getCommandBarItems = (
  project: ProjectDetailsWithChilds
): ICommandBarItemProps[] => {
  let result: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{project.name}</Text>,
    },
  ];

  return computeCommandBarItems(result);
};

const getColumns = ({
  t,
  hasActions,
  onDelete,
  onEdit,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "dalogId",
      name: "Dalog Id",
      fieldName: "dalogId",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, dalogId }: MachineListItem) => (
        <Link to={"../machines/" + id.toString()} style={linkStyle}>
          {dalogId}
        </Link>
      ),
    },
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "manufacturer",
      name: t("Manufacturer"),
      fieldName: "manufacturer",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "customerCode",
      name: t("Customer Code"),
      fieldName: "customerCode",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dataFrom",
      name: t("Data From"),
      fieldName: "dataFrom",
      minWidth: 150,
      isSortable: true,
      onRender: ({ dataFrom }: MachineListItem) =>
        dataFrom && format(new Date(dataFrom)),
    },
    {
      key: "dataUntil",
      name: t("Data Until"),
      fieldName: "dataUntil",
      minWidth: 150,
      isSortable: true,
      onRender: ({ dataUntil }: MachineListItem) =>
        dataUntil && format(new Date(dataUntil)),
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (machine: MachineListItem) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={0}
            content={t("Edit")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(machine)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={1}
            content={t("Delete")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(machine)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

const buildMachineToList = (data: MachineListItem): MachineToList | null => {
  let result: MachineToList | null = null;
  if (!data) {
    return result;
  }

  result = {
    id: data.id,
    dalogId: data.dalogId,
    projectId: data.projectId,
    name: data.name,
    corporationName: "",
    companyName: "",
    projectName: "",
    gearbox: null,
  };

  return result;
};

export const ProjectDetails: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listAsyncProjDetails(id ? id : ""));
  }, [id, dispatch]);
  const navigate = useNavigate();
  const project = useAppSelector(selectProjDetails);
  const auth = useContext(authContext);
  const status = useAppSelector(selectProjectDetailsStatus);
  const hasWritePermission = auth.metaDataContributor;
  const machines = useAppSelector(selectMachinesToList);
  const { filters, handleSearch } = useTableFilters<MachineListItem>({
    keys: [
      "name",
      "customerCode",
      "dalogId",
      "manufacturer",
      "dataFrom",
      "dataUntil",
    ],
  });
  const [selected, setSelected] = useState<
    | {
        data: MachineListItem | string | undefined;
        context: "edit" | "delete";
      }
    | undefined
  >();

  // Lists the machines.
  useEffect(() => {
    dispatch(listAsyncMachines());
  }, [dispatch]);

  // Handlers
  const goBack = () => navigate(-1);

  const onDelete = (machine: MachineListItem) =>
    setSelected({ data: machine, context: "delete" });

  const onEdit = async (machine: MachineListItem) => {
    setSelected({ data: machine, context: "edit" });
  };

  return (
    <>
      {project ? (
        <>
          <Stack horizontal verticalAlign="center">
            <IconButton iconProps={{ iconName: "Back" }} onClick={goBack} />
            <Breadcrumb
              items={[
                {
                  key: `corporation-${project.corporation?.number}`,
                  text: project.corporation.name,
                },
                {
                  key: `company-${project.company.number}`,
                  text: project.company.name,
                },
                { key: `project-${project.name}`, text: project.name },
              ]}
              styles={{
                root: { margin: 0 },
                item: { fontSize: "14px" },
                chevron: { fontSize: "10px" },
              }}
            />
          </Stack>
          <div style={pageStyle}>
            <BaseCommandBar
              items={getCommandBarItems(project)}
              onSearch={handleSearch}
              styles={commandBarStyles}
            />

            <Table
              persistOpts={{
                key: "table-projectDet",
                version: 2,
              }}
              header={{
                title: t("Machines"),
              }}
              items={project.machines}
              columns={getColumns({
                t,
                hasActions: hasWritePermission,
                onEdit,
                onDelete,
              })}
              filters={filters}
              hasSelection={false}
              isLoading={status === Status.loading}
              isError={status === Status.error}
            />

            {selected?.context === "delete" && (
              <DeleteConfirm
                data={selected?.data as MachineListItem}
                show={true}
                onSuccess={(hasError) => {
                  const aux = selected?.data as MachineListItem;
                  if (hasError) {
                    notification.error(
                      t(`Failed deleting {{dalogId}} machine`, {
                        dalogId: aux.dalogId,
                      })
                    );
                  } else {
                    dispatch(listAsyncProjDetails(id ? id : ""));
                    notification.success(
                      t(`{{dalogId}} machine deleted successfully`, {
                        dalogId: aux.dalogId,
                      })
                    );
                  }

                  setSelected(null);
                }}
                onClose={() => setSelected(null)}
              />
            )}
            {selected?.context === "edit" && (
              <MachineEditDialog
                tableItem={buildMachineToList(
                  selected?.data as MachineListItem
                )}
                dalogIds={machines.map((gate) => gate.dalogId)}
                onClose={() => setSelected(undefined)}
              />
            )}
          </div>
        </>
      ) : (
        <NotFoundRoute />
      )}
    </>
  );
};
