import type { IToggleProps } from "@fluentui/react";
import { Toggle } from "@fluentui/react";
import { Controller } from "react-hook-form";

import type { BaseFormFieldProps } from "../types";
import { FormGroup } from "./FormGroup";
import { useTranslation } from "react-i18next";

export type FormToggleProps = BaseFormFieldProps &
  IToggleProps & {
    name: string;
  };

export const FormToggle = ({
  name,
  defaultValue,
  groupProps,
  hookProps: { control },
  ...rest
}: FormToggleProps) => {
  const { t } = useTranslation();

  return (
    <FormGroup {...groupProps}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Toggle
              offText={t("Off")}
              styles={{ root: { margin: 0 } }}
              checked={value}
              onText={t("On")}
              onChange={(_, checked) => {
                onChange(checked);
              }}
              onBlur={onBlur}
              {...rest}
              label={undefined}
            />
          );
        }}
      />
    </FormGroup>
  );
};
