import { Text } from "@fluentui/react/lib/Text";
import {
  FormItemProps,
  FormItemType,
  renderFormItems,
  useZodForm,
} from "../../common/Form";
import { ResponseSimplifiedSignal } from "../../Machines/models";
import {
  D325TableItem,
  DBasicTableItem,
  D850EcoTableItem,
  D850TableItem,
} from "../tableItemModels";
import { FieldError } from "react-hook-form";
import { z } from "zod";
import {
  DialogFooter,
  IComboBoxOption,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { JSX, useMemo, useState } from "react";
import ConfigText from "./ConfigText";

import { useTranslation } from "react-i18next";

type IoTLinkFormProps = {
  tableItem: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem;
  signals: ResponseSimplifiedSignal[] | undefined;
  isLoading: boolean;
  onClose: () => void;
};

type DialogFormProps = {
  signals: ResponseSimplifiedSignal[];
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
  show: boolean;
};

type FormData = {
  baseUrl: string;
  port: string;
  rawSignals: string[];
};

type ConfigData = {
  machineId: string;
  dataloggerId: string;
  rawSignals: string[];
  baseUrl: string;
  port: string;
};

/**
 * Gets the configuration HTML element
 * @param config The configuration data
 * @returns The configuration HTML element
 */
const getConfigHtmlText = (
  signals: ResponseSimplifiedSignal[],
  config: ConfigData
): JSX.Element => {
  if (!config) {
    return <></>;
  }

  let rawSignals = config.rawSignals
    .map((id) => {
      return signals.find((s) => s.id === id)?.name || "";
    })
    .filter((id) => id !== "");

  return (
    <Stack tokens={{ padding: 10 }}>
      <Text variant="smallPlus">
        {"[MODBUS]"}
        <br />
        {"logEnabled=F              ;generate no output"}
        <br />
        {";logEnabled=2             ;generate console output"}
        <br />
        {";logEnabled=T             ;generate output in modbus.log"}
        <br />
        <br />
        {"[DLW Client]"}
        <br />
        {"server=127.0.0.1:5100"}
        <br />
        {"mem=1000,200             ;von DALOG"}
        <br />
        <br />
        {";[MODBUS Client]"}
        <br />
        {";rate=1000"}
        <br />
        {";server=127.0.0.1:502"}
        <br />
        {";regs=5000,20,0           ;Einlesen vom fernen MODBUS-Server"}
        <br />
        <br />
        {"[MODBUS Server]"}
        <br />
        {"port=502"}
        <br />
        {"regsIn=1000,200           ;Schreiben an fernen MODBUS-Client"}
        <br />
        <br />
        {";[DLW Server]"}
        <br />
        {";port=5100"}
        <br />
        {";mem=1000,200             ;zu DALOG"}
        <br />
        <br />
        {"[DDP]"}
        <br />
        {"clean=FALSE"}
        <br />
        {"token0=--insecure"}
        <br />
        {`token1=-F "machineId=${config.machineId}"`}
        <br />
        {`token2=-F "boxId:${config.dataloggerId}"`}
        <br />
        {`token3=-F "rawToProcess=${rawSignals}"`}
        <br />
        {`url=${config.baseUrl}:${config.port}/files/v1/images/gzip`}
        <br />
        {"lastSave=0"}
        <br />
        {"cleanBefore=0"}
        <br />
        {"curlStart=0"}
        <br />
        {"delGzAfterSend=2"}
        <br />
        {"delSavAfterSend=FALSE"}
        <br />
      </Text>
    </Stack>
  );
};

/**
 * Gets the form item props
 * @param signals The signals list.
 * @returns The form item props list.
 */
const getFormItemProps = (signals: IComboBoxOption[], t): FormItemProps[] => {
  return [
    {
      name: "baseUrl",
      type: FormItemType.TextField,
      groupProps: { label: t("Base URL *") },
      placeholder: "http://...",
    },
    {
      name: "port",
      type: FormItemType.TextField,
      groupProps: { label: t("Port *") },
    },
    {
      name: "rawSignals",
      type: FormItemType.Dropdown,
      options: signals,
      groupProps: { label: t("Raw signals *") },
      multiSelect: true,
    },
  ];
};

/**
 * The form schema
 */

/**
 * Gets the Dialog form component
 * @param signals The signals
 * @param isLoading value indicating whether the form is in loading state
 * @param onSubmit Method called when the submit button is clicked.
 * @param show Value indicating whether to show this form.
 * @returns The Dialog Form component.
 */
const DialogForm = ({
  signals,
  isLoading,
  onSubmit,
  show,
}: DialogFormProps) => {
  const { t } = useTranslation();

  const formSchema = useMemo(
    () =>
      z
        .object({
          baseUrl: z.string().url(),
          port: z.string(),
          rawSignals: z
            .string()
            .array()
            .min(1, t("Select at least one signal")),
        })
        .refine(
          (input: FormData) => {
            if (!input.port) {
              return true;
            }

            var regExpression = /^\d*\.?\d*$/;
            const valid = new RegExp(regExpression);
            return valid.test(input.port);
          },
          {
            message: t("This value must be a number"),
            path: ["port"],
          }
        ),
    [t]
  );

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useZodForm({
    mode: "onChange",
    schema: formSchema,
    defaultValues: {
      baseUrl: "",
      port: "3021",
      rawSignals: [],
    },
  });

  return (
    <div hidden={!show}>
      {renderFormItems(
        getFormItemProps(
          signals?.map((s) => {
            return { key: s.id, text: s.name };
          }),
          t
        ),
        {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        }
      )}
      <DialogFooter>
        <PrimaryButton
          text={t("Generate")}
          disabled={isLoading || !isValid}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
          onClick={handleSubmit(onSubmit)}
        />
      </DialogFooter>
    </div>
  );
};

/**
 * Gets the IoT link form component.
 * @param tableItem the table item
 * @param signals the signals list.
 * @param isLoading value determining whether the form is on loading state.
 * @param onClose Method called when this dialog needs to be closed.
 * @returns the IoT Link form component.
 */
const IoTLinkConfigForm = ({
  tableItem,
  signals,
  isLoading,
  onClose,
}: IoTLinkFormProps) => {
  const [configData, setConfigData] = useState<ConfigData>(undefined);

  // Function called when the submit button is clicked.
  const onSubmit = (formData: FormData) => {
    let configData: ConfigData = {
      machineId: tableItem.machineIds?.[0],
      dataloggerId: tableItem.id,
      rawSignals: formData.rawSignals.map((signal) => signal.toString()),
      baseUrl: formData.baseUrl.trim(),
      port: formData.port.trim(),
    };

    setConfigData(configData);
  };

  return (
    <section>
      <DialogForm
        show={!configData}
        signals={signals}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
      <ConfigText
        show={configData !== undefined}
        htmlText={getConfigHtmlText(signals, configData)}
        onClose={onClose}
      />
    </section>
  );
};

export default IoTLinkConfigForm;
