import {
  ILabelStyles,
  IStackProps,
  IStackStyles,
  Label,
  mergeStyleSets,
  Stack,
} from "@fluentui/react";
import { PropsWithChildren } from "react";

type FormItemRowProps = Omit<
  IStackProps,
  "horizontal" | "horizontalAlign" | "verticalAlign"
> & {
  label: string;
  htmlFor?: string;
};

const containerStyles: IStackStyles = {
  root: {
    marginTop: "8px",
    marginBottom: "8px",
  },
};

const labelContainerStyles: IStackStyles = {
  root: {
    minWidth: "160px",
    marginRight: "8px",
  },
};

const labelStyles: ILabelStyles = {
  root: {
    fontSize: "14px",
    fontWeight: 400,
  },
};

const FormItemRow = ({
  label,
  htmlFor,
  children,
  styles,
  ...rest
}: PropsWithChildren<FormItemRowProps>) => {
  return (
    <Stack
      {...rest}
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      styles={mergeStyleSets(containerStyles, styles)}
    >
      <Stack.Item styles={labelContainerStyles}>
        <Label styles={labelStyles} htmlFor={htmlFor}>
          {label}
        </Label>
      </Stack.Item>
      <Stack.Item styles={{ root: { width: "100%" } }}>{children}</Stack.Item>
    </Stack>
  );
};

export default FormItemRow;
