import { useEffect, useState } from "react";
import { notification } from "../../common/Notification";
import { GenerateConfigsAPI } from "./api";
import { ResponseSimplifiedSignal } from "../../Machines/models";

import { useTranslation } from "react-i18next";

/**
 * Gets the useRawSignals hook
 * @param machineId the machine Id
 * @returns The signals list, if fetched successfully.
 */
export const useRawSignals = (machineId: string) => {
  const { t } = useTranslation();
  const [signalsAreLoading, setSignalsAreLoading] = useState<boolean>(false);
  const [signals, setSignals] = useState<
    ResponseSimplifiedSignal[] | undefined
  >([]);

  // Fetches the machine signals.
  useEffect(() => {
    if (!machineId) {
      notification.error(
        t("There are no machines assigned to this datalogger.")
      );
      setSignals(undefined);
      return;
    }

    setSignalsAreLoading(true);
    GenerateConfigsAPI.getRawSignals(machineId).then((response) => {
      setSignalsAreLoading(false);
      if (response.status !== 200) {
        notification.error(
          t(
            `Failure getting raw signals for this datalogger: {{dataloggerName}}.`,
            { dataloggerName: response.statusText }
          )
        );
      }

      setSignals(response.data);
    });
  }, [machineId, t]);

  return { signals, signalsAreLoading };
};
