/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import Table, { useTableFilters, Column } from "../common/Table";
import BaseDialog, { DialogSize } from "../common/Dialog";

import { DialogType, IDialogProps } from "@fluentui/react";
import { ListLogWithSubType } from "./models";
import { linkStyle } from "../../schema/Constants";
import { Link as LinkA } from "react-router-dom";
import { format } from "../../schema/Utils";
import { submissionsListLog } from "./api";
import { useTranslation } from "react-i18next";

const getColumns = (t): Column[] => {
  const columns: Column[] = [
    {
      key: "sID",
      name: t("SID"),
      fieldName: "sID",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, sID }: ListLogWithSubType) => (
        <LinkA to={id + "/log"} style={linkStyle}>
          {sID}
        </LinkA>
      ),
    },
    {
      key: "approvedAt",
      name: t("Approved At"),
      fieldName: "approvedAt",
      minWidth: 200,
      isSortable: true,
      onRender: (item: ListLogWithSubType) => format(new Date(item.approvedAt)),
    },
    {
      key: "approvedBy",
      name: t("Approved By"),
      fieldName: "approvedBy",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "status",
      name: t("Status"),
      fieldName: "status",
      minWidth: 200,
      isSortable: true,
    },
  ];

  return columns;
};

type LogSubmissionsListProps = IDialogProps & {
  show: boolean;
  onClose: () => void;
};

export const LogSubmissionsList = ({
  show,
  onClose,
  ...rest
}: LogSubmissionsListProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<ListLogWithSubType[]>();
  const { filters, handleSearch } = useTableFilters<ListLogWithSubType>({
    keys: ["sID", "submissionType", "approvedBy", "status", "submissionType"],
  });

  useEffect(() => {
    setLoading(true);
    submissionsListLog()
      .then((list) => {
        setItems(list);
        setLoading(false);
      })
      .catch(() => setItems([]));

    return;
  }, []);

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.close,
        title: t("Submission Logs"),
        closeButtonAriaLabel: t("Close"),
        onDismiss: onClose,
      }}
      styles={{
        main: {
          "@media (min-width: 480px)": {
            height: "78vh",
          },
        },
      }}
      size={DialogSize.L}
    >
      <Table
        persistOpts={{
          key: "table-submissions-logs",
          version: 2,
        }}
        header={{
          title: t("Logs"),
        }}
        items={items ? items : []}
        columns={getColumns(t)}
        filters={filters}
        hasSelection={false}
        isLoading={loading}
        isError={false}
      />
    </BaseDialog>
  );
};
