import "../../styles.scss";

import classNames from "classnames";
import { useMemo, useReducer, useState } from "react";

import {
  useWebAnalysisPermissions,
  useCurrentUser,
} from "../../../../../Hooks";
import DeleteDialog from "../../../../../modules/common/components/Dialogs/DeleteDialog";
import type { TaskOverview } from "../../../../../types";
import type { TableProps } from "../../../../common/Table";
import Table from "../../../../common/Table";

import type { Edit } from "../../hooks/useTasksStore";
import useTasksStore from "../../hooks/useTasksStore";
import { pageInitialState, pageReducer } from "../../pageReducer";
import { getColumns } from "./columns";
import { CommandBar } from "./CommandBar";
import { useTranslation } from "react-i18next";

export const filterFields: (keyof TaskOverview)[] = [
  "subject",
  "description",
  "fileCount",
  "createdAt",
  "dueDate",
];

type TasksTableProps = {
  items: any[];
  isError: boolean;
  isLoading: boolean;
  itemsKey: "others" | "done";
  tableTitle: string;
};

export default function TasksTable({
  items,
  isError,
  isLoading,
  itemsKey,
  tableTitle,
}: TasksTableProps) {
  const { t } = useTranslation();
  const [{ search, status, filter }, pageDispatch] = useReducer(
    pageReducer,
    pageInitialState
  );
  const [showDelete, setShowDelete] = useState(false);

  const userId = useCurrentUser().memberId;

  const { selectedItems, reload, dispatch } = useTasksStore((state) => ({
    selectedItems: state.selectedItems[itemsKey] as TaskOverview[],
    reload: state.reload,
    showDelete: state.showDelete,
    dispatch: state.dispatch,
  }));

  const { useReadyPermission, checkCreatorUserIDMatch } =
    useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });

  const onRenderRow = (props: any, defaultRender: any) => {
    const showCheckMark =
      hasMachineCvAdministratorPermission ||
      checkCreatorUserIDMatch(props?.item?.createdById);

    const customProps = {
      ...props,
      className: showCheckMark ? "" : "tasks-table-row",
    };

    return defaultRender(customProps);
  };

  const onSelectionChanged = (items: any) => {
    const filteredItems = items.filter(
      (item: any) =>
        hasMachineCvAdministratorPermission ||
        checkCreatorUserIDMatch(item?.createdById)
    );

    return dispatch({
      type: "SET_SELECTION",
      payload: {
        itemsKey,
        items: filteredItems,
      },
    });
  };

  const filters = useMemo<TableProps["filters"]>(() => {
    const filters: TableProps["filters"] = [];

    if (search.length > 0) {
      const newFilters = filterFields
        .filter((f) => f !== undefined)
        .map((field: string) => {
          return {
            key: field,
            search: search,
            operator: "or",
          } as NonNullable<TableProps["filters"]>[0];
        });

      filters.push(...newFilters);
    }

    if (status !== "All") {
      filters.push({
        key: "status",
        search: status,
        operator: "and",
      });
    }

    if (filter !== "all") {
      filters.push({
        key: filter === "createdbyme" ? "createdById" : "assignedToId",
        search: userId,
        operator: "and",
      });
    }

    return filters;
  }, [search, status, filter]);

  const tableProps: TableProps = useMemo(
    () => ({
      persistOpts: {
        key: itemsKey,
        version: 1,
      },
      header: {
        title: tableTitle,
      },
      items,
      isLoading,
      isError,
      filters,
      resetPagination: reload,
      columns: getColumns({
        t,
        showEdit: (payload: Edit) => dispatch({ type: "SET_EDIT", payload }),
      }),
      onRenderRow,
      onSelectionChanged,
    }),
    [items, isLoading, isError, filters, reload, dispatch, t]
  );

  const showSelectAllCheckmark = useMemo(
    () => items.some(({ createdById }) => checkCreatorUserIDMatch(createdById)),
    [items]
  );

  return (
    <div
      className={classNames("machine-cv-tasks-table", {
        "tasks-table__hide-select-all-checkmark": !showSelectAllCheckmark,
      })}
    >
      <CommandBar
        itemsKey={itemsKey}
        filter={filter}
        status={status}
        pageDispatch={pageDispatch}
        setShowDelete={setShowDelete}
      />

      <Table {...tableProps} />

      <DeleteDialog
        dialogContentProps={{
          onDismiss: () => setShowDelete(false),
        }}
        data={{
          ids: selectedItems.map(({ id }) => id),
          name: selectedItems.length === 1 ? selectedItems[0].subject : "items",
        }}
        hidden={!showDelete}
        route={"/meta/machinecv/v1/tasks"}
        onSuccess={() => dispatch({ type: "SET_RELOAD" })}
      />
    </div>
  );
}
