import { zodResolver } from "@hookform/resolvers/zod";
import type { UseFormProps, UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import type { ZodType } from "zod";
import { z } from "zod";

import { FormErrorMessages } from "./types";
import { t } from "i18next";

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    return { message: t(FormErrorMessages.Required) };
  }

  return { message: ctx.defaultError };
};

export const useZodForm = <Schema extends ZodType>(
  props: Omit<UseFormProps<Schema["_input"]>, "resolver"> & { schema: Schema }
): UseFormReturn<Schema["_input"]> => {
  const { schema, ...rest } = props;

  return useForm<Schema["_input"]>({
    ...rest,
    resolver: zodResolver(schema, { errorMap: customErrorMap }),
  });
};
