import {
  Text,
  TooltipHost,
  Icon,
  DialogType,
  DialogFooter,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  DefaultButton,
  Link,
} from "@fluentui/react";

import Table, { Column, useTableFilters, TableProps } from "../common/Table";
import BaseDialog from "../common/Dialog";
import { notification } from "../common/Notification";
import BaseCommandBar, {
  computeCommandBarItems,
  CommandBarItemType,
  CommandBarItemProps,
} from "../common/CommandBar";

import { useContext, useEffect, useMemo, useState } from "react";
import { Link as LinkA } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import { Corporation } from "./models";
import {
  listAsyncCorpo,
  selectCorporations,
  selectCorporationsError,
  selectCorporationsStatus,
} from "./reducer";
import { DeleteConfirm } from "./DeleteConfirm";
import { AddOrEditDialog } from "./AddOrEditDialog";
import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import { AcceptedFile } from "../common/Uploader/Uploader";
import { formatFileSize } from "../../schema/Utils";
import { metaDataImport } from "../MetaData/api";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";

import { useTranslation } from "react-i18next";

// --- Columns ---

type GetColumnsOpts = {
  t: any;
  hasActions: boolean;
  onEdit: (corporation: Corporation) => void;
  onDelete: (corporation: Corporation) => void;
};

const getColumns = ({
  t,
  hasActions,
  onEdit,
  onDelete,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, name }: Corporation) => (
        <LinkA to={id} style={linkStyle}>
          {name}
        </LinkA>
      ),
    },
    {
      key: "number",
      name: t("Number"),
      fieldName: "number",
      minWidth: 200,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (corporation: Corporation) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={0}
            content={t("Edit")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(corporation)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={1}
            content={t("Delete")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(corporation)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

type GetColumnsFileOpts = {
  t: any;
  onRemove: (id: string) => void;
};

export const getColumnsFile = ({
  t,
  onRemove,
}: GetColumnsFileOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: ({ name }) => {
      // TODO: should we use Link from react-router-dom?
      return (
        <Link underline href="#" style={{ fontSize: 13 }}>
          {name}
        </Link>
      );
    },
  },
  {
    key: "size",
    fieldName: "size",
    name: t("Size"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ size, isValid }) => {
      return (
        <span
          style={{ fontSize: 13, color: isValid ? "currentColor" : "#ff6a66" }}
        >
          {isValid ? formatFileSize(size) : t("Too large")}
        </span>
      );
    },
  },
];
export const CorporationsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const auth = useContext(authContext);
  const items = useAppSelector(selectCorporations);
  const status = useAppSelector(selectCorporationsStatus);
  const error = useAppSelector(selectCorporationsError);
  const hasWritePermission = auth.metaDataContributor;
  const [file, setFile] = useState<AcceptedFile>();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (status === Status.error && items.length < 1) notification.error(error);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, items.length]);

  const { filters, handleSearch } = useTableFilters<Corporation>({
    keys: ["name", "number"],
  });

  const [selected, setSelected] = useState<{
    data: Corporation | null;
    context: "add" | "edit" | "delete";
  } | null>(null);

  useEffect(() => {
    dispatch(listAsyncCorpo());
  }, [dispatch]);

  const onAdd = () => setSelected({ data: null, context: "add" });

  const onEdit = (corporation: Corporation) =>
    setSelected({ data: corporation, context: "edit" });

  const onDelete = (corporation: Corporation) =>
    setSelected({ data: corporation, context: "delete" });

  const onImport = (files: AcceptedFile[]) => {
    setFile(files.at(0));
  };

  const getCommandBarItems = (
    title: string,
    onChange?: (files: AcceptedFile[]) => void
  ): CommandBarItemProps[] => {
    const commandBarItems: CommandBarItemProps[] = [
      {
        key: "title",
        type: CommandBarItemType.Custom,
        onRender: () => <Text style={titleStyle}>{title}</Text>,
      },
      ...(hasWritePermission
        ? [
            {
              key: "add",
              text: t("Add"),
              type: CommandBarItemType.Button,
              iconProps: { iconName: "Add" },
              onClick: onAdd,
            },
            {
              key: "import",
              type: CommandBarItemType.Upload,
              onRenderProps: {
                accept: ".csv",
                multiple: false,
                onChange,
              },
            },
          ]
        : []),
    ];

    return commandBarItems;
  };

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog",
        version: 1,
      },
      items: [file],
      perPage: 5,
      hasSelection: false,
      columns: getColumnsFile({
        t,
        onRemove: (fileId) => {
          setFile(undefined);
        },
      }),
    }),
    [file]
  );

  return (
    <div style={pageStyle}>
      <BaseCommandBar
        items={computeCommandBarItems(
          getCommandBarItems(t("Corporation"), onImport)
        )}
        onSearch={handleSearch}
        styles={commandBarStyles}
      />

      <Table
        persistOpts={{
          key: "table-corporations",
          version: 2,
        }}
        header={{
          title: t("Corporation"),
        }}
        items={items}
        columns={getColumns({
          t,
          hasActions: hasWritePermission,
          onEdit,
          onDelete,
        })}
        filters={filters}
        hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
      />

      <DeleteConfirm
        data={selected?.data}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(
              t(`Failed deleting {{corporationName}} corporation`, {
                corporationName: selected?.data?.name,
              })
            );
          } else {
            dispatch(listAsyncCorpo());
            notification.success(
              t(`{{corpoartionName}} corporation deleted successfully`, {
                corpoartionName: selected?.data?.name,
              })
            );
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />
      <AddOrEditDialog
        data={selected?.data}
        items={items}
        show={["add", "edit"].includes(selected?.context)}
        onSuccess={(hasError, data, context) => {
          if (hasError) {
            const message =
              context === "add"
                ? t(`Failed creating {{entity}}`, { entity: data.name })
                : t(`Failed updating {{entity}}`, { entity: data.name });

            notification.error(message);
          } else {
            dispatch(listAsyncCorpo());

            const message =
              context === "add"
                ? t(`{{entity}} created successfully`, { entity: data.name })
                : t(`{{entity}} updated successfully`, { entity: data.name });

            notification.success(message);
          }
        }}
        onClose={() => {
          setSelected(null);
        }}
      />
      <BaseDialog
        hidden={!file}
        dialogContentProps={{
          type: DialogType.normal,
          title: t("Import all Metadata"),
          closeButtonAriaLabel: t("Close"),
          onDismiss: () => setSelected(undefined),
        }}
      >
        <Table {...tableProps}></Table>
        <DialogFooter>
          <PrimaryButton
            text={t("Save Changes")}
            disabled={isLoading}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
            onClick={() => {
              setLoading(true);
              metaDataImport(file.file).then((res) => {
                dispatch(listAsyncCorpo);

                setSelected(undefined);
                res.status === 200
                  ? notification.success(t("Added data successfully."))
                  : notification.error(`${t("Something went wrong")}.`);
              });
              setLoading(false);
            }}
          />
          <DefaultButton
            styles={{
              root: { border: "unset", background: "transparent" },
            }}
            text={t("Cancel")}
            onClick={() => setFile(undefined)}
          />
        </DialogFooter>
      </BaseDialog>
    </div>
  );
};
