import classnames from "classnames";

import { useMaximize, useUniqueId } from "../../../../Hooks";
import useSingleTrendViewMeasuredData from "../../../analysis-raw-data/hooks/useSingleTrendViewMeasuredData";

import useChartTimestampStore from "../../hooks/useChartTimestampStore";
import type { TrendChartSectionProps } from "../../types";
import ChartSectionHeader from "../ChartSectionHeader";
import { PlotSpinner, RawDataPlotNoData } from "../PlotPlaceholders";
import TrendChart from "../TrendChart";
import { useTranslation } from "react-i18next";

const TrendChartSection = ({ signal }: TrendChartSectionProps) => {
  const { data: chartDataPoints, isLoading } = useSingleTrendViewMeasuredData({
    signal,
    queryEnabled: !!signal.id,
  });

  const uniqueId = useUniqueId();
  const { t } = useTranslation();

  const {
    isChartMaximized,
    isChartHidden,
    maximizeAction,
    maximizeIcon,
    maximizeLabel,
  } = useMaximize({ id: uniqueId, page: "raw-data" });

  const { findTimestamp } = useChartTimestampStore((store) => ({
    timestamps: store.timestamps,
    findTimestamp: store.findTimestamp,
  }));

  const timestamp = findTimestamp(signal.id);

  return (
    <div
      className={classnames("trend-chart-section", {
        "raw-data-chart-section--hidden": isChartHidden,
        "raw-data-chart-section--maximized": isChartMaximized,
      })}
    >
      <ChartSectionHeader
        id={uniqueId}
        title={t("Trend")}
        {...{
          maximizeAction,
          maximizeIcon,
          maximizeLabel,
          timestamp: timestamp?.timestamp,
        }}
      />

      {isLoading && <PlotSpinner />}
      {!isLoading && chartDataPoints?.length === 0 && (
        <RawDataPlotNoData signal={signal} />
      )}
      {!isLoading && chartDataPoints?.length > 0 && (
        <TrendChart
          data={chartDataPoints}
          exportId={uniqueId}
          signal={signal}
        />
      )}
    </div>
  );
};

export default TrendChartSection;
