import { FontIcon, mergeStyles } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";

import useHarmonicsStore from "./useHarmonicsStore";
import type { Actions, HarmonicsState } from "./waterfallHarmonicsReducer";

import { useTranslation } from "react-i18next";

const iconClass = mergeStyles({
  lineHeight: 1,
  fontSize: 17,
  marginRight: 10,
});

const WaterfallContextMenu = ({
  root,
  harmonicsOne,
  harmonicsTwo,
  dispatchHarmonicsOne,
  dispatchHarmonicsTwo,
  clearHarmonics,
  setChartHoverParams,
}: {
  root: { el: HTMLDivElement } | null;
  harmonicsOne: HarmonicsState;
  harmonicsTwo: HarmonicsState;
  dispatchHarmonicsOne: React.Dispatch<Actions>;
  dispatchHarmonicsTwo: React.Dispatch<Actions>;
  clearHarmonics: () => void;
  setChartHoverParams: (value: undefined) => void;
}) => {
  const [visible, setVisible] = useState(false);
  const [currentValue, setCurrentValue] = useState(0);
  const contextRef = useRef<HTMLDivElement>(null);
  const mountedRef = useRef(true);
  const { t } = useTranslation();

  const { harmonicsOneList, harmonicsTwoList } = useHarmonicsStore((state) => ({
    harmonicsOneList: state.harmonicsOneList,
    harmonicsTwoList: state.harmonicsTwoList,
  }));

  useEffect(() => {
    const handleClick = () => {
      setVisible(false);
    };

    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
      setChartHoverParams(undefined);

      if (root) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const pointData = root?.el._hoverdata[0];
        if (pointData) {
          const xValue = pointData.x;
          setCurrentValue(xValue);
        }
      }

      setVisible(true);

      if (contextRef.current) {
        const { clientWidth, clientHeight } = contextRef.current;
        const { innerWidth: screenW, innerHeight: screenH } = window;
        const { clientX: clickX, clientY: clickY } = e;

        const right = screenW - clickX > clientWidth;
        const top = screenH - clickY > clientHeight;

        if (contextRef.current.style) {
          contextRef.current.style.left = right
            ? `${clickX + 5}px`
            : `${clickX - clientWidth - 5}px`;
          contextRef.current.style.top = top
            ? `${clickY + 5}px`
            : `${clickY - clientHeight - 5}px`;
        }
      }
    };

    document.addEventListener("click", handleClick);
    root?.el?.addEventListener("contextmenu", handleContextMenu);

    return () => {
      mountedRef.current = false;
      document.removeEventListener("click", handleClick);

      if (root && root.el) {
        root.el.removeEventListener("contextmenu", handleContextMenu);
      }
    };
  }, [root]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <div
      ref={contextRef}
      className="contextMenu"
      style={{ display: visible ? "block" : "none" }}
    >
      <button
        className="contextMenu--option"
        onClick={() => {
          dispatchHarmonicsOne({ type: "SET_VISIBLE", payload: true });
          dispatchHarmonicsOne({
            type: "SET_LINES_FREQ",
            payload: currentValue,
          });
        }}
      >
        <FontIcon iconName="Separator" className={iconClass} />
        {t("Set as frequency 1")}
      </button>

      <button
        disabled={!harmonicsOne.visible}
        style={{ opacity: !harmonicsOne.visible ? 0.2 : 1 }}
        className="contextMenu--option"
        onClick={() => {
          dispatchHarmonicsOne({
            type: "SET_SIDEBANDS_FREQ",
            payload: harmonicsOne.linesFreq - currentValue,
          });
        }}
      >
        <FontIcon iconName="GripperBarVertical" className={iconClass} />
        {t("Set as sideband 1")}
      </button>

      <button
        className="contextMenu--option"
        onClick={() => {
          dispatchHarmonicsTwo({ type: "SET_VISIBLE", payload: true });
          dispatchHarmonicsTwo({
            type: "SET_LINES_FREQ",
            payload: currentValue,
          });
        }}
      >
        <FontIcon iconName="Separator" className={iconClass} />
        {t("Set as frequency 2")}
      </button>

      <button
        disabled={!harmonicsTwo.visible}
        style={{ opacity: !harmonicsTwo.visible ? 0.2 : 1 }}
        className="contextMenu--option"
        onClick={() => {
          dispatchHarmonicsTwo({
            type: "SET_SIDEBANDS_FREQ",
            payload: harmonicsTwo.linesFreq - currentValue,
          });
        }}
      >
        <FontIcon iconName="GripperBarVertical" className={iconClass} />
        {t("Set as sideband 2")}
      </button>

      <button
        disabled={
          harmonicsOneList.length === 0 && harmonicsTwoList.length === 0
        }
        style={{
          opacity:
            harmonicsOneList.length === 0 && harmonicsTwoList.length === 0
              ? 0.2
              : 1,
        }}
        className="contextMenu--option"
        onClick={clearHarmonics}
      >
        <FontIcon iconName="Clear" className={iconClass} />
        {t("Clear Harmonics")}
      </button>
    </div>
  );
};

export default WaterfallContextMenu;
