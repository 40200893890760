import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import MaximizeIcon from "../../../../../../assets/svg/MaximizeIcon";
import MinimizeIcon from "../../../../../../assets/svg/MinimizeIcon";
import { maximizeButtonIcon } from "../../../../../../modules/analysis-raw-data/styles/ChartSection.styles";
import { t } from "i18next";

interface MopMaximizeStore {
  isMaximized: boolean;
  maximizeAction: () => void;
  maximizeIcon: JSX.Element;
  maximizeLabel: string;
}

const useMopMaximizeStore = createWithEqualityFn<MopMaximizeStore>(
  (set) => ({
    isMaximized: false,
    maximizeAction: () =>
      set((state) => {
        const newIsMaximized = !state.isMaximized;
        return {
          isMaximized: newIsMaximized,
          maximizeIcon: newIsMaximized ? (
            <MinimizeIcon style={maximizeButtonIcon} />
          ) : (
            <MaximizeIcon style={maximizeButtonIcon} />
          ),
          maximizeLabel: newIsMaximized ? t("Minimize") : t("Maximize"),
        };
      }),
    maximizeIcon: <MaximizeIcon style={maximizeButtonIcon} />,
    maximizeLabel: t("Maximize"),
  }),
  shallow
);

export default useMopMaximizeStore;
