import { IconButton } from "@fluentui/react";

import { File } from "../../../../common/Preview/File";
import type { Column } from "../../../../common/Table/Table";

type GetColumnsOpts = {
  t: any;
  onRemove: (id: string) => void;
};

export const getColumns = ({ t, onRemove }: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: (item) => {
      // TODO: should we use Link from react-router-dom?
      return <File file={item} />;
    },
  },
  {
    key: "description",
    fieldName: "description",
    name: t("Description"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ description }) => {
      return <span style={{ fontSize: 13 }}>{description}</span>;
    },
  },
  {
    key: "dateAdded",
    fieldName: "dateAdded",
    name: t("Date Added"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ dateAdded }) => {
      return <span style={{ fontSize: 13 }}>{dateAdded}</span>;
    },
  },
  {
    fieldName: "actions",
    key: "actions",
    name: t("Actions"),
    minWidth: 68,
    maxWidth: 68,
    onRender: ({ id }) => (
      <IconButton
        iconProps={{ iconName: "Delete" }}
        title={t("Delete")}
        ariaLabel={t("Delete")}
        styles={{
          root: {
            verticalAlign: "middle",
            color: "#0078d4",
            width: "100%",
            height: 16,
            padding: 0,
            margin: "0 auto",
          },
          rootHovered: {
            background: "transparent",
          },
          rootDisabled: {
            background: "transparent",
          },
        }}
        onClick={() => onRemove(id)}
      />
    ),
  },
];
