import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";

import { ProductContractExpanded } from "./models";
import { listServiceAccountsProducts } from "./api";
import { t } from "i18next";

export const listAsyncServiceAccountProducts = createAsyncThunk(
  "serviceAccountsProductsProducts/list",
  async () => {
    const response = await listServiceAccountsProducts();
    return response;
  }
);

interface toSortbyKey {
  sort: boolean;
  key: string;
}

interface toFindbyKey {
  input: string;
  key: string;
}

export interface ServiceAccountsProductsState {
  serviceAccountsProducts: ProductContractExpanded[];
  serviceAccountsProductsIni: ProductContractExpanded[];
  serviceAccountsProductsSort: ProductContractExpanded[];
  status: Status;
  error: string;
}

const initialState: ServiceAccountsProductsState = {
  serviceAccountsProducts: [],
  serviceAccountsProductsIni: [],
  serviceAccountsProductsSort: [],
  status: Status.void,
  error: "",
};

export const serviceAccountsProductsSlice = createSlice({
  name: "serviceAccountsProducts",
  initialState,
  reducers: {
    sortValues: (
      state: ServiceAccountsProductsState,
      action: PayloadAction<toSortbyKey>
    ) => {
      const key = action.payload.key as keyof ProductContractExpanded;
      state.serviceAccountsProducts !== undefined
        ? (state.serviceAccountsProducts = state.serviceAccountsProducts
            .slice(0)
            .sort((a: ProductContractExpanded, b: ProductContractExpanded) =>
              (
                action.payload.sort
                  ? a[key].toString().toLowerCase() <
                    b[key].toString().toLowerCase()
                  : a[key].toString().toLowerCase() >
                    b[key].toString().toLowerCase()
              )
                ? 1
                : -1
            ))
        : (state.serviceAccountsProducts = []);
    },
    findValues: (
      state: ServiceAccountsProductsState,
      action: PayloadAction<toFindbyKey>
    ) => {
      const key = action.payload.key as keyof ProductContractExpanded;
      if (state.serviceAccountsProductsIni.length > 0) {
        state.serviceAccountsProducts = state.serviceAccountsProductsIni.filter(
          (i) =>
            i[key]
              .toString()
              .toLowerCase()
              .indexOf(action.payload.input.toLowerCase()) > -1
        );
        state.serviceAccountsProductsSort = [...state.serviceAccountsProducts];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncServiceAccountProducts.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncServiceAccountProducts.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.serviceAccountsProducts = action.payload;
        state.serviceAccountsProductsIni = action.payload;
        state.serviceAccountsProductsSort = action.payload;
      })
      .addCase(listAsyncServiceAccountProducts.rejected, (state) => {
        state.status = Status.error;
        state.error = t(
          "Error loading Service Accounts. Please try again later."
        );
      });
  },
});

export const selectServiceAccountsProducts = (state: RootState) =>
  state.serviceAccountsProducts.serviceAccountsProducts;

export const selectServiceAccountsProductsStatus = (state: RootState) =>
  state.serviceAccountsProducts.status;

export const selectServiceAccountByMemberId =
  (id: string) => (state: RootState) => {
    if (
      state.serviceAccountsProducts &&
      state.serviceAccountsProducts.serviceAccountsProducts &&
      state.serviceAccountsProducts.serviceAccountsProducts.length > 0
    ) {
      return state.serviceAccountsProducts.serviceAccountsProducts.find(
        (c: ProductContractExpanded) => c.id === id
      );
    }

    return undefined;
  };

export const { sortValues, findValues } = serviceAccountsProductsSlice.actions;
export default serviceAccountsProductsSlice.reducer;
