import { Stack } from "@fluentui/react";
import Legend from "./components/Legend";
import Panel from "./components/Panel";
import PieChart from "./components/PieChart";

import { useMachineConditionsTable } from "../../../Machines/useMachineConditionsTable";
import { useTranslation } from "react-i18next";
import { getSlicesDataByKey } from "./components/PieChart/utils";

const DATA_KEY = "condition";

function ConditionsPanel() {
  const { machines, filterMachinesByKeyAndValue } = useMachineConditionsTable();

  const slices = getSlicesDataByKey(machines, DATA_KEY);

  const actionableSlices = slices.map((slice) => ({
    ...slice,
    onClick: () => filterMachinesByKeyAndValue(DATA_KEY, slice[DATA_KEY]),
  }));

  return (
    <Stack
      horizontal
      verticalAlign="center"
      horizontalAlign="space-between"
      style={{
        gap: 16,
        flexGrow: 1,
      }}
    >
      <PieChart slices={actionableSlices} />
      <Legend slices={actionableSlices} />
    </Stack>
  );
}

export default function Conditions() {
  const { t } = useTranslation();

  return (
    <Panel
      dataKey={DATA_KEY}
      title={t("Conditions")}
      subTitle={t("Overview of machines conditions")}
      hasClearSelection={true}
      content={<ConditionsPanel />}
      style={{ width: "100%", maxWidth: "520px" }}
    />
  );
}
