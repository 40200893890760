import { SearchBox, Stack } from "@fluentui/react";

import { useMemo } from "react";
import { groupBy } from "lodash-es";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./ClusterList.scss";
import { useSearch } from "../../../../../../../../Hooks";
import {
  conditionColor,
  getConditionFormat,
} from "../../../../../../../../modules/machine-cv/constants";
import { NoData } from "../../../../../../../common/NoData";

function renderMachine({ name, machineId, color }: any) {
  return (
    <Link
      key={machineId}
      to={`/machine-cv/summary?id=${machineId}`}
      className="cluster-list-machine"
    >
      <Stack style={{ gap: 8 }} verticalAlign="center" horizontal>
        <div className="condition-dot" style={{ background: color }} />
        {name}
      </Stack>
    </Link>
  );
}

export default function ClusterList({ clusterPoints }) {
  const { t } = useTranslation();
  const { data, handleSearch } = useSearch({
    data: clusterPoints as any,
    keys: ["name", "projectName", "condition"],
  });

  const machinesGroupedByProjects = useMemo(
    () => groupBy(data, "projectName"),
    [data]
  );

  const machinesGroupedByCondition = useMemo(
    () => groupBy(data, "condition"),
    [data]
  );

  return (
    <div className="cluster-list">
      <Stack
        horizontal
        wrap
        styles={{
          root: { padding: 4 },
          inner: { gap: 4 },
        }}
      >
        {Object.keys(machinesGroupedByCondition).map((condition) => (
          <Stack.Item
            key={condition}
            className="condition-pill"
            style={{
              background: conditionColor[condition.replaceAll(" ", "")],
            }}
          >
            {getConditionFormat(t)[condition]}{" "}
            <strong>{`${machinesGroupedByCondition[condition].length}`}</strong>
          </Stack.Item>
        ))}
      </Stack>

      {clusterPoints.length > 5 && (
        <SearchBox
          styles={{ root: { position: "sticky", top: 0, margin: "4px" } }}
          placeholder={"Search"}
          onChange={(_, newValue) => handleSearch(newValue ?? "")}
        />
      )}

      {!data.length ? (
        <NoData />
      ) : (
        <div style={{ overflow: "auto", maxHeight: "160px" }}>
          {Object.keys(machinesGroupedByProjects).map((project) => {
            const machines = machinesGroupedByProjects[project];

            return (
              <div key={project}>
                <div className="project-name">{project}</div>
                {machines.map(renderMachine)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
