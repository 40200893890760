import type { IDropdownOption } from "@fluentui/react";
import React from "react";

import FilterIcon from "../../../../../../assets/svg/FilterIcon";
import { dropdownIconStyle } from "../../../../../../assets/styles";
import BaseCommandBar from "../../../../../common/CommandBar";
import type { CommandBarItemProps } from "../../../../../common/CommandBar/methods";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../../../common/CommandBar/methods";
import { useTranslation } from "react-i18next";

enum CommentCreated {
  AllTime = "All Time",
  PastWeek = "Past Week",
  PastMonth = "Past Month",
  PastYear = "Past Year",
}

export const commentsCreatedDropdown: IDropdownOption[] = [
  { key: 0, text: CommentCreated.AllTime },
  { key: 7, text: CommentCreated.PastWeek },
  { key: 30, text: CommentCreated.PastMonth },
  { key: 365, text: CommentCreated.PastYear },
];

type TaskCommentsCommandBarProps = {
  handleSearch?: (value: string) => void;
  onAdd: () => void;
  onFilter: (item: IDropdownOption) => void;
};

export const CommandBar = ({
  handleSearch,
  onAdd,
  onFilter,
}: TaskCommentsCommandBarProps) => {
  const { t } = useTranslation();
  const items: CommandBarItemProps[] = [
    {
      key: "newComment",
      text: t("New comment"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add" },
      onClick: onAdd,
    },
    {
      key: "filter",
      text: t("Created"),
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: 0,
        options: commentsCreatedDropdown,
        onChange: (_: never, item: IDropdownOption) => onFilter(item),
        onRenderLabelProps: {
          label: t("Created"),
          icon: (
            <FilterIcon fill="rgb(44, 82, 159)" style={dropdownIconStyle} />
          ),
        },
      },
    },
  ];

  return (
    <BaseCommandBar
      items={computeCommandBarItems(items)}
      onSearch={handleSearch}
    />
  );
};
