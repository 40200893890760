import type {
  IButtonStyles,
  ICheckboxStyles,
  ITextStyles,
  ITheme,
} from "@fluentui/react";
import { FontWeights, getTheme, mergeStyles } from "@fluentui/react";

const theme: ITheme = getTheme();

export const styles = {
  container: mergeStyles({
    backgroundColor: theme.palette.white,
    borderRadius: "8px",
    boxShadow: theme.effects.elevation8,
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  }),

  machineContainer: mergeStyles({
    backgroundColor: theme.palette.neutralLighterAlt,
    borderRadius: "6px",
    margin: "8px 0",
    overflow: "hidden",
    transition: "all 0.3s ease",
    ":hover": {
      backgroundColor: theme.palette.neutralLight,
    },
  }),

  machineHeader: mergeStyles({
    padding: "12px 16px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    borderBottom: `1px solid ${theme.palette.neutralLight}`,
  }),

  sensorContainer: mergeStyles({
    padding: "8px 16px 8px 32px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    ":hover": {
      backgroundColor: theme.palette.neutralLighter,
    },
    ".ms-Checkbox": {
      marginLeft: "16px",
      width: "100%",
    },
  }),

  searchBox: mergeStyles({
    ".ms-SearchBox": {
      border: `1px solid ${theme.palette.neutralLight}`,
      borderRadius: "4px",
    },
  }),

  headerContainer: mergeStyles({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    padding: "0 0 16px 0",
    borderBottom: `1px solid ${theme.palette.neutralLight}`,
  }),

  selectionControls: mergeStyles({
    display: "flex",
    alignItems: "center",
    gap: "16px",
  }),
};

export const componentStyles = {
  expandIcon: {
    root: {
      height: "32px",
      width: "32px",
      padding: "4px",
      margin: "0",
    },
  } as IButtonStyles,

  headerCheckbox: {
    root: {
      margin: "0",
      flex: 1,
    },
  } as ICheckboxStyles,

  searchBox: {
    root: {
      width: 300,
    },
  },

  title: {
    root: {
      fontWeight: FontWeights.semibold,
    },
  } as ITextStyles,
};
