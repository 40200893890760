import { useMemo, useState, useEffect, useCallback } from "react";
import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../../common/Form";
import BaseDialog from "../../common/Dialog";
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
} from "@fluentui/react";
import { z } from "zod";
import { Company } from "../../Companies/models";
import { maxLengthType1 } from "../../../schema/Constants";
import { addCompany, editCompany } from "../../Companies/api";
import { selectCorporationDetailsStatus } from "./reducer";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { listAsyncCorpo, selectCorporations } from "../reducer";
import { Status } from "../../../schema/status";
import type { FieldError } from "react-hook-form";

import { useTranslation } from "react-i18next";

type AddOrEditDialogProps = IDialogProps & {
  data: Company | null;
  items: Company[];
  show: boolean;
  onSuccess: (
    hasError: boolean,
    data: Company,
    context: "add" | "edit"
  ) => void;
  onClose: () => void;
};

export const AddOrEditDialog = ({
  data,
  items,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const filteredItems = useMemo(
    () =>
      // we need to exclude selected data from items when editing
      items.filter(({ name }) =>
        data
          ? name.trim().toLowerCase() !== data.name.trim().toLowerCase()
          : true
      ),
    [items, data]
  );

  const getSchema = useCallback(
    (companies: Company[]) =>
      z
        .object({
          id: z.string().optional(),
          name: z
            .string()
            .min(1, { message: t("This field is required") })
            .max(maxLengthType1, {
              message: t(
                `Name must contain at most {{maxLength}} character(s)`,
                {
                  maxLength: maxLengthType1,
                }
              ),
            }),
          number: z
            .string()
            .max(maxLengthType1, {
              message: t(
                `Number must contain at most {{maxLength}} character(s)`,
                {
                  maxLength: maxLengthType1,
                }
              ),
            })
            .optional(),
          corporationId: z.string().optional(),
        })
        .refine(
          (input) => {
            if (!input.name) {
              return true;
            }

            return (
              companies
                .map(({ name }) => name.trim().toLowerCase())
                .findIndex(
                  (value) => value === input.name.trim().toLowerCase()
                ) === -1
            );
          },
          {
            path: ["name"],
            message: t("The company already exists"),
          }
        ),
    [t]
  );

  const schema = useMemo(
    () => getSchema(filteredItems),
    [filteredItems, getSchema]
  );

  const [isLoading, setLoading] = useState(false);
  const statusParent = useAppSelector(selectCorporationDetailsStatus);
  const itemsParent = useAppSelector(selectCorporations);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        corporationId: data.corporationId,
        id: data.id,
        name: data.name,
        number: data.number,
      },
    }),
  });

  useEffect(() => {
    if (statusParent !== Status.idle) dispatch(listAsyncCorpo());
  }, [dispatch, statusParent]);

  useEffect(() => {
    data
      ? reset(data)
      : reset({ id: "", name: "", number: "", corporationId: "" });
  }, [data, reset]);

  const companiesFields: FormItemProps[] = [
    {
      name: "corporationId",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Corporation *") },
      options: itemsParent.map((item) => {
        return { key: item.id, text: item.name };
      }),
      defaultValue: data ? data.corporationId : "",
    },
    {
      name: "name",
      type: FormItemType.TextField,
      groupProps: { label: t("Name *") },
    },
    {
      name: "number",
      type: FormItemType.TextField,
      groupProps: { label: t("Number") },
    },
  ];

  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);

    const mutation = data ? editCompany : addCompany;
    await mutation(formData as Company).then((response) =>
      onSuccess(
        "status" in response,
        formData as Company,
        data ? "edit" : "add"
      )
    );

    handleClose();
  });

  const handleClose = () => {
    // reset state
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.normal,
        title: data ? t("Edit new company") : t("Add new company"),
        closeButtonAriaLabel: t("Close"),
        onDismiss: handleClose,
      }}
    >
      <form onSubmit={onSubmit}>
        {renderFormItems(companiesFields, {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}
        <DialogFooter>
          <PrimaryButton
            type="submit"
            text={t("Save Changes")}
            disabled={isLoading || !isValid}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton
            styles={{
              root: { border: "unset", background: "transparent" },
            }}
            text={t("Cancel")}
            onClick={handleClose}
          />
        </DialogFooter>
      </form>
    </BaseDialog>
  );
};
