import { Pivot, PivotItem } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent/SupportingContent";
import { AskResponse } from "../../Api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import { useTranslation } from "react-i18next";

interface Props {
  className: string;
  activeTab: AnalysisPanelTabs;
  onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
  activeCitation: string | undefined;
  citationHeight: string;
  answer: AskResponse;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({
  answer,
  activeTab,
  activeCitation,
  citationHeight,
  className,
  onActiveTabChanged,
}: Props) => {
  const { t } = useTranslation();
  const isDisabledThoughtProcessTab: boolean = !answer.thoughts;
  const isDisabledSupportingContentTab: boolean = !answer.data_points.length;
  const isDisabledCitationTab: boolean = !activeCitation;

  const sanitizedThoughts = DOMPurify.sanitize(answer.thoughts!);

  return (
    <Pivot
      className={className}
      selectedKey={activeTab}
      onLinkClick={(pivotItem) =>
        pivotItem &&
        onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)
      }
    >
      <PivotItem
        itemKey={AnalysisPanelTabs.ThoughtProcessTab}
        headerText={t("Thought process")}
        headerButtonProps={
          isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined
        }
      >
        <div
          className={styles.thoughtProcess}
          dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}
        ></div>
      </PivotItem>
      <PivotItem
        itemKey={AnalysisPanelTabs.SupportingContentTab}
        headerText={t("Supporting content")}
        headerButtonProps={
          isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined
        }
      >
        <SupportingContent supportingContent={answer.data_points} />
      </PivotItem>
      <PivotItem
        itemKey={AnalysisPanelTabs.CitationTab}
        headerText={t("Citation")}
        headerButtonProps={
          isDisabledCitationTab ? pivotItemDisabledStyle : undefined
        }
      >
        <iframe
          title="Citation"
          src={activeCitation}
          width="100%"
          height={citationHeight}
        />
      </PivotItem>
    </Pivot>
  );
};
