/* eslint-disable @typescript-eslint/no-unused-vars */

import Table, { TableProps } from "../../common/Table";
import BaseDialog from "../../common/Dialog";

import {
  DialogFooter,
  DefaultButton,
  IDialogProps,
  DialogType,
  Icon,
  Stack,
} from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import { getColumnsListQueue } from "./columnsHelper";
import { ImageQueued } from "./Images/models";
import { queuedImageList } from "./Images/api";
import { iconStyle } from "../../../schema/Constants";
import { useTranslation } from "react-i18next";

type AddOrEditDialogProps = IDialogProps & {
  show: boolean;
  onClose: () => void;
};

export const ListQueue = ({ show, onClose, ...rest }: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const handleClose = () => {
    onClose?.();
  };

  const [items, setItems] = useState<ImageQueued[]>();

  useEffect(() => {
    queuedImageList().then((res) => setItems(res));

    return () => {};
  }, []);

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-list-queued",
        version: 1,
      },
      items: items,
      perPage: 5,
      hasSelection: false,
      columns: getColumnsListQueue(t),
      hidePerPage: items && items.length < 5,
    }),
    [items, t]
  );

  const dynamicTitle = (
    <Stack
      horizontal
      tokens={{
        childrenGap: 30,
      }}
      verticalAlign="center"
    >
      <div>{t("Queued list")}</div>
      <Icon
        iconName="Refresh"
        onClick={() => queuedImageList().then((res) => setItems(res))}
        style={iconStyle}
      />
    </Stack>
  );

  return (
    <>
      {
        <BaseDialog
          {...rest}
          hidden={!show}
          dialogContentProps={{
            type: DialogType.normal,
            title: dynamicTitle,
            closeButtonAriaLabel: t("Close"),
            onDismiss: handleClose,
          }}
        >
          <Table {...tableProps}></Table>
          <DialogFooter>
            <DefaultButton
              styles={{
                root: { border: "unset", background: "transparent" },
              }}
              text={t("Cancel")}
              onClick={handleClose}
            />
          </DialogFooter>
        </BaseDialog>
      }
    </>
  );
};
