import { useTranslation } from "react-i18next";
import BaseCommandBar, {
  computeCommandBarItems,
} from "../../../common/CommandBar";
import { getColumnsSensor, getCommandBarItems } from "../columnsHelper";
import { authContext } from "../../../LeftMenuAlt/LeftMenuAlt";
import { useContext } from "react";
import { machineExport } from "../../api";
import { convertCSVtoXLSX } from "../../../../schema/Utils";
import Table, { useTableFilters } from "../../../common/Table";
import { Sensor } from "../../models";
import { useParams } from "react-router-dom";
import { commandBarStyles } from "../../../../schema/Constants";
import { useAppSelector } from "../../../../Hooks";
import { selectMachDetails, selectMachineDetailsStatus } from "../reducer";
import { Status, TypeMachineExport } from "../../../../schema/status";
import { notification } from "../../../common/Notification";

export default function SensorsContent({ setSelected, setActionSensors }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const auth = useContext(authContext);
  const items = useAppSelector(selectMachDetails);
  const status = useAppSelector(selectMachineDetailsStatus);

  const sensorFilter = useTableFilters<Sensor>({
    keys: [
      "sensorNo",
      "mountingDirection",
      "mountingType",
      "sensorDescription",
      "sensorSerialNo",
    ],
  });

  return (
    <>
      <BaseCommandBar
        items={computeCommandBarItems(
          getCommandBarItems({
            t,
            title: t("Sensors"),
            onChange(files) {
              setSelected({
                context: "sensors",
                file: files.at(0),
              });
            },
            addButton: auth.metaDataContributor,
            importButton: auth.metaDataContributor,
            onAdd() {
              setActionSensors({
                data: undefined,
                context: "add",
              });
            },
            xlsxToo: true,
            exportable: auth.metaDataReader,
            onExport() {
              machineExport(id, TypeMachineExport.Sensors).then((resp) => {
                const success = typeof resp === "string";
                !success
                  ? notification.error(
                      t("Something went wrong on Events export.")
                    )
                  : convertCSVtoXLSX(resp, "Sensors_" + id + ".xlsx");
              });
            },
          })
        )}
        onSearch={sensorFilter.handleSearch}
        styles={commandBarStyles}
      />
      <Table
        persistOpts={{
          key: "table-sensors",
          version: 2,
        }}
        header={{
          title: t("Sensors"),
          isExportable: false,
        }}
        items={items?.sensors}
        columns={getColumnsSensor({
          t,
          hasActions: auth.metaDataContributor,
          onEdit(sensor) {
            setActionSensors({
              data: sensor,
              context: "edit",
            });
          },
          onDelete(sensorId) {
            setActionSensors({
              data: sensorId,
              context: "delete",
            });
          },
        })}
        filters={sensorFilter.filters}
        hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
        //perPage={bigScreen ? 20 : 10}
      />
    </>
  );
}
