import type { DataItem } from "@amcharts/amcharts5";
import * as am5 from "@amcharts/amcharts5";
import type { IValueAxisDataItem } from "@amcharts/amcharts5/xy";
import * as am5xy from "@amcharts/amcharts5/xy";

import DalogLogo from "../../../../../../assets/images/dalog-logo.svg";
import { watermarkLogos } from "../../../../../../modules/analysis-raw-data/constants/chartWatermarkLogos";
import { yAxisWheel } from "../../../../../../modules/analysis-trend-view/components/TrendViewGroupedPlot/methods";
import { createTorqueTooltip } from "../../../../MachineCVDriveLoadPage/components/TorqueChart/createTooltip";

import { performanceSignalsChartColors } from "./config";
import { t } from "i18next";

const removeYAxisRange = (ref: any) => {
  const yAxis = ref.current?.chart.yAxes.getIndex(0);

  const ranges: DataItem<IValueAxisDataItem>[] = [];

  yAxis?.axisRanges.each((range: any) => {
    ranges.push(range);
  });

  ranges.forEach((range) => {
    yAxis?.axisRanges.removeValue(range);
  });
};

const createRange = (value: any, color: any, ref: any) => {
  if (!ref.current) return;
  const yAxis = ref.current?.chart.yAxes.getIndex(0);
  const rangeDataItem = yAxis?.makeDataItem({ value, affectsMinMax: true });

  const range = yAxis?.createAxisRange(rangeDataItem);

  range?.get("axisFill")?.setAll({
    fill: color,
    fillOpacity: 0.2,
    visible: true,
  });
  range?.get("grid")?.setAll({
    stroke: color,
    strokeOpacity: 1,
    strokeWidth: 2,
    strokeDasharray: [5, 3],
  });
};

export const createSeriesOneYaxis = ({
  ref,
  xAxis,
  yAxis,
  data,
  signals,
}: any) => {
  if (!ref.current) return;

  const { root, chart } = ref.current;

  // Remove prev series
  chart.series.clear();

  // Remove prev ranges
  removeYAxisRange(ref);

  for (let index = 0; index < signals.length; index++) {
    const color = (performanceSignalsChartColors[index] || {}).color
      ? am5.color(performanceSignalsChartColors[index].color)
      : null;
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: t(
          signals[index].signalDescription ||
            signals[index].name ||
            "No signal name"
        ),
        unit: signals[index].unit,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: `value`,
        valueXField: `timeStamp`,
        stroke: color,
        fill: color,
        snapTooltip: true,
      })
    );

    series.bullets.push(function (root: any, series: any, dataItem: any) {
      let circleSettings = {};

      if (dataItem?.dataContext?.isBest) {
        const bestValueColor = "#66CD7D";

        circleSettings = {
          ...circleSettings,
          radius: 7,
          fill: am5.color(bestValueColor),
        };

        createRange(dataItem?.dataContext?.value, bestValueColor, ref);
      }

      if (dataItem?.dataContext?.isWorst) {
        const worstValueColor = "#F63F4D";

        circleSettings = {
          ...circleSettings,
          radius: 7,
          fill: am5.color(worstValueColor),
        };

        createRange(dataItem?.dataContext?.value, worstValueColor, ref);
      }

      const circle = am5.Circle.new(root, circleSettings);
      return am5.Bullet.new(root, {
        sprite: circle,
      });
    });

    series.data.setAll(data?.[index] || []);

    // === Snap to series ==
    const cursor = chart.get("cursor");

    cursor?.set("snapToSeries", [...chart.series.values]);
    cursor?.set("snapToSeriesBy", "y!");
    cursor?.set("xAxis", xAxis);
    cursor?.set("yAxis", yAxis);
  }
};

export function initializePerformanceChart({
  ref,
  entityIds,
  data,
  machineId,
  signals,
  updateStore,
}: any) {
  const { root, chart } = ref.current;

  // ==== X Axis ===
  const xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      id: entityIds.xAxis,
      baseInterval: { timeUnit: "second", count: 0 },
      renderer: am5xy.AxisRendererX.new(root, {}),
      maxDeviation: 0,
      marginTop: 16,
      tooltip: am5.Tooltip.new(root, {
        forceHidden: true,
      }),
    })
  );

  xAxis.get("renderer").labels.template.set("fontSize", 10);

  // ==== Y Axis ===
  // Remove prev y axis
  chart.yAxes.clear();

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      id: entityIds.yAxis,
      extraTooltipPrecision: 1,
      renderer: am5xy.AxisRendererY.new(root, {}),
      tooltip: am5.Tooltip.new(root, {
        forceHidden: true,
      }),
    })
  );

  yAxis.events.on("wheel", (ev: any) => yAxisWheel(ev, yAxis));
  yAxis.zoom(0, 1);
  yAxis.get("renderer").labels.template.set("fontSize", 10);

  // === Series ===
  createSeriesOneYaxis({ ref, xAxis, yAxis, data, signals });

  // === Chart-Modal functionality ===

  const cursor = chart.get("cursor");

  chart.plotContainer.set("cursorOverStyle", "pointer");

  // === Open Modal on point chart click ===
  chart.plotContainer.events.on("click", function () {
    const selectStatedX = cursor?.getPrivate("positionX") as number;

    const xValue = xAxis.positionToValue(xAxis.toAxisPosition(selectStatedX));

    updateStore({
      isIndicesModalOpen: true,
      selectedTimeStamp: xValue,
    });
  });

  // === watermarks ===
  watermarkLogos.forEach((logoData) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const logo = am5.Picture.new(root, {
      src: DalogLogo,
      height: logoData.height,
      opacity: logoData.opacity,
      x: logoData.x,
      y: logoData.y,
      centerX: logoData.centerX,
      centerY: logoData.centerY,
    });

    ref.current?.chart.plotContainer.children.push(logo);
  });

  createTorqueTooltip({
    ref: ref.current,
    type: "date",
  });
}
