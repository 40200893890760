import { useMemo } from "react";

import { useFileDownload } from "../../../../Hooks/useFile";
import useDocumentsStore from "../hooks/useDocumentsStore";
import BaseCommandBar from "../../../common/CommandBar";
import type { CommandBarItemProps } from "../../../common/CommandBar/methods";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar/methods";

import type { DocumentResponse } from "../hooks/useDocumentsQuery";
import { useTranslation } from "react-i18next";

type DocumentsCommandBarProps = {
  handleSearch?: (value: string) => void;
};

export const CommandBar = ({ handleSearch }: DocumentsCommandBarProps) => {
  const { t } = useTranslation();

  const onDelete = () => dispatch({ type: "SHOW_DELETE", payload: true });
  const onUpload = () => dispatch({ type: "SHOW_UPLOAD", payload: true });

  const { downloadMultipleFiles } = useFileDownload();

  const onDownload = async () => {
    const files = selectedItems.map(({ id, name }) => ({ id, name }));
    await downloadMultipleFiles(files);
  };

  const { selectedItems, dispatch } = useDocumentsStore((state) => ({
    selectedItems: state.selectedItems as DocumentResponse[],
    dispatch: state.dispatch,
  }));

  const items: CommandBarItemProps[] = useMemo(
    () => [
      {
        key: "upload",
        text: t("Upload"),
        className: "secondary-dropdown",
        type: CommandBarItemType.Button,
        iconProps: { iconName: "Upload" },
        onClick: onUpload,
      },
      {
        key: "download",
        text: t("Download"),
        className: "secondary-dropdown",
        type: CommandBarItemType.Button,
        iconProps: { iconName: "Download" },
        onClick: onDownload,
        ...(selectedItems.length === 0 && {
          disabled: true,
        }),
      },
      {
        key: "delete",
        text: t("Delete"),
        className: "secondary-dropdown",
        type: CommandBarItemType.Button,
        iconProps: { iconName: "Delete" },
        onClick: onDelete,
        ...(selectedItems.length === 0 && {
          disabled: true,
        }),
      },
    ],
    [selectedItems.length, t]
  );

  return (
    <BaseCommandBar
      items={computeCommandBarItems(items)}
      onSearch={handleSearch}
    />
  );
};
