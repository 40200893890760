import "./Overview.scss";

import { Stack, Text, Toggle } from "@fluentui/react";
import type { ReactElement } from "react";

import { format } from "../../../../../utils";

import StatusBadge from "../StatusBadge";
import { useTranslation } from "react-i18next";

interface OverviewProps {
  status?: string;
  machine?: {
    customerCode: string;
    dalogId: string;
    manufacturer: string;
    name: string;
    notation: string;
    process: string;
    projectId: string;
    type: string;
    dataFrom: string;
    dataUntil: string;
    eventDataFrom: string;
    eventDataUntil: string;
    fastTrendDataFrom: string;
    fastTrendDataUntil: string;
    id: string;
    shortTermDataFrom: string;
    shortTermDataUntil: string;
    trendDataFrom: string;
    trendDataUntil: string;
    drawingIds: {
      fileId: string;
      sortIndex: number;
    }[];
    gearbox: {
      manufacturer: string;
      name: string;
      notation: string;
      power: number;
      rotationalSpeed: number;
      serialnumber: string;
    };
  };
  hideTopSection?: boolean;
  onHideTopSection?: () => void;
  onAddTask: () => void;
  onAddDocument: () => void;
  title?: string | undefined;
  description?: string | undefined;
  customBadge?: ReactElement;
}

const Overview = ({
  machine,
  status,
  hideTopSection,
  onHideTopSection,
  onAddTask,
  onAddDocument,
  title,
  description,
  customBadge,
}: OverviewProps) => {
  const { t } = useTranslation();
  const condition = {
    id: null,
    value: status,
  };

  return (
    <Stack className="overview-container">
      <Stack
        horizontal
        style={{ gap: 16 }}
        className="overview-container__left-side"
      >
        <Stack style={{ borderRight: "1px solid #E1DFDD", paddingRight: 16 }}>
          <Text variant="mediumPlus" as="h2" className="overview-title">
            {title ||
              t(`Condition Overview ({{machineName}})`, {
                machineName: machine?.name,
              })}
          </Text>
          <Text variant="medium" className="overview-subtitle" as="span">
            {format(new Date())}
          </Text>
          {description && (
            <Text variant="medium" className="overview-description">
              {description}
            </Text>
          )}
        </Stack>
        {onHideTopSection ? (
          <Toggle
            inlineLabel
            label={t("Drawings & Conditions")}
            styles={{
              root: {
                marginBottom: 0,
              },
            }}
            checked={!hideTopSection}
            onChange={(_) => onHideTopSection && onHideTopSection()}
          />
        ) : null}
      </Stack>
      <Stack horizontal className="overview-container__right-side">
        <StatusBadge
          data={condition}
          customBadge={customBadge}
          onAddTask={onAddTask}
          onAddDocument={onAddDocument}
        />
      </Stack>
    </Stack>
  );
};

export default Overview;
