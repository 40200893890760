import type { IComboBoxProps } from "@fluentui/react";
import { ComboBox } from "@fluentui/react";
import { isArray } from "lodash-es";
import { Controller } from "react-hook-form";

import type { BaseFormFieldProps } from "../types";
import { FormGroup } from "./FormGroup";

export type FormDropdownProps = BaseFormFieldProps &
  IComboBoxProps & {
    name: string;
  };

export const FormDropdown = ({
  name,
  defaultValue,
  groupProps,
  hookProps: { control, errors },
  ...rest
}: FormDropdownProps) => (
  <FormGroup {...groupProps}>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <ComboBox
            allowFreeform
            selectedKey={value}
            allowFreeInput={false}
            styles={{ root: { width: "100%" }, container: { width: "100%" } }}
            errorMessage={errors?.[name]?.message}
            autoComplete="on"
            onBlur={onBlur}
            onChange={(_, option) => {
              const { multiSelect } = rest;
              if (multiSelect) {
                const { selected, key }: any = option || {};
                const filteredValues = isArray(value)
                  ? value.filter((item) => item !== key)
                  : [];
                onChange(
                  selected ? [...filteredValues, key] : [...filteredValues]
                );
              } else {
                onChange(option!.key);
              }
            }}
            {...rest}
            label={undefined}
          />
        );
      }}
    />
  </FormGroup>
);
