import {
  Text,
  Breadcrumb,
  Stack,
  IconButton,
  StackItem,
  IStackTokens,
} from "@fluentui/react";
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Status } from "../../../schema/status";
import { useAppDispatch, useAppSelector } from "../../../Hooks";

import Table from "../../common/Table";
import { notification } from "../../common/Notification";

import {
  listAsyncMachines,
  selectMachine,
  selectMachinesError,
  selectMachinesStatus,
} from "../../Machines/reducer";
import { getColumnsSignal } from "../../Machines/MachineDetails/columnsHelper";
import { ResponseSimplifiedSignal, Sensor } from "../../Machines/models";
import { sensorsList } from "../api";
import { WirelessSensorNode } from "../../SensorNodes/models";
import { list } from "../../SensorNodes/api";
import { UpdateSignalCondition } from "../../Signals/models";
import { signalsPerSensorGet } from "./api";
import { EditCondition } from "../../Signals/EditCondition";
import { CUDDialog } from "../../Signals/CUDDialog";
import { authContext } from "../../LeftMenuAlt/LeftMenuAlt";
import { useTranslation } from "react-i18next";

// Styles //
const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "auto",
};

const stackTokens: IStackTokens = {
  childrenGap: 50,
};

export const SensorDetails: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const auth = useContext(authContext);
  const { machineId, id } = useParams();
  const [items, setItems] = useState<ResponseSimplifiedSignal[]>();
  const [sensors, setSensors] = useState<Sensor[]>();
  const [sensorNodes, setSensorNodes] = useState<WirelessSensorNode[]>();

  const [actionSignals, setActionSignals] = useState<{
    data: ResponseSimplifiedSignal | string | undefined;
    context: "add" | "edit" | "delete";
  }>();

  const [actionSignalCondition, setActionSignalsCondition] = useState<{
    data: UpdateSignalCondition;
    context: "edit";
  }>();

  //const machines = useAppSelector(selectMachinesToList);
  const status = useAppSelector(selectMachinesStatus);
  const error = useAppSelector(selectMachinesError);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    status === Status.void && dispatch(listAsyncMachines());
  }, [dispatch, status]);

  const machine = useAppSelector((state) => selectMachine(state, machineId));
  const navigate = useNavigate();

  useEffect(() => {
    signalsPerSensorGet(machineId, id).then((res) =>
      "status" in res
        ? notification.error(t("Signal fetching went wrong."))
        : setItems(res)
    );
    sensorsList(machineId).then((res) =>
      "status" in res
        ? notification.error(t("Sensors fetching went wrong."))
        : setSensors(res)
    );
    list().then((resp) =>
      !("status" in resp)
        ? setSensorNodes(resp.filter((sens) => sens.machineId === machineId))
        : setSensorNodes([])
    );
  }, [machineId, id]);

  const content = (
    <Stack tokens={stackTokens} styles={{ root: { margin: "50px" } }}>
      <StackItem>
        <Text style={titleStylePivot}>Details:</Text>
        <Stack>
          <Table
            persistOpts={{
              key: "table-signalCondition",
              version: 2,
            }}
            header={{
              title: t("Details"),
            }}
            items={items}
            columns={getColumnsSignal({
              t,
              onEditCondition(signalId, condition) {
                setActionSignalsCondition({
                  data: { id: signalId, condition: condition },
                  context: "edit",
                });
              },
              onEdit(signal) {
                setActionSignals({
                  data: signal,
                  context: "edit",
                });
              },
              noLink: true,
              metaDataContributor: auth.metaDataContributor,
            })}
            hasSelection={false}
            isLoading={items === undefined}
            hidePerPage
            isError={status === Status.error}
          />
        </Stack>
      </StackItem>
    </Stack>
  );

  const goBack = () => navigate(-1);
  return (
    <>
      <Stack horizontal verticalAlign="center">
        <IconButton iconProps={{ iconName: "Back" }} onClick={goBack} />
        <Breadcrumb
          items={[
            {
              key: `corporation-${machine?.corporation?.number}`,
              text: machine?.corporation?.name,
            },
            {
              key: `company-${machine?.company?.number}`,
              text: machine?.company?.name,
            },
            {
              key: `project-${machine?.project?.name}`,
              text: machine?.project?.name,
            },
            { key: `machine-${machine?.dalogId}`, text: machine?.dalogId },
            //{ key: `signal-${item?.name}`, text: item?.name },
          ]}
          styles={{
            root: { margin: 0 },
            item: { fontSize: "14px" },
            chevron: { fontSize: "10px" },
          }}
        />
      </Stack>
      {content}
      {actionSignalCondition && (
        <EditCondition
          machineId={machineId}
          data={actionSignalCondition?.data}
          show={["edit"].includes(actionSignalCondition?.context)}
          onSuccess={(hasError) => {
            if (hasError) {
              const message = t(`Failed updating Signal condition`);

              notification.error(message);
            } else {
              signalsPerSensorGet(machineId, id).then((res) =>
                "status" in res
                  ? notification.error(t("Signal fetching went wrong."))
                  : setItems(res)
              );
              const message = t(`Signal condition updated successfully`);
              notification.success(message);
            }
          }}
          onClose={() => {
            setActionSignalsCondition(undefined);
          }}
        ></EditCondition>
      )}
      {actionSignals && (
        <CUDDialog
          machineId={machineId}
          data={actionSignals?.data}
          sensors={sensors}
          sensorNodes={sensorNodes}
          show={["add", "edit", "delete"].includes(actionSignals?.context)}
          onSuccess={(hasError, context) => {
            if (hasError) {
              let message = t(
                "Failed deleting (It may not be a custom signal.)"
              );

              if (context === "add") {
                message = t("Failed creating Signal");
              }

              if (context === "edit") {
                message = t("Failed updating Signal");
              }

              notification.error(message);
            } else {
              signalsPerSensorGet(machineId, id).then((res) =>
                "status" in res
                  ? notification.error(t("Signal fetching went wrong."))
                  : setItems(res)
              );
              const message = t(`Signal {{context}} successfully`, { context });

              notification.success(message);
            }
          }}
          onClose={() => {
            setActionSignals(undefined);
          }}
          context={actionSignals?.context}
        />
      )}
    </>
  );
};
