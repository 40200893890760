import { ThemeProvider } from "@fluentui/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { AxiosInstance } from "axios";
import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import * as ReactRouterDOM from "react-router-dom";

import { queryClient } from "../modules/core";
import useApiStore from "../modules/core/apiClient/useApiStore";
import MachineCVNavigationBar from "../modules/machine-cv/components/MachineCVNavigationBar";
import { theme } from "../theme";
import { Spin } from "../Components/common/Spin";
import { useTranslation } from "react-i18next";

type ErrorFallbackProps = {
  error: Error;
};

const ErrorFallback = ({ error }: ErrorFallbackProps) => {
  const { t } = useTranslation();
  // TODO: Uncomment once components are migrated
  // if (import.meta.env.PROD) {
  //   return <ReactRouterDOM.Navigate replace to="/error" />;
  // }

  return (
    <div style={{ padding: 16 }}>
      <p>{t("Something went wrong")}:</p>
      <pre>{error.message}</pre>
      <pre>{error.stack}</pre>
    </div>
  );
};

interface AppLayoutProps {
  axiosInstance: AxiosInstance;
  children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ axiosInstance, children }) => {
  const apiStore = useApiStore();

  const location = ReactRouterDOM.useLocation();

  const { pathname } = location;

  // Split the pathname into segments
  const pathSegments = pathname.split("/");

  // Find the index of "machine-cv" in the pathSegments array
  const machineCvIndex = pathSegments.indexOf("machine-cv");

  // Check if there is something after "machine-cv"
  const hasSomethingAfterMachineCv =
    machineCvIndex !== -1 && machineCvIndex < pathSegments.length - 1;

  const handleError = (
    error: Error,
    info: {
      componentStack: string;
    }
  ) => {
    // Tracking analytics
    console.log("LOGGING", error, info);
  };

  useEffect(() => {
    apiStore.setApiClient(axiosInstance);
  }, [axiosInstance]);

  if (!apiStore.apiClient) {
    return (
      <ThemeProvider style={{ background: "#FAF9F8" }} theme={theme}>
        <Spin style={{ margin: "50vh auto 0 auto" }} />
      </ThemeProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        <ThemeProvider
          style={{ background: "#FAF9F8", position: "relative" }}
          theme={theme}
        >
          {hasSomethingAfterMachineCv ? (
            <MachineCVNavigationBar>{children}</MachineCVNavigationBar>
          ) : (
            <div>{children}</div>
          )}
        </ThemeProvider>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default AppLayout;
