import { DefaultButton, IButtonProps, mergeStyles } from "@fluentui/react";
import React, { useRef } from "react";

type UploadButtonProps = Omit<IButtonProps, "onClick"> & {
  accept: string;
  onFilesSelected: (files: File[]) => void;
  multiple?: boolean;
};

export const UploadDefaultButton = ({
  accept,
  onFilesSelected,
  multiple,
  ...rest
}: UploadButtonProps) => {
  const htmlFileInputRef = useRef(null);

  // Handlers
  const onFilesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length === 0) {
      return;
    }

    onFilesSelected?.(Array.from(event.target.files));
  };

  return (
    <React.Fragment>
      <DefaultButton
        {...rest}
        text="Browse..."
        onClick={() => htmlFileInputRef?.current.click()}
      />
      <input
        type="file"
        accept={accept}
        className={mergeStyles({ display: "none" })}
        ref={htmlFileInputRef}
        multiple={multiple}
        onChange={onFilesChanged}
      />
    </React.Fragment>
  );
};
