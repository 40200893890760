export const getOperationView = (t) => [
  { key: "Yearly", text: t("Yearly") },
  { key: "Monthly", text: t("Monthly") },
];

export const DATE_FORMAT_DAYJS = "YYYY-MM-DDTHH:mm:ss.SSS";

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const shortMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
