import { Stack } from "@fluentui/react";

import "./styles.scss";

import Machines from "./Panels/Machines";
import Conditions from "./Panels/Conditions";
import LastDataUpdate from "./Panels/LastDataUpdate";
import { useHomeOverviewQuery } from "../../../hooks/useHomeOverviewQuery";
import { Spin } from "../../../../../common/Spin";
import { selectUserSettings } from "../../../../../Settings/selectors";
import { useAppSelector } from "../../../../../../Hooks";

export const OVERVIEW_SECTIONS = {
  machinesCounter: <Machines key="machinesCounter" />,
  conditions: <Conditions key="conditions" />,
  lastDataUpdate: <LastDataUpdate key="lastDataUpdate" />,
};

export default function Overview() {
  const { dashboardSettings } = useAppSelector(selectUserSettings);
  const { isLoading } = useHomeOverviewQuery();

  if (isLoading) {
    return <Spin />;
  }

  function renderSetSection(sectionKey: string) {
    return dashboardSettings?.[sectionKey]
      ? OVERVIEW_SECTIONS[sectionKey]
      : null;
  }

  return (
    <Stack
      horizontal
      styles={{
        root: {
          gap: "8px",
        },
      }}
    >
      {Object.keys(OVERVIEW_SECTIONS).map(renderSetSection)}
    </Stack>
  );
}
