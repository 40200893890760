import { t } from "i18next";
import Icon1 from "../../../../MachineCVDriveLoadPage/components/TorqueChart/torqueSvgs/Icon1.svg";
import Icon2 from "../../../../MachineCVDriveLoadPage/components/TorqueChart/torqueSvgs/Icon2.svg";

export const performanceSignalsChartColors = [
  {
    name: t("Performance"),
    color: "#61A329",
    icon: Icon1,
  },
  {
    name: t("Performance Tendency"),
    color: "#344c92",
    icon: Icon2,
  },
];
