// ProgressSpinner.tsx
import type { IStackStyles, IStackTokens } from "@fluentui/react";
import {
  MessageBar,
  MessageBarType,
  ProgressIndicator,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ProgressSpinnerProps {
  totalPromises: number;
  completedPromises: number;
  errors: Array<{ timeId: string; message: string }>;
}

const containerStyles: IStackStyles = {
  root: {
    width: "80vw",
    height: "80vh",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
};

const contentStyles: IStackStyles = {
  root: {
    width: "500px",
    padding: "24px",
    backgroundColor: "white",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 16,
  padding: 8,
};

const ProgressSpinner: React.FC<ProgressSpinnerProps> = ({
  totalPromises,
  completedPromises,
  errors,
}) => {
  const { t } = useTranslation();
  const [percentComplete, setPercentComplete] = useState(0);

  useEffect(() => {
    const progress = totalPromises > 0 ? completedPromises / totalPromises : 0;
    setPercentComplete(progress);
  }, [completedPromises, totalPromises]);

  return (
    <Stack styles={containerStyles}>
      <Stack styles={contentStyles} tokens={stackTokens}>
        <Stack horizontalAlign="center" tokens={{ childrenGap: 12 }}>
          <Spinner
            size={SpinnerSize.large}
            label={t("Processing operations...")}
            labelPosition="bottom"
          />

          <Text block variant="large">
            {t("Saving automatic triggers data")}
          </Text>

          <ProgressIndicator
            description={t(
              `{{operationsNum}} of {{totalOperations}} operations completed`,
              {
                operationsNum: completedPromises,
                totalOperations: totalPromises,
              }
            )}
            percentComplete={percentComplete}
            styles={{
              itemName: { textAlign: "center" },
              itemDescription: { textAlign: "center" },
            }}
          />
        </Stack>

        {errors.length > 0 && (
          <Stack tokens={{ childrenGap: 8 }}>
            <Text
              block
              variant="mediumPlus"
              styles={{ root: { fontWeight: 600 } }}
            >
              {t("Errors encountered:")}
            </Text>
            {errors.map((error, index) => (
              <MessageBar
                key={index}
                messageBarType={MessageBarType.error}
                isMultiline={true}
                dismissButtonAriaLabel="Close"
              >
                {t("Error in {{timeId}}: {{message}}", {
                  timeId: error.timeId,
                  message: error.message,
                })}
              </MessageBar>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default ProgressSpinner;
