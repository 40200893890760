import "./WaterfallChart.scss";

import { useBoolean } from "../../../../Hooks";
import Plotly from "plotly.js/dist/plotly.min.js";
import React, { useEffect, useRef, useState } from "react";
import createPlotlyComponent from "react-plotly.js/factory";

import {
  getColorScaleOptions,
  plotConfig,
  plotStyles,
} from "../WaterfallChart/config";
import WaterfallChartTooltip from "../WaterfallChartTooltip";
import type { InteractiveColorValues } from "../../../common/components/InteractiveColorBar";
import InteractiveColorBar from "../../../common/components/InteractiveColorBar";

import useHarmonicsStoreV0 from "../../hooks/useHarmonicsStoreV0";
import useLayoutStore from "../../hooks/useLayoutStore";
import useMinMaxValue from "../../hooks/useMinMaxValue";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import useWaterfallStore from "../../hooks/useWaterfallStore";
import type {
  WaterfallChartInterface,
  WaterfallChartMethods,
} from "../../types";
import { harmonicsInitialState } from "../SpectrumChart/harmonicsReducer";
import {
  createWaterfallHarmonics,
  selectValuesWithEqualDistance,
} from "./methods";
import ReductionDialog from "./ReductionDialog";
import useHarmonicsStore from "./useHarmonicsStore";
import WaterfallContextMenu from "./WaterfallContextMenu";
import { useTranslation } from "react-i18next";

const Plot: React.ComponentType<
  Plotly.PlotlyHTMLElement & {
    data: Partial<Plotly.Data>[];
    layout: Partial<Plotly.Layout>;
  }
> = createPlotlyComponent(Plotly);

interface WaterfallChartProps extends WaterfallChartInterface {
  onInit: (methods: WaterfallChartMethods) => void;
}

const WaterfallChart = ({ data, onInit }: WaterfallChartProps) => {
  const { t } = useTranslation();
  const plotRef: React.MutableRefObject<Plotly.PlotlyHTMLElement | undefined> =
    useRef(null);
  const [yChartValues, setYChartValues] = useState<string[]>([]);
  const [yChartTickLabels, setYChartTickLabels] = useState<string[]>([]);
  const { downloadImage, relayout } = Plotly;
  const containerWidth = useLayoutStore((state) => state.containerWidth);
  const [minValue, maxValue, rms] = useMinMaxValue({ data });
  const [hideDialog, { toggle: toggleReductionDialog }] = useBoolean(true);

  const { machineId_1 } = useSelectedSignalsStore((store) => ({
    machineId_1: store.machineId_1,
  }));

  const { machines, updateHarmonics } = useHarmonicsStoreV0();

  const machineState = machines[machineId_1!] || {
    harmonicsOne: harmonicsInitialState,
    harmonicsTwo: harmonicsInitialState,
    harmonicsThree: harmonicsInitialState,
  };

  const { harmonicsOne, harmonicsTwo, harmonicsThree } = machineState;

  const {
    waterfallData,
    colorScale,
    layout,
    interpolation,
    plotType,
    resetColorBar,
    setResetColorBar,
    setInterpolation,
    setLayout,
    setColorScale,
    setChartHoverParams,
    setWaterfallData,
    setPlotType,
    setYaxisDate,
  } = useWaterfallStore((state) => ({
    waterfallData: state.waterfallData,
    colorScale: state.colorScale,
    layout: state.layout,
    interpolation: state.interpolation,
    plotType: state.plotType,
    resetColorBar: state.resetColorBar,
    setResetColorBar: state.setResetColorBar,
    setInterpolation: state.setInterpolation,
    setLayout: state.setLayout,
    setColorScale: state.setColorScale,
    setChartHoverParams: state.setChartHoverParams,
    setWaterfallData: state.setWaterfallData,
    setPlotType: state.setPlotType,
    setYaxisDate: state.setYaxisDate,
  }));

  const {
    harmonicsOneList,
    harmonicsTwoList,
    harmonicsThreeList,
    setHarmonicsOneList,
    setHarmonicsTwoList,
    setHarmonicsThreeList,
  } = useHarmonicsStore((state) => ({
    harmonicsOneList: state.harmonicsOneList,
    harmonicsTwoList: state.harmonicsTwoList,
    harmonicsThreeList: state.harmonicsThreeList,
    setHarmonicsOneList: state.setHarmonicsOneList,
    setHarmonicsTwoList: state.setHarmonicsTwoList,
    setHarmonicsThreeList: state.setHarmonicsThreeList,
  }));

  // Re-applies styling to the waterfall
  useEffect(() => {
    if (!plotRef?.current?.el) return;

    setWaterfallData([
      {
        ...waterfallData[0],
        type: plotType,
        zsmooth: interpolation,
      },
    ]);
  }, [interpolation, plotType]);

  // Updates the color scale.
  useEffect(() => {
    setWaterfallData([
      {
        ...waterfallData[0],
        colorscale: colorScale.scale,
      },
    ]);
  }, [colorScale.key]);

  // Updates the waterfall data points
  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    const yAxisValues = data.map(
      ({ timeStamp }) => timeStamp
    ) as unknown as string[];
    let zAxisValues = data.map(({ values }) => values.map(({ a }) => a));
    const maxLength = Math.max(...zAxisValues.map((arr) => arr.length));
    zAxisValues = zAxisValues.map((arr) =>
      arr.length < maxLength
        ? [...arr, ...Array(maxLength - arr.length).fill(0)]
        : arr
    );

    // Extract unique frequency values for x-axis labels
    const frequencies = Array.from(
      new Set(data.flatMap((obj) => obj.values.map((val) => val.f)))
    );

    // Sort the frequency values
    frequencies.sort((a, b) => a - b);

    // Create x-axis labels array
    const xAxisValues = [] as string[];

    // Populate x-axis labels array
    frequencies.forEach((frequency) => {
      xAxisValues.push(frequency.toString());
    });

    const waterfallChartData = {
      z: zAxisValues.reverse(),
      x: xAxisValues,
      y: yAxisValues.reverse(),
      autocolorscale: false,
      colorscale: colorScale.scale,
      zmin: minValue,
      zmax: rms || maxValue,
      type: plotType,
      hoverongaps: false,
      zsmooth: interpolation,
      showscale: false,
      hoverinfo: "none",
      connectgaps: true,
    };

    setYChartValues(yAxisValues.reverse());
    setYChartTickLabels(
      yAxisValues
        .reverse()
        .map((timestamp) => new Date(timestamp).toISOString().slice(0, 10))
    );
    setWaterfallData([waterfallChartData]);
    setYaxisDate({
      start: yAxisValues[0],
      end: yAxisValues[yAxisValues.length - 1],
    });
    setChartHoverParams(undefined);
  }, [data]);

  //useEffect(() => clearHarmonics(), [clearHarmonicsTrigger]);

  useEffect(() => {
    const shapes = createWaterfallHarmonics({
      harmonicsNumber: harmonicsOne.linesNumber,
      harmonicsFreq: harmonicsOne.linesFreq,
      sidebandsNumber: harmonicsOne.sidebandsNumber,
      sidebandsFreq: harmonicsOne.sidebandsFreq,
      color: "red",
    });

    setHarmonicsOneList(shapes);
  }, [harmonicsOne]);

  useEffect(() => {
    const shapes = createWaterfallHarmonics({
      harmonicsNumber: harmonicsTwo.linesNumber,
      harmonicsFreq: harmonicsTwo.linesFreq,
      sidebandsNumber: harmonicsTwo.sidebandsNumber,
      sidebandsFreq: harmonicsTwo.sidebandsFreq,
      color: "blue",
    });

    setHarmonicsTwoList(shapes);
  }, [harmonicsTwo]);

  useEffect(() => {
    const shapes = createWaterfallHarmonics({
      harmonicsNumber: harmonicsThree.linesNumber,
      harmonicsFreq: harmonicsThree.linesFreq,
      sidebandsNumber: harmonicsThree.sidebandsNumber,
      sidebandsFreq: harmonicsThree.sidebandsFreq,
      color: "green",
    });

    setHarmonicsThreeList(shapes);
  }, [harmonicsThree]);

  // Handlers
  const savePlotAs = ({ value }: { value: string }) => {
    downloadImage(plotRef?.current?.el, {
      format: value,
      width: 800,
      height: 600,
      filename: "DALOG-Waterfall-Chart",
    });
  };

  const resetPlot = () => {
    setResetColorBar(new Date());

    setInterpolation("best");

    setColorScale(getColorScaleOptions(t)[0]);

    setChartHoverParams(undefined);

    setPlotType("heatmap");

    relayout(plotRef?.current?.el, {
      xaxis: { autorange: true },
      yaxis: { autorange: true, type: "category" },
    });

    setLayout({
      ...layout,
      xaxis: {
        ...layout.xaxis,
        title: {
          text: t("Frequency (Hz)"),
          standoff: 20,
          font: {
            size: 10,
          },
        },
        autorange: true,
      },
      yaxis: {
        ...layout.yaxis,
        autorange: true,
        type: "category",
      },
    });
  };

  const setPlotScheme = ({ value }: { value: string }) => {
    const selectedColorScale = getColorScaleOptions(t).filter(
      (item) => item.key === value
    )[0];
    setColorScale(selectedColorScale || getColorScaleOptions(t)[0]);
  };

  const onInitialized = () => {
    onInit({
      savePlotAs,
      resetPlot,
      toggleReductionDialog,
      setPlotScheme,
    });
  };

  const setYaxisStartEndDate = (ev: any = {}) => {
    const data = plotRef.current?.el?.data
      ? plotRef.current.el.data[0] || { y: [] }
      : { y: [] };
    const yValues = data.y;

    const startIndex = Math.round(ev["yaxis.range[0]"] || data.y[0]);
    const endIndex = Math.round(
      ev["yaxis.range[1]"] || data.y[data.y.length - 1]
    );

    const startTimestamp = yValues[startIndex] || data.y[0];
    const endTimestamp = yValues[endIndex] || data.y[data.y.length - 1];

    const formatDate = (timestamp: string) => new Date(timestamp).toISOString();

    const formattedStart = formatDate(startTimestamp);
    const formattedEnd = formatDate(endTimestamp);

    setYaxisDate({
      start: formattedStart,
      end: formattedEnd,
    });
  };

  const onColorValuesChange = (newValue: InteractiveColorValues) => {
    setWaterfallData([
      {
        ...waterfallData[0],
        zmin: newValue.bottom,
        zmax: newValue.top,
      },
    ]);
  };

  const clearHarmonics = () => {
    setHarmonicsOneList([]);
    setHarmonicsTwoList([]);
    setHarmonicsThreeList([]);

    updateHarmonics(machineId_1!, "harmonicsOne", { type: "RESET" });
    updateHarmonics(machineId_1!, "harmonicsTwo", { type: "RESET" });
    updateHarmonics(machineId_1!, "harmonicsThree", { type: "RESET" });
  };

  return (
    <div className="waterfall-chart-container">
      <WaterfallChartTooltip />

      <Plot
        ref={plotRef}
        data={waterfallData}
        layout={{
          ...layout,
          width: containerWidth - (35 + 100),
          shapes: [
            ...harmonicsOneList,
            ...harmonicsTwoList,
            ...harmonicsThreeList,
            // ...waterfallShapes,
          ],
          xaxis: {
            ...layout.xaxis,
            title: {
              ...layout.xaxis.title,
              text: t("Frequency (Hz)"),
            },
          },
          yaxis: {
            ...layout.yaxis,
            ticktext: selectValuesWithEqualDistance(yChartTickLabels, 10),
            tickvals: selectValuesWithEqualDistance(yChartValues, 10),
            tickformat: "%Y-%m-%d",
          },
        }}
        style={plotStyles}
        config={plotConfig}
        useResizeHandler={true}
        onInitialized={onInitialized}
        onHover={(event: MouseEvent) => setChartHoverParams(event)}
        onUnhover={() => setChartHoverParams(undefined)}
        onRelayout={(ev: any) => setYaxisStartEndDate(ev)}
      />

      <InteractiveColorBar
        reset={resetColorBar}
        colorScale={colorScale.scale}
        segments={4}
        style={{ height: 520 }}
        min={minValue}
        value={waterfallData[0]?.zmax ?? rms}
        defaultValue={rms}
        onChange={onColorValuesChange}
      />

      <ReductionDialog
        {...{ hideDialog, toggleDialog: toggleReductionDialog }}
      />

      <WaterfallContextMenu
        root={plotRef?.current}
        harmonicsOne={harmonicsOne}
        harmonicsTwo={harmonicsTwo}
        dispatchHarmonicsOne={(action) =>
          updateHarmonics(machineId_1!, "harmonicsOne", action)
        }
        dispatchHarmonicsTwo={(action) =>
          updateHarmonics(machineId_1!, "harmonicsTwo", action)
        }
        clearHarmonics={clearHarmonics}
        setChartHoverParams={setChartHoverParams}
      />
    </div>
  );
};

export default WaterfallChart;
