import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TooltipHost,
  Dropdown,
  IObjectWithKey,
  IDropdownOption,
} from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";

import FilePreview from "../common/Preview/FilePreview";
import BaseDialog from "../common/Dialog";
import Table, { TableProps, Column } from "../common/Table";

import { v4 as uuidv4 } from "uuid";
import { formatFileSize } from "../../schema/Utils";
import { CreateFile, File } from "../FIles/models";
import { addBulkFiles, getFilesOfMachine } from "../FIles/api";
import { MachineDrawing } from "../Machines/models";
import { NoFilesToSelect } from "./NoFilesToSelect";
import AddDocumentDialog from "./AddDocumentDialog";
import { axiosInstance } from "../..";

import { useTranslation } from "react-i18next";

const dropdownStyles = { dropdown: { width: 55 } };

type GetColumnsOpts = {
  t: any;
  onRemove: (id: string) => void;
  items: ItemsSelected[];
  options: (
    items: ItemsSelected[],
    item: ItemsSelected
  ) => IDropdownOption<any>[];
  onChangeSelSortIndex: (
    ele: ItemsSelected,
    opt: IDropdownOption<any>,
    idx: number
  ) => void;
};

const getColumns = ({
  t,
  onRemove,
  items,
  options,
  onChangeSelSortIndex,
}: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: (file) => {
      // TODO: should we use Link from react-router-dom?
      return (
        <FilePreview
          axiosInstance={axiosInstance}
          file={{
            name: file.name,
            id: file.id,
          }}
        />
        // <Link underline href="" style={{ fontSize: 13 }}>
        //   {name}
        // </Link>
      );
    },
  },
  {
    key: "size",
    fieldName: "size",
    name: t("Size"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ sizeInBytes }) => {
      return (
        <span style={{ fontSize: 13, color: "currentColor" }}>
          {formatFileSize(sizeInBytes)}
        </span>
      );
    },
  },
  {
    fieldName: "actions",
    key: "actions",
    name: t("Actions"),
    minWidth: 100,
    onRender: (ele: ItemsSelected, idx) => (
      <div style={{ display: "flex" }} key={ele.id + "actions"}>
        <TooltipHost
          key={ele.id + "actions0"}
          content={t("Choose the sort index")}
          styles={{ root: { display: "flex" } }}
        >
          <Dropdown
            key={uuidv4()}
            ariaLabel={t("Required")}
            options={options(items, ele)}
            styles={dropdownStyles}
            defaultSelectedKey={ele.sortIndex}
            onChange={(ev, opt) => onChangeSelSortIndex(ele, opt, idx)}
          />
        </TooltipHost>
        {/* {onRemove && (
          <TooltipHost
            key={id + "actions1"}
            content={"Delete"}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onRemove(id)}
              style={iconStyle}
            />
          </TooltipHost>
        )} */}
        {/* {onSelected && (
          <TooltipHost
            key={id + "actions2"}
            content={"Select as Icon "}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName={idSelected === id ? "InfoSolid" : "Info"}
              onClick={() => onSelected(id)}
              style={iconStyle}
            />
          </TooltipHost>
        )} */}
      </div>
    ),
  },
];

export type AddDocumentBase = {
  description: string;
};

export type UploadedFile<T = AddDocumentBase> = AcceptedFile & T;

const dialogStyles = {
  root: { height: "75%" },
};

type AcceptedFile = {
  id: string;
  name: string;
  type: string;
  size: number;
  isValid: boolean;
  dateAdded: string;
  file: File;
};

type SelectFilesOfMachineDialogProps = {
  machineId: string;
  currentDrawings: MachineDrawing[];
  hidden: boolean;
  onClose: () => void;
  onSubmit: (drawings: MachineDrawing[]) => void;
};
type ItemsSelected = File & IObjectWithKey & { sortIndex?: number };

const SelectFilesDrawingsOfMachine = ({
  machineId,
  currentDrawings,
  hidden,
  onClose,
  onSubmit,
}: SelectFilesOfMachineDialogProps) => {
  const { t } = useTranslation();
  const [localFiles, setLocalFiles] = useState<ItemsSelected[]>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAddMoreFiles, setShowAddMoreFiles] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    refreshFiles(machineId, currentDrawings, setLocalFiles);
    setIsLoading(false);

    return;
  }, [currentDrawings, machineId]);

  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-files-dialog-machine-drawings",
        version: 1,
      },
      items: localFiles,
      hasSelection: false,
      columns: getColumns({
        t,
        onRemove: (fileId) => {
          setLocalFiles((prev) => [...prev.filter(({ id }) => id !== fileId)]);
        },
        items: localFiles,
        options: uniqueOptions,
        onChangeSelSortIndex(ele, opt, idx) {
          let newArr = localFiles.slice(0);
          newArr[idx] = {
            ...ele,
            sortIndex: opt.key !== "" ? (opt.key as number) : undefined,
          };

          newArr.forEach((ele, idx) =>
            (opt.key as number) < ele.sortIndex
              ? (newArr[idx] = {
                  ...ele,
                  sortIndex: undefined,
                })
              : console.log
          );

          setLocalFiles(newArr);
        },
      }),
      perPage: localFiles?.length,
      hidePerPage: true,
    }),
    [localFiles]
  );

  return (
    <>
      <BaseDialog
        hidden={hidden}
        styles={dialogStyles}
        dialogContentProps={{
          type: DialogType.close,
          title: t("Select Drawings Files"),
          onDismiss: () => {
            onClose();
            setLocalFiles(undefined);
          },
        }}
      >
        {!localFiles ? (
          <Spinner size={SpinnerSize.large} />
        ) : localFiles?.length > 0 ? (
          <>
            <Table {...tableProps} />
            <DefaultButton
              text={t("Add more image files")}
              onClick={() => {
                setShowAddMoreFiles(true);
              }}
            />
            <DialogFooter>
              <PrimaryButton
                text={t("Assign Machine Drawings")}
                onClick={() => {
                  setIsSubmitting(true);
                  onSubmit(
                    localFiles
                      .filter((ele) =>
                        ele.sortIndex !== undefined ? true : false
                      )
                      .map((ele) => {
                        return {
                          fileId: ele.id,
                          sortIndex: ele.sortIndex,
                        };
                      })
                  );
                  setLocalFiles(undefined);
                  onClose();
                  setIsSubmitting(false);
                }}
                disabled={
                  localFiles.filter((ele) =>
                    ele.sortIndex !== undefined ? true : false
                  ).length === 0
                }
                onRenderIcon={() =>
                  isSubmitting ? <Spinner size={SpinnerSize.xSmall} /> : null
                }
              />
              <DefaultButton
                text={t("Cancel")}
                onClick={() => {
                  onClose();
                  setLocalFiles(undefined);
                }}
              />
            </DialogFooter>
          </>
        ) : (
          <NoFilesToSelect
            machineId={machineId}
            onClose={() => onClose()}
            onSubmit={() =>
              refreshFiles(machineId, currentDrawings, setLocalFiles)
            }
          />
        )}
      </BaseDialog>
      {showAddMoreFiles && (
        <AddDocumentDialog
          hidden={!showAddMoreFiles}
          onClose={() => setShowAddMoreFiles(false)}
          onSubmit={(data) => {
            const filesToCreate: CreateFile[] = data.map((ele) => {
              return {
                description: ele.description,
                file: ele.file,
                machineId: machineId,
                name: ele.name,
              };
            });
            addBulkFiles(filesToCreate)
              .then((resp) => console.log({ resp }))
              .finally(() =>
                refreshFiles(machineId, currentDrawings, setLocalFiles)
              );
          }}
          accept={".jpg,.jpeg,.png"}
        />
      )}
    </>
  );
};

const uniqueOptions = (
  selectedItems: ItemsSelected[],
  selectedItem: ItemsSelected
) => {
  const auxItems = selectedItems.filter((ele) =>
    ele.sortIndex !== undefined ? true : false
  );

  const aaa: IDropdownOption<any>[] = [{ key: "", text: "" }];
  auxItems.forEach((ele) =>
    aaa.push({
      key: ele.sortIndex,
      text: (ele.sortIndex + 1).toString(),
      disabled: true,
    })
  );
  selectedItem.sortIndex === undefined
    ? aaa.push({
        key: auxItems.length,
        text: (auxItems.length + 1).toString(),
      })
    : aaa.push({
        key: auxItems.length,
        text: (auxItems.length + 1).toString(),
        disabled: true,
      });
  return aaa;
};

export default SelectFilesDrawingsOfMachine;

function refreshFiles(
  machineId: string,
  currentDrawings: MachineDrawing[],
  setLocalFiles: React.Dispatch<React.SetStateAction<ItemsSelected[]>>
) {
  getFilesOfMachine(machineId).then((resp: File[]) => {
    const aux: ItemsSelected[] = [];
    resp
      .filter((ele) => ele.type.split("/").at(0) === "image")
      .forEach((ele) =>
        currentDrawings.some((dra) => ele.id === dra.fileId)
          ? aux.push({
              ...ele,
              key: ele.id,
              sortIndex: currentDrawings.find((draw) => draw.fileId === ele.id)
                .sortIndex,
            })
          : aux.push({ ...ele, key: ele.id })
      );
    setLocalFiles(
      aux
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .sort((a, b) => {
          if (a.sortIndex === undefined) {
            return 1;
          }
          if (b.sortIndex === undefined) {
            return -1;
          }
          if (a.sortIndex === b.sortIndex) {
            return 0;
          }
          return a.sortIndex.toString() < b.sortIndex.toString() ? -1 : 1;
        })
    );
  });
}
