import { useContext, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import { Icon, TooltipHost, Text } from "@fluentui/react";
import { ProjectToList } from "./models";
import { Link } from "react-router-dom";

import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../common/CommandBar";
import { notification } from "../common/Notification";
import Table, { Column, useTableFilters } from "../common/Table";

import {
  listAsyncProjs,
  selectProjects,
  selectProjectsError,
  selectProjectsStatus,
} from "./reducer";
import { DeleteConfirm } from "./DeleteConfirm";
import { AddOrEditDialog } from "./AddOrEditDialog";
import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import {
  listAsyncCompanies,
  selectCompanies,
  selectCompaniesStatus,
} from "../Companies/reducer";
import { Company } from "../Companies/models";
import { authContext } from "../LeftMenuAlt/LeftMenuAlt";

import { useTranslation } from "react-i18next";

// --- Styles ---

// --- Columns ---

type GetColumnsOpts = {
  t: any;
  hasActions: boolean;
  onEdit: (project: ProjectToList) => void;
  onDelete: (project: ProjectToList) => void;
};

const getColumns = ({
  t,
  hasActions,
  onEdit,
  onDelete,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 200,
      isSortable: true,
      onRender: ({ id, name }: ProjectToList) => (
        <Link to={id} style={linkStyle}>
          {name}
        </Link>
      ),
    },
    {
      key: "company",
      name: t("Company"),
      fieldName: "company",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "corporation",
      name: t("Corporation"),
      fieldName: "corporation",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "city",
      name: t("City"),
      fieldName: "city",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "country",
      name: t("Country"),
      fieldName: "country",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "continent",
      name: t("Continent"),
      fieldName: "continent",
      minWidth: 200,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (project: ProjectToList) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={0}
            content={t("Edit")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(project)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={1}
            content={t("Delete")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(project)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

export const ProjectsList: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const auth = useContext(authContext);
  const items = useAppSelector(selectProjects);
  const status = useAppSelector(selectProjectsStatus);
  const error = useAppSelector(selectProjectsError);
  const companiesStatus = useAppSelector(selectCompaniesStatus);
  const compas = useAppSelector(selectCompanies);
  const [itemsWithParents, setItemsWithParents] = useState<ProjectToList[]>([]);

  useEffect(() => {
    setItemsWithParents(
      items.map((item) => {
        return {
          id: item.id,
          name: item.name,
          companyId: item.companyId,
          city: item.city,
          company: item.company?.name,
          continent: item.continent,
          corporation: item.corporation?.name,
          country: item.country,
          latitude: item.latitude,
          longitude: item.longitude,
        };
      })
    );
    return () => {};
  }, [items]);

  useEffect(() => {
    dispatch(listAsyncProjs());
  }, [dispatch]);

  useEffect(() => {
    if (companiesStatus === Status.void) dispatch(listAsyncCompanies());
  }, [companiesStatus, dispatch]);

  useEffect(() => {
    if (status === Status.error) notification.error(error);

    return () => {};
  }, [error, status]);

  const hasWritePermission = auth.metaDataContributor;

  const { filters, handleSearch } = useTableFilters<ProjectToList>({
    keys: ["name", "corporation", "company", "country", "city", "continent"],
  });

  const [selected, setSelected] = useState<{
    data: ProjectToList | null;
    context: "add" | "edit" | "delete";
  } | null>(null);

  const onAdd = () => setSelected({ data: null, context: "add" });

  const onEdit = (project: ProjectToList) =>
    setSelected({ data: project, context: "edit" });

  const onDelete = (project: ProjectToList) =>
    setSelected({ data: project, context: "delete" });

  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{t("Project")}</Text>,
    },
    ...(hasWritePermission
      ? [
          {
            key: "add",
            text: t("Add"),
            type: CommandBarItemType.Button,
            iconProps: { iconName: "Add" },
            onClick: onAdd,
          },
        ]
      : []),
  ];

  return (
    <div style={pageStyle}>
      <BaseCommandBar
        items={computeCommandBarItems(commandBarItems)}
        onSearch={handleSearch}
        styles={commandBarStyles}
      />

      <Table
        persistOpts={{
          key: "table-projects",
          version: 2,
        }}
        header={{
          title: t("Projects"),
        }}
        items={itemsWithParents}
        columns={getColumns({
          t,
          hasActions: hasWritePermission,
          onEdit,
          onDelete,
        })}
        filters={filters}
        hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
      />

      <DeleteConfirm
        data={selected?.data}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(
              t(`Failed deleting {{projectName}} project`, {
                projectName: selected?.data?.name,
              })
            );
          } else {
            dispatch(listAsyncProjs());
            notification.success(
              t(`{{projectName}} project deleted successfully`, {
                projectName: selected?.data?.name,
              })
            );
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />
      {["add", "edit"].includes(selected?.context) && (
        <AddOrEditDialog
          options={compas.map((comp: Company) => ({
            key: comp.id,
            text: comp.name,
          }))}
          data={selected?.data}
          items={itemsWithParents}
          show={["add", "edit"].includes(selected?.context)}
          onSuccess={(hasError, data, context) => {
            if (hasError) {
              const message =
                context === "add"
                  ? t(`Failed creating {{entity}}`, { entity: data.name })
                  : t(`Failed updating {{entity}}`, { entity: data.name });

              notification.error(message);
            } else {
              dispatch(listAsyncProjs());

              const message =
                context === "add"
                  ? t(`{{entity}} created successfully`, { entity: data.name })
                  : t(`{{entity}} updated successfully`, { entity: data.name });

              notification.success(message);
            }
          }}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}
    </div>
  );
};
