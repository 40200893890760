import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../../schema/status";
import { RootState } from "../../../store";
import { fetchData } from "./api";
import { ResponseWirelessSensorNodeDetails } from "../models";
import { ResponseSimplifiedSignal } from "../../Machines/models";
import { t } from "i18next";

export const listAsyncSensorNodeDetails = createAsyncThunk(
  "sensorNodeDetail",
  async (id: string) => {
    const response = await fetchData(id);
    return response;
  }
);

interface toSortbyKey {
  sort: boolean;
  key: string;
}

export interface SensorNodeDetailsState {
  SensorNodeDet: ResponseWirelessSensorNodeDetails;
  status: Status;
  error: string;
}

const initialState: SensorNodeDetailsState = {
  SensorNodeDet: {
    sensorNodeId: "",
    deviceClass: 0,
    deviceModel: 0,
    id: "",
    machineId: "",
    signals: [],
    wirelessGatewayId: "",
    wirelessGateway: {
      adminPassword: "",
      connectionString: "",
      id: "",
      ltePlanSize: 0,
      projectId: "",
      serialNumber: "",
      userPassword: "",
      publicKey: "",
      rsshPort: 0,
    },
    isActive: true,
  },
  status: Status.loading,
  error: "",
};

export const wirelessSensorNodeDetailsSlice = createSlice({
  name: "wirelessSensorNodeDetails",
  initialState,
  reducers: {
    sortValues: (
      state: SensorNodeDetailsState,
      action: PayloadAction<toSortbyKey>
    ) => {
      const key = action.payload.key as keyof ResponseSimplifiedSignal;
      state.SensorNodeDet.signals !== undefined
        ? (state.SensorNodeDet.signals = state.SensorNodeDet.signals
            .slice(0)
            .sort((a: ResponseSimplifiedSignal, b: ResponseSimplifiedSignal) =>
              (!a[key] || a[key] === "") && (!b[key] || b[key] === "")
                ? 0
                : !a[key] || a[key] === ""
                  ? 1
                  : !b[key] || b[key] === ""
                    ? -1
                    : (
                          action.payload.sort
                            ? a[key]!.toString().toLowerCase() <
                              b[key]!.toString().toLowerCase()
                            : a[key]!.toString().toLowerCase() >
                              b[key]!.toString().toLowerCase()
                        )
                      ? 1
                      : -1
            ))
        : (state.SensorNodeDet.signals = []);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncSensorNodeDetails.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncSensorNodeDetails.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.SensorNodeDet = action.payload;
      })
      .addCase(listAsyncSensorNodeDetails.rejected, (state) => {
        state.status = Status.error;
        state.error = t("Error loading corporations. Please try again later.");
      });
  },
});

export const selectSignPerNode = (state: RootState) =>
  state.wirelessSensorNodeDetails.SensorNodeDet.signals;

export const selectSensorNodeDetails = (state: RootState) =>
  state.wirelessSensorNodeDetails.SensorNodeDet;

export const selectSensorNodeDetailsStatus = (state: RootState) =>
  state.wirelessSensorNodeDetails.status;

export const selectSensorNodeDetailsError = (state: RootState) =>
  state.wirelessSensorNodeDetails.error;

export const { sortValues } = wirelessSensorNodeDetailsSlice.actions;
export default wirelessSensorNodeDetailsSlice.reducer;
