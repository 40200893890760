import type { ISpinButtonStyles } from "@fluentui/react";
import { Label, SpinButton, Stack } from "@fluentui/react";
import type { FormEvent, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

const schemaNumber = z.number().int().nonnegative();
const schemaFloat = z.number().nonnegative();

const validateNumber = (
  value: string,
  type: "float" | "int"
): number | undefined => {
  const result =
    type === "float"
      ? schemaFloat.safeParse(parseFloat(value))
      : schemaNumber.safeParse(parseInt(value, 10));

  return result.success ? parseFloat(result.data.toFixed(4)) : undefined;
};

const handleValidate = (value: string, type: "float" | "int"): string => {
  const result = validateNumber(value, type);
  return result !== undefined ? result.toString() : "";
};

const handleChange = (
  value: string,
  type: "float" | "int",
  action: (value: number) => void
) => {
  const result = validateNumber(value, type);
  if (result !== undefined) {
    action(result);
  }
};

const handleInput = (
  event: FormEvent<HTMLDivElement> | SyntheticEvent<HTMLElement, Event>,
  type: "float" | "int",
  action: (value: number) => void
) => {
  const result = validateNumber((event.target as HTMLInputElement).value, type);
  if (result !== undefined) {
    action(result);
  }
};

const spinButtonStyles: Partial<ISpinButtonStyles> = {
  spinButtonWrapper: { marginLeft: "auto" },
  labelWrapper: { minWidth: 240 },
  label: { fontWeight: 400 },
};

export default function CustomFrequencies({ inputs }: any) {
  const { t } = useTranslation();

  return (
    <Stack style={{ rowGap: 16 }}>
      <Stack style={{ rowGap: 8 }}>
        <Label styles={{ root: { padding: 0, fontWeight: 600 } }}>
          {t("Set 1st Harmonics")}
        </Label>

        {inputs.harmonicsOne.map(({ label, value, type, action }: any) => {
          return (
            <Stack key={`harmonics1-${label}`} horizontal style={{ rowGap: 8 }}>
              <SpinButton
                value={
                  type === "float"
                    ? parseFloat(value.toFixed(1)).toString()
                    : value.toString()
                }
                min={0}
                styles={spinButtonStyles}
                step={type === "float" ? 0.1 : 1}
                label={label}
                onValidate={(value) => handleValidate(value, type)}
                onInput={(event) => handleInput(event, type, action)}
                onChange={(event, newValue) =>
                  handleChange(newValue || "0", type, action)
                }
              />
            </Stack>
          );
        })}
      </Stack>

      <hr style={{ background: "#e1dfdd", border: "none", height: "1px" }} />

      <Stack style={{ rowGap: 8 }}>
        <Label styles={{ root: { padding: 0, fontWeight: 600 } }}>
          {t("Set 2nd Harmonics")}
        </Label>

        {inputs.harmonicsTwo.map(({ label, value, type, action }: any) => (
          <Stack key={`harmonics2-${label}`} horizontal style={{ rowGap: 8 }}>
            <SpinButton
              value={
                type === "float"
                  ? parseFloat(value.toFixed(1)).toString()
                  : value.toString()
              }
              min={0}
              step={type === "float" ? 0.1 : 1}
              label={label}
              styles={spinButtonStyles}
              onValidate={(value) => handleValidate(value, type)}
              onInput={(event) => handleInput(event, type, action)}
              onChange={(_, newValue) =>
                handleChange(newValue || "0", type, action)
              }
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
