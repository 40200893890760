import { isEmpty } from "lodash-es";
import { useEffect } from "react";

import { usePrevious, useLocationSearch } from "../../../Hooks";

import { ErrorDialog } from "../../../modules/analysis-trend-view/components/TrendViewControls/ErrorDialog";
import { MAX_GROUP_THRESHOLD } from "../../../modules/analysis-trend-view/constants/controlOptions";
import Header from "./components/LoadAndPerformance/Header";
import Statistics from "./components/Statistics";
import { usePerformanceRequest } from "./hooks/usePerformanceRequest";
import usePerformanceStore, {
  performanceStoreInitialValues,
} from "./hooks/usePerformanceStore";
import { useTrendSignalsList } from "../MachineCVSummaryPage/hooks/useTrendSignalsList";
import { NoData } from "../../common/NoData";
import { Spin } from "../../common/Spin";

import LoadAndPerformance from "./components/LoadAndPerformance";
import { useTranslation } from "react-i18next";

const Page = () => {
  const { t } = useTranslation();
  const [{ id }, ,]: any = useLocationSearch();
  const { signals, isLoading } = useTrendSignalsList(id as string);
  const { data: performanceData, isLoading: performanceIsLoading }: any =
    usePerformanceRequest(id);

  const { isWarningModalOpen, updateStore } = usePerformanceStore(
    (store: any) => ({
      isWarningModalOpen: store.isWarningModalOpen,
      updateStore: store.updateStore,
    })
  );
  const errorMessage = t(
    `You cannot have more than <b>{{MAX_GROUP_THRESHOLD}} signals selected</b> in <b>Grouped Signals mode</b>. Please remove\nsignals until there are <b>maximum {{MAX_GROUP_THRESHOLD}}</b>.`,
    { MAX_GROUP_THRESHOLD }
  );

  const handleDismiss = () => updateStore({ isWarningModalOpen: false });

  const loading = isLoading || performanceIsLoading;

  const isPerformanceAvailable =
    performanceData.calculatePerformance && performanceData.perfSignalId;

  const noData =
    !loading &&
    (isEmpty(performanceData) ||
      !isPerformanceAvailable ||
      signals.length === 0);

  const showContent =
    !loading &&
    performanceData.calculatePerformance &&
    performanceData.perfSignalId &&
    signals.length > 0;

  const prevId = usePrevious(id);
  // reset performance store in some cases
  useEffect(() => {
    if (noData) {
      updateStore({ ...performanceStoreInitialValues });
    }

    if (id !== prevId) {
      updateStore({ ...performanceStoreInitialValues });
    }

    return () => {
      updateStore({ ...performanceStoreInitialValues });
    };
  }, [noData, id]);

  return (
    <>
      <div className="machine-cv-performance">
        {isLoading && <Spin style={{ height: "calc(100vh - 120px)" }} />}
        {noData && (
          <NoData
            style={{ marginTop: 20 }}
            text={t("Performance page is not available for this machine")}
          />
        )}

        {showContent && (
          <>
            <div className="machine-cv-performance__first-row">
              <Header />
              <LoadAndPerformance
                signals={signals}
                performance={performanceData}
              />
            </div>
            <div className="machine-cv-performance__second-row">
              <Statistics signals={signals} performance={performanceData} />
            </div>
          </>
        )}

        <ErrorDialog
          hidden={!isWarningModalOpen}
          content={errorMessage}
          onDismiss={handleDismiss}
        />
      </div>
    </>
  );
};

export default Page;
