export enum REPORT_CATEGORIES {
  Report = "Report",
  Alert = "Alert",
  Information = "Information",
}

export const getReportCategoryOptions = (t) => [
  {
    text: t("Report"),
    key: REPORT_CATEGORIES.Report,
  },
  {
    text: t("Alert"),
    key: REPORT_CATEGORIES.Alert,
  },
  {
    text: t("Information"),
    key: REPORT_CATEGORIES.Information,
  },
];
