import {
  DefaultButton,
  DialogFooter,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { format as fnsDateFormat } from "date-fns";
import type { CSSProperties, PropsWithChildren } from "react";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { z } from "zod";

import { format, ISOStringToDate } from "../../../../../../utils";

import {
  useWebAnalysisPermissions,
  getUsersOptions,
  useMachineUsersQuery,
  useLocationSearch,
} from "../../../../../../Hooks";
import { FORMAT } from "../../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import { notification } from "../../../../../common/Notification";
import { getPriorityOptions, getStatusOptions } from "../../TaskAdd";
import { useTask } from "../../../hooks/useTask";
import { useTaskUpdate } from "../../../hooks/useTaskMutations";
import useTasksStore from "../../../hooks/useTasksStore";
import type { Task } from "../../../../../../types";
import { renderFormItems, useZodForm } from "../../../../../common/Form";
import type { FormItemProps } from "../../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../../common/Form/FormItems/helpers";

import SectionTitle from "../../SectionTitle";

import { useTranslation } from "react-i18next";

const linkStyle: CSSProperties = {
  color: "#0078d4",
  cursor: "pointer",
  textDecoration: "underline",
};

const getInitialValues = (task: Task) => ({
  assignedTo: task?.assignedTo,
  assignedBy: task?.assignedBy,
  ...(task?.startDate ? { startDate: ISOStringToDate(task?.startDate) } : {}),
  ...(task?.dueDate ? { dueDate: ISOStringToDate(task?.dueDate) } : {}),
  priority: task?.priority,
  status: task?.status,
});

type TaskDetailsProps = {
  onClose: () => void;
};

export const TaskDetails = (props: PropsWithChildren<TaskDetailsProps>) => {
  const { t } = useTranslation();
  const [{ id }] = useLocationSearch();
  const { onClose, children } = props;
  const [isLoading, setIsLoading] = useState(false);

  const { edit, dispatch } = useTasksStore((state) => ({
    edit: state.edit,
    dispatch: state.dispatch,
  }));

  const { task, getTaskDetails } = useTask({ taskId: edit.id });

  const schema = z
    .object({
      assignedTo: z.string(),
      assignedBy: z.string(),
      startDate: z.date(),
      dueDate: z.date(),
      priority: z.string(),
      status: z.string(),
    })
    .refine(
      (input) => {
        if (!input.startDate || !input.dueDate) {
          return true;
        }
        return input.startDate <= input.dueDate;
      },
      {
        path: ["startDate"],
        message: t("The start date must be earlier than the end date."),
      }
    );

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useZodForm({
    defaultValues: getInitialValues(task),
    schema,
  });

  const { updateTaskAsync } = useTaskUpdate();
  const { usersList } = useMachineUsersQuery({ machineId: id as string });
  const [seeMore, setSeeMore] = useState(false);

  const { useReadyPermissionGeneral, checkCreatorUserIDMatch } =
    useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermissionGeneral({
    permissionRoles: ["MachineCvContributor", "MachineCvAdministrator"],
  });

  const hasSavePermission =
    hasMachineCvAdministratorPermission ||
    checkCreatorUserIDMatch(task?.createdBy);

  const taskDescriptionFields: FormItemProps[] = useMemo(
    () => [
      {
        name: "assignedTo",
        type: FormItemType.Dropdown,
        groupProps: { label: t("Assigned to") },
        options: getUsersOptions(usersList),
      },
      {
        name: "assignedBy",
        type: FormItemType.Dropdown,
        disabled: true,
        groupProps: { label: t("Assigned by") },
        options: getUsersOptions(usersList),
      },
      {
        name: "startDate",
        type: FormItemType.DatePicker,
        groupProps: { label: t("Start Date") },
        formatDate: (date) => format(date ?? new Date()),
      },
      {
        name: "dueDate",
        type: FormItemType.DatePicker,
        groupProps: { label: t("Due Date") },
        formatDate: (date) => format(date ?? new Date()),
      },
      {
        name: "priority",
        type: FormItemType.Dropdown,
        groupProps: { label: t("Priority") },
        options: getPriorityOptions(t),
      },
      {
        name: "status",
        type: FormItemType.Dropdown,
        groupProps: { label: t("Status") },
        options: getStatusOptions(t),
      },
    ],
    [t, usersList]
  );

  const formDeps = useMemo(
    () => taskDescriptionFields.map((field) => field.name),
    [taskDescriptionFields]
  );

  useEffect(() => {
    reset(getInitialValues(task));
  }, [...formDeps.map((dep) => task?.[dep])]);

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    const submitData = {
      ...data,
      dueDate: fnsDateFormat(data.dueDate, FORMAT) as unknown as Date,
      startDate: fnsDateFormat(data.startDate, FORMAT) as unknown as Date,
    };

    await updateTaskAsync({
      id: edit.id || "",
      task: { ...task, ...submitData },
    })
      .then(() => {
        notification.success(t("Task successfully updated!"));
        dispatch({ type: "SET_RELOAD" });
        getTaskDetails();
        onClose();
      })
      .catch(() => notification.error(t("Error updating task")))
      .finally(() => setIsLoading(false));
  });

  return (
    <form
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
      onSubmit={onSubmit}
    >
      <div className="taskDescription">
        <div>
          <SectionTitle>{t("Details")}</SectionTitle>

          {task?.description && (
            <>
              <Label className="title">{t("Task description")}</Label>
              <div className="description">
                <span style={{ whiteSpace: "pre-wrap" }}>
                  {seeMore
                    ? `${task?.description} `
                    : `${task?.description.slice(0, 100)}${
                        task?.description.length > 100 ? "..." : ""
                      } `}
                </span>
                {task?.description.length > 100 && (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <span style={linkStyle} onClick={() => setSeeMore(!seeMore)}>
                    {seeMore ? t("Show less") : t("Show more")}
                  </span>
                )}
              </div>
            </>
          )}
          {renderFormItems(taskDescriptionFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
        </div>

        {children}
      </div>

      <DialogFooter>
        {hasSavePermission ? (
          <PrimaryButton
            type="submit"
            text={t("Update Task")}
            disabled={isLoading}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
        ) : null}
        <DefaultButton
          styles={{
            root: { border: "unset", background: "transparent" },
          }}
          text={t("Close")}
          onClick={onClose}
        />
      </DialogFooter>
    </form>
  );
};
