import { AxiosResponse } from "axios";
import { ResponseSimplifiedSignal } from "../../Machines/models";
import { axiosInstance } from "../../..";
import { t } from "i18next";

export class GenerateConfigsAPI {
  /**
   * Gets the raw signals
   * @param machineId The machine Id
   * @returns The raw signals list.
   */
  static getRawSignals = async (
    machineId: string
  ): Promise<AxiosResponse<ResponseSimplifiedSignal[]>> => {
    let result: AxiosResponse<ResponseSimplifiedSignal[]> = {
      status: 0,
      statusText: "",
      data: [],
      headers: {},
      config: {},
    };

    try {
      let response = await axiosInstance.get<ResponseSimplifiedSignal[]>(
        `/meta/read/v1/machines/${machineId}/signals`
      );

      result = { ...response };

      // Filters the result
      if (response.status === 200) {
        result.data = response.data.filter(
          (signal) => signal.dataType?.trim().toLowerCase() === "shortterm"
        );
      }
    } catch (e: any) {
      result.statusText = e.message || t("Unknown error");
    }

    return result;
  };
}
