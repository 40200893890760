import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";
import { listCompanies } from "./api";
import { Company } from "./models";
import { t } from "i18next";

export interface CompaniesState {
  companies: Company[];
  status: Status;
  error: string;
}

const initialState: CompaniesState = {
  companies: [],
  status: Status.void,
  error: "",
};

export const listAsyncCompanies = createAsyncThunk(
  "companies/list",
  async () => {
    const response = await listCompanies();
    if (response.length > 0)
      return response.sort((a, b) => (a.name > b.name ? 1 : -1));
    return response;
  }
);

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncCompanies.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncCompanies.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.companies = action.payload;
      })
      .addCase(listAsyncCompanies.rejected, (state) => {
        state.status = Status.error;
        state.error = t("Error loading companies. Please try again later.");
      });
  },
});

export const selectCompanies = (state: RootState) => state.companies.companies;

export const selectCompaniesStatus = (state: RootState) =>
  state.companies.status;

export const selectCompaniesError = (state: RootState) => state.companies.error;

export const selectCompany = (id: string) => (state: RootState) => {
  if (
    state.companies &&
    state.companies.companies &&
    state.companies.companies.length > 0
  ) {
    return state.companies.companies.find((c: Company) => c.id === id);
  }

  return undefined;
};

export default companiesSlice.reducer;
