import { useTranslation } from "react-i18next";
import BaseCommandBar, {
  computeCommandBarItems,
} from "../../../common/CommandBar";
import { getColumnsImages, getCommandBarItems } from "../columnsHelper";
import { useAppDispatch, useAppSelector } from "../../../../Hooks";
import {
  listAsyncMachDetails,
  selectMachDetails,
  selectMachineDetailsStatus,
} from "../reducer";
import { authContext } from "../../../LeftMenuAlt/LeftMenuAlt";
import { useContext, useState } from "react";

import { convertToZipFileName } from "../../../../schema/Utils";
import Table, { useTableFilters } from "../../../common/Table";
import { commandBarStyles } from "../../../../schema/Constants";
import { Status } from "../../../../schema/status";
import { useParams } from "react-router-dom";
import { Image } from "../../models";
import saveAs from "file-saver";
import { downloadImage } from "../Images/api";

export default function ImagesContent({ setSelected, setActionImages }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});

  const auth = useContext(authContext);
  const status = useAppSelector(selectMachineDetailsStatus);
  const dispatch = useAppDispatch();
  const items = useAppSelector(selectMachDetails);

  const imagesFilter = useTableFilters<Image>({
    keys: [
      "fileName",
      "md5Hash",
      "size",
      "dataUntil",
      "dataFrom",
      "postProcessedTime",
      "creationTime",
    ],
  });

  const handleDownloadClick = (image: Image) => {
    if (!loadingStates[image.id]) {
      setLoadingStates((prevState) => ({
        ...prevState,
        [image.id]: true,
      }));

      // setSelectedItems((prevState) => [...prevState, image]);

      downloadImage(image.id).then((resp) => {
        saveAs(
          new Blob([resp], { type: "application/zip" }),
          convertToZipFileName(image.fileName)
        );
        setLoadingStates((prevState) => ({
          ...prevState,
          [image.id]: false,
        }));
      });
    }
  };

  return (
    <>
      <BaseCommandBar
        items={computeCommandBarItems(
          getCommandBarItems({
            t,
            title: t("Images"),
            onChange(files) {
              setSelected({
                context: "images",
                file: files.at(0),
              });
            },
            imagesExts: true,
            importButton: auth.imageContributor,
            onRefresh: () => dispatch(listAsyncMachDetails(id ? id : "")),
            onQueue() {
              setActionImages({
                data: undefined,
                context: "queue",
              });
            },
          })
        )}
        onSearch={imagesFilter.handleSearch}
        styles={commandBarStyles}
      />
      <Table
        persistOpts={{
          key: "table-images",
          version: 2,
        }}
        header={{
          title: t("Images"),
        }}
        items={items?.images}
        columns={getColumnsImages({
          t,
          handleDownloadClick,
          loadingStates,
        })}
        filters={imagesFilter.filters}
        hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
        //perPage={bigScreen ? 20 : 10}
      />
    </>
  );
}
