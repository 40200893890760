import {
  DefaultButton,
  DialogFooter,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import React from "react";

import { notification } from "../../../common/Notification";
import BaseDialog, { DialogSize } from "../../../common/Dialog";

import SensorNodeSelector from "./SensorNodeSelector";
import type { ProjectSelectable, SensorNodeSelectable } from "./types";

import { useTranslation } from "react-i18next";

interface SensorSelectDialogProps {
  isVisible: boolean;
  onDismiss: () => void;
  onSave: (selectedSensors: string[]) => void;
  options: ProjectSelectable;
  isLoading?: boolean;
  title?: string;
  saveButtonText?: string;
  sensorNodeId?: string;
}

const SensorSelectDialog: React.FC<SensorSelectDialogProps> = ({
  isVisible,
  onDismiss,
  onSave,
  options: projectData,
  isLoading = false,
  saveButtonText,
  sensorNodeId,
}) => {
  const { t } = useTranslation();
  const [selectedNodes, setSelectedNodes] = React.useState<string[]>([]);
  const handleSave = () => {
    if (selectedNodes.length === 0) {
      notification.error(t("Please select at least one sensor node"));
      return;
    } else {
      onSave(selectedNodes);
    }
  };

  const handleSelectionChange = (selectedNodes: SensorNodeSelectable[]) => {
    setSelectedNodes(selectedNodes.map((node) => node.sensorNodeId));
  };

  const handleDialogClose = () => {
    onDismiss();
    setSelectedNodes([]);
  };

  return (
    <BaseDialog
      hidden={!isVisible}
      size={DialogSize.M}
      styles={{
        main: {
          minHeight: "auto",
          selectors: {
            ".ms-Dialog-title": {
              paddingTop: 18,
              paddingBottom: 10,
            },
          },
        },
      }}
      dialogContentProps={{
        title: t("Sensor: {{sensorNodeId}}", { sensorNodeId }),
      }}
      onDismiss={handleDialogClose}
    >
      <SensorNodeSelector
        project={projectData}
        onSelectionChange={handleSelectionChange}
      />
      <DialogFooter>
        <PrimaryButton
          text={saveButtonText || t("Save to sensors")}
          onClick={handleSave}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton text={t("Close")} onClick={handleDialogClose} />
      </DialogFooter>
    </BaseDialog>
  );
};

export default SensorSelectDialog;
