import {
  DetailsRow,
  GroupedList,
  GroupHeader,
  IColumn,
  Icon,
  IGroupDividerProps,
  SelectionMode,
  TooltipHost,
} from "@fluentui/react";
import React from "react";
import { FrequencyCatalogueGroupsAndItemsResponse } from "./models";
import { iconStyle } from "../../../../schema/Constants";
import { useTranslation } from "react-i18next";

const onRenderHeader =
  (
    t,
    onAddItem: (groupName: string) => void,
    onDeleteGroup: (groupName: string) => void,
    onEditGroup: (groupName: string) => void
  ) =>
  (props?: IGroupDividerProps): JSX.Element | null => {
    if (props) {
      return (
        <GroupHeader
          {...props}
          onRenderTitle={(groupProps) => {
            const group = groupProps?.group as any;
            return (
              <>
                {groupProps ? (
                  <>
                    <div
                      style={{ display: "flex" }}
                      key={groupProps.group.name + "actions"}
                    >
                      {groupProps.group.name + " (" + group.description + ")"}
                      <TooltipHost
                        key={groupProps.group.name + "actions"}
                        content={"Add"}
                        styles={{ root: { display: "flex" } }}
                      >
                        <Icon
                          iconName="Add"
                          onClick={() => onAddItem(groupProps.group.name)}
                          style={iconStyle}
                        />
                      </TooltipHost>
                      <TooltipHost
                        key={groupProps.group.name + "actions0"}
                        content={t("Edit")}
                        styles={{ root: { display: "flex" } }}
                      >
                        <Icon
                          iconName="Edit"
                          onClick={() => onEditGroup(groupProps.group.name)}
                          style={iconStyle}
                        />
                      </TooltipHost>
                      <TooltipHost
                        key={groupProps.group.name + "actions1"}
                        content={t("Delete")}
                        styles={{ root: { display: "flex" } }}
                      >
                        <Icon
                          iconName="Delete"
                          onClick={() => onDeleteGroup(groupProps.group.name)}
                          style={iconStyle}
                        />
                      </TooltipHost>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            );
          }}
          selectionMode={SelectionMode.none}
        />
      );
    }
    return null;
  };

interface FrequencyCatalogueGroupTableProps {
  groupsAndItems: FrequencyCatalogueGroupsAndItemsResponse;
  columns: IColumn[];
  onAddItem: (groupName: string) => void;
  onDeleteGroup: (groupName: string) => void;
  onEditGroup: (groupName: string) => void;
}

export const FrequencyCatalogueGroupTable: React.FC<
  FrequencyCatalogueGroupTableProps
> = ({ groupsAndItems, columns, onAddItem, onDeleteGroup, onEditGroup }) => {
  const { t } = useTranslation();

  const onRenderCell = (
    nestingDepth?: number,
    item?: any,
    itemIndex?: number
  ): JSX.Element => {
    return (
      <DetailsRow
        columns={columns}
        groupNestingDepth={nestingDepth}
        item={item}
        itemIndex={itemIndex || 0}
        selectionMode={SelectionMode.none}
      />
    );
  };

  return (
    <GroupedList
      items={groupsAndItems.items}
      onRenderCell={onRenderCell}
      groups={groupsAndItems.groups.map((group) => {
        return {
          ...group,
          key: group.id,
        };
      })}
      groupProps={{
        onRenderHeader: onRenderHeader(
          t,
          onAddItem,
          onDeleteGroup,
          onEditGroup
        ),
      }}
    />
  );
};

export default FrequencyCatalogueGroupTable;
