import { useEffect, useMemo, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import { torqueSignalsChartColors } from "../../../../MachineCVDriveLoadPage/components/TorqueChart/config";

import { useLocationSearch } from "../../../../../../Hooks";
import type { EntityIds } from "../../../../../../modules/analysis-trend-view/components/TrendViewGroupedPlot/config";
import {} from "../../../../MachineCVDriveLoadPage/components/TorqueChart/methods";
import usePerformanceStore from "../../../hooks/usePerformanceStore";
import type { XYChartRef } from "../../../../../common/XYChart";
import { getDateAxis, XYChart } from "../../../../../common/XYChart";

import { createSeriesOneYaxis, initializePerformanceChart } from "./methods";
import { useTranslation } from "react-i18next";

const PerformancePlot = ({ data, signals }: any) => {
  const { t } = useTranslation();
  const [{ id: machineId }, ,]: any = useLocationSearch();
  const { updateStore } = usePerformanceStore((store: any) => ({
    updateStore: store.updateStore,
  }));

  const ref = useRef<XYChartRef | null>(null);

  const legendRef = useRef<any>(null);

  const dataRef = useRef<any>([]);

  const entityIds = useMemo<EntityIds>(
    () => ({
      xAxis: `x-axis-load-performance-grouped-plot-${machineId}`,
      yAxis: `y-axis-load-performance-grouped-plot-${machineId}`,
      legend: `legend-load-performance-grouped-plot-${machineId}`,
      outOfSync: `out-of-sync-load-performance-grouped-plot-${machineId}`,
      noAverageTrend: `no-average-load-performance-grouped-plot-${machineId}`,
    }),
    []
  );

  useEffect(() => {
    if (!ref?.current) return;

    dataRef.current = [...data];

    initializePerformanceChart({
      ref,
      entityIds,
      updateStore,
      data,
      machineId,
      signals,
    });

    // === Legend ===
    const legendRoot = am5.Root.new(`legend-container-${machineId}`);
    const legendContainer = document.getElementById(
      `legend-container-${machineId}`
    );

    const legend = legendRoot.container.children.push(
      am5.Legend.new(legendRoot, {
        width: am5.percent(100),
        centerX: am5.percent(50),
        x: am5.percent(50),
      })
    );

    // === Set legend marker icon
    legend.events.on("datavalidated", () => {
      const markers = legend.markers.values;

      for (let i = 0; i < markers.length; i++) {
        const marker = markers[i];

        const icon = am5.Picture.new(legendRoot, {
          width: 16,
          height: 16,
          src: `${torqueSignalsChartColors[i].icon}`,
        });

        marker.children.setAll([icon]);
      }
    });

    // === Lower marker opacity when disabled
    legend.markers.template.setup = (marker) => {
      marker.states.create("disabled", {
        opacity: 0.5,
      });
    };

    // === Legend container height based on legend height
    legend.events.on("boundschanged", () => {
      if (legendContainer) {
        legendContainer.style.height = legend.height() + "px";
      }
    });

    // === Legend marker label font size
    legend.labels.template.setAll({
      fontSize: 12,
    });

    // === Remove default legend marker
    legend.markerRectangles.template.setAll({
      visible: false,
      width: 0,
      height: 0,
    });

    legend.data.setAll(ref.current.chart.series.values);
    legendRef.current = legend;

    return () => {
      legendRoot.dispose();
      ref.current.root.dispose();
      ref.current = null;
    };
  }, []);

  useEffect(() => {
    if (!ref?.current) return;

    if (
      ref.current.chart.series.length === signals.length &&
      (dataRef.current || []).flat().length === (data || []).flat().length
    ) {
      return;
    }

    dataRef.current = [...data];

    // Create current series
    createSeriesOneYaxis({
      ref,
      xAxis: getDateAxis(entityIds.xAxis),
      yAxis: getDateAxis(entityIds.yAxis || ""),
      data,
      signals,
    });

    // Update legend
    legendRef?.current?.data.setAll(ref.current.chart.series.values);
  }, [signals.length, data?.flat()?.length]);

  // Translate legend
  useEffect(() => {
    if (!legendRef.current && signals.length === 0) return;

    legendRef?.current?.data?.values.forEach((item, index) => {
      const translatedName = t(
        signals[index].signalDescription ||
          signals[index].name ||
          "No signal name"
      );

      item.set("name", translatedName);
    });
  }, [signals, t]);

  return (
    <div className="grouped-chart-container">
      <XYChart
        ref={ref}
        exportSelector=""
        styles={{ chart: { height: "calc(50vh - 150px)", minHeight: 350 } }}
      />
      <div
        ref={legendRef}
        id={`legend-container-${machineId}`}
        className="legend-container"
      />
    </div>
  );
};

export default PerformancePlot;
