/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useEffect, useContext } from "react";

import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../../../common/Form";

import { IComboBoxOption, IDialogProps, Stack } from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { maxLengthType1 } from "../../../../schema/Constants";
import { RequestWirelessGatewayUpdateAux } from "../../models";
import FormItemRow from "../../../Generic/FormItemRow";
import ControlledComboBox from "../../../Generic/ControlledComboBox";
import { SupportContext } from "../SupportContext";
import { maxRsshPort, minRsshPort } from "../../../Gateways/AddDialog";

import { useTranslation } from "react-i18next";

const getSchema = (t) =>
  z
    .object({
      id: z.string().optional(),
      projectId: z.string().optional(),
      ltePlanSize: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      publicKey: z.string().optional(),
      rsshPort: z.string().optional(),
      wifiPasswordForAP: z.string().optional(),
      wifiSSID: z.string().optional(),
      iMEI: z.string().optional(),
      wirepassSinkNodeAddress: z.string().optional(),
      wirepassChannel: z.string().optional(),
      ethernetMacAddress: z.string().optional(),
      firmware: z.string().optional(),
    })
    .refine(
      (input) => {
        if (!input.rsshPort) {
          return true;
        }
        const rsshPortNumber = Number(input.rsshPort);
        return (
          Number.isInteger(rsshPortNumber) &&
          rsshPortNumber >= minRsshPort &&
          rsshPortNumber <= maxRsshPort
        );
      },
      {
        path: ["rsshPort"],
        message: t(
          `rsshPort must be an integer between {{minRsshPort}} and {{maxRsshPort}}`,
          { minRsshPort, maxRsshPort }
        ),
      }
    )
    .refine(
      (input) => {
        if (!input.ltePlanSize) {
          return true;
        }
        var regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.ltePlanSize);
      },
      {
        path: ["ltePlanSize"],
        message: t("Try a number between (1.0:1000.0)"),
      }
    );

const getGatewayFields: (t) => FormItemProps[] = (t) => [
  {
    name: "ltePlanSize",
    type: FormItemType.TextField,
    groupProps: { label: t("Lte Plan Size *") },
  },
  {
    name: "publicKey",
    type: FormItemType.TextField,
    groupProps: { label: t("Public Key") },
  },
  {
    name: "rsshPort",
    type: FormItemType.TextField,
    groupProps: { label: t("Rssh Port") },
  },
  {
    name: "wifiPasswordForAP",
    type: FormItemType.TextField,
    groupProps: { label: t("Wifi Password For AP") },
  },
  {
    name: "wifiSSID",
    type: FormItemType.TextField,
    groupProps: { label: t("Wifi SSID") },
  },
  {
    name: "iMEI",
    type: FormItemType.TextField,
    groupProps: { label: t("IMEI") },
  },
  {
    name: "wirepassSinkNodeAddress",
    type: FormItemType.TextField,
    groupProps: { label: t("Wirepass Sink Node Editress") },
  },
  {
    name: "wirepassChannel",
    type: FormItemType.TextField,
    groupProps: { label: t("Wirepass Channel") },
  },
  {
    name: "ethernetMacAddress",
    type: FormItemType.TextField,
    groupProps: { label: t("Ethernet Mac Editress") },
  },
  {
    name: "firmware",
    type: FormItemType.TextField,
    groupProps: { label: t("Firmware") },
  },
];

type EditDialogProps = IDialogProps & {
  options: IComboBoxOption[];
  serialNumbers: string[];
  serialNumber: string;
};

export const EditDialog = ({
  options,
  serialNumbers,
  serialNumber,
  ...rest
}: EditDialogProps) => {
  const { t } = useTranslation();
  const { support, updateSupportEle } = useContext(SupportContext);

  const toUpdate = support?.gatewaysSupportToUpdate.find(
    (gateway) => gateway.serialNumber === serialNumber
  );

  const schema = useMemo(() => getSchema(t), [t]);

  const {
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: {
      projectId: toUpdate?.projectId || "",
      id: toUpdate?.id || "",
      ltePlanSize: toUpdate?.ltePlanSize ? String(toUpdate.ltePlanSize) : "",
      publicKey: toUpdate?.publicKey || "",
      rsshPort: toUpdate?.rsshPort ? String(toUpdate.rsshPort) : "",
      wifiPasswordForAP: toUpdate?.wifiPasswordForAP || "",
      wifiSSID: toUpdate?.wifiSSID || "",
      iMEI: toUpdate?.iMEI || "",
      wirepassSinkNodeAddress: toUpdate?.wirepassSinkNodeAddress || "",
      wirepassChannel: toUpdate?.wirepassChannel || "",
      ethernetMacAddress: toUpdate?.ethernetMacAddress || "",
      firmware: toUpdate?.firmware || "",
    },
  });

  // Checks whether the entity has changed.
  useEffect(() => {
    if (!control) {
      return;
    }
    const toUp: RequestWirelessGatewayUpdateAux = {
      ...toUpdate,
      matchUniqueId: toUpdate?.matchUniqueId,
      projectId: toUpdate?.projectId,
      ...(control._formValues as RequestWirelessGatewayUpdateAux),
      ltePlanSize: control._formValues.ltePlanSize
        ? Number(control._formValues.ltePlanSize)
        : undefined,
      rsshPort: control._formValues.rsshPort
        ? Number(control._formValues.rsshPort)
        : undefined,
      isValid,
    };

    isValid && updateSupportEle(toUp);
  }, [watch(), isValid]);

  return (
    <Stack>
      {
        <>
          <FormItemRow label={t("Current Project")}>
            <ControlledComboBox
              options={options}
              selectedKey={toUpdate?.projectId}
              disabled={true}
              onKeySelected={(key: string) => console.log("key")}
            />
          </FormItemRow>
          {renderFormItems(getGatewayFields(t), {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
        </>
      }
    </Stack>
  );
};
