import { useTranslation } from "react-i18next";
import BaseCommandBar, {
  computeCommandBarItems,
} from "../../../common/CommandBar";
import { getColumnsSignalEvent, getCommandBarItems } from "../columnsHelper";
import { authContext } from "../../../LeftMenuAlt/LeftMenuAlt";
import { useContext } from "react";
import { machineExport } from "../../api";
import { convertCSVtoXLSX } from "../../../../schema/Utils";
import Table, { useTableFilters } from "../../../common/Table";
import { ResponseSimplifiedEvent } from "../../models";
import { useParams } from "react-router-dom";
import { commandBarStyles } from "../../../../schema/Constants";
import { useAppSelector } from "../../../../Hooks";
import { selectMachDetails, selectMachineDetailsStatus } from "../reducer";
import { Status } from "../../../../schema/status";

export default function EventsContent({ setSelected }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const auth = useContext(authContext);
  const items = useAppSelector(selectMachDetails);
  const status = useAppSelector(selectMachineDetailsStatus);

  const eventFilter = useTableFilters<ResponseSimplifiedEvent>({
    keys: ["dataFrom", "dataUntil", "configName", "source"],
  });

  return (
    <>
      <BaseCommandBar
        items={computeCommandBarItems(
          getCommandBarItems({
            t,
            title: t("Events"),
            onChange(files) {
              setSelected({ context: "events", file: files.at(0) });
            },
            importButton: auth.metaDataContributor,
            xlsxToo: true,
            addButton: auth.metaDataContributor,
            exportable: true,
            onExport: () => {
              machineExport(id, "events").then((resp) => {
                convertCSVtoXLSX(resp, "Events_" + id + ".xlsx");
              });
            },
          })
        )}
        onSearch={eventFilter.handleSearch}
        styles={commandBarStyles}
      />
      <Table
        persistOpts={{
          key: "table-events",
          version: 2,
        }}
        header={{
          title: t("Events"),
          isExportable: false,
        }}
        items={items?.events}
        columns={getColumnsSignalEvent(t)}
        filters={eventFilter.filters}
        hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
        //perPage={bigScreen ? 20 : 10}
      />
    </>
  );
}
