import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { fetchUserAccountDetails } from "./api";
import { t } from "i18next";

export const defaultUserAccountDetails = {
  memberId: "",
  displayName: "",
  email: "",
  id: "",
  lastName: "",
  firstName: "",
  jobTitle: "",
  companyName: "",
  department: "",
  streetAddress: "",
  city: "",
  stateOrProvince: "",
  zipOrPostalCode: "",
  countryOrRegion: "",
  mobilePhone: "",
  initialPassword: null,
  externalProvider: "",
  externalMemberId: "",
  memberType: "",
  purpose: null,
  lastTokenGenerated: "",
  inviteUrl: "",
  profilePicture: "",
};

export const getAsyncUserAccountDetails = createAsyncThunk(
  "userAccountDetails/get",
  async (id: string) => {
    let response = await fetchUserAccountDetails(id);

    const isEmptySettings = Object.keys(response || []).length === 0;

    if (isEmptySettings) {
      response = {
        ...response,
        defaultUserAccountDetails,
      };
    }

    return {
      ...defaultUserAccountDetails,
      ...response?.properties,
      profilePicture: response?.pictureBase64,
    };
  }
);

const initialState = {
  initialData: defaultUserAccountDetails,
  response: defaultUserAccountDetails,
  status: Status.void,
  error: "",
};

export const userAccountReducer = createSlice({
  name: "userAccount",
  initialState,
  reducers: {
    updateProfilePciture: (state, action) => {
      state.response.profilePicture = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAsyncUserAccountDetails.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(getAsyncUserAccountDetails.fulfilled, (state, action) => {
        if (action.payload === undefined) {
          state.status = Status.error;
          state.error = t(
            "Error loading settings list RBAC. Please try again later."
          );
        }

        if (action?.payload) {
          state.initialData = action?.payload;
          state.response = action?.payload;
        }
      })
      .addCase(getAsyncUserAccountDetails.rejected, (state) => {
        state.status = Status.error;
        state.error = t(
          "Error loading settings list RBAC. Please try again later."
        );
      });
  },
});

export const { updateProfilePciture } = userAccountReducer.actions;

export default userAccountReducer.reducer;
