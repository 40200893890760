import { IconButton, Stack, Text } from "@fluentui/react";
import type { CSSProperties, ReactElement } from "react";
import * as ReactRouterDOM from "react-router-dom";

import { useLocationSearch } from "../../../../../Hooks";
import type { Condition } from "../../../../../modules/machine-cv/constants";
import {
  conditionColor,
  getConditionFormat,
} from "../../../../../modules/machine-cv/constants";
import { useTranslation } from "react-i18next";

interface StatusBadgeProps {
  onAddTask: () => void;
  onAddDocument: () => void;
  data: {
    value: string | undefined;
    id: string | null;
  };
  style?: CSSProperties;
  customBadge?: ReactElement;
}

const StatusBadge = ({
  data,
  onAddTask,
  onAddDocument,
  customBadge,
}: StatusBadgeProps) => {
  const { t } = useTranslation();
  const navigate = ReactRouterDOM.useNavigate();

  const [{ search }] = useLocationSearch();

  const actionOptions = {
    items: [
      {
        key: "add_task",
        text: t("Add task"),
        onClick: onAddTask,
      },
      {
        key: "add_document",
        text: t("Upload document"),
        onClick: onAddDocument,
      },
      {
        key: "view_trend",
        text: t("View Trend"),
        onClick: () => navigate(`/trend-view${search}`),
      },
      {
        key: "raw_data",
        text: t("View Raw data"),
        onClick: () => navigate(`/raw-data${search}`),
      },
    ],
  };

  return (
    <Stack style={{ width: "100%" }}>
      <Stack key={data.id} horizontal>
        <Stack
          horizontal
          horizontalAlign="center"
          className={customBadge ? "custom-badge-container" : ""}
          style={{
            ...badgeContainerStyles,
            backgroundColor: conditionColor[data.value as Condition],
          }}
        >
          {customBadge || (
            <Text style={{ color: "#fff" }}>
              {getConditionFormat(t)[data.value as Condition]}
            </Text>
          )}
        </Stack>
        <IconButton
          iconProps={{
            iconName: "MoreVertical",
          }}
          styles={{ root: { height: 36, width: 40, borderRadius: 0 } }}
          menuProps={actionOptions}
          menuIconProps={{ style: { display: "none" } }}
          className="status-badge-button"
        />
      </Stack>
    </Stack>
  );
};

export const badgeContainerStyles = {
  padding: "4px 16px",
  minHeight: 36,
  minWidth: 36,
  display: "flex",
  alignItems: "center",
  width: "100%",
};

export default StatusBadge;
