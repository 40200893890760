import type { IDropdownOption } from "@fluentui/react";
import { useMemo } from "react";

import useThreadsStore from "../hooks/useThreadsStore";

import BaseCommandBar from "../../../common/CommandBar";
import type { CommandBarItemProps } from "../../../common/CommandBar/methods";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar/methods";
import { useTranslation } from "react-i18next";

type DocumentsCommandBarProps = {
  handleSearch?: (value: string) => void;
  onFilter: (days: number) => void;
};

export const getThreadCreatedDropdown: (t) => IDropdownOption[] = (t) => [
  { key: 1, text: t("Today") },
  { key: 7, text: t("Past Week") },
  { key: 30, text: t("Past Month") },
  { key: 365, text: t("Past Year") },
  { key: 0, text: t("All Time") },
];

export const CommandBar = ({
  handleSearch,
  onFilter,
}: DocumentsCommandBarProps) => {
  const { t } = useTranslation();
  const onDelete = () => dispatch({ type: "SHOW_DELETE", payload: true });
  const onAdd = () => dispatch({ type: "SHOW_ADD", payload: true });

  const { selectedItems, dispatch } = useThreadsStore((state) => ({
    selectedItems: state.selectedItems as any[],
    dispatch: state.dispatch,
  }));

  const items: CommandBarItemProps[] = useMemo(
    () => [
      {
        key: "newThread",
        text: t("New thread"),
        type: CommandBarItemType.Button,
        iconProps: { iconName: "Add" },
        onClick: onAdd,
      },
      {
        key: "createdFilter",
        type: CommandBarItemType.Dropdown,
        onRenderProps: {
          defaultSelectedKey: getThreadCreatedDropdown(t)[4].key,
          options: getThreadCreatedDropdown(t),
          onChange: (_: never, item: IDropdownOption) =>
            onFilter(item.key as number),
          onRenderLabelProps: {
            label: t("Time"),
            icon: "Clock",
          },
        },
      },
      {
        key: "delete",
        text: t("Delete"),
        type: CommandBarItemType.Button,
        iconProps: { iconName: "Delete" },
        onClick: onDelete,
        ...(selectedItems.length === 0 && {
          disabled: true,
        }),
      },
    ],
    [selectedItems.length, t]
  );

  return (
    <BaseCommandBar
      items={computeCommandBarItems(items)}
      onSearch={handleSearch}
    />
  );
};
