import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

import { YEARLY_EVENTS } from "../config";

import numberOfAlertsSvg from "./svgs/numberOfAlertsIcon.svg";
import operatingHoursSvg from "./svgs/operatingHoursIcon.svg";
import { t } from "i18next";

export const initializeEventsChart = ({ chart, root }: any) => {
  const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
  cursor.lineY.set("visible", false);

  chart.set("panX", false);
  chart.set("panY", false);
  chart.set("wheelX", "none");
  chart.set("wheelY", "none");
  chart.zoomOutButton.set("forceHidden", true);

  const xRenderer = am5xy.AxisRendererX.new(root, {
    minGridDistance: 5,
    cellStartLocation: 0.2,
    cellEndLocation: 0.8,
  });

  xRenderer.labels.template.setAll({
    fill: am5.color("#3B3A39"),
    fontSize: 10,
    paddingBottom: 20,
    paddingTop: 10,
  });

  xRenderer.grid.template.setAll({
    location: 1,
  });

  xRenderer.grid.template.setAll({
    stroke: am5.color("#f3f2f1"),
    strokeWidth: 1,
    strokeOpacity: 1,
  });

  const xAxis = chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      zoomY: false,
      zoomX: false,
      maxDeviation: 0,
      categoryField: "label",
      baseValue: 0,
      start: 0,
      renderer: xRenderer,
    })
  );

  const scrollbarX = chart.get("scrollbarX");
  const scrollbarY = chart.get("scrollbarY");
  scrollbarX.setAll({ visible: false });
  scrollbarY.setAll({ visible: false });

  chart.series.clear();

  return {
    xAxis,
  };
};

export const createYaxisAndSeries = ({
  chart,
  root,
  data,
  xAxis,
  activeTab,
}: any) => {
  const seriesData: any = [
    {
      name: t("Operating Hours"),
      color: "#2c529f",
      icon: operatingHoursSvg,
      valueYField: "operatingHours",
    },
    {
      name: t("Number of Alerts"),
      color: "#66CD7D",
      icon: numberOfAlertsSvg,
      valueYField: "numberOfAlerts",
    },
  ];

  chart.series.clear();
  chart.yAxes.clear();

  seriesData.forEach(({ name, color, icon, valueYField }: any) => {
    const yRenderer = am5xy.AxisRendererY.new(root, {});

    yRenderer.grid.template.setAll({
      stroke: am5.color("#f3f2f1"),
      strokeWidth: 1,
      strokeOpacity: 1,
    });

    yRenderer.setAll({
      stroke: am5.color("#f3f2f1"),
      strokeOpacity: 1,
      opacity: 1,
    });

    yRenderer.labels.template.setAll({
      fill: am5.color("#3B3A39"),
      fontSize: 10,
    });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        zoomY: false,
        zoomX: false,
        maxDeviation: 0,
        renderer: yRenderer,
      })
    );

    yAxis.zoom(0, 1);
    yAxis.get("renderer").labels.template.set("fill", am5.color("#3B3A39"));
    yAxis.get("renderer").labels.template.set("fontSize", 10);

    const container = am5.Container.new(root, {
      ariaLabel: "yAxisLabelContainer",
      layout: root.verticalLayout,
      height: am5.percent(100),
      centerX: am5.p100,
    });

    yAxis.children.unshift(container);

    container.children.unshift(
      am5.Label.new(root, {
        ariaLabel: "yAxisLabel",
        rotation: -90,
        y: am5.percent(55),
        centerX: am5.p50,
        width: am5.percent(100),
        text: name,
        fontWeight: "600",
        fill: am5.color("#3B3A39"),
        fontSize: 10,
        marginRight: 0,
        marginBottom: 0,
      })
    );

    container.children.unshift(
      am5.Picture.new(root, {
        ariaLabel: "y-axis-icon",
        width: 16,
        height: 16,
        x: 5,
        y: am5.percent(60),
        src: icon,
      })
    );

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name,
        valueYField,
        xAxis,
        yAxis,
        clustered: true,
        sequencedInterpolation: true,
        categoryXField: "label",
        fill: am5.color(color),
        stroke: am5.color(color),
      })
    );

    series.columns.template.setAll({
      tooltipText: "{valueY}",
      width: am5.percent(90),
      tooltipY: 0,
      strokeOpacity: 0,
      cornerRadiusTL: 3,
      cornerRadiusTR: 3,
      maxWidth: activeTab === YEARLY_EVENTS ? 20 : 8,
    });

    series.data.setAll(data);
  });
};
