import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import { format as fnsDateFormat } from "date-fns";
import React, { useEffect } from "react";
import type { FieldError } from "react-hook-form";
import { z } from "zod";

import { format } from "../../../../utils";
import { FORMAT } from "../../../analysis-trend-view/utils/getPeriodFilters";
import BaseDialog from "../../../../Components/common/Dialog";
import {
  renderFormItems,
  useZodForm,
} from "../../../../Components/common/Form";
import type { FormItemProps } from "../../../../Components/common/Form/FormItems/helpers";
import { FormItemType } from "../../../../Components/common/Form/FormItems/helpers";
import { useTranslation } from "react-i18next";

const schema = z.object({
  start: z.date(),
  end: z.date(),
});

const CustomDateDialog = ({
  hidden,
  setVisible,
  onSubmit,
  initialValues,
}: any) => {
  const { t } = useTranslation();

  const dialogContentProps = {
    type: DialogType.normal,
    title: t("Custom date"),
    closeButtonAriaLabel: t("Close"),
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useZodForm({
    schema,
  });

  useEffect(() => {
    reset({
      start: initialValues?.startDate
        ? new Date(initialValues.startDate)
        : undefined,
      end: initialValues?.endDate ? new Date(initialValues.endDate) : undefined,
    });
  }, [hidden]);

  const [start, end] = watch(["start", "end"]);

  const fields: FormItemProps[] = [
    {
      name: "start",
      type: FormItemType.DatePicker,
      groupProps: { label: t("Start date") },
      placeholder: t("Start date"),
      formatDate: (date) => format(date ?? new Date()),
      isRequired: true,
      maxDate: end || new Date(),
    },
    {
      name: "end",
      type: FormItemType.DatePicker,
      groupProps: { label: t("End date") },
      placeholder: t("End date"),
      formatDate: (date) => format(date ?? new Date()),
      isRequired: true,
      minDate: start,
      maxDate: new Date(),
    },
  ];

  const submit = handleSubmit((data) => {
    const submitData = {
      end: fnsDateFormat(data.end, FORMAT),
      start: fnsDateFormat(data.start, FORMAT),
    };

    onSubmit && onSubmit(submitData);
    setVisible(false);
  });

  return (
    <BaseDialog
      hidden={hidden}
      dialogContentProps={dialogContentProps}
      onDismiss={() => setVisible(false)}
    >
      <form onSubmit={submit}>
        {renderFormItems(fields, {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}
        <DialogFooter>
          <PrimaryButton text={t("Set date")} onClick={submit} />
          <DefaultButton text={t("Close")} onClick={() => setVisible(false)} />
        </DialogFooter>
      </form>
    </BaseDialog>
  );
};

export default CustomDateDialog;
