import { useEffect } from "react";

import { PAGE_TYPE_TREND } from "../modules/analysis-trend-view/components/TrendViewSelectSignals/TrendViewSelectSignals";

import useSelectedSignalsStoreRawData from "../modules/analysis-raw-data/hooks/useSelectedSignalsStore";
import useSelectedSignalsStore from "../modules/analysis-trend-view/hooks/useSelectedSignalsStore";
import { useTranslation } from "react-i18next";

export const LAST_SIGNAL_LOCAL_STORAGE_KEY = "LAST_SIGNAL";

const useLastSelectedSignal = ({ page }: any) => {
  const { t } = useTranslation();
  const isTrendPage = page === PAGE_TYPE_TREND;

  const { selectedSignals } = useSelectedSignalsStore((store) => ({
    selectedSignals: store.selectedSignals,
  }));

  const { selectedSignals: selectedRawDataSignals } =
    useSelectedSignalsStoreRawData((store) => ({
      selectedSignals: store.selectedSignals,
    }));

  useEffect(() => {
    const lastSelectedSignal = isTrendPage
      ? selectedSignals[selectedSignals.length - 1]
      : selectedRawDataSignals[selectedRawDataSignals.length - 1];
    if (lastSelectedSignal) {
      localStorage.setItem(
        LAST_SIGNAL_LOCAL_STORAGE_KEY,
        JSON.stringify({
          page,
          dataType: lastSelectedSignal.dataType,
          signalId: lastSelectedSignal.id,
          sensorNo: lastSelectedSignal.sensorNo || t("No sensor"),
          machineId: lastSelectedSignal.machineId,
        })
      );
    }
  }, [selectedSignals, selectedRawDataSignals]);

  const getLastSelectedSignal = () => {
    return JSON.parse(
      localStorage.getItem(LAST_SIGNAL_LOCAL_STORAGE_KEY) || "{}"
    );
  };

  return {
    getLastSelectedSignal,
  };
};

export default useLastSelectedSignal;
