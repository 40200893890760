export const getTrendsGroupingOptions = (t) => [
  { key: "separated", text: t("Separated") },
  { key: "grouped by machine", text: t("Grouped by machine") },
  { key: "group everything", text: t("Group everything") },
];

export const getPeriodOptions = (t) => [
  { key: "All data", text: t("All data") },
  { key: "Last 2 years", text: t("Last 2 years") },
  { key: "Last year", text: t("Last year") },
  { key: "Last quarter", text: t("Last quarter") },
  { key: "Last month", text: t("Last month") },
  { key: "Last week", text: t("Last week") },
  { key: "Custom date", text: t("Custom date") },
];

export enum PeriodOptionsEnum {
  LastYear = "Last year",
  LastQuarter = "Last quarter",
  LastMonth = "Last month",
  LastWeek = "Last week",
  CustomDate = "Custom date",
}

export const MAX_GROUP_THRESHOLD = 8;
export const CHART_NUMBER_OF_POINTS = 3000;
