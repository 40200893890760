import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

import { convertToTzDate } from "../../modules/analysis-trend-view/utils/getRangeFormat";
import { apiService } from "../../modules/common";
import { t } from "i18next";

interface UseShortTrendMetaDataPointsInACustomTimeRangeAsZipArgs {
  signalId: string;
  signalTimezone?: string | null | undefined;
  startDateTime: string | Date;
  endDateTime: string | Date;
}

type UseShortTrendMetaDataPointsInACustomTimeRangeAsZipOptions =
  | (Omit<
      UseQueryOptions<any, unknown, any, string[]>,
      "initialData" | "queryFn" | "queryKey"
    > & {
      initialData?: (() => undefined) | undefined;
    })
  | undefined;

const useShortTrendMetaDataPointsInACustomTimeRangeAsZip = (
  args: UseShortTrendMetaDataPointsInACustomTimeRangeAsZipArgs,
  options?: UseShortTrendMetaDataPointsInACustomTimeRangeAsZipOptions
) => {
  return useQuery(
    [
      "short-trend-meta-data-points-in-a-custom-time-range-as-zip",
      args.signalId,
      args.startDateTime as never,
      args.endDateTime as never,
    ],
    () => {
      const getData = async () => {
        const data =
          await apiService.measuredDataRead.getShortTrendMetaDataPointsInACustomTimeRangeAsZip(
            args as never
          );

        return getNodes(data as never, args.signalTimezone);
      };

      return getData();
    },
    options
  );
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getFormattedYMDHMMS = (dayJSDate: any) => {
  const y = dayJSDate.year();
  const m = dayJSDate.month();
  const d = dayJSDate.date();
  const h = dayJSDate.hour();
  const mm = dayJSDate.minute();
  const s = dayJSDate.second();

  const monthFormatted = m + 1 < 10 ? `0${m + 1}` : m + 1;
  const dayFormatted = d < 10 ? `0${d}` : d;
  const hoursFormatted = h < 10 ? `0${h}` : h;
  const minutesFormatted = mm < 10 ? `0${mm}` : mm;
  const secondsFormatted = s < 10 ? `0${s}` : s;

  return {
    yearFormatted: String(y),
    monthFormatted,
    dayFormatted,
    hoursFormatted,
    minutesFormatted,
    secondsFormatted,
  };
};

const getDateObject = (
  date: Dayjs
): { year: string; month: string; day: string; hour: string; formats: any } => {
  const {
    yearFormatted,
    monthFormatted,
    dayFormatted,
    hoursFormatted,
    minutesFormatted,
    secondsFormatted,
  } = getFormattedYMDHMMS(date);

  return {
    year: yearFormatted,
    month: `${yearFormatted}-${monthFormatted}`,
    day: `${yearFormatted}-${monthFormatted}-${dayFormatted}`,
    hour: `${yearFormatted}-${monthFormatted}-${dayFormatted} ${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`,
    formats: {
      YYYY: yearFormatted,
      "YYYY-MM": `${yearFormatted}-${monthFormatted}`,
      "YYYY-MM-DD": `${yearFormatted}-${monthFormatted}-${dayFormatted}`,
      "YYYY-MM-DD hh:mm:ss": `${yearFormatted}-${monthFormatted}-${dayFormatted} ${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`,
      "hh:mm:ss": `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`,
      "hh:mm": `${hoursFormatted}:${minutesFormatted}`,
      MM: `${monthFormatted}`,
      MMM: `${monthNames[Number(monthFormatted) - 1]}`,
      DD: `${dayFormatted}`,
      MONTH: `${Number(monthFormatted)}`,
      default: `${yearFormatted}-${monthFormatted}-${dayFormatted} ${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`,
    },
  };
};

const getNodes = (
  treeDataPointsZip?: {
    dataPoints: { id: string; timeStamp: string; value: number }[];
  },
  signalTimezone?: any
) => {
  if (!treeDataPointsZip?.dataPoints) return [];
  const dataPoints = treeDataPointsZip?.dataPoints;
  const tree = new Map();

  for (let idx = dataPoints.length - 1; idx >= 0; idx -= 1) {
    const convertedSignalTimezoneDate = convertToTzDate(
      dataPoints[idx].timeStamp,
      null,
      signalTimezone
    );

    const convertedDayJSDate = dayjs(convertedSignalTimezoneDate);

    const { year, month, day, hour, formats } =
      getDateObject(convertedDayJSDate);

    const hasYear = tree.has(year);

    if (!hasYear) {
      tree.set(year, { id: year, name: year, children: new Map() });
    }

    const currentYear = tree.get(year);

    const hasMonth = currentYear.children.has(month);

    if (!hasMonth) {
      currentYear.children.set(month, {
        id: month,
        name: formats["MMM"],
        month: formats["MONTH"],
        children: new Map(),
      });
    }

    const currentMonth = currentYear.children.get(month);

    const hasDay = currentMonth.children.has(day);

    if (!hasDay) {
      currentMonth.children.set(day, {
        id: day,
        name: formats["DD"],
        children: new Map(),
      });
    }

    const currentDay = currentMonth.children.get(day);

    const hasHour = currentDay.children.has(hour);

    if (!hasHour) {
      currentDay.children.set(hour, {
        id: dataPoints[idx].id,
        name: formats["hh:mm"],
        last: true,
      });
    }
  }

  const mappedData = [...tree.values()].map(({ id, name, children }) => ({
    id,
    name,
    children: [...children.values()].map(({ id, name, month, children }) => ({
      id,
      name,
      month,
      children: [...children.values()].map(({ id, name, children }) => ({
        id,
        name,
        children: [...children.values()],
      })),
    })),
  }));

  return {
    dataPoints: mappedData,
    dataPointsLength: dataPoints.length,
  };
};

export default useShortTrendMetaDataPointsInACustomTimeRangeAsZip;
