/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState, useEffect } from "react";

import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../../common/Form";
import BaseDialog from "../../common/Dialog";

import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
} from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { BooleanDefault, ConditionStatus } from "../../../schema/status";
import { getOptionFromEnum } from "../../../schema/Utils";
import { MachineFixedCondition } from "../models";
import { updateMachineFixedCondition } from "../api";

import { useTranslation } from "react-i18next";

const getSchema = () =>
  z.object({
    useFixedCondition: z.nativeEnum(BooleanDefault),
    fixedCondition: z.nativeEnum(ConditionStatus),
    fixedConditionComment: z.string().optional().nullable(),
  });

const getFields: (t) => FormItemProps[] = (t) => [
  {
    name: "useFixedCondition",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Fix Condition") },
    options: getOptionFromEnum(t, BooleanDefault),
  },
  {
    name: "fixedCondition",
    type: FormItemType.Dropdown,
    groupProps: { label: t("Condition") },
    options: getOptionFromEnum(t, ConditionStatus),
  },
  {
    name: "fixedConditionComment",
    type: FormItemType.TextArea,
    groupProps: { label: t("Comment") },
  },
];

type AddOrEditDialogProps = IDialogProps & {
  machineId: string;
  data: MachineFixedCondition;
  show: boolean;
  onSuccess: (hasError: boolean) => void;
  onClose: () => void;
};

export const EditCondition = ({
  machineId,
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(), []);

  const [isLoading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        useFixedCondition: data.useFixedCondition
          ? BooleanDefault.True
          : BooleanDefault.False,
        fixedCondition: data.fixedCondition,
        fixedConditionComment: data.fixedConditionComment,
      },
    }),
  });

  useEffect(() => {
    data
      ? reset({
          useFixedCondition: data.useFixedCondition
            ? BooleanDefault.True
            : BooleanDefault.False,
          fixedCondition: data.fixedCondition,
          fixedConditionComment: data.fixedConditionComment
            ? data.fixedConditionComment
            : "",
        })
      : reset({
          useFixedCondition: BooleanDefault.False,
          fixedCondition: ConditionStatus.NoStatus,
          fixedConditionComment: data.fixedConditionComment
            ? data.fixedConditionComment
            : "",
        });
  }, [data, reset]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);

    const toSend: MachineFixedCondition = {
      id: data?.id,
      useFixedCondition: formData.useFixedCondition === "True" ? true : false,
      fixedCondition: formData.fixedCondition,
      fixedConditionComment: formData.fixedConditionComment,
    };

    await updateMachineFixedCondition(machineId, toSend).then((response) =>
      onSuccess("status" in response)
    );
    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <>
      {
        <BaseDialog
          {...rest}
          hidden={!show}
          dialogContentProps={{
            type: DialogType.normal,
            title: t("Edit machine condition"),
            closeButtonAriaLabel: t("Close"),
            onDismiss: handleClose,
          }}
        >
          {
            <form onSubmit={onSubmit}>
              {renderFormItems(getFields(t), {
                control,
                errors: errors as { [schemaProp: string]: FieldError },
              })}

              <DialogFooter>
                <PrimaryButton
                  type="submit"
                  text={t("Save Changes")}
                  disabled={isLoading || !isValid}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                />
                <DefaultButton
                  styles={{
                    root: { border: "unset", background: "transparent" },
                  }}
                  text={t("Cancel")}
                  onClick={handleClose}
                />
              </DialogFooter>
            </form>
          }
        </BaseDialog>
      }
    </>
  );
};
