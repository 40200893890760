/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect } from "react";
import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../common/Form";
import BaseDialog from "../common/Dialog";
import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
} from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { maxLengthType1 } from "../../schema/Constants";
import { Corporation } from "./models";
import { editCorporation, addCorporation } from "./api";
import { areObjectsEqual } from "../../schema/Utils";

import { useTranslation } from "react-i18next";

const getCorporationFields: (t) => FormItemProps[] = (t) => [
  {
    name: "name",
    type: FormItemType.TextField,
    groupProps: { label: t("Name *") },
  },
  {
    name: "number",
    type: FormItemType.TextField,
    groupProps: { label: t("Number") },
  },
];

type AddOrEditDialogProps = IDialogProps & {
  data: Corporation | null;
  items: Corporation[];
  show: boolean;
  onSuccess: (
    hasError: boolean,
    data: Corporation,
    context: "add" | "edit"
  ) => void;
  onClose: () => void;
};

export const AddOrEditDialog = ({
  data,
  items,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(
    data === null || data === undefined
  );
  const filteredItems = useMemo(
    () =>
      // we need to exclude selected data from items when editing
      items.filter(({ name }) =>
        data
          ? name.trim().toLowerCase() !== data.name.trim().toLowerCase()
          : true
      ),
    [items, data]
  );

  const getSchema = (corporations: Corporation[]) =>
    z
      .object({
        id: z.string().optional(),
        name: z
          .string()
          .min(1, { message: t("This field is required") })
          .max(maxLengthType1, {
            message: t(`Name must contain at most {{maxLength}} character(s)`, {
              maxLength: maxLengthType1,
            }),
          }),
        number: z.string().optional(),
      })
      .superRefine((input, ctx) => {
        let issueData: z.IssueData = {
          code: z.ZodIssueCode.custom,
          path: [],
          message: "",
        };

        // Name
        if (input.name && input.name.length > 0) {
          let existingCorporation = corporations.find(
            (c) =>
              c.name.trim().toLowerCase() === input.name.trim().toLowerCase()
          );
          if (existingCorporation) {
            issueData = {
              ...issueData,
              path: ["name"],
              message: t("The corporation name already exists."),
            };

            ctx.addIssue(issueData);
          }
        }

        // Number
        if (input.number && input.number.length > 0) {
          let existingCorporation = corporations.find(
            (c) => c.number === input.number.trim()
          );
          if (existingCorporation) {
            issueData = {
              ...issueData,
              path: ["number"],
              message: t("The corporation number already exists."),
            };
            ctx.addIssue(issueData);
          }
        }
      });

  const schema = useMemo(() => getSchema(filteredItems), [filteredItems]);
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...(!!data && {
      defaultValues: {
        id: data.id,
        name: data.name,
        number: data.number,
      },
    }),
  });

  useEffect(() => {
    data ? reset(data) : reset({ id: "", name: "", number: "" });
  }, [data, reset]);

  // Checks whether the entity has changed.
  useEffect(() => {
    if (!control) {
      return;
    }

    let areEqual = areObjectsEqual(control._defaultValues, control._formValues);
    setDataHasChanged(!areEqual);
  }, [watch()]);

  // Handlers.
  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);

    const mutation = data ? editCorporation : addCorporation;

    await mutation(formData as Corporation).then((response) =>
      onSuccess(
        "status" in response,
        formData as Corporation,
        data ? "edit" : "add"
      )
    );

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.normal,
        title: data ? t("Edit new corporation") : t("Add new corporation"),
        closeButtonAriaLabel: t("Close"),
        onDismiss: handleClose,
      }}
    >
      <form onSubmit={onSubmit}>
        {renderFormItems(getCorporationFields(t), {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}
        <DialogFooter>
          <PrimaryButton
            type="submit"
            text={t("Save Changes")}
            disabled={isLoading || !isValid || !dataHasChanged}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton
            styles={{
              root: { border: "unset", background: "transparent" },
            }}
            text={t("Cancel")}
            onClick={handleClose}
          />
        </DialogFooter>
      </form>
    </BaseDialog>
  );
};
