import { Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
// eslint-disable-next-line import/no-named-as-default
import Tree from "react-hyper-tree";

import { NoData } from "../../../../Components/common/NoData";

import CustomSelectNode from "./CustomSelectNode";
import useSignalsList from "./useSignalsList";
import { useTranslation } from "react-i18next";

export const PAGE_TYPE_RAW_DATA = "RAW_DATA";

const SignalsList: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const { required, handlers, isLoading, hasData } = useSignalsList();

  if (required?.data?.length === 0 || isLoading) {
    return (
      <Spinner
        size={SpinnerSize.large}
        label={t("Loading signal selection...")}
      />
    );
  }

  if (!hasData && !isLoading) {
    return (
      <NoData
        text={t("There is no data!")}
        styles={{
          root: {
            width: "100%",
          },
        }}
      />
    );
  }

  return (
    <Tree
      {...required}
      {...handlers}
      disableLines
      depthGap={10}
      renderNode={(props) => (
        <CustomSelectNode {...props} handlers={handlers} />
      )}
    />
  );
});

SignalsList.displayName = "SignalsList";

export default SignalsList;
