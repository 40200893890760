import "./styles.scss";

import { Selection, SelectionMode } from "@fluentui/react";
import classNames from "classnames";
import { isEmpty } from "lodash-es";
import { useEffect, useMemo } from "react";

import { MAX_GROUP_THRESHOLD } from "../../../../../../modules/analysis-trend-view/constants/controlOptions";
import {
  getColumns,
  prepareCurrentBestValue,
  prepareDiffValue,
} from "./methods";
import usePerformanceStore from "../../../hooks/usePerformanceStore";
import { useStatistics } from "../../../hooks/useStatistics";
import { NoData } from "../../../../../common/NoData";
import { Spin } from "../../../../../common/Spin";
import type { TableProps } from "../../../../../common/Table";
import Table from "../../../../../common/Table";
import { useTranslation } from "react-i18next";

const KpiTable = ({
  bestPerformance = {},
  worstPerformance = {},
  tableSettings = {},
  tableStyle = {},
  tableKeyName,
  tableColumns,
  perfPeriodFilter,
  kpiSignals,
}: any) => {
  const { t } = useTranslation();
  const { updateStore, selectedSignals, isGroupedEverything } =
    usePerformanceStore((store: any) => ({
      updateStore: store.updateStore,
      selectedSignals: store.selectedSignals,
      isGroupedEverything: store.isGroupedEverything,
    }));

  const { isLoading, data: statisticsData } = useStatistics({
    trendOrFastTrendIds: kpiSignals,
    periodStart: perfPeriodFilter?.startDate,
    periodEnd: perfPeriodFilter?.endDate,
    dateTime1: bestPerformance?.timeStamp,
    dateTime2: worstPerformance?.timeStamp,
    options: {
      enabled: !!bestPerformance?.timeStamp && !!worstPerformance?.timeStamp,
    },
  });

  const data = useMemo(() => {
    return (statisticsData || []).map((statisticItem: any) => {
      const {
        signal,
        statistics = {},
        dateTime1 = {},
        dateTime2 = {},
        dateTimeMax = {},
      } = statisticItem;

      const current = perfPeriodFilter ? dateTimeMax?.value : dateTime2?.value;
      const currentBest = prepareCurrentBestValue(current, dateTime1?.value);
      const diff = prepareDiffValue(currentBest, dateTime1?.value);

      return {
        ...signal,
        average: statistics?.avererageAbsolute,
        worst: dateTime2?.value,
        best: dateTime1?.value,
        current,
        currentBest,
        diff,
      };
    });
  }, [statisticsData]);

  const selection: Selection = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.multiple,
        onSelectionChanged: () =>
          updateStore({ selectedSignals: selection.getSelection() }),
      }),
    []
  );

  useEffect(() => {
    if (selectedSignals.length === 0) {
      selection.setAllSelected(false);
      return;
    }

    const tableRows = selection.getItems();
    const selectedSignalsIds = selectedSignals.map(({ id }: any) => id);
    tableRows.forEach((item: any, idx: number) => {
      if (selectedSignalsIds.includes(item.id)) {
        selection.setIndexSelected(idx, true, false);
      }
    });
  }, [selectedSignals, data]);

  const tableProps: TableProps = useMemo(() => {
    return {
      persistOpts: {
        key: tableKeyName || "table-kpi-performance",
        version: 1,
      },
      items: data,
      perPage: 999999,
      hidePerPage: true,
      isLoading,
      isError: false,
      selectionPreservedOnEmptyClick: true,
      columns: tableColumns || getColumns(t),
      ...tableSettings,
    };
  }, [data, isLoading, tableKeyName, tableColumns, tableSettings]);

  const isSelectableTable = ![
    "kpi-modal-table",
    "signals-modal-table",
  ].includes(tableKeyName);

  return (
    <div
      className={classNames("table-drive-load-events kpi-table", {
        "data-section": "exists data?",
      })}
    >
      {isLoading && <Spin style={{ height: "100%" }} />}
      {!isLoading && isEmpty(data) && <NoData style={{ height: "100%" }} />}
      {!isEmpty(data) && (
        <div
          className={classNames({
            "disable-select-all-button":
              isGroupedEverything && isSelectableTable,
            "disable-unchecked-values":
              isGroupedEverything &&
              selectedSignals.length >= MAX_GROUP_THRESHOLD &&
              isSelectableTable,
          })}
          style={{ ...tableStyle }}
        >
          <Table selection={selection} {...tableProps} />
        </div>
      )}
    </div>
  );
};

export default KpiTable;
