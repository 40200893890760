import {
  Text,
  TooltipHost,
  Icon,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";

import BaseCommandBar, {
  computeCommandBarItems,
  CommandBarItemType,
  CommandBarItemProps,
} from "../common/CommandBar";
import { notification } from "../common/Notification";
import Table, { Column, useTableFilters } from "../common/Table";

import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import {
  listAsyncServiceAccount,
  selectServiceAccounts,
  selectServiceAccountsError,
  selectServiceAccountsStatus,
} from "./reducer";
import { ServiceAccountDetails, UserExtendedProperties } from "./models";
import { DeleteConfirm } from "./DeleteConfirm";
import { DetailsDialog } from "./DetailsDialog";
import { detailsServiceAccount } from "./api";
import {
  commandBarStyles,
  iconStyle,
  linkStyle,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import { AddDialog } from "./AddDialog";

import { useTranslation } from "react-i18next";

// --- Columns ---

type ToLoadItem = {
  load: boolean;
  memberId: string;
};

type GetColumnsOpts = {
  t: any;
  isLoading: ToLoadItem;
  onSuccess: (hasError: boolean, displayName: string) => void;
  hasActions: boolean;
  onDetails: (serviceAccountDet: ServiceAccountDetails) => void;
  onDelete: (userExtendedProperties: UserExtendedProperties) => void;
  setIsLoading: React.Dispatch<React.SetStateAction<ToLoadItem>>;
};

const getColumns = ({
  t,
  isLoading,
  onSuccess,
  hasActions,
  onDetails,
  onDelete,
  setIsLoading,
}: GetColumnsOpts): Column[] => {
  const columns: Column[] = [
    {
      key: "memberId",
      name: t("Member Id"),
      fieldName: "memberId",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "displayName",
      name: t("Display Name"),
      fieldName: "displayName",
      minWidth: 150,
      isSortable: true,
      onRender: (data: UserExtendedProperties) => {
        return (
          <Stack horizontal>
            {isLoading.load && isLoading.memberId === data.memberId ? (
              <Spinner size={SpinnerSize.xSmall} />
            ) : null}
            <Link
              to={"."}
              style={linkStyle}
              onClick={async () => {
                setIsLoading({ load: true, memberId: data.memberId });
                await detailsServiceAccount(data.memberId).then((resp) => {
                  onDetails(resp);
                });
                setIsLoading({ load: false, memberId: data.memberId });
              }}
            >
              {data.displayName}
            </Link>
          </Stack>
        );
      },
    },
    {
      key: "email",
      name: t("E-mail"),
      fieldName: "email",
      minWidth: 200,
      isSortable: true,
    },
    {
      key: "firstName",
      name: t("First Name"),
      fieldName: "firstName",
      minWidth: 100,
      isSortable: true,
    },
    {
      key: "lastName",
      name: t("Last Name"),
      fieldName: "lastName",
      minWidth: 100,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (data: UserExtendedProperties) => (
        <div style={{ display: "flex" }}>
          <TooltipHost
            key={1}
            content={t("Delete")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(data)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

export const ServiceAccounts = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const items = useAppSelector(selectServiceAccounts);
  const status = useAppSelector(selectServiceAccountsStatus);
  const error = useAppSelector(selectServiceAccountsError);
  const hasWritePermission = true;

  const [isLoading, setIsLoading] = useState<ToLoadItem>({
    load: false,
    memberId: "",
  });

  useEffect(() => {
    if (id && items.length > 0) {
      //const sel = items.find((ele) => ele?.ddpProperties?.id === id);
      setIsLoading({ load: true, memberId: id });
      detailsServiceAccount(id).then((resp) => {
        resp.status === 404 ? onNotFound() : onDetails(resp);
      });
      setIsLoading({ load: false, memberId: id });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, items]);

  useEffect(() => {
    if (status === Status.error && items.length < 1) notification.error(error);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, items.length]);

  const { filters, handleSearch } = useTableFilters<UserExtendedProperties>({
    keys: ["displayName", "memberId", "lastName", "firstName", "email"],
  });

  const [selected, setSelected] = useState<{
    data?: UserExtendedProperties;
    dataDet?: ServiceAccountDetails;
    context: "add" | "delete" | "details" | "notFound";
  } | null>(null);

  useEffect(() => {
    dispatch(listAsyncServiceAccount());
  }, [dispatch]);

  const onAdd = () =>
    setSelected({
      data: undefined,
      dataDet: undefined,
      context: "add",
    });

  const onNotFound = () => {
    navigate("../services/");
    notification.warning(t("The item does not exist."));
  };

  const onDetails = (serviceAccountDet: ServiceAccountDetails) =>
    setSelected({
      data: serviceAccountDet.ddpProperties as UserExtendedProperties,
      dataDet: serviceAccountDet,
      context: "details",
    });

  const onDelete = (userExtendedProperties: UserExtendedProperties) => {
    setSelected({ data: userExtendedProperties, context: "delete" });
  };

  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "title",
      type: CommandBarItemType.Custom,
      onRender: () => <Text style={titleStyle}>{t("Service Accounts")}</Text>,
    },
    ...(hasWritePermission
      ? [
          {
            key: "add",
            text: t("Add"),
            type: CommandBarItemType.Button,
            iconProps: { iconName: "Add" },
            onClick: onAdd,
          },
        ]
      : []),
  ];

  const onSuccess = (hasError: boolean, displayName: string): void => {
    if (hasError) {
      const message = t(`Failed updating {{displayName}}`, { displayName });
      notification.error(message);
    } else {
      dispatch(listAsyncServiceAccount());
      const message = t(`{{displayName}} updated successfully`, {
        displayName,
      });
      notification.success(message);
    }
  };

  const onSuccessAdd = (hasError: boolean, displayName: string): void => {
    if (hasError) {
      const message = t(`Failed adding {{displayName}}`, { displayName });
      notification.error(message);
    } else {
      dispatch(listAsyncServiceAccount());
      const message = t(`{{displayName}} added successfully`, { displayName });
      notification.success(message);
    }
  };

  return (
    <div style={pageStyle}>
      <BaseCommandBar
        items={computeCommandBarItems(commandBarItems)}
        onSearch={handleSearch}
        styles={commandBarStyles}
      />

      <Table
        persistOpts={{
          key: "table-serviceAccounts",
          version: 2,
        }}
        header={{
          title: t("Services Accounts"),
        }}
        items={items
          .filter((ite) => (ite.ddpProperties ? true : false))
          .map((it) => it.ddpProperties)}
        columns={getColumns({
          t,
          onSuccess: onSuccess,
          hasActions: hasWritePermission,
          onDetails: onDetails,
          onDelete,
          isLoading: isLoading,
          setIsLoading: setIsLoading,
        })}
        filters={filters}
        hasSelection={false}
        isLoading={status === Status.loading}
        isError={status === Status.error}
      />

      <DeleteConfirm
        data={selected?.data as UserExtendedProperties}
        show={selected?.context === "delete"}
        onSuccess={(hasError) => {
          if (hasError) {
            notification.error(t(`Failed deleting service account`));
          } else {
            dispatch(listAsyncServiceAccount());
            notification.success(t(`Service account deleted successfully`));
          }

          setSelected(null);
        }}
        onClose={() => setSelected(null)}
      />

      {selected?.context === "details" && (
        <DetailsDialog
          data={selected?.dataDet}
          show={selected?.context === "details"}
          onSuccess={onSuccess}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}
      {selected?.context === "add" && (
        <AddDialog
          show={selected?.context === "add"}
          onSuccess={onSuccessAdd}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}
    </div>
  );
};
