import { Spinner } from "@fluentui/react";
import type { IDialogProps } from "@fluentui/react/lib/Dialog";
import * as React from "react";

import BaseDialog, {
  DialogSize,
} from "../../../../Components/common/Dialog/Dialog";
import { useTranslation } from "react-i18next";

const DownloadDialog: React.FC<IDialogProps> = ({
  dialogContentProps,
  ...rest
}) => {
  const { t } = useTranslation();
  const defaultContent = (
    <>
      <span>{t("Files are being downloaded...")}</span>
      <Spinner />
    </>
  );

  return (
    <BaseDialog
      {...rest}
      dialogContentProps={{
        title: t("Downloading"),
        // type: DialogType.close,
        ...dialogContentProps,
      }}
      size={DialogSize.M}
    >
      {dialogContentProps?.children || defaultContent}
    </BaseDialog>
  );
};

export default DownloadDialog;
