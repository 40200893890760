import { Stack, Text } from "@fluentui/react";
import type { CSSProperties } from "react";

import type { Report } from "../../../../../types";
import { MarkdownText } from "../../../../common/MarkdownEditor";
import { File } from "../../../../common/Preview/File";
import type { UploadedFile } from "../../../../common/Uploader/types";
import { useTranslation } from "react-i18next";

type ReportTextProps = {
  report: Report;
};

const titleStyle: CSSProperties = {
  fontWeight: 600,
  marginTop: 10,
  marginBottom: 3,
};

const ReportText = ({ report }: ReportTextProps) => {
  const { t } = useTranslation();
  return (
    <Stack tokens={{ childrenGap: 10 }} style={{ position: "relative" }}>
      <MarkdownText text={report.text} />
      {report.files && report.files.length > 0 && (
        <Stack tokens={{ childrenGap: 5 }}>
          <Text variant="large" as="h5" style={titleStyle}>
            {t("Attachments")}
          </Text>
          <Stack.Item>
            {report.files.map((value: UploadedFile, index: number) => {
              return <File key={index} file={value} />;
            })}
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
};

export default ReportText;
