import { Spinner, SpinnerSize, Text } from "@fluentui/react";
import classNames from "classnames";

import ArrowLeftIcon from "../../../../assets/svg/ArrowLeftIcon";
import ArrowRightIcon from "../../../../assets/svg/ArrowRightIcon";
import { useSectionsStore } from "../../../../Hooks";
import type { PlaceholderInterface } from "../../types";
import { useTranslation } from "react-i18next";

const Placeholder = ({
  text,
  icon,
  isLoading,
  style,
}: PlaceholderInterface) => {
  const { isAnyMaximized } = useSectionsStore((store) => ({
    domIds: store.domIds,
    isAnyMaximized: store.isAnyMaximized,
  }));

  const { t } = useTranslation();

  return (
    <div
      className={classNames("placeholder", {
        "placeholder--hidden": isAnyMaximized(),
      })}
      style={style}
    >
      {isLoading ? (
        <div>
          <Spinner size={SpinnerSize.large} label={text} />
          <Text style={{ fontSize: 10, display: "block" }}>
            {t("loading time depends on the amount of data")}
          </Text>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon === "left" && <ArrowLeftIcon style={{ marginRight: 15 }} />}
          <div>{text}</div>
          {icon === "right" && <ArrowRightIcon style={{ marginLeft: 15 }} />}
        </div>
      )}
    </div>
  );
};

export default Placeholder;
