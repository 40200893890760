import "./styles.scss";

import type {
  IColumn,
  INavStyles,
  ISearchBoxStyles,
  ISpinButtonStyles,
} from "@fluentui/react";
import {
  ActionButton,
  Checkbox,
  Dropdown,
  Link,
  SearchBox,
  Separator,
  SpinButton,
  Stack,
  StackItem,
} from "@fluentui/react";
import type { FormEvent, SyntheticEvent } from "react";
import React, { useEffect, useState } from "react";
import { z } from "zod";

import ClearIcon from "../../../../assets/svg/ClearIcon";
import { queryClient } from "../../../core";
import { NoData } from "../../../../Components/common/NoData";

import useSearchGroupsAndItems from "../../hooks/useSearchGroupsAndItems";
import { useCatalogueStore } from "./hooks";
import PaginatedShimmeredDetailsList from "./PaginatedShimmeredDetailsList";
import { useTranslation } from "react-i18next";

const spinButtonStyles: Partial<ISpinButtonStyles> = {
  spinButtonWrapper: { marginLeft: "auto" },
  labelWrapper: { minWidth: 240 },
  label: { fontWeight: 400 },
};

const styles: Partial<INavStyles> = {
  root: [
    {
      height: 1,
      padding: "0px",
      background: "Grey40",
    },
  ],
};

const searchBoxStyles: ISearchBoxStyles = {
  root: {
    width: 300,
  },
};

const schemaNumber = z.number().int().nonnegative();
const schemaFloat = z.number().nonnegative();

const validateNumber = (
  value: string,
  type: "float" | "int"
): number | undefined => {
  const result =
    type === "float"
      ? schemaFloat.safeParse(parseFloat(value))
      : schemaNumber.safeParse(parseInt(value, 10));

  return result.success ? parseFloat(result.data.toFixed(4)) : undefined;
};

const handleValidate = (value: string, type: "float" | "int"): string => {
  const result = validateNumber(value, type);
  return result !== undefined ? result.toString() : "";
};

const handleChange = (
  value: string,
  type: "float" | "int",
  action: (value: number) => void
) => {
  const result = validateNumber(value, type);
  if (result !== undefined) {
    action(result);
  }
};

const handleInput = (
  event: FormEvent<HTMLDivElement> | SyntheticEvent<HTMLElement, Event>,
  type: "float" | "int",
  action: (value: number) => void
) => {
  const result = validateNumber((event.target as HTMLInputElement).value, type);
  if (result !== undefined) {
    action(result);
  }
};

function getGuidString(
  items: any[],
  firstFrequency: number,
  secondFrequency: number,
  currentOption?: string
): string {
  const uncertainty = 0.04;

  if (firstFrequency === 0) return "";

  // Filter items by first frequency
  const firstItems = items.filter(
    (item) => Math.abs(item.frequencyInHz - firstFrequency) <= uncertainty
  );

  // Filter sidebands by second frequency
  const secondItems = items
    .flatMap((item) => item.sidebands)
    .filter(
      (item) => Math.abs(item.frequencyInHz - secondFrequency) <= uncertainty
    );

  // Construct potential matches
  const potentialMatches = firstItems.flatMap((firstItem) => {
    if (secondFrequency === 0) {
      return [firstItem.id];
    }
    return secondItems.map((secondItem) => `${firstItem.id}:${secondItem.id}`);
  });

  // Check if currentOption matches any potential match
  if (currentOption && potentialMatches.includes(currentOption)) {
    return currentOption;
  }

  // If no direct match, construct the option string based on filtered items
  const firstGuid = firstItems.length > 0 ? firstItems[0].id : "";
  const secondGuid = secondItems.length > 0 ? secondItems[0].id : "";
  return secondFrequency === 0 ? firstGuid : `${firstGuid}:${secondGuid}`;
}

function getFrequencies(items: any[], guidString: string): [number, number] {
  const guids = guidString.split(":");
  const firstGuid = guids[0];
  const secondGuid = guids[1] || "";

  const firstItem = items.find((item) => item.id === firstGuid);
  if (!firstItem) {
    return [0, 0];
  }

  const firstFrequency = firstItem.frequencyInHz;

  if (!secondGuid) {
    return [firstFrequency, 0];
  }

  const secondItem = items
    .flatMap((item) => item.sidebands)
    .find((item) => item.id === secondGuid);
  const secondFrequency = secondItem ? secondItem.frequencyInHz : 0;

  return [firstFrequency, secondFrequency];
}

function multiplyFrequencies(data: any, multiplier: number | undefined) {
  if (!multiplier) {
    return data;
  }
  return data?.map((item: any) => {
    const { sidebands, ...rest } = item;
    return {
      ...rest,
      frequencyInHz: parseFloat((item.frequencyInHz * multiplier).toFixed(2)),
      sidebands: sidebands.map((sideband: any) => {
        return {
          ...sideband,
          frequencyInHz: parseFloat(
            (sideband.frequencyInHz * multiplier).toFixed(2)
          ),
        };
      }),
    };
  });
}

function FrequenciesTable({
  data,
  catalogueData,
  onSelectedOption,
  selectedOption,
  setSelectedOption,
}: any) {
  const { t } = useTranslation();

  function renderDropdown(item?: any) {
    return (
      <Dropdown
        selectedKey={selectedOption}
        options={[
          { key: item.id, text: t("None") },
          ...(item?.sidebands || []).map(
            ({ id, itemName }: { id: string; itemName: string }) => ({
              key: item.id + ":" + id,
              text: itemName,
            })
          ),
        ]}
        onChange={(event, option) => {
          if (option) {
            const selectedOptionKey = option.key as string;
            item.options = selectedOptionKey;
            setSelectedOption(selectedOptionKey);
            onSelectedOption(selectedOptionKey);
          }
        }}
      />
    );
  }

  const columns: IColumn[] = [
    {
      key: "itemName",
      fieldName: "itemName",
      name: t("Name"),
      maxWidth: 150,
      minWidth: 50,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Link
            to={item.link}
            onClick={() => {
              setSelectedOption(item.id);
              onSelectedOption(item.id);
            }}
          >
            {item.itemName}
          </Link>
        );
      },
    },
    {
      key: "frequencyInHz",
      fieldName: "frequencyInHz",
      name: t("Frequency"),
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
    },

    // Sidebands feature

    {
      key: "sidebands",
      fieldName: "sidebands",
      name: t("Sidebands"),
      minWidth: 50,
      maxWidth: 200,
      isResizable: true,
      onRender: renderDropdown,
    },
  ];

  return (
    <PaginatedShimmeredDetailsList
      items={data}
      columns={columns}
      groups={catalogueData.groups}
      selectedOption={selectedOption}
      itemsPerPage={40}
    />
  );
}

export default function FrequenciesCatalogue({
  machineId,
  inputs,
  inputSpeedReference,
}: any) {
  const { t } = useTranslation();

  const { keyedCatalogueData } = useCatalogueStore((store: any) => ({
    keyedCatalogueData: store.keyedCatalogueData,
    setSelectedSignalValues: store.setSelectedSignalValues,
    removeXAxisRange: store.removeXAxisRange,
    selectedSignalsStores: store.selectedSignalsStores,
  }));

  const referenceSpeedSignal: any = queryClient.getQueryData([
    "frequency-catalogue-reference-speed-signal",
    machineId,
  ]);

  const catalogueData = keyedCatalogueData[machineId]?.groupsAndItems
    ? keyedCatalogueData[machineId].groupsAndItems
    : [];

  const { data, handleSearch } = useSearchGroupsAndItems(catalogueData);

  const [dataWithVelocityFactor, setDataWithVelocityFactor] = useState<any>(
    multiplyFrequencies(
      data.items,
      inputSpeedReference.ratioSpeedFromCustomValueRPM
    )
  );

  const [selectedOption, setSelectedOption] = React.useState<string>(
    getGuidString(
      dataWithVelocityFactor,
      inputs.freqHzInput.value,
      inputs.sbsHzInput.value
    )
  );

  useEffect(() => {
    setDataWithVelocityFactor(
      multiplyFrequencies(
        data.items,
        inputSpeedReference.ratioSpeedFromCustomValueRPM
      )
    );
    return;
  }, [data.items]);

  useEffect(() => {
    setSelectedOption(
      getGuidString(
        dataWithVelocityFactor,
        inputs.freqHzInput.value,
        inputs.sbsHzInput.value,
        selectedOption
      )
    );
    return;
  }, [inputs.freqHzInput.value, dataWithVelocityFactor]);

  if (catalogueData?.length === 0) {
    return <NoData style={{ height: "100%" }} />;
  }
  const handleSelectedOption = (selectedOption: string) => {
    let freq1 = 0;
    let freqSB = 0;
    [freq1, freqSB] = getFrequencies(dataWithVelocityFactor, selectedOption);
    handleChange(
      freq1.toString(),
      inputs.freqHzInput.type,
      inputs.freqHzInput.action
    );
    handleChange(
      freqSB.toString(),
      inputs.sbsHzInput.type,
      inputs.sbsHzInput.action
    );
  };

  const commonStyle = { minWidth: "400px" };

  return (
    <Stack className="catalogue-content" style={{ rowGap: 16, height: "100%" }}>
      <Stack
        horizontal
        wrap
        tokens={{ childrenGap: 10 }}
        style={{ paddingLeft: "40px" }}
      >
        <Stack horizontal horizontalAlign="space-between" style={commonStyle}>
          <StackItem>{t("Reference Speed Signal")}</StackItem>
          <StackItem>
            {referenceSpeedSignal?.name
              ? referenceSpeedSignal.name
              : t("The Reference Speed signal is undefined")}
          </StackItem>
        </Stack>
        {referenceSpeedSignal?.rpm && (
          <>
            <Stack
              horizontal
              horizontalAlign="space-between"
              style={commonStyle}
            >
              <StackItem>{t("Nominal speed value (RPM)")}</StackItem>
              <StackItem>{referenceSpeedSignal?.rpm}</StackItem>
            </Stack>

            <Stack style={commonStyle}>
              <Checkbox
                label={t("Use manual input")}
                defaultChecked={inputSpeedReference.manualInput}
                onChange={(e, checked) => {
                  inputSpeedReference.setManualInput(machineId, checked);
                  checked
                    ? setDataWithVelocityFactor(
                        multiplyFrequencies(
                          data.items,
                          inputSpeedReference.ratioSpeedFromCustomValueRPM
                        )
                      )
                    : setDataWithVelocityFactor(
                        multiplyFrequencies(data.items, 1)
                      );
                  handleChange(
                    "0",
                    inputs.freqHzInput.type,
                    inputs.freqHzInput.action
                  );
                  handleChange(
                    "0",
                    inputs.sbsHzInput.type,
                    inputs.sbsHzInput.action
                  );
                }}
              />
            </Stack>
            <Stack style={commonStyle}>
              <SpinButton
                styles={spinButtonStyles}
                min={0}
                value={
                  inputSpeedReference.ratioSpeedFromCustomValueRPM
                    ? inputSpeedReference.ratioSpeedFromCustomValueRPM *
                      referenceSpeedSignal?.rpm
                    : referenceSpeedSignal?.rpm
                }
                label={t("Custom value (RPM)")}
                disabled={!inputSpeedReference.manualInput}
                onChange={(event, newValue) => {
                  inputSpeedReference.setRatioSpeedFromCustomValueRPM(
                    machineId,
                    newValue
                      ? parseInt(newValue) / referenceSpeedSignal?.rpm
                      : referenceSpeedSignal?.rpm
                  );
                  newValue &&
                    setDataWithVelocityFactor(
                      multiplyFrequencies(
                        data.items,
                        parseInt(newValue) / referenceSpeedSignal?.rpm
                      )
                    );
                  handleChange(
                    "0",
                    inputs.freqHzInput.type,
                    inputs.freqHzInput.action
                  );
                  handleChange(
                    "0",
                    inputs.sbsHzInput.type,
                    inputs.sbsHzInput.action
                  );
                }}
              />
            </Stack>
          </>
        )}
        <Separator styles={styles}></Separator>
        <Stack style={commonStyle}>
          <SpinButton
            value={inputs.freqNumberInput.value.toString()}
            styles={spinButtonStyles}
            min={0}
            step={1}
            label={t("Freq. Number")}
            onValidate={(value) =>
              handleValidate(value, inputs.freqNumberInput.type)
            }
            onInput={(event) =>
              handleInput(
                event,
                inputs.freqNumberInput.type,
                inputs.freqNumberInput.action
              )
            }
            onChange={(event, newValue) =>
              handleChange(
                newValue || "0",
                inputs.freqNumberInput.type,
                inputs.freqNumberInput.action
              )
            }
          />
        </Stack>
        <Stack style={commonStyle}>
          <SpinButton
            value={inputs.sbsNumberInput.value.toString()}
            styles={spinButtonStyles}
            min={0}
            step={1}
            label={t("SBs Number")}
            onValidate={(value) =>
              handleValidate(value, inputs.sbsNumberInput.type)
            }
            onInput={(event) =>
              handleInput(
                event,
                inputs.sbsNumberInput.type,
                inputs.sbsNumberInput.action
              )
            }
            onChange={(event, newValue) =>
              handleChange(
                newValue || "0",
                inputs.sbsNumberInput.type,
                inputs.sbsNumberInput.action
              )
            }
          />
        </Stack>
      </Stack>

      <Separator styles={styles}></Separator>
      <hr style={{ background: "#e1dfdd", border: "none", height: "1px" }} />

      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <ActionButton
          styles={{
            root: [
              { minWidth: 125, marginRight: 16 },
              {
                ".ms-Button-flexContainer": {
                  justifyContent: "space-between",
                },
              },
            ],
          }}
          onClick={() => {
            handleChange(
              "0",
              inputs.freqHzInput.type,
              inputs.freqHzInput.action
            );
            handleChange("0", inputs.sbsHzInput.type, inputs.sbsHzInput.action);
          }}
        >
          <ClearIcon />
          {t("Clear selection")}
        </ActionButton>

        <SearchBox
          placeholder={t("Search")}
          styles={searchBoxStyles}
          onChange={(_, newValue) => handleSearch(newValue ?? "")}
        />
      </Stack>

      <FrequenciesTable
        data={dataWithVelocityFactor}
        catalogueData={data}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        onSelectedOption={handleSelectedOption}
      />
    </Stack>
  );
}
