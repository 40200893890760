/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { notification } from "../Components/common/Notification";
import { HaagMappingApi } from "../Components/Machines/MachineDetails/HaagMapping/api";
import { HaagSignalMapping } from "../Components/Machines/MachineDetails/HaagMapping/models";
import { Status } from "../schema/status";
import { useTranslation } from "react-i18next";

type HaagMappingState = {
  status: Status;
  payload?: Map<string, HaagSignalMapping>;
};

export type HaagMappingData = HaagMappingState & {
  refresh: () => void;
};

export const useHaagMapping = (machineId: string): HaagMappingData => {
  const { t } = useTranslation();
  const [state, setState] = useState<HaagMappingState>({ status: Status.void });

  // Triggers the data fetch when machine Id changes.
  useEffect(() => {
    if (machineId.length === 0) {
      return;
    }

    setState({ status: Status.loading });
  }, [machineId]);

  // Fetches the information
  useEffect(() => {
    if (
      state.status === Status.idle ||
      state.status === Status.error ||
      !machineId ||
      machineId.length === 0
    ) {
      return;
    }

    let mount = true;
    HaagMappingApi.getAll(machineId).then((response) => {
      if (!mount) {
        return;
      }

      if (response.status !== 200) {
        setState({ status: Status.error });
        notification.error(
          response["text"]?.message
            ? t("Error {{status}} retrieving HAAG data: {{errorMessage}}", {
                status: response.status,
                errorMessage: response["text"]?.message,
              })
            : t(`Error {{status}} retrieving HAAG data: Unknown error`, {
                status: response.status,
              })
        );
        return;
      }

      let payload = new Map<string, HaagSignalMapping>();
      response.data.forEach((item) => payload.set(item.id, item));
      setState({ status: Status.idle, payload });
    });

    // Cleans all resources
    return () => {
      mount = false;
    };
  }, [state.status]);

  // Handlers
  const refresh = () => setState({ ...state, status: Status.loading });

  return { ...state, refresh };
};
