import { Stack, StackItem } from "@fluentui/react";
import {
  conditionColor,
  getConditionFormat,
} from "../../../../../../../../modules/machine-cv/constants";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const MACHINE_CONDITION_FILTERS = ["NotInstalled", "Faulty"];

const CONDITIONS = Object.keys(conditionColor).filter(
  (key) => !MACHINE_CONDITION_FILTERS.includes(key)
);

function renderCondition({ text, number, color, onClick }) {
  return (
    <Stack key={text} style={{ gap: 8 }} verticalAlign="center" horizontal>
      <div
        className="legend-circle"
        onClick={onClick}
        style={{ background: color }}
      />

      <Stack>
        <StackItem style={{ fontWeight: 700 }}>{number}</StackItem>
        <StackItem style={{ fontSize: 10 }}>{text}</StackItem>
      </Stack>
    </Stack>
  );
}

export default function Legend({ slices }) {
  const { t } = useTranslation();

  const slicesMap = slices.reduce(
    (acc, { condition, text, color, onClick }) => {
      acc[condition] = {
        text: condition,
        number: text,
        color: color,
        onClick,
      };
      return acc;
    },
    {}
  );

  const statuses = useMemo(
    () =>
      CONDITIONS.map((item) => {
        const { text, number, color, onClick } = slicesMap?.[item] || {
          text: item,
          number: 0,
          color: conditionColor[item],
          onClick: () => null,
        };
        return {
          text: getConditionFormat(t)[text],
          number,
          color,
          onClick,
        };
      }),
    [slicesMap, t]
  );

  return (
    <Stack style={{ gap: 16, minWidth: "128px" }}>
      <Stack style={{ gap: 8 }}>{statuses.map(renderCondition)}</Stack>
    </Stack>
  );
}
