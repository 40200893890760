import { useEffect, useMemo } from "react";

import { getQuery, useHomeOverview } from "../../../../Hooks";
import { BookmarkType } from "../../../../modules/common/components/Bookmarks";
import type { HomeOverviewMetaInformation } from "../../../../types";
import { useMachineConditionsTable } from "../components/Machines/useMachineConditionsTable";

const MACHINE_CONDITION_FILTERS = ["NotInstalled", "Faulty"];

const mapQuery = (metaInformation: HomeOverviewMetaInformation) =>
  getQuery({
    corporation: metaInformation.corporationName,
    company: metaInformation.companyName,
    project: metaInformation.projectName,
    machine: metaInformation.machineName,
    id: metaInformation.machineId,
    sensorNo: "",
  });

export const useHomeOverviewQuery = () => {
  const {
    data = { dashboards: [], machines: [], tasks: [] },
    isLoading,
    isError,
  } = useHomeOverview({ cacheTime: 0, retry: 0 });
  const { setMachinesData } = useMachineConditionsTable();

  const mappedDashboards = useMemo(
    () =>
      data.dashboards.map(({ id, parentId, parentType, metaInformation }) => ({
        id: parentId,
        uniqId: parentId + "-" + id,
        type: parentType,
        isDashboard: true,
        name: metaInformation.title,
        subtitle: metaInformation.titleParent,
      })),
    [data.dashboards, isLoading, isError]
  );

  const mappedMachines = useMemo(
    () =>
      data.machines
        .filter(
          ({ condition }) => !MACHINE_CONDITION_FILTERS.includes(condition)
        )
        .map(({ dalogId, condition, iconId, metaInformation }) => ({
          id: metaInformation.machineId,
          uniqId: dalogId + "-" + metaInformation?.projectId,
          condition,
          iconId,
          title: metaInformation.title,
          subtitle: metaInformation.titleParent,
          query: mapQuery(metaInformation),
          type: BookmarkType.Machine,
          isMachine: true,
          projectName: metaInformation.projectName,
          projectId: metaInformation.projectId,
        })),
    [data.machines, isLoading, isError]
  );

  const mappedTasks = useMemo(
    () =>
      data.tasks
        .filter(({ status }) => status !== "Done")
        .map(({ metaInformation, ...task }) => ({
          ...task,
          uniqId: metaInformation?.machineId + "-" + task?.id,
          machineName: metaInformation.machineName,
          query: mapQuery(metaInformation),
        })),
    [data.tasks, isLoading, isError]
  );

  useEffect(() => {
    setMachinesData(mappedMachines);
  }, [mappedMachines?.length]);

  return {
    data: {
      dashboards: mappedDashboards,
      machines: mappedMachines,
      tasks: mappedTasks,
    },
    isLoading,
    isError,
  };
};
