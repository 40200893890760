import { useMutation } from "@tanstack/react-query";
import { getApiClient } from "../../../modules/core/apiClient/useApiStore";

export type UseProjectTriggeringProps = {
  projectId: string;
};

export const useProjectTriggering = ({
  projectId,
}: UseProjectTriggeringProps) => {
  const { mutateAsync } = useMutation((selectedKeys: string[]) =>
    getApiClient().post(
      `/dws/v1/triggers/on-demand/projects/${projectId}`,
      selectedKeys
    )
  );

  return {
    triggerProjectAsync: mutateAsync,
  };
};
