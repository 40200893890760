import { sortBy, uniqueId } from "lodash-es";

import { useState } from "react";
import { Marker as MapGlMarker } from "react-map-gl";
import type useSupercluster from "use-supercluster";

import { useModeSelect } from "../../Home/HomePage/hooks/useModeSelect";
import DefaultMarker from "./DefaultMarker";

type _Internal = ReturnType<typeof useSupercluster>;

export type MapMarkerProps = {
  supercluster: _Internal["supercluster"];
  cluster: _Internal["clusters"][0];
};

// TODO: should be generic (used only in Dashboard Page)
const MarkerMap = ({ cluster, supercluster }: MapMarkerProps) => {
  const { getModeColor } = useModeSelect();
  const [isPopupVisible, setPopupVisible] = useState(false);

  const [longitude, latitude] = cluster.geometry.coordinates;

  const { cluster: isCluster, name } = cluster.properties as {
    cluster: boolean;
    id: string;
    name: string;
    slug: string;
  };

  if (isCluster) {
    const { point_count: count } = cluster.properties as {
      point_count: number;
    };

    const clusterPoints = sortBy(
      supercluster
        ?.getLeaves(cluster.id as number, Infinity)
        .map(({ properties: { id, uniqId = "", name = "", slug = "" } }) => ({
          id,
          uniqId,
          name,
          slug,
        })),
      [({ name }) => name?.toLowerCase()]
    );

    return (
      <MapGlMarker
        key={`cluster-${cluster.id}`}
        latitude={latitude}
        longitude={longitude}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          role="button"
          tabIndex={0}
          className="marker-cluster"
          style={{ background: getModeColor() }}
          onClick={() => {
            setPopupVisible(!isPopupVisible);
          }}
        >
          {count}
          {isPopupVisible && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              role="button"
              tabIndex={-1}
              className="marker-cluster-popup-list"
              onClick={(e) => e.stopPropagation()}
            >
              {clusterPoints?.map(({ uniqId, name }) => {
                return (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <div
                    key={uniqueId(uniqId)}
                    role="button"
                    tabIndex={-1}
                    className="marker-cluster-popup-list-item"
                  >
                    <div>{name}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </MapGlMarker>
    );
  }

  return (
    <MapGlMarker
      key={cluster.properties?.uniqId || cluster.properties?.id}
      latitude={latitude}
      longitude={longitude}
    >
      <DefaultMarker name={name} />
    </MapGlMarker>
  );
};

export default MarkerMap;
