import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { getColumns } from "../../Gateway/columns";
import { useMachineSensorsProfiles } from "../../../hooks/useMachineSensorsProfiles";
import Table, { TableProps } from "../../../../common/Table";
import { useTranslation } from "react-i18next";

const TriggerStatusTab = () => {
  const { t } = useTranslation();
  const machineId = useParams()?.machineId || "";
  const {
    data: sensorsProfiles,
    isLoading,
    isError,
  } = useMachineSensorsProfiles({ machineId });

  const tableProps = useMemo<TableProps>(
    () => ({
      isLoading,
      isError,
      persistOpts: {
        key: "machine-sensor-trigger-status",
        version: 1,
      },
      header: {
        title: t("Machine Sensor Trigger Status"),
      },
      columns: getColumns(t),
      hasSelection: false,
      items: sensorsProfiles || [],
    }),
    [isLoading, isError, sensorsProfiles, t]
  );

  return <Table {...tableProps} />;
};

export default TriggerStatusTab;
