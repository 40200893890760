import type { TaskStatuses } from "../../../types";

export const conditionColor = {
  Ok: "#66CD7D",
  Indication: "#FFCC00",
  Alert: "#FF7E0D",
  Danger: "#F63F4D",
  NotInstalled: "#5A71A9",
  NoStatus: "#B3B3B3",
  Faulty: "#C7B8E7",
};

export const getConditionFormat = (t) => ({
  Ok: t("Ok"),
  Indication: t("Indication"),
  Alert: t("Alert"),
  Danger: t("Danger"),
  NotInstalled: t("Not Installed"),
  NoStatus: t("No Status"),
  Faulty: t("Faulty"),
});

export const CONDITION_RANKING = [
  "Danger",
  "Alert",
  "Indication",
  "Ok",
  "NoStatus",
  "Faulty",
  "NotInstalled",
];

export type Condition = keyof typeof conditionColor;

export const getTaskStatusFormat: (
  t
) => Record<TaskStatuses | "All", string> = (t) => ({
  All: t("All"),
  Done: t("Done"),
  Cancelled: t("Cancelled"),
  InProgress: t("In progress"),
  ToDo: t("To do"),
});
