import { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { AskResponse, getCitationFilePath } from "../../Api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { useTranslation } from "react-i18next";

interface Props {
  answer: AskResponse;
  isSelected?: boolean;
  onCitationClicked: (filePath: string) => void;
  onThoughtProcessClicked: () => void;
  onSupportingContentClicked: () => void;
  onFollowupQuestionClicked?: (question: string) => void;
  showFollowupQuestions?: boolean;
}

export const Answer = ({
  answer,
  isSelected,
  onCitationClicked,
  onThoughtProcessClicked,
  onSupportingContentClicked,
  onFollowupQuestionClicked,
  showFollowupQuestions,
}: Props) => {
  const { t } = useTranslation();
  const parsedAnswer = useMemo(
    () => parseAnswerToHtml(answer.answer, onCitationClicked),
    [answer]
  );

  const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

  return (
    <Stack
      className={`${styles.answerContainer} ${isSelected && styles.selected}`}
      verticalAlign="space-between"
    >
      <Stack.Item>
        <Stack horizontal horizontalAlign="space-between">
          <AnswerIcon />
          <div>
            <IconButton
              style={{ color: "black" }}
              iconProps={{ iconName: "Lightbulb" }}
              title={t("Show thought process")}
              ariaLabel={t("Show thought process")}
              onClick={() => onThoughtProcessClicked()}
              disabled={!answer.thoughts}
            />
            <IconButton
              style={{ color: "black" }}
              iconProps={{ iconName: "ClipboardList" }}
              title={t("Show supporting content")}
              ariaLabel={t("Show supporting content")}
              onClick={() => onSupportingContentClicked()}
              disabled={!answer.data_points.length}
            />
          </div>
        </Stack>
      </Stack.Item>

      <Stack.Item grow>
        <div
          className={styles.answerText}
          dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}
        />
      </Stack.Item>

      {!!parsedAnswer.citations.length && (
        <Stack.Item>
          <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
            <span className={styles.citationLearnMore}>{t("Citations:")}</span>
            {parsedAnswer.citations.map((x, i) => {
              const path = getCitationFilePath(x);
              return (
                <a
                  href="/#"
                  key={i}
                  className={styles.citation}
                  title={x}
                  onClick={() => onCitationClicked(path)}
                >
                  {`${++i}. ${x}`}
                </a>
              );
            })}
          </Stack>
        </Stack.Item>
      )}

      {!!parsedAnswer.followupQuestions.length &&
        showFollowupQuestions &&
        onFollowupQuestionClicked && (
          <Stack.Item>
            <Stack
              horizontal
              wrap
              className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}
              tokens={{ childrenGap: 6 }}
            >
              <span className={styles.followupQuestionLearnMore}>
                {t("Follow-up questions:")}
              </span>
              {parsedAnswer.followupQuestions.map((x, i) => {
                return (
                  <a
                    href="/#"
                    key={i}
                    className={styles.followupQuestion}
                    title={x}
                    onClick={() => onFollowupQuestionClicked(x)}
                  >
                    {`${x}`}
                  </a>
                );
              })}
            </Stack>
          </Stack.Item>
        )}
    </Stack>
  );
};
