import {
  Link,
  Icon,
  TooltipHost,
  Text,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { DataTypeSignals, SignalCondition } from "../../../schema/status";

import { Column } from "../../common/Table";
import {
  CommandBarItemProps,
  CommandBarItemType,
} from "../../common/CommandBar";

import {
  camelCaseToSpacedString,
  format,
  formatFileSize,
  formatSize,
} from "../../../schema/Utils";
import {
  Configuration,
  Image,
  MachineFixedCondition,
  ResponseSimplifiedEvent,
  ResponseSimplifiedSignal,
  Sensor,
} from "../models";
import { iconStyle } from "../../../schema/Constants";
import { AlertLevel } from "./AlertLevels/models";
import { AcceptedFile } from "../../common/Uploader/Uploader";
import { FrequencyCatalogueDbEntry } from "./FrequencyCatalogue/models";

type GetColumnsOptsFrequencyCatalogue = {
  t: any;
  hasActions: boolean;
  onEdit: (freq: FrequencyCatalogueDbEntry) => void;
  onDelete?: (freqId: string) => void;
};

export const getColumnsFrequencyCatalogue = ({
  t,
  hasActions,
  onEdit,
  onDelete,
}: GetColumnsOptsFrequencyCatalogue): Column[] => {
  const columns: Column[] = [
    {
      key: "itemName",
      name: t("Name"),
      fieldName: "itemName",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "frequencyInHz",
      name: t("Frequency In Hz"),
      fieldName: "frequencyInHz",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "description",
      name: t("Description"),
      fieldName: "description",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (freq: FrequencyCatalogueDbEntry) => (
        <div style={{ display: "flex" }} key={freq.id + "actions"}>
          <TooltipHost
            key={freq.id + "actions0"}
            content={t("Edit")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(freq)}
              style={iconStyle}
            />
          </TooltipHost>
          {onDelete && (
            <TooltipHost
              key={freq.id + "actions1"}
              content={t("Delete")}
              styles={{ root: { display: "flex" } }}
            >
              <Icon
                iconName="Delete"
                onClick={() => onDelete(freq.id)}
                style={iconStyle}
              />
            </TooltipHost>
          )}
        </div>
      ),
    });
  }

  return columns;
};

export const getColumnsConditions = (t): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 150,
    },
    {
      key: "value",
      name: t("Value"),
      fieldName: "value",
      minWidth: 150,
      onRender: ({ value }) => camelCaseToSpacedString(value),
    },
  ];

  return columns;
};

type GetColumnsOptsFixCondition = {
  t: any;
  onEdit: (fixCondition: MachineFixedCondition) => void;
};

export const getColumnsFixCondition = ({
  t,
  onEdit,
}: GetColumnsOptsFixCondition): Column[] => {
  const columns: Column[] = [
    {
      key: "condition",
      name: t("Condition"),
      fieldName: "condition",
      minWidth: 150,
      onRender: ({ fixedCondition }) => camelCaseToSpacedString(fixedCondition),
    },
    {
      key: "statusDate",
      name: t("Status Date"),
      fieldName: "statusDate",
      minWidth: 150,
      onRender: ({ statusDate }: MachineFixedCondition) =>
        statusDate && format(new Date(statusDate)),
    },
    {
      key: "useFixedCondition",
      name: t("Fix Condition"),
      fieldName: "useFixedCondition",
      minWidth: 150,
    },
    {
      key: "fixedConditionComment",
      name: t("Comment"),
      fieldName: "fixedConditionComment",
      minWidth: 300,
    },
    {
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (condition: MachineFixedCondition) => (
        <div style={{ display: "flex" }} key={"action Fix condition"}>
          <TooltipHost
            key={condition.id + "actionFixCondition"}
            content={t("Edit")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(condition)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    },
  ];

  return columns;
};

export const getColumnsSignalEvent = (t): Column[] => {
  const columns: Column[] = [
    {
      key: "configName",
      name: t("Config Name"),
      fieldName: "configName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dataFrom",
      name: t("Data From"),
      fieldName: "dataFrom",
      minWidth: 150,
      isSortable: true,
      onRender: ({ dataFrom }: ResponseSimplifiedEvent) =>
        dataFrom && format(new Date(dataFrom)),
    },
    {
      key: "dataUntil",
      name: t("Data Until"),
      fieldName: "dataUntil",
      minWidth: 150,
      isSortable: true,
      onRender: ({ dataUntil }: ResponseSimplifiedEvent) =>
        dataUntil && format(new Date(dataUntil)),
    },
    {
      key: "source",
      name: t("Source"),
      fieldName: "source",
      minWidth: 150,
      isSortable: true,
    },
  ];

  return columns;
};

type GetColumnsOptsSensors = {
  t: any;
  hasActions: boolean;
  onEdit: (sensor: Sensor) => void;
  onDelete?: (sensorId: string) => void;
  noLink?: boolean;
};

export const getColumnsSensor = ({
  t,
  hasActions,
  onEdit,
  onDelete,
  noLink,
}: GetColumnsOptsSensors): Column[] => {
  const columns: Column[] = [
    {
      key: "sensorNo",
      name: t("Sensor No"),
      fieldName: "sensorNo",
      minWidth: 150,
      isSortable: true,
      onRender: ({ sensorNo, machineId }: Sensor) => {
        const nameLink = (
          <Link
            underline
            href={"/machines/" + machineId + "/sensor/" + sensorNo}
            style={{ fontSize: 13 }}
          >
            {sensorNo}
          </Link>
        );
        const nameNoLink = <Text style={{ fontSize: 13 }}>{sensorNo}</Text>;
        return noLink ? nameNoLink : nameLink;
      },
    },
    {
      key: "sensorSerialNo",
      name: t("Sensor Serial No"),
      fieldName: "sensorSerialNo",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "mountingType",
      name: t("Mounting Type"),
      fieldName: "mountingType",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "mountingDirection",
      name: t("Mounting Direction"),
      fieldName: "mountingDirection",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "sensorDescription",
      name: t("Sensor Description"),
      fieldName: "sensorDescription",
      minWidth: 150,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (sensor: Sensor) => (
        <div style={{ display: "flex" }} key={sensor.id + "actions"}>
          <TooltipHost
            key={sensor.id + "actions0"}
            content={t("Edit")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(sensor)}
              style={iconStyle}
            />
          </TooltipHost>
          {onDelete && (
            <TooltipHost
              key={sensor.id + "actions1"}
              content={t("Delete")}
              styles={{ root: { display: "flex" } }}
            >
              <Icon
                iconName="Delete"
                onClick={() => onDelete(sensor.id)}
                style={iconStyle}
              />
            </TooltipHost>
          )}
        </div>
      ),
    });
  }

  return columns;
};

type GetColumnsOptsSignals = {
  t: any;
  onEdit: (signal: ResponseSimplifiedSignal) => void;
  onDelete?: (signalId: string) => void;
  onEditCondition: (signalId: string, condition: SignalCondition) => void;
  noLink?: boolean;
  metaDataContributor: boolean;
};

export const getColumnsSignal = ({
  t,
  onEdit,
  onDelete,
  onEditCondition,
  noLink,
  metaDataContributor,
}: GetColumnsOptsSignals): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 150,
      isSortable: true,
      onRender: ({
        name,
        id,
        machineId,
        sensorNo,
        dataType,
      }: ResponseSimplifiedSignal) => {
        const nameLink = (
          <Link
            underline
            href={
              dataType === DataTypeSignals.Raw
                ? "/raw-data?id="
                : "/trend-view?id=" + machineId + "&signal=" + id
            }
            style={{ fontSize: 13 }}
          >
            {name}
          </Link>
        );
        const nameNoLink = <Text style={{ fontSize: 13 }}>{name}</Text>;
        return noLink ? nameNoLink : nameLink;
      },
    },
    {
      key: "sensorSerialNo",
      name: t("Sensor Serial No"),
      fieldName: "sensorSerialNo",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dataType",
      name: t("Data Type"),
      fieldName: "dataType",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dataFrom",
      name: t("Data From"),
      fieldName: "dataFrom",
      minWidth: 150,
      isSortable: true,
      onRender: ({ dataFrom }: ResponseSimplifiedSignal) =>
        dataFrom && format(new Date(dataFrom), "Y-MM-dd HH:mm:ss"),
    },
    {
      key: "dataUntil",
      name: t("Data Until"),
      fieldName: "dataUntil",
      minWidth: 150,
      isSortable: true,
      onRender: ({ dataUntil }: ResponseSimplifiedSignal) =>
        dataUntil && format(new Date(dataUntil), "Y-MM-dd HH:mm:ss"),
    },
    {
      key: "unit",
      name: t("unit"),
      fieldName: "unit",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "configName",
      name: t("Config Name"),
      fieldName: "configName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "sensorNo",
      name: t("Sensor No"),
      fieldName: "sensorNo",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "signalDescription",
      name: t("Signal Description"),
      fieldName: "signalDescription",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "signalGroup",
      name: t("Signal Group"),
      fieldName: "signalGroup",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "signalType",
      name: t("Signal Type"),
      fieldName: "signalType",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "feature",
      name: t("Feature"),
      fieldName: "feature",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "condition",
      name: t("Condition"),
      fieldName: "condition",
      minWidth: 150,
      isSortable: true,
      onRender: ({ condition }) =>
        condition && camelCaseToSpacedString(condition),
    },
  ];

  if (metaDataContributor)
    columns.push(
      {
        key: "sampleRateInHz",
        name: t("Sample Rate In Hz"),
        fieldName: "sampleRateInHz",
        minWidth: 150,
        isSortable: true,
      },
      {
        key: "actions",
        name: t("Actions"),
        fieldName: "actions",
        minWidth: 100,
        isSortable: false,
        isExportable: false,
        onRender: (signal: ResponseSimplifiedSignal) => (
          <div style={{ display: "flex" }} key={signal.id + "actions"}>
            <TooltipHost
              key={signal.id + "actions00"}
              content={t("Edit condition")}
              styles={{ root: { display: "flex" } }}
            >
              <Icon
                iconName="InsertSignatureLine"
                onClick={() => onEditCondition(signal.id, signal.condition)}
                style={iconStyle}
              />
            </TooltipHost>
            {/*<TooltipHost
              key={signal.id + "actions0"}
              content={"Edit"}
              styles={{ root: { display: "flex" } }}
            >
              <Icon
                iconName="Edit"
                onClick={() => onEdit(signal)}
                style={iconStyle}
              />
            </TooltipHost>
             {onDelete && (
              <TooltipHost
                key={signal.id + "actions1"}
                content={"Delete"}
                styles={{ root: { display: "flex" } }}
              >
                <Icon
                  iconName="Delete"
                  onClick={() => onDelete(signal.id)}
                  style={iconStyle}
                />
              </TooltipHost>
            )} */}
          </div>
        ),
      }
    );
  return columns;
};

type GetColumnsOptsImages = {
  t: any;
  handleDownloadClick: (image: Image) => void;
  loadingStates: {
    [key: string]: boolean;
  };
};

export const getColumnsImages = ({
  t,
  handleDownloadClick,
  loadingStates,
}: GetColumnsOptsImages): Column[] => {
  const columns: Column[] = [
    {
      key: "cfgName",
      name: t("Config Name"),
      fieldName: "cfgName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "fileName",
      name: t("File Name"),
      fieldName: "fileName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "md5Hash",
      name: t("Md5 Hash"),
      fieldName: "md5Hash",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "size",
      name: t("Size"),
      fieldName: "size",
      minWidth: 150,
      isSortable: true,
      onRender: ({ size }: Image) => formatSize(size),
    },
    {
      key: "creationTime",
      name: t("Creation Time"),
      fieldName: "creationTime",
      minWidth: 150,
      isSortable: true,
      onRender: ({ creationTime }: Image) =>
        creationTime && format(new Date(creationTime)),
    },
    {
      key: "postProcessedTime",
      name: t("Post Processed Time"),
      fieldName: "postProcessedTime",
      minWidth: 150,
      isSortable: true,
      onRender: ({ postProcessedTime }: Image) =>
        postProcessedTime && format(new Date(postProcessedTime)),
    },
    {
      key: "dataFrom",
      name: t("Data From"),
      fieldName: "dataFrom",
      minWidth: 150,
      isSortable: true,
      onRender: ({ dataFrom }: Image) => dataFrom && format(new Date(dataFrom)),
    },
    {
      key: "dataUntil",
      name: t("Data Until"),
      fieldName: "dataUntil",
      minWidth: 150,
      isSortable: true,
      onRender: ({ dataUntil }: Image) =>
        dataUntil && format(new Date(dataUntil)),
    },
    {
      key: "fastTrendDataProcessDuration",
      name: t("Fast Trend Data Process Duration"),
      fieldName: "fastTrendDataProcessDuration",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "fastTrendDataProcessed",
      name: t("Fast Trend Data Processed"),
      fieldName: "fastTrendDataProcessed",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "fastTrendDataProcessErrorMessage",
      name: t("Fast Trend Data Process Error Message"),
      fieldName: "fastTrendDataProcessErrorMessage",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "configurationProcessDuration",
      name: t("Configuration Process Duration"),
      fieldName: "configurationProcessDuration",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "configurationProcessed",
      name: t("Configuration Processed"),
      fieldName: "configurationProcessed",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "configurationProcessErrorMessage",
      name: t("Configuration Process Error Message"),
      fieldName: "configurationProcessErrorMessage",
      minWidth: 150,
      isSortable: true,
    },

    {
      key: "eventDataProcessDuration",
      name: t("Event Data Process Duration"),
      fieldName: "eventDataProcessDuration",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "eventDataProcessed",
      name: t("Event Data Processed"),
      fieldName: "eventDataProcessed",
      minWidth: 150,
      isSortable: true,
    },

    {
      key: "eventDataProcessErrorMessage",
      name: t("Event Data Process Error Message"),
      fieldName: "eventDataProcessErrorMessage",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "overallDuration",
      name: t("Overall Duration"),
      fieldName: "overallDuration",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "isCompressed",
      name: t("Is Compressed"),
      fieldName: "isCompressed",
      minWidth: 150,
      isSortable: true,
    },

    {
      key: "processed",
      name: t("Processed"),
      fieldName: "processed",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "processingDuration",
      name: t("Processing Duration"),
      fieldName: "processingDuration",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "shortDataProcessDuration",
      name: t("Short Data Process Duration"),
      fieldName: "shortDataProcessDuration",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "shortDataProcessed",
      name: t("Short Data Processed"),
      fieldName: "shortDataProcessed",
      minWidth: 150,
      isSortable: true,
    },

    {
      key: "shortDataProcessErrorMessage",
      name: t("Short Data Process Error Message"),
      fieldName: "shortDataProcessErrorMessage",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "trendDataProcessDuration",
      name: t("Trend Data Process Duration"),
      fieldName: "trendDataProcessDuration",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "trendDataProcessed",
      name: t("Trend Data Processed"),
      fieldName: "trendDataProcessed",
      minWidth: 150,
      isSortable: true,
    },

    {
      key: "trendDataProcessErrorMessage",
      name: t("Trend Data Process Error Message"),
      fieldName: "trendDataProcessErrorMessage",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (image: Image) => (
        <div style={{ display: "flex" }} key={image.id + "actions"}>
          <TooltipHost
            key={image.id + "actions00"}
            content={t("Download")}
            styles={{ root: { display: "flex" } }}
          >
            {loadingStates[image.id] ? (
              <Spinner size={SpinnerSize.small}></Spinner>
            ) : (
              <Icon
                iconName="Download"
                onClick={() => handleDownloadClick(image)}
                style={iconStyle}
              />
            )}
          </TooltipHost>
        </div>
      ),
    },
  ];

  return columns;
};

type GetColumnsOptsConfigs = {
  t: any;
  onEdit: (config: Configuration) => void;
};

export const getColumnsConfigs = ({
  t,
  onEdit,
}: GetColumnsOptsConfigs): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "version",
      name: t("Version"),
      fieldName: "version",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "operator",
      name: t("Operator"),
      fieldName: "operator",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "time",
      name: t("Time"),
      fieldName: "time",
      minWidth: 150,
      isSortable: true,
      onRender: ({ time }: Configuration) => time && format(new Date(time)),
    },
    {
      key: "boxType",
      name: t("Box Type"),
      fieldName: "boxType",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dalogType",
      name: t("Dalog Type"),
      fieldName: "dalogType",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "md5Hash",
      name: t("Md5 Hash"),
      fieldName: "md5Hash",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "isMatCfg",
      name: t("Is Mat Cfg"),
      fieldName: "isMatCfg",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "isActive",
      name: t("Is Active"),
      fieldName: "isActive",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "maxDataAgeInDaysWarning",
      name: t("Max Data Age In Days Warning"),
      fieldName: "maxDataAgeInDaysWarning",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "maxDataAgeInDaysError",
      name: t("Max Data Age In Days Error"),
      fieldName: "maxDataAgeInDaysError",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (config: Configuration) => (
        <div style={{ display: "flex" }} key={"actions"}>
          <TooltipHost
            key={"actions00"}
            content={t("Edit")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(config)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    },
  ];

  return columns;
};

export const getColumns = (t): Column[] => [
  {
    key: "name",
    name: t("Name"),
    fieldName: "name",
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: ({ name }) => {
      // TODO: should we use Link from react-router-dom?
      return (
        <Link underline href="#" style={{ fontSize: 13 }}>
          {name}
        </Link>
      );
    },
  },
  {
    key: "size",
    name: t("Size"),
    fieldName: "size",
    minWidth: 100,
    isSortable: true,
    onRender: ({ size, isValid }) => {
      return (
        <span
          style={{ fontSize: 13, color: isValid ? "currentColor" : "#ff6a66" }}
        >
          {isValid ? formatFileSize(size) : "Too large"}
        </span>
      );
    },
  },
];

export const getColumnsListQueue = (t): Column[] => {
  const columns: Column[] = [
    {
      key: "fileName",
      name: t("File Name"),
      fieldName: "fileName",
      minWidth: 150,
    },
    {
      key: "cfgName",
      name: t("Config Name"),
      fieldName: "cfgName",
      minWidth: 150,
    },
    {
      key: "timeStamp",
      name: t("Time Stamp"),
      fieldName: "timeStamp",
      minWidth: 150,
    },
    {
      key: "machineId",
      name: t("Machine Id"),
      fieldName: "machineId",
      minWidth: 150,
    },
    {
      key: "md5Hash",
      name: t("MD5 Hash"),
      fieldName: "md5Hash",
      minWidth: 150,
    },
  ];

  return columns;
};

export const getColumnsStandStill = (t): Column[] => {
  const columns: Column[] = [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 150,
    },
    {
      key: "dataType",
      name: t("Data Type"),
      fieldName: "dataType",
      minWidth: 150,
    },
    {
      key: "signalDescription",
      name: t("Signal Description"),
      fieldName: "signalDescription",
      minWidth: 150,
    },
    {
      key: "signalType",
      name: t("Signal Type"),
      fieldName: "signalType",
      minWidth: 150,
    },
    {
      key: "thresholdLow",
      name: t("Low"),
      fieldName: "thresholdLow",
      minWidth: 150,
    },
    {
      key: "thresholdHigh",
      name: t("High"),
      fieldName: "thresholdHigh",
      minWidth: 150,
    },
  ];

  return columns;
};

export type AlertLevelPlus = AlertLevel & {
  signalName: string;
  sensorSerialNo: string;
  sensorNo: string;
};

type GetColumnsOptsAlertLevelsOnDet = {
  t: any;
  hasActions: boolean;
  onEdit: (alertLevel: AlertLevel) => void;
  onDelete: (alertLevelId: string) => void;
};

export const getColumnsAlertLevelsOnDet = ({
  t,
  hasActions,
  onEdit,
  onDelete,
}: GetColumnsOptsAlertLevelsOnDet): Column[] => {
  const columns: Column[] = [
    {
      key: "faultyLow",
      name: t("Faulty Low"),
      fieldName: "faultyLow",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "faultyHigh",
      name: t("Faulty High"),
      fieldName: "faultyHigh",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "direction",
      name: t("Direction"),
      fieldName: "direction",
      minWidth: 150,
      isSortable: true,
      onRender: ({ direction }) => camelCaseToSpacedString(direction),
    },
    {
      key: "alertLow",
      name: t("Alert Low"),
      fieldName: "alertLow",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "alertHigh",
      name: t("Alert High"),
      fieldName: "alertHigh",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "indicationLow",
      name: t("Indication Low"),
      fieldName: "indicationLow",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "indicationHigh",
      name: t("Indication High"),
      fieldName: "indicationHigh",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dangerLow",
      name: t("Danger Low"),
      fieldName: "dangerLow",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dangerHigh",
      name: t("Danger High"),
      fieldName: "dangerHigh",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "notificationType",
      name: t("Notification Type"),
      fieldName: "notificationType",
      minWidth: 150,
      isSortable: true,
      onRender: ({ notificationType }) =>
        camelCaseToSpacedString(notificationType),
    },
    {
      key: "conditionManualValue",
      name: t("ConditionManual Value"),
      fieldName: "conditionManualValue",
      minWidth: 150,
      isSortable: true,
      onRender: ({ conditionManualValue }) =>
        camelCaseToSpacedString(conditionManualValue),
    },
    {
      key: "conditionManualEnabled",
      name: t("Condition Manual Enabled"),
      fieldName: "conditionManualEnabled",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "conditionGroup",
      name: t("Condition Group"),
      fieldName: "conditionGroup",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "conditionCluster",
      name: t("Condition Cluster"),
      fieldName: "conditionCluster",
      minWidth: 150,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (alertLevel: AlertLevel) => (
        <div style={{ display: "flex" }} key={alertLevel.id + "actions"}>
          <TooltipHost
            key={alertLevel.id + "actions0"}
            content={t("Edit")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(alertLevel)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={alertLevel.id + "actions1"}
            content={t("Delete")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(alertLevel.id)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

type GetColumnsOptsAlertLevels = {
  t: any;
  hasActions: boolean;
  onEdit: (alertLevel: AlertLevelPlus) => void;
  onDelete: (alertLevelId: string) => void;
};

export const getColumnsAlertLevels = ({
  t,
  hasActions,
  onEdit,
  onDelete,
}: GetColumnsOptsAlertLevels): Column[] => {
  const columns: Column[] = [
    {
      key: "signalName",
      name: t("Signal Name"),
      fieldName: "signalName",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "sensorSerialNo",
      name: t("Sensor Serial No"),
      fieldName: "sensorSerialNo",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "sensorNo",
      name: t("Sensor No"),
      fieldName: "sensorNo",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "faultyLow",
      name: t("Faulty Low"),
      fieldName: "faultyLow",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "faultyHigh",
      name: t("Faulty High"),
      fieldName: "faultyHigh",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "direction",
      name: t("Direction"),
      fieldName: "direction",
      minWidth: 150,
      isSortable: true,
      onRender: ({ direction }) => camelCaseToSpacedString(direction),
    },
    {
      key: "alertLow",
      name: t("Alert Low"),
      fieldName: "alertLow",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "alertHigh",
      name: t("Alert High"),
      fieldName: "alertHigh",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "indicationLow",
      name: t("Indication Low"),
      fieldName: "indicationLow",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "indicationHigh",
      name: t("Indication High"),
      fieldName: "indicationHigh",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dangerLow",
      name: t("Danger Low"),
      fieldName: "dangerLow",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "dangerHigh",
      name: t("Danger High"),
      fieldName: "dangerHigh",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "notificationType",
      name: t("Notification Type"),
      fieldName: "notificationType",
      minWidth: 150,
      isSortable: true,
      onRender: ({ notificationType }) =>
        camelCaseToSpacedString(notificationType),
    },
    {
      key: "conditionManualValue",
      name: t("ConditionManual Value"),
      fieldName: "conditionManualValue",
      minWidth: 150,
      isSortable: true,
      onRender: ({ conditionManualValue }) =>
        camelCaseToSpacedString(conditionManualValue),
    },
    {
      key: "conditionManualEnabled",
      name: t("Condition Manual Enabled"),
      fieldName: "conditionManualEnabled",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "conditionGroup",
      name: t("Condition Group"),
      fieldName: "conditionGroup",
      minWidth: 150,
      isSortable: true,
    },
    {
      key: "conditionCluster",
      name: t("Condition Cluster"),
      fieldName: "conditionCluster",
      minWidth: 150,
      isSortable: true,
    },
  ];

  if (hasActions) {
    columns.push({
      key: "actions",
      name: t("Actions"),
      fieldName: "actions",
      minWidth: 100,
      isSortable: false,
      isExportable: false,
      onRender: (alertLevel: AlertLevelPlus) => (
        <div style={{ display: "flex" }} key={alertLevel.id + "actions"}>
          <TooltipHost
            key={alertLevel.id + "actions0"}
            content={t("Edit")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Edit"
              onClick={() => onEdit(alertLevel)}
              style={iconStyle}
            />
          </TooltipHost>
          <TooltipHost
            key={alertLevel.id + "actions1"}
            content={t("Delete")}
            styles={{ root: { display: "flex" } }}
          >
            <Icon
              iconName="Delete"
              onClick={() => onDelete(alertLevel.id)}
              style={iconStyle}
            />
          </TooltipHost>
        </div>
      ),
    });
  }

  return columns;
};

type BarItemsProps = {
  t: any;
  title: string;
  onChange?: (files: AcceptedFile[]) => void;
  imagesExts?: boolean;
  xlsxToo?: boolean;
  addButton?: boolean;
  onAdd?: () => void;
  onDelete?: () => void;
  onAddGroup?: () => void;
  onEditBulk?: () => void;
  disableBEdit?: boolean;
  exportable?: boolean;
  onExport?: () => void;
  onRefresh?: () => void;
  onQueue?: () => void;
  importButton?: boolean;
  xlsxOnly?: boolean;
};

export const getCommandBarItems = ({
  t,
  title,
  onChange,
  imagesExts,
  xlsxToo,
  addButton,
  importButton,
  onAdd,
  onDelete,
  onEditBulk,
  disableBEdit,
  exportable,
  onExport,
  onRefresh,
  onQueue,
  xlsxOnly,
}: BarItemsProps): CommandBarItemProps[] => {
  const commandBarItems: CommandBarItemProps[] = [];

  if (importButton)
    commandBarItems.push({
      key: "import",
      type: CommandBarItemType.Upload,
      text: t("Import"),
      onRenderProps: {
        maxSize: 100000000,
        accept: imagesExts
          ? ".sav,.zip,.gzip"
          : xlsxToo
            ? ".xlsx,.csv"
            : xlsxOnly
              ? ".xlsx"
              : ".csv",
        multiple: false,
        onChange,
      },
    });

  if (exportable)
    commandBarItems.push({
      key: "export",
      type: CommandBarItemType.Button,
      text: t("Export"),
      iconProps: { iconName: "Export" },
      onClick: onExport,
    });

  if (addButton)
    commandBarItems.push({
      key: "add",
      type: CommandBarItemType.Button,
      text: t("Add Group/Item"),
      iconProps: { iconName: "Add" },
      onClick: onAdd,
    });

  if (onRefresh)
    commandBarItems.push({
      key: "refresh",
      text: t("Refresh"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Refresh" },
      onClick: onRefresh,
    });

  if (onQueue)
    commandBarItems.push({
      key: "onQueue",
      text: t("Queue"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "BulletedList2Mirrored" },
      onClick: onQueue,
    });

  if (onEditBulk)
    commandBarItems.push({
      key: "edit",
      text: t("Bulk Edit"),
      type: CommandBarItemType.Button,
      disabled: disableBEdit,
      iconProps: { iconName: "ColumnVerticalSectionEdit" },
      onClick: onEditBulk,
    });

  if (onDelete)
    commandBarItems.push({
      key: "delete",
      type: CommandBarItemType.Button,
      text: t("Delete All"),
      iconProps: { iconName: "Delete" },
      onClick: onDelete,
    });

  return commandBarItems;
};
