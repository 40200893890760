import type { IDialogProps } from "@fluentui/react";
import { DefaultButton, DialogFooter, DialogType } from "@fluentui/react";

import BaseDialog, { DialogSize } from "../../../../Components/common/Dialog";

import { useTranslation } from "react-i18next";

type ErrorDialogProps = IDialogProps & {
  content: string;
  onDismiss: () => void;
};

export const ErrorDialog = ({
  content,
  onDismiss,
  ...rest
}: ErrorDialogProps) => {
  const { t } = useTranslation();

  return (
    <BaseDialog
      {...rest}
      dialogContentProps={{ title: t("Attention!"), type: DialogType.close }}
      size={DialogSize.M}
      onDismiss={onDismiss}
    >
      <span dangerouslySetInnerHTML={{ __html: content }} />
      <DialogFooter>
        <DefaultButton text={t("Close")} onClick={onDismiss} />
      </DialogFooter>
    </BaseDialog>
  );
};
