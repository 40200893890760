/* eslint-disable react-hooks/exhaustive-deps */
import {
  DefaultEffects,
  IButtonStyles,
  Icon,
  IconButton,
  IIconStyles,
  IStackStyles,
  IStyle,
  ITextStyles,
  mergeStyles,
  MotionAnimations,
  MotionDurations,
  MotionTimings,
  Stack,
  Text,
} from "@fluentui/react";
import { HTMLAttributes, useMemo, useState } from "react";
import { theme } from "../../theme";
import { useTranslation } from "react-i18next";

type AppNotificationMessageProps = Omit<
  HTMLAttributes<HTMLElement>,
  "role" | "className" | "onAnimationEnd"
> & {
  title: string;
  message: string;
  visited: boolean;
  expired: boolean;
  onVisited: () => void;
};

const containerStyle: IStyle = {
  position: "fixed",
  boxSizing: "border-box",
  top: "0.5em",
  right: "0.5em",
  width: "25em",
  boxShadow: DefaultEffects.elevation64,
  background: "white",
  zIndex: 999,
};

const contentStyles: IStackStyles = {
  root: {
    position: "relative",
    padding: "1em",
    boxSizing: "border-box",
    rowGap: "0.5em",
  },
};

const btnCloseStyles: IButtonStyles = {
  root: {
    position: "absolute",
    top: "0em",
    right: "0em",
  },
  icon: {
    fontSize: "12px",
  },
};

const titleStyles: ITextStyles = {
  root: {
    fontWeight: 600,
  },
};

const iconStyles: IIconStyles = {
  root: {
    fontSize: "20px",
    color: theme.palette?.themePrimary,
  },
};

export const AppNotificationMessage = ({
  title,
  message,
  visited,
  expired,
  onVisited: onMessageSeen,
  ...rest
}: AppNotificationMessageProps) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(!visited);
  const [isClosing, setIsClosing] = useState<boolean>(!isVisible);
  const className: string = useMemo(() => {
    const innerStyle: IStyle = {
      display: isVisible && !expired ? "flex" : "none",
      animation: !isClosing
        ? MotionAnimations.slideLeftIn
        : MotionAnimations.slideRightOut,
      animationDuration: !isClosing
        ? MotionDurations.duration4
        : MotionDurations.duration2,
      animationTimingFunction: !isClosing
        ? MotionTimings.decelerate
        : MotionTimings.accelerate,
      animationFillMode: "forwards",
    };

    return mergeStyles(containerStyle, innerStyle);
  }, [isVisible, isClosing, expired]);

  // Handlers
  const onAnimationEnd = () => {
    if (!isClosing) {
      return;
    }

    setIsVisible(false);
    onMessageSeen?.();
  };

  return (
    <article
      {...rest}
      role="dialog"
      className={className}
      onAnimationEnd={onAnimationEnd}
    >
      <Stack role="presentation" styles={contentStyles}>
        <Stack
          horizontal
          verticalAlign="center"
          styles={{ root: { columnGap: "1em" } }}
        >
          <Icon iconName="InfoSolid" styles={iconStyles} />
          <Text variant="medium" as="h1" styles={titleStyles}>
            {title}
          </Text>
        </Stack>
        <Text variant="medium" as="p">
          {message}
        </Text>
        <IconButton
          iconProps={{ iconName: "ChromeClose" }}
          title={t("Close")}
          styles={btnCloseStyles}
          onClick={() => setIsClosing(true)}
        />
      </Stack>
    </article>
  );
};
