import { z } from "zod";

import type { FormItemProps } from "../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../common/Form/FormItems/helpers";
import { t } from "i18next";

export const selectedCalculationsOptions = [
  "CalcId0",
  "CalcId1",
  "CalcId2",
  "CalcId3",
  "CalcId4",
  "CalcId5",
  "CalcId6",
  "CalcId7",
  "CalcId8",
  "CalcId9",
  "CalcId10",
  "CalcId11",
  "CalcId12",
  "CalcId13",
  "CalcId14",
];

export const generateDefaultMeasurementSettingsValues = (
  activeMeasurements: any
) => {
  return {
    measurement: activeMeasurements.map(() => {
      return {
        gRange: "",
        dataAxis: "",
        dataSampleCount: "",
        selectedCalculations: [],
      };
    }),
  };
};

export const measurementSchema = z.object({
  measurement: z.array(
    z.object({
      gRange: z.any(),
      dataAxis: z.any(),
      dataSampleCount: z.any(),
      selectedCalculations: z.any(),
    })
  ),
});

export const measurementRangesOptions = [
  { value: "TwoG", text: "+/- 2g" },
  { value: "FourG", text: "+/- 4g" },
  { value: "EightG", text: "+/- 8g" },
  { value: "SixteenG", text: "+/- 16g" },
];

export const measurementRangeFormFields: any = [
  {
    name: "gRange",
    type: FormItemType.Radio,
    options: measurementRangesOptions,
  },
];

export const measurementAxesOptions = [
  { value: "All", text: t("All") },
  { value: "X", text: "X-Dir" },
  // { value: 'Y', text: 'Y-dir' },
  // { value: 'Z', text: 'Z-dir' },
];

export const measurementAxesFormField: any = [
  {
    name: "dataAxis",
    type: FormItemType.Radio,
    options: measurementAxesOptions,
  },
];

export const measurementSampleFormField: FormItemProps[] = [
  {
    name: "dataSampleCount",
    type: FormItemType.TextField,
  },
];

export const measurementSelectedCalculationsFormField: FormItemProps[] = [
  {
    name: "selectedCalculations",
    placeholder: t("Select calculations"),
    type: FormItemType.Dropdown,
    multiSelect: true,
    options: selectedCalculationsOptions.map((item) => ({
      key: item,
      text: item,
    })),
    styles: { root: { width: 300 } },
    dropdownWidth: 300,
  },
];
