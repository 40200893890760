import { Stack } from "@fluentui/react";
import { groupBy } from "lodash-es";
import React, { useCallback, useEffect, useMemo } from "react";
import { treeHandlers } from "react-hyper-tree";

import { prefetchMachineList, useSectionsStore } from "../../../Hooks";

import {
  TrendViewControls,
  TrendViewSelectSignals,
  useControlsStore,
  useSelectedSignalsStore,
} from "../../../modules/analysis-trend-view";
import { TrendViewPlaceholder } from "../../../modules/analysis-trend-view/components/TrendViewPlaceholder";
import TrendGroupingEnum from "../../../modules/analysis-trend-view/constants/TrendGroupingEnum";
import SignalsSidebar from "../../../modules/common/components/SignalsSidebar";
import { queryClient } from "../../../modules/core";
import Pagination from "../../common/Pagination";
import { Spin } from "../../common/Spin";
import { useTranslation } from "react-i18next";

const TrendViewSection = React.lazy(
  () =>
    import(
      "../../../modules/analysis-trend-view/components/TrendViewSection/TrendViewSection"
    )
);

const TrendViewGroupedSection = React.lazy(
  () =>
    import(
      "../../../modules/analysis-trend-view/components/TrendViewGroupedSection/TrendViewGroupedSection"
    )
);

export const SuspenseSpin = () => {
  const { t } = useTranslation();

  return <Spin text={t("Loading layout ...")} style={{ height: 575 }} />;
};

type TrendsProps = {
  marginTop?: number;
};

const Trends = ({ marginTop }: TrendsProps) => {
  const { t } = useTranslation();
  const { selectedSignals, clearSelectedSignals } = useSelectedSignalsStore(
    (state) => ({
      selectedSignals: state.selectedSignals,
      clearSelectedSignals: state.clearSelectedSignals,
    })
  );

  const { trendsGrouping, setConnectedTimeRange, changeReferenceSignal } =
    useControlsStore((state) => ({
      trendsGrouping: state.trendsGrouping,
      setConnectedTimeRange: state.setConnectedTimeRange,
      changeReferenceSignal: state.changeReferenceSignal,
    }));

  const { isAnyMaximized } = useSectionsStore((store) => ({
    domIds: store.domIds,
    isAnyMaximized: store.isAnyMaximized,
  }));

  // const isExpanded = useSidebarStore((store) => store.isExpanded);

  useEffect(() => {
    prefetchMachineList();
    queryClient.setQueryData(["signals-list"], []);

    clearSelectedSignals();
  }, []);

  useEffect(() => {
    // We can't compare 0 or 1 signal
    if (selectedSignals.length < 2) {
      setConnectedTimeRange(null);
    }

    // Clear ref signal when no selected signals
    if (selectedSignals.length === 0) {
      changeReferenceSignal(undefined);
    }
  }, [selectedSignals.length]);

  const signalsGroupedByMachine = useMemo(() => {
    if (trendsGrouping?.key === TrendGroupingEnum.GROUP_BY_MACHINE) {
      return groupBy(selectedSignals, ({ machineId }) => machineId);
    }

    return {};
  }, [selectedSignals, trendsGrouping?.key]);

  const clearSignals = useCallback(() => {
    clearSelectedSignals();
    selectedSignals.forEach((signal) => {
      treeHandlers.trees["trend-view-signal-tree"].handlers.setSelected(
        signal.id,
        false
      );
    });
  }, [selectedSignals]);

  const noSignalsSelected = selectedSignals.length === 0;

  return (
    <Stack
      horizontal
      style={{
        flexGrow: 1,
      }}
    >
      <div style={{ width: "100%" }}>
        <TrendViewControls title={t("Trend View")} />

        <Stack horizontal>
          <SignalsSidebar
            marginTop={marginTop}
            isClearDisabled={noSignalsSelected}
            clearSignals={clearSignals}
          >
            <TrendViewSelectSignals />
          </SignalsSidebar>
          <div
            style={{
              height: `calc(100vh - 62px - ${marginTop}px)`,
              width: "100%",
              overflowY: "auto",
              background: "#FAF9F8",
              transition: "all 0.2s ease",
              padding: 5,
            }}
          >
            {selectedSignals.length === 0 && <TrendViewPlaceholder />}

            {trendsGrouping?.key === TrendGroupingEnum.SEPARATED && (
              <Pagination.Items
                hidePerPage={true}
                perPage={3}
                styles={{
                  root: {
                    marginBottom: "1rem",
                    display: isAnyMaximized() ? "none" : "flex",
                  },
                }}
                items={selectedSignals.map((selectedSignal) => ({
                  key: selectedSignal.id!,
                  Element: (
                    <React.Suspense fallback={<SuspenseSpin />}>
                      <TrendViewSection signal={selectedSignal} />
                    </React.Suspense>
                  ),
                }))}
              />
            )}

            {trendsGrouping?.key === TrendGroupingEnum.GROUP_BY_MACHINE && (
              <Pagination.Items
                hidePerPage={true}
                perPage={3}
                styles={{
                  root: {
                    marginBottom: "1rem",
                    display: isAnyMaximized() ? "none" : "flex",
                  },
                }}
                items={Object.entries(signalsGroupedByMachine).map(
                  ([machineId, machineSignals]) => ({
                    key: machineId,
                    Element: (
                      <React.Suspense fallback={<SuspenseSpin />}>
                        <TrendViewGroupedSection
                          signals={machineSignals}
                          machineId={machineId}
                          chartStyles={{ chart: { height: "450px" } }}
                        />
                      </React.Suspense>
                    ),
                  })
                )}
              />
            )}

            {trendsGrouping?.key === TrendGroupingEnum.GROUP_EVERYTHING &&
              selectedSignals.length > 0 && (
                <React.Suspense fallback={<SuspenseSpin />}>
                  <TrendViewGroupedSection
                    signals={selectedSignals}
                    machineId="all"
                    chartStyles={{ chart: { height: "450px" } }}
                  />
                </React.Suspense>
              )}
          </div>
        </Stack>
      </div>
    </Stack>
  );
};

export default Trends;
