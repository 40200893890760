import { Stack } from "@fluentui/react";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo } from "react";
import { treeHandlers } from "react-hyper-tree";

import { prefetchMachineList } from "../../../Hooks";
import {
  HeaderControls,
  SignalsList,
  useSelectedSignalsStore,
} from "../../../modules/analysis-raw-data";
import DataPointsSidebar from "../../../modules/analysis-raw-data/components/DataPointsSidebar";
import PageContent from "../../../modules/analysis-raw-data/components/PageContent";
import { DifferenceProvider } from "../../../modules/analysis-raw-data/components/WaveformChartSection/DifferenceContext";
import useConditionalWrapper from "../../../modules/analysis-raw-data/hooks/useConditionalWrapper";
import useLayoutTypes from "../../../modules/analysis-raw-data/hooks/useLayoutTypes";
import useSelectedDataPointsStore from "../../../modules/analysis-raw-data/hooks/useSelectedDataPointsStore";
import SignalsSidebar from "../../../modules/common/components/SignalsSidebar";
import { queryClient } from "../../../modules/core";

import styles from "./RawDataPage.styles";
import { useTranslation } from "react-i18next";

interface RawDataPageProps {
  marginTop: number;
}
const RawDataPage: React.FC<RawDataPageProps> = React.memo(({ marginTop }) => {
  const { t } = useTranslation();
  const { selectedSignals, clearSelectedSignals } = useSelectedSignalsStore(
    (store) => ({
      selectedSignals: store.selectedSignals,
      clearSelectedSignals: store.clearSelectedSignals,
    })
  );

  const { ConditionalWrapper, conditionalWrapperStyles } =
    useConditionalWrapper({
      selectedSignals,
      marginTop,
    });

  useEffect(() => {
    prefetchMachineList();
    queryClient.setQueryData(["signals-list"], []);
    clearSelectedSignals();
  }, [clearSelectedSignals]);

  const { clearSelectedDataPoints } = useSelectedDataPointsStore((store) => ({
    clearSelectedDataPoints: store.clearSelectedDataPoints,
  }));

  const clearSignals = useCallback(() => {
    clearSelectedSignals();
    clearSelectedDataPoints();
    selectedSignals.forEach(({ id }) => {
      treeHandlers.trees["raw-data-signal-tree"].handlers.setSelected(
        id,
        false
      );
    });
  }, [selectedSignals, clearSelectedSignals, clearSelectedDataPoints]);

  const noSignalsSelected = selectedSignals.length === 0;
  const { isCompareLayout, isWaveformLayout, isTrendLayout } = useLayoutTypes();

  const layoutClassName = useMemo(
    () =>
      classNames({
        "waveform-layout": isWaveformLayout,
        "compare-layout": isCompareLayout,
        "trend-layout": isTrendLayout,
      }),
    [isWaveformLayout, isCompareLayout, isTrendLayout]
  );

  return (
    <Stack horizontal style={styles.contentWrapper}>
      <div style={styles.contentWrapper}>
        <HeaderControls marginTop={marginTop} title={t("Raw Data")} />
        <Stack horizontal className={layoutClassName}>
          <SignalsSidebar
            clearSignals={clearSignals}
            isClearDisabled={noSignalsSelected}
            marginTop={marginTop}
          >
            <SignalsList />
          </SignalsSidebar>
          <DifferenceProvider>
            <ConditionalWrapper {...conditionalWrapperStyles}>
              <PageContent marginTop={marginTop} />
              <DataPointsSidebar />
            </ConditionalWrapper>
          </DifferenceProvider>
        </Stack>
      </div>
    </Stack>
  );
});

RawDataPage.displayName = "RawDataPage";

export default RawDataPage;
