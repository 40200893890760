const MapIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="currentColor"
    {...props}
  >
    <path d="M18.23 3.05702C18.3112 3.0991 18.3793 3.16265 18.427 3.24077C18.4746 3.31888 18.4998 3.40855 18.5 3.50002V13.5C18.5 13.5864 18.4776 13.6714 18.4351 13.7466C18.3925 13.8217 18.3311 13.8846 18.257 13.929L13.257 16.929C13.1817 16.9741 13.0958 16.9986 13.0081 17C12.9203 17.0014 12.8337 16.9797 12.757 16.937L8.247 14.431C8.17381 14.3846 8.08898 14.3597 8.00229 14.3593C7.91559 14.359 7.83056 14.3831 7.757 14.429L7.743 14.437L3.243 16.937C3.16689 16.9794 3.08104 17.0011 2.99396 17C2.90688 16.999 2.82158 16.9752 2.74652 16.931C2.67145 16.8869 2.60922 16.8239 2.56598 16.7483C2.52274 16.6727 2.5 16.5871 2.5 16.5V7.00002C2.49991 6.91959 2.51922 6.84032 2.5563 6.76895C2.59338 6.69757 2.64714 6.63619 2.713 6.59002L7.713 3.09002C7.7971 3.03107 7.8973 2.99945 8 2.99945C8.1027 2.99945 8.20291 3.03107 8.287 3.09002L13 6.39002L17.713 3.09002C17.7881 3.03764 17.876 3.00686 17.9674 3.00104C18.0587 2.99522 18.1499 3.01459 18.231 3.05702H18.23ZM3.5 7.26002V15.65L7.25 13.567C7.32949 13.5197 7.41318 13.4799 7.5 13.448V4.46002L3.5 7.26002ZM8.748 13.566L12.504 15.652L12.5 7.26002L8.5 4.46002V13.45C8.585 13.48 8.668 13.519 8.748 13.566ZM13.504 15.615L17.5 13.217V4.46002L13.5 7.26002L13.504 15.615Z" />
  </svg>
);

export default MapIcon;
