import { FC, useContext, useEffect, useState } from "react";
import { GatewaySubmission } from "../models";
import { SecondPanelContext } from "../SubmissionsDetails";
import { SupportContext } from "../SupportContext";
import DialogSelector from "./DialogSelector";
import { useTranslation } from "react-i18next";
import { TextFieldWithDocuments } from "../TextFieldWithDocuments";

interface GatewaySupportInputProps {
  context: SecondPanelContext;
}

const GatewaySupportInput: FC<GatewaySupportInputProps> = ({ context }) => {
  const { t } = useTranslation();
  const { support } = useContext(SupportContext);

  const [loading, isLoading] = useState(false);
  const [renderGateway, setRenderGateway] = useState(0);

  useEffect(() => {
    if (loading) {
      setTimeout(() => setRenderGateway((prev) => prev + 1), 2000);
      isLoading(false);
    }
  }, [loading]);

  return (
    <>
      <TextFieldWithDocuments
        value={context.initialValues.gat?.serialNumber || ""}
        disabled={context.context !== "Gateway"}
        label={t("Gateway Serial")}
        onChange={(newVal) => {
          const gatNew: GatewaySubmission = {
            serialNumber: newVal.trim(),
            uniqueId: context.initialValues.gat.uniqueId,
            id: context.initialValues.gat.id,
          };
          context.updateFunction(gatNew, context.initialValues.gat.uniqueId);
          isLoading(true);
        }}
        files={context.initialValues.gat?.files}
        fieldName="serialNumber"
      />
      {support && <DialogSelector key={renderGateway} />}
    </>
  );
};

export default GatewaySupportInput;
