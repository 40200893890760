import type { IDropdownOption } from "@fluentui/react";

import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { t } from "i18next";

interface RefreshStore {
  refreshInterval: IDropdownOption | undefined;
  setRefreshInterval: (refreshInterval: IDropdownOption | undefined) => void;
}

const useRefreshStore = createWithEqualityFn<RefreshStore>(
  (set) => ({
    refreshInterval: { key: "off", text: t("Off") },
    setRefreshInterval: (refreshInterval: IDropdownOption | undefined) =>
      set({ refreshInterval }),
  }),
  shallow
);

export default useRefreshStore;
