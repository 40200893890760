/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeStyleSets, Toggle } from "@fluentui/react";
import type { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import classNames from "classnames";
import { format as fnsFormat } from "date-fns";
import React, { useState } from "react";

import PeriodIcon from "../../../../assets/svg/TasksIcon";
import { ErrorDialog } from "../../../../modules/analysis-trend-view/components/TrendViewControls/ErrorDialog";
import { dropdownIconStyle } from "../../../../modules/analysis-trend-view/components/TrendViewControls/TrendView.styles";
import useTrendViewControls from "../../../../modules/analysis-trend-view/components/TrendViewControls/useTrendViewControls";
import {
  MAX_GROUP_THRESHOLD,
  getPeriodOptions,
  PeriodOptionsEnum,
} from "../../../../modules/analysis-trend-view/constants/controlOptions";
import type { ControlsStore } from "../../../../modules/analysis-trend-view/hooks/useControlsStore";
import { FORMAT } from "../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import CustomDateDialog from "../../../../modules/common/components/CustomDateDialog";
import { OperationsTab } from "./OperationsTab";
import OperationCommandBar from "./OperationsTab/OperationCommandBar";
import { ReportsTab } from "./ReportsTab";
import { TasksTab } from "./TasksTab";
import { TrendTab } from "./TrendTab";
import {
  dropdownStyles,
  onRenderLabel,
} from "../../../common/CommandBar/methods";
import { TabMenu } from "../../../common/TabMenu";

import useSummaryStore from "../hooks/useSummaryStore";
import type { SectionCommandBarItem } from "../SectionContainer";
import SectionContainer from "../SectionContainer";

import { useTranslation } from "react-i18next";

const TAB_TITLES = {
  Trend: "Trend",
  Reports: "Reports",
  Tasks: "Tasks",
  Operations: "Operations",
};

type TabTitlesType = "Trend" | "Reports" | "Tasks" | "Operations";

const tabItems = (search: string, t) => [
  {
    key: TAB_TITLES.Trend,
    title: t("Trend"),
    content: <TrendTab />,
  },
  {
    key: TAB_TITLES.Reports,
    title: t("Reports"),
    content: <ReportsTab search={search} />,
  },
  {
    key: TAB_TITLES.Tasks,
    title: t("Tasks"),
    content: <TasksTab search={search} />,
  },
  {
    key: TAB_TITLES.Operations,
    title: t("Operations"),
    content: <OperationsTab />,
    pivotItemProps: {
      className: "operation-tab-pivot",
    },
  },
];

export const DEFAULT_TAB_MAX_HEIGHT = 530;

type CommandBarProps = {
  t: any;
  activeTab: TabTitlesType;
  controlsStore: ControlsStore;
  groupSignals: boolean;
  onPeriodChange: (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any> | undefined,
    index?: number | undefined
  ) => void;
  onGroupSignalsChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean | undefined
  ) => void;
};

const getCommandBarItems = ({
  t,
  activeTab,
  controlsStore,
  groupSignals,
  onPeriodChange,
  onGroupSignalsChange,
}: CommandBarProps): SectionCommandBarItem[] => {
  const trendButtons: SectionCommandBarItem[] = [
    {
      key: "section-trends-period-dropdown",
      onRender: () => (
        <Dropdown
          className={classNames("layout-dropdown secondary-dropdown")}
          styles={mergeStyleSets(dropdownStyles, {
            root: { height: 30, marginLeft: 16 },
          })}
          calloutProps={{ calloutWidth: undefined, calloutMinWidth: 200 }}
          options={getPeriodOptions(t)}
          selectedKey={controlsStore.period?.key || undefined}
          onChange={onPeriodChange}
          onRenderLabel={() =>
            onRenderLabel({
              label: t("Period"),
              icon: <PeriodIcon fill="#2c529f" style={dropdownIconStyle} />,
            })
          }
        />
      ),
    },
    {
      key: "section-trends-average-trend-toggle",
      onRender: () => (
        <Toggle
          inlineLabel
          label={t("Average trend")}
          checked={controlsStore.averageTrend}
          styles={{ root: { marginBottom: 0, marginLeft: 20 } }}
          onChange={(_, checked) => controlsStore.changeAverageTrend(checked)}
        />
      ),
    },
    {
      key: "section-trends-group-signals-toggle",
      onRender: () => (
        <Toggle
          inlineLabel
          label={t("Group Signals")}
          checked={groupSignals}
          styles={{ root: { marginBottom: 0, marginLeft: 20 } }}
          onChange={onGroupSignalsChange}
        />
      ),
    },
  ];

  const operationsBar: SectionCommandBarItem = {
    key: "section-details-operations-bar",
    onRender: () => <OperationCommandBar />,
  };

  if (activeTab === TAB_TITLES.Trend) return trendButtons;
  else if (activeTab === TAB_TITLES.Operations) return [operationsBar];
  else return [];
};

export const DetailsSection = ({
  ...rest
}: React.HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab]: any = useState(TAB_TITLES.Reports);
  const [search, setSearch] = useState("");
  const [visibleCustomDateDialog, setVisibleCustomDateDialog] = useState(false);
  const { controlsStore } = useTrendViewControls();

  const {
    selectedSignals,
    isWarningModalOpen,
    setIsWarningModalOpen,
    isGroupedEverything,
    setIsGroupedEverything,
  } = useSummaryStore((state) => ({
    selectedSignals: state.selectedSignals,
    isWarningModalOpen: state.isWarningModalOpen,
    setIsWarningModalOpen: state.setIsWarningModalOpen,
    isGroupedEverything: state.isGroupedEverything,
    setIsGroupedEverything: state.setIsGroupedEverything,
  }));

  const errorMessage = t(
    `You cannot have more than <b>{{MAX_GROUP_THRESHOLD}} signals selected</b> in <b>Grouped Signals mode</b>. Please remove\nsignals until there are <b>maximum {{MAX_GROUP_THRESHOLD}}</b>.`,
    { MAX_GROUP_THRESHOLD }
  );

  const handleDismiss = () => setIsWarningModalOpen(false);

  const onCustomDateChange = ({ start, end }: any) => {
    controlsStore.changePeriod({
      key: PeriodOptionsEnum.CustomDate,
      text: t(PeriodOptionsEnum.CustomDate),
      startDate: fnsFormat(new Date(start), FORMAT),
      endDate: fnsFormat(new Date(end), FORMAT),
    });
  };

  const onPeriodChange = (e: any, item: any) => {
    if (item?.key !== PeriodOptionsEnum.CustomDate) {
      controlsStore.changePeriod(item);
    } else {
      setVisibleCustomDateDialog(true);
    }
  };

  function handleGroupSignalsChange(_: any, checked: boolean | undefined) {
    return selectedSignals.length > MAX_GROUP_THRESHOLD
      ? setIsWarningModalOpen(true)
      : setIsGroupedEverything(checked);
  }

  return (
    <SectionContainer
      title={t("Details")}
      commandBarItems={getCommandBarItems({
        t,
        activeTab,
        controlsStore,
        groupSignals: isGroupedEverything,
        onPeriodChange,
        onGroupSignalsChange: handleGroupSignalsChange,
      })}
      searchBoxProps={{
        defaultValue: search,
        onChange: (_, newValue) => setSearch(newValue ?? ""),
        styles: {
          root: {
            display:
              activeTab === TAB_TITLES.Operations ||
              activeTab === TAB_TITLES.Trend
                ? "none"
                : "flex",
          },
        },
      }}
      {...rest}
    >
      <TabMenu
        tabs={tabItems(search, t)}
        pivotProps={{
          className: "details-section-tabs-pivot",
          defaultSelectedKey: activeTab,
          onLinkClick: (tab) => {
            setActiveTab(tab?.props?.itemKey);
            setSearch("");
          },
          styles: {
            root: {
              display: "flex",
              background: "white",
              marginRight: 14,
            },
            link: { flex: 1, margin: 0, fontSize: 14, fontWeight: 600 },
            linkIsSelected: { background: "rgb(243, 242, 241)" },
            itemContainer: {
              maxHeight: DEFAULT_TAB_MAX_HEIGHT,
              overflowY: "scroll",
            },
          },
        }}
      />
      <CustomDateDialog
        initialValues={controlsStore.period}
        hidden={!visibleCustomDateDialog}
        setVisible={setVisibleCustomDateDialog}
        onSubmit={onCustomDateChange}
      />
      <ErrorDialog
        hidden={!isWarningModalOpen}
        content={errorMessage}
        onDismiss={handleDismiss}
      />
    </SectionContainer>
  );
};
