import { useState } from "react";

import { TRIGGERS_TAB } from "../Sensor/SensorTabs";
import TelemetryTab from "./TelemetryTab";
import TriggerStatusTab from "./TriggerStatusTab";
import { TabMenu } from "../../../common/TabMenu";
import { useTranslation } from "react-i18next";

export const TRIGGER_STATUS_TAB = "Trigger Status";
export const TELEMETRY_TAB = "Telemetry";

const GatewayTabs = ({ gateway }: any) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(TELEMETRY_TAB);

  const tabItems: {
    key: string;
    title: string;
    content: JSX.Element;
    pivotItemProps?: any;
  }[] = [
    {
      key: TELEMETRY_TAB,
      title: t("Telemetry"),
      content: <TelemetryTab gateway={gateway} />,
      pivotItemProps: {
        className: "tab-container",
      },
    },
    {
      key: TRIGGER_STATUS_TAB,
      title: t("Trigger Status"),
      content: <TriggerStatusTab gateway={gateway} />,
      pivotItemProps: {
        className: "tab-container",
      },
    },
  ];

  return (
    <>
      <TabMenu
        tabs={tabItems}
        pivotProps={{
          onLinkClick: (pivot) => {
            const activePivot = pivot ? pivot.props.itemKey : undefined;
            setActiveTab(activePivot || TRIGGERS_TAB);
          },
          selectedKey: activeTab,
          className: "gateway-status",
          styles: {
            root: {
              marginTop: "15px",
              borderBottom: "1px solid rgb(243,242,241)",
            },
          },
        }}
      />
    </>
  );
};

export default GatewayTabs;
