import type { SVGProps } from "react";
import React from "react";

const Attachment: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.71652 8.48519L7.37352 2.82819C7.93618 2.26553 8.6993 1.94943 9.49502 1.94943C10.2907 1.94943 11.0539 2.26553 11.6165 2.82819C12.1792 3.39084 12.4953 4.15397 12.4953 4.94969C12.4953 5.7454 12.1792 6.50853 11.6165 7.07119L4.89852 13.7882C4.61562 14.0614 4.23671 14.2126 3.84342 14.2092C3.45012 14.2058 3.0739 14.048 2.79579 13.7699C2.51768 13.4918 2.35993 13.1156 2.35651 12.7223C2.35309 12.329 2.50428 11.9501 2.77752 11.6672L8.78752 5.65719C8.88141 5.56343 8.93421 5.43622 8.9343 5.30354C8.93439 5.17086 8.88177 5.04357 8.78802 4.94969C8.69427 4.8558 8.56706 4.803 8.43437 4.80291C8.30169 4.80282 8.17441 4.85543 8.08052 4.94919L2.07052 10.9592C1.605 11.4287 1.34441 12.0634 1.34576 12.7246C1.34712 13.3857 1.61031 14.0194 2.07774 14.487C2.54518 14.9545 3.17879 15.2179 3.83993 15.2194C4.50108 15.221 5.13591 14.9606 5.60552 14.4952L12.3235 7.77819C13.0738 7.02802 13.4954 6.01053 13.4955 4.94954C13.4956 3.88855 13.0742 2.87098 12.324 2.12069C11.5739 1.37039 10.5564 0.948824 9.49537 0.94873C8.43439 0.948637 7.41682 1.37002 6.66652 2.12019L1.00852 7.77819C0.917441 7.87249 0.867044 7.99879 0.868183 8.12989C0.869322 8.26099 0.921907 8.38639 1.01461 8.4791C1.10731 8.5718 1.23272 8.62438 1.36382 8.62552C1.49492 8.62666 1.62122 8.57627 1.71552 8.48519H1.71652Z"
        fill="#2C529F"
      />
    </svg>
  );
};

export default Attachment;
