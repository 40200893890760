import { useMemo, useState } from "react";

import {
  FormItemProps,
  FormItemType,
  renderFormItems,
  useZodForm,
} from "../../../common/Form";
import { notification } from "../../../common/Notification";
import useTableStore from "../../../common/Table/useTableStore";

import { z } from "zod";
import type { FieldError } from "react-hook-form";
import {
  PrimaryButton,
  Spinner,
  SpinnerSize,
  StackItem,
} from "@fluentui/react";
import { FormGroupProps } from "../../../common/Form";
import SectionContainer from "../SectionContainer";
import { axiosInstance } from "../../../..";
import SectionInfo from "../SectionInfo";
import { selectUserSettings } from "../../selectors";
import { useAppDispatch, useAppSelector } from "../../../../Hooks";
import { selectUserAccountMemberID } from "../../../UserAccount/selectors";
import { settingsUpdateUsersRBAC } from "../../api";
import { DEFAULT_ROWS_PER_TABLE, updateUserSettingsSlice } from "../../reducer";
import { perPageOptions } from "../../../common/Pagination/constants";

import { useTranslation } from "react-i18next";

export type ThreadAddProps = {
  machineId: string;
  text: string;
};

export const useAccountUpdate = (data) => {
  return axiosInstance.post("meta/machinecv/v1/comments", data);
};

const getSchema = () =>
  z.object({
    rowsPerTable: z.number(),
    gridItemsCount: z.string(),
  });

const groupPropsStyles = {
  labelProps: {
    style: {
      fontWeight: 600,
    },
  },
  stackProps: {
    style: {
      flexDirection: "column",
      marginBottom: 12,
      maxWidth: 600,
    },
  },
} as FormGroupProps;

const formStyles = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: 16,
  marginBottom: 65,
  overflow: "auto",
} as const;

export const getRowsPerTableDropdownField = (t) => ({
  name: "rowsPerTable",
  type: FormItemType.Dropdown,
  groupProps: {
    label: t("Rows per table"),
    ...groupPropsStyles,
  },
  options: perPageOptions,
  allowFreeform: false,
});

const getFormItems = (t) => ({
  rowSettings: [getRowsPerTableDropdownField(t)],

  cardSettings: [
    {
      name: "gridItemsCount",
      type: FormItemType.NumberField,
      min: 1,
      groupProps: {
        label: t("Cards count"),
        ...groupPropsStyles,
      },
    },
  ],
});

const List = () => {
  const { t } = useTranslation();
  const schema = useMemo(() => getSchema(), []);
  const [isLoading, setIsLoading] = useState(false);
  const { setTablePerPage } = useTableStore();

  const settings = useAppSelector(selectUserSettings);
  const userId = useAppSelector(selectUserAccountMemberID);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: {
      ...settings.list,
      gridItemsCount: `${settings.list.gridItemsCount}`,
    },
  });

  const onSubmit = handleSubmit(async (data: any) => {
    if (!data) return;

    const payload = {
      ...settings,
      list: { ...data, gridItemsCount: Number(data?.gridItemsCount) },
    };

    setIsLoading(true);

    await settingsUpdateUsersRBAC(userId, payload)
      .then((response) => {
        if (response) {
          dispatch(updateUserSettingsSlice(response));

          const rowsPerTable = data?.rowsPerTable || DEFAULT_ROWS_PER_TABLE;
          localStorage.setItem("rows-per-page", rowsPerTable);

          setTablePerPage(rowsPerTable);
          setIsLoading(false);

          notification.success(t("Settings updated successfully!"));
        }
      })
      .catch(() => {
        notification.warning(
          t("Something went wrong, please refresh the page. And try it again.")
        );
      });
  });

  return (
    <SectionContainer headerTitle={t("List")}>
      <form onSubmit={onSubmit} style={{ ...formStyles }}>
        <div className="form-items-container">
          <SectionInfo
            title={t("Row settings")}
            description={t(
              "Set the amount of lines you want to be displayed per table."
            )}
            style={{ padding: "8px 0px 10px 0px" }}
          />
          {renderFormItems(getFormItems(t).rowSettings as FormItemProps[], {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          }).map((element) => (
            <StackItem key={element.key}>{element}</StackItem>
          ))}
        </div>

        <hr
          style={{
            background: "#e1dfdd",
            border: "none",
            height: "1px",
          }}
        />

        <div className="form-items-container">
          <SectionInfo
            title={t("Card settings")}
            description={t(
              "Set the amount of cards you want to display at once."
            )}
            style={{ padding: "8px 0px 10px 0px" }}
          />
          {renderFormItems(getFormItems(t).cardSettings as FormItemProps[], {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          }).map((element) => (
            <StackItem key={element.key}>{element}</StackItem>
          ))}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: 16,
            background: "white",
            width: "100%",
            borderTop: "1px solid #F3F2F1",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <PrimaryButton
            type="submit"
            text={t("Update")}
            disabled={!isValid || isLoading}
            style={{
              marginTop: "auto",
              alignSelf: "flex-start",
            }}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
        </div>
      </form>
    </SectionContainer>
  );
};

export default List;
