import "./styles.scss";

import type { IDialogStyles } from "@fluentui/react";
import {
  ContextualMenu,
  DefaultButton,
  DialogFooter,
  DialogType,
  mergeStyles,
  mergeStyleSets,
  PrimaryButton,
} from "@fluentui/react";
import { useMemo, useState } from "react";

import BaseDialog, { DialogSize } from "../../../../Components/common/Dialog";
import { TabMenu } from "../../../../Components/common/TabMenu";

import useHarmonicsStoreV0 from "../../hooks/useHarmonicsStoreV0";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import { harmonicsInitialState } from "../SpectrumChart/harmonicsReducer";
import FrequenciesCatalogue from "./Catalogue";
import CustomFrequencies from "./Custom";
import { useCatalogueStore } from "./hooks";
import { useTranslation } from "react-i18next";

enum TAB_TITLES {
  Custom = "Custom",
  Catalogue = "Catalogue",
}

type FrequencyCatalogueType = {
  type: "First" | "Second";
};

export default function FrequencyCatalogueDialog({
  type = "First",
}: FrequencyCatalogueType) {
  const [isMinimized, setIsMinimized] = useState(false);
  const {
    machines,
    updateHarmonics,
    setManualInput,
    setRatioSpeedFromCustomValueRPM,
  } = useHarmonicsStoreV0();

  const { t } = useTranslation();

  const { keyedCatalogueData, toggleIsCatalogueOpen } = useCatalogueStore(
    (store: any) => ({
      setSelectedSignalValues: store.setSelectedSignalValues,
      keyedCatalogueData: store.keyedCatalogueData,
      toggleIsCatalogueOpen: store.toggleIsCatalogueOpen,
    })
  );

  const { machineId_1, machineId_2 } = useSelectedSignalsStore((store) => ({
    machineId_1: store.machineId_1,
    machineId_2: store.machineId_2,
  }));

  const machineId = type === "First" ? machineId_1 : machineId_2;

  const machineState =
    machineId && machines[machineId]
      ? machines[machineId]
      : {
          harmonicsOne: harmonicsInitialState,
          harmonicsTwo: harmonicsInitialState,
          harmonicsThree: harmonicsInitialState,
          manualInput: false,
          ratioSpeedFromCustomValueRPM: 1.0,
          clearHarmonicsTrigger: false,
        };

  const {
    harmonicsOne,
    harmonicsTwo,
    harmonicsThree,
    manualInput,
    ratioSpeedFromCustomValueRPM,
  } = machineState;

  const harmonicsInputs = useMemo(() => {
    return {
      harmonicsOne: [
        {
          label: t("Freq 1 [Hz]"),
          value: harmonicsOne.linesFreq,
          type: "float",
          action: (value: any) =>
            machineId &&
            updateHarmonics(machineId, "harmonicsOne", {
              type: "SET_LINES_FREQ",
              payload: value,
            }),
        },
        {
          label: t("Freq 1 Number"),
          value: harmonicsOne.linesNumber,
          type: "int",
          action: (value: any) =>
            machineId &&
            updateHarmonics(machineId, "harmonicsOne", {
              type: "SET_LINES_NUMBER",
              payload: value,
            }),
        },
        {
          label: t("SBs 1 [Hz]"),
          value: harmonicsOne.sidebandsFreq,
          type: "float",
          action: (value: any) =>
            machineId &&
            updateHarmonics(machineId, "harmonicsOne", {
              type: "SET_SIDEBANDS_FREQ",
              payload: value,
            }),
        },
        {
          label: t("SBs 1 Number"),
          value: harmonicsOne.sidebandsNumber,
          type: "int",
          action: (value: any) =>
            machineId &&
            updateHarmonics(machineId, "harmonicsOne", {
              type: "SET_SIDEBANDS_NUMBER",
              payload: value,
            }),
        },
      ],
      harmonicsTwo: [
        {
          label: t("Freq 2 [Hz]"),
          value: harmonicsTwo.linesFreq,
          type: "float",
          action: (value: any) =>
            machineId &&
            updateHarmonics(machineId, "harmonicsTwo", {
              type: "SET_LINES_FREQ",
              payload: value,
            }),
        },
        {
          label: t("Freq 2 Number"),
          value: harmonicsTwo.linesNumber,
          type: "int",
          action: (value: any) =>
            machineId &&
            updateHarmonics(machineId, "harmonicsTwo", {
              type: "SET_LINES_NUMBER",
              payload: value,
            }),
        },
        {
          label: t("SBs 2 [Hz]"),
          value: harmonicsTwo.sidebandsFreq,
          type: "float",
          action: (value: any) =>
            machineId &&
            updateHarmonics(machineId, "harmonicsTwo", {
              type: "SET_SIDEBANDS_FREQ",
              payload: value,
            }),
        },
        {
          label: t("SBs 2 Number"),
          value: harmonicsTwo.sidebandsNumber,
          type: "int",
          action: (value: any) =>
            machineId &&
            updateHarmonics(machineId, "harmonicsTwo", {
              type: "SET_SIDEBANDS_NUMBER",
              payload: value,
            }),
        },
      ],
      harmonicsThree: [
        {
          label: t("Freq [Hz]"),
          value: harmonicsThree.linesFreq,
          type: "float",
          action: (value: any) =>
            machineId &&
            updateHarmonics(machineId, "harmonicsThree", {
              type: "SET_LINES_FREQ",
              payload: value,
            }),
        },
        {
          label: t("Freq. Number"),
          value: harmonicsThree.linesNumber,
          type: "int",
          action: (value: any) =>
            machineId &&
            updateHarmonics(machineId, "harmonicsThree", {
              type: "SET_LINES_NUMBER",
              payload: value,
            }),
        },
        {
          label: t("SBs [Hz]"),
          value: harmonicsThree.sidebandsFreq,
          type: "float",
          action: (value: any) =>
            machineId &&
            updateHarmonics(machineId, "harmonicsThree", {
              type: "SET_SIDEBANDS_FREQ",
              payload: value,
            }),
        },
        {
          label: t("SBs Number"),
          value: harmonicsThree.sidebandsNumber,
          type: "int",
          action: (value: any) =>
            machineId &&
            updateHarmonics(machineId, "harmonicsThree", {
              type: "SET_SIDEBANDS_NUMBER",
              payload: value,
            }),
        },
      ],
    };
  }, [machineId, harmonicsOne, harmonicsTwo, harmonicsThree, t]);

  function getInputsObject() {
    const findInputByLabel = (label: string) =>
      harmonicsInputs.harmonicsThree.find(
        (input: any) => input.label === label
      );

    return {
      freqHzInput: findInputByLabel(t("Freq [Hz]")),
      sbsHzInput: findInputByLabel(t("SBs [Hz]")),
      freqNumberInput: findInputByLabel(t("Freq. Number")),
      sbsNumberInput: findInputByLabel(t("SBs Number")),
    };
  }

  const tabItems = [
    {
      key: TAB_TITLES.Custom,
      title: t("Custom"),
      content: <CustomFrequencies inputs={harmonicsInputs} />,
    },
    {
      key: TAB_TITLES.Catalogue,
      title: t("Catalogue"),
      content: (
        <FrequenciesCatalogue
          machineId={machineId}
          inputs={getInputsObject()}
          inputSpeedReference={{
            manualInput,
            setManualInput,
            ratioSpeedFromCustomValueRPM,
            setRatioSpeedFromCustomValueRPM,
          }}
        />
      ),
    },
  ];

  function onDismiss() {
    toggleIsCatalogueOpen(machineId, false);
  }

  const customStyles = mergeStyleSets({
    dialogRoot: mergeStyles([
      { pointerEvents: "none" },
      {
        ".ms-Dialog-main": {
          maxWidth: "100%",
          resize: isMinimized ? "" : "both",
          height: isMinimized ? "auto" : "80vh",
          width: "580px",
          pointerEvents: "auto",
        },
        ".ms-Modal-scrollableContent": {
          height: "100%",
          maxHeight: "100%",
        },
        ".ms-Dialog--close": {
          height: "100%",
        },
        ".ms-Dialog-header": {
          height: "auto",
        },
        ".ms-Dialog-inner": {
          height: isMinimized ? "auto" : "calc(100% - 63px)",
        },
        ".ms-Dialog-content": {
          height: isMinimized ? "auto" : "calc(100% - 70px)",
          display: isMinimized ? "none" : "block",
        },
      },
    ]),
    dialogMain: {
      pointerEvents: "auto",
      selectors: {
        "@media (min-width: 480px)": {
          width: "auto",
          position: "absolute",
          maxWidth: "80vw",
          maxHeight: isMinimized ? "176px" : "90vh",
        },
      },
    },
    dialogHeader: {
      cursor: "move",
      padding: "10px",
      backgroundColor: "#f0f0f0",
      borderBottom: "1px solid #ddd",
    },
  });

  const dialogStyles = (): Partial<IDialogStyles> => ({
    root: customStyles.dialogRoot,
    main: customStyles.dialogMain,
  });

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <BaseDialog
      hidden={machineId ? !keyedCatalogueData?.[machineId]?.isOpen : true}
      dialogContentProps={{
        title: t("Frequency catalogue: ({{machineName}})", {
          machineName:
            machineId && keyedCatalogueData?.[machineId as string]
              ? keyedCatalogueData?.[machineId as string]?.machineName
              : "",
        }),

        type: DialogType.close,
        topButtonsProps: [
          {
            ariaLabel: isMinimized ? t("Maximize") : t("Minimize"),
            iconProps: {
              iconName: isMinimized ? "ChevronUp" : "ChevronDown",
            },
            onClick: toggleMinimize,
          },
        ],
      }}
      modalProps={{
        isBlocking: false,
        isDarkOverlay: false,
        styles: dialogStyles(),
        allowTouchBodyScroll: true,
        overlay: {
          hidden: true,
        },
        dragOptions: {
          moveMenuItemText: t("Move"),
          closeMenuItemText: t("Close"),
          menu: ContextualMenu,
        },
      }}
      minWidth="580px"
      size={DialogSize.AUTO}
      onDismiss={onDismiss}
    >
      <TabMenu
        tabs={tabItems}
        pivotProps={{
          className: "harmonics-catalogue-tab-menu",
          styles: {
            link: { flex: 1, margin: 0, fontSize: 14, fontWeight: "600" },
            linkIsSelected: { background: "#2C529F", color: "#fff" },
          },
          defaultSelectedKey: TAB_TITLES.Custom,
        }}
      />
      <DialogFooter>
        <PrimaryButton
          text={t("Clear settings")}
          onClick={() => {
            if (!machineId) return;
            updateHarmonics(machineId, "harmonicsOne", { type: "RESET" });
            updateHarmonics(machineId, "harmonicsTwo", { type: "RESET" });
            updateHarmonics(machineId, "harmonicsThree", { type: "RESET" });
          }}
        />
        <DefaultButton text={t("Cancel")} onClick={onDismiss} />
      </DialogFooter>
    </BaseDialog>
  );
}
