import { useEffect, useState } from "react";
import WirelessSensorsTree, { TreeItem } from "../../../components/Tree";
import { useGatewayTriggering } from "../../../hooks/useGatewayTriggering";
import { useGatewaySensorsProfiles } from "../../../hooks/useGatewaySensorsProfiles";
import { useGatewayTelemetry } from "../../../hooks/useGatewayTelemetry";
import { notification } from "../../../../common/Notification";
import { Topology } from "../../../../../types";
import SensorNodeIcon from "../../../../../assets/svg/WirelessSensors/SensorNodeIcon";
import GatewayIcon from "../../../../../assets/svg/WirelessSensors/GatewayIcon";
import ChartCommandBar from "../../../components/Tree/ChartCommandBar";
import ManualTriggerSettings from "../../Sensor/TriggersTab/ManualTriggerSettings";
import { useTranslation } from "react-i18next";

const TelemetryTab = ({ gateway }: any) => {
  const { t } = useTranslation();
  const gatewayId = gateway?.serialNumber;
  const [isManualTriggerOpen, setIsManualTriggerOpen] = useState(false);
  const [tree, setTree] = useState<TreeItem>({});

  const { triggerGatewayAsync } = useGatewayTriggering({
    gatewayId: gatewayId as string,
  });
  const { refetch } = useGatewaySensorsProfiles({ gatewayId });
  const {
    data,
    isLoading: isLoadingGatTelemetry,
    isError: isErrorGatTel,
  } = useGatewayTelemetry({
    gatewayId: gatewayId,
  });

  const handleManualTrigger = async (selectedKeys: string[]) => {
    try {
      await triggerGatewayAsync(selectedKeys);
      notification.success(t("The gateway trigger was sent successfully"));
    } catch (error) {
      notification.error(t("Gateway trigger error"));
      throw error;
    }
  };

  const topologyTelemetryToTreeItem = (telemetry: Topology): TreeItem => {
    return {
      name: telemetry.value,
      children: telemetry.children.map(topologyTelemetryToTreeItem),
      icon: <SensorNodeIcon />,
      condition: "Ok",
    };
  };

  useEffect(() => {
    if (!isErrorGatTel && !isLoadingGatTelemetry && data) {
      const treeData: TreeItem = {
        name: data.gatewayTelemetry.gatewayId,
        icon: <GatewayIcon />,
        condition: data.gatewayTelemetry.enabled ? "Ok" : "Alert",
        children: data?.gatewayTelemetry.topology.children?.map((topology) =>
          topologyTelemetryToTreeItem(topology)
        ),
      };
      setTree(treeData);
    }
  }, [data, isLoadingGatTelemetry, isErrorGatTel]);

  return (
    <div className="chart-container">
      <ChartCommandBar
        title={t("Gateway Telemetry")}
        onRefresh={refetch}
        onTrigger={() => setIsManualTriggerOpen(true)}
      />

      <div className="chart-inner-container">
        <WirelessSensorsTree
          data={tree}
          isLoading={isLoadingGatTelemetry}
          split={false}
        />
      </div>

      <ManualTriggerSettings
        isOpen={isManualTriggerOpen}
        trendAndRaw={true}
        onDismiss={() => setIsManualTriggerOpen(false)}
        onSubmit={handleManualTrigger}
      />
    </div>
  );
};

export default TelemetryTab;
