import { Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import type { PropsWithChildren } from "react";

import { format } from "../../../common/utils/signalFormatter";
import type { ResponseSimplifiedSignal } from "../../../../types";
import { Trans, useTranslation } from "react-i18next";

const PlotContainer = ({ children }: PropsWithChildren) => (
  <Stack
    verticalAlign="center"
    horizontalAlign="center"
    style={{
      height: 511,
      background: "#FFF",
      borderRadius: 2,
    }}
  >
    {children}
  </Stack>
);

export const PlotSpinner = () => {
  const { t } = useTranslation();

  return (
    <PlotContainer>
      <Spinner size={SpinnerSize.large} label={t("Loading chart...")} />
      <Text style={{ fontSize: 10, display: "block" }}>
        {t("loading time depends on the amount of data")}
      </Text>
    </PlotContainer>
  );
};

export const RawDataPlotNoData = ({
  signal,
}: {
  signal: ResponseSimplifiedSignal;
}) => {
  const formattedSignal = format(signal);

  return (
    <PlotContainer>
      <span>
        <Trans
          i18nKey={"<0>{{formattedSignal}}</0> has no data"}
          values={{ formattedSignal }}
        >
          <b>{formattedSignal}</b> has no data
        </Trans>
      </span>
    </PlotContainer>
  );
};
