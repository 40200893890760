import React, { useState } from "react";
import {
  Stack,
  TextField,
  IconButton,
  Spinner,
  mergeStyles,
} from "@fluentui/react";
import dayjs from "dayjs";

import { MessageItem } from "./MessageItem";
import { Message } from "../models";
import { useTranslation } from "react-i18next";

const styles = {
  container: mergeStyles({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  }),
  messageList: mergeStyles({
    flex: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column-reverse",
    marginBottom: "20px",
  }),
  inputContainer: mergeStyles({
    display: "flex",
    gap: "8px",
    padding: "16px",
    backgroundColor: "white",
    borderTop: "1px solid #edebe9",
  }),
  spinner: mergeStyles({
    margin: "auto",
  }),
};

interface SubmissionPreviewMessagesScreenProps {
  messages: Message[];
  creatorName: string;
  isLoading?: boolean;
  onSent: (message: string) => void;
  isSending?: boolean;
}

const ChatMessageDisplay: React.FC<SubmissionPreviewMessagesScreenProps> = ({
  messages,
  creatorName,
  isLoading,
  onSent,
  isSending,
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");

  const mapMessages = (messages: Message[] | undefined) => {
    if (!messages) return [];
    return messages
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .map((message) => {
        return {
          name: message.createdBy,
          date: dayjs(message.createdAt).format("YYYY-MM-DD HH:mm"),
          message: message.text,
          role:
            creatorName === message.createdBy ? "Contributor" : "Administrator",
          isMe: creatorName !== message.createdBy,
        };
      });
  };

  return (
    <Stack className={styles.container}>
      <div className={styles.messageList}>
        {isLoading ? (
          <Spinner className={styles.spinner} />
        ) : (
          mapMessages(messages).map((message, index) => (
            <MessageItem key={index} message={message} />
          ))
        )}
      </div>

      <div className={styles.inputContainer}>
        <TextField
          value={comment}
          placeholder={t("Comment")}
          onChange={(_, newValue) => setComment(newValue || "")}
          styles={{
            root: { flex: 1 },
            fieldGroup: { height: 40 },
          }}
        />
        <IconButton
          iconProps={{ iconName: "Send" }}
          disabled={isSending || !comment.trim()}
          onClick={() => {
            onSent(comment);
            setComment("");
          }}
        />
      </div>
    </Stack>
  );
};

export default ChatMessageDisplay;
