import { useEffect, useMemo } from "react";

import {
  usePrevious,
  useTrendMeasuredDataInAPeriod,
} from "../../../../../Hooks";
import { CHART_NUMBER_OF_POINTS } from "../../../../../modules/analysis-trend-view/constants/controlOptions";
import getPeriodFilters from "../../../../../modules/analysis-trend-view/utils/getPeriodFilters";
import { ChartSection } from "./ChartSection";
import Header from "./Header";
import KpiTable from "./KpiTable";
import { getWorstBestCurrentPerformance } from "./methods";
import StatisticsTabs from "./Tabs";
import { STATISTICS_TAB_1 } from "./Tabs/config";
import usePerformanceStore from "../../hooks/usePerformanceStore";
import { NoData } from "../../../../common/NoData";
import { Spin } from "../../../../common/Spin";
import { useTranslation } from "react-i18next";

const Statistics = ({ signals, performance }: any) => {
  const { t } = useTranslation();
  const { statisticsActiveTab, updateStore, period } = usePerformanceStore(
    (store: any) => ({
      statisticsActiveTab: store.statisticsActiveTab,
      updateStore: store.updateStore,
      period: store.period,
    })
  );

  const perfSignal = useMemo(
    () => signals.find(({ id }: any) => id === performance?.perfSignalId),

    [signals.length, performance?.perfSignalId]
  );

  const perfPeriodFilter = useMemo(
    () => getPeriodFilters(period, perfSignal),
    [period?.key, perfSignal]
  );

  const { data: perfSignalData, isLoading } = useTrendMeasuredDataInAPeriod(
    {
      startDate: perfPeriodFilter.startDate,
      endDate: perfPeriodFilter.endDate,
      queryKey: [
        "performance-signal",
        perfSignal?.id,
        perfPeriodFilter?.startDate,
        perfPeriodFilter?.endDate,
        "off",
      ],
      machineId: perfSignal?.machineId,
      signalId: perfSignal?.id,
      numberOfPoints: CHART_NUMBER_OF_POINTS,
      signalType: perfSignal?.dataType,
      onlyZip: true,
      refreshInterval: { key: "off", text: t("Off") },
    },
    {
      enabled: !!perfSignal?.id,
      retry: false,
    }
  );

  const prevStatisticsActiveTab = usePrevious(statisticsActiveTab);

  useEffect(() => {
    if (statisticsActiveTab !== prevStatisticsActiveTab) {
      updateStore({ selectedSignals: [] });
    }
  }, [statisticsActiveTab]);

  const { bestPerformance, worstPerformance } = useMemo(
    () => getWorstBestCurrentPerformance(perfSignalData),
    [perfSignalData]
  );

  const tableProps = {
    bestPerformance,
    worstPerformance,
    perfPeriodFilter,
    signals,
  };

  const loading = isLoading && !!perfSignal;
  const noData = !isLoading && !perfSignal;

  return (
    <div className="events-container">
      {loading && <Spin style={{ marginTop: 100 }} />}
      {noData && <NoData text={t("Data performance missing")} />}
      {!loading && !noData && (
        <>
          <Header />
          <div className="two-columns">
            <div className="white-container">
              <StatisticsTabs />
              {statisticsActiveTab === STATISTICS_TAB_1 ? (
                <KpiTable
                  {...tableProps}
                  kpiSignals={performance.performancePageParamSignals}
                />
              ) : (
                <KpiTable
                  {...tableProps}
                  kpiSignals={performance.performancePageKpiSignals}
                />
              )}
            </div>
            <div className="white-container">
              <ChartSection />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Statistics;
