import { AxiosResponse } from "axios";
import {
  CfgToMachinePinningItem,
  ImageInformation,
  ImageType,
  ImagesStatisticsResponse,
  UploadImageFormData,
} from "./models";
import { axiosInstance } from "../..";
import { MockImagesAPI } from "./mockApi";
import { t } from "i18next";

/**
 * Gets the upload image Url
 * @param imageType The image type
 * @returns The upload image relative Url string.
 */
const getUploadImageUrl = (imageType: ImageType): string => {
  switch (imageType) {
    case ImageType.Image:
      return "/files/v1/images";
    case ImageType.Zip:
      return "/files/v1/images/zip";
    case ImageType.GZip:
      return "/files/v1/images/gzip";
    case ImageType.BusyBee:
      return "/files/v1/images/busybee";
    case ImageType.FLS:
      return "/files/v1/images/fls";
    case ImageType.Wireless:
      return "/files/v1/images/wireless";
    default:
      return "";
  }
};

/**
 * * The Images API
 */
export class ImagesAPI {
  /**
   * Gets the images list.
   * @returns The image items list.
   */
  static list = async (): Promise<AxiosResponse<ImageInformation[]>> => {
    let response: AxiosResponse<ImageInformation[]> = {
      data: [],
      status: 0,
      statusText: "",
      headers: {},
      config: {},
    };

    try {
      // response = await axiosInstance.get<ImageInformation[]>(
      //   "/meta/read/v1/images"
      // );
      response = await MockImagesAPI.list();
    } catch (e: any) {
      response.statusText = e.Message ?? t("Unknown error");
    }

    return response;
  };

  /**
   * Uploads an image of a selected type.
   * @param imageType The image type.
   * @param body The request body object
   * @returns The axios response.
   */
  static uploadImage = async (
    imageType: ImageType,
    body: UploadImageFormData
  ): Promise<AxiosResponse> => {
    let response: AxiosResponse = {
      data: null,
      status: 0,
      statusText: "",
      headers: {},
      config: {},
    };

    if (imageType === ImageType.None) {
      response.statusText = t("The request image type is 'None'.");
      return response;
    }

    try {
      const form = new FormData();
      form.append("file", body.file);
      switch (imageType) {
        case ImageType.Image:
        case ImageType.Zip:
        case ImageType.GZip:
        case ImageType.BusyBee:
          form.append("machineId", body.machineId);
          form.append("dalogId", body.dalogId);
          break;
        case ImageType.Wireless:
          form.append(
            "isFileEncrypted",
            body.isFileEncrypted?.toString() ?? "false"
          );
          break;
      }

      response = await axiosInstance.post(getUploadImageUrl(imageType), form);
    } catch (e: any) {
      response.statusText = e.Message ?? t("Unknown error");
    }

    return response;
  };

  /**
   * Gets the images statistics in a time range.
   * @param from The time range From date
   * @param to The time range To date.
   * @returns The image statistics response.
   */
  static getStatistics = async (
    from: Date,
    to: Date
  ): Promise<AxiosResponse<ImagesStatisticsResponse>> => {
    let response: AxiosResponse<ImagesStatisticsResponse> = {
      data: null,
      status: 0,
      statusText: "",
      headers: {},
      config: {},
    };

    try {
      // response = await axiosInstance.get<ImagesStatisticsResponse>(
      //   "/meta/read/v1/images"
      // );
      response = await MockImagesAPI.getStatistics(from, to);
    } catch (e: any) {
      response.statusText = e.Message ?? t("Unknown error");
    }

    return response;
  };
}

/**
 * * The CFG to machine pinning API
 */
export class CfgToMachinePinningApi {
  /**
   * Lists all confignames.
   * @returns The axios response with the items list data.
   */
  static list = async (): Promise<AxiosResponse<CfgToMachinePinningItem[]>> => {
    let response: AxiosResponse<CfgToMachinePinningItem[]> = {
      data: [],
      status: 0,
      statusText: "",
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance.get<CfgToMachinePinningItem[]>(
        "/meta/read/internal/v1/cfgToMachinePinnings"
      );
    } catch (e: any) {
      response.statusText = e.Message ?? t("Unknown error");
    }

    return response;
  };

  /**
   * Creates a CFG pinning item
   * @param item The new item
   * @returns The axios response with the created element.
   */
  static create = async (
    item: CfgToMachinePinningItem
  ): Promise<AxiosResponse<CfgToMachinePinningItem>> => {
    let response: AxiosResponse<CfgToMachinePinningItem> = {
      data: undefined,
      status: 0,
      statusText: "",
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance.post<CfgToMachinePinningItem>(
        "/meta/write/v1/cfgToMachinePinnings",
        item
      );
    } catch (e: any) {
      response.statusText = e.Message ?? t("Unknown error");
    }

    return response;
  };

  /**
   * Updates a CFG pinning item
   * @param item The item to update
   * @returns The axios response with the updated element.
   */
  static update = async (
    item: CfgToMachinePinningItem
  ): Promise<AxiosResponse<CfgToMachinePinningItem>> => {
    let response: AxiosResponse<CfgToMachinePinningItem> = {
      data: undefined,
      status: 0,
      statusText: "",
      headers: {},
      config: {},
    };

    try {
      if (!item.id) {
        response.statusText = t("No ID attached to the update request.");
        return response;
      }

      response = await axiosInstance.put<CfgToMachinePinningItem>(
        `/meta/write/v1/cfgToMachinePinnings/${item.id}`,
        item
      );
    } catch (e: any) {
      response.statusText = e.Message ?? t("Unknown error");
    }

    return response;
  };

  /**
   * Deletes a CFG pinning item
   * @param id The item id
   * @returns The axios response with the created element.
   */
  static delete = async (
    id: string
  ): Promise<AxiosResponse<CfgToMachinePinningItem>> => {
    let response: AxiosResponse<CfgToMachinePinningItem> = {
      data: undefined,
      status: 0,
      statusText: "",
      headers: {},
      config: {},
    };

    try {
      response = await axiosInstance.delete<CfgToMachinePinningItem>(
        `/meta/write/v1/cfgToMachinePinnings/${id}`
      );
    } catch (e: any) {
      response.statusText = e.Message ?? t("Unknown error");
    }

    return response;
  };
}
