import React, { useState } from "react";
import "./Tabs.css";
import { Text } from "@fluentui/react/lib/Text";

import TabContent from "./TabContent";
import Tab from "./Tab";
import { useTranslation } from "react-i18next";

const Tabs = ({ tabs, hasExtraClass, hasHeaderContent }) => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const tabArray = Array.isArray(tabs) ? tabs : [tabs];

  return (
    <div className="vertical-tabs">
      <div className="settings-tabs">
        {hasHeaderContent && (
          <div className="settings-header-content">
            <Text block className="settings-tabs-header">
              {t("Settings")}
            </Text>
          </div>
        )}
        {tabArray.map((tab, index) => (
          <Tab
            key={index}
            tab={tab}
            isActive={index === activeTab}
            onClick={() => handleTabClick(index)}
          />
        ))}
      </div>

      <TabContent
        tabArray={tabArray}
        activeTab={activeTab}
        hasExtraClass={hasExtraClass}
      />
    </div>
  );
};

export default Tabs;
