/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import "../../style.scss";

import type { IPersonaProps } from "@fluentui/react";
import { IconButton, Persona, PersonaSize } from "@fluentui/react";
import { differenceInDays } from "date-fns";
import type { CSSProperties } from "react";
import { useMemo, useState } from "react";

import { format } from "../../../../../../../utils";
import {
  useWebAnalysisPermissions,
  useMachineUsersQuery,
  useLocationSearch,
} from "../../../../../../../Hooks";
import DeleteDialog from "../../../../../../../modules/common/components/Dialogs/DeleteDialog";
import { CommentDialog } from "./CommentDialog";
import { useTask } from "../../../../hooks/useTask";
import useTasksStore from "../../../../hooks/useTasksStore";
import type { Comment as IComment } from "../../../../../../../types";
import { File } from "../../../../../../common/Preview/File";

import { useTranslation } from "react-i18next";

const linkStyle: CSSProperties = {
  color: "#0078d4",
  cursor: "pointer",
  fontSize: "12px",
  textDecoration: "underline",
};

const postedAt = (t, date: string) => {
  const now = new Date();
  const createdAt = new Date(date);
  const days = differenceInDays(now, createdAt);

  if (!days) {
    return t(`today at {{date}}`, { date: format(createdAt, "HH:mm") });
  }

  if (days === 1) {
    return t(`yesterday at {{date}}`, { date: format(createdAt, "HH:mm") });
  }

  return `${format(createdAt)} ${format(createdAt, "HH:mm")}`;
};

export const Comment = ({ item }: { item: IComment }) => {
  const { t } = useTranslation();
  const [locationData, ,] = useLocationSearch();
  const { getTaskDetails } = useTask({ taskId: item.taskId });
  const { usersPersonas } = useMachineUsersQuery({
    machineId: locationData.id as string,
  });
  const [showTaskCommentsDialog, setShowTaskCommentsDialog] = useState(false);
  const [showReplyDialog, setShowReplyDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [seeMore, setSeeMore] = useState(false);

  const user = useMemo(
    () => usersPersonas.find((user) => user.id === item.createdBy),
    [usersPersonas]
  );

  const { edit } = useTasksStore((state) => ({
    edit: state.edit,
  }));

  const { useReadyPermission, checkCreatorUserIDMatch } =
    useWebAnalysisPermissions();
  const hasMachineCvAdministratorPermission = useReadyPermission({
    permissionRoles: "MachineCvAdministrator",
  });

  const avatarProps: IPersonaProps = {
    ...user,
    size: PersonaSize.size40,
    onRenderPrimaryText: () => (
      <div className="avatar-text">
        <span className="name">{user?.text || item?.createdByDisplayName}</span>
        <span className="title">{user?.secondaryText}</span>
        <div className="created">{postedAt(t, item.createdAt)}</div>
      </div>
    ),
    styles: {
      root: { height: "auto" },
      details: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
  };

  const actionOptionsItems = [
    // Temporary removed Reply functionality
    // {
    //   key: 'reply',
    //   text: 'Reply',
    //   onClick: () => setShowReplyDialog(true),
    // },
  ];

  if (checkCreatorUserIDMatch(item?.createdBy)) {
    actionOptionsItems.push({
      key: "edit",
      text: t("Edit"),
      onClick: () => setShowTaskCommentsDialog(true),
    });
  }

  if (hasMachineCvAdministratorPermission) {
    actionOptionsItems.push({
      key: "delete",
      text: t("Delete"),
      onClick: () => setShowDeleteDialog(true),
    });
  }

  return (
    <>
      <div
        className="comment-container"
        style={{
          padding: "15px 0",
          borderBottom: "1px solid rgb(243,242,241)",
        }}
      >
        <Persona className="comment-avatar" {...avatarProps} />
        <div className="comment-text-container">
          <span className="comment-text" style={{ whiteSpace: "pre-wrap" }}>
            {item.text}
          </span>
          {item.files.length !== 0 && (
            <>
              <span className="attachments">{t("Attachments")}</span>
              <div className="comment-documents">
                {item.files.map((file, idx) => {
                  if (!seeMore && idx > 1) {
                    return null;
                  }
                  return (
                    <div key={idx}>
                      <File file={file} />
                    </div>
                  );
                })}
                {item.files.length > 2 && (
                  <span style={linkStyle} onClick={() => setSeeMore(!seeMore)}>
                    {seeMore ? t("Show less") : t("Show more")}
                  </span>
                )}
              </div>
            </>
          )}
        </div>
        {actionOptionsItems?.length > 0 ? (
          <IconButton
            iconProps={{
              iconName: "MoreVertical",
            }}
            styles={{ root: { height: 36, width: 40, borderRadius: 0 } }}
            menuProps={{ items: actionOptionsItems }}
            menuIconProps={{ style: { display: "none" } }}
            className="status-badge-button"
          />
        ) : null}
      </div>
      <CommentDialog
        comment={item}
        id={edit.id}
        hidden={!showTaskCommentsDialog}
        onDismiss={() => setShowTaskCommentsDialog(false)}
      />
      <CommentDialog
        isReply={true}
        comment={{
          ...item,
          text: t(`Reply to:\n{{comment}}`, { comment: item.text }),
        }}
        id={edit.id}
        hidden={!showReplyDialog}
        onDismiss={() => setShowReplyDialog(false)}
      />
      <DeleteDialog
        dialogContentProps={{
          onDismiss: () => setShowDeleteDialog(false),
        }}
        data={{
          ids: [item.id],
          name: t("1 comment"),
        }}
        hidden={!showDeleteDialog}
        route={`/meta/machinecv/v1/tasks/${edit.id}/comments`}
        onSuccess={getTaskDetails}
      />
    </>
  );
};
