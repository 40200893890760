import * as am5 from "@amcharts/amcharts5";
import type { IXYAxis } from "@amcharts/amcharts5/.internal/charts/xy/series/XYSeries";
import * as am5xy from "@amcharts/amcharts5/xy";

import { default as chartSymbols } from "../../../../../assets/svg/ChartSymbolsIcons";
import { yAxisWheel } from "../../../../../modules/analysis-trend-view/components/TrendViewGroupedPlot/methods";
import type {
  DataBlockPoint,
  DataPoint,
  ResponseSimplifiedSignal,
} from "../../../../../types";

import type { MopSignalData } from "../../config";

export const getMopSignals = (
  signals: ResponseSimplifiedSignal[]
): ResponseSimplifiedSignal[] => {
  const result = signals.filter((s) => s.sensorNo === "MOP");
  result.sort((a, b) => {
    if (!a.name) return -1;
    if (!b.name) return 1;
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

  return result;
};

export const setLegendMarkers = (
  root: am5.Root | null,
  legend: am5.Legend | null
) => {
  if (!root || !legend) {
    return;
  }

  const markers: am5.Container[] = legend.markers.values;
  for (let i = 0; i < markers.length; i++) {
    const marker = markers[i];
    const icon = am5.Picture.new(root, {
      width: 16,
      height: 16,
      src: `${chartSymbols.svgs[i]}`,
    });

    marker.children.setAll([icon]);
  }
};

export const updateSeries = <T = DataBlockPoint | DataPoint>(
  root: am5.Root,
  chart: am5xy.XYChart,
  xAxis: IXYAxis,
  legend: am5.Legend,
  data: Map<string, MopSignalData<T>>,
  valueXField: string,
  valueYField: string,
  yAxisSettings: any = {}
) => {
  return new Promise<am5xy.XYSeries[]>((resolve) => {
    chart.series.clear();
    chart.yAxes.clear();
    legend.data.clear();

    if (data.size === 0) {
      return [];
    }

    let colorIndex = 0;
    for (const signal of data.values()) {
      if (!signal.data || signal.data.length === 0) {
        continue;
      }

      const seriesColor = am5.color(chartSymbols.colors[colorIndex]);
      const iconSrc = `${chartSymbols.svgs[colorIndex]}`;

      // === Y Axis ===
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            stroke: seriesColor,
            pan: "zoom",
            strokeOpacity: 1,
            opacity: 1,
          }),
          maxDeviation: 1,
          autoZoom: false,
          marginRight: 0,
          // Add these lines to set min and max when syncSettingsMode === 'syncAll'
          min: yAxisSettings.strictMinMax ? yAxisSettings.min : undefined,
          max: yAxisSettings.strictMinMax ? yAxisSettings.max : undefined,
        })
      );

      yAxis.events.on("wheel", (ev) => yAxisWheel(ev, yAxis));

      yAxis.get("renderer").labels.template.set("fill", seriesColor);
      yAxis.get("renderer").labels.template.set("fontSize", 10);

      if (colorIndex > 0) {
        const refYAxis = chart.yAxes.getIndex(0);
        if (refYAxis && yAxisSettings.strictMinMax !== true) {
          yAxis.set(
            "syncWithAxis",
            refYAxis as am5xy.ValueAxis<am5xy.AxisRenderer>
          );
        }
      }

      const container = am5.Container.new(root, {
        ariaLabel: "yAxisLabelContainer",
        layout: root.verticalLayout,
        height: am5.percent(100),
        centerX: am5.p100,
      });

      yAxis.children.unshift(container);
      container.children.unshift(
        am5.Label.new(root, {
          ariaLabel: "yAxisLabel",
          rotation: -90,
          y: am5.p50,
          centerX: am5.p50,
          width: am5.percent(100),
          text: signal.unit,
          fill: seriesColor,
          fontSize: 10,
          marginRight: 0,
          marginBottom: 0,
        })
      );

      container.children.unshift(
        am5.Picture.new(root, {
          ariaLabel: "y-axis-icon",
          width: 16,
          height: 16,
          x: 5,
          src: iconSrc,
        })
      );

      // === Series ===

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: signal.name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField,
          valueYField,
          stroke: seriesColor,
          fill: seriesColor,
        })
      );

      series.data.setAll(signal.data);
      colorIndex++;
    }

    return resolve(chart.series.values);
  });
};
