import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMachineTelemetry } from "../../../hooks/useMachineTelemetry";
import WirelessSensorsTree, { TreeItem } from "../../../components/Tree";
import { useMachineTriggering } from "../../../hooks/useMachineTriggering";
import { notification } from "../../../../common/Notification";
import { GatewayTelemetry, Topology } from "../../../../../types";
import GatewayIcon from "../../../../../assets/svg/WirelessSensors/GatewayIcon";
import SensorNodeIcon from "../../../../../assets/svg/WirelessSensors/SensorNodeIcon";
import MachineIcon from "../../../../../assets/svg/MachineIcon";
import ChartCommandBar from "../../../components/Tree/ChartCommandBar";
import ManualTriggerSettings from "../../Sensor/TriggersTab/ManualTriggerSettings";
import { useTranslation } from "react-i18next";

const TelemetryTab = () => {
  const { t } = useTranslation();
  const machineId = useParams()?.machineId || "";
  const [isManualTriggerOpen, setIsManualTriggerOpen] = useState(false);
  const {
    data,
    isLoading: isLoadingMachine,
    isError: isErrorMachine,
    refetch,
  } = useMachineTelemetry({ machineId });
  const [tree, setTree] = useState<TreeItem>({});
  const { triggerMachineAsync } = useMachineTriggering({
    machineId: machineId as string,
  });

  const handleManualTrigger = async (selectedKeys: string[]) => {
    try {
      await triggerMachineAsync(selectedKeys);
      notification.success(t("The machine trigger was sent successfully"));
    } catch (error) {
      notification.error(t("Machine trigger error"));
      throw error;
    }
  };

  const gatewayTelemetryToTreeItem = (
    telemetry: GatewayTelemetry
  ): TreeItem => {
    return {
      icon: <GatewayIcon />,
      name: telemetry.gatewayId,
      children: telemetry.topology.children.map(topologyTelemetryToTreeItem),
      condition: telemetry.enabled ? "Ok" : "Alert",
    };
  };

  const topologyTelemetryToTreeItem = (telemetry: Topology): TreeItem => {
    return {
      name: telemetry.value,
      children: telemetry.children.map(topologyTelemetryToTreeItem),
      icon: <SensorNodeIcon />,
      condition: "Ok",
      disabled: !data.machineSensors.some(
        (sensor) => sensor === telemetry.value
      ),
    };
  };

  useEffect(() => {
    if (!isErrorMachine && !isLoadingMachine && data) {
      const treeData: TreeItem = {
        name: "",
        icon: <MachineIcon />,
        children: data.gatewayTelemetries.map((telemetry: GatewayTelemetry) =>
          gatewayTelemetryToTreeItem(telemetry)
        ),
      };
      setTree(treeData);
    }
  }, [data, isLoadingMachine, isErrorMachine]);

  return (
    <div className="chart-container">
      <ChartCommandBar
        title={t("Machine Telemetry")}
        onRefresh={refetch}
        onTrigger={() => setIsManualTriggerOpen(true)}
      />

      <div className="chart-inner-container" style={{ height: "65vh" }}>
        <WirelessSensorsTree data={tree} isLoading={isLoadingMachine} />
      </div>

      <ManualTriggerSettings
        isOpen={isManualTriggerOpen}
        trendAndRaw={true}
        onDismiss={() => setIsManualTriggerOpen(false)}
        onSubmit={handleManualTrigger}
      />
    </div>
  );
};

export default TelemetryTab;
