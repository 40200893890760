import { useEffect, CSSProperties } from "react";

import BaseDialog, { DialogSize } from "../common/Dialog";
import { notification } from "../common/Notification";

import {
  IDialogProps,
  DialogType,
  Text,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import { WithOutPermissionsUserExtendedProperties } from "./models";
import { useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import { RolesComponentDetails } from "../Roles/RolesComponentDetails";
import { pageStyle } from "../../schema/Constants";
import { selectUsersRBACError, selectUsersRBACStatus } from "./reducer";
import { detailsUsersRBAC } from "./api";
import { useTranslation } from "react-i18next";

const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "50px",
};

type DetailsDialogProps = IDialogProps & {
  data?: WithOutPermissionsUserExtendedProperties;
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const DetailsDialogPending = ({
  data,
  show,
  onSuccess,
  onClose,
  ...rest
}: DetailsDialogProps) => {
  const { t } = useTranslation();
  const status = useAppSelector(selectUsersRBACStatus);
  const error = useAppSelector(selectUsersRBACError);

  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  const handleClose = () => {
    onClose?.();
  };

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.close,
        title: t("Details User Account"),
        closeButtonAriaLabel: t("Close"),
        onDismiss: handleClose,
      }}
      styles={{
        main: {
          "@media (min-width: 480px)": {
            height: "65vh",
          },
        },
      }}
      size={DialogSize.L}
    >
      <div style={pageStyle}>
        <Pivot aria-label={t("Pivots to edit service account")}>
          <PivotItem
            headerText={t("Roles")}
            itemKey={"roles"}
            onRenderItemLink={() => (
              <Text style={titleStylePivot}>{t("Roles")}</Text>
            )}
          >
            {RolesComponentDetails({
              data: undefined,
              memberId: data?.memberId,
              detailsFunction: detailsUsersRBAC,
              onSuccess: () => {
                handleClose();
                onSuccess(false, "");
              },
            })}
          </PivotItem>
        </Pivot>
      </div>
    </BaseDialog>
  );
};
