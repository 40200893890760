import { useTranslation } from "react-i18next";
import BaseCommandBar, {
  computeCommandBarItems,
} from "../../../common/CommandBar";
import { getColumnsSignal, getCommandBarItems } from "../columnsHelper";
import { useContext } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Hooks";
import {
  listAsyncMachDetails,
  selectMachDetails,
  selectMachineDetailsStatus,
} from "../reducer";
import { authContext } from "../../../LeftMenuAlt/LeftMenuAlt";
import { convertCSVtoXLSX } from "../../../../schema/Utils";
import { signalsExport } from "../api";
import { notification } from "../../../common/Notification";
import { useParams } from "react-router-dom";
import { commandBarStyles } from "../../../../schema/Constants";
import Table, { useTableFilters } from "../../../common/Table";
import { Status } from "../../../../schema/status";
import { ResponseSimplifiedSignal } from "../../models";

export default function SignalContent({
  setSelected,
  setActionSignals,
  setActionSignalsCondition,
}) {
  const { t } = useTranslation();
  const { id } = useParams();

  const auth = useContext(authContext);
  const status = useAppSelector(selectMachineDetailsStatus);
  const dispatch = useAppDispatch();
  const items = useAppSelector(selectMachDetails);

  const signalFilter = useTableFilters<ResponseSimplifiedSignal>({
    keys: [
      "name",
      "dataFrom",
      "dataUntil",
      "configName",
      "feature",
      "sensorNo",
      "signalDescription",
      "signalGroup",
      "signalType",
      "dataType",
      "unit",
      "sensorSerialNo",
    ],
  });

  function onAdd() {
    setActionSignals({
      data: undefined,
      context: "add",
    });
  }

  function onExport() {
    signalsExport(id).then((resp) => {
      if (resp.status === 400) {
        notification.error(t("Issue exporting signals"));
      } else if (resp.status >= 200 && resp.status < 300) {
        convertCSVtoXLSX(resp.data, "Signals_" + id + ".xlsx");
      }
    });
  }

  function onRefresh() {
    dispatch(listAsyncMachDetails(id || ""));
  }

  return (
    <>
      <BaseCommandBar
        items={computeCommandBarItems(
          getCommandBarItems({
            t,
            title: t("Signals"),
            onChange(files) {
              setSelected({
                context: "signals",
                file: files.at(0),
              });
            },
            xlsxToo: true,
            addButton: auth.metaDataContributor,
            onAdd,
            importButton: auth.metaDataContributor,
            exportable: auth.metaDataReader,
            onExport,
            onRefresh,
          })
        )}
        onSearch={signalFilter.handleSearch}
        styles={commandBarStyles}
      />
      <Table
        persistOpts={{
          key: "table-Signals",
          version: 2,
        }}
        header={{
          title: t("Signals"),
          isExportable: false,
        }}
        items={items?.signals}
        columns={getColumnsSignal({
          t,
          onEditCondition(signalId, condition) {
            setActionSignalsCondition({
              data: { id: signalId, condition: condition },
              context: "edit",
            });
          },
          onEdit(signal) {
            setActionSignals({
              data: signal,
              context: "edit",
            });
            notification.warning(t("It has effect only on CUSTOM signals."));
          },
          onDelete(signalId) {
            setActionSignals({
              data: signalId,
              context: "delete",
            });
          },
          metaDataContributor: auth.metaDataContributor,
        })}
        filters={signalFilter.filters}
        hasSelection={false}
        //onSelectionChanged={(items: ItemsSelected[]) => setSelectedItems(items)}  // For Bulk Edit Paused
        isLoading={status === Status.loading}
        isError={status === Status.error}
        //onRenderRow={OnRenderRow}   // For Bulk Edit Paused
        //perPage={bigScreen ? 20 : 10}
      />
    </>
  );
}
