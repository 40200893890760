import React, { useState } from "react";
import {
  TextField,
  ITextFieldProps,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  mergeStyles,
  ITextFieldStyleProps,
  ITextFieldStyles,
} from "@fluentui/react";
import Attachment from "./Attachment";
import { FileSubmission } from "./models";

import { axiosInstance } from "../../..";
import FilePreview from "../../common/Preview/FilePreview";
import { useTranslation } from "react-i18next";

interface TextFieldWithDocumentsProps
  extends Omit<ITextFieldProps, "onChange"> {
  value: string;
  onChange: (newValue: string) => void;
  files?: FileSubmission[];
  fieldName: string;
}

const styles = {
  dialog: mergeStyles({
    main: {
      maxWidth: 800,
      minWidth: 600,
    },
  }),
  filesContainer: mergeStyles({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    gap: "16px",
    padding: "20px",
    maxHeight: "60vh",
    overflowY: "auto",
    msOverflowStyle: "none", // IE and Edge
    scrollbarWidth: "thin", // Firefox
    "::-webkit-scrollbar": {
      width: "6px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "3px",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "3px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  fileItem: mergeStyles({
    border: "1px solid #edebe9",
    borderRadius: "4px",
    padding: "12px",
    cursor: "pointer",
    backgroundColor: "#ffffff",
    transition: "all 0.2s ease",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
    ":hover": {
      backgroundColor: "#f8f8f8",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      transform: "translateY(-2px)",
    },
  }),
  attachmentButton: mergeStyles({
    color: "#2C529F",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    padding: "4px 8px",
    borderRadius: "4px",
    transition: "background-color 0.2s ease",
    ":hover": {
      backgroundColor: "#f3f2f1",
    },
  }),
  fileCount: mergeStyles({
    fontSize: 12,
    color: "#2C529F",
    textDecoration: "underline",
  }),
};

export const TextFieldWithDocuments: React.FC<TextFieldWithDocumentsProps> = ({
  value,
  onChange,
  files = [],
  fieldName,
  ...props
}) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const relevantFiles = files.filter((file) => file.fieldName === fieldName);

  const getTextFieldCustomStyles = (
    props: ITextFieldStyleProps
  ): Partial<ITextFieldStyles> => {
    return {
      wrapper: { position: "relative" },
      fieldGroup: {
        paddingRight: relevantFiles.length ? "80px" : undefined,
      },
      field: { paddingRight: relevantFiles.length ? "80px" : undefined },
    };
  };

  const renderAttachmentButton = () => {
    if (!relevantFiles.length) return null;

    return (
      <span
        role="button"
        tabIndex={0}
        className={styles.attachmentButton}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDialogOpen(true);
        }}
        style={{
          position: "absolute",
          right: "8px",
          top: "50%",
          zIndex: 1,
        }}
      >
        <Attachment style={{ flexShrink: 0 }} />
        <span>({relevantFiles.length})</span>
      </span>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <TextField
        defaultValue={value}
        onChange={(_, newValue) => onChange(newValue || "")}
        styles={getTextFieldCustomStyles}
        {...props}
      />
      {renderAttachmentButton()}

      <Dialog
        hidden={!isDialogOpen}
        onDismiss={() => setIsDialogOpen(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title: t(`{{fieldName}} Associated Documents`, { fieldName }),
          subText: t(`Showing ${relevantFiles.length} document`, {
            filesNum: relevantFiles.length,
            count: relevantFiles.length,
          }),
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: styles.dialog },
        }}
      >
        <div className={styles.filesContainer}>
          {relevantFiles.map((file, idx) => (
            <div key={file.id} className={styles.fileItem}>
              <FilePreview
                axiosInstance={axiosInstance}
                file={{
                  id: file.id,
                  name: `${file.fieldName} - ${idx + 1}`,
                }}
              />
            </div>
          ))}
        </div>
        <DialogFooter>
          <PrimaryButton
            onClick={() => setIsDialogOpen(false)}
            text={t("Close")}
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
