import { format as fnsFormat } from "date-fns";
import {
  EntityItemWithAccountRoles,
  EntityItemWithAccountRolesToMapInTable,
  roleMappings,
} from "../Components/Permissions/models";
import * as XLSX from "xlsx";
import * as Papa from "papaparse";
import saveAs from "file-saver";
import { t } from "i18next";

export function formatTimeString(timeString: string): string {
  const [hours, minutes, seconds, milliseconds] = timeString
    .split(/[:.]/)
    .map(Number);
  let formattedTime = "";

  if (hours > 0) {
    formattedTime += `${hours} ${t("hour", { count: hours })}, `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes} ${t("minute", { count: minutes })}, `;
  }
  if (seconds > 0) {
    formattedTime += `${seconds} ${t("second", { count: seconds })}, `;
  }

  if (milliseconds > 0) {
    if (milliseconds >= 1000) {
      const calculatedMiliseconds = Math.floor(milliseconds / 1000);

      formattedTime += `${calculatedMiliseconds} ${t("millisecond", { count: calculatedMiliseconds })}`;
    } else {
      formattedTime += `${milliseconds} ${t("microsecond", { count: milliseconds })}`;
    }
  }
  return formattedTime.trim();
}

export function convertToZipFileName(fileName: string): string {
  // Check if the file name ends with ".save"
  if (fileName.endsWith(".save")) {
    // Remove ".save" and then append ".zip"
    return fileName.slice(0, -5) + ".zip";
  }

  if (fileName.endsWith(".sav") || fileName.endsWith(".img")) {
    // Remove ".sav" and then append ".zip"
    return fileName.slice(0, -4) + ".zip";
  }

  // If it doesn't end with ".save," just append ".zip"
  return fileName + ".zip";
}

export async function convertCSVtoXLSX(
  csvData: string,
  fileName: string = "output.xlsx"
): Promise<void> {
  // Parse CSV data with semicolon delimiter and convert it to XLSX format
  const parsedData = Papa.parse(csvData, {
    delimiter: ";", // Specify the delimiter as ";"
    header: true, // If the first row contains headers
  });

  const ws = XLSX.utils.json_to_sheet(parsedData.data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Create a data URI
  const dataURI = XLSX.write(wb, { bookType: "xlsx", type: "base64" });

  // Convert the data URI to a Blob
  const byteCharacters = atob(dataURI);
  const byteArrays = [];
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }
  const blob = new Blob([new Uint8Array(byteArrays)], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Save the Blob as a file using file-saver
  saveAs(blob, fileName);
}

export function convertXLSXtoCSV(inputFile: File): Promise<File> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const arrayBuffer = event.target?.result as ArrayBuffer;
        const workbook = XLSX.read(arrayBuffer, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const csvData = XLSX.utils.sheet_to_csv(worksheet);

        // Use PapaParse to ensure proper CSV format
        Papa.parse(csvData, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            if (result.errors && result.errors.length > 0) {
              reject(result.errors);
            } else {
              const csvString = [Object.keys(result.data[0]).join(";")]
                .concat(result.data.map((row) => Object.values(row).join(";")))
                .join("\n");
              const csvBlob = new Blob([csvString], { type: "text/csv" });
              const csvFile = new File([csvBlob], "output.csv", {
                type: "text/csv",
              });
              resolve(csvFile);
            }
          },
          error: (error) => {
            reject(error.message);
          },
        });
      } catch (error) {
        reject(error);
      }
    };

    reader.readAsArrayBuffer(inputFile);
  });
}

export function mapPermissionsToTable(
  source: EntityItemWithAccountRoles
): EntityItemWithAccountRolesToMapInTable {
  const destination: EntityItemWithAccountRolesToMapInTable = {
    issuer: source.issuer,
    displayName: source.displayName,
    email: source.email,
    memberId: source.memberId,
    type: source.type,
    corporationId: source.corporation.id,
    corporationName: source.corporation.name,
    companyId: source.company.id,
    companyName: source.company.name,
    projectId: source.project.id,
    projectName: source.project.name,
    machineId: source.machine.id,
    machineName: source.machine.name,
  };

  for (const role of source.roles) {
    const mappedRole = roleMappings[role];
    if (mappedRole) {
      destination[mappedRole] = true;
    }
  }

  // Set boolean properties for roles that are not present in the source roles array
  for (const role of Object.keys(roleMappings)) {
    if (!source.roles.includes(role)) {
      const mappedRole = roleMappings[role];
      if (mappedRole) {
        destination[mappedRole] = false;
      }
    }
  }

  return destination;
}

function base64ToBlob(base64: string): Blob {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: "image/png" }); // Replace 'image/png' with the appropriate mime type if your base64 string is for a different image format.
}

export function getObjectUrlFromBase64(base64: string): string {
  let objectUrl: string = undefined;
  try {
    const blob = base64ToBlob(base64);
    objectUrl = URL.createObjectURL(blob);
  } catch (e: any) {
    objectUrl = undefined;
  }

  return objectUrl;
}

// Map from 0 t 24. To select colors.
export function mapInitialsToNumber(inputString: string): number {
  const truncatedString: string = inputString.slice(0, 4).toUpperCase();
  let hash: number = 0;
  for (let i = 0; i < truncatedString.length; i++) {
    hash += truncatedString.charCodeAt(i);
  }
  const mappedNumber: number = hash % 25;
  return mappedNumber;
}

export function extract4Initials(inputString: string | undefined): string {
  if (!inputString) return "";
  const words: string[] = inputString.split(" ");
  const initials: string[] = words.map((word) => word.charAt(0).toUpperCase());
  if (initials.length > 4) {
    initials.length = 4;
  }
  const result: string = initials.join("");

  return result;
}

export function isNumber(str: any) {
  if (str.trim() === "") {
    return false;
  }
  return !isNaN(str);
}

export const format = (date: Date | number, dateFormat = "Y-MM-dd") => {
  return fnsFormat(date, dateFormat);
};

export const formatWitHrs = (
  date: Date | number,
  dateFormat = "Y-MM-dd HH:mm"
) => {
  return fnsFormat(date, dateFormat);
};

export const formatSize = (size: number) => {
  const inMb = Math.round(size / 1024);
  return inMb.toString() + " Kb";
};

export const formatFileSize = (size: number) => {
  for (const unit of ["B", "KB", "MB"]) {
    if (size < 1024) return `${size.toFixed(2)} ${unit}`;
    size /= 1024.0;
  }
};

export const getOptionFromEnum = (t, enumCustom: any): any => {
  return (Object.keys(enumCustom) as Array<keyof typeof enumCustom>).map(
    (key) => {
      const text = enumCustom[key];
      const result = text.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return { key: key, text: t(finalResult), data: key };
    }
  );
};

export function isEmptyString(str: string | null | undefined) {
  if (str?.trim()) {
    return false;
  } else {
    return true;
  }
}

export const camelCaseToSpacedString = (text: string): string => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export function areObjectsEqual<T extends object>(obj1: T, obj2: T): boolean {
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  return (
    keys1.every((key) => {
      if (
        !keys2.includes(key) &&
        (obj1[key] === undefined || obj1[key] === 0)
      ) {
        return true;
      }

      const value1 = obj1[key];
      const value2 = obj2[key];

      if (
        (value1 === undefined || value1 === 0) &&
        (value2 === undefined || value2 === 0)
      ) {
        return true;
      }

      if (typeof value1 === "string" && typeof value2 === "string") {
        return value1.trim() === value2.trim();
      } else {
        return areObjectsEqual(value1, value2);
      }
    }) &&
    keys2.every((key) => {
      if (
        !keys1.includes(key) &&
        (obj2[key] === undefined || obj2[key] === 0)
      ) {
        return true;
      }

      const value1 = obj1[key];
      const value2 = obj2[key];

      if (
        (value1 === undefined || value1 === 0) &&
        (value2 === undefined || value2 === 0)
      ) {
        return true;
      }

      if (typeof value1 === "string" && typeof value2 === "string") {
        return value1.trim() === value2.trim();
      } else {
        return areObjectsEqual(value1, value2);
      }
    })
  );
}
export function formatDateHuman(date: Date, withMins: boolean = true): string {
  const now = new Date();
  const diffInMilliseconds = now.getTime() - date.getTime();
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30);

  if (diffInHours < 24) {
    if (withMins)
      return `${t("Today")} ${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}`;
    else return t("Today");
  } else if (diffInHours < 48) {
    if (withMins)
      return `${t("Yesterday")} ${pad(date.getUTCHours())}:${pad(
        date.getUTCMinutes()
      )}`;
    else return t("Yesterday");
  } else if (diffInDays < 7) {
    return `${diffInDays} ${t("days ago")}`;
  } else if (diffInDays < 14) {
    return t("One week ago");
  } else if (diffInMonths < 1) {
    return `${diffInWeeks} ${t("weeks ago")}`;
  } else if (diffInMonths < 2) {
    return t("One month ago");
  } else {
    return `${diffInMonths} ${t("months ago")}`;
  }
}

function pad(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}

export function isNullOrEmpty(valor: string | null): boolean {
  return valor === null || valor === "";
}

export function isValidDalogId(input: string | null): boolean {
  if (input === null) return false;
  const regex = /^\d{2}-\d{3}-\d{2}$/;

  return regex.test(input);
}
