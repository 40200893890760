import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  endOfMonth,
  format,
  startOfMonth,
} from "date-fns";
import dayjs from "dayjs";
import { groupBy } from "lodash-es";

import ChartFourthSymbolIcon from "../../../../../assets/svg/ChartSymbols/ChartFourthSymbolIcon.svg";
import {
  DATE_FORMAT_DAYJS,
  monthNames,
  getOperationView,
  shortMonthNames,
} from "./config";
import { t } from "i18next";

export const getMonthName = (monthIndex: any) => {
  return monthNames[monthIndex];
};

export const generateYearsOptions = (startDate: any, endDate: any) => {
  if (!startDate || !endDate) return [];
  const yearsList =
    eachYearOfInterval({
      start: new Date(startDate),
      end: new Date(endDate),
    }) || [];
  const years = yearsList.map((yearDate, idx) => {
    const year = new Date(yearDate).getFullYear();
    let start = new Date(year, 0, 1, 0, 0, 0);
    let end = new Date(year, 11, 31, 23, 59, 59);

    if (idx === 0) {
      start = new Date(startDate);
    }

    if (idx === yearsList.length - 1) {
      end = new Date(endDate);
    }

    return {
      year,
      start,
      end,
    };
  });

  return years.reverse();
};

export const generateMonthsOptions = (startDate: any, endDate: any) => {
  if (!startDate || !endDate) return [];
  const year = new Date(startDate).getFullYear();
  const monthsList =
    eachMonthOfInterval({
      start: new Date(startDate),
      end: new Date(endDate),
    }) || [];

  return monthsList.map((monthDate, idx) => {
    const month = new Date(monthDate).getMonth();
    let start = new Date(year, month, 1, 0, 0, 0);
    let end = new Date(year, month + 1, 0, 23, 59, 59);

    if (idx === 0) {
      start = new Date(startDate);
    }

    if (idx === monthsList.length - 1) {
      end = new Date(endDate);
    }

    return {
      month: getMonthName(month),
      start,
      end,
    };
  });
};

export const prepareRequestPeriod = (
  t: any,
  view: any = {},
  year: any = {},
  month: any = {}
) => {
  const yearStart = year.start
    ? dayjs(year.start).format(DATE_FORMAT_DAYJS)
    : null;
  const yearEnd = year.end ? dayjs(year.end).format(DATE_FORMAT_DAYJS) : null;
  const monthStart = month.start
    ? dayjs(month.start).format(DATE_FORMAT_DAYJS)
    : null;
  const monthEnd = month.end
    ? dayjs(month.end).format(DATE_FORMAT_DAYJS)
    : null;

  if (view.key === getOperationView(t)[0].key) {
    return {
      startDate: yearStart,
      endDate: yearEnd,
    };
  } else {
    return {
      startDate: monthStart,
      endDate: monthEnd,
    };
  }
};

export const initializeOperationChart = ({ chart, root, view }: any) => {
  const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
  cursor.lineY.set("visible", false);

  chart.set("panX", false);
  chart.set("panY", false);
  chart.set("wheelX", "none");
  chart.set("wheelY", "none");
  chart.zoomOutButton.set("forceHidden", true);

  const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 5 });

  xRenderer.labels.template.setAll({
    fill: am5.color("#3B3A39"),
    fontSize: 10,
    paddingBottom: 20,
    paddingTop: 10,
  });

  xRenderer.grid.template.setAll({
    location: 1,
  });

  xRenderer.grid.template.setAll({
    stroke: am5.color("#f3f2f1"),
    strokeWidth: 1,
    strokeOpacity: 1,
  });

  const xAxis = chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      zoomY: false,
      zoomX: false,
      maxDeviation: 0,
      categoryField: "label",
      baseValue: 0,
      start: 0,
      renderer: xRenderer,
    })
  );

  const yRenderer = am5xy.AxisRendererY.new(root, {});

  yRenderer.grid.template.setAll({
    stroke: am5.color("#f3f2f1"),
    strokeWidth: 1,
    strokeOpacity: 1,
  });

  yRenderer.labels.template.setAll({
    fill: am5.color("#3B3A39"),
    fontSize: 10,
  });

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      min: 0,
      zoomY: false,
      zoomX: false,
      maxDeviation: 0,
      renderer: yRenderer,
    })
  );

  const yAxisLabel = am5.Label.new(root, {
    ariaLabel: "yAxisLabel",
    rotation: -90,
    text:
      view.key === getOperationView(t)[0].key
        ? t("Hours / Month")
        : t("Hours / Day"),
    y: am5.p50,
    centerX: am5.p50,
    fill: am5.color("#3B3A39"),
    fontSize: 10,
    fontWeight: "600",
  });

  const container: any = am5.Container.new(root, {
    ariaLabel: "yAxisLabelContainer",
    layout: root.verticalLayout,
    height: am5.percent(100),
    centerX: am5.p100,
  });

  container.children.unshift(yAxisLabel);

  yAxis.children.unshift(container);

  container.children.unshift(
    am5.Picture.new(root, {
      ariaLabel: "y-axis-icon",
      width: 16,
      height: 16,
      dx: 5,
      y: 205,
      src: ChartFourthSymbolIcon,
    })
  );

  const seriesTooltip: any = am5.Tooltip.new(root, {
    getFillFromSprite: false,
    getStrokeFromSprite: false,
    autoTextColor: false,
    getLabelFillFromSprite: false,
    labelText: "{date}:\n [bold]{valueY}[/]",
  });

  seriesTooltip.get("background").setAll({
    fill: am5.color("#ffffff"),
    stroke: am5.color("#3B3A39"),
    fillOpacity: 1,
  });

  seriesTooltip.label.setAll({
    fill: am5.color("#3B3A39"),
    fontSize: 12,
  });

  const series = chart.series.push(
    am5xy.ColumnSeries.new(root, {
      name: "Series",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      sequencedInterpolation: true,
      categoryXField: "label",
      fill: am5.color(0x2c529f),
      stroke: am5.color(0x2c529f),
      tooltip: seriesTooltip,
    })
  );

  series.columns.template.setAll({
    cornerRadiusTL: 3,
    cornerRadiusTR: 3,
    maxWidth: 46,
  });

  const scrollbarX = chart.get("scrollbarX");
  const scrollbarY = chart.get("scrollbarY");
  scrollbarX.setAll({ visible: false });
  scrollbarY.setAll({ visible: false });

  return {
    yAxisLabel,
    xAxis,
    yAxis,
    series,
  };
};

export const prepareDataForYearlyView = ({ t, data, year: { start } }: any) => {
  const grouped = groupBy(data, ({ timeStamp }) =>
    new Date(timeStamp).getMonth()
  );
  const year = new Date(start).getFullYear();
  return monthNames.map((monthName, idx) => {
    const series = grouped[idx];
    let value = 0;
    if (series && series.length > 0) {
      value = series.reduce((accumulator, { value = 0 }) => {
        return accumulator + value;
      }, 0);
    }
    return {
      date: `${t(shortMonthNames[idx])} ${year}`,
      label: t(shortMonthNames[idx]),
      value: value,
    };
  });
};

export const prepareDataForMonthlyView = ({
  t,
  data,
  month: { start },
}: any) => {
  const grouped = groupBy(data, ({ timeStamp }) =>
    String(new Date(timeStamp).getDate()).padStart(1, "0")
  );

  const currentDate = new Date(start);
  const firstDayOfMonth = startOfMonth(currentDate);
  const lastDayOfMonth = endOfMonth(currentDate);
  const year = currentDate.getFullYear();
  const month = shortMonthNames[currentDate.getMonth()];

  const daysOfMonth = eachDayOfInterval({
    start: firstDayOfMonth,
    end: lastDayOfMonth,
  }).map((date) => format(date, "d"));

  return daysOfMonth.map((day) => {
    const series = grouped[day];
    let value = 0;
    if (series && series.length > 0) {
      value = series.reduce((accumulator, { value = 0 }) => {
        return accumulator + value;
      }, 0);
    }
    return {
      date: `${day} ${t(month)} ${year}`,
      label: day,
      value: value,
    };
  });
};

export const prepareDataForChart = ({
  t,
  data = [],
  view,
  month,
  year,
}: any) => {
  if (data.length === 0 || !year || !month) return [];

  if (view.key === getOperationView(t)[0].key) {
    return prepareDataForYearlyView({ t, data, year });
  }

  return prepareDataForMonthlyView({ t, data, month });
};
