import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { initReactI18next } from "react-i18next";
import en from "./languages/en.json";
import es from "./languages/es.json";
import de from "./languages/de.json";
import fr from "./languages/fr.json";
import pt from "./languages/pt.json";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      de: {
        translation: de,
      },
      fr: {
        translation: fr,
      },
      pt: {
        translation: pt,
      },
    },
  });
