import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";
import { listProjects } from "./api";
import { ResponseProjectDetails } from "./models";
import { t } from "i18next";

export interface ProjectState {
  projects: ResponseProjectDetails[];
  status: Status;
  error: string;
}

const initialState: ProjectState = {
  projects: [],
  status: Status.void,
  error: "",
};

export const listAsyncProjs = createAsyncThunk("projects/list", async () => {
  const response = await listProjects();

  if (response.length > 0) {
    return response.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
  return response;
});

export const ProjectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setStatus: (state: ProjectState, action: PayloadAction<Status>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncProjs.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncProjs.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.projects = action.payload;
      })
      .addCase(listAsyncProjs.rejected, (state) => {
        state.status = Status.error;
        state.error = t("Error loading projects. Please try again later.");
      });
  },
});

export const selectProjects = (state: RootState) => state.projects.projects;

export const selectProjectsStatus = (state: RootState) => state.projects.status;

export const selectProjectsError = (state: RootState) => state.projects.error;

export const selectProject = (id: string) => (state: RootState) => {
  if (
    state.projects &&
    state.projects.projects &&
    state.projects.projects.length > 0
  ) {
    return state.projects.projects.find(
      (c: ResponseProjectDetails) => c.id === id
    );
  }

  return undefined;
};

export const { setStatus } = ProjectSlice.actions;
export default ProjectSlice.reducer;
