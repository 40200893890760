/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";

import {
  FormItemProps,
  FormItemType,
  renderFormItems,
  useZodForm,
} from "../common/Form";
import { notification } from "../common/Notification";
import { DialogSize } from "../common/Dialog";

import { IDialogProps, DialogType, Text } from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { maxLengthType1 } from "../../schema/Constants";
import {
  CreateMachine,
  MachineToList,
  ResponseMachineDetailsWithChilds,
  UpdateMachine,
} from "./models";
import { areObjectsEqual } from "../../schema/Utils";
import FormItemRow from "../Generic/FormItemRow";
import ControlledComboBox from "../Generic/ControlledComboBox";
import { getMachineDetails } from "./MachineDetails/api";
import FormDialog from "../Generic/FormDialog";
import { createMachine, updateMachine } from "./api";
import { useAppDispatch } from "../../Hooks";
import { listAsyncMachines } from "./reducer";
import { ResponseProjectDetails } from "../Projects/models";
import { useProjects } from "../../Hooks";
import { Status } from "../../schema/status";

import { useTranslation } from "react-i18next";

type MachineFormData = Partial<UpdateMachine> & {
  gearBoxManufacturer: string;
  gearBoxName: string;
  gearBoxPower: string;
  gearBoxRotationalSpeed: string;
  gearBoxSerialnumber: string;
  gearBoxNotation: string;
};

type BasicFormProps = IDialogProps & {
  onClose: () => void;
};

type AddEditDialogProps = BasicFormProps & {
  title: string;
  data?: MachineFormData;
  schema: any;
  isLoading: boolean;
  onSubmit: (formData: MachineFormData) => void;
};

type AddDialogProps = BasicFormProps & {
  dalogIds: string[];
};

type EditDialogProps = AddDialogProps & {
  tableItem: MachineToList;
};

const numberRegex = /^\d*\.?\d*$/;

const getMachineFields: (t: any) => FormItemProps[] = (t) => [
  {
    name: "dalogId",
    type: FormItemType.TextField,
    groupProps: { label: "Dalog Id *" },
    disabled: false,
  },
  {
    name: "name",
    type: FormItemType.TextField,
    groupProps: { label: t("Name") },
    disabled: false,
  },
  {
    name: "customerCode",
    type: FormItemType.TextField,
    groupProps: { label: t("Customer Code") },
    disabled: false,
  },
  {
    name: "manufacturer",
    type: FormItemType.TextField,
    groupProps: { label: t("Manufacturer") },
    disabled: false,
  },
  {
    name: "process",
    type: FormItemType.TextField,
    groupProps: { label: t("Process") },
    disabled: false,
  },
  {
    name: "type",
    type: FormItemType.TextField,
    groupProps: { label: t("Type") },
    disabled: false,
  },
  {
    name: "notation",
    type: FormItemType.TextField,
    groupProps: { label: t("Notation") },
    disabled: false,
  },
  {
    name: "gearBoxManufacturer",
    type: FormItemType.TextField,
    groupProps: { label: t("Gearbox Manufacturer") },
    disabled: false,
  },
  {
    name: "gearBoxName",
    type: FormItemType.TextField,
    groupProps: { label: t("Gearbox Name") },
    disabled: false,
  },
  {
    name: "gearBoxPower",
    type: FormItemType.TextField,
    groupProps: { label: t("Gearbox Power") },
    disabled: false,
  },
  {
    name: "gearBoxRotationalSpeed",
    type: FormItemType.TextField,
    groupProps: { label: t("Gearbox Rotational Speed") },
    disabled: false,
  },
  {
    name: "gearBoxSerialnumber",
    type: FormItemType.TextField,
    groupProps: { label: t("Gearbox Serial Number") },
    disabled: false,
  },
  {
    name: "gearBoxNotation",
    type: FormItemType.TextField,
    groupProps: { label: t("Gearbox Notation") },
    disabled: false,
  },
];

const getSchema = (t, dalogIds: string[]) =>
  z
    .object({
      dalogId: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(
            `Serial Number must contain at most {{maxLength}} character(s)`,
            { maxLength: maxLengthType1 }
          ),
        })
        .regex(/^[0-9]{2}-[0-9]{3}-[0-9]{2}$/)
        .transform((e) => (e === "" ? "" : e)),
      name: z.string().optional(),
      customerCode: z.string().optional(),
      manufacturer: z.string().optional(),
      process: z.string().optional(),
      type: z.string().optional(),
      notation: z.string().optional(),
      gearBoxManufacturer: z.string().optional(),
      gearBoxName: z.string().optional(),
      gearBoxPower: z.string().optional(),
      gearBoxRotationalSpeed: z.string().optional(),
      gearBoxSerialnumber: z.string().optional(),
      gearBoxNotation: z.string().optional(),
    })
    .superRefine((machine, ctx) => {
      // Unique machine number
      if (
        dalogIds.findIndex(
          (dalogId) =>
            dalogId.trim().toLowerCase() ===
            machine.dalogId.trim().toLowerCase()
        ) !== -1
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["dalogId"],
          message: t("The dalog Id already exists"),
        });
      }

      // Validates gearbox numbers
      const valid = new RegExp(numberRegex);
      let isNumber = valid.test(machine.gearBoxPower);
      if (!isNumber) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["gearBoxPower"],
          message: t("Try a number"),
        });
      }

      isNumber = valid.test(machine.gearBoxRotationalSpeed);
      if (!isNumber) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["gearBoxRotationalSpeed"],
          message: t("Try a number"),
        });
      }
    });

const buildFormData = (
  data: ResponseMachineDetailsWithChilds | null
): MachineFormData => {
  let result: MachineFormData = {
    dalogId: data?.dalogId || "",
    projectId: data?.projectId || "",
    name: data?.name || "",
    customerCode: data?.customerCode || "",
    manufacturer: data?.manufacturer || "",
    process: data?.process || "",
    type: data?.type || "",
    notation: data?.notation || "",
    gearBoxManufacturer: data?.gearbox?.manufacturer || "",
    gearBoxName: data?.gearbox?.name || "",
    gearBoxPower: data?.gearbox?.power?.toString() || "",
    gearBoxRotationalSpeed: data?.gearbox?.rotationalSpeed?.toString() || "",
    gearBoxSerialnumber: data?.gearbox?.serialnumber || "",
    gearBoxNotation: data?.gearbox?.notation || "",
  };

  if (data) {
    result = { ...result, id: data.id };
  }

  return result;
};

export const MachineAddDialog = ({
  dalogIds,
  onClose,
  ...rest
}: AddDialogProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const schema = useMemo(() => getSchema(t, dalogIds), [t]);
  const dispatch = useAppDispatch();

  const onSubmit = (formData: MachineFormData) => {
    const toSend: CreateMachine = {
      ...formData,
      dalogId: formData.dalogId,
      projectId: formData.projectId,
      gearbox: {
        manufacturer: formData.gearBoxManufacturer,
        name: formData.gearBoxName,
        notation: formData.gearBoxNotation,
        power: Number(formData.gearBoxPower) as number,
        rotationalSpeed: Number(formData.gearBoxRotationalSpeed) as number,
        serialnumber: formData.gearBoxSerialnumber,
      },
    };

    // Sends the request.
    setIsLoading(true);
    createMachine(toSend).then((response) => {
      setIsLoading(false);
      if (response.status !== 201) {
        notification.error(t("Failure: Adding new machine."));
        return;
      }

      notification.success(t("Success: Adding new machine."));
      notification.warning(
        t(
          "The machine creation process will take around one minute to finish. Please refresh this page after the mentioned time."
        )
      );
      dispatch(listAsyncMachines());
      onClose?.();
    });
  };

  return (
    <AddEditDialog
      {...rest}
      title={t("Add New Machine")}
      data={buildFormData(null)}
      schema={schema}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

export const MachineEditDialog = ({
  tableItem,
  dalogIds,
  onClose,
  ...rest
}: EditDialogProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<MachineFormData>(null);
  const dispatch = useAppDispatch();
  const schema = getSchema(
    t,
    dalogIds.filter((item) => item !== tableItem?.dalogId)
  );
  let root = document.getElementById("root");

  // Fetches the machine entry data.
  useEffect(() => {
    let data: MachineFormData = buildFormData(null);
    if (!tableItem || !tableItem.id) {
      setFormData(data);
      return;
    }

    // Gets the machine details
    root.style.cursor = "wait";
    getMachineDetails(tableItem.id).then((response) => {
      if (!response) {
        notification.error(t("Failure: Getting machine details."));
        onClose?.();
        return;
      }

      let machineDetails: ResponseMachineDetailsWithChilds = { ...response };
      data = buildFormData(machineDetails);
      setFormData(data);
    });
  }, [tableItem]);

  const onSubmit = (formData: MachineFormData) => {
    const toSend: UpdateMachine = {
      ...formData,
      id: tableItem.id,
      projectId: formData.projectId,
      gearbox: {
        manufacturer: formData.gearBoxManufacturer,
        name: formData.gearBoxName,
        notation: formData.gearBoxNotation,
        power: Number(formData.gearBoxPower) as number,
        rotationalSpeed: Number(formData.gearBoxRotationalSpeed) as number,
        serialnumber: formData.gearBoxSerialnumber,
      },
      dalogId: formData.dalogId,
    };

    // Sends the request.
    setIsLoading(true);
    updateMachine(toSend).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(t("Failure: Updating machine."));
        return;
      }

      notification.success(t("Success: Updating machine."));
      dispatch(listAsyncMachines());
      onClose?.();
    });
  };

  // Changes the cursor pointer
  if (formData) {
    root.style.cursor = "default";
  }

  return (
    formData && (
      <AddEditDialog
        {...rest}
        title={t("Edit Machine")}
        data={formData}
        schema={schema}
        isLoading={isLoading}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    )
  );
};

const AddEditDialog = ({
  title,
  data,
  schema,
  isLoading,
  onSubmit,
  onClose,
  ...rest
}: AddEditDialogProps) => {
  const { t } = useTranslation();
  const { projects, status } = useProjects();
  const [selectedProject, setSelectedProject] = useState<
    ResponseProjectDetails | undefined
  >(undefined);

  const [isFormChanged, setIsFormChanged] = useState(!data);
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...{ defaultValues: { ...data } },
  });

  // Creates the projects map
  useEffect(() => {
    if (status !== Status.idle || projects.size === 0) {
      return;
    }

    setSelectedProject(projects.get(data.projectId));
  }, [status, projects.size]);

  // Checks whether the data has changed, compared to the initial data.
  useEffect(() => {
    if (!data) {
      return;
    }

    let formDataHasChanged =
      !areObjectsEqual(control._formValues, control._defaultValues) ||
      data.projectId !== selectedProject?.id;
    setIsFormChanged(formDataHasChanged);
  }, [watch()]);

  const onSubmitHandler = (formData: MachineFormData) => {
    let data: MachineFormData = { ...formData, projectId: selectedProject?.id };
    onSubmit?.(data);
  };

  return (
    <FormDialog
      {...rest}
      title={title}
      isLoading={isLoading}
      isValid={isValid && selectedProject && isFormChanged}
      type={DialogType.normal}
      size={DialogSize.M}
      onSubmit={handleSubmit(onSubmitHandler)}
      onClose={onClose}
    >
      <FormItemRow label={t("Company")} style={{ marginBottom: "0.75em" }}>
        <Text variant="medium" as={"p"} style={{ fontWeight: 600 }}>
          {selectedProject?.company?.name}
        </Text>
      </FormItemRow>
      <FormItemRow label={t("Project *")}>
        <ControlledComboBox
          options={Array.from(projects.values()).map((project) => {
            return {
              key: project.id,
              text: project.name,
            };
          })}
          selectedKey={selectedProject?.id ?? ""}
          disabled={false}
          onKeySelected={(key: string) => setSelectedProject(projects.get(key))}
        />
      </FormItemRow>
      {renderFormItems(getMachineFields(t), {
        control,
        errors: errors as { [schemaProp: string]: FieldError },
      })}
    </FormDialog>
  );
};
