// Original path: src/src/Components/WirelessSensors/components/Tree/ChartCommandBar.tsx
import { useEffect } from "react";
import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar";

type ChartCommandBarProps = {
  title: string;
  onRefresh: () => void;
  onTrigger?: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const refreshInterval: ReturnType<typeof setInterval> = null;
const ChartCommandBar = ({
  title,
  onRefresh,
  onTrigger,
}: ChartCommandBarProps) => {
  useEffect(() => {
    return () => clearInterval(refreshInterval);
  }, []);

  const items: CommandBarItemProps[] = [
    {
      key: "refresh",
      text: "",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Refresh" },
      onClick: onRefresh,
    },
    {
      key: "trigger",
      text: "",
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Trending12" },
      onClick: onTrigger,
    },
  ];

  return (
    <div className="chart-header">
      <div className="chart-header-left">
        <div className="chart-header-title">{title}</div>
        <BaseCommandBar
          items={computeCommandBarItems(items)}
          styles={{ root: { margin: 0, padding: 0 } }}
        />
      </div>
    </div>
  );
};

export default ChartCommandBar;
