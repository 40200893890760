import { Link } from "react-router-dom";

const LINK_STYLES = {
  display: "block",
  padding: "8px",
  border: "4px solid #FFF",
  borderRadius: "2px",
  color: "inherit",
  textDecoration: "none",
};

export default function SoloMarker(props) {
  const { name, color, projectName, machineId } = props;

  return (
    <Link
      to={`machine-cv/summary?id=${machineId}`}
      style={{ ...LINK_STYLES, background: color }}
    >
      <div style={{ fontSize: 10 }}>{projectName}</div>
      <div style={{ fontSize: 14, fontWeight: 600 }}>{name}</div>
    </Link>
  );
}
