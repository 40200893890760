import type { AxiosInstance } from "axios";

import AppLayout from "../../../templates/AppLayout";

import Page from "./Page";

type HomePageSettings = {
  dashboards: boolean;
  machines: boolean;
  bookmarks: boolean;
  tasks: boolean;
  machinesCounter: boolean;
  conditions: boolean;
  lastDataUpdate: boolean;
  worldMap: boolean;
  gridItemsCount: number;
};
interface HomeProps {
  axiosInstance: AxiosInstance;
  settings?: HomePageSettings;
}

const defaultHomePageSettings = {
  dashboards: false,
  machines: true,
  bookmarks: false,
  tasks: true,
  machinesCounter: true,
  conditions: true,
  lastDataUpdate: true,
  worldMap: true,
  gridItemsCount: 6,
};

const Home = ({
  axiosInstance,
  settings = defaultHomePageSettings,
}: HomeProps) => (
  <AppLayout axiosInstance={axiosInstance}>
    <Page settings={settings} />
  </AppLayout>
);

export default Home;
