import type { IStackProps } from "@fluentui/react";
import { Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";

export type SpinProps = IStackProps & {
  text?: string;
};

const Spin = ({ text, style, ...rest }: SpinProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      {...rest}
      verticalAlign="center"
      horizontalAlign="center"
      style={{ minHeight: "10em", ...style }}
      tokens={{ childrenGap: 10 }}
    >
      <Spinner size={SpinnerSize.large} />
      <Text variant="medium" style={{ fontWeight: 600 }}>
        {text ?? t("Loading data...")}
      </Text>
    </Stack>
  );
};

export default Spin;
