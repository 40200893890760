import { Stack, Text } from "@fluentui/react";

import {
  conditionColor,
  getConditionFormat,
} from "../../../../../modules/machine-cv/constants";
import type { MachineCvOverviewMachine } from "../../../../../types";
import { useTranslation } from "react-i18next";

const Condition = ({
  conditions,
}: // comment,
{
  conditions: MachineCvOverviewMachine["conditions"];
  // comment: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {/*<>{comment}</>*/}
      {conditions?.map(({ name, value }) => (
        <Stack
          key={name}
          horizontal
          horizontalAlign="space-between"
          style={{
            color: "#fff",
            padding: "4px 16px",
            backgroundColor: conditionColor[value],
          }}
        >
          <Text style={{ color: "inherit" }}>{name}</Text>
          <Text style={{ color: "inherit", fontWeight: 600 }}>
            {getConditionFormat(t)[value]}
          </Text>
        </Stack>
      ))}
    </>
  );
};

export default Condition;
