/* eslint-disable import/no-named-as-default */

import { Spinner, SpinnerSize } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import Tree, { useTreeState } from "react-hyper-tree";

import { findLocationTimezone } from "../../../analysis-trend-view/utils/getRangeFormat";
import { NoData } from "../../../../Components/common/NoData";

import useChartTimestampStore from "../../hooks/useChartTimestampStore";
import useHeaderControlsStore from "../../hooks/useHeaderControlsStore";
import useLayoutTypes from "../../hooks/useLayoutTypes";
import useRawDataPointsStore from "../../hooks/useRawDataPointsStore";
import useSelectedDataPointsStore from "../../hooks/useSelectedDataPointsStore";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import useSignalMetaData from "../../hooks/useSignalMetaData";
import useSignalMonitoring from "../../hooks/useSignalMonitoring";
import type { DataPoint, Nodes, NodesProps } from "../../types";
import {
  useCatalogueStore,
  useFrequencyCatalogueData,
} from "../FrequencyCatalogueDialog/hooks";
import CustomSelectNode from "./CustomSelectNode";
import { stepHandler } from "./methods";
import { useTranslation } from "react-i18next";

const CheckboxTree = ({
  startDateTime,
  endDateTime,
  customStyle,
  signal,
}: NodesProps) => {
  const { t } = useTranslation();

  const selectedSignals = useSelectedSignalsStore(
    (store) => store.selectedSignals
  );
  const signalMetaData = useSignalMetaData(signal);
  const signalTimezone = findLocationTimezone(signalMetaData?.project);

  const { isCompareLayout } = useLayoutTypes();

  const {
    dataPoints: treeDataPoints,
    isLoading,
    isError,
  } = useSignalMonitoring({
    signalTimezone,
    signal,
    startDateTime,
    endDateTime,
    selectedSignals,
  });

  const machinesIds: string[] = Array.from(
    new Set(
      selectedSignals
        .filter(({ dataType }) => dataType !== "Trend")
        .map(({ machineId }) => machineId) as string[]
    )
  );

  const selectedSignalsIDs = selectedSignals.map(({ id }) => id);

  const { initSelectedSignalsStore } = useCatalogueStore((store: any) => ({
    initSelectedSignalsStore: store.initSelectedSignalsStore,
  }));

  useFrequencyCatalogueData(machinesIds);

  const { addTimestamp, removeTimestamp, setTrendChartTimestamp } =
    useChartTimestampStore((store) => ({
      timestamps: store.timestamps,
      addTimestamp: store.addTimestamp,
      removeTimestamp: store.removeTimestamp,
      setTrendChartTimestamp: store.setTrendChartTimestamp,
    }));

  const [lastSelectedNode, setLastSelectedNode] = useState({
    nodeId: "",
    timestamp: "",
  });

  /*

  Set the last selected node id when clicking on a checkbox from tree
  (data comes from the CustomSelectNode component when the onSelectedNodeId function inside it is called)

  */

  const handleSetLastSelectedNode = ({
    nodeId,
    timestamp,
  }: {
    nodeId: string;
    timestamp: string;
  }) => {
    setLastSelectedNode({
      ...lastSelectedNode,
      nodeId: nodeId,
      timestamp: timestamp,
    });
  };

  const {
    selectedDataPoints,
    updateSelectedDataPoint,
    addSelectedDataPoint,
    setTrendChartRequestValues,
    setSelectedDataPointsTimestamps,
  } = useSelectedDataPointsStore((store) => ({
    selectedDataPoints: store.selectedDataPoints,
    updateSelectedDataPoint: store.updateSelectedDataPoint,
    addSelectedDataPoint: store.addSelectedDataPoint,
    setTrendChartRequestValues: store.setTrendChartRequestValues,
    setSelectedDataPointsTimestamps: store.setSelectedDataPointsTimestamps,
  }));

  const { setIsFetchingRawDataPoints } = useRawDataPointsStore((store) => ({
    setIsFetchingRawDataPoints: store.setIsFetchingRawDataPoints,
  }));

  const { prevTimestamp, nextTimestamp, period } = useHeaderControlsStore(
    (store) => ({
      prevTimestamp: store.prevTimestamp,
      nextTimestamp: store.nextTimestamp,
      period: store.period,
    })
  );

  useEffect(() => {
    setIsFetchingRawDataPoints(isLoading);
  }, [isLoading]);

  const nodes = useMemo(() => {
    if (!treeDataPoints) return [];
    return treeDataPoints;
  }, [treeDataPoints]) as Nodes[];

  useEffect(() => {
    if (!nodes) return;

    setLastSelectedNode({
      ...lastSelectedNode,
      nodeId: nodes[0]?.children?.[0]?.children?.[0]?.children?.[0]?.id,
    });
  }, [nodes]);

  /*****
   * Watch if the last selected node changes and add the datapoint for that node in the datapoints store
   * (NOTE: in this case when we add a new datapoint to the store, a new request will be made automatically with that new datapoint and get the data for the chart)
   *****/

  useEffect(() => {
    if (lastSelectedNode.nodeId === "") return;

    const nodeItem = {
      signal: signal.id,
      dataPoints: [lastSelectedNode.nodeId],
    };

    const matchingDataPoint = selectedDataPoints.find(
      (x) => x.signal === signal.id
    );

    if (matchingDataPoint) {
      updateSelectedDataPoint(nodeItem as DataPoint);
    } else {
      addSelectedDataPoint(nodeItem as DataPoint);
    }

    setSelectedDataPointsTimestamps(
      lastSelectedNode.nodeId,
      `${handlers.getNode(lastSelectedNode.nodeId)?.getParent()?.id} ${
        handlers.getNode(lastSelectedNode.nodeId)?.getData().name
      }`
    );
  }, [lastSelectedNode.nodeId, selectedSignals]);

  /*****
   * Watch if the lastSelectedNodeId and open the tree to the last node and select it
   * Then set the store timestamp of the current selected node and in the same time set the values for the trendview chart
   * (NOTE: the values for the trend view chart will be used only to make the request for the chart data)
   *****/

  useEffect(() => {
    if (lastSelectedNode.nodeId === "" || isLoading) return;

    handlers.setSelected(lastSelectedNode.nodeId, true);

    handlers
      .getNode(lastSelectedNode.nodeId)
      ?.getParent()
      ?.getParent()
      ?.getParent()
      ?.setOpened(true);

    handlers
      .getNode(lastSelectedNode.nodeId)
      ?.getParent()
      ?.getParent()
      ?.setOpened(true);

    handlers.getNode(lastSelectedNode.nodeId)?.getParent()?.setOpened(true);

    if (
      handlers.getNode(lastSelectedNode.nodeId)?.getParent()?.id &&
      handlers.getNode(lastSelectedNode.nodeId)?.getData().name
    ) {
      const selectedTimestamp = `${
        handlers.getNode(lastSelectedNode.nodeId)?.getParent()?.id
      } ${handlers.getNode(lastSelectedNode.nodeId)?.getData().name}`;

      // Setting the chart timestamp for the current selected signal
      addTimestamp(signal.id, selectedTimestamp);

      // Setting the trend chart timestamp
      setTrendChartTimestamp(selectedTimestamp);

      setLastSelectedNode({
        ...lastSelectedNode,
        nodeId: lastSelectedNode.nodeId,
        timestamp: selectedTimestamp,
      });
    }

    setTrendChartRequestValues({
      month: handlers.getNode(lastSelectedNode.nodeId)?.getParent()?.getParent()
        ?.data?.month,
      year: handlers
        .getNode(lastSelectedNode.nodeId)
        ?.getParent()
        ?.getParent()
        ?.getParent()?.data?.id,
    });

    return () => {
      removeTimestamp(signal.id);
    };
  }, [lastSelectedNode.nodeId, period?.key, isLoading]);

  const { required, handlers } = useTreeState({
    data: nodes,
    id: `datapoints_tree_${signal.id}`,
    multipleSelect: isCompareLayout ? true : false,
  });

  useEffect(() => {
    if (nextTimestamp) {
      stepHandler(
        true,
        lastSelectedNode,
        handlers,
        nodes,
        setLastSelectedNode,
        signal.id
      );
    }
  }, [nextTimestamp]);

  useEffect(() => {
    if (prevTimestamp) {
      stepHandler(
        false,
        lastSelectedNode,
        handlers,
        nodes,
        setLastSelectedNode,
        signal.id
      );
    }
  }, [prevTimestamp]);

  useEffect(() => {
    initSelectedSignalsStore(selectedSignalsIDs);
  }, [selectedSignalsIDs[0], selectedSignalsIDs.length]);

  if (isLoading) {
    return (
      <Spinner
        style={{ width: "100%", height: "100%" }}
        size={SpinnerSize.large}
        label={t("Loading data points...")}
      />
    );
  }

  if (nodes.length === 0 || isError) {
    return (
      <NoData
        text={t("There is no data")}
        styles={{
          root: {
            width: "100%",
            height: "100%",
          },
        }}
      />
    );
  }

  return (
    <>
      <div style={customStyle}>
        <Tree
          key={signal.id}
          {...required}
          {...handlers}
          disableLines
          depthGap={15}
          disableTransitions={true}
          renderNode={(props) => (
            <CustomSelectNode
              key={signal.id}
              signalId={signal.id}
              handlers={handlers}
              {...props}
              {...handlers}
              selectedSignals={selectedSignals}
              onSelectedNode={handleSetLastSelectedNode}
            />
          )}
        />
      </div>
    </>
  );
};

export default CheckboxTree;
