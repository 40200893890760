import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";
import { listMachines } from "./api";
import {
  MachineListItem,
  MachineToList,
  ResponseMachineDetails,
} from "./models";
import { t } from "i18next";

export interface MachineListItemAux extends MachineListItem {
  nameCompany: string;
  nameProject: string;
}

export interface MachineState {
  machines: ResponseMachineDetails[];
  machineToList: MachineToList[];
  status: Status;
  error: string;
}

const initialState: MachineState = {
  machines: [],
  machineToList: [],
  status: Status.void,
  error: "",
};

export const listAsyncMachines = createAsyncThunk("machines/list", async () => {
  const response = await listMachines();

  if (response.length > 0) {
    return response.sort((a, b) => (a.dalogId > b.dalogId ? 1 : -1));
  }
  return response;
});

export const machineSlice = createSlice({
  name: "machines",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncMachines.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncMachines.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.machines = action.payload;
        state.machineToList = action.payload.map((mach) => {
          return {
            ...mach,
            projectName: mach.project.name,
            companyName: mach.company.name,
            corporationName: mach.corporation.name,
          };
        });
      })
      .addCase(listAsyncMachines.rejected, (state) => {
        state.status = Status.error;
        state.error = t("Error loading machines. Please try again later.");
      });
  },
});

export const selectMachines = (state: RootState) => state?.machines?.machines;

export const selectMachinesError = (state: RootState) => state.machines.error;

export const selectMachinesToList = (state: RootState) =>
  state?.machines?.machineToList;

export const selectMachinesStatus = (state: RootState) => state.machines.status;

export const selectMachine = createSelector(
  [selectMachines, (_, id: string) => id],
  (machines, id) => {
    if (machines.length > 0) {
      return machines.find((c: ResponseMachineDetails) => c.id === id);
    }

    return undefined;
  }
);

export const selectMachinesProProjectOptions = createSelector(
  [selectMachines, (_, idProject: string) => idProject],
  (machines, idProject) => {
    if (machines.length > 0) {
      return machines
        .filter((c: ResponseMachineDetails) => c.projectId === idProject)
        .map((mac) => ({
          key: mac.id!,
          text: mac.dalogId!,
        }));
    }

    return undefined;
  }
);

export const selectMachinesOptions = createSelector(
  [selectMachines],
  (machines) => {
    if (machines.length > 0) {
      return machines.map((mac) => ({
        key: mac.id!,
        text: mac.dalogId!,
      }));
    }

    return undefined;
  }
);

export const selectMachinesProProject = createSelector(
  [selectMachinesToList, (_, idProject: string) => idProject],
  (machineToList, idProject) => {
    if (machineToList.length > 0) {
      return machineToList.filter(
        (c: MachineToList) => c.projectId === idProject
      );
    }

    return undefined;
  }
);

export const selectMachineSiblings = createSelector(
  [selectMachines, (_, machineId: string) => machineId],
  (machines, machineId) => {
    if (machines.length > 0) {
      const myMach = machines.find((mach) => mach.id === machineId);

      return machines.filter(
        (mach: ResponseMachineDetails) => mach?.projectId === myMach?.projectId
      );
    }

    return undefined;
  }
);

export default machineSlice.reducer;
