import { useMutation } from "@tanstack/react-query";

import { getApiClient } from "../../../modules/core/apiClient/useApiStore";

export type UseMachineTriggeringProps = {
  machineId: string;
};

export const useMachineTriggering = ({
  machineId,
}: UseMachineTriggeringProps) => {
  const { mutateAsync } = useMutation((selectedKeys: string[]) =>
    getApiClient().post(
      `/dws/v1/triggers/on-demand/machines/${machineId}`,
      selectedKeys
    )
  );

  return {
    triggerMachineAsync: mutateAsync,
  };
};
