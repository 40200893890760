/* eslint-disable react-hooks/exhaustive-deps */
import { DialogFooter, PrimaryButton, IconButton } from "@fluentui/react";
import {
  ConnectionTableItem,
  HistoricalChangesItemBasic,
  HistoricalChangesItemDetailed,
} from "../../Schema/models";
import { Utils } from "../../Utils/utils";
import FormDialog from "../generic/FormDialog";
import { useContext, useEffect, useState } from "react";
import LoadingPanel from "../generic/LoadingPanel";
import HistoricalChangesDetailsDialog from "./HistoricalChangesDetailsDialog";

import Table, { Column } from "../../../common/Table";
import { DialogSize } from "../../../common/Dialog";
import { notification } from "../../../common/Notification";

import { withLoadingPanelHOC } from "../generic/HOCs";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import { VpnConnectionsAPI } from "../../Schema/api";

import { useTranslation } from "react-i18next";

type HistoricalChangesTableItem = {
  id?: string;
  date: string;
  time: string;
  changedBy: string;
};

type HistoricalChangesDialogProps = {
  item: ConnectionTableItem;
  onClose: () => void;
};

/**
 * Gets the connection historical changes table columns.
 * @param onDetails Method called when the details action button is clicked.
 * @returns The IColumns list.
 */
const getColumns = (t, onDetails: (id: string) => void): Column[] => {
  let dataColumns: Column[] = [
    {
      name: t("Date"),
      fieldName: "date",
      key: "date",
      minWidth: 150,
      isSortable: true,
    },
    {
      name: t("Time"),
      fieldName: "time",
      key: "time",
      minWidth: 100,
      isSortable: true,
    },
    {
      name: t("Changed By"),
      fieldName: "changedBy",
      key: "changedBy",
      minWidth: 150,
      isSortable: true,
    },
  ];

  let actionsColumn: Column = {
    key: "actions",
    name: t("Actions"),
    fieldName: "actions",
    minWidth: 100,
    onRender: (item: HistoricalChangesTableItem) => (
      <IconButton
        key={item.id + "show"}
        className="table-icon-button"
        title={t("Show changes")}
        ariaLabel={t("Show changes")}
        iconProps={{
          iconName: "Info",
        }}
        onClick={() => onDetails(item.id!)}
      />
    ),
  };

  return [...dataColumns, actionsColumn];
};

/**
 * Gets the table items, from the historical changes basic items list.
 * @param items the historical changes basic items list.
 * @returns The connection historical changes table items list.
 */
const getTableItems = (
  items: HistoricalChangesItemBasic[]
): HistoricalChangesTableItem[] => {
  let sortedItems = items.sort((a, b) => {
    if (a.timeStamp < b.timeStamp) {
      return 1;
    }

    if (b.timeStamp < a.timeStamp) {
      return -1;
    }

    return 0;
  });

  let result: HistoricalChangesTableItem[] = sortedItems.map((item) => {
    let timeStamp = new Date(item.timeStamp);
    return {
      id: item.id,
      date: Utils.getDate(timeStamp),
      time: Utils.getTime(timeStamp),
      changedBy: item.changedBy || "",
    };
  });

  return result;
};

/**
 * The historical changes dialog.
 * @param item The connection table item.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The historical changes dialog component.
 */
const HistoricalChangesListDialog = ({
  item,
  onClose,
}: HistoricalChangesDialogProps) => {
  const { t } = useTranslation();
  const axiosInstance = useContext(AxiosContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] =
    useState<HistoricalChangesItemDetailed | null>(null);
  const [tableItems, setTableItems] = useState<HistoricalChangesTableItem[]>(
    []
  );

  // Gets the historical changes entries.
  useEffect(() => {
    if (!axiosInstance) {
      onClose?.();
      return;
    }

    setIsLoading(true);
    VpnConnectionsAPI.listHistory(axiosInstance, item.id).then((response) => {
      setIsLoading(false);
      if (response.status !== 200) {
        notification.error(
          t(`Failure getting the historical changes list: {{statusText}}.`, {
            statusText: response.statusText,
          })
        );
        onClose?.();
        return;
      }

      setTableItems(getTableItems(response.data));
    });
  }, [item]);

  // Handlers.
  const onDetailsHandler = async (id: string) => {
    if (!axiosInstance) {
      return;
    }

    setIsLoading(true);
    let response = await VpnConnectionsAPI.getHistory(
      axiosInstance,
      item.id,
      id
    );

    setIsLoading(false);
    if (response.status !== 200) {
      notification.error(
        t(`Failure getting the historical changes item: {{statusText}}.`, {
          statusText: response.statusText,
        })
      );
      return;
    }

    setSelectedItem(response.data as HistoricalChangesItemDetailed);
  };

  // Sets the component to show.
  let result: JSX.Element | null = null;
  if (isLoading) {
    result = <LoadingPanel />;
  } else if (selectedItem) {
    result = (
      <HistoricalChangesDetailsDialog
        item={selectedItem}
        onClose={() => setSelectedItem(null)}
      />
    );
  } else {
    result = (
      <FormDialog
        title={t("Historical Changes")}
        size={DialogSize.M}
        onClose={onClose}
      >
        {withLoadingPanelHOC(
          t,
          tableItems,
          <Table
            className="box-shadow-panel"
            persistOpts={{
              key: `table-${item.projectName}-historical-changes`,
              version: 2,
            }}
            header={{
              title: `${Utils.firstToUpperCase(item.projectName)} changes`,
            }}
            hasSelection={false}
            items={tableItems}
            columns={getColumns(t, onDetailsHandler)}
          />
        )}
        <DialogFooter>
          <PrimaryButton
            className="primary-button"
            text={t("Done")}
            onClick={onClose}
          />
        </DialogFooter>
      </FormDialog>
    );
  }

  return result;
};

export default HistoricalChangesListDialog;
