import { TextField } from "@fluentui/react";
import { MachineSubmission, GearboxSubmission } from "../models";
import { RequestSubmissionMachine } from "../../models";
import { isValidDalogId } from "../../../../schema/Utils";
import { useAppSelector } from "../../../../Hooks";
import { selectMachinesToList } from "../../../Machines/reducer";
import { SupportContext } from "../SupportContext";
import { useContext } from "react";
import { SecondPanelContext } from "../SubmissionsDetails";
import { useTranslation } from "react-i18next";
import { TextFieldWithDocuments } from "../TextFieldWithDocuments";

interface MachineSupportInputProps {
  context: SecondPanelContext;
  matchUniqueId: string;
}

export const MachineSupportInput: React.FC<MachineSupportInputProps> = ({
  context,
  matchUniqueId,
}) => {
  const { t } = useTranslation();
  const machines = useAppSelector(selectMachinesToList);
  const { support, updateSupportEle } = useContext(SupportContext);
  const toUpdate = support?.machinesSupport.find(
    (mach) => mach.matchUniqueId === matchUniqueId
  );

  const handleMachineChange = (
    field: keyof MachineSubmission,
    value: string
  ) => {
    const machNew: MachineSubmission = {
      ...context.initialValues.mach,
      [field]: value.trim(),
      uniqueId: context.initialValues.mach.uniqueId,
    };
    context.updateFunction(machNew, context.initialValues.mach.uniqueId);
  };

  const handleGearboxChange = (
    field: keyof GearboxSubmission,
    value: string
  ) => {
    const gearboxNew: GearboxSubmission = {
      ...context.initialValues.mach.gearbox,
      [field]:
        field === "power" || field === "rotationalSpeed"
          ? Number(value)
          : value.trim(),
    };

    const machNew: MachineSubmission = {
      ...context.initialValues.mach,
      gearbox: gearboxNew,
    };

    context.updateFunction(machNew, context.initialValues.mach.uniqueId);
  };

  return (
    <>
      <TextFieldWithDocuments
        label={t("Machine Name")}
        value={context.initialValues.mach.name || ""}
        onChange={(newVal) => handleMachineChange("name", newVal)}
        disabled={context.context !== "Machine"}
        files={context.initialValues.mach.files}
        fieldName="name"
      />

      <TextField
        defaultValue={toUpdate?.dalogId}
        disabled={context.context !== "Machine"}
        label="Dalog Id"
        onChange={(ev, newVal) => {
          const machNew: RequestSubmissionMachine = {
            dalogId: newVal.trim(),
            matchUniqueId: context.initialValues.mach.uniqueId,
            nameMachine: context.initialValues.mach.name,
          };
          updateSupportEle(machNew);
        }}
        onGetErrorMessage={(val) =>
          !isValidDalogId(val)
            ? t("The format is XX-XXX-XX.")
            : machines.some((ele) => ele.dalogId === val)
              ? t("The dalogId already exist")
              : ""
        }
      />

      <TextFieldWithDocuments
        label={t("Manufacturer")}
        value={context.initialValues.mach.manufacturer || ""}
        onChange={(newVal) => handleMachineChange("manufacturer", newVal)}
        disabled={context.context !== "Machine"}
        files={context.initialValues.mach.files}
        fieldName="manufacturer"
      />

      <TextFieldWithDocuments
        label={t("Customer Code")}
        value={context.initialValues.mach.customerCode || ""}
        onChange={(newVal) => handleMachineChange("customerCode", newVal)}
        disabled={context.context !== "Machine"}
        files={context.initialValues.mach.files}
        fieldName="customerCode"
      />

      <TextFieldWithDocuments
        label={t("Process")}
        value={context.initialValues.mach.process || ""}
        onChange={(newVal) => handleMachineChange("process", newVal)}
        disabled={context.context !== "Machine"}
        files={context.initialValues.mach.files}
        fieldName="process"
      />

      {/* Gearbox Fields */}
      <TextFieldWithDocuments
        label={t("Gearbox Manufacturer")}
        value={context.initialValues.mach.gearbox.manufacturer || ""}
        onChange={(newVal) => handleGearboxChange("manufacturer", newVal)}
        disabled={context.context !== "Machine"}
        files={context.initialValues.mach.files}
        fieldName="gearbox/manufacturer"
      />

      <TextFieldWithDocuments
        label={t("Gearbox Notation")}
        value={context.initialValues.mach.gearbox.notation || ""}
        onChange={(newVal) => handleGearboxChange("notation", newVal)}
        disabled={context.context !== "Machine"}
        files={context.initialValues.mach.files}
        fieldName="gearbox/notation"
      />

      <TextFieldWithDocuments
        label={t("Gearbox Power")}
        value={context.initialValues.mach.gearbox.power?.toString() || ""}
        onChange={(newVal) => handleGearboxChange("power", newVal)}
        disabled={context.context !== "Machine"}
        files={context.initialValues.mach.files}
        fieldName="gearbox/power"
      />

      <TextFieldWithDocuments
        label={t("Gearbox Rotational Speed")}
        value={
          context.initialValues.mach.gearbox.rotationalSpeed?.toString() || ""
        }
        onChange={(newVal) => handleGearboxChange("rotationalSpeed", newVal)}
        disabled={context.context !== "Machine"}
        files={context.initialValues.mach.files}
        fieldName="gearbox/rotationalSpeed"
      />

      <TextFieldWithDocuments
        label={t("Gearbox Serial Number")}
        value={context.initialValues.mach.gearbox.serialnumber || ""}
        onChange={(newVal) => handleGearboxChange("serialnumber", newVal)}
        disabled={context.context !== "Machine"}
        files={context.initialValues.mach.files}
        fieldName="gearbox/serialnumber"
      />
    </>
  );
};
