import { ActionButton } from "@fluentui/react";
import { isEmpty } from "lodash-es";
import React, { useMemo } from "react";
import type { Settings } from "react-slick";
import Slider from "react-slick";
import { useFileDownload, useLocationSearch } from "../../../../Hooks";
import Image from "../../../common/Image";
import ImagePlaceholder from "../../../common/ImagePlaceholder";
import useConditionsOverview from "../ConditionsSection/hooks";
import SectionContainer from "../SectionContainer";
import { useFilePreview } from "../../../common/Preview/useFilePreview";
import { FileType } from "../../../common/Preview";

import { useTranslation } from "react-i18next";

interface ISliderImage {
  fileId: string;
  sortIndex: number;
  type?: string;
}

const getSliderSettings: (t) => Settings = (t) => ({
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: (
    <ActionButton
      size={20}
      title={t("Previous image")}
      iconProps={{ iconName: "Previous" }}
    />
  ),
  nextArrow: (
    <ActionButton
      size={20}
      title={t("Next image")}
      iconProps={{ iconName: "Next" }}
    />
  ),
});

export const DrawingSection = ({
  ...rest
}: React.HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation();
  const { openPreview, PreviewModal } = useFilePreview();
  const { getFile } = useFileDownload();
  const [{ id }] = useLocationSearch();

  const { data: condition, isLoading } = useConditionsOverview(
    { machineId: id as string },
    { cacheTime: 0 }
  );

  const hasDrawingIds = useMemo(
    () => !!condition && condition.machine.drawingIds.length > 0,
    [condition]
  );

  const handlePreview = async (fileId: string, name: string) => {
    try {
      const file = await getFile({ id: fileId });
      const fileType =
        file.data.type === "application/pdf" ? FileType.Pdf : FileType.Img;
      openPreview(fileId, name, fileType);
    } catch (error) {
      console.error("Error getting file type:", error);
      // Si hay un error al obtener el tipo, asumimos que es una imagen
      openPreview(fileId, name, FileType.Img);
    }
  };

  let content: JSX.Element = (
    <ImagePlaceholder text={t("There is no image to be displayed")} />
  );

  if (
    hasDrawingIds &&
    condition?.machine?.drawingIds &&
    condition.machine.drawingIds.length > 0
  ) {
    content = (
      <Slider {...getSliderSettings(t)}>
        {condition.machine.drawingIds.map((item: ISliderImage) => (
          <Image
            key={item.fileId}
            id={item.fileId}
            className="slick-slider-image"
            alt={condition.machine.name}
            onClick={() => handlePreview(item.fileId, condition.machine.name)}
          />
        ))}
      </Slider>
    );
  }

  return (
    <>
      <SectionContainer
        title={t("Drawings")}
        isLoading={isLoading}
        noData={isEmpty(condition)}
        {...rest}
      >
        {content}
      </SectionContainer>
      <PreviewModal />
    </>
  );
};
