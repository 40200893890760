import { groupBy } from "lodash-es";
import { useMemo } from "react";

import { useMachineList } from "../../../Hooks";
import type { ResponseSimplifiedSignal } from "../../../types";

import { MAX_GROUP_THRESHOLD } from "../constants/controlOptions";
import useSelectedSignalsStore from "../hooks/useSelectedSignalsStore";
import { useTranslation } from "react-i18next";

const useGroupingErrorMessages = () => {
  const { t } = useTranslation();
  const { data: machineList } = useMachineList({ enabled: false });

  const selectedSignalsStore = useSelectedSignalsStore();

  const signalsGroupedByMachine = useMemo(
    () =>
      groupBy(selectedSignalsStore.selectedSignals, (value) => value.machineId),
    [selectedSignalsStore.selectedSignals]
  );

  const getGroupByMachineErrorMessage = (calculationThreshold = 0) => {
    const machineIds = [] as string[];

    Object.entries(signalsGroupedByMachine).forEach(([machineId, signals]) => {
      if (signals.length > MAX_GROUP_THRESHOLD - calculationThreshold) {
        machineIds.push(machineId);
      }
    });

    const machineNames = machineIds
      .map((machineId) => machineList?.find((item) => item.id === machineId))
      .map((machine) => machine?.name);

    if (machineNames.length > 0) {
      return t(
        `You have more than <b>{{maxGroupThreshold}} signals selected</b> for machines: <b>{{machines}}</b>. Please deselect some signals and then group them again.`,
        {
          maxGroupThreshold: MAX_GROUP_THRESHOLD,
          machines: machineNames.join(", "),
        }
      );
    }

    return "";
  };

  const getSignalGroupByMachineErrorMessage = (
    signal: ResponseSimplifiedSignal
  ) => {
    const entry = Object.entries(signalsGroupedByMachine).find(
      ([machineId]) => machineId === signal.machineId
    );

    if (entry === undefined) return "";

    const [machineId, signals] = entry;
    const machine = machineList?.find((item) => item.id === machineId);

    if (signals.length === MAX_GROUP_THRESHOLD) {
      return t(
        `You have already selected <b>{{maxGroupThreshold}} signals</b> for the machine: <b>{{machinesName}}</b>. Please deselect some signals and then group them again.`,
        { maxGroupThreshold: MAX_GROUP_THRESHOLD, machinesName: machine?.name }
      );
    }

    return "";
  };

  const getGroupEverythingErrorMessage = (calculationThreshold = 0) => {
    if (
      selectedSignalsStore.selectedSignals.length >
      MAX_GROUP_THRESHOLD - calculationThreshold
    ) {
      return t(
        `You cannot have more than <b>{{maxGroupThreshold}} signals selected</b>. Please remove signals until there are <b>maximum {{maxGroupThreshold}}</b>.`,
        { maxGroupThreshold: MAX_GROUP_THRESHOLD }
      );
    }

    return "";
  };

  return {
    getGroupByMachineErrorMessage,
    getSignalGroupByMachineErrorMessage,
    getGroupEverythingErrorMessage,
  };
};

export default useGroupingErrorMessages;
