import { PrimaryButton } from "@fluentui/react";
import type { CSSProperties } from "react";
import { useState } from "react";

import BookmarkAddIcon from "../../../../assets/svg/BookmarkAddIcon";
import BookmarkedIcon from "../../../../assets/svg/BookmarkedIcon";
import BookmarkRemoveIcon from "../../../../assets/svg/BookmarkRemoveIcon";

export const getBookmarkedIcon = ({
  isBookmarked,
  hovered,
}: {
  isBookmarked: boolean;
  hovered: boolean;
}) => {
  if (!isBookmarked) return <BookmarkAddIcon />;

  return hovered ? <BookmarkRemoveIcon /> : <BookmarkedIcon />;
};

type BookmarkButtonProps = {
  isBookmarked: boolean;
  text?: string;
  onClick?: any;
  isLoading?: boolean;
};

const styles: Record<string, CSSProperties> = {
  wrapper: {
    minWidth: 52,
    height: 32,
    display: "flex",
    zIndex: 1,
  },
  text: {
    marginLeft: 10,
    fontWeight: 600,
  },
};

export const BookmarkButton = ({
  isBookmarked,
  text,
  onClick,
  isLoading,
}: BookmarkButtonProps) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div style={styles.wrapper}>
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <PrimaryButton
          disabled={isLoading}
          styles={{
            root: {
              minWidth: "initial",
            },
          }}
          onClick={(e) => {
            onClick && onClick();
            e.stopPropagation();
          }}
        >
          {getBookmarkedIcon({ isBookmarked, hovered })}
          {text && <div style={styles.text}>{text}</div>}
        </PrimaryButton>
      </div>
    </div>
  );
};
