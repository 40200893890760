import { useMemo } from "react";

import { getColumns } from "../columns";
import { useGatewaySensorsProfiles } from "../../../hooks/useGatewaySensorsProfiles";
import Table, { TableProps } from "../../../../common/Table";
import { useTranslation } from "react-i18next";

const TriggerStatusTab = ({ gateway }: any) => {
  const { t } = useTranslation();
  const gatewayId = gateway?.serialNumber;
  const {
    data: sensorsProfiles,
    isLoading,
    isError,
  } = useGatewaySensorsProfiles({ gatewayId });

  const tableProps = useMemo<TableProps>(
    () => ({
      isLoading,
      isError,
      persistOpts: {
        key: "gateway-sensor-trigger-status",
        version: 1,
      },
      header: {
        title: t("Gateway Sensor Trigger Status"),
      },
      columns: getColumns(t),
      hasSelection: false,
      items: sensorsProfiles,
    }),
    [isLoading, isError, sensorsProfiles, t]
  );
  return <Table {...tableProps} />;
};

export default TriggerStatusTab;
