import React, { useEffect, useMemo, useState } from "react";

import type { ResponseSimplifiedSignal } from "../../../../../types";
import type { TabProps } from "../../../../common/TabMenu";
import { TabMenu } from "../../../../common/TabMenu";

import { getMopSignals } from "./methods";
import MopRawChart from "./MopRawChart";
import MopTrendChart from "./MopTrendChart";
import { useTranslation } from "react-i18next";

enum TabOptions {
  Trends = "MOP Trends",
  Events = "MOP Events",
}

type MopChartsProps = React.HTMLAttributes<HTMLDivElement> & {
  machineId: string;
  signals: ResponseSimplifiedSignal[];
};

type TabsContentProps = {
  t: any;
  machineId: string;
  signals: ResponseSimplifiedSignal[];
  rawSignals: ResponseSimplifiedSignal[];
  trendSignals: ResponseSimplifiedSignal[];
  setRawSignals: (signals: ResponseSimplifiedSignal[]) => void;
  setTrendSignals: (signals: ResponseSimplifiedSignal[]) => void;
  maximizeAction?: () => void;
  maximizeIcon?: JSX.Element;
  maximizeLabel?: string;
};

const getTabsContent = ({
  t,
  machineId,
  signals,
  rawSignals,
  trendSignals,
  setRawSignals,
  setTrendSignals,
  ...rest
}: TabsContentProps): TabProps[] => {
  return [
    {
      key: TabOptions.Trends,
      title: t("MOP Trends"),
      content: (
        <div
          {...rest}
          key={`mop-trend-chart-${machineId}`}
          className="white-container"
        >
          <MopTrendChart
            machineId={machineId}
            signals={signals.filter((s) => s.dataType === "Trend")}
            selectedSignals={trendSignals}
            onSelectedChange={setTrendSignals}
          />
        </div>
      ),
    },
    {
      key: TabOptions.Events,
      title: t("MOP Events"),
      content: (
        <div
          {...rest}
          key={`mop-raw-chart-${machineId}`}
          className="white-container"
        >
          <MopRawChart
            machineId={machineId}
            signals={signals.filter((s) => s.dataType === "ShortTerm")}
            selectedSignals={rawSignals}
            onSelectedChange={setRawSignals}
          />
        </div>
      ),
    },
  ];
};

const MopCharts = ({ machineId, signals, ...rest }: MopChartsProps) => {
  const { t } = useTranslation();
  const [trendSignals, setTrendSignals] = useState<ResponseSimplifiedSignal[]>(
    []
  );
  const [rawSignals, setRawSignals] = useState<ResponseSimplifiedSignal[]>([]);

  const tabs: TabProps[] = useMemo(() => {
    if (signals.length === 0) return [];

    return getTabsContent({
      t,
      machineId,
      signals: getMopSignals(signals),
      rawSignals,
      trendSignals,
      setRawSignals,
      setTrendSignals,
      ...rest,
    });
  }, [signals.length, rawSignals.length, trendSignals.length, t]);

  // Initializes the selected trend and raw signals.
  useEffect(() => {
    if (signals.length === 0) return;

    const mopSignals = getMopSignals(signals);
    if (!mopSignals || mopSignals.length === 0) return;

    setTrendSignals(
      mopSignals.filter((s) => s.dataType === "Trend").slice(0, 6)
    );
    setRawSignals(
      mopSignals.filter((s) => s.dataType === "ShortTerm").slice(0, 6)
    );
  }, [signals.length]);

  return (
    <TabMenu
      tabs={tabs}
      pivotProps={{
        styles: {
          root: { display: "flex", flexFlow: "row wrap", paddingLeft: "1em" },
        },
        defaultSelectedKey: TabOptions.Trends,
      }}
    />
  );
};

export default MopCharts;
