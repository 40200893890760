const OverviewIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    {...props}
  >
    <path d="M9 12C8.73478 12 8.48043 11.8947 8.29289 11.7071C8.10536 11.5196 8 11.2652 8 11V5.08302C6.51993 5.33776 5.18914 6.13812 4.27034 7.3261C3.35155 8.51409 2.9115 10.0034 3.03709 11.4999C3.16267 12.9965 3.84475 14.3916 4.94868 15.4099C6.05262 16.4281 7.49818 16.9955 9 17C10.4181 17.0002 11.7904 16.4982 12.8736 15.5829C13.9567 14.6676 14.6807 13.3983 14.917 12H9ZM8.003 4.07002C8.55 3.99202 9 4.44802 9 5.00002V11H15C15.552 11 16.008 11.45 15.93 11.997C15.6738 13.7457 14.7657 15.3329 13.388 16.4399C12.0104 17.547 10.265 18.0922 8.50211 17.966C6.73923 17.8398 5.08928 17.0517 3.88331 15.7597C2.67734 14.4677 2.00458 12.7674 2 11C1.99967 9.316 2.60644 7.68831 3.70904 6.41545C4.81164 5.14258 6.33615 4.30987 8.003 4.07002ZM11 9.00002V3.00002C12.5913 3.00002 14.1174 3.63216 15.2426 4.75738C16.3679 5.88259 17 7.40872 17 9.00002H11ZM17.062 10C17.56 10 17.989 9.63402 17.999 9.13602L18 9.00002C18 8.06929 17.8145 7.1479 17.4542 6.28975C17.0938 5.4316 16.566 4.65392 15.9015 4.00221C15.2371 3.3505 14.4493 2.83784 13.5843 2.49422C12.7194 2.1506 11.7945 1.98293 10.864 2.00102C10.366 2.01102 10 2.44102 10 2.93802V9.00002C10 9.26523 10.1054 9.51959 10.2929 9.70712C10.4804 9.89466 10.7348 10 11 10H17.062Z" />
  </svg>
);

export default OverviewIcon;
