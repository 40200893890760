import React from "react";
import { Text, mergeStyles } from "@fluentui/react";
import { formatDateHuman } from "../../../../schema/Utils";

interface MessageItemProps {
  message: {
    name: string;
    date: string;
    role?: string;
    message: string;
    isMe: boolean;
  };
}

const styles = {
  container: mergeStyles({
    padding: "16px",
    backgroundColor: "#f8f8f8",
    borderRadius: "8px",
    marginBottom: "8px",
    selectors: {
      "&.active": {
        backgroundColor: "#e8f4ff",
      },
    },
  }),
  userSection: mergeStyles({
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    justifyContent: "space-between",
  }),
  userInfo: mergeStyles({
    display: "flex",
    alignItems: "center",
  }),
  avatar: mergeStyles({
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: "#0078d4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "12px",
    selectors: {
      "&.active": {
        backgroundColor: "#2c529f",
      },
    },
  }),
  initials: mergeStyles({
    color: "white",
    fontSize: "16px",
    fontWeight: 600,
  }),
  nameDate: mergeStyles({
    display: "flex",
    flexDirection: "column",
  }),
  name: mergeStyles({
    fontWeight: 600,
    fontSize: "14px",
  }),
  date: mergeStyles({
    fontSize: "12px",
    color: "#666",
  }),
  role: mergeStyles({
    padding: "4px 8px",
    backgroundColor: "#f0f0f0",
    borderRadius: "4px",
    fontSize: "12px",
    selectors: {
      "&.active": {
        backgroundColor: "#d0e0e0",
      },
    },
  }),
  message: mergeStyles({
    fontSize: "14px",
    lineHeight: "20px",
    color: "#333",
    selectors: {
      ".active &": {
        color: "#000",
      },
    },
  }),
};

export const MessageItem: React.FC<MessageItemProps> = ({ message }) => {
  const firstName = message.name.split(" ")[0];
  const lastName = message.name.split(" ")[1] || "";

  return (
    <div className={`${styles.container} ${message.isMe ? "active" : ""}`}>
      <div className={styles.userSection}>
        <div className={styles.userInfo}>
          <div className={`${styles.avatar} ${message.isMe ? "active" : ""}`}>
            <Text className={styles.initials}>
              {firstName[0]?.toUpperCase()}
              {lastName[0]?.toUpperCase()}
            </Text>
          </div>
          <div className={styles.nameDate}>
            <Text className={styles.name}>{message.name}</Text>
            <Text className={styles.date}>
              {formatDateHuman(new Date(message.date), false)}
            </Text>
          </div>
        </div>
        <div className={`${styles.role} ${message.isMe ? "active" : ""}`}>
          <Text>{message.role}</Text>
        </div>
      </div>
      <Text className={styles.message}>{message.message}</Text>
    </div>
  );
};
