import React, { useMemo, useState } from "react";
import { TextField, Dropdown, IDropdownOption } from "@fluentui/react";
import { SecondPanelContext } from "../SubmissionsDetails";
import { ProjectSubmission } from "../models";
import countryByContinent from "../../../../schema/country-by-continent";

interface ProjectFieldsProps {
  context: SecondPanelContext;
}

const continents = Array.from(
  new Set(countryByContinent.map((c) => c.continent))
).sort();

const continentOptions: IDropdownOption[] = continents.map((c) => ({
  key: c,
  text: c,
}));

export const ProjectFields: React.FC<ProjectFieldsProps> = ({ context }) => {
  const { initialValues, updateFunction } = context;
  // useState ProjectSubmission
  const [projectSub, setProjectSub] = useState<ProjectSubmission>(
    initialValues.proj
  );

  const countryOptions: IDropdownOption[] = useMemo(() => {
    const filteredCountries = projectSub.continent
      ? countryByContinent.filter((c) => c.continent === projectSub.continent)
      : [];
    return filteredCountries.map((c) => ({ key: c.country, text: c.country }));
  }, [projectSub.continent]);

  const handleFieldChange = (
    field: keyof ProjectSubmission,
    newValue: string
  ) => {
    const projNew: ProjectSubmission = {
      [field]: newValue.trim(),
      uniqueId: projectSub.uniqueId,
    };

    if (field === "continent") {
      projNew.country = "";
    }

    setProjectSub((prev) => ({ ...prev, ...projNew }));
    updateFunction(projNew, projectSub.uniqueId);
  };

  return (
    <>
      <TextField
        value={projectSub.name || ""}
        disabled={!!initialValues.proj.id || context.context !== "Project"}
        label="Project to assign:"
        onChange={(_, newVal) => handleFieldChange("name", newVal || "")}
      />

      <Dropdown
        label="Continent:"
        selectedKey={projectSub.continent}
        options={continentOptions}
        disabled={!!initialValues.proj.id || context.context !== "Project"}
        onChange={(_, option) =>
          handleFieldChange("continent", option?.key?.toString() || "")
        }
      />

      <Dropdown
        label="Country:"
        selectedKey={projectSub.country}
        options={countryOptions}
        disabled={
          !!initialValues.proj.id ||
          context.context !== "Project" ||
          !projectSub.continent
        }
        onChange={(_, option) =>
          handleFieldChange("country", option?.key?.toString() || "")
        }
      />

      <TextField
        value={projectSub.city || ""}
        disabled={!!initialValues.proj.id || context.context !== "Project"}
        label="City:"
        onChange={(_, newVal) => handleFieldChange("city", newVal || "")}
      />
    </>
  );
};
