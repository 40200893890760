import type { PropsWithChildren } from "react";

import NoData from "../../../../Components/common/NoData/NoData";
import Spin from "../../../../Components/common/Spin/Spin";
import { useTranslation } from "react-i18next";

type DataPanelProps = React.HTMLAttributes<HTMLElement> & {
  isLoading: boolean;
  noData?: boolean;
  noDataText?: string;
};

export const DataPanel = ({
  isLoading,
  noData,
  noDataText,
  children,
  ...rest
}: PropsWithChildren<DataPanelProps>) => {
  const { t } = useTranslation();

  let result: React.ReactNode = children;
  if (isLoading) {
    result = (
      <Spin
        styles={{ root: { height: "calc(100vh - 120px)", width: "100%" } }}
      />
    );
  } else if (noData === true) {
    result = (
      <NoData
        text={noDataText ?? t("Data not available")}
        styles={{ root: { marginTop: 20, width: "100%" } }}
      />
    );
  }

  return <div {...rest}>{result}</div>;
};
