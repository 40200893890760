import classNames from "classnames";

import { STATISTICS_TAB_1, STATISTICS_TAB_2 } from "./config";
import usePerformanceStore from "../../../hooks/usePerformanceStore";
import { useTranslation } from "react-i18next";

const StatisticsTabs = () => {
  const { t } = useTranslation();
  const { statisticsActiveTab, updateStore } = usePerformanceStore(
    (store: any) => ({
      statisticsActiveTab: store.statisticsActiveTab,
      updateStore: store.updateStore,
    })
  );

  return (
    <div className="events-tabs">
      <div
        className={classNames("events-tabs__tab", {
          active: statisticsActiveTab === STATISTICS_TAB_1,
        })}
      >
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            updateStore({ statisticsActiveTab: STATISTICS_TAB_1 });
          }}
        >
          {t("Operating Parameters and Setpoints")}
        </a>
      </div>
      <div
        className={classNames("events-tabs__tab", {
          active: statisticsActiveTab === STATISTICS_TAB_2,
        })}
      >
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            updateStore({ statisticsActiveTab: STATISTICS_TAB_2 });
          }}
        >
          {t("KPIs")}
        </a>
      </div>
    </div>
  );
};

export default StatisticsTabs;
