// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import OrganizationChart from "@dabeng/react-orgchart";
import { Spinner } from "@fluentui/react";
import { type CSSProperties, useEffect, useState } from "react";

import NodeComponent from "./NodeComponent";
import type { ConditionStatus } from "../../../../types";

export type TreeItem = {
  name?: string;
  icon?: any;
  condition?: ConditionStatus;
  children?: TreeItem[];
  disabled?: boolean;
};

type WirelessSensorsTreeProps = {
  data: TreeItem;
  isLoading?: boolean;
  chartStyle?: CSSProperties;
  split?: boolean;
};

function filterDisabledNodes(node: TreeItem): TreeItem | undefined {
  if (!node || Object.keys(node).length === 0 || node.children === undefined) {
    return {};
  }
  if (node.disabled) {
    return undefined;
  }
  return {
    ...node,
    children: node.children
      .map(filterDisabledNodes)
      .filter((child: any) => child !== undefined) as TreeItem[],
  };
}

function splitChildren(obj: TreeItem | undefined): TreeItem[] | undefined {
  if (!obj || Object.keys(obj).length === 0 || obj.children === undefined) {
    return undefined;
  }

  return obj.children.map((child: TreeItem) => {
    return {
      ...obj,
      children: [child],
    };
  });
}

function countChildrenAndGrandchildren(item: TreeItem): [number, number] {
  let childrenCount = 0;
  let grandchildrenCount = 0;

  if (item.children) {
    childrenCount = item.children.length;
    item.children.forEach((child) => {
      if (child.children) {
        grandchildrenCount += child.children.length;
      }
    });
  }

  return [childrenCount, grandchildrenCount];
}

const WirelessSensorsTree = ({
  data,
  chartStyle,
  isLoading,
  split = true,
}: WirelessSensorsTreeProps) => {
  const [object, setObject] = useState<TreeItem[]>();

  useEffect(() => {
    if (data || Object.keys(data).length != 0) {
      const filteredNodes = filterDisabledNodes(data);
      if (filteredNodes) {
        const [nNodes, nSubNodes] =
          countChildrenAndGrandchildren(filteredNodes);
        (nNodes > 10 || nSubNodes > 10) && split
          ? setObject(splitChildren(filteredNodes))
          : setObject([filteredNodes]);
      }
    }
  }, [data, split]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        ...chartStyle,
      }}
    >
      {object?.map((item: any, idx: number) => (
        <div key={idx} style={{ height: "auto" }}>
          <OrganizationChart
            multilineNodes
            collapsible={true}
            datasource={item}
            NodeTemplate={(nodeData: any) => (
              <NodeComponent {...nodeData} style={{ padding: "10px" }} />
            )}
            style={{ padding: "10px" }}
          />
        </div>
      ))}
    </div>
  );
};

export default WirelessSensorsTree;
