import { Stack } from "@fluentui/react";
import classNames from "classnames";
import { useMemo } from "react";

import useSectionsStore from "../../../../Hooks/useSectionsStore";
import { useSelectedSignalsStore } from "../../../../modules/analysis-raw-data";
import Placeholder from "../../../../modules/analysis-raw-data/components/Placeholder";
import SpectrumChartSection from "../../../../modules/analysis-raw-data/components/SpectrumChartSection";
import WaveformChartSection from "../../../../modules/analysis-raw-data/components/WaveformChartSection";
import { useDataPointsWithTimestamps } from "../../../../modules/analysis-raw-data/hooks/useDataPoints";
import { NoData } from "../../../common/NoData";
import { useTranslation } from "react-i18next";

const CompareLayout = () => {
  const { t } = useTranslation();
  const selectedSignals = useSelectedSignalsStore(
    (store) => store.selectedSignals
  );
  const getDataPoints = useDataPointsWithTimestamps();
  const isAnyMaximized = useSectionsStore((store) => store.isAnyMaximized());
  const isSameMachineId = useMemo(
    () =>
      selectedSignals.every(
        (signal, _, array) => signal.machineId === array[0].machineId
      ),
    [selectedSignals]
  );

  return (
    <>
      {selectedSignals.map((selectedSignal, indexSignal) => {
        const dataPoints = getDataPoints(selectedSignal.id);
        return (
          <Stack
            key={selectedSignal.id + "-" + indexSignal}
            horizontal
            style={{ display: "block", marginBottom: 15, paddingBottom: 15 }}
            className={classNames({
              "border--hidden": isAnyMaximized,
            })}
          >
            {dataPoints.length === 0 && (
              <NoData
                text={t("There is no data")}
                styles={{
                  root: {
                    width: "100%",
                    height: 500,
                  },
                }}
              />
            )}

            {dataPoints.map((dataPoint, indexDataPoint) => {
              return (
                <Stack
                  key={dataPoint.id + "-" + indexDataPoint}
                  horizontal
                  className={classNames("layout-grid", {
                    "layout-grid--hidden": isAnyMaximized,
                  })}
                >
                  <WaveformChartSection
                    signal={selectedSignal}
                    dataPointTimestamp={dataPoint.timestamp}
                    dataPoint={dataPoint.id}
                  />
                  <SpectrumChartSection
                    signal={selectedSignal}
                    dataPointTimestamp={dataPoint.timestamp}
                    dataPoint={dataPoint.id}
                    isSecond={
                      (indexSignal === 1 || indexDataPoint === 1) &&
                      isSameMachineId
                    }
                  />
                </Stack>
              );
            })}

            {selectedSignals.length < 2 && dataPoints.length < 2 && (
              <Placeholder
                icon="left"
                text={t("Please select a signal or data point")}
                isLoading={false}
                style={{ height: 500 }}
              />
            )}
          </Stack>
        );
      })}
    </>
  );
};

export default CompareLayout;
