import React from "react";
import {
  Stack,
  IconButton,
  Breadcrumb,
  DefaultButton,
  PrimaryButton,
  TooltipHost,
  DirectionalHint,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { notification } from "../../common/Notification";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  goBack: () => void;
  item: { id: string; sID: string };
  isNotLog: boolean;
  rejectSubmission: (id: string) => Promise<any>;
  id: string;
  feedback: string;
  SubmitApproval: () => void;
  navigate: (path: string) => void;
  isSubmitting: boolean;
}

const Header: React.FC<HeaderProps> = ({
  goBack,
  item,
  isNotLog,
  rejectSubmission,
  id,
  feedback,
  SubmitApproval,
  navigate,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      horizontal
      className="breadcrumb-container"
      horizontalAlign="space-between"
      verticalAlign="center"
      style={{ padding: "8px 16px", borderBottom: "1px solid #EDEBE9" }}
    >
      <Stack
        horizontal
        verticalAlign="center"
        style={{ width: `calc(100% - ${50}px - 32px)` }}
      >
        <IconButton iconProps={{ iconName: "Back" }} onClick={goBack} />
        <Breadcrumb
          items={[
            {
              key: `submissions-key`,
              text: t(`Submissions`),
            },
            { key: `submission-${item.id}`, text: item.sID },
          ]}
          styles={{
            root: { margin: 0 },
            item: { fontSize: "14px" },
            chevron: { fontSize: "10px" },
          }}
        />
      </Stack>
      {isNotLog && (
        <Stack horizontal>
          <DefaultButton
            text={t("Reject")}
            style={{ width: 100, marginRight: 10 }}
            onClick={() =>
              rejectSubmission(id).then((resp) => {
                if ("data" in resp) {
                  notification.success(
                    t("Submission {{id}} successfully rejected.", {
                      id: item.sID,
                    })
                  );
                  navigate("/submissions");
                } else
                  notification.error(
                    t("Error: {{message}}", { message: resp.Error })
                  );
              })
            }
            disabled={isSubmitting}
          />
          <TooltipHost
            content={feedback}
            directionalHint={DirectionalHint.topCenter}
          >
            <PrimaryButton
              text={isSubmitting ? undefined : t("Approve")}
              style={{ width: 100 }}
              onClick={SubmitApproval}
              disabled={feedback ? true : false || isSubmitting}
            >
              {isSubmitting ? (
                <Stack horizontal verticalAlign="center">
                  <Spinner size={SpinnerSize.small} />
                  <span style={{ marginLeft: 8 }}>{t("Approving...")}</span>
                </Stack>
              ) : (
                <>{t("Approve")}</>
              )}
            </PrimaryButton>
          </TooltipHost>
        </Stack>
      )}
    </Stack>
  );
};

export default Header;
