import { TooltipHost } from "@fluentui/react";
import * as ReactRouterDOM from "react-router-dom";

import TaskIcon from "../../../../../assets/svg/TaskIcon";
import { format } from "../../../../../utils";
import { getTaskStatusFormat } from "../../../../../modules/machine-cv/constants";
import type { TaskOverview } from "../../../../../types";
import type { Column } from "../../../../common/Table/Table";

export const getColumns = (t): Column[] => [
  {
    key: "subject",
    fieldName: "subject",
    name: t("Subject"),
    minWidth: 100,
    flexGrow: 1,
    calculatedWidth: 0,
    isSortable: true,
  },
  {
    key: "description",
    fieldName: "description",
    name: t("Description"),
    minWidth: 100,
    flexGrow: 1,
    calculatedWidth: 0,
    isSortable: true,
  },
  {
    key: "priority",
    fieldName: "priority",
    name: t("Priority"),
    minWidth: 100,
    isSortable: true,
    onRender: (item) => t(item.priority),
  },
  {
    key: "status",
    fieldName: "status",
    name: t("Status"),
    minWidth: 100,
    isSortable: true,
    onRender: (item: TaskOverview) => getTaskStatusFormat(t)[item.status],
  },
  {
    key: "assignedTo",
    fieldName: "assignedTo",
    name: t("Assigned To"),
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "assignedBy",
    fieldName: "assignedBy",
    name: t("Assigned By"),
    minWidth: 100,
    isSortable: true,
  },
  {
    key: "createdAt",
    fieldName: "createdAt",
    name: t("Created At"),
    minWidth: 100,
    isSortable: true,
    onRender: (item: TaskOverview) => format(new Date(item.createdAt)),
  },
  {
    key: "dueDate",
    fieldName: "dueDate",
    name: t("Due date"),
    minWidth: 100,
    isSortable: true,
    isExportable: true,
    onRender: (item: TaskOverview) => format(new Date(item.dueDate)),
  },
  {
    key: "documents",
    fieldName: "documents",
    name: t("Documents"),
    minWidth: 100,
    maxWidth: 100,
    isSortable: true,
    isExportable: true,
  },
  {
    key: "actions",
    fieldName: "actions",
    name: t("Actions"),
    minWidth: 100,
    onRender: ({ query }) => (
      <div style={{ display: "flex" }}>
        <TooltipHost
          content={t("Tasks")}
          styles={{ root: { display: "flex" } }}
        >
          <ReactRouterDOM.Link
            to={`/machine-cv/tasks?${query}`}
            className="router-link"
            style={{ margin: "0 5px" }}
          >
            <TaskIcon />
          </ReactRouterDOM.Link>
        </TooltipHost>
      </div>
    ),
  },
];
