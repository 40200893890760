/* eslint-disable react-hooks/exhaustive-deps */
import {
  DialogFooter,
  IconButton,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import FormDialog from "../generic/FormDialog";
import { MetadataVpnPC } from "../../Schema/models";
import { useContext, useEffect, useState } from "react";
import { AddVpnPcDialog, EditVpnPcDialog } from "./AddEditVpnPcDialog";
import DeleteVpnPcDialog from "./DeleteVpnPcDialog";

import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar";
import { notification } from "../../../common/Notification";
import { DialogSize } from "../../../common/Dialog";
import Table, { Column } from "../../../common/Table";

import { withLoadingPanelHOC } from "../generic/HOCs";
import { AxiosContext } from "../../VpnConnectionsManager/VpnConnectionsManager";
import { VpnPCsAPI } from "../../Schema/api";

import { useTranslation } from "react-i18next";

enum VpnPCsActionList {
  None,
  Add,
  Edit,
  Delete,
}

type VpnPCsActionDialogProps = {
  item: MetadataVpnPC | null;
  action: VpnPCsActionList;
  onClose: (listChanged?: boolean) => void;
};

type VpnPCsListDialogProps = {
  onClose: () => void;
};

const getCommandBarItems = (t, onAdd: () => void): CommandBarItemProps[] => {
  let result: CommandBarItemProps[] = [
    {
      key: "addVpnPCItem",
      text: t("Add"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add", color: "rgb(44, 83, 160)" },
      onClick: onAdd,
    },
  ];

  return result;
};

/**
 * Gets the VPN PCs List columns
 * @param onEdit Method called when the Edit action button is clicked.
 * @param onDelete Method called when the Delete action button is clicked.
 * @returns The IColumns list.
 */
const getColumns = (
  t,
  onEdit: (item: MetadataVpnPC) => void,
  onDelete: (item: MetadataVpnPC) => void
): Column[] => {
  let actionsColumn: Column = {
    key: "actions",
    name: t("Actions"),
    fieldName: "actions",
    minWidth: 150,
    onRender: (item: MetadataVpnPC) => (
      <Stack horizontal>
        <IconButton
          key={item.id + "edit"}
          className="table-icon-button"
          title={t("Edit VPN PC")}
          ariaLabel={t("Edit VPN PC")}
          iconProps={{
            iconName: "Edit",
          }}
          onClick={() => onEdit(item)}
        />
        <IconButton
          key={item.id + "delete"}
          className="table-icon-button"
          title={t("Delete VPN PC")}
          ariaLabel={t("Delete VPN PC")}
          iconProps={{
            iconName: "Delete",
          }}
          onClick={() => onDelete(item)}
        />
      </Stack>
    ),
  };
  return [
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      minWidth: 100,
      data: "string",
    },
    actionsColumn,
  ];
};

/**
 * Gets the VPN PCs action dialog component.
 * @param item The VPN PC item.
 * @param action The VPN PC action.
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns
 */
const ActionDialog = ({ item, action, onClose }: VpnPCsActionDialogProps) => {
  let result: JSX.Element | null = null;
  switch (action) {
    case VpnPCsActionList.Add:
      result = <AddVpnPcDialog onClose={onClose} />;
      break;

    case VpnPCsActionList.Edit:
      result = item && <EditVpnPcDialog item={item} onClose={onClose} />;
      break;

    case VpnPCsActionList.Delete:
      result = item && <DeleteVpnPcDialog item={item} onClose={onClose} />;
      break;
  }

  return result;
};

/**
 * Gets the VPN PCs list dialog component
 * @param onClose The method called when the close button is clicked. Use it to close this dialog.
 * @returns The VPN PCs details dialog component.
 */
const VpnPCsListDialog = ({ onClose }: VpnPCsListDialogProps) => {
  const { t } = useTranslation();
  const axiosInstance = useContext(AxiosContext);
  const [vpnPCs, setVpnPCs] = useState<MetadataVpnPC[]>([]);
  const [selectedItem, setSelectedItem] = useState<MetadataVpnPC | null>(null);
  const [actionDialog, setActionDialog] = useState<VpnPCsActionList>(
    VpnPCsActionList.None
  );
  const [listChanged, setListChanged] = useState<boolean>(true);

  // Gets the VPN PCs list.
  useEffect(() => {
    if (!axiosInstance) {
      onClose?.();
      return;
    }

    if (!listChanged) {
      return;
    }

    VpnPCsAPI.list(axiosInstance).then((response) => {
      if (response.status !== 200) {
        notification.error(
          t(`Failure getting the VPN PCs list: {{statusText}}.`, {
            statusText: response.statusText,
          })
        );
        onClose?.();
        return;
      }

      setVpnPCs(response.data);
      setListChanged(false);
    });
  }, [listChanged]);

  // Handlers.
  const onAddHandler = () => {
    setActionDialog(VpnPCsActionList.Add);
  };

  const onEditHandler = (item: MetadataVpnPC) => {
    setSelectedItem(item);
    setActionDialog(VpnPCsActionList.Edit);
  };

  const onDeleteHandler = (item: MetadataVpnPC) => {
    setSelectedItem(item);
    setActionDialog(VpnPCsActionList.Delete);
  };

  const onCloseActionDialog = (listChanged?: boolean) => {
    listChanged && setListChanged(true);
    setSelectedItem(null);
    setActionDialog(VpnPCsActionList.None);
  };

  // Sets the current dialog to show.
  let dialog: JSX.Element;
  if (actionDialog === VpnPCsActionList.None) {
    dialog = (
      <FormDialog
        title={t("VPN PCs Details")}
        size={DialogSize.S}
        onClose={onClose}
      >
        <BaseCommandBar
          items={computeCommandBarItems(getCommandBarItems(t, onAddHandler))}
          styles={{
            root: { marginBottom: "0em", background: "transparent" },
          }}
        />
        {withLoadingPanelHOC(
          t,
          vpnPCs,
          <Table
            className="box-shadow-panel"
            persistOpts={{
              key: "table-vpn-pcs",
              version: 2,
            }}
            header={{ title: "VPN PCs" }}
            hasSelection={false}
            items={vpnPCs}
            columns={getColumns(t, onEditHandler, onDeleteHandler)}
            // perPage={25}
            hidePerPage={true}
          />
        )}
        <DialogFooter>
          <PrimaryButton
            className="primary-button"
            text={t("Done")}
            onClick={onClose}
          />
        </DialogFooter>
      </FormDialog>
    );
  } else {
    dialog = (
      <ActionDialog
        item={selectedItem}
        action={actionDialog}
        onClose={onCloseActionDialog}
      />
    );
  }
  return dialog;
};

export default VpnPCsListDialog;
