import {
  Breadcrumb,
  DefaultButton,
  DialogFooter,
  DialogType,
  IObjectWithKey,
  IconButton,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from "@fluentui/react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FilesExtensions,
  ImageUploadExtensions,
  Status,
} from "../../../schema/status";
import {
  listAsyncMachDetails,
  selectMachDetails,
  selectMachineDetailsStatus,
} from "./reducer";

import BaseDialog from "../../common/Dialog";
import { notification } from "../../common/Notification";
import Table, { TableProps } from "../../common/Table";

import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { pageStyle } from "../../../schema/Constants";
import { AcceptedFile } from "../../common/Uploader/Uploader";
import { NotFoundRoute } from "../../Generic/NotFoundRoute";
import { getMachineFixedCondition, machineImport } from "../api";
import {
  MachineFixedCondition,
  ResponseSimplifiedSignal,
  Sensor,
  StandstillSignal,
} from "../models";

import {
  PivotRenderPermissionsBased,
  PropsJsxForPivotsMachineDet,
} from "../../Generic/PivotRenderPermissionsBased";
import { authContext } from "../../LeftMenuAlt/LeftMenuAlt";
import { list } from "../../SensorNodes/api";
import { WirelessSensorNode } from "../../SensorNodes/models";
import { CUDDialog as CUDDialogSens } from "../../Sensors/CUDDialog";
import { CUDDialog as CUDDialogSig } from "../../Signals/CUDDialog";
import { EditCondition } from "../../Signals/EditCondition";
import { UpdateSignalCondition } from "../../Signals/models";
import {
  listAsyncMachines,
  selectMachineSiblings,
  selectMachinesError,
  selectMachinesStatus,
} from "../reducer";
import { alertLevelsImport, alertLevelsList } from "./AlertLevels/api";
import { CUDDialog as CUDDialogALev } from "./AlertLevels/CUDDialog";

import { AlertLevelPlus, getColumns } from "./columnsHelper";
import { EditCondition as EditFixCondition } from "./EditCondition";
import { EditStandstill } from "./EditStandstill";
import { CUDDialog as CUDDialogFrequencyCatalogue } from "./FrequencyCatalogue/CUDDialog";
import { CUDDialogGroup as CUDDialogFrequencyCatalogueGroup } from "./FrequencyCatalogue/CUDDialogGroup";
import { InputUploadImage, uploadImagesGeneral } from "./Images/api";
import { ListQueue } from "./ListQueue";

import { useHaagMapping } from "../../../Hooks/useHaagMapping";
import { ConfirmDialog } from "../../Generic/ConfirmDialog";
import { UpdateConfiguration } from "./Configs/api";
import { ConfigurationEditor } from "./Configs/ConfigurationEditor";
import {
  deleteAllFrequencyCatalogueGroups,
  frequencyCatalogueGroupDetails,
  frequencyCatalogueImport,
} from "./FrequencyCatalogue/api";

import {
  FrequencyCatalogueDbEntry,
  FrequencyCatalogueGroupPageDetails,
  FrequencyCatalogueGroupsAndItemsResponse,
} from "./FrequencyCatalogue/models";
import HaagMappingSection from "./HaagMapping/HaagMappingSection";

import GeneralConfigsContent from "./Contents/GeneralConfigsContent";
import AlertLevelsContent from "./Contents/AlertLevelsContent";
import { useTranslation } from "react-i18next";
import SignalContent from "./Contents/SignalContent";
import EventsContent from "./Contents/EventsContent";
import SensorsContent from "./Contents/SensorsContent";
import ImagesContent from "./Contents/ImagesContent";
import ConfigsContent from "./Contents/ConfigsContent";

export type ItemsSelected = ResponseSimplifiedSignal & IObjectWithKey;

export const MachineDetails: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  // const { bigScreen } = useViewport();
  const machine = useAppSelector(selectMachDetails);
  const haagData = useHaagMapping(id);
  const auth = useContext(authContext);
  const status = useAppSelector(selectMachineDetailsStatus);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const machines = useAppSelector((state) => selectMachineSiblings(state, id));

  const [isLoading, setLoading] = useState(false);

  const [selected, setSelected] = useState<{
    file: AcceptedFile;
    context:
      | "signals"
      | "sensors"
      | "events"
      | "alert Levels"
      | "images"
      | "frequency catalogue";
  }>();
  const [actionAlertLevel, setActionAlertLevel] = useState<{
    data: AlertLevelPlus | string | undefined;
    context: "add" | "edit" | "delete";
  }>();
  const [actionImages, setActionImages] = useState<{
    data: undefined;
    context: "queue";
  }>();
  const [actionSignals, setActionSignals] = useState<{
    data: ResponseSimplifiedSignal | string | undefined;
    context: "add" | "edit" | "delete";
  }>();
  const [actionSensors, setActionSensors] = useState<{
    data: Sensor | string | undefined;
    context: "add" | "edit" | "delete";
  }>();
  const [actionSignalCondition, setActionSignalsCondition] = useState<{
    data: UpdateSignalCondition;
    context: "edit";
  }>();
  const [actionFixCondition, setActionFixCondition] = useState<{
    data: MachineFixedCondition;
    context: "edit";
  }>();
  const [actionFrequencyCatalogue, setActionFrequencyCatalogue] = useState<{
    data: undefined | string | FrequencyCatalogueDbEntry;
    context: "add" | "edit" | "delete" | "addWithGroup";
  }>();
  const [actionFrequencyCatalogueGroup, setActionFrequencyCatalogueGroup] =
    useState<{
      data: undefined | FrequencyCatalogueGroupPageDetails | string;
      context: "add" | "edit" | "delete";
    }>();
  const [actionFrequencyCatalogueMachine, setActionFrequencyCatalogueMachine] =
    useState<{
      context: "delete";
    }>();

  const [actionEditConfiguration, setActionEditConfiguration] = useState<{
    data: UpdateConfiguration;
    context: "edit";
  }>();

  const [showEditStandstill, setShowEditStandstill] = useState(false);
  const [listAlertLevels, setListAlertLevels] = useState<AlertLevelPlus[]>([]);
  const [sensorNodes, setSensorNodes] = useState<WirelessSensorNode[]>();
  const [standStillSignals, setStandStillSignals] = useState<{
    primary: { signal: ResponseSimplifiedSignal & StandstillSignal };
    secondary: { signal: ResponseSimplifiedSignal & StandstillSignal };
  }>();
  const [fixedCondition, setFixedCondition] =
    useState<MachineFixedCondition[]>();
  const [frequencyCatalogue, setFrequencyCatalogue] =
    useState<FrequencyCatalogueGroupsAndItemsResponse>();
  const statusListMachs = useAppSelector(selectMachinesStatus);
  const error = useAppSelector(selectMachinesError);

  useEffect(() => {
    if (statusListMachs === Status.error && machines?.length < 1)
      notification.error(error);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, machines?.length]);

  useEffect(() => {
    statusListMachs === Status.void && dispatch(listAsyncMachines());
  }, [dispatch, statusListMachs]);

  useEffect(() => {
    list().then((resp) =>
      !("status" in resp)
        ? setSensorNodes(resp.filter((sens) => sens.machineId === id))
        : setSensorNodes([])
    );
  }, [id]);

  useEffect(() => {
    machine &&
      machine.signals.length > 0 &&
      setStandStillSignals({
        primary: {
          signal: {
            ...machine?.signals?.find(
              (ele) => ele.id === machine?.standstillSettings?.primary?.signalId
            ),
            ...machine?.standstillSettings?.primary,
          },
        },
        secondary: {
          signal: {
            ...machine?.signals?.find(
              (ele) =>
                ele.id === machine?.standstillSettings?.secondary?.signalId
            ),
            ...machine?.standstillSettings?.secondary,
          },
        },
      });

    machine &&
      alertLevelsList(id).then((response) => {
        "status" in response
          ? notification.error(t("Alert levels fetching went wrong."))
          : setListAlertLevels(
              response.map((ele) => {
                const signal = machine?.signals?.find(
                  (sig) => sig.id === ele.signalId
                );
                return {
                  ...ele,
                  signalName: signal?.name,
                  sensorSerialNo: signal?.sensorSerialNo,
                  sensorNo: signal?.sensorNo,
                };
              })
            );
      });

    machine &&
      frequencyCatalogueGroupDetails(id).then((response) => {
        "status" in response
          ? notification.error(t("Frequency catalogue fetching went wrong."))
          : setFrequencyCatalogue(response);
      });

    return () => {};
  }, [id, machine, t]);

  useEffect(() => {
    getMachineFixedCondition(id).then((resp) =>
      !("status" in resp)
        ? setFixedCondition([resp])
        : notification.warning(t("Machine fixed condition is not reachable."))
    );

    dispatch(listAsyncMachDetails(id ? id : ""));

    return () => {};
  }, [id, dispatch, t]);

  const goBack = () => navigate(-1);
  const tableProps = useMemo<TableProps>(
    () => ({
      persistOpts: {
        key: "table-documents-dialog",
        version: 1,
      },
      items: [selected?.file],
      perPage: 5,
      hasSelection: false,
      columns: getColumns(t),
      hidePerPage: true,
    }),
    [selected?.file, t]
  );

  // Handlers

  const onUploadImagesClick = () => {
    let body: InputUploadImage = {
      machineId: id,
      dalogId: machine.dalogId,
      file: selected?.file.file,
    };
    let type = selected?.file.name
      .split(".")
      .slice(-1)
      .at(0) as ImageUploadExtensions;

    setLoading(true);
    uploadImagesGeneral(body, type).then((response) => {
      setLoading(false);
      setSelected(undefined);
      if (response.status !== 202) {
        notification.error(response.text);
        return;
      }

      notification.success(t("Added data successfully."));
      notification.warning(
        t(
          "Image processing: It may takes some minutes to display it on the list."
        )
      );
    });
  };

  // Contents

  const arrayPivotItems: PropsJsxForPivotsMachineDet[] = [
    {
      itemKey: "generalConfigs",
      headerText: t("General Configs"),
      textOnRender: t("General Configs"),
      content: (
        <GeneralConfigsContent
          fixedCondition={fixedCondition}
          setActionFixCondition={setActionFixCondition}
          standStillSignals={standStillSignals}
          setShowEditStandstill={setShowEditStandstill}
          frequencyCatalogue={frequencyCatalogue}
          setActionFrequencyCatalogue={setActionFrequencyCatalogue}
          setActionFrequencyCatalogueMachine={
            setActionFrequencyCatalogueMachine
          }
          setSelected={setSelected}
          setActionFrequencyCatalogueGroup={setActionFrequencyCatalogueGroup}
        />
      ),
      show: auth.metaDataContributor,
    },
    {
      itemKey: "signals",
      headerText: t("Signals"),
      textOnRender: t("Signals [{{signalsNumber}}]", {
        signalsNumber: machine?.signals?.length?.toString(),
      }),
      content: (
        <SignalContent
          setSelected={setSelected}
          setActionSignals={setActionSignals}
          setActionSignalsCondition={setActionSignalsCondition}
        />
      ),
      show: true,
    },
    {
      itemKey: "events",
      headerText: t("Events"),
      textOnRender: t("Events [{{eventsNumber}}]", {
        eventsNumber: machine?.events?.length.toString(),
      }),
      content: <EventsContent setSelected={setSelected} />,
      show: true,
    },
    {
      itemKey: "sensors",
      headerText: "Sensors",
      textOnRender: t("Sensors [{{sensorsNumber}}]", {
        sensorsNumber: machine?.sensors?.length.toString(),
      }),
      content: (
        <SensorsContent
          setSelected={setSelected}
          setActionSensors={setActionSensors}
        />
      ),
      show: true,
    },
    {
      itemKey: "alertLevels",
      headerText: t("Alert Levels"),
      textOnRender: listAlertLevels
        ? t("Alert Levels [{{alertsNumber}}]", {
            alertsNumber: listAlertLevels.length,
          })
        : t("Alert Levels [0]"),
      content: (
        <AlertLevelsContent
          listAlertLevels={listAlertLevels}
          setSelected={setSelected}
          setActionAlertLevel={setActionAlertLevel}
        />
      ),
      show: true,
    },
    {
      itemKey: "images",
      headerText: t("Images"),
      textOnRender: t("Images [{{imagesNumber}}]", {
        imagesNumber: machine?.images?.length.toString(),
      }),
      content: (
        <ImagesContent
          setSelected={setSelected}
          setActionImages={setActionImages}
        />
      ),
      show: auth.metaDataContributor,
    },
    {
      itemKey: "configs",
      headerText: t("Configs"),
      textOnRender: t("Configs [{{configsNumber}}]", {
        configsNumber: machine?.configurations?.length.toString(),
      }),
      content: (
        <ConfigsContent
          setActionEditConfiguration={setActionEditConfiguration}
        />
      ),
      show: auth.metaDataContributor,
    },
    {
      itemKey: "haag",
      headerText: "HAAG",
      textOnRender: `HAAG [${haagData.payload?.size ?? "..."}]`,
      content: <HaagMappingSection machine={machine} data={haagData} />,
      show: auth.metaDataContributor,
    },
  ];

  return (
    <>
      {machine ? (
        <>
          <Stack horizontal verticalAlign="center">
            <IconButton iconProps={{ iconName: "Back" }} onClick={goBack} />
            <Breadcrumb
              items={[
                {
                  key: `corporation-${machine?.corporation?.number}`,
                  text: machine?.corporation?.name,
                },
                {
                  key: `company-${machine?.company?.number}`,
                  text: machine?.company?.name,
                },
                {
                  key: `project-${machine?.project?.name}`,
                  text: machine?.project?.name,
                },
                {
                  key: `machine-${machine?.dalogId}`,
                  text: machine?.dalogId,
                },
              ]}
              styles={{
                root: { margin: 0 },
                item: { fontSize: "14px" },
                chevron: { fontSize: "10px" },
              }}
            />
          </Stack>
          <div style={pageStyle}>
            <PivotRenderPermissionsBased items={arrayPivotItems} />
          </div>
          {showEditStandstill && (
            <EditStandstill
              standstillSettings={machine?.standstillSettings}
              signals={machine?.signals}
              onClose={() => {
                setShowEditStandstill(false);
              }}
              show={showEditStandstill}
              machineId={id}
              onSuccess={() => {
                notification.success(t("Successfully updated"));
                dispatch(listAsyncMachDetails(id || ""));
              }}
            />
          )}
          <BaseDialog
            hidden={
              ![
                "signals",
                "sensors",
                "events",
                "alert Levels",
                "images",
                "frequency catalogue",
              ].includes(selected?.context)
            }
            dialogContentProps={{
              type: DialogType.normal,
              title: t("Import {{entity}}", { entity: selected?.context }),
              closeButtonAriaLabel: t("Close"),
              onDismiss: () => setSelected(undefined),
            }}
          >
            <Table {...tableProps}></Table>
            <DialogFooter>
              {["signals", "sensors", "events"].includes(selected?.context) ? (
                <PrimaryButton
                  text={t("Save Changes")}
                  disabled={isLoading}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                  onClick={() => {
                    setLoading(true);
                    machineImport(
                      id,
                      selected?.context,
                      selected?.file.file
                    ).then((res) => {
                      dispatch(listAsyncMachDetails(id));

                      setSelected(undefined);
                      res.status === 200
                        ? notification.success(t("Added data successfully."))
                        : notification.error(res.text);
                    });
                    setLoading(false);
                  }}
                />
              ) : ["alert Levels"].includes(selected?.context) ? (
                <PrimaryButton
                  text={t("Save Changes")}
                  disabled={isLoading}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                  onClick={() => {
                    setLoading(true);
                    alertLevelsImport(
                      id,
                      selected?.file.name
                        .split(".")
                        .slice(-1)
                        .at(0) as FilesExtensions,
                      selected?.file.file
                    ).then(async (res) => {
                      await alertLevelsList(id).then((response) => {
                        "status" in response
                          ? notification.error(
                              t("Alert levels fetching went wrong.")
                            )
                          : setListAlertLevels(
                              response.map((ele) => {
                                return {
                                  ...ele,
                                  signalName: machine?.signals?.find(
                                    (sig) => sig.id === ele.signalId
                                  ).name,
                                };
                              })
                            );
                      });
                      setLoading(false);
                      setSelected(undefined);
                      res.status === 200
                        ? notification.success(t("Added data successfully."))
                        : notification.error(`${t("Something went wrong")}.`);
                    });
                    //setLoading(false);
                  }}
                />
              ) : ["frequency catalogue"].includes(selected?.context) ? (
                <PrimaryButton
                  text={t("Save Changes")}
                  disabled={isLoading}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                  onClick={() => {
                    setLoading(true);
                    frequencyCatalogueImport(id, selected?.file.file).then(
                      (res) => {
                        if (res.status === 200) {
                          frequencyCatalogueGroupDetails(id).then(
                            (response) => {
                              if ("status" in response) {
                                notification.error(
                                  t("Frequency catalogue fetching went wrong.")
                                );
                              } else {
                                setFrequencyCatalogue(response);
                                dispatch(listAsyncMachDetails(id));
                              }
                            }
                          );
                          notification.success(t("Added data successfully."));
                        } else notification.error(res.text);
                        setSelected(undefined);
                        setLoading(false);
                      }
                    );
                  }}
                />
              ) : (
                <PrimaryButton
                  text={t("Save Changes")}
                  disabled={isLoading}
                  onRenderIcon={() =>
                    isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
                  }
                  onClick={onUploadImagesClick}
                />
              )}

              <DefaultButton
                styles={{
                  root: { border: "unset", background: "transparent" },
                }}
                text={t("Cancel")}
                onClick={() => setSelected(undefined)}
              />
            </DialogFooter>
          </BaseDialog>
          {actionAlertLevel && (
            <CUDDialogALev
              machineId={id}
              data={actionAlertLevel?.data}
              signals={
                ["add"].includes(actionAlertLevel?.context)
                  ? machine?.signals.filter(
                      (sig) =>
                        !listAlertLevels.some(
                          (alert) => alert.signalId === sig.id
                        )
                    )
                  : machine?.signals
              }
              show={["add", "edit", "delete"].includes(
                actionAlertLevel?.context
              )}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message =
                    context === "add"
                      ? t(`Failed creating Alert level`)
                      : t(`Failed updating Alert level`);

                  notification.error(message);
                } else {
                  alertLevelsList(id).then((response) => {
                    "status" in response
                      ? notification.error(
                          t("Alert levels fetching went wrong.")
                        )
                      : setListAlertLevels(
                          response.map((ele) => {
                            return {
                              ...ele,
                              signalName: machine?.signals?.find(
                                (sig) => sig.id === ele.signalId
                              ).name,
                            };
                          })
                        );
                  });
                  const message = t(`Alert level {{context}} successfully`, {
                    context,
                  });

                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionAlertLevel(undefined);
              }}
              context={actionAlertLevel?.context}
            />
          )}
          {actionSignals && (
            <CUDDialogSig
              machineId={id}
              data={actionSignals?.data}
              sensors={machine?.sensors}
              sensorNodes={sensorNodes}
              show={["add", "edit", "delete"].includes(actionSignals?.context)}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  let message = t(
                    `Failed deleting (It may not be a custom signal.) Signal`
                  );

                  if (context === "add") {
                    message = t("Failed creating Signal");
                  }

                  if (context === "edit") {
                    message = t("Failed updating Signal");
                  }

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = t(`Signal {{context}} successfully`, {
                    context,
                  });

                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionSignals(undefined);
              }}
              context={actionSignals?.context}
            />
          )}

          {actionImages && (
            <ListQueue
              show={["queue"].includes(actionImages?.context)}
              onClose={function (): void {
                setActionImages(undefined);
              }}
            />
          )}
          {actionSignalCondition && (
            <EditCondition
              machineId={id}
              data={actionSignalCondition?.data}
              show={["edit"].includes(actionSignalCondition?.context)}
              onSuccess={(hasError) => {
                if (hasError) {
                  const message = t(`Failed updating Signal condition`);

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = t(`Signal condition updated successfully`);

                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionSignalsCondition(undefined);
              }}
            />
          )}

          {actionEditConfiguration && (
            <ConfigurationEditor
              machineId={id}
              data={actionEditConfiguration?.data}
              show={["edit"].includes(actionEditConfiguration?.context)}
              onSuccess={(hasError) => {
                if (hasError) {
                  const message = t(`Failed updating Configuration`);

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = t(`Configuration updated successfully`);
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionEditConfiguration(undefined);
              }}
            />
          )}
          {actionFixCondition && (
            <EditFixCondition
              machineId={id}
              data={actionFixCondition?.data}
              show={["edit"].includes(actionFixCondition?.context)}
              onSuccess={(hasError) => {
                if (hasError) {
                  const message = t(`Failed updating condition`);

                  notification.error(message);
                } else {
                  const message = t(`Condition updated successfully`);
                  notification.success(message);
                  getMachineFixedCondition(id).then((resp) =>
                    !("status" in resp)
                      ? setFixedCondition([resp])
                      : notification.warning(
                          t("Machine condition is not reachable.")
                        )
                  );
                }
              }}
              onClose={() => {
                setActionFixCondition(undefined);
              }}
            />
          )}
          {actionSensors && (
            <CUDDialogSens
              machineId={id}
              data={actionSensors?.data}
              machines={machines}
              show={["add", "edit", "delete"].includes(actionSensors?.context)}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message =
                    context === "add"
                      ? t(`Failed creating Sensor`)
                      : t(`Failed updating Sensor`);

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = t(`Sensor {{context}} successfully`, {
                    context,
                  });
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionSensors(undefined);
              }}
              context={actionSensors?.context}
            />
          )}
          {actionFrequencyCatalogue && (
            <CUDDialogFrequencyCatalogue
              machineId={id}
              data={actionFrequencyCatalogue?.data}
              show={["add", "edit", "delete", "addWithGroup"].includes(
                actionFrequencyCatalogue?.context
              )}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message =
                    context === "add"
                      ? t(`Failed creating Frequency Catalogue item`)
                      : t(`Failed updating Frequency Catalogue item`);

                  notification.error(message);
                } else {
                  frequencyCatalogueGroupDetails(id).then((response) => {
                    "status" in response
                      ? notification.error(
                          t("Frequency Catalogue fetching went wrong.")
                        )
                      : setFrequencyCatalogue(response);
                  });
                  const message = t(
                    `Frequency Catalogue item {{context}} successfully`,
                    { context }
                  );
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionFrequencyCatalogue(undefined);
              }}
              context={actionFrequencyCatalogue?.context}
            />
          )}
          {actionFrequencyCatalogueGroup && (
            <CUDDialogFrequencyCatalogueGroup
              machineId={id}
              data={actionFrequencyCatalogueGroup?.data}
              show={["add", "edit", "delete"].includes(
                actionFrequencyCatalogueGroup?.context
              )}
              onSuccess={(hasError, context) => {
                if (hasError) {
                  const message =
                    context === "add"
                      ? t(`Failed creating Frequency Catalogue Group`)
                      : t(`Failed updating Frequency Catalogue Group`);

                  notification.error(message);
                } else {
                  frequencyCatalogueGroupDetails(id).then((response) => {
                    "status" in response
                      ? notification.error(
                          t("Frequency Catalogue fetching went wrong.")
                        )
                      : setFrequencyCatalogue(response);
                  });
                  const message = t(
                    `Frequency Catalogue item {{context}} successfully`,
                    { context }
                  );
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionFrequencyCatalogueGroup(undefined);
              }}
              context={actionFrequencyCatalogueGroup?.context}
            />
          )}
          {["delete"].includes(actionFrequencyCatalogueMachine?.context) && (
            <ConfirmDialog
              title={t("Delete all items of Machine")}
              isLoading={isLoading}
              onConfirm={() => {
                deleteAllFrequencyCatalogueGroups(id).then((response) => {
                  if (response.status === 200) {
                    notification.success(t("All items deleted successfully"));
                    setFrequencyCatalogue(undefined);
                  } else {
                    notification.error(t("Something went wrong"));
                  }
                });
                setActionFrequencyCatalogueMachine(undefined);
              }}
              onDismiss={() => {
                setActionFrequencyCatalogueMachine(undefined);
              }}
            >
              <Text as="p" variant="medium">
                {t(
                  "You are about to delete all frequency catalogue items of the current machine."
                )}
              </Text>
            </ConfirmDialog>
          )}
          {/* {actionSignalsBulk && (
            <EditBulkDialog
              signalsNotCommon={selectedItems}
              data={selectedItems}
              sensors={items.sensors}
              show={["editB"].includes(actionSignalsBulk?.context)}
              onSuccess={(hasError) => {
                if (hasError) {
                  const message = `Failed updating signals`;

                  notification.error(message);
                } else {
                  dispatch(listAsyncMachDetails(id ? id : ""));
                  const message = `Signals updated successfully`;
                  notification.success(message);
                }
              }}
              onClose={() => {
                setActionSignalsBulk(undefined);
              }}
            />
          )} */}
        </>
      ) : status === Status.idle || status === Status.error ? (
        <NotFoundRoute />
      ) : (
        <Spinner size={SpinnerSize.large} />
      )}
    </>
  );
};
