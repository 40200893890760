import "./style.scss";

import { Dialog } from "@fluentui/react";
import type { IDialogProps } from "@fluentui/react/lib/Dialog";
import { DialogType } from "@fluentui/react/lib/Dialog";

export enum DialogSize {
  S = 450,
  M = 640,
  L = 800,
  AUTO = "auto",
}

// TODO: adjust mobile breakpoints and sizes
const modalProps = {
  isBlocking: false,
  isDarkOverlay: false,
  type: DialogType.normal,
  selectors: {
    "@media (max-width: 768px)": {
      maxWidth: "640px",
      minWidth: "640px",
    },
  },
};

export type BaseDialogProps = IDialogProps & {
  size?: DialogSize;
};

const BaseDialog = ({
  children,
  size = DialogSize.M,
  ...rest
}: BaseDialogProps) => {
  return (
    <Dialog modalProps={modalProps} maxWidth={size} minWidth={size} {...rest}>
      {children}
    </Dialog>
  );
};

export default BaseDialog;
