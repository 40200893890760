import { useMemo, useEffect, useState, CSSProperties } from "react";

import { useZodForm, renderFormItems, FormItemProps } from "../common/Form";
import BaseDialog, { DialogSize } from "../common/Dialog";
import { notification } from "../common/Notification";

import {
  DialogFooter,
  IDialogProps,
  DialogType,
  Stack,
  StackItem,
  Text,
  Spinner,
  SpinnerSize,
  PrimaryButton,
  ChoiceGroup,
  IChoiceGroupOption,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import { Icon as IconF } from "@fluentui/react";
import { z } from "zod";
import { ServiceAccountAdd, ServiceAccountAddRole } from "./models";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import {
  listAsyncServiceAccountProducts,
  selectServiceAccountsProducts,
} from "./reducerProducts";
import {
  selectServiceAccountsError,
  selectServiceAccountsStatus,
} from "./reducer";
import { RoleType, ScopeLevelType, Status } from "../../schema/status";
import type { FieldError } from "react-hook-form";
import { addServiceAccount } from "./api";
import {
  getUserFieldsReq,
  maxLengthType2,
  maxLengthType3,
  pageStyle,
  titleStyle,
} from "../../schema/Constants";
import { RolesComponentToAdd } from "../Roles/RolesComponentToAdd";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export interface ServiceAccountAddRoleToShow extends ServiceAccountAddRole {
  role?: RoleType | undefined;
  scopeLevel?: ScopeLevelType | undefined;
  resource?: string;
}

export type personaProps = {
  persona: RoleType[];
  root: boolean;
};

const titleStylePivot: CSSProperties = {
  fontSize: 18,
  fontWeight: 600,
  paddingRight: 24,
  paddingLeft: 16,
  marginRight: 24,
  marginTop: "auto",
  marginBottom: "50px",
};

const getSchema = () =>
  z.object({
    email: z
      .string()
      .email({ message: t("Invalid email address") })
      .min(1, { message: t("This field is required") })
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      }),
    lastName: z
      .string()
      .min(1, { message: t("This field is required") })
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      }),
    firstName: z
      .string()
      .min(1, { message: t("This field is required") })
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      }),
    purpose: z
      .string()
      .min(1, { message: t("This field is required") })
      .max(maxLengthType3, {
        message: t(`Name must contain at most ${maxLengthType3} character(s)`, {
          maxLength: maxLengthType3,
        }),
      }),
    jobTitle: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    companyName: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    department: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    streetAddress: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    city: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    stateOrProvince: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    zipOrPostalCode: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    countryOrRegion: z
      .string()
      .max(maxLengthType2, {
        message: t(`Name must contain at most ${maxLengthType2} character(s)`, {
          maxLength: maxLengthType2,
        }),
      })
      .optional(),
    mobilePhone: z
      .union([
        z.string().length(0, { message: t("Invalid number") }),
        z.string().regex(/^[+(\s.\-/\d)]{5,30}/),
      ])
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
    memberId: z.string().optional(),
  });

type AddDialogProps = IDialogProps & {
  show: boolean;
  onSuccess: (hasError: boolean, data: string) => void;
  onClose: () => void;
};

export const AddDialog = ({
  show,
  onSuccess,
  onClose,
  ...rest
}: AddDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const schema = useMemo(() => getSchema(), []);
  const status = useAppSelector(selectServiceAccountsStatus);
  const error = useAppSelector(selectServiceAccountsError);
  const items = useAppSelector(selectServiceAccountsProducts);
  const [isLoading, setLoading] = useState(false);
  const [productIdToAdd, setProductIdToAdd] = useState<string>();
  const [rolesToAdd, setRolesToAdd] = useState<ServiceAccountAddRoleToShow[]>(
    []
  );
  useEffect(() => {
    if (status === Status.error) notification.error(error);
    return () => {};
  }, [error, status]);

  useEffect(() => {
    dispatch(listAsyncServiceAccountProducts());
  }, [dispatch]);

  const onDelete = (roles: ServiceAccountAddRoleToShow) => {
    setRolesToAdd(
      rolesToAdd.filter(
        (rol) =>
          rol.roleId !== roles.roleId ||
          rol.scopeLevelId !== roles.scopeLevelId ||
          rol.scopeResourceId !== roles.scopeResourceId
      )
    );
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useZodForm({
    mode: "onChange",
    schema,
    ...{
      defaultValues: {
        email: "",
        lastName: "",
        firstName: "",
        purpose: "",
        jobTitle: "",
        companyName: "",
        department: "",
        streetAddress: "",
        city: "",
        stateOrProvince: "",
        zipOrPostalCode: "",
        countryOrRegion: "",
        mobilePhone: "",
        memberId: "",
      },
    },
  });

  useEffect(() => {
    reset({
      email: "",
      lastName: "",
      firstName: "",
      purpose: "",
      jobTitle: "",
      companyName: "",
      department: "",
      streetAddress: "",
      city: "",
      stateOrProvince: "",
      zipOrPostalCode: "",
      countryOrRegion: "",
      mobilePhone: "",
      memberId: "",
    });
  }, [reset]);

  const handleClose = () => {
    setLoading(false);
    setProductIdToAdd(undefined);
    onClose?.();
  };

  const onSubmit = handleSubmit(async (formData) => {
    setLoading(true);
    const toSubmit: ServiceAccountAdd = {
      ...(formData as ServiceAccountAdd),
      productId: productIdToAdd,
      roles: rolesToAdd as ServiceAccountAddRole[],
    };
    await addServiceAccount(toSubmit).then((res) => {
      onSuccess(!("apimProperties" in res), toSubmit.firstName);
    });
    setLoading(true);
    handleClose();
  });

  function onChange(ev, option: IChoiceGroupOption): void {
    if (
      option.text.includes("WebApp") === true ||
      option.text.includes("Wireless") === true
    )
      notification.severeWarning(
        t("Remind: The selected product is for internal users.")
      );

    setProductIdToAdd(option.key);
  }

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.close,
        title: t("Add Service Account"),
        closeButtonAriaLabel: t("Close"),
        onDismiss: handleClose,
      }}
      styles={{
        main: {
          "@media (min-width: 480px)": {
            height: "78vh",
          },
        },
      }}
      size={DialogSize.L}
    >
      <div style={pageStyle}>
        <Pivot aria-label={t("Pivots to edit service account")}>
          <PivotItem
            headerText={t("Properties")}
            itemKey={"properties"}
            onRenderItemLink={() => (
              <>
                {isValid && productIdToAdd && (
                  <>
                    <IconF iconName="SkypeCheck" />
                  </>
                )}

                <Text style={titleStylePivot}>{t("Properties")}</Text>
              </>
            )}
          >
            <Stack horizontalAlign="space-evenly" verticalAlign="center">
              <div style={{ marginTop: "50px" }}>
                <Text style={titleStyle}>General</Text>
              </div>
              <Stack
                style={{ maxWidth: "70vw", padding: "35px" }}
                wrap
                horizontal
                tokens={{ childrenGap: 8 }}
                horizontalAlign="stretch"
                verticalAlign="center"
              >
                {renderFormItems(
                  getUserFieldsReq({
                    t,
                    withServiceAccountFieldsReqExtra: true,
                    withUserFieldsNonReq: true,
                  }) as FormItemProps[],

                  {
                    control,
                    errors: errors as { [schemaProp: string]: FieldError },
                  }
                ).map((ele) => (
                  <StackItem key={ele.key}> {ele}</StackItem>
                ))}
              </Stack>
              <Text style={titleStyle}>{t("Product")}</Text>
              <Stack
                style={{ maxWidth: "70vw" }}
                wrap
                horizontal
                tokens={{ childrenGap: 8 }}
                horizontalAlign="space-evenly"
                verticalAlign="center"
              >
                <ChoiceGroup
                  options={items?.map((ele) => {
                    return {
                      key: ele.id,
                      text:
                        ele.displayName.includes("WebApp") ||
                        ele.displayName.includes("Wireless")
                          ? t("{{name}} (for internal users)", {
                              name: ele.displayName,
                            })
                          : ele.displayName,
                    };
                  })}
                  onChange={onChange}
                  selectedKey={productIdToAdd}
                />
              </Stack>
            </Stack>
          </PivotItem>
          <PivotItem
            headerText={t("Roles")}
            itemKey={"roles"}
            onRenderItemLink={() => (
              <>
                {rolesToAdd.length > 0 && (
                  <>
                    <IconF iconName="SkypeCheck" />
                  </>
                )}
                <Text style={titleStylePivot}>{t("Roles")}</Text>
              </>
            )}
          >
            {RolesComponentToAdd({
              rolesToAdd,
              setRolesToAdd,
              onDelete,
            })}
          </PivotItem>
        </Pivot>
        <DialogFooter>
          <PrimaryButton
            text={t("Save Changes")}
            disabled={isLoading || !isValid || !productIdToAdd}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
            onClick={onSubmit}
          />
        </DialogFooter>
      </div>
    </BaseDialog>
  );
};
