export const plotStyles = { width: "100%", height: "100%" };

export const plotConfig = {
  displaylogo: false,
  displayModeBar: false,
  responsive: true,
  showTips: false,
  showSendToCloud: true,
  showEditInChartStudio: false,
};

export interface IColorScale {
  key: string;
  text: string;
  scale: Array<[number, string]>;
}

export const getColorScaleOptions: (t) => IColorScale[] = (t) => [
  {
    key: "default",
    text: t("Default"),
    scale: [
      [0, "#FFFFFF"],
      [1, "#2C529F"],
    ],
  },
  {
    key: "YlGnBu",
    text: t("YlGnBu"),
    scale: [
      [0, "rgb(255,255,217)"],
      [0.125, "rgb(237,248,217)"],
      [0.25, "rgb(199,233,180)"],
      [0.375, "rgb(127,205,187)"],
      [0.5, "rgb(65,182,196)"],
      [0.625, "rgb(29,145,192)"],
      [0.75, "rgb(34,94,168)"],
      [0.875, "rgb(37,52,148)"],
      [1, "rgb(8,29,88)"],
    ],
  },
  {
    key: "YlOrRd",
    text: t("YlOrRd"),
    scale: [
      [0, "rgb(255,255,204)"],
      [0.125, "rgb(255,237,160)"],
      [0.25, "rgb(254,217,118)"],
      [0.375, "rgb(254,178,76)"],
      [0.5, "rgb(253,141,60)"],
      [0.625, "rgb(252,78,42)"],
      [0.75, "rgb(227,26,28)"],
      [0.875, "rgb(189,0,38)"],
      [1, "rgb(128,0,38)"],
    ],
  },
  {
    key: "Portland",
    text: t("Portland"),
    scale: [
      [0, "rgb(12,51,131)"],
      [0.25, "rgb(10,136,186)"],
      [0.5, "rgb(242,211,56)"],
      [0.75, "rgb(242,143,56)"],
      [1, "rgb(217,30,30)"],
    ],
  },
  {
    key: "Greys",
    text: t("Greys"),
    scale: [
      [0, "rgb(255,255,255)"],
      [1, "rgb(0,0,0)"],
    ],
  },
  {
    key: "Jet",
    text: t("Jet"),
    scale: [
      [0, "rgb(0,0,131)"],
      [0.125, "rgb(0,60,170)"],
      [0.375, "rgb(5,255,255)"],
      [0.625, "rgb(255,255,0)"],
      [0.875, "rgb(250,0,0)"],
      [1, "rgb(128,0,0)"],
    ],
  },
];
