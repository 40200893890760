import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

export const useMachineConditionsTable = createWithEqualityFn<{
  machines: any[];
  filteredMachines: any[];
  filterRegistry: { [key: string]: string };
  getFilteredState: (key: string) => boolean;
  setMachinesData: (machines: any[]) => void;
  filterMachinesByKeyAndValue: (key: string, value: string) => any;
  clearFilteredDataByKey: (key: string) => void;
}>()(
  (set, get) => ({
    machines: [],
    filteredMachines: [],
    filterRegistry: {},

    setMachinesData: (machines: any[]) => {
      set({ machines, filteredMachines: machines });
    },
    getFilteredState: (key) => {
      const filteringRegistry = get().filterRegistry;

      return !!filteringRegistry[key];
    },
    filterMachinesByKeyAndValue: (key, value) => {
      const data = get().machines;
      const filteringRegistry = get().filterRegistry;

      const newFilteringRegistry = {
        ...filteringRegistry,
        [key]: value,
      };

      const filteredMachines = data.filter((machine) =>
        Object.keys(newFilteringRegistry).every(
          (filterKey) => machine[filterKey] === newFilteringRegistry[filterKey]
        )
      );

      set({ filteredMachines, filterRegistry: newFilteringRegistry });
    },
    clearFilteredDataByKey: (key) => {
      const data = get().machines;
      const filteringRegistry = get().filterRegistry;

      const newFilteringRegistry = Object.keys(filteringRegistry)
        .filter((filterKey) => filterKey !== key)
        .reduce((acc, curr) => {
          acc[curr] = filteringRegistry[curr];
          return acc;
        }, {});

      const filteredMachines = data.filter((machine) =>
        Object.keys(newFilteringRegistry).every(
          (key) => machine[key] === newFilteringRegistry[key]
        )
      );

      set({ filteredMachines, filterRegistry: newFilteringRegistry });
    },
  }),
  shallow
);
