import { Stack, Text, mergeStyles } from "@fluentui/react";
import { useEffect, useState } from "react";
import {
  CompanySubmission,
  CorporationSubmission,
  GatewaySubmission,
  MachineSubmission,
  ProjectSubmission,
  SensorNodesSubmission,
  SubmissionWithSId,
} from "./models";
import { useNavigate, useParams } from "react-router-dom";
import ChevronItemWithChildren from "./ChevronItemWithChildren";
import {
  approveSubmission,
  rejectSubmission,
  sendSubmissionsComment,
  submissionsDetails,
} from "../api";
import DetailsPanel from "./DetailsPanel";
import { RequestApproveSubmissionAdd, SubmissionSupport } from "../models";
import { WirelessGateway } from "../../Gateways/models";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import {
  listAsyncGateways,
  selectWirelessGateways,
  selectWirelessGatewaysStatus,
} from "../../Gateways/reducer";
import {
  listAsyncProjs,
  selectProjects,
  selectProjectsStatus,
} from "../../Projects/reducer";
import { Status } from "../../../schema/status";
import { isValidDalogId } from "../../../schema/Utils";
import { Corporation } from "../../Corporations/models";
import { Company } from "../../../types";
import { Project } from "../../Projects/models";
import { MachineToList } from "../../Machines/models";
import {
  listAsyncMachines,
  selectMachinesStatus,
  selectMachinesToList,
} from "../../Machines/reducer";
import {
  listAsyncCorpo,
  selectCorporations,
  selectCorporationsStatus,
} from "../../Corporations/reducer";
import {
  listAsyncCompanies,
  selectCompanies,
  selectCompaniesStatus,
} from "../../Companies/reducer";
import { notification } from "../../common/Notification";
import { SupportProvider } from "./SupportContext";
import { useTranslation } from "react-i18next";

import { ThreeColumnLayout } from "./ThreeColumnLayout";
import Header from "./Header";
import ChatMessageDisplay from "./ChatPanel/ChatMessageDisplay";
import {
  listAsyncSensorNodes,
  selectWirelessSensorNodes,
  selectWirelessSensorNodesStatus,
} from "../../SensorNodes/reducer";
import { WirelessSensorNodeResponse } from "../../SensorNodes/models";

export interface SubmissionToUpdateByParts {
  corpo: CorporationSubmission;
  comp?: CompanySubmission;
  proj?: ProjectSubmission;
  mach?: MachineSubmission;
  gat?: GatewaySubmission;
  sen?: SensorNodesSubmission;
}

export interface SecondPanelContext {
  context:
    | "Corporation"
    | "Company"
    | "Project"
    | "Machine"
    | "Sensor Node"
    | "Gateway";
  updateFunction: (
    partToUpdate:
      | CorporationSubmission
      | CompanySubmission
      | ProjectSubmission
      | GatewaySubmission
      | MachineSubmission
      | SensorNodesSubmission,
    uniqueId: string
  ) => void;
  initialValues: SubmissionToUpdateByParts;
  matchUniqueId?: string;
  index?: number;
}

const generateUniqueId = (): string => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
};

const addUniqueIdsToSubmission = (
  corporationData: CorporationSubmission
): CorporationSubmission => {
  const updatedCompanies = corporationData?.companies?.map((company) => ({
    ...company,
    uniqueId: generateUniqueId(),
    projects: company.projects?.map((project) => ({
      ...project,
      uniqueId: generateUniqueId(),
      machines: project.machines?.map((machine) => ({
        ...machine,
        uniqueId: generateUniqueId(),
        sensors: machine.sensors?.map((sensor) => ({
          ...sensor,
          uniqueId: generateUniqueId(),
        })),
      })),
      gateways: project.gateways?.map((gateway) => ({
        ...gateway,
        uniqueId: generateUniqueId(),
      })),
    })),
  }));
  return {
    ...corporationData,
    companies: updatedCompanies,
    uniqueId: generateUniqueId(),
  };
};

function updatePartOfSubmission(
  corporationSub: CorporationSubmission,
  uniqueId: string,
  partToUpdate:
    | CorporationSubmission
    | CompanySubmission
    | ProjectSubmission
    | GatewaySubmission
    | MachineSubmission
    | SensorNodesSubmission
): CorporationSubmission {
  if (corporationSub.uniqueId === uniqueId) {
    return { ...corporationSub, ...partToUpdate };
  }

  const updateCompany = (company: CompanySubmission): CompanySubmission => {
    if (company.uniqueId === uniqueId) {
      return { ...company, ...partToUpdate };
    } else {
      const updatedProjects = company.projects?.map(updateProject);
      return { ...company, projects: updatedProjects };
    }
  };

  const updateProject = (project: ProjectSubmission): ProjectSubmission => {
    if (project.uniqueId === uniqueId) {
      return { ...project, ...partToUpdate };
    } else {
      const updatedMachines = project.machines?.map(updateMachine);
      const updatedGateways = project.gateways?.map(updateGateway);
      return {
        ...project,
        machines: updatedMachines,
        gateways: updatedGateways,
      };
    }
  };

  const updateMachine = (machine: MachineSubmission): MachineSubmission => {
    if (machine.uniqueId === uniqueId) {
      return { ...machine, ...partToUpdate };
    } else {
      const updatedSensors = machine.sensors?.map(updateSensor);
      return { ...machine, sensors: updatedSensors };
    }
  };

  const updateSensor = (
    sensor: SensorNodesSubmission
  ): SensorNodesSubmission => {
    if (sensor.uniqueId === uniqueId) {
      return { ...sensor, ...partToUpdate };
    } else {
      return sensor;
    }
  };

  const updateGateway = (gateway: GatewaySubmission): GatewaySubmission => {
    return gateway.uniqueId === uniqueId
      ? { ...gateway, ...partToUpdate }
      : gateway;
  };

  const updatedCompanies = corporationSub?.companies?.map(updateCompany);

  return { ...corporationSub, companies: updatedCompanies };
}

function createSubmissionSupport(
  corporationSubmission: CorporationSubmission,
  submissionSupport: SubmissionSupport | null,
  wirelessGateways: WirelessGateway[]
): SubmissionSupport {
  if (!submissionSupport) {
    submissionSupport = {
      gatewaysSupportToAdd: [],
      gatewaysSupportToUpdate: [],
      machinesSupport: [],
    };
  }

  // Step 1: Process MachineSubmissions
  if (corporationSubmission?.companies) {
    for (const company of corporationSubmission?.companies) {
      if (company.projects) {
        for (const project of company.projects) {
          if (project.machines) {
            for (const machine of project.machines) {
              if (!machine.id) {
                const existingMachineIndex =
                  submissionSupport.machinesSupport.findIndex(
                    (support) => support.matchUniqueId === machine.uniqueId
                  );
                if (existingMachineIndex !== -1) {
                  submissionSupport.machinesSupport[existingMachineIndex] = {
                    dalogId:
                      submissionSupport.machinesSupport[existingMachineIndex]
                        .dalogId,
                    nameMachine: machine.name,
                    matchUniqueId: machine.uniqueId,
                  };
                } else {
                  submissionSupport.machinesSupport.push({
                    dalogId: "",
                    nameMachine: machine.name,
                    matchUniqueId: machine.uniqueId,
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  // Step 2: Process GatewaySubmissions
  if (corporationSubmission?.companies) {
    for (const company of corporationSubmission?.companies) {
      if (company.projects) {
        for (const project of company.projects) {
          if (project.gateways) {
            for (const gateway of project.gateways) {
              const gatewayExists = wirelessGateways.some(
                (wirelessGateway) =>
                  wirelessGateway.serialNumber === gateway.serialNumber
              );

              if (gatewayExists) {
                const existingUpdateIndex =
                  submissionSupport.gatewaysSupportToUpdate.findIndex(
                    (update) => update.matchUniqueId === gateway.uniqueId
                  );

                const existingAddIndex =
                  submissionSupport.gatewaysSupportToAdd.findIndex(
                    (add) => add.matchUniqueId === gateway.uniqueId
                  );
                if (existingAddIndex !== -1) {
                  submissionSupport.gatewaysSupportToAdd.splice(
                    existingAddIndex,
                    1
                  );
                }

                const myGat = wirelessGateways.find(
                  (wirelessGateway) =>
                    wirelessGateway.serialNumber === gateway.serialNumber
                );
                if (existingUpdateIndex !== -1) {
                  submissionSupport.gatewaysSupportToUpdate[
                    existingUpdateIndex
                  ] = {
                    ...myGat,
                    ...submissionSupport.gatewaysSupportToUpdate[
                      existingUpdateIndex
                    ],
                    serialNumber: gateway.serialNumber,
                    matchUniqueId: gateway.uniqueId,
                  };
                } else {
                  submissionSupport.gatewaysSupportToUpdate.push({
                    ...myGat,
                    serialNumber: gateway.serialNumber,
                    matchUniqueId: gateway.uniqueId,
                    isValid: false,
                  });
                }
              } else {
                const existingAddIndex =
                  submissionSupport.gatewaysSupportToAdd.findIndex(
                    (add) => add.matchUniqueId === gateway.uniqueId
                  );

                const existingUpdateIndex =
                  submissionSupport.gatewaysSupportToUpdate.findIndex(
                    (update) => update.matchUniqueId === gateway.uniqueId
                  );

                if (existingUpdateIndex !== -1) {
                  submissionSupport.gatewaysSupportToUpdate.splice(
                    existingUpdateIndex,
                    1
                  );
                }

                if (existingAddIndex !== -1) {
                  submissionSupport.gatewaysSupportToAdd[existingAddIndex] = {
                    ...submissionSupport.gatewaysSupportToAdd[existingAddIndex],
                    serialNumber: gateway.serialNumber,
                    projectId: undefined,
                    matchUniqueId: gateway.uniqueId,
                  };
                } else {
                  submissionSupport.gatewaysSupportToAdd.push({
                    serialNumber: gateway.serialNumber,
                    projectId: "123e4567-e89b-12d3-a456-426614174000",
                    ltePlanSize: 0,
                    adminPassword: "",
                    userPassword: "",
                    matchUniqueId: gateway.uniqueId,
                    isValid: false,
                  });
                }
              }
            }
          }
        }
      }
    }
  }
  return submissionSupport;
}

interface SubmissionsDetailsProps {
  isNotLog?: boolean;
}

const panelClassNameGeneral = mergeStyles({
  height: "calc(100% - 53px)",
});

const panelClassName = mergeStyles({
  padding: 15,
  height: "100%",
  backgroundColor: "#f8f8f8",
  display: "flow",
});

const panelClassNameChat = mergeStyles({
  padding: 5,
  height: "100%",
  backgroundColor: "#f8f8f8",
});

export const SubmissionsDetails: React.FC<SubmissionsDetailsProps> = ({
  isNotLog,
}) => {
  const { t } = useTranslation();
  const [item, setItem] = useState<SubmissionWithSId>();
  const [support, setSupport] = useState<SubmissionSupport>();
  const navigate = useNavigate();
  const { id } = useParams();
  const [secondPanelContext, setSecondPanelContext] =
    useState<SecondPanelContext>();
  const gatsList = useAppSelector(selectWirelessGateways);
  const status = useAppSelector(selectWirelessGatewaysStatus);
  const dispatch = useAppDispatch();
  const projs = useAppSelector(selectProjects);
  const projectStatus = useAppSelector(selectProjectsStatus);
  const machineStatus = useAppSelector(selectMachinesStatus);
  const machines = useAppSelector(selectMachinesToList);
  const sensors = useAppSelector(selectWirelessSensorNodes);
  const statusSensors = useAppSelector(selectWirelessSensorNodesStatus);
  const corpos = useAppSelector(selectCorporations);
  const corporationStatus = useAppSelector(selectCorporationsStatus);
  const companies = useAppSelector(selectCompanies);
  const statusCompanies = useAppSelector(selectCompaniesStatus);
  const [feedback, setFeedback] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const { bigScreen } = useViewport();

  // Gets the machines and projects list.
  useEffect(() => {
    if (
      machineStatus !== Status.void &&
      projectStatus !== Status.void &&
      corporationStatus !== Status.void &&
      statusCompanies !== Status.void
    ) {
      return;
    }

    machineStatus === Status.void && dispatch(listAsyncMachines());
    projectStatus === Status.void && dispatch(listAsyncProjs());
    corporationStatus === Status.void && dispatch(listAsyncCorpo());
    statusCompanies === Status.void && dispatch(listAsyncCompanies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineStatus, projectStatus, corporationStatus, statusCompanies]);

  useEffect(() => {
    if (status === Status.void && projectStatus === Status.idle) {
      dispatch(listAsyncGateways(projs));
    }
  }, [dispatch, projectStatus, status, projs]);

  useEffect(() => {
    if (
      status === Status.idle &&
      machineStatus === Status.idle &&
      statusSensors === Status.void
    ) {
      dispatch(
        listAsyncSensorNodes({
          wireless: gatsList,
          machs: machines,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, machineStatus, status, statusSensors]);

  useEffect(() => {
    submissionsDetails(id).then((list) => {
      const corpoSubmission = addUniqueIdsToSubmission(list.submission);
      setItem({
        ...list,
        submission: corpoSubmission,
        messages: list.messages,
      });
    });
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (status === Status.idle && item && gatsList.length > 0) {
      setSupport(createSubmissionSupport(item.submission, support, gatsList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, item, gatsList]);

  useEffect(() => {
    statusSensors &&
      support &&
      setFeedback(
        validateInput(
          t,
          item.submission,
          corpos,
          companies,
          projs,
          machines,
          sensors,
          support
        )
      );
  }, [statusSensors, support, item?.submission]);

  const goBack = () => navigate(-1);

  const updateRecall = (
    partToUpdate:
      | CorporationSubmission
      | CompanySubmission
      | ProjectSubmission
      | GatewaySubmission
      | MachineSubmission
      | SensorNodesSubmission,
    uniqueId: string
  ): void =>
    setItem((ele) => {
      return {
        ...ele,
        submission: updatePartOfSubmission(
          ele.submission,
          uniqueId,
          partToUpdate
        ),
      };
    });

  const SubmitApproval = () => {
    setIsSubmitting(true);
    const toApprove: RequestApproveSubmissionAdd = {
      submissionToUpdate: item.submission,
      supportForSubmission: support,
    };

    approveSubmission(id, toApprove)
      .then((resp) => {
        if ("data" in resp) {
          notification.success(
            t("Submission {{id}} successfully performed.", {
              id: item.sID,
            })
          );
          navigate("/submissions");
        } else {
          notification.error(t("Error: {{message}}", { message: resp.text }));
        }
        dispatch(listAsyncProjs());
      })
      .catch((error) => {
        notification.error(error.response.data);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  function isValidMachineSupport(mach: any, support: any): boolean {
    const machineSupport = support?.machinesSupport?.find(
      (ele) => ele.matchUniqueId === mach.uniqueId
    );

    return (
      !isValidDalogId(machineSupport?.dalogId) ||
      machines.some((ele) => ele.dalogId === machineSupport.dalogId)
    );
  }

  return (
    <>
      {item && (
        <>
          <div className={panelClassNameGeneral}>
            <Header
              goBack={goBack}
              item={item}
              isNotLog={isNotLog}
              rejectSubmission={rejectSubmission}
              id={id}
              feedback={feedback}
              SubmitApproval={SubmitApproval}
              navigate={navigate}
              isSubmitting={isSubmitting}
            />
            <ThreeColumnLayout
              leftChildren={
                <div className={panelClassName}>
                  <ChevronItemWithChildren
                    key={item?.submission?.name + "chevron"}
                    name={item?.submission?.name}
                    parentIconName="Corporations"
                    chevron={
                      item?.submission?.companies &&
                      item?.submission?.companies?.length > 0
                        ? true
                        : false
                    }
                    onClick={() =>
                      setSecondPanelContext({
                        context: "Corporation",
                        updateFunction: updateRecall,
                        initialValues: { corpo: item.submission },
                      })
                    }
                  >
                    {item.submission.companies &&
                      item.submission.companies.length > 0 && (
                        <>
                          {item.submission.companies.map((ele, index) => (
                            <ChevronItemWithChildren
                              key={ele.name + "chevron"}
                              name={ele.name}
                              parentIconName="Companies"
                              chevron={ele.projects && ele.projects.length > 0}
                              onClick={() =>
                                setSecondPanelContext({
                                  context: "Company",
                                  updateFunction: updateRecall,
                                  initialValues: {
                                    corpo: item.submission,
                                    comp: ele,
                                  },
                                  index: index,
                                })
                              }
                            >
                              {ele.projects && ele.projects.length > 0 && (
                                <>
                                  {ele.projects.map((pro, index) => (
                                    <ChevronItemWithChildren
                                      key={pro.name + "chevron"}
                                      name={pro.name}
                                      parentIconName="Projects"
                                      chevron={
                                        (pro.gateways &&
                                          pro.gateways.length > 0) ||
                                        (pro.machines &&
                                          pro.machines.length > 0)
                                      }
                                      onClick={() =>
                                        setSecondPanelContext({
                                          context: "Project",
                                          updateFunction: updateRecall,
                                          initialValues: {
                                            corpo: item.submission,
                                            comp: ele,
                                            proj: pro,
                                          },
                                          index: index,
                                        })
                                      }
                                    >
                                      {
                                        <>
                                          {pro.machines &&
                                            pro.machines.length > 0 && (
                                              <>
                                                {pro.machines.map(
                                                  (mach, index) => (
                                                    <ChevronItemWithChildren
                                                      key={
                                                        mach.name + "chevron"
                                                      }
                                                      name={mach.name}
                                                      parentIconName="Machines"
                                                      chevron={
                                                        mach.sensors &&
                                                        mach.sensors.length > 0
                                                      }
                                                      onClick={() => {
                                                        setSupport(
                                                          (prevSupport) => ({
                                                            ...prevSupport,
                                                            matchUniqueId:
                                                              mach.uniqueId,
                                                          })
                                                        );
                                                        setSecondPanelContext({
                                                          context: "Machine",
                                                          updateFunction:
                                                            updateRecall,
                                                          initialValues: {
                                                            corpo:
                                                              item.submission,
                                                            comp: ele,
                                                            proj: pro,
                                                            mach: mach,
                                                          },
                                                          index: index,
                                                        });
                                                      }}
                                                      pending={isValidMachineSupport(
                                                        mach,
                                                        support
                                                      )}
                                                    >
                                                      {mach.sensors &&
                                                        mach.sensors.length >
                                                          0 && (
                                                          <>
                                                            {mach.sensors.map(
                                                              (sen, index) => (
                                                                <ChevronItemWithChildren
                                                                  key={
                                                                    sen.sensorSerialNumber +
                                                                    "chevron"
                                                                  }
                                                                  name={
                                                                    sen.sensorSerialNumber
                                                                  }
                                                                  parentIconName="SensorsNodes"
                                                                  chevron={
                                                                    false
                                                                  }
                                                                  onClick={() => {
                                                                    setSecondPanelContext(
                                                                      {
                                                                        context:
                                                                          "Sensor Node",
                                                                        updateFunction:
                                                                          updateRecall,
                                                                        initialValues:
                                                                          {
                                                                            corpo:
                                                                              item.submission,
                                                                            comp: ele,
                                                                            proj: pro,
                                                                            mach: mach,
                                                                            sen: sen,
                                                                          },
                                                                        index:
                                                                          index,
                                                                      }
                                                                    );
                                                                  }}
                                                                />
                                                              )
                                                            )}
                                                          </>
                                                        )}
                                                    </ChevronItemWithChildren>
                                                  )
                                                )}
                                              </>
                                            )}

                                          {pro.gateways &&
                                            pro.gateways.length > 0 && (
                                              <>
                                                {pro.gateways.map(
                                                  (gat, index) => (
                                                    <ChevronItemWithChildren
                                                      key={
                                                        gat.serialNumber +
                                                        "chevron"
                                                      }
                                                      name={gat.serialNumber}
                                                      parentIconName="Gateways"
                                                      chevron={false}
                                                      pending={
                                                        gatsList.some(
                                                          (g) =>
                                                            g.serialNumber ===
                                                            gat.serialNumber
                                                        )
                                                          ? !support?.gatewaysSupportToUpdate?.find(
                                                              (ele) =>
                                                                ele.matchUniqueId ===
                                                                  gat.uniqueId &&
                                                                ele.serialNumber ===
                                                                  gat.serialNumber
                                                            )?.isValid
                                                          : !support?.gatewaysSupportToAdd?.find(
                                                              (ele) =>
                                                                ele.matchUniqueId ===
                                                                  gat.uniqueId &&
                                                                ele.serialNumber ===
                                                                  gat.serialNumber
                                                            )?.isValid
                                                      }
                                                      onClick={() => {
                                                        setSupport(
                                                          (prevSupport) => ({
                                                            ...prevSupport,
                                                            matchUniqueId:
                                                              gat.uniqueId,
                                                          })
                                                        );
                                                        setSecondPanelContext({
                                                          context: "Gateway",
                                                          updateFunction:
                                                            updateRecall,
                                                          initialValues: {
                                                            corpo:
                                                              item.submission,
                                                            comp: ele,
                                                            proj: pro,
                                                            gat: gat,
                                                          },
                                                          matchUniqueId:
                                                            gat.uniqueId,
                                                          index: index,
                                                        });
                                                      }}
                                                    />
                                                  )
                                                )}
                                              </>
                                            )}
                                        </>
                                      }
                                    </ChevronItemWithChildren>
                                  ))}
                                </>
                              )}
                            </ChevronItemWithChildren>
                          ))}
                        </>
                      )}
                  </ChevronItemWithChildren>
                </div>
              }
              centerChildren={
                <div className={panelClassName}>
                  <Text variant={"xLarge"} nowrap>
                    {secondPanelContext?.context
                      ? t(secondPanelContext.context)
                      : t("Select an item")}
                  </Text>

                  <Stack
                    styles={{
                      root: {
                        display: "flex",
                        alignItems: "flex",
                      },
                    }}
                  >
                    {secondPanelContext && (
                      <SupportProvider
                        support={support}
                        setSupport={setSupport}
                      >
                        <DetailsPanel
                          context={secondPanelContext}
                          key={
                            secondPanelContext.context +
                            secondPanelContext.index
                          }
                        />
                      </SupportProvider>
                    )}
                  </Stack>
                </div>
              }
              rightChildren={
                <div className={panelClassNameChat}>
                  <ChatMessageDisplay
                    messages={item.messages}
                    creatorName={item.creatorName}
                    onSent={(mess) =>
                      sendSubmissionsComment(id, mess).then((resp) =>
                        !("data" in resp)
                          ? notification.error(
                              t("Some issue sending the submission comment")
                            )
                          : submissionsDetails(id).then((list) => {
                              const corpoSubmission = addUniqueIdsToSubmission(
                                list.submission
                              );
                              setItem({
                                ...list,
                                submission: corpoSubmission,
                                messages: list.messages,
                              });
                            })
                      )
                    }
                  />
                </div>
              }
            />
          </div>
        </>
      )}
    </>
  );
};

function validateInput(
  t,
  submission: CorporationSubmission,
  corpos: Corporation[],
  companies: Company[],
  projs: Project[],
  machs: MachineToList[],
  sensors: WirelessSensorNodeResponse[],
  supportRaw: SubmissionSupport
): string | undefined {
  if (submission.id) {
    const existing = corpos.some((ele) => ele.id === submission.id);
    if (!existing)
      return t(
        "Selected corporation does not exist id: {{submissionId}} : It may mean that the corporation was deleted.",
        { submissionId: submission.id }
      );
    if (!submission?.companies?.length)
      return t(
        "Since you are providing a Corporation Id: The 'companies' list is either null or empty. Please provide valid companies information."
      );
  } else {
    const existing = corpos.some((ele) => ele.name === submission.name);
    if (existing)
      return t(
        "Name of corporation {{submissionName}}, you want to add, already exist",
        { submissionName: submission.name }
      );
  }

  for (const company of submission?.companies) {
    if (company.id) {
      const existing = companies.some((ele) => ele.id === company.id);
      if (!existing)
        return t(
          "Selected company does not exist id: {{companyId}} : It may mean that the company was deleted.",
          { companyId: company.id }
        );
      if (!company?.projects?.length)
        return t(
          "Since you are providing a Company Id: The 'projects' list is either null or empty. Please provide valid project information."
        );
    } else {
      const existing = companies.some((ele) => ele.name === company.name);
      if (existing)
        return (
          "Name of company " + company.name + ", you want to add, already exist"
        );
    }

    for (const project of company.projects) {
      if (project.id) {
        const existing = projs.some((ele) => ele.id === project.id);
        if (!existing)
          return t(
            "Selected project does not exist id: {{projectId}} : It may mean that the project was deleted.",
            { projectId: project.id }
          );

        if (!project?.machines?.length && !project?.gateways?.length)
          return t(
            "Since you are providing a Project Id: The 'machines' || 'gateways' list is either null or empty. Please provide valid 'machines' || 'gateways' information."
          );
      }

      for (const machine of project.machines) {
        if (machine.id) {
          const existing = machs.some((ele) => ele.id === machine.id);

          if (!existing)
            return t(
              "Selected machine does not exist id: {{machineId}} : It may mean that the machine was deleted.",
              { machineId: machine.id }
            );

          if (machine.sensors === null || machine.sensors.length === 0)
            return t(
              "Since you are providing a Machine Id: The 'sensors' list is either null or empty. Please provide valid 'sensors' information."
            );
        }
        if (!machine.name || machine.name.trim() === "") {
          return t(`machines: Name cannot be undefined or empty.`);
        }
        if (machine?.sensors && machine.sensors.length) {
          for (const sensor of machine.sensors) {
            const existing = sensors.some(
              (ele) =>
                ele.sensorNodeId === sensor.sensorSerialNumber.toLowerCase()
            );
            if (existing)
              return t(
                "Sensor with serial number {{serialNumber}} already exist.",
                { serialNumber: sensor.sensorSerialNumber }
              );
          }
        }
      }
    }
  }

  const supportFilter = filterGatewaySupport(supportRaw, submission);

  // Validate gatewaysSupportToAdd
  for (const gateway of supportFilter.gatewaysSupportToAdd) {
    if (!gateway.serialNumber || gateway.serialNumber.trim() === "") {
      return t(`gateways: serialNumber cannot be undefined or empty`);
    }
    if (!gateway.ltePlanSize) {
      return t(
        `gateways: ltePlanSize cannot be undefined or empty for serialNumber: {{serialNumber}}`,
        { serialNumber: gateway.serialNumber }
      );
    }
    if (gateway.isValid === false) {
      return t(
        `gateways: isValid is false for serialNumber: {{serialNumber}}`,
        { serialNumber: gateway.serialNumber }
      );
    }
  }

  // Validate gatewaysSupportToUpdate
  for (const gateway of supportFilter.gatewaysSupportToUpdate) {
    if (!gateway.serialNumber || gateway.serialNumber.trim() === "") {
      return t(`gateways: serialNumber cannot be undefined or empty`);
    }
    if (!gateway.ltePlanSize) {
      return t(
        `gateways: ltePlanSize cannot be undefined or empty for serialNumber: {{serialNumber}}`,
        { serialNumber: gateway.serialNumber }
      );
    }
    if (!gateway.id || gateway.id.trim() === "") {
      return t(
        `gateways: id cannot be undefined or empty for serialNumber: {{serialNumber}}`,
        { serialNumber: gateway.serialNumber }
      );
    }
    if (gateway.isValid === false) {
      return t(
        `gateways: isValid is false for serialNumber: {{serialNumber}}`,
        { serialNumber: gateway.serialNumber }
      );
    }
  }

  // Validate machinesSupport
  for (const machine of supportFilter.machinesSupport) {
    if (!machine.dalogId || machine.dalogId.trim() === "") {
      return t(
        `machines: dalogId cannot be undefined or empty. name: {{machineName}}`,
        { machineName: machine.nameMachine }
      );
    }
    if (!isValidDalogId(machine.dalogId))
      return t(
        `machines: dalogId {{machineName}} is not valid. Format (xx-xxx-xx)`,
        { machineName: machine.nameMachine }
      );
    if (machs.some((ele) => ele.dalogId === machine.dalogId))
      return t(`machines: dalogId ${machine.nameMachine} already exist.`, {
        machineName: machine.nameMachine,
      });
  }

  return undefined;
}

function filterGatewaySupport(
  submissionSupport: SubmissionSupport,
  corporationSubmission: CorporationSubmission
): SubmissionSupport {
  const validGatewayIds = new Set<string>();

  // Collect all valid gateway uniqueIds
  corporationSubmission?.companies?.forEach((company) => {
    company.projects?.forEach((project) => {
      project.gateways?.forEach((gateway) => {
        validGatewayIds.add(gateway.uniqueId);
      });
    });
  });

  // Filter gatewaysSupportToAdd
  const filteredGatewaysSupportToAdd =
    submissionSupport.gatewaysSupportToAdd.filter((gateway) =>
      validGatewayIds.has(gateway.matchUniqueId)
    );

  // Filter gatewaysSupportToUpdate
  const filteredGatewaysSupportToUpdate =
    submissionSupport.gatewaysSupportToUpdate.filter((gateway) =>
      validGatewayIds.has(gateway.matchUniqueId)
    );

  return {
    ...submissionSupport,
    gatewaysSupportToAdd: filteredGatewaysSupportToAdd,
    gatewaysSupportToUpdate: filteredGatewaysSupportToUpdate,
  };
}
