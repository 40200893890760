import type { IDialogProps } from "@fluentui/react";
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import type { FieldError } from "react-hook-form";
import { z } from "zod";

import { useTask } from "../../../hooks/useTask";
import { useTaskUpdate } from "../../../hooks/useTaskMutations";
import useTasksStore from "../../../hooks/useTasksStore";
import BaseDialog from "../../../../../common/Dialog/Dialog";
import { renderFormItems, useZodForm } from "../../../../../common/Form";
import type { FormItemProps } from "../../../../../common/Form/FormItems/helpers";
import { FormItemType } from "../../../../../common/Form/FormItems/helpers";
import { notification } from "../../../../../common/Notification";

import { useTranslation } from "react-i18next";

type TaskDetailsDialogProps = {
  id: string;
  onSuccess?: () => void;
} & IDialogProps;

const getSchema = (t) =>
  z.object({
    subject: z.string(),
    description: z
      .string()
      .max(512, {
        message: t("Description must contain at most 512 character(s)"),
      })
      .optional(),
  });

export const TaskDetailsDialog: FC<TaskDetailsDialogProps> = (props) => {
  const { t } = useTranslation();
  const { edit } = useTasksStore((state) => ({
    edit: state.edit,
  }));
  const { task, getTaskDetails } = useTask({ taskId: edit.id });
  const { updateTaskAsync } = useTaskUpdate();
  const [isLoading, setIsLoading] = useState(false);
  const schema = useMemo(() => getSchema(t), [t]);

  useEffect(() => {
    reset(task);
  }, [task?.subject, task?.description]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useZodForm({
    defaultValues: useMemo(
      () => ({
        subject: task?.subject,
        description: task?.description,
      }),
      [task?.subject, task?.description]
    ),
    schema,
  });

  const taskFields: FormItemProps[] = [
    {
      name: "subject",
      type: FormItemType.TextField,
      groupProps: { label: t("Subject") },
    },
    {
      name: "description",
      type: FormItemType.TextArea,
      groupProps: { label: t("Description") },
    },
  ];

  const { dispatch } = useTasksStore((state) => ({
    dispatch: state.dispatch,
  }));

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    await updateTaskAsync({ id: edit.id || "", task: { ...task, ...data } })
      .then(() => {
        notification.success(t("Task successfully updated!"));
        dispatch({ type: "SET_RELOAD" });
        getTaskDetails();
        onClose();
      })
      .catch(() => notification.error(t("Error updating task")))
      .finally(() => setIsLoading(false));
  });

  const onClose = () => {
    props.onDismiss?.();
  };

  return (
    <>
      <BaseDialog
        {...props}
        dialogContentProps={{
          type: DialogType.normal,
          title: t("Edit task details"),
          closeButtonAriaLabel: t("Close"),
        }}
      >
        <form onSubmit={onSubmit}>
          {renderFormItems(taskFields, {
            control,
            errors: errors as { [schemaProp: string]: FieldError },
          })}
          <DialogFooter>
            <PrimaryButton
              type="submit"
              text={t("Save changes")}
              disabled={isLoading}
              onRenderIcon={() =>
                isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
              }
            />
            <DefaultButton
              styles={{
                root: { border: "unset", background: "transparent" },
              }}
              text={t("Cancel")}
              onClick={onClose}
            />
          </DialogFooter>
        </form>
      </BaseDialog>
    </>
  );
};
