import { DefaultButton } from "@fluentui/react";
import type { CSSProperties } from "react";

import { useMap } from "../../../../common/Map";
import { useTranslation } from "react-i18next";

const containerStyles: CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "end",
  marginBottom: 8,
  gap: 8,
};

const buttonStyle: CSSProperties = {
  border: 0,
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  background: "white",
  borderRadius: 2,
  height: 32,
  padding: 8,
};

const labelStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: 8,
};

export const Continents = () => {
  const { t } = useTranslation();

  const continents = [
    { name: t("Africa"), center: [8.8701, 19.7472] },
    { name: t("Asia"), center: [34.0479, 100.6197] },
    { name: t("Australia"), center: [-25.2744, 133.7751] },
    { name: t("Europe"), center: [54.906667, 25.32] },
    { name: t("North America"), center: [54.526, -105.2551] },
    { name: t("South America"), center: [-14.235, -51.9253] },
  ];

  const {
    actions: { changeViewport },
  } = useMap();

  return (
    <div style={containerStyles}>
      <div style={labelStyles}>{t("Go to:")}</div>
      {continents.map(({ name, center: [latitude, longitude] }) => (
        <DefaultButton
          key={name}
          style={buttonStyle}
          onClick={() => {
            changeViewport({
              latitude,
              longitude,
              zoom: 3,
            });
          }}
        >
          {name}
        </DefaultButton>
      ))}
    </div>
  );
};
