import { Spinner, SpinnerSize } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import type { IDialogProps } from "@fluentui/react/lib/Dialog";
import { DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { useMutation } from "@tanstack/react-query";
import * as React from "react";
import { useState } from "react";

import {
  getApiClient,
  validateStatus,
} from "../../../core/apiClient/useApiStore";
import BaseDialog, {
  DialogSize,
} from "../../../../Components/common/Dialog/Dialog";
import { notification } from "../../../../Components/common/Notification";
import { useTranslation } from "react-i18next";

export type DeleteItemDataProps = {
  name: string;
  ids: string[];
};

type DeleteDialogProps = IDialogProps & {
  route: string;
  onSuccess?: () => void;
  onClose?: () => void;
  confirmText?: string;
  closeText?: string;
  data: DeleteItemDataProps;
};

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  confirmText,
  closeText,
  onClose,
  route,
  onSuccess,
  data,
  dialogContentProps,
  ...rest
}) => {
  const { ids, name } = data;
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  const { mutateAsync } = useMutation((ids: string[]) =>
    Promise.allSettled(
      ids.map((id) =>
        getApiClient().delete(`${route}/${id}`, { validateStatus })
      )
    )
  );

  const handleMutateAsync = async () => {
    setLoading(true);

    const settled = await mutateAsync(ids);

    const errors = settled.filter((response) => {
      if (response.status === "rejected") {
        return true;
      }

      return response?.value?.status < 200 || response?.value?.status >= 300;
    });

    if (errors.length === 0) {
      onSuccess?.();
      notification.success(t(`{{name}} deleted successfully`, { name }));
    } else {
      if (errors.length === ids.length) {
        notification.error(
          t(`Failed deleting {{multipleIds}} {{name}}`, {
            name,
            multipleIds: ids.length > 1 ? ids.length : "",
          })
        );
      } else {
        onSuccess?.();
        notification.error(
          t(`Failed deleting {{errorNumber}} out of {{idNumber}} {{name}}`, {
            errorNumber: errors.length,
            idNumber: ids.length,
            name,
          })
        );
      }
    }

    dialogContentProps?.onDismiss?.();
    setLoading(false);
  };

  const onDeleteClick = async () => {
    await handleMutateAsync();
  };

  const onCloseClick = () => {
    onClose?.();
    dialogContentProps?.onDismiss?.();
  };

  const defaultContent = (
    <>
      {t("You are about to delete")}{" "}
      <span style={{ fontWeight: "bold" }}>
        {ids.length > 1 ? `${ids.length} ${name}` : name}
      </span>
    </>
  );

  return (
    <BaseDialog
      {...rest}
      dialogContentProps={{
        title: t("Attention!"),
        type: DialogType.close,
        ...dialogContentProps,
      }}
      size={DialogSize.M}
    >
      {dialogContentProps?.children || defaultContent}
      <DialogFooter>
        <PrimaryButton
          text={confirmText || t("Delete")}
          disabled={isLoading}
          onClick={onDeleteClick}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton text={closeText || t("Cancel")} onClick={onCloseClick} />
      </DialogFooter>
    </BaseDialog>
  );
};

export default DeleteDialog;
