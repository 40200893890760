/* eslint-disable react-hooks/exhaustive-deps */
import {
  ICommandBarItemProps,
  ICommandBarStyles,
  IconButton,
  IStackProps,
  Stack,
} from "@fluentui/react";
import React, { useEffect, useMemo, useState } from "react";
import { HaagMappingData } from "../../../../Hooks/useHaagMapping";
import { Status } from "../../../../schema/status";
import BaseCommandBar, {
  CommandBarItemProps,
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../common/CommandBar";
import Table, { Column, useTableFilters } from "../../../common/Table";
import { ResponseMachineDetailsWithChilds } from "../../models";
import HaagMappingAddEditDialog from "./HaagMappingAddEditDialog";
import HaagMappingDeleteBySourceDialog from "./HaagMappingDeleteBySourceDialog";
import HaagMappingDeleteDialog from "./HaagMappingDeleteDialog";
import HaagMappingImportDialog from "./HaagMappingImportDialog";
import {
  HaagSignalMapping,
  OpcUaAccessMode,
  OpcUaDataType,
  SignalSource,
} from "./models";
import { useTranslation } from "react-i18next";

enum UserActions {
  None,
  Add,
  Import,
  Edit,
  Delete,
  DeleteBySource,
}

type TableItem = Omit<
  HaagSignalMapping,
  "machineId" | "signalId" | "plantId" | "source" | "accessMode" | "dataType"
> & {
  plantId: string;
  source: string;
  signalName: string;
  accessMode: string;
  dataType: string;
};

type UserActionsState = {
  action: UserActions;
  item?: HaagSignalMapping;
};

type GetCommandBarItemProps = {
  t: any;
  machineId: string;
  onRefreshClick: () => void;
  onImportClick: () => void;
  onAddClick: () => void;
  onDeleteBySourceClick: () => void;
};

type GetTableColumnsProps = {
  t: any;
  onEditClick: (tableItem: TableItem) => void;
  onDeleteClick: (tableItem: TableItem) => void;
};

type ActionDialogProps = {
  machineId: string;
  state: UserActionsState;
  onClose: (refresh: boolean) => void;
};

type HaagMappingSectionProps = IStackProps & {
  machine: ResponseMachineDetailsWithChilds;
  data: HaagMappingData;
};

const commandBarStyles: ICommandBarStyles = {
  root: { marginBottom: "0em", background: "transparent", paddingLeft: "0em" },
};

const getCommandBarItems = ({
  t,
  machineId,
  onRefreshClick,
  onImportClick,
  onAddClick,
  onDeleteBySourceClick,
}: GetCommandBarItemProps): ICommandBarItemProps[] => {
  const result: CommandBarItemProps[] = [
    {
      key: `haag-${machineId}-refresh`,
      text: t("Refresh"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Refresh" },
      onClick: onRefreshClick,
    },
    {
      key: `haag-${machineId}-import`,
      text: t("Import"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Import" },
      onClick: onImportClick,
    },
    {
      key: `haag-${machineId}-add`,
      text: t("Add"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add" },
      onClick: onAddClick,
    },
    {
      key: `haag-${machineId}-delete-by-source`,
      text: t("Delete by Source"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Delete" },
      onClick: onDeleteBySourceClick,
    },
  ];

  return computeCommandBarItems(result);
};

const getTableColumns = ({
  t,
  onEditClick,
  onDeleteClick,
}: GetTableColumnsProps) => {
  const dataColumns: Column[] = [
    {
      key: "nodeId",
      name: t("Node ID"),
      fieldName: "nodeId",
      minWidth: 100,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "plantId",
      name: t("Plant ID"),
      fieldName: "plantId",
      minWidth: 100,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "deviceName",
      name: t("Device Name"),
      fieldName: "deviceName",
      minWidth: 100,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "componentName",
      name: t("Component Name"),
      fieldName: "componentName",
      minWidth: 150,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "metricName",
      name: t("Metric Name"),
      fieldName: "metricName",
      minWidth: 100,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "unit",
      name: t("Unit"),
      fieldName: "unit",
      minWidth: 50,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "sourceIdentifier",
      name: t("Source Identifier"),
      fieldName: "sourceIdentifier",
      minWidth: 150,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "signalName",
      name: t("Signal Name"),
      fieldName: "signalName",
      minWidth: 100,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "source",
      name: t("Source"),
      fieldName: "source",
      minWidth: 100,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "namespaceIndex",
      name: t("Namespace Index"),
      fieldName: "namespaceIndex",
      minWidth: 125,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "periodSec",
      name: t("Period (sec)"),
      fieldName: "periodSec",
      minWidth: 50,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "accessMode",
      name: t("Access Mode"),
      fieldName: "accessMode",
      minWidth: 150,
      isSortable: true,
      isExportable: true,
    },
    {
      key: "dataType",
      name: t("Data Type"),
      fieldName: "dataType",
      minWidth: 75,
      isSortable: true,
      isExportable: true,
    },
  ];

  const actionsColumn: Column = {
    key: "actions",
    name: t("Actions"),
    fieldName: "actions",
    minWidth: 100,
    isSortable: false,
    isExportable: false,
    onRender: (item: TableItem) => (
      <Stack horizontal>
        <IconButton
          key={`haag-${item.id}-edit`}
          title={t("Edit")}
          ariaLabel={t("Edit")}
          iconProps={{ iconName: "Edit" }}
          onClick={() => onEditClick(item)}
        />
        <IconButton
          key={`haag-${item.id}-delete`}
          title={t("Delete")}
          ariaLabel={t("Delete")}
          iconProps={{ iconName: "Delete" }}
          onClick={() => onDeleteClick(item)}
        />
      </Stack>
    ),
  };

  return [...dataColumns, actionsColumn];
};

const ActionDialog = ({ machineId, state, onClose }: ActionDialogProps) => {
  let result: JSX.Element | null = null;
  switch (state.action) {
    case UserActions.Add:
      result = (
        <HaagMappingAddEditDialog machineId={machineId} onClose={onClose} />
      );
      break;

    case UserActions.Import:
      result = (
        <HaagMappingImportDialog machineId={machineId} onClose={onClose} />
      );
      break;

    case UserActions.Edit:
      result = (
        <HaagMappingAddEditDialog
          machineId={machineId}
          item={state.item}
          onClose={onClose}
        />
      );
      break;

    case UserActions.Delete:
      result = (
        <HaagMappingDeleteDialog
          machineId={machineId}
          item={state.item}
          onClose={onClose}
        />
      );
      break;

    case UserActions.DeleteBySource:
      result = (
        <HaagMappingDeleteBySourceDialog
          machineId={machineId}
          onClose={onClose}
        />
      );
      break;
  }

  return result;
};

const HaagMappingSection = ({
  machine,
  data,
  ...rest
}: HaagMappingSectionProps) => {
  const { t } = useTranslation();
  const [tableItems, setTableItems] = useState<TableItem[]>([]);
  const [actionsState, setActionsState] = useState<UserActionsState>({
    action: UserActions.None,
  });
  const { filters, handleSearch } = useTableFilters<TableItem>({
    keys: [
      "nodeId",
      "signalName",
      "deviceName",
      "componentName",
      "metricName",
      "sourceIdentifier",
      "source",
      "accessMode",
      "dataType",
    ],
  });
  const machineId = useMemo(() => {
    return machine?.id ?? "";
  }, [machine.id]);

  // Builds the table items.
  useEffect(() => {
    if (data.status !== Status.idle || !data.payload) {
      return;
    }

    // Builds the items.
    const tableItems: TableItem[] = Array.from(data.payload.values()).map(
      (item) => {
        const index = machine.signals?.findIndex((s) => s.id === item.signalId);
        const signalName = index >= 0 ? machine.signals![index].name : "";

        return {
          ...item,
          signalName,
          plantId: item.plantId ?? "",
          source: SignalSource[item.source],
          accessMode: item.accessMode ? OpcUaAccessMode[item.accessMode] : "",
          dataType: item.dataType ? OpcUaDataType[item.dataType] : "",
        };
      }
    );

    setTableItems(tableItems);
  }, [data.status]);

  // Handlers
  const onRefreshClick = () => data.refresh();

  const onImportClick = () => setActionsState({ action: UserActions.Import });

  const onAddClick = () => setActionsState({ action: UserActions.Add });

  const onEditClick = (tableItem: TableItem) => {
    const item = data.payload?.get(tableItem.id);
    if (!item) {
      return;
    }

    setActionsState({ action: UserActions.Edit, item });
  };

  const onDeleteClick = (tableItem: TableItem) => {
    const item = data.payload?.get(tableItem.id);
    if (!item) {
      return;
    }

    setActionsState({ action: UserActions.Delete, item });
  };

  const onDeleteBySourceClick = () =>
    setActionsState({ action: UserActions.DeleteBySource });

  const onCloseActionDialog = (refresh: boolean) => {
    refresh === true && onRefreshClick();
    setActionsState({ action: UserActions.None });
  };

  return (
    <React.Fragment>
      <Stack {...rest} role="presentation">
        <BaseCommandBar
          items={getCommandBarItems({
            t,
            machineId,
            onRefreshClick,
            onImportClick,
            onAddClick,
            onDeleteBySourceClick,
          })}
          onSearch={handleSearch}
          styles={commandBarStyles}
        />
        <Table
          persistOpts={{
            key: `haag-${machineId}-table`,
            version: 2,
          }}
          header={{ title: "HAAG" }}
          hasSelection={false}
          isLoading={data.status === Status.loading}
          isError={data.status === Status.error}
          items={tableItems}
          columns={getTableColumns({ t, onEditClick, onDeleteClick })}
          filters={filters}
        />
      </Stack>
      <ActionDialog
        machineId={machineId}
        state={actionsState}
        onClose={onCloseActionDialog}
      />
    </React.Fragment>
  );
};

export default HaagMappingSection;
