import { useContext, useEffect, useMemo } from "react";

import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../../../common/Form";

import { IDialogProps, Stack } from "@fluentui/react";
import { z } from "zod";
import type { FieldError } from "react-hook-form";
import { maxLengthType1 } from "../../../../schema/Constants";
import { RequestWirelessGatewayAddAux } from "../../models";
import { SupportContext } from "../SupportContext";
import { maxRsshPort, minRsshPort } from "../../../Gateways/AddDialog";
import { useTranslation } from "react-i18next";

const getSchema = (t, serialNumbers: string[]) =>
  z
    .object({
      projectId: z.string().optional(),
      serialNumber: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(
            `Serial Number must contain at most {{maxLength}} character(s)`,
            { maxLength: maxLengthType1 }
          ),
        })
        .refine(
          (val) =>
            serialNumbers
              .map((serialN) => serialN.trim().toLowerCase())
              .findIndex((value) => value === val.trim().toLowerCase()) === -1,
          {
            message: t("The serial number already exists"),
          }
        ),
      adminPassword: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(
            `Admin Password must contain at most {{maxLength}} character(s)`,
            { maxLength: maxLengthType1 }
          ),
        }),
      userPassword: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(
            `User Password must contain at most {{maxLength}} character(s)`,
            { maxLength: maxLengthType1 }
          ),
        }),
      ltePlanSize: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(`Name must contain at most {{maxLength}} character(s)`, {
            maxLength: maxLengthType1,
          }),
        }),
      publicKey: z.string().optional(),
      rsshPort: z.string().optional(),
      wifiPasswordForAP: z.string().optional(),
      wifiSSID: z.string().optional(),
      iMEI: z.string().optional(),
      wirepassSinkNodeAddress: z.string().optional(),
      wirepassChannel: z.string().optional(),
      ethernetMacAddress: z.string().optional(),
      firmware: z.string().optional(),
    })
    .refine(
      (input) => {
        if (!input.rsshPort) {
          return true;
        }
        const rsshPortNumber = Number(input.rsshPort);
        return (
          Number.isInteger(rsshPortNumber) &&
          rsshPortNumber >= minRsshPort &&
          rsshPortNumber <= maxRsshPort
        );
      },
      {
        path: ["rsshPort"],
        message: t(
          `rsshPort must be an integer between {{minRsshPort}} and {{maxRsshPort}}`,
          { minRsshPort, maxRsshPort }
        ),
      }
    )
    .refine(
      (input) => {
        if (!input.ltePlanSize) {
          return true;
        }
        var regExpression = /^\d*\.?\d*$/;
        const valid = new RegExp(regExpression);
        return valid.test(input.ltePlanSize);
      },
      {
        path: ["ltePlanSize"],
        message: t("Try a number between (1.0:1000.0)"),
      }
    );

const getGatewayFields: (t) => FormItemProps[] = (t) => [
  {
    name: "adminPassword",
    type: FormItemType.TextField,
    groupProps: { label: t("Admin Password *") },
  },
  {
    name: "userPassword",
    type: FormItemType.TextField,
    groupProps: { label: t("User Password *") },
  },
  {
    name: "ltePlanSize",
    type: FormItemType.TextField,
    groupProps: { label: t("Lte Plan Size *") },
  },
  {
    name: "publicKey",
    type: FormItemType.TextField,
    groupProps: { label: t("Public Key") },
  },
  {
    name: "rsshPort",
    type: FormItemType.TextField,
    groupProps: { label: t("Rssh Port") },
  },
  {
    name: "wifiPasswordForAP",
    type: FormItemType.TextField,
    groupProps: { label: t("Wifi Password For AP") },
  },
  {
    name: "wifiSSID",
    type: FormItemType.TextField,
    groupProps: { label: t("Wifi SSID") },
  },
  {
    name: "iMEI",
    type: FormItemType.TextField,
    groupProps: { label: t("IMEI") },
  },
  {
    name: "wirepassSinkNodeAddress",
    type: FormItemType.TextField,
    groupProps: { label: t("Wirepass Sink Node Address") },
  },
  {
    name: "wirepassChannel",
    type: FormItemType.TextField,
    groupProps: { label: t("Wirepass Channel") },
  },
  {
    name: "ethernetMacAddress",
    type: FormItemType.TextField,
    groupProps: { label: t("Ethernet Mac Address") },
  },
  {
    name: "firmware",
    type: FormItemType.TextField,
    groupProps: { label: t("Firmware") },
  },
];

type AddDialogProps = IDialogProps & {
  serialNumbers: string[];
};

export const AddDialog = ({ serialNumbers, ...rest }: AddDialogProps) => {
  const { t } = useTranslation();
  const { support, updateSupportEle } = useContext(SupportContext);

  const toUpdate = support.gatewaysSupportToAdd.find(
    (gateway) => gateway.matchUniqueId === support.matchUniqueId
  );

  const schema = useMemo(
    () =>
      getSchema(
        t,
        serialNumbers.filter((item) => item !== toUpdate.serialNumber)
      ),

    [t, serialNumbers, toUpdate.serialNumber]
  );

  const {
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: {
      ...toUpdate,
      ltePlanSize: toUpdate?.ltePlanSize ? String(toUpdate?.ltePlanSize) : "",
      rsshPort: toUpdate?.rsshPort ? String(toUpdate?.rsshPort) : "",
    },
  });

  // Checks whether the entity has changed.
  useEffect(() => {
    if (!control) {
      return;
    }

    const toUp: RequestWirelessGatewayAddAux = {
      ...(control._formValues as RequestWirelessGatewayAddAux),
      matchUniqueId: toUpdate?.matchUniqueId,
      projectId: toUpdate?.projectId,
      ltePlanSize: control._formValues.ltePlanSize
        ? Number(control._formValues.ltePlanSize)
        : undefined,
      rsshPort: control._formValues.rsshPort
        ? Number(control._formValues.rsshPort)
        : undefined,
      isValid,
    };
    isValid && updateSupportEle(toUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  return (
    <Stack>
      {renderFormItems(getGatewayFields(t), {
        control,
        errors: errors as { [schemaProp: string]: FieldError },
      })}
    </Stack>
  );
};
