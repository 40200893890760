import { useTranslation } from "react-i18next";
import BaseCommandBar, {
  computeCommandBarItems,
} from "../../../common/CommandBar";
import {
  AlertLevelPlus,
  getColumnsAlertLevels,
  getCommandBarItems,
} from "../columnsHelper";
import { useAppSelector } from "../../../../Hooks";
import { selectMachineDetailsStatus } from "../reducer";
import { authContext } from "../../../LeftMenuAlt/LeftMenuAlt";
import { useContext } from "react";
import { alertLevelsExport } from "../../api";
import { convertCSVtoXLSX } from "../../../../schema/Utils";
import Table, { useTableFilters } from "../../../common/Table";
import { commandBarStyles } from "../../../../schema/Constants";
import { Status } from "../../../../schema/status";
import { useParams } from "react-router-dom";

export default function AlertLevelsContent({
  listAlertLevels,
  setSelected,
  setActionAlertLevel,
}) {
  const { t } = useTranslation();
  const { id } = useParams();

  const auth = useContext(authContext);
  const status = useAppSelector(selectMachineDetailsStatus);

  const alertLevelsFilter = useTableFilters<AlertLevelPlus>({
    keys: [
      "signalName",
      "alertHigh",
      "alertLow",
      "conditionGroup",
      "conditionManualEnabled",
      "conditionManualValue",
      "dangerHigh",
      "dangerLow",
      "direction",
      "faultyHigh",
      "faultyLow",
      "indicationHigh",
      "indicationLow",
      "notificationType",
    ],
  });

  return (
    <>
      <BaseCommandBar
        items={computeCommandBarItems(
          getCommandBarItems({
            t,
            title: t("Alert levels"),
            onChange(files) {
              setSelected({
                context: "alert Levels",
                file: files.at(0),
              });
            },
            xlsxToo: true,
            addButton: auth.metaDataContributor,
            importButton: auth.metaDataContributor,
            onAdd() {
              setActionAlertLevel({
                data: undefined,
                context: "add",
              });
            },
            exportable: auth.metaDataReader,
            onExport() {
              alertLevelsExport(id).then((resp) => {
                convertCSVtoXLSX(resp, "AlertLevels_" + id + ".xlsx");
              });
            },
          })
        )}
        onSearch={alertLevelsFilter.handleSearch}
        styles={commandBarStyles}
      />
      <Table
        persistOpts={{
          key: "table-alertLevels",
          version: 2,
        }}
        header={{
          title: t("Alert Levels"),
          isExportable: false,
        }}
        items={listAlertLevels}
        columns={getColumnsAlertLevels({
          t,
          hasActions: true,
          onEdit(alertLevel) {
            setActionAlertLevel({
              data: alertLevel,
              context: "edit",
            });
          },
          onDelete(alertLevelId) {
            setActionAlertLevel({
              data: alertLevelId,
              context: "delete",
            });
          },
        })}
        hasSelection={false}
        isLoading={listAlertLevels === undefined}
        hidePerPage
        filters={alertLevelsFilter.filters}
        isError={status === Status.error}
      />
    </>
  );
}
