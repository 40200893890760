import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import OverviewIcon from "../../../../assets/svg/OverviewIcon";
import MapIcon from "../../../../assets/svg/MapIcon";

export type Mode = "overview" | "map";

export const modeIcons = {
  overview: OverviewIcon,
  map: MapIcon,
};

export const useModeSelect = createWithEqualityFn<{
  mode: Mode;
  getModeIcon: any;
  setDataMode: (mode: Mode) => void;
  getModeColor: () => string;
}>()(
  (set, get) => ({
    mode: "overview",
    getModeIcon: () => modeIcons[get().mode],
    setDataMode: (mode: Mode) =>
      set(() => ({
        mode,
      })),
    getModeColor: () => "#2c529f",
  }),
  shallow
);
