/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect } from "react";

import {
  useZodForm,
  FormItemType,
  FormItemProps,
  renderFormItems,
} from "../common/Form";
import BaseDialog from "../common/Dialog";

import {
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  SpinnerSize,
  Spinner,
  IDialogProps,
  DialogType,
  IComboBoxOption,
  IDropdownOption,
} from "@fluentui/react";
import { z } from "zod";
import { maxLengthType1 } from "../../schema/Constants";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Status } from "../../schema/status";
import { ProjectUpdate } from "./models";
import {
  listAsyncCompanies,
  selectCompaniesStatus,
} from "../Companies/reducer";
import { addProject, editProject } from "./api";
import type { FieldError } from "react-hook-form";
import countries from "../../schema/country-by-continent";
import FormItemRow from "../Generic/FormItemRow";
import ControlledComboBox from "../Generic/ControlledComboBox";
import { areObjectsEqual } from "../../schema/Utils";
import { useTranslation } from "react-i18next";

type AddOrEditDialogProps = IDialogProps & {
  options: IComboBoxOption[];
  data: ProjectUpdate | null;
  items: ProjectUpdate[];
  show: boolean;
  onSuccess: (
    hasError: boolean,
    data: ProjectUpdate,
    context: "add" | "edit"
  ) => void;
  onClose: () => void;
};

const getSchema = (t, projects: ProjectUpdate[]) =>
  z
    .object({
      id: z.string().optional(),
      name: z
        .string()
        .min(1, { message: t("This field is required") })
        .max(maxLengthType1, {
          message: t(
            `Name must contain at most {{maximumCharacterLength}} character(s)`,
            { maximumCharacterLength: maxLengthType1 }
          ),
        }),
      city: z
        .string()
        .max(maxLengthType1, {
          message: t(
            `City must contain at most {{maximumCharacterLength}} character(s)`,
            { maximumCharacterLength: maxLengthType1 }
          ),
        })
        .optional(),
      country: z
        .string()
        .max(maxLengthType1, {
          message: t(
            `Country must contain at most {{maximumCharacterLength}} character(s)`,
            { maximumCharacterLength: maxLengthType1 }
          ),
        })
        .optional(),
      continent: z
        .string()
        .max(maxLengthType1, {
          message: t(
            `Continent must contain at most {{maximumCharacterLength}} character(s)`,
            { maximumCharacterLength: maxLengthType1 }
          ),
        })
        .optional(),
      companyId: z.string().optional(),
      latitude: z
        .union([
          z
            .string()
            .length(0, { message: t("Try a number between (-90.0:90.0)") }),
          z.string().regex(/^[-]?\d+[.]?\d*$/),
        ])
        .optional()
        .transform((e) => (e === "" ? "" : e)),
      longitude: z
        .union([
          z
            .string()
            .length(0, { message: t("Try a number between (-180.0:180.0)") }),
          z.string().regex(/^[-]?\d+[.]?\d*$/),
        ])
        .optional()
        .transform((e) => (e === "" ? "" : e)),
    })
    .refine(
      (input) => {
        if (!input.name) {
          return true;
        }

        return (
          projects
            .map(({ name }) => name.trim().toLowerCase())
            .findIndex((value) => value === input.name.trim().toLowerCase()) ===
          -1
        );
      },
      {
        path: ["name"],
        message: t("The project already exists"),
      }
    )
    .refine(
      (input) => {
        if (!input.latitude) {
          return true;
        }

        return (
          Number(input.latitude) >= -90.0 && Number(input.latitude) <= 90.0
        );
      },
      {
        path: ["latitude"],
        message: t("Try between (-90.0:90.0)"),
      }
    )
    .refine(
      (input) => {
        if (!input.longitude) {
          return true;
        }

        return (
          Number(input.longitude) >= -180.0 && Number(input.longitude) <= 180.0
        );
      },
      {
        path: ["longitude"],
        message: t("Try between (-180.0:180.0)"),
      }
    );

export function getContinentOptions(
  countries: { country: string; continent: string }[]
): IDropdownOption<any>[] {
  const depArray = countries.map((count) => count.continent);

  var continents = depArray.filter(function (elem, pos) {
    return depArray.indexOf(elem) === pos;
  });

  const options: IDropdownOption[] = continents.map((cont) => {
    return { key: cont, text: cont };
  });

  return options;
}

export function getCountriesOptions(continent: string): IDropdownOption<any>[] {
  const options: IDropdownOption[] = countries
    .filter((cont) => cont.continent === continent)
    .map((ele) => {
      return { key: ele.country, text: ele.country };
    });

  return options;
}

export const AddOrEditDialog = ({
  options,
  data,
  items,
  show,
  onSuccess,
  onClose,
  ...rest
}: AddOrEditDialogProps) => {
  const { t } = useTranslation();
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(
    data === null || data === undefined
  );
  const filteredItems = useMemo(
    () =>
      // we need to exclude selected data from items when editing
      items.filter(({ name }) =>
        data
          ? name.trim().toLowerCase() !== data.name.trim().toLowerCase()
          : true
      ),
    [items, data]
  );

  const schema = useMemo(() => getSchema(t, filteredItems), [t, filteredItems]);
  const [isLoading, setLoading] = useState(false);
  const statusParent = useAppSelector(selectCompaniesStatus);
  const dispatch = useAppDispatch();
  const [companyId, setCompanyId] = useState<string>(
    data ? data.companyId : undefined
  );
  const [selectedContinent, setSelectedContinent] = useState<IDropdownOption>({
    key: data?.continent || "",
    text: data?.continent || "",
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = useZodForm({
    mode: "onChange",
    schema,
    defaultValues: {
      companyId: data?.companyId || "",
      id: data?.id || "",
      name: data?.name || "",
      continent: data?.continent || "",
      country: data?.country || "",
      city: data?.city || "",
      longitude: data?.longitude?.toString() || "0",
      latitude: data?.latitude?.toString() || "0",
    },
  });

  useEffect(() => {
    if (statusParent === Status.void) dispatch(listAsyncCompanies());
  }, [dispatch, statusParent]);

  // Checks whether the entity has changed.
  useEffect(() => {
    if (!control) {
      return;
    }

    let areEqual =
      areObjectsEqual(control._defaultValues, control._formValues) &&
      control._defaultValues.continent === (selectedContinent?.text || "") &&
      data?.companyId === companyId;
    setDataHasChanged(!areEqual);
  }, [watch(), companyId]);

  const onSubmit = handleSubmit(async (formData: any) => {
    setLoading(true);
    const toSend: ProjectUpdate = {
      companyId: companyId,
      id: data && data.id,
      name: formData.name,
      continent: selectedContinent?.text,
      country: formData.country,
      city: formData.city,
      latitude: Number(formData.latitude) as number,
      longitude: Number(formData.longitude) as number,
    };

    const mutation = data ? editProject : addProject;
    await mutation(toSend).then((response) =>
      onSuccess(
        "status" in response,
        formData as ProjectUpdate,
        data ? "edit" : "add"
      )
    );

    handleClose();
  });

  const handleClose = () => {
    setLoading(false);

    onClose?.();
  };

  const projectsFields: FormItemProps[] = [
    {
      name: "name",
      type: FormItemType.TextField,
      groupProps: { label: t("Name *") },
    },
    {
      name: "continent",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Continent") },
      options: getContinentOptions(countries),
      selectedKey: selectedContinent?.key || "",
      onChange(_, option) {
        setSelectedContinent(option);
      },
    },
    {
      name: "country",
      type: FormItemType.Dropdown,
      groupProps: { label: t("Country") },
      options: getCountriesOptions(selectedContinent?.key as string),
    },
    {
      name: "city",
      type: FormItemType.TextField,
      groupProps: { label: t("City") },
    },
    {
      name: "latitude",
      type: FormItemType.TextField,
      groupProps: { label: t("Latitude") },
    },
    {
      name: "longitude",
      type: FormItemType.TextField,
      groupProps: { label: t("Longitude") },
    },
  ];

  return (
    <BaseDialog
      {...rest}
      hidden={!show}
      dialogContentProps={{
        type: DialogType.normal,
        title: data ? t("Edit new project") : t("Add new project"),
        closeButtonAriaLabel: t("Close"),
        onDismiss: handleClose,
      }}
    >
      <form onSubmit={onSubmit}>
        <FormItemRow label={t("Company *")}>
          <ControlledComboBox
            options={options}
            selectedKey={companyId}
            disabled={false}
            onKeySelected={(key: string) => setCompanyId(key)}
          />
        </FormItemRow>
        {renderFormItems(projectsFields, {
          control,
          errors: errors as { [schemaProp: string]: FieldError },
        })}
        <DialogFooter>
          <PrimaryButton
            type="submit"
            text={t("Save Changes")}
            disabled={isLoading || !isValid || !companyId || !dataHasChanged}
            onRenderIcon={() =>
              isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
            }
          />
          <DefaultButton
            styles={{
              root: { border: "unset", background: "transparent" },
            }}
            text={t("Cancel")}
            onClick={handleClose}
          />
        </DialogFooter>
      </form>
    </BaseDialog>
  );
};
