// Original path: src/src/Components/MachineCV/MachineCVDriveLoadPage/components/TorqueChart/Chart.tsx
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as am5 from "@amcharts/amcharts5";
import { Spinner, SpinnerSize } from "@fluentui/react";
import classNames from "classnames";
import { debounce, isEmpty, isEqual } from "lodash-es";
import React, { useEffect, useMemo, useRef } from "react";

import { DEFAULT_DEBOUNCE } from "../../../../../config/constants";
import { createRange } from "../../../../../modules/analysis-raw-data/components/TrendChart/TrendChart";
import type { EntityIds } from "../../../../../modules/analysis-trend-view/components/TrendViewGroupedPlot/config";
import { createSeriesOneYaxis, initializeTorqueChart } from "./methods";
import { useTorqueChartZoom } from "./useTorqueChartZoom";
import type { ResponseSimplifiedSignal } from "../../../../../types";
import type { XYChartRef } from "../../../../common/XYChart";
import { getDateAxis, XYChart } from "../../../../common/XYChart";
import { useTranslation } from "react-i18next";

type TorquePlotProps = {
  data: any[];
  signals: ResponseSimplifiedSignal[];
  machine: any;
  isZoomLoading: boolean;
  zoomLoadingStart: () => void;
  zoomLoadingEnd: () => void;
  selectedTableEvent?: any;
  onDataValidated?: () => void;
  isMaximized?: boolean;
};

const TorquePlot = React.forwardRef<XYChartRef, TorquePlotProps>(
  (
    {
      data,
      signals,
      machine,
      zoomLoadingStart,
      zoomLoadingEnd,
      isZoomLoading,
      selectedTableEvent,
      onDataValidated,
      isMaximized,
    },
    ref: any
  ) => {
    const { t } = useTranslation();
    const legendRef = useRef<any>(null);
    const dataRef = useRef<any[] | null>(data);
    const { handleZoomChange }: any = useTorqueChartZoom();
    const entityIds = useMemo<EntityIds>(
      () => ({
        xAxis: `x-axis-trend-view-grouped-plot-${machine.id}`,
        legend: `legend-trend-view-grouped-plot-${machine.id}`,
        outOfSync: `out-of-sync-trend-view-grouped-plot-${machine.id}`,
        noAverageTrend: `no-average-trend-view-grouped-plot-${machine.id}`,
      }),
      []
    );

    useEffect(() => {
      if (!ref?.current) return;

      dataRef.current = [...data];

      initializeTorqueChart({
        ref,
        entityIds,
        handleStartEndChanged,
        data,
        signals,
        legendRef,
        onDataValidated,
      });

      return () => {
        handleStartEndChanged.cancel();
        ref.current = null;
      };
    }, []);

    useEffect(() => {
      if (!ref?.current) return;

      if (
        ref.current.chart.series.length === signals.length &&
        isEqual(dataRef.current, data)
      ) {
        return;
      }

      dataRef.current = [...data];

      // Create current series
      createSeriesOneYaxis({
        ref,
        xAxis: getDateAxis(entityIds.xAxis),
        data,
        signals,
      });

      // Update legend
      legendRef?.current?.data.setAll(ref.current.chart.series.values);
    }, [signals.length, data]);

    useEffect(() => {
      if (!ref?.current) return;
      const xAxis = getDateAxis(entityIds.xAxis);
      if (!isEmpty(selectedTableEvent)) {
        createRange({
          value: new Date(selectedTableEvent.timeStamp).getTime(),
          color: am5.color(0x000000),
          root: ref.current.root,
          chart: ref.current.chart,
          xAxis,
        });
      } else {
        if (xAxis.axisRanges.values.length > 0) {
          xAxis.axisRanges.removeIndex(0);
        }
      }
    }, [selectedTableEvent]);

    // Translate legend
    useEffect(() => {
      if (!legendRef.current && signals.length === 0) return;

      legendRef?.current?.data?.values.forEach((item, index) => {
        const translatedName = t(
          signals[index].signalDescription ||
            signals[index].name ||
            "No signal name"
        );

        item.set("name", translatedName);
      });
    }, [signals, t]);

    // Handlers
    const onStartEndChanged = (
      _: unknown,
      target: am5.Scrollbar | undefined
    ) => {
      if (!ref.current || !target || data.length === 0) return;

      const start = target.get("start") ?? 0;
      const end = target.get("end") ?? 1;

      const xAxis = getDateAxis(entityIds.xAxis);

      const times: any = {
        startIndex: new Date(xAxis.positionToDate(start)).getTime(),
        endIndex: new Date(xAxis.positionToDate(end)).getTime(),
      };

      handleZoomChange({
        currentRange: times,
        signals,
        onStart: zoomLoadingStart,
        onEnd: zoomLoadingEnd,
      });
    };

    const handleStartEndChanged = debounce(onStartEndChanged, DEFAULT_DEBOUNCE);
    return (
      <div
        className={
          isMaximized ? "torque-am-chart-maximized" : "torque-am-chart"
        }
      >
        {isZoomLoading && (
          <Spinner
            className="loading-chart-data"
            size={SpinnerSize.large}
            label={t("Loading data...")}
          />
        )}
        <div
          className={classNames("grouped-chart-container", {
            loading: isZoomLoading,
          })}
        >
          <XYChart
            ref={ref}
            exportSelector=""
            styles={{
              root: { flexGrow: 1 },
              chart: { height: "100%" },
            }}
          />
        </div>
      </div>
    );
  }
);

TorquePlot.displayName = "TorquePlot";

export default TorquePlot;
