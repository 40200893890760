import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";
import { listPermissions } from "./api";
import {
  EntityItemWithAccountRoles,
  EntityItemWithAccountRolesToMapInTable,
} from "./models";
import { mapPermissionsToTable } from "../../schema/Utils";
import { t } from "i18next";

export const listAsyncPermi = createAsyncThunk("permissions/list", async () => {
  const response = await listPermissions();
  return response.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
});

export interface PermissionsState {
  permissions: EntityItemWithAccountRoles[];
  permissionsToTable: EntityItemWithAccountRolesToMapInTable[];
  status: Status;
  error: string;
}

const initialState: PermissionsState = {
  permissions: [],
  permissionsToTable: [],
  status: Status.void,
  error: "",
};

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncPermi.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncPermi.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.permissions = action.payload;
        state.permissionsToTable = action.payload.map((ele) =>
          mapPermissionsToTable(ele)
        );
      })
      .addCase(listAsyncPermi.rejected, (state) => {
        state.status = Status.error;
        state.error = t("Error loading permissions. Please try again later.");
      });
  },
});

export const selectPermissions = (state: RootState) =>
  state.permissions.permissions;

export const selectPermissionsTable = (state: RootState) =>
  state.permissions.permissionsToTable;

export const selectPermissionsStatus = (state: RootState) =>
  state.permissions.status;

export default permissionsSlice.reducer;
