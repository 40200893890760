import { PrimaryButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";

type UploaderButtonProps = {
  onClick: () => void;
};

const UploaderButton = ({ onClick }: UploaderButtonProps) => {
  const { t } = useTranslation();

  return (
    <PrimaryButton
      style={{
        marginTop: 25,
      }}
      text={t("Add document")}
      onClick={onClick}
    />
  );
};

export default UploaderButton;
