import React from "react";

import ArrowLeftIcon from "../../../assets/svg/ArrowLeftIcon";
import { useTranslation } from "react-i18next";

const WirelessSensorsPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <div className="placeholder" style={{ height: "100%", background: "#FFF" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ArrowLeftIcon style={{ marginRight: 15 }} />
        <div>{t("Please select a Sensor")}</div>
      </div>
    </div>
  );
};

export default WirelessSensorsPlaceholder;
