import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";
import { listRoles } from "./api";
import { IRole } from "./models";
import { t } from "i18next";

export const listAsyncRoles = createAsyncThunk("roles/list", async () => {
  const response = await listRoles();
  if (response.length > 0) return response;
  return response;
});

interface toSortbyKey {
  sort: boolean;
  key: string;
}

interface toFindByKey {
  input: string;
  key: string;
}

export interface RolesState {
  roles: IRole[];
  rolesIni: IRole[];
  rolesSort: IRole[];
  status: Status;
  error: string;
}

const initialState: RolesState = {
  roles: [],
  rolesIni: [],
  rolesSort: [],
  status: Status.void,
  error: "",
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    sortValues: (state: RolesState, action: PayloadAction<toSortbyKey>) => {
      const key = action.payload.key as keyof IRole;
      state.roles !== undefined
        ? (state.roles = state.roles
            .slice(0)
            .sort((a: IRole, b: IRole) =>
              (
                action.payload.sort
                  ? a[key].toString().toLowerCase() <
                    b[key].toString().toLowerCase()
                  : a[key].toString().toLowerCase() >
                    b[key].toString().toLowerCase()
              )
                ? 1
                : -1
            ))
        : (state.roles = []);
    },
    findValues: (state: RolesState, action: PayloadAction<toFindByKey>) => {
      const key = action.payload.key as keyof IRole;
      if (state.rolesIni.length > 0) {
        state.roles = state.rolesIni.filter(
          (i) =>
            i[key]
              .toString()
              .toLowerCase()
              .indexOf(action.payload.input.toLowerCase()) > -1
        );
        state.rolesSort = [...state.roles];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncRoles.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncRoles.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.error = "";
        state.roles = action.payload;
        state.rolesIni = action.payload;
        state.rolesSort = action.payload;
      })
      .addCase(listAsyncRoles.rejected, (state) => {
        state.status = Status.error;
        state.error = t("Error loading roles. Please try again later.");
      });
  },
});

export const selectRoles = (state: RootState) => state.roles.roles;

export const selectRolesStatus = (state: RootState) => state.roles.status;

export const selectRole = (id: string) => (state: RootState) => {
  if (state.roles && state.roles.roles && state.roles.roles.length > 0) {
    return state.roles.roles.find((c: IRole) => c.id === id);
  }

  return undefined;
};

export const { sortValues, findValues } = rolesSlice.actions;
export default rolesSlice.reducer;
