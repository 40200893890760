import {
  Stack,
  PrimaryButton,
  StackItem,
  Persona,
  PersonaSize,
  DialogFooter,
} from "@fluentui/react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { defaultImageProfileBase64 } from "../../schema/Constants";
import { MyAccountInfo, UserPicture } from "./models";
import { pictureGetUsersRBAC, pictureUpdateUsersRBAC } from "./api";
import { notification } from "../common/Notification";
import {
  extract4Initials,
  getObjectUrlFromBase64,
  mapInitialsToNumber,
} from "../../schema/Utils";
import { useTranslation } from "react-i18next";

interface IPropsAdd {
  displayName: string;
  memberId?: string;
  pictureBase64?: string;
  setShowModal?: Dispatch<SetStateAction<boolean>>;
  imageBase64?: string;
  setImageBase64?: Dispatch<SetStateAction<string>>;
  setMyAccount?: Dispatch<React.SetStateAction<MyAccountInfo>>;
}

export const UpdateUserPhoto: React.FunctionComponent<IPropsAdd> = (
  props: IPropsAdd
) => {
  const { t } = useTranslation();
  const [keyInput, setKeyInput] = useState<number>(0);
  const [imageBase64, setImageBase64] = useState<string>();

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const [currentImageBase64, setCurrentImageBase64] = useState<string>(
    props.pictureBase64
  );

  useEffect(() => {
    if (props.memberId)
      pictureGetUsersRBAC(props.memberId).then(
        (pic) =>
          "pictureBase64" in pic &&
          setImageBase64(pic.pictureBase64 ? pic.pictureBase64 : undefined)
      );
  }, [props.memberId]);

  const handleClickConfirm = async () => {
    if (props.memberId) {
      const aux: UserPicture = {
        id: props.memberId,
        pictureBase64: imageBase64,
      };
      pictureUpdateUsersRBAC(props.memberId, aux).then((pic) =>
        "pictureBase64" in pic && pic.pictureBase64
          ? notification.success(t("Picture updated"))
          : notification.warning(t("Something went wrong"))
      );
      if (props.memberId) setImageBase64(undefined);
      pictureGetUsersRBAC(props.memberId).then((pic) =>
        "pictureBase64" in pic && pic.pictureBase64
          ? setCurrentImageBase64(pic.pictureBase64)
          : notification.warning(t("The updated picture is null"))
      );
    } else {
      props.setImageBase64(imageBase64);
      props.setShowModal(false);
    }

    setKeyInput((prev) => prev + 1);
  };

  const handleOnChange = (e: any) => {
    const [file] = e.target.files;

    if (file) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target?.result;
        setImageBase64(btoa(bstr as string));
      };
      reader.readAsBinaryString(file);
    }
  };

  return (
    <>
      <input
        key={keyInput}
        type={"file"}
        id={"profilePhoto"}
        accept={".jpg, .jpeg, .png"}
        onChange={handleOnChange}
        ref={hiddenFileInput}
        style={{ display: "none" }}
      />
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        tokens={{ childrenGap: 8 }}
      >
        <Stack
          style={{ maxWidth: "70vw" }}
          wrap
          horizontal
          tokens={{ childrenGap: 8 }}
          horizontalAlign="center"
          verticalAlign="center"
        >
          <StackItem>
            <Persona
              imageInitials={extract4Initials(props.displayName)}
              initialsColor={mapInitialsToNumber(
                extract4Initials(props.displayName)
              )}
              imageUrl={
                currentImageBase64
                  ? getObjectUrlFromBase64(defaultImageProfileBase64)
                  : undefined
              }
              size={PersonaSize.size120}
              styles={{ root: { padding: "20px" } }}
            />
          </StackItem>
          {imageBase64 && (
            <StackItem>
              <Persona
                imageInitials={extract4Initials(props.displayName)}
                initialsColor={mapInitialsToNumber(
                  extract4Initials(props.displayName)
                )}
                imageUrl={getObjectUrlFromBase64(imageBase64)}
                size={PersonaSize.size120}
                styles={{ root: { padding: "20px" } }}
              />
            </StackItem>
          )}
        </Stack>
        <DialogFooter>
          <PrimaryButton
            text={imageBase64 ? t("Confirm") : t("Choose File")}
            onClick={imageBase64 ? handleClickConfirm : handleClick}
          />
        </DialogFooter>
      </Stack>
    </>
  );
};
