import { useRef, useState } from "react";
import {
  Checkbox,
  ChoiceGroup,
  IChoiceGroupOption,
  Panel,
  DefaultButton,
  Spinner,
  TextField,
  SpinButton,
} from "@fluentui/react";

import styles from "./OneShot.module.css";

import { askApi, Approaches, AskResponse, AskRequest } from "../Api";
import { Answer } from "../Components/Answer/Answer";
import { AnswerError } from "../Components/Answer/AnswerError";
import { QuestionInput } from "../Components/QuestionInput/QuestionInput";
import { AnalysisPanel } from "../Components/AnalysisPanel/AnalysisPanel";
import { AnalysisPanelTabs } from "../Components/AnalysisPanel/AnalysisPanelTabs";
import { SettingsButton } from "../Components/SettingsButton/SettingsButton";
import { useTranslation } from "react-i18next";

const OneShot = () => {
  const { t } = useTranslation();
  const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
  const [approach, setApproach] = useState<Approaches>(
    Approaches.RetrieveThenRead
  );
  const [promptTemplate, setPromptTemplate] = useState<string>("");
  const [promptTemplatePrefix, setPromptTemplatePrefix] = useState<string>("");
  const [promptTemplateSuffix, setPromptTemplateSuffix] = useState<string>("");
  const [retrieveCount, setRetrieveCount] = useState<number>(3);
  const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
  const [useSemanticCaptions, setUseSemanticCaptions] =
    useState<boolean>(false);
  const [excludeCategory, setExcludeCategory] = useState<string>("");

  const lastQuestionRef = useRef<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [answer, setAnswer] = useState<AskResponse>();

  const [activeCitation, setActiveCitation] = useState<string>();
  const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<
    AnalysisPanelTabs | undefined
  >(undefined);

  const makeApiRequest = async (question: string) => {
    lastQuestionRef.current = question;

    error && setError(undefined);
    setIsLoading(true);
    setActiveCitation(undefined);
    setActiveAnalysisPanelTab(undefined);

    try {
      const request: AskRequest = {
        question,
        approach,
        overrides: {
          promptTemplate:
            promptTemplate.length === 0 ? undefined : promptTemplate,
          promptTemplatePrefix:
            promptTemplatePrefix.length === 0
              ? undefined
              : promptTemplatePrefix,
          promptTemplateSuffix:
            promptTemplateSuffix.length === 0
              ? undefined
              : promptTemplateSuffix,
          excludeCategory:
            excludeCategory.length === 0 ? undefined : excludeCategory,
          top: retrieveCount,
          semanticRanker: useSemanticRanker,
          semanticCaptions: useSemanticCaptions,
        },
      };
      const result = await askApi(request);
      setAnswer(result);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onPromptTemplateChange = (
    _ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setPromptTemplate(newValue || "");
  };

  const onPromptTemplatePrefixChange = (
    _ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setPromptTemplatePrefix(newValue || "");
  };

  const onPromptTemplateSuffixChange = (
    _ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setPromptTemplateSuffix(newValue || "");
  };

  const onRetrieveCountChange = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setRetrieveCount(parseInt(newValue || "3"));
  };

  const onApproachChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    setApproach((option?.key as Approaches) || Approaches.RetrieveThenRead);
  };

  const onUseSemanticRankerChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSemanticRanker(!!checked);
  };

  const onUseSemanticCaptionsChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSemanticCaptions(!!checked);
  };

  const onExcludeCategoryChanged = (
    _ev?: React.FormEvent,
    newValue?: string
  ) => {
    setExcludeCategory(newValue || "");
  };

  const onExampleClicked = (example: string) => {
    makeApiRequest(example);
  };

  const onShowCitation = (citation: string) => {
    if (
      activeCitation === citation &&
      activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab
    ) {
      setActiveAnalysisPanelTab(undefined);
    } else {
      setActiveCitation(citation);
      setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
    }
  };

  const onToggleTab = (tab: AnalysisPanelTabs) => {
    if (activeAnalysisPanelTab === tab) {
      setActiveAnalysisPanelTab(undefined);
    } else {
      setActiveAnalysisPanelTab(tab);
    }
  };

  const approaches: IChoiceGroupOption[] = [
    {
      key: Approaches.RetrieveThenRead,
      text: "Retrieve-Then-Read",
    },
    {
      key: Approaches.ReadRetrieveRead,
      text: "Read-Retrieve-Read",
    },
    {
      key: Approaches.ReadDecomposeAsk,
      text: "Read-Decompose-Ask",
    },
  ];

  return (
    <div className={styles.oneshotContainer}>
      <div className={styles.oneshotTopSection}>
        {/* <SettingsButton className={styles.settingsButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} /> */}
        <h1 className={styles.oneshotTitle}>Ask D-GPT</h1>
        <div className={styles.oneshotQuestionInput}>
          <QuestionInput
            placeholder={t(
              "Example: Is there a way to measure stable operation?"
            )}
            disabled={isLoading}
            onSend={(question) => makeApiRequest(question)}
          />
        </div>
      </div>
      <div className={styles.oneshotBottomSection}>
        {isLoading && <Spinner label={t("Generating answer")} />}
        {!lastQuestionRef.current}
        {!isLoading && answer && !error && (
          <div className={styles.oneshotAnswerContainer}>
            <Answer
              answer={answer}
              onCitationClicked={(x) => onShowCitation(x)}
              onThoughtProcessClicked={() =>
                onToggleTab(AnalysisPanelTabs.ThoughtProcessTab)
              }
              onSupportingContentClicked={() =>
                onToggleTab(AnalysisPanelTabs.SupportingContentTab)
              }
            />
          </div>
        )}
        {error ? (
          <div className={styles.oneshotAnswerContainer}>
            <AnswerError
              error={error.toString()}
              onRetry={() => makeApiRequest(lastQuestionRef.current)}
            />
          </div>
        ) : null}
        {activeAnalysisPanelTab && answer && (
          <AnalysisPanel
            className={styles.oneshotAnalysisPanel}
            activeCitation={activeCitation}
            onActiveTabChanged={(x) => onToggleTab(x)}
            citationHeight="600px"
            answer={answer}
            activeTab={activeAnalysisPanelTab}
          />
        )}
      </div>

      {/* <Panel
                headerText="Configure answer generation"
                isOpen={isConfigPanelOpen}
                isBlocking={false}
                onDismiss={() => setIsConfigPanelOpen(false)}
                closeButtonAriaLabel="Close"
                onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                isFooterAtBottom={true}
            >
                <ChoiceGroup
                    className={styles.oneshotSettingsSeparator}
                    label="Approach"
                    options={approaches}
                    defaultSelectedKey={approach}
                    onChange={onApproachChange}
                />

                {(approach === Approaches.RetrieveThenRead || approach === Approaches.ReadDecomposeAsk) && (
                    <TextField
                        className={styles.oneshotSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />
                )}

                {approach === Approaches.ReadRetrieveRead && (
                    <>
                        <TextField
                            className={styles.oneshotSettingsSeparator}
                            defaultValue={promptTemplatePrefix}
                            label="Override prompt prefix template"
                            multiline
                            autoAdjustHeight
                            onChange={onPromptTemplatePrefixChange}
                        />
                        <TextField
                            className={styles.oneshotSettingsSeparator}
                            defaultValue={promptTemplateSuffix}
                            label="Override prompt suffix template"
                            multiline
                            autoAdjustHeight
                            onChange={onPromptTemplateSuffixChange}
                        />
                    </>
                )}

                <SpinButton
                    className={styles.oneshotSettingsSeparator}
                    label="Retrieve this many documents from search:"
                    min={1}
                    max={50}
                    defaultValue={retrieveCount.toString()}
                    onChange={onRetrieveCountChange}
                />
                <TextField className={styles.oneshotSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                <Checkbox
                    className={styles.oneshotSettingsSeparator}
                    checked={useSemanticRanker}
                    label="Use semantic ranker for retrieval"
                    onChange={onUseSemanticRankerChange}
                />
                <Checkbox
                    className={styles.oneshotSettingsSeparator}
                    checked={useSemanticCaptions}
                    label="Use query-contextual summaries instead of whole documents"
                    onChange={onUseSemanticCaptionsChange}
                    disabled={!useSemanticRanker}
                />
            </Panel> */}
    </div>
  );
};

export default OneShot;
