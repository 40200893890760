import { IconButton, Stack, Text } from "@fluentui/react";
import classnames from "classnames";
import type { CSSProperties, ReactElement } from "react";
import { useEffect, useMemo, useState } from "react";
import * as ReactRouterDOM from "react-router-dom";

import CommentsIcon from "../../../../assets/svg/CommentsIcon";
import MachineIcon from "../../../../assets/svg/MachineIcon";
import TasksIcon from "../../../../assets/svg/TasksIcon";
import type {
  Company,
  Corporation,
  MachineCvOverviewProject,
} from "../../../../types";

import useOverviewStore from "../hooks/useOverviewStore";
import { getProjectElementId } from "../selectors";
import CompactLayout from "./CompactLayout";
import DetailedLayout from "./DetailedLayout";
import { useTranslation } from "react-i18next";

type TogglerProps = {
  isOpen: boolean;
  toggle: () => void;
};

const Toggler = ({ isOpen, toggle }: TogglerProps) => (
  <IconButton
    className="toggle-overview-button"
    iconProps={{
      iconName: isOpen ? "ChevronUp" : "ChevronDown",
      styles: {
        root: {
          color: "#fff",
        },
      },
    }}
    styles={{
      root: {
        selectors: {
          ":hover": { background: "#0078D4 !important" },
        },
      },
    }}
    onClick={toggle}
  />
);

type MachinesOverviewProps = {
  corporation: Corporation;
  company: Company;
  project: MachineCvOverviewProject;
};

interface HeaderProps {
  project: {
    machinesCount: number;
    activeTasksCount: number;
    commentsCount: number;
  };
}

const headerIconStyles = {
  minWidth: 16,
  minHeight: 16,
};

const textEllipsis: CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "80%",
  color: "#fff",
};

const Header = ({ project }: HeaderProps) => {
  const { t } = useTranslation();

  const items = useMemo(
    () => [
      {
        name: t("Machines"),
        value: project.machinesCount,
        icon: <MachineIcon style={headerIconStyles} />,
      },
      {
        name: t("Tasks"),
        value: project.activeTasksCount,
        icon: <TasksIcon style={headerIconStyles} />,
      },
      {
        name: t("Comments"),
        value: project.commentsCount,
        icon: <CommentsIcon style={headerIconStyles} />,
      },
    ],
    [project.activeTasksCount, project.commentsCount, project.machinesCount, t]
  );

  return (
    <Stack horizontal tokens={{ childrenGap: 40 }} className="header-items">
      {items.map(({ name, value, icon }) => (
        <Stack
          key={name}
          horizontal
          verticalAlign="center"
          tokens={{ childrenGap: 8 }}
        >
          {icon}
          <Text className="header-items__title">{name}</Text>
          <Text>{value}</Text>
        </Stack>
      ))}
    </Stack>
  );
};

const MachinesOverview = ({
  corporation,
  company,
  project,
}: MachinesOverviewProps) => {
  const layout = useOverviewStore((store) => store.layout);

  const { state: previousId }: { state: string | null } =
    ReactRouterDOM.useLocation();

  const id = useMemo(
    () =>
      getProjectElementId({
        corporation: corporation.name,
        company: company.name,
        project: project.metaData.name,
      }),
    [corporation, company, project]
  );

  const [isOpen, setOpen] = useState(previousId === id);

  const toggle = () => setOpen(!isOpen);

  useEffect(() => {
    if (previousId !== id) {
      return;
    }

    const timerId = setTimeout(() => {
      const element = document.getElementById(id);

      if (element === null) {
        return;
      }

      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 100);

    return () => {
      timerId && clearTimeout(timerId);
    };
  }, [id, previousId]);

  const selectedLayout =
    layout === "detailed" ? (
      <DetailedLayout project={project} />
    ) : (
      <CompactLayout project={project} />
    );

  const overview = isOpen && selectedLayout;

  return (
    <>
      <Stack
        horizontal
        id={id}
        verticalAlign="center"
        className={classnames({ header: true, "header--open": isOpen })}
        onClick={toggle}
      >
        <Text style={textEllipsis}>{project.metaData.name}</Text>
        <Header project={project} />
        <Stack horizontal horizontalAlign="end">
          <Toggler isOpen={isOpen} toggle={toggle} />
        </Stack>
      </Stack>
      {overview}
    </>
  );
};

export default MachinesOverview;
