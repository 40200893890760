import { memo, useMemo } from "react";

import { Marker as MapGlMarker } from "react-map-gl";
import { MapMarkerProps } from "../../../../../common/Map/MarkerMap";
import ClusterMarker from "./components/ClusterMarker";
import SoloMarker from "./components/SoloMarker";

function MapMarker({ cluster, supercluster }: MapMarkerProps) {
  const [longitude, latitude] = cluster.geometry.coordinates;

  const { cluster: isCluster, ...rest } = cluster.properties;

  const clusterPoints = useMemo(
    () =>
      isCluster
        ? supercluster
            ?.getLeaves(cluster?.id as number, Infinity)
            .map(({ properties }) => properties)
        : [],
    [cluster?.id, isCluster, supercluster]
  );

  return (
    <MapGlMarker
      key={cluster.properties.cluster_id}
      latitude={latitude}
      longitude={longitude}
    >
      {isCluster ? (
        <ClusterMarker
          clusterProps={cluster.properties}
          clusterPoints={clusterPoints}
        />
      ) : (
        <SoloMarker {...rest} />
      )}
    </MapGlMarker>
  );
}

export default memo(MapMarker);
