import { notification } from "../common/Notification";
import { axiosInstance } from "../..";
import {
  RequestSubmissionComment,
  Submission,
  SubmissionWithSId,
} from "./SubmissionDetails/models";
import { RequestApproveSubmissionAdd, SubmissionForList } from "./models";
import { t } from "i18next";

export async function submissionsList(): Promise<SubmissionForList[]> {
  try {
    const response = await axiosInstance.get<any>(
      "/meta/read/internal/v1/submissions"
    );
    return response.data;
  } catch (error) {
    notification.error(t("Error API: {{error}}", { error }));
    console.error("Error API:", error);
    throw new Error("Error on the attempt of submission list.");
  }
}

export async function submissionsListLog(): Promise<any | SubmissionForList[]> {
  try {
    const response = await axiosInstance.get<any>(
      "/meta/read/internal/v1/submissions/log"
    );
    return response.data;
  } catch (error) {
    notification.error("Error API:" + error);
    throw new Error("Error on the attempt of submission list. ");
  }
}

export async function submissionsDetails(
  id: string
): Promise<any | SubmissionWithSId[]> {
  const response = await axiosInstance.get<any>(
    "/meta/read/internal/v1/submissions/" + id
  );
  if (response.data) return response.data;
  else return response;
}

export async function sendSubmissionsComment(
  id: string,
  message: string
): Promise<any | Submission> {
  const mess: RequestSubmissionComment = {
    text: message,
  };
  const response = await axiosInstance.post<any>(
    "/meta/write/v1/submissions/" + id + "/messages",
    mess
  );
  return response;
}

export async function removeSubmissions(id: string): Promise<any | Submission> {
  const response = await axiosInstance.delete<any>(
    "/meta/write/v1/submissions/" + id
  );
  return response;
}

export async function approveSubmission(
  id: string,
  body: RequestApproveSubmissionAdd
): Promise<any | Submission> {
  const response = await axiosInstance.put<any>(
    "/meta/write/v1/submissions/" + id + "/approve",
    body
  );
  return response;
}

export async function rejectSubmission(id: string): Promise<any | Submission> {
  const response = await axiosInstance.put<any>(
    "/meta/write/v1/submissions/" + id + "/reject"
  );
  return response;
}
