import classNames from "classnames";
import { useCallback, useEffect, useMemo } from "react";
import { useMeasure } from "react-use";

import CompareLayout from "../../../../Components/Analysis/RawDataPage/components/CompareLayout";
import CompareLayoutTwoMachines from "../../../../Components/Analysis/RawDataPage/components/CompareLayoutTwoMachines";
import TrendViewLayout from "../../../../Components/Analysis/RawDataPage/components/TrendViewLayout";
import WaterfallLayout from "../../../../Components/Analysis/RawDataPage/components/WaterfallLayout";
import WaveformLayout from "../../../../Components/Analysis/RawDataPage/components/WaveformLayout";
import styles from "../../../../Components/Analysis/RawDataPage/RawDataPage.styles";

import useLayoutStore from "../../hooks/useLayoutStore";
import useLayoutTypes from "../../hooks/useLayoutTypes";
import useRawDataPointsStore from "../../hooks/useRawDataPointsStore";
import useSelectedDataPointsStore from "../../hooks/useSelectedDataPointsStore";
import useSelectedSignalsStore from "../../hooks/useSelectedSignalsStore";
import FrequencyCatalogueDialog from "../FrequencyCatalogueDialog";
import Placeholder from "../Placeholder";
import { useTranslation } from "react-i18next";

const PageContent = ({ marginTop }: { marginTop: number }) => {
  const [containerRef, { width }] = useMeasure<HTMLDivElement>();
  const {
    isTrendLayout,
    isCompareLayout,
    isWaveformLayout,
    isWaterfallLayout,
  } = useLayoutTypes();

  const { t } = useTranslation();

  const isFetchingRawDataPoints = useRawDataPointsStore(
    (store) => store.isFetchingRawDataPoints
  );
  const { selectedSignals, machineId_1, machineId_2 } = useSelectedSignalsStore(
    (store) => ({
      selectedSignals: store.selectedSignals,
      machineId_1: store.machineId_1,
      machineId_2: store.machineId_2,
    })
  );
  const setContainerWidth = useLayoutStore((store) => store.setContainerWidth);
  const setCurrentContainerWidth = useCallback(() => {
    setContainerWidth(width);
  }, [width, setContainerWidth]);

  const selectedDataPoints = useSelectedDataPointsStore(
    (store) => store.selectedDataPoints
  );

  const hasSelectedDataPoints = Object.values(selectedDataPoints).filter(
    (datapoint) => datapoint?.dataPoints.length > 0
  );

  const noSignalsSelected =
    selectedSignals.filter(({ dataType }) => dataType !== "Trend").length === 0;

  const showPlaceholder =
    noSignalsSelected ||
    (selectedSignals.length > 0 && hasSelectedDataPoints.length === 0);

  useEffect(() => {
    setCurrentContainerWidth();
  }, [setCurrentContainerWidth]);

  const renderSubLayoutSameMachine = useCallback(() => {
    if (isWaveformLayout) return <WaveformLayout />;
    if (isTrendLayout) return <TrendViewLayout />;
    if (isCompareLayout) return <CompareLayout />;
    if (isWaterfallLayout) return <WaterfallLayout />;
    return null;
  }, [isTrendLayout, isWaveformLayout, isCompareLayout, isWaterfallLayout]);

  const memoizedSingleMachineContent = useMemo(() => {
    if (machineId_1) {
      return renderSubLayoutSameMachine();
    }
    return null;
  }, [machineId_1, renderSubLayoutSameMachine]);

  const renderLayout = () => {
    if (machineId_2) {
      return (
        <>
          <FrequencyCatalogueDialog type="Second" />
          <CompareLayoutTwoMachines />
        </>
      );
    } else if (machineId_1) {
      return memoizedSingleMachineContent;
    }
    return null;
  };

  return (
    <div
      ref={containerRef}
      data-scroll-lock-lockable={true}
      className="container-lockable"
      style={{
        ...styles.refContainer,
        height: `calc(100vh - 62px - ${marginTop}px)`,
        overflowY:
          isCompareLayout && selectedSignals.length > 1 ? "unset" : "auto",
      }}
    >
      <FrequencyCatalogueDialog type="First" />
      {!noSignalsSelected && isFetchingRawDataPoints ? (
        <Placeholder icon="left" text={t("Loading data...")} isLoading={true} />
      ) : showPlaceholder ? (
        <Placeholder
          isLoading={false}
          icon={noSignalsSelected ? "left" : "right"}
          text={
            noSignalsSelected
              ? t("Please select a Raw Signal")
              : t("Please select a raw data point")
          }
        />
      ) : (
        <>
          <div
            style={styles.contentContainer}
            className={classNames({
              "is-waveform-layout": isWaveformLayout,
              "is-compare-layout": isCompareLayout,
              "is-trend-layout": isTrendLayout,
              "is-waterfall-layout": isWaterfallLayout,
            })}
          >
            {renderLayout()}
          </div>
        </>
      )}
    </div>
  );
};

export default PageContent;
