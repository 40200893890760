import { useMutation } from "@tanstack/react-query";

import { getApiClient } from "../../../modules/core/apiClient/useApiStore";

export type UseGatewayTriggeringParams = {
  gatewayId: string;
};

export const useGatewayTriggering = ({
  gatewayId,
}: UseGatewayTriggeringParams) => {
  const { mutateAsync } = useMutation((selectedKeys: string[]) =>
    getApiClient().post(
      `/dws/v1/triggers/on-demand/gateways/${gatewayId}`,
      selectedKeys
    )
  );

  return { triggerGatewayAsync: mutateAsync };
};
