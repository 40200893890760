import "./styles.scss";

import { Map } from "../../../../../common/Map";
import { Spin } from "../../../../../common/Spin";

import geoData from "../../../../../../geoData.json";

import uniqueId from "lodash-es/uniqueId";
import { useMachineCvOverviewList } from "../../../../../../Hooks";
import { useMemo } from "react";
import { Point } from "../../../../../common/Map/Map";
import {
  CONDITION_RANKING,
  conditionColor,
} from "../../../../../../modules/machine-cv/constants";
import MapMarker from "./MapMarker";

function getMachines(data) {
  return (data || []).flatMap(({ companies }) =>
    companies.flatMap(({ projects }) =>
      projects.flatMap(({ metaData, machines }) => {
        return machines.map((machine, index) => {
          const coordinatesAddFactor = index / 10000;

          return {
            continent: metaData.continent,
            country: metaData.country,
            coordinates: [
              metaData.longitude + coordinatesAddFactor,
              metaData.latitude + coordinatesAddFactor,
            ],
            machineId: machine.metaData.id,
            condition: machine.condition,
            projectName: metaData.name,
            projectId: metaData.id,
            title: machine.metaData.name,
          };
        });
      })
    )
  );
}

function getMapPoints(machines) {
  return (machines || []).map(
    ({ title, projectName, coordinates, condition, machineId }) => {
      const uniqId = uniqueId();

      return {
        type: "Feature",
        geometry: { type: "Point", coordinates },
        properties: {
          machineId,
          name: title,
          projectName,
          color: conditionColor[condition],
          cluster: false,
          type: "Project",
          id: uniqId,
          condition,
        },
      };
    }
  );
}

export default function HomepageMap() {
  const { data, isLoading, isError } = useMachineCvOverviewList();

  const machines = useMemo(() => getMachines(data), [data]);

  const mapPoints = getMapPoints(machines);

  const conditionsByCountry = machines.reduce((acc, { country, condition }) => {
    const place = country || "Other";
    if (!acc?.[place]) {
      acc[place] = {};
    }

    if (!acc?.[place]?.[condition]) {
      acc[place][condition] = 0;
    }

    acc[place][condition] = acc[place][condition] + 1;
    return acc;
  }, {});

  const mappedGeoData = Object.keys(conditionsByCountry)
    .map((key) => {
      const countryGeoData = geoData[key];

      if (!countryGeoData) return null;

      const conditions = conditionsByCountry[key];
      const rankedConditionColor = CONDITION_RANKING.find(
        (key) => conditions[key]
      );

      return {
        ...countryGeoData,
        properties: {
          ...countryGeoData.properties,
          name: key,
          color: conditionColor[rankedConditionColor],
        },
      };
    })
    .filter(Boolean);

  function onMapLoad({ target }) {
    target.addSource("geoData", {
      type: "geojson",
      data: { type: "FeatureCollection", features: mappedGeoData },
    });
    target.addLayer(
      {
        id: "colore-countries",
        type: "fill",
        source: "geoData",
        paint: {
          "fill-color": ["get", "color"],
        },
      },
      "landuse"
    );
  }

  if (isError) {
    return null;
  }

  if (isLoading) {
    return <Spin style={{ height: "50vh" }} />;
  }

  return (
    <Map
      points={mapPoints as Point[]}
      style={{ marginBottom: 80 }}
      onLoad={onMapLoad}
      Marker={MapMarker}
      mapStyle="mapbox://styles/dirkwoldt/cm67lwuia008801s27v2ccpuo"
    />
  );
}
