import type { IDropdownOption, IStackStyles, IStyle } from "@fluentui/react";
import {
  Dropdown,
  mergeStyles,
  mergeStyleSets,
  Stack,
  Toggle,
} from "@fluentui/react";
import classnames from "classnames";
import { isEmpty } from "lodash-es";
import type { CSSProperties } from "react";
import { useState } from "react";

import {
  useLocationSearch,
  useMachineCVBreadcrumb,
  useMaximize,
  useUniqueId,
} from "../../../Hooks";
import { DataPanel } from "../../../modules/common/components/DataPanel/DataPanel";
import { breadcrumbDefaultResponse } from "../../../modules/common/services/api/MachineCVBreadcrumb";
import { dropdownStyles } from "../../common/CommandBar/methods";

import { useSignalsList } from "../MachineCVSummaryPage/hooks/useSignalsList";
import ColoredChart from "./components/ColoredChart";
import EventsChart from "./components/Events/EventsChart";
import EventsTable from "./components/Events/EventsTable";
import EventsTabs from "./components/Events/EventsTabs";
import EventsHeader from "./components/Events/Header";
import LoadAndPerformance from "./components/LoadAndPerformance";
import MopCharts from "./components/MopCharts";
import useMopMaximizeStore from "./components/MopCharts/MopChartContainer/useMopMaximizeStore";
import TorqueChart from "./components/TorqueChart";
import { useMachineDriveLoad } from "./hooks/useMachineDriveLoad";
import { prepareColorChartSignals, prepareTorqueSignals } from "./methods";
import {
  DriveLoadEnriched,
  ResponseSimplifiedSignalWithConditionKey,
} from "../../../types";
import { useTranslation } from "react-i18next";

enum DriveLoadLayoutTypes {
  DriveLoad = "Torque & Drive Load",
  EventsAndMOP = "Torque Events and MOP",
}

const headerStyles: IStackStyles = {
  root: { marginBottom: "0.75em" },
};

const contentStyles: IStyle = {
  display: "flex",
  justifyContent: "stretch",
  alignItems: "stretch",
  columnGap: "1em",
  rowGap: "1em",
};

const containerStyle: CSSProperties = {
  padding: "1em",
};

const columnStyles = {
  root: {
    minWidth: "30em",
    flexGrow: 1,
  },
};

const dropdownLayoutStyles = mergeStyleSets(dropdownStyles, {
  dropdown: { fontSize: "18px" },
});

const driveLoadChartStyles = {
  root: { width: "100%", minWidth: "25em", minHeight: "35em", height: "50vh" },
};

const columnWidthsDriveLoad = {
  long: "calc(51% - 2em)",
  short: "calc(49% - 2em)",
};

const columnWidthsEventsAndMOP = {
  long: "calc(40% - 2em)",
  short: "calc(60% - 2em)",
};

const MachineCVDriveLoadPage = ({
  style,
  ...rest
}: React.HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation();
  const [layout, setLayout] =
    useState<keyof typeof DriveLoadLayoutTypes>("DriveLoad");
  const [{ id: machineId }, ,]: any = useLocationSearch();
  const { signals, isLoading: isLoadingSignals } = useSignalsList(
    machineId as string
  );
  const {
    data,
    isLoading: isLoadingMachineDriveLoad,
  }: { data: DriveLoadEnriched; isLoading: boolean; isError: boolean } =
    useMachineDriveLoad(machineId);
  const { data: breadcrumbResponse = breadcrumbDefaultResponse } =
    useMachineCVBreadcrumb({
      machineId: machineId as string,
      options: { enabled: !!machineId, retry: 0 },
    });
  const [showEvents, setShowEvents] = useState<boolean>(true);

  const uniqueIdTorque = useUniqueId();

  const {
    isChartMaximized: isTorqueChartMaximized,
    maximizeAction: maximizeActionTorque,
    maximizeIcon: maximizeTorqueIcon,
    maximizeLabel: maximizeTorqueLabel,
  } = useMaximize({ id: uniqueIdTorque, page: "torque-chart" });

  const { isMaximized: isMopChartMaximized } = useMopMaximizeStore();

  const onLayoutChange = (_: any, option?: IDropdownOption) => {
    if (option) {
      setLayout(option.key as keyof typeof DriveLoadLayoutTypes);
    }
  };

  const isLoading =
    isLoadingSignals || isLoadingMachineDriveLoad || !data.calculateDriveLoad;
  const noData =
    !isLoading &&
    (isEmpty(data) ||
      data.calculateDriveLoad === false ||
      signals.length === 0);

  return (
    <section {...rest} style={{ ...style, ...containerStyle }}>
      <Stack horizontal verticalAlign="center" styles={headerStyles}>
        <Dropdown
          className="layout-dropdown secondary-dropdown"
          options={Object.keys(DriveLoadLayoutTypes).map((key) => ({
            key: key,
            text: t(
              DriveLoadLayoutTypes[key as keyof typeof DriveLoadLayoutTypes]
            ),
          }))}
          selectedKey={layout}
          styles={dropdownLayoutStyles}
          role="heading"
          onChange={onLayoutChange}
        />
        {layout === "EventsAndMOP" && (
          <Toggle
            inlineLabel
            label={t("Show events")}
            checked={showEvents}
            styles={{ root: { marginBottom: 0, marginLeft: 10 } }}
            onChange={(_, checked) => setShowEvents(checked ?? false)}
          />
        )}
      </Stack>
      <div
        className={classnames("torque-chart", {
          "torque-chart--maximized":
            isTorqueChartMaximized || isMopChartMaximized,
        })}
      >
        <DataPanel
          className={mergeStyles(contentStyles, {
            flexFlow: `${layout === "DriveLoad" ? "row-reverse" : "row"} wrap`,
          })}
          isLoading={isLoading}
          noData={noData}
          noDataText={t("Drive load page is not available for this machine")}
        >
          <Stack
            tokens={{ childrenGap: 10 }}
            styles={mergeStyleSets(columnStyles, {
              root: {
                width:
                  layout === "DriveLoad"
                    ? columnWidthsDriveLoad.short
                    : columnWidthsEventsAndMOP.short,
              },
            })}
          >
            <div
              className={classnames({
                "chart-container": true,
                "chart-maximized": isTorqueChartMaximized,
              })}
            >
              <TorqueChart
                maximizeLabel={maximizeTorqueLabel}
                maximizeIcon={maximizeTorqueIcon}
                maximizeAction={maximizeActionTorque}
                signals={prepareTorqueSignals(
                  signals.filter(
                    (item: ResponseSimplifiedSignalWithConditionKey) =>
                      ["Trend", "FastTrend"].includes(item.dataType as string)
                  ),
                  data.signals
                )}
                machine={breadcrumbResponse.machine}
                styles={driveLoadChartStyles}
                isDriveLoadLayout={layout === "DriveLoad"}
              />
            </div>
            <div
              className={classnames({
                "chart-container": true,
                "chart-maximized": isMopChartMaximized,
              })}
            >
              {layout === "DriveLoad" && (
                <LoadAndPerformance
                  className={mergeStyles({
                    margin: "1em 0em 0.5em 1em",
                    width: "50%",
                    minWidth: "20em",
                  })}
                />
              )}
              {layout === "EventsAndMOP" && (
                <MopCharts
                  machineId={machineId}
                  signals={signals}
                  style={driveLoadChartStyles.root}
                />
              )}
            </div>
          </Stack>

          <Stack
            tokens={{ childrenGap: 10 }}
            styles={mergeStyleSets(columnStyles, {
              root: {
                width:
                  layout === "DriveLoad"
                    ? columnWidthsDriveLoad.long
                    : columnWidthsEventsAndMOP.long,
                display: layout === "DriveLoad" || showEvents ? "flex" : "none",
              },
            })}
          >
            {layout === "DriveLoad" && (
              <ColoredChart
                driveLoadSignalsMetaData={prepareColorChartSignals(
                  signals.filter(
                    (item: ResponseSimplifiedSignalWithConditionKey) =>
                      ["Trend", "FastTrend"].includes(item.dataType as string)
                  ),
                  data
                )}
                styles={mergeStyleSets(driveLoadChartStyles, {
                  root: { height: "calc(100% - 8px)" },
                })}
              />
            )}
            {layout === "EventsAndMOP" &&
              !isMopChartMaximized &&
              !isTorqueChartMaximized && (
                <EventsTable styles={driveLoadChartStyles} />
              )}
            {layout === "EventsAndMOP" && (
              <Stack horizontalAlign="stretch" tokens={{ childrenGap: 8 }}>
                <EventsHeader />
                <div
                  className="white-container"
                  style={driveLoadChartStyles.root}
                >
                  <EventsTabs />
                  <EventsChart style={{ height: "100%" }} />
                </div>
              </Stack>
            )}
          </Stack>
        </DataPanel>
      </div>
    </section>
  );
};

export default MachineCVDriveLoadPage;
