import {
  DefaultButton,
  DialogFooter,
  DialogType,
  IDialogProps,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";

import BaseDialog, { DialogSize } from "../common/Dialog";
import { useTranslation } from "react-i18next";
import { PropsWithChildren } from "react";

type ConfirmDialogProps = IDialogProps & {
  title: string;
  isLoading: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  message?: string;
};

export const ConfirmDialog = ({
  title,
  isLoading,
  onConfirm,
  onDismiss,
  children,
  ...rest
}: PropsWithChildren<ConfirmDialogProps>) => {
  const { t } = useTranslation();
  return (
    <BaseDialog
      {...rest}
      hidden={false}
      dialogContentProps={{
        title: title,
        type: DialogType.close,
        onDismiss: onDismiss,
      }}
      size={DialogSize.M}
    >
      {children}
      <DialogFooter>
        <PrimaryButton
          text={t("Yes")}
          onClick={onConfirm}
          disabled={isLoading}
          onRenderIcon={() =>
            isLoading ? <Spinner size={SpinnerSize.xSmall} /> : null
          }
        />
        <DefaultButton
          text={t("No")}
          onClick={onDismiss}
          disabled={isLoading}
        />
      </DialogFooter>
    </BaseDialog>
  );
};
