import type { IDatePickerProps, IDatePickerStrings } from "@fluentui/react";
import { DatePicker } from "@fluentui/react";
import { Controller } from "react-hook-form";

import type { BaseFormFieldProps } from "../types";
import { FormGroup } from "./FormGroup";
import { useTranslation } from "react-i18next";
import { getDayPickerStrings } from "./helpers";

export type FormDatePickerProps = BaseFormFieldProps &
  IDatePickerProps & {
    name: string;
  };

export const FormDatePicker = ({
  name,
  defaultValue,
  groupProps,
  hookProps: { control, errors },
  ...rest
}: FormDatePickerProps) => {
  const { t } = useTranslation();

  return (
    <FormGroup {...groupProps}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => (
          <DatePicker
            strings={getDayPickerStrings(t)}
            textField={{
              name,
              errorMessage: errors?.[name]?.message,
            }}
            styles={{ root: { width: "100%" } }}
            value={value}
            onSelectDate={(date) => {
              onChange(date);
            }}
            onBlur={onBlur}
            {...rest}
            label={undefined}
          />
        )}
      />
    </FormGroup>
  );
};
