import { axiosInstance } from "../..";

import { default as ProjectTelemetry } from "./pages/Project";
import { default as SensorTriggering } from "./pages/SensorTriggering";
import { default as SensorGateway } from "./pages/Gateway";
import { default as Sensor } from "./pages/Sensor";
import MachineTabs from "./pages/Machines";
import { default as WirelessSensorsPlaceholder } from "./components/Placeholder";
import { default as WirelessSensorsLayout } from "./components/Layout";
import { default as ErrorsAndIncidents } from "./components/Layout/ErrorsIncidents";

export const SensorConfiguratorPage = () => <ProjectTelemetry />;
export const SensorTriggeringPage = () => <SensorTriggering />;
export const SensorGatewayPage = () => <SensorGateway />;
export const SensorPage = () => <Sensor />;
export const SensorTriggersPage = () => <MachineTabs />;
export const WirelessSensorsLayoutPage = () => (
  <WirelessSensorsLayout axiosInstance={axiosInstance} marginTop={50} />
);
export const ErrorsAndIncidentsPage = () => <ErrorsAndIncidents />;
export const WirelessSensorsPlaceholderComp = () => (
  <WirelessSensorsPlaceholder />
);
