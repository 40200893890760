import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";
import { pendingListUsersRBAC } from "./api";
import { WithOutPermissionsUserExtendedProperties } from "../UsersRBCA/models";
import { t } from "i18next";

export const listAsyncUsersPendingRBAC = createAsyncThunk(
  "usersPendingRBAC/list",
  async () => {
    const response = await pendingListUsersRBAC();
    return response;
  }
);

export interface UsersRBACState {
  usersPendingRBAC: WithOutPermissionsUserExtendedProperties[];
  status: Status;
  error: string;
  openDisplay: boolean;
}

const initialState: UsersRBACState = {
  usersPendingRBAC: [],
  status: Status.void,
  error: "",
  openDisplay: true,
};

export const usersPendingRBACSlice = createSlice({
  name: "usersPendingRBAC",
  initialState,
  reducers: {
    setTogglePending: (state: UsersRBACState) => {
      state.openDisplay = !state.openDisplay;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncUsersPendingRBAC.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncUsersPendingRBAC.fulfilled, (state, action) => {
        state.openDisplay =
          state.openDisplay !== undefined ? state.openDisplay : true;
        state.usersPendingRBAC = action.payload;
      })
      .addCase(listAsyncUsersPendingRBAC.rejected, (state) => {
        state.status = Status.error;
        state.error = t(
          "Error loading user pending list RBAC. Please try again later."
        );
      });
  },
});

export const selectUsersPendingRBACOpenDisplay = (state: RootState) =>
  state.usersPendingRBAC.openDisplay;

export const selectUsersPendingRBAC = (state: RootState) =>
  state.usersPendingRBAC.usersPendingRBAC;

export const selectUsersPendingRBACStatus = (state: RootState) =>
  state.usersPendingRBAC.status;

export const selectUsersPendingRBACError = (state: RootState) =>
  state.usersPendingRBAC.error;

export const selectUsersPendingRBACByName =
  (name: string) => (state: RootState) => {
    if (
      state.usersPendingRBAC &&
      state.usersPendingRBAC.usersPendingRBAC &&
      state.usersPendingRBAC.usersPendingRBAC.length > 0
    ) {
      return state.usersPendingRBAC.usersPendingRBAC.find(
        (c: WithOutPermissionsUserExtendedProperties) => c.displayName === name
      );
    }

    return undefined;
  };
export const { setTogglePending } = usersPendingRBACSlice.actions;
export default usersPendingRBACSlice.reducer;
