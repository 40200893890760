import "./style.scss";

import type { IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { ChoiceGroup } from "@fluentui/react/lib/ChoiceGroup";
import React from "react";

import useLayoutSettingsStore from "../../useLayoutSettingsStore";
import { useTranslation } from "react-i18next";

const getOptions: (t) => IChoiceGroupOption[] = (t) => [
  { key: "normal", text: t("Normal") },
  { key: "syncAll", text: t("Sync all left Y-Axes") },
];

export default function SyncSettings() {
  const { t } = useTranslation();
  const { updateStore, syncSettingsMode, resetSettings } =
    useLayoutSettingsStore((store: any) => ({
      updateStore: store.updateStore,
      syncSettingsMode: store.syncSettingsMode,
      resetSettings: store.resetSettings,
    }));

  function onChoiceChange(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption | undefined
  ) {
    resetSettings();
    updateStore({ syncSettingsMode: option?.key });
  }

  return (
    <ChoiceGroup
      className="chart-layout-modal_sync-settings"
      options={getOptions(t)}
      defaultSelectedKey={syncSettingsMode}
      onChange={onChoiceChange}
    />
  );
}
