import { IconButton, Link } from "@fluentui/react";

import { formatFileSize } from "../../../../../utils";
import type { Column } from "../../../../../Components/common/Table/Table";

type GetColumnsOpts = {
  t: any;
  onRemove: (id: string) => void;
};

export const getColumns = ({ t, onRemove }: GetColumnsOpts): Column[] => [
  {
    key: "name",
    fieldName: "name",
    name: t("Name"),
    flexGrow: 1,
    calculatedWidth: 0,
    minWidth: 200,
    isSortable: true,
    onRender: ({ name }) => {
      // TODO: should we use Link from react-router-dom?
      return (
        <Link underline href="#" style={{ fontSize: 13 }}>
          {name}
        </Link>
      );
    },
  },
  {
    key: "size",
    fieldName: "size",
    name: t("Size"),
    minWidth: 100,
    isSortable: true,
    onRender: ({ size, isValid }) => {
      return (
        <span
          style={{ fontSize: 13, color: isValid ? "currentColor" : "#ff6a66" }}
        >
          {isValid ? formatFileSize(size) : t("Too large")}
        </span>
      );
    },
  },
  // {
  //   key: 'type',
  //   fieldName: 'type',
  //   name: 'Type',
  //   minWidth: 100,
  //   maxWidth: 100,
  //   isSortable: true,
  //   onRender: ({ type }) => {
  //     return <span style={{ fontSize: 13 }}>{type}</span>;
  //   },
  // },
  {
    fieldName: "actions",
    key: "actions",
    name: t("Actions"),
    minWidth: 68,
    onRender: ({ id }) => (
      <IconButton
        iconProps={{ iconName: "Delete" }}
        title={t("Delete")}
        ariaLabel={t("Delete")}
        styles={{
          root: {
            verticalAlign: "middle",
            color: "#0078d4",
            width: "100%",
            height: 16,
            padding: 0,
            margin: "0 auto",
          },
          rootHovered: {
            background: "transparent",
          },
          rootDisabled: {
            background: "transparent",
          },
        }}
        onClick={() => onRemove(id)}
      />
    ),
  },
];
