import React from "react";

import BaseCommandBar from "../../../../common/CommandBar";
import type { CommandBarItemProps } from "../../../../common/CommandBar/methods";
import {
  CommandBarItemType,
  computeCommandBarItems,
} from "../../../../common/CommandBar/methods";
import { useTranslation } from "react-i18next";

type ThreadCommentsCommandBarProps = {
  handleSearch?: (value: string) => void;
  onAdd: () => void;
};

export const CommandBar = ({
  handleSearch,
  onAdd,
}: ThreadCommentsCommandBarProps) => {
  const { t } = useTranslation();
  const items: CommandBarItemProps[] = [
    {
      key: "newTag",
      text: t("Create tag"),
      type: CommandBarItemType.Button,
      iconProps: { iconName: "Add" },
      onClick: onAdd,
    },
  ];

  return (
    <BaseCommandBar
      items={computeCommandBarItems(items)}
      onSearch={handleSearch}
    />
  );
};
