/* eslint-disable react-hooks/exhaustive-deps */
import { DialogType, Pivot, PivotItem } from "@fluentui/react";
import {
  D325TableItem,
  DBasicTableItem,
  D850EcoTableItem,
  D850TableItem,
} from "../tableItemModels";
import BaseDialog, { DialogSize } from "../../common/Dialog";
import { useEffect } from "react";
import IoTLinkConfigForm from "./IoTLinkConfigForm";
import { useRawSignals } from "./hooks";
import DdpConfigForm from "./DdpConfigForm";
import { useTranslation } from "react-i18next";

export type GenerateConfigDialogProps = {
  tableItem: D325TableItem | DBasicTableItem | D850EcoTableItem | D850TableItem;
  onClose: () => void;
};

const excludeSignalCases: string[] = ["env", "torque", "torq", "trq", "toq"];

/**
 * Gets the Generate Configuration dialog component.
 * @param tableItem the table item
 * @param onClose Method called to close this dialog.
 * @returns The Generate Configuration dialog component.
 */
const GenerateConfigDialog = ({
  tableItem,
  onClose,
}: GenerateConfigDialogProps) => {
  const { t } = useTranslation();
  const { signals, signalsAreLoading } = useRawSignals(
    tableItem.machineIds?.[0]
  );

  // Closes the dialog, if the signals were not found.
  useEffect(() => {
    if (!signals) {
      onClose?.();
      return;
    }
  }, [signals]);

  return (
    <BaseDialog
      hidden={false}
      dialogContentProps={{
        title: t("Generate Configuration"),
        type: DialogType.close,
        onDismiss: onClose,
      }}
      size={DialogSize.S}
    >
      <Pivot aria-label={t("generate datalogger configuration pivot")}>
        <PivotItem headerText={t("IoT Link")}>
          <IoTLinkConfigForm
            tableItem={tableItem}
            signals={signals?.filter((signal) => {
              for (let word of excludeSignalCases) {
                if (!signal.name.toLocaleLowerCase().includes(word)) {
                  continue;
                }

                return false;
              }

              return true;
            })}
            isLoading={signalsAreLoading}
            onClose={onClose}
          />
        </PivotItem>
        <PivotItem headerText={t("Data Platform")}>
          <DdpConfigForm tableItem={tableItem} onClose={onClose} />
        </PivotItem>
      </Pivot>
    </BaseDialog>
  );
};

export default GenerateConfigDialog;
