import type { IDropdownOption, IIconProps } from "@fluentui/react";
import { ActionButton, Stack, Text, VerticalDivider } from "@fluentui/react";
import { format } from "date-fns";

import LayoutIcon from "../../../../assets/svg/LayoutIcon";
import SchemeIcon from "../../../../assets/svg/SchemeIcon";
import type { CommandBarItemProps } from "../../../../Components/common/CommandBar";
import BaseCommandBar, {
  CommandBarItemType,
} from "../../../../Components/common/CommandBar";
import {
  computeCommandBarItems,
  dropdownStyles,
} from "../../../../Components/common/CommandBar/methods";

import useChartLabels from "../../hooks/useChartLabels";
import useLayoutTypes from "../../hooks/useLayoutTypes";
import useWaterfallStore from "../../hooks/useWaterfallStore";
import {
  buttonsContainer,
  exportElement,
  headerContainer,
  headerTitle,
  headerTitleText,
  maximizeButton,
} from "../../styles/ChartSection.styles";
import type { WaterfallChartMethods } from "../../types";
import { renderCustomLabel } from "../HeaderControls/HeaderControls";
import { baseCommandBar } from "../HeaderControls/HeaderControls.styles";
import type { IColorScale } from "../WaterfallChart/config";
import { getColorScaleOptions } from "../WaterfallChart/config";
import { useTranslation } from "react-i18next";

type ChartSectionHeaderProps = {
  id: string;
  title: string;
  isSpectrum?: boolean;
  items?: CommandBarItemProps[] | undefined;

  // items?: IContextualMenuProps | undefined;
  timestamp?: string;
  waterfallMethods?: WaterfallChartMethods;
  maximizeIcon?: JSX.Element;
  maximizeLabel?: string;
  maximizeAction?: () => void;
};

const refreshIcon: IIconProps = { iconName: "Refresh" };
const saveAsIcon: IIconProps = { iconName: "Save" };
const reductionIcon: IIconProps = { iconName: "Settings" };

const ChartSectionHeader = ({
  id,
  title,
  isSpectrum,
  items,
  timestamp,
  waterfallMethods,
  maximizeIcon,
  maximizeLabel,
  maximizeAction,
}: ChartSectionHeaderProps) => {
  const shouldShowChartLabels = useChartLabels();

  const { t } = useTranslation();

  const { isWaterfallLayout } = useLayoutTypes();

  const formattedTimestamp = timestamp
    ? format(new Date(timestamp), "dd MMM yyyy HH:mm")
    : "";

  const {
    interpolation,
    colorScale,
    plotType,
    setInterpolation,
    setColorScale,
    setPlotType,
  } = useWaterfallStore((state) => ({
    interpolation: state.interpolation,
    colorScale: state.colorScale,
    plotType: state.plotType,
    setInterpolation: state.setInterpolation,
    setColorScale: state.setColorScale,
    setPlotType: state.setPlotType,
  }));

  const commandBarItems: CommandBarItemProps[] = [
    {
      key: "reset",
      text: t("Reset"),
      type: CommandBarItemType.Button,
      iconProps: refreshIcon,
      style: { marginLeft: 16, height: 36 },
      onClick: () => waterfallMethods?.resetPlot(),
    },
    {
      key: "save",
      text: t("Save as"),
      type: CommandBarItemType.Button,
      iconProps: saveAsIcon,
      style: { marginLeft: 16, height: 36 },
      subMenuProps: {
        items: [
          {
            key: "png",
            text: "PNG",
            onClick: () =>
              waterfallMethods?.savePlotAs({
                value: "png",
              }),
          },
          {
            key: "jpeg",
            text: "JPG",
            onClick: () =>
              waterfallMethods?.savePlotAs({
                value: "jpeg",
              }),
          },
        ],
      },
    },
    {
      key: "plot_type",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: plotType,
        styles: dropdownStyles,
        options: [
          {
            key: "heatmap",
            text: t("Heatmap"),
          },
          // {
          //   key: 'contour',
          //   text: 'Contour',
          // },
          // {
          //   key: 'surface',
          //   text: '3D',
          // },
        ],
        onChange: (_: never, item: IDropdownOption) =>
          setPlotType(item.key as "heatmap" | "contour" | "surface"),
        onRenderLabel: () =>
          renderCustomLabel({
            text: t("Chart view"),
            icon: LayoutIcon,
            disabled: false,
          }),
      },
    },
    {
      key: "reduction",
      text: t("Reduction"),
      type: CommandBarItemType.Button,
      iconProps: reductionIcon,
      style: { marginLeft: 0, marginRight: 10, height: 36 },
      onClick: () => waterfallMethods?.toggleReductionDialog(),
    },
    {
      key: "interpolation",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: interpolation,
        styles: dropdownStyles,
        options: [
          {
            key: "none",
            text: t("None"),
          },
          {
            key: "best",
            text: t("Best"),
          },
        ],
        onChange: (_: never, item: IDropdownOption) => {
          setInterpolation(item.key as string);
        },
        onRenderLabel: () =>
          renderCustomLabel({
            text: t("Interpolation"),
            icon: LayoutIcon,
            disabled: false,
          }),
      },
    },
    {
      key: "scheme",
      type: CommandBarItemType.Dropdown,
      onRenderProps: {
        defaultSelectedKey: colorScale.key,
        styles: dropdownStyles,
        options: getColorScaleOptions(t),
        onChange: (_: never, item: IDropdownOption) =>
          setColorScale(item as IColorScale),
        onRenderLabel: () =>
          renderCustomLabel({
            text: t("Scheme"),
            icon: SchemeIcon,
            disabled: false,
          }),
      },
    },
  ];

  return (
    <Stack horizontal style={headerContainer}>
      <Stack horizontal>
        <Text variant="mediumPlus" style={headerTitle}>
          {title} <VerticalDivider styles={headerTitleText} />
        </Text>

        {(isSpectrum || isWaterfallLayout) && items && (
          <BaseCommandBar
            items={computeCommandBarItems(items)}
            styles={baseCommandBar}
          />
        )}

        {isWaterfallLayout && (
          <BaseCommandBar
            items={computeCommandBarItems(commandBarItems)}
            styles={baseCommandBar}
          />
        )}
      </Stack>
      <Stack horizontal style={buttonsContainer}>
        <Text className="range">{formattedTimestamp}</Text>
        <ActionButton
          allowDisabledFocus
          styles={maximizeButton}
          onClick={maximizeAction}
        >
          {maximizeIcon}
          {shouldShowChartLabels && <span>{maximizeLabel}</span>}
        </ActionButton>
        <div className={id} style={exportElement} />
      </Stack>
    </Stack>
  );
};

export default ChartSectionHeader;
