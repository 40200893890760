export const getLayoutOptions = (t) => [
  {
    key: "waveform-spectrum",
    text: t("Waveform and Spectrum"),
    data: { reset: true },
  },
  {
    key: "trend-waveform-spectrum",
    text: t("Trend, Waveform and Spectrum"),
    data: { reset: true },
  },
  {
    key: "compare-waveform-spectrum",
    text: t("Compare Waveform and Spectrum"),
    data: { reset: true },
  },
  {
    key: "waterfall",
    text: t("Waterfall"),
    data: { reset: true },
  },
];
