import React, { PropsWithChildren, createContext } from "react";
import {
  RequestSubmissionMachine,
  RequestWirelessGatewayAddAux,
  RequestWirelessGatewayUpdateAux,
  SubmissionSupport,
} from "../models";
import { areObjectsEqual } from "../../../schema/Utils";

interface SupportContextValue {
  support: SubmissionSupport | undefined;
  setSupport: React.Dispatch<
    React.SetStateAction<SubmissionSupport | undefined>
  >;
  updateSupportEle: (
    ele:
      | RequestWirelessGatewayAddAux
      | RequestWirelessGatewayUpdateAux
      | RequestSubmissionMachine
  ) => void;
}

export const SupportContext = createContext<SupportContextValue>({
  support: undefined,
  setSupport: () => {},
  updateSupportEle: () => {},
});

interface SupportProviderProps {
  support: SubmissionSupport | undefined;
  setSupport: React.Dispatch<
    React.SetStateAction<SubmissionSupport | undefined>
  >;
}

export const SupportProvider = ({
  children,
  support,
  setSupport,
}: PropsWithChildren<SupportProviderProps>) => {
  const updateSupportEle = (
    ele:
      | RequestWirelessGatewayAddAux
      | RequestWirelessGatewayUpdateAux
      | RequestSubmissionMachine
  ) => {
    if (!support) {
      return;
    }

    const newSupport = { ...support };

    if ("nameMachine" in ele) {
      const index = newSupport.machinesSupport.findIndex(
        (machine) =>
          machine.nameMachine === ele.nameMachine &&
          machine.matchUniqueId === ele.matchUniqueId
      );
      if (index !== -1) {
        newSupport.machinesSupport[index] = ele;
        setSupport(newSupport);
      }
    } else {
      const esAdd = !("connectionString" in ele);
      const array = esAdd
        ? newSupport.gatewaysSupportToAdd
        : newSupport.gatewaysSupportToUpdate;
      const index = array.findIndex(
        (element: any) =>
          element.matchUniqueId === ele.matchUniqueId &&
          element.serialNumber === ele.serialNumber
      );
      if (index !== -1 && !areObjectsEqual(array[index], ele)) {
        array[index] = ele;
        setSupport(newSupport);
      }
    }
  };

  const value: SupportContextValue = {
    support,
    setSupport,
    updateSupportEle,
  };

  return (
    <SupportContext.Provider value={value}>{children}</SupportContext.Provider>
  );
};
