import classNames from "classnames";
import { isEmpty, isEqual } from "lodash-es";
import { useEffect, useMemo, useRef, useState } from "react";

import {
  useLocationSearch,
  useTrendMeasuredDataInAPeriod,
  usePrevious,
} from "../../../../../../Hooks";

import { CHART_NUMBER_OF_POINTS } from "../../../../../../modules/analysis-trend-view/constants/controlOptions";
import { createYaxisAndSeries, initializeEventsChart } from "./methods";
import useDriveLoadStore from "../../../hooks/useDriveLoadStore";
import { useEvents } from "../../../hooks/useEvents";
import { useTrendSignalsList } from "../../../../MachineCVSummaryPage/hooks/useTrendSignalsList";
import type { ResponseSimplifiedSignal } from "../../../../../../types";
import { NoData } from "../../../../../common/NoData";
import { Spin } from "../../../../../common/Spin";
import { XYChart } from "../../../../../common/XYChart";
import type { XYChartStyles } from "../../../../../common/XYChart/XYChart";

import { DAILY_EVENTS } from "../config";
import { prepareEventsChartData, prepareEventsStartEndDate } from "../methods";
import { useTranslation } from "react-i18next";

type EventsChartProps = React.HTMLAttributes<HTMLDivElement> & {
  chartStyles?: XYChartStyles;
};

const EventsChart = ({ chartStyles, ...rest }: EventsChartProps) => {
  const { t } = useTranslation();
  const chartRef: any = useRef({});
  const [chartInstance, setChartInstance]: any = useState({});
  const [eventsChart, setEventsChart] = useState({
    loading: false,
    data: [],
  });
  const prevData = usePrevious(eventsChart.data);
  const [{ id }, ,]: any = useLocationSearch();
  const { signals, isLoading: loadingSignals } = useTrendSignalsList(
    id as string
  );
  const operationSignal = useMemo(() => {
    return signals.filter((signal: ResponseSimplifiedSignal) => {
      return signal.name === "operation_hours_OP";
    })[0];
  }, [signals, loadingSignals]);

  const { events, driveLoad, updateDriveLoadStore, selectedFilterEventsNames } =
    useDriveLoadStore((store: any) => ({
      events: store.events,
      driveLoad: store.driveLoad,
      updateDriveLoadStore: store.updateDriveLoadStore,
      selectedFilterEventsNames: store.selectedFilterEventsNames,
    }));

  const { year, month, activeTab } = events;
  const { startDate, endDate }: any = prepareEventsStartEndDate(
    activeTab,
    year,
    month
  );

  const { refetch, data: operationData } = useTrendMeasuredDataInAPeriod(
    {
      startDate,
      endDate,
      queryKey: ["events-chart", operationSignal?.id, startDate, endDate],
      machineId: operationSignal?.machineId,
      signalId: operationSignal?.id,
      numberOfPoints: CHART_NUMBER_OF_POINTS,
      signalType: operationSignal?.dataType,
      onlyZip: true,
      refreshInterval: { key: "off", text: t("Off") },
    },
    {
      enabled: false,
    }
  );

  const { refetch: refetchEvents, data: eventsData }: any = useEvents({
    eventId: driveLoad?.eventId,
    year: year?.year,
    month:
      activeTab === DAILY_EVENTS ? month?.start?.getMonth() + 1 : undefined,
    options: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (!isEmpty(year)) {
      setEventsChart((prevState: any) => ({ ...prevState, loading: true }));
      updateDriveLoadStore({ eventsTableLoading: true });
      Promise.allSettled([refetch(), refetchEvents()]).then(
        (res: any) => {
          const preparedChartData = prepareEventsChartData({
            t,
            eventsData: res[1]?.value?.data?.events,
            operationData: res[0]?.value?.data,
            activeTab,
            year,
            month,
            filteredNames: ["selectAll"],
          });

          setEventsChart((prevState: any) => ({
            ...prevState,
            data: preparedChartData,
            loading: false,
          }));

          const removedEventsNegativeDuration = (
            res[1]?.value?.data?.events || []
          ).filter(({ duration }: any) => !(duration || "").includes("-"));

          updateDriveLoadStore({
            eventsTableLoading: false,
            eventsTableData: removedEventsNegativeDuration || [],
          });
        },
        () => {
          setEventsChart((prevState: any) => ({
            ...prevState,
            loading: false,
          }));
          updateDriveLoadStore({ eventsTableLoading: false });
        }
      );
    }
  }, [year, month, activeTab, t]);

  useEffect(() => {
    const preparedChartData = prepareEventsChartData({
      t,
      eventsData: eventsData?.events,
      operationData: operationData,
      activeTab,
      year,
      month,
      filteredNames: selectedFilterEventsNames,
    });

    setEventsChart((prevState: any) => ({
      ...prevState,
      data: preparedChartData,
    }));
  }, [selectedFilterEventsNames, t]);

  // initialize the chart
  useEffect(() => {
    const { root, chart } = chartInstance;
    if (!chart || !root || (chart && !isEmpty(chartRef.current))) return;
    const { xAxis } = initializeEventsChart({ chart, root });
    chartRef.current = { xAxis };
  }, [chartInstance]);

  useEffect(() => {
    const { chart, root } = chartInstance;
    if (!chart) return;

    const { xAxis } = chartRef.current;

    if (!isEqual(eventsChart.data, prevData)) {
      createYaxisAndSeries({
        chart,
        root,
        data: eventsChart.data,
        xAxis,
        activeTab,
      });
      xAxis.data.setAll(eventsChart.data);
    }
  }, [chartInstance, eventsChart.data, activeTab]);

  return (
    <div
      {...rest}
      className={classNames("events-chart-container", {
        loading: eventsChart.loading,
        "no-data": !eventsChart.loading && eventsChart.data.length === 0,
      })}
    >
      {!eventsChart.loading && eventsChart.data.length === 0 && (
        <NoData className="no-data-message" />
      )}
      {eventsChart.loading && <Spin className="loading-spinner" />}
      <XYChart
        className="operation-chart"
        exportSelector="operations-export"
        styles={{
          root: {
            height: "100%",
            minHeight: "200px",
          },
          chart: {
            height: "calc(100% - 2em)",
          },
          ...chartStyles,
        }}
        customSettings={{
          onReady: (instance: any) => setChartInstance(instance),
        }}
      />
    </div>
  );
};

export default EventsChart;
