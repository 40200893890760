import { Callout, DirectionalHint, PrimaryButton } from "@fluentui/react";
import { uniqueId } from "lodash-es";
import ClusterList from "./ClusterList";
import { memo, useEffect, useState } from "react";
import {
  getSlicesDataByKey,
  renderPieChartSlices,
} from "../../Overview/Panels/components/PieChart/utils";

function ClusterMarker({ clusterPoints, clusterProps }) {
  const [isPopupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    if (isPopupVisible) {
      setPopupVisible(false);
    }
  }, [clusterProps]);

  const calloutID = uniqueId("cluster-id");

  const slices = getSlicesDataByKey(clusterPoints, "condition");

  return (
    <>
      <PrimaryButton
        text={`${clusterProps?.point_count}`}
        className="cluster-marker"
        id={calloutID}
        onClick={() => setPopupVisible(!isPopupVisible)}
      >
        <svg
          style={{ position: "absolute", zIndex: "-1" }}
          id="pieChart"
          width="62"
          height="62"
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
        >
          {renderPieChartSlices(slices)}
          <circle cx="100" cy="100" r="70" fill="#2c529f" />
        </svg>
      </PrimaryButton>

      {isPopupVisible && (
        <Callout
          role="dialog"
          directionalHint={DirectionalHint.bottomRightEdge}
          target={`#${calloutID}`}
          onDismiss={() => setPopupVisible(false)}
          beakWidth={0}
        >
          <ClusterList clusterPoints={clusterPoints} />
        </Callout>
      )}
    </>
  );
}

export default memo(ClusterMarker);
