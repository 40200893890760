import { mergeStyleSets, Stack, Toggle } from "@fluentui/react";
import type { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Dropdown } from "@fluentui/react/lib/Dropdown";

import LayoutIcon from "../../../../../assets/svg/LayoutIcon";
import { dropdownIconStyle } from "../../../../../modules/analysis-trend-view/components/TrendViewControls/TrendView.styles";
import { getViewDropdownOptions } from "../../config";
import useDriveLoadStore from "../../hooks/useDriveLoadStore";
import {
  dropdownStyles,
  onRenderLabel,
} from "../../../../common/CommandBar/methods";
import { useTranslation } from "react-i18next";

const ColoredChartHeader = () => {
  const { t } = useTranslation();
  const { showDates, colorChartView, updateDriveLoadStore } = useDriveLoadStore(
    (store: any) => ({
      showDates: store.showDates,
      colorChartView: store.colorChartView,
      updateDriveLoadStore: store.updateDriveLoadStore,
    })
  );

  return (
    <Stack
      horizontal
      verticalAlign="center"
      tokens={{ childrenGap: 10 }}
      role="menubar"
    >
      <Dropdown
        className="layout-dropdown secondary-dropdown"
        styles={mergeStyleSets(dropdownStyles)}
        calloutProps={{ calloutMinWidth: 200 }}
        selectedKey={colorChartView.key}
        options={getViewDropdownOptions(t)}
        onChange={(_: any, option?: IDropdownOption) =>
          updateDriveLoadStore({ colorChartView: option })
        }
        onRenderLabel={() =>
          onRenderLabel({
            label: t("Scatter Chart"),
            icon: <LayoutIcon fill="#2c529f" style={dropdownIconStyle} />,
          })
        }
      />
      <Toggle
        inlineLabel
        label={t("Show dates")}
        checked={showDates}
        styles={{ root: { marginBottom: 0, marginLeft: 10 } }}
        onChange={(_, checked) => updateDriveLoadStore({ showDates: checked })}
      />
    </Stack>
  );
};

export default ColoredChartHeader;
