import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "../../schema/status";
import { RootState } from "../../store";
import { list } from "./api";
import { Corporation } from "./models";
import { t } from "i18next";

export const listAsyncCorpo = createAsyncThunk(
  "corporations/list",
  async () => {
    const response = await list();
    if (response.length > 0)
      return response.sort((a, b) => (a.name > b.name ? 1 : -1));
    return response;
  }
);

export interface CorporationsState {
  items: Corporation[];
  status: Status;
  error: string;
}

const initialState: CorporationsState = {
  items: [],
  status: Status.void,
  error: "",
};

export const corporationsSlice = createSlice({
  name: "corporations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listAsyncCorpo.pending, (state) => {
        state.status = Status.loading;
      })
      .addCase(listAsyncCorpo.fulfilled, (state, action) => {
        state.status = Status.idle;
        state.items = action.payload;
      })
      .addCase(listAsyncCorpo.rejected, (state) => {
        state.status = Status.error;
        state.error = t("Error loading corporations. Please try again later.");
      });
  },
});

// Selectors

export const selectCorporations = (state: RootState) =>
  state.corporations.items;

export const selectCorporationsStatus = (state: RootState) =>
  state.corporations.status;
export const selectCorporationsError = (state: RootState) =>
  state.corporations.error;

export const selectCorporation =
  (corporationId: string) => (state: RootState) =>
    state.corporations.items.find(({ id }) => corporationId === id);

export default corporationsSlice.reducer;
